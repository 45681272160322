import { CircularProgress } from "@mui/material";
import APIList from "api";
import BenModal from "components/common/Modal/BenModal";
import { isEmptyNullUndefined } from "components/utils/utils";
import { useEffect, useState } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import alphabetB from "../../../../assets/Used/letterB.png";
import { actions as getPlanActions } from "../../../common/Plans/redux/actions";
import Slider from "react-slick";
import PageHeader from "components/common/PageHeader";
import LearnPageSkeleton from "./skeleton";
import { FormattedMessage } from "react-intl";

var classNames = require("classnames");
const parse = require("html-react-parser");

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1
};
const settings1 = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1
};

const NewLearnTabs = () => {

    const params = useParams();
    const dispatch = useDispatch();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const selectedPlan = useSelector((state) => state?.planReducer?.planData);

    const [loader, setLoader] = useState(false);
    const [learningPageData, setLearningPageData] = useState(null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [activeTileIndex, setActiveTileIndex] = useState(null);
    const [viewDetails, setViewDetails] = useState(false);
    const [leaveHeaderData, setLeaveHeaderData] = useState(null);
    const [leaveHeaderLoader, setLeaveHeaderLoader] = useState(false);

    useEffect(() => {
      if(params?.classname === "learnLeaves"){
        if(!isEmptyNullUndefined(employeeDetails?.id)){
          getLeaveData();
          getLeaveHeaderData();
          }
      }else{
        if(!isEmptyNullUndefined(employeeDetails?.id)){
          dispatch(
            getPlanActions.PlanDataRequest({
              className: params?.classname,
              empId: employeeDetails?.id,
              Id: params?.id,
            })
          );
          getBackendDataLearningPage();
          }
      }        
      }, []);
    
      const getBackendDataLearningPage = () => {
        setLoader(() => true)
        APIList.getLearningPageData(params?.id, employeeDetails?.employeeRole?.id)
        .then((response) => {
          response?.data?.tabs.sort((a,b) => a.sequenceNumber - b.sequenceNumber)
          setLearningPageData(() => response.data)
          setLoader(() => false)
        })
        .catch((error) => {
          toast.error(
            <div className='flex flex-row'>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{error?.title}
            </div>
          ); 
          setLoader(() => false)
        })
        
      }   
      const getLeaveData = () => {
        setLoader(() => true)
        APIList.leaveLearn(`${employeeDetails?.company?.id}-Time-off-and-Leave`)
        .then((response) => {
          response?.data?.tabs.sort((a,b) => a.sequenceNumber - b.sequenceNumber)
          setLearningPageData(() => response.data)
          setLoader(() => false)
        })
        .catch((error) => {
          toast.error(
            <div className='flex flex-row'>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{error?.title}
            </div>
          ); 
          setLoader(() => false)
        })
        
      }   
      const getLeaveHeaderData = () => {
        setLeaveHeaderLoader(() => true)
        APIList.leaveHeader({empId:employeeDetails?.id})
        .then((response) => {
          setLeaveHeaderData(() => response.data)
          setLeaveHeaderLoader(() => false)
        })
        .catch((error) => {
          toast.error(
            <div className='flex flex-row'>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{error?.title}
            </div>
          ); 
          setLeaveHeaderLoader(() => false)
        })
        
      }   

    return(

        <div className="new-learn-main-container"> 
          {
            params?.classname === "learnLeaves" ? 
            <h4 className="page-heading-navbar">
              {/* Time off and Leave */}
              <FormattedMessage id="policyDetails.timeOffAndLeave" defaultMessage="Time off and Leave"/>
              </h4>
            :
            <h4 className="page-heading-navbar">{selectedPlan?.displayName}</h4>
          }        

          {
            params?.classname === "learnLeaves" ? 
             <PageHeader description={leaveHeaderData?.plansMessages[0]?.details} leaveLoading={leaveHeaderLoader} type="leave" />
             :
             <PageHeader planIcon={selectedPlan?.icon?.path} />
          }

        
       
            <div className="main-tabs-scroll">
                {
                    loader ? 
                    <LearnPageSkeleton />
                    :
                    learningPageData?.tabs?.length > 2 ?
                    <div className="tab-sliders">
                      {
                        window?.innerWidth > 768 && 
                        <Slider {...settings} className="custom-setting">
                            {(learningPageData) &&
                              learningPageData?.tabs?.map((tab, index) => (
                                // <div >
                                //   <div className="outer-container-slick">


                                      <div
                                        className={classNames("learnTab", {
                                          active: (index === activeTabIndex),
                                        })} 
                                        key={index}
                                        onClick={() => setActiveTabIndex(index)}
                                      >
                                        {/* <button className="list-menu"> */}
                                          {tab?.tabTitle ? tab.tabTitle : ""}
                                          {/* </button> */}
                                      </div>
                                //    </div>
                                //  </div>
                              ))
                            } 
                        </Slider> 
                      }
                      {
                        window?.innerWidth < 768 && 
                        <Slider {...settings1} className="custom-setting">
                            {(learningPageData) &&
                              learningPageData?.tabs?.map((tab, index) => (
                                // <div >
                                //   <div className="outer-container-slick">


                                      <div
                                        className={classNames("learnTab", {
                                          active: (index === activeTabIndex),
                                        })} 
                                        key={index}
                                        onClick={() => setActiveTabIndex(index)}
                                      >
                                        {/* <button className="list-menu"> */}
                                          {tab?.tabTitle ? tab.tabTitle : ""}
                                          {/* </button> */}
                                      </div>
                                //    </div>
                                //  </div>
                              ))
                            } 
                        </Slider> 
                      }
                    
                          </div>
                    :
                    <div className="main-tabs-section">
                      {
                    learningPageData?.tabs?.sort((x, y) => x.sequenceNumber - y.sequenceNumber)?.map((tab, index) => {
                        return(
                            <div 
                              className={classNames("learnTab", {
                                active: (index === activeTabIndex),
                              })} 
                              key={index}
                              onClick={() => setActiveTabIndex(index)}
                              >
                                {tab?.tabTitle}
                            </div>
                        )
                    })
                    }
                    </div>
                }
            </div>
            <div className="tiles-primary-div">
            {
                   learningPageData && learningPageData?.tabs[activeTabIndex]?.tiles?.map((tile, index) => { 
                    return(
                      tile?.isPrimaryTile &&
                        <div 
                        className="primary-tile" 
                        // onClick={() => {setViewDetails(true); setActiveTileIndex(index)}}
                        style={{backgroundColor:"#dedede"}}
                        >
                            {/* <img src={tile?.icon?.path} alt="icon" /> */}
                            <p>{tile?.tileTitle}</p>    
                            <p className="desc">{tile?.description && parse(tile?.description)}</p>                        
                            </div>
                    )
                   })
                }
            </div>
            <div className="tiles-main-div">
                {
                   learningPageData && learningPageData?.tabs[activeTabIndex]?.tiles?.sort((x, y) => x.sequenceNumber - y.sequenceNumber)?.map((tile, index) => { 
                    return(
                      !(tile?.isPrimaryTile) &&
                        <div 
                        className="tile" 
                        onClick={() => {setViewDetails(true); setActiveTileIndex(index)}}
                        style={{backgroundColor:tile?.colour}}
                        >
                          {
                            tile?.icon?.path ?
                            <img src={tile?.icon?.path} alt="icon" />
                            :
                            <img src={alphabetB} alt="icon" />
                          }
                            <p>{tile?.tileTitle}</p>                            
                            </div>
                    )
                   })
                }
            </div>
            
            <div className="learn-modal-container">
            {
              viewDetails && 
                <BenModal setViewDetails={setViewDetails}>
                
                {
                  learningPageData && learningPageData?.tabs[activeTabIndex]?.tiles[activeTileIndex]?.icon?.path && 
                  <img src={learningPageData && learningPageData?.tabs[activeTabIndex]?.tiles[activeTileIndex]?.icon?.path} alt="icon" />
                }
                  <div className="tile-title">{learningPageData && learningPageData?.tabs[activeTabIndex]?.tiles[activeTileIndex]?.tileTitle}</div>
                  <div className="content-description">
                      {
                      learningPageData && learningPageData?.tabs[activeTabIndex]?.tiles[activeTileIndex]?.description && parse(learningPageData?.tabs[activeTabIndex]?.tiles[activeTileIndex]?.description)
                      }
                  </div>
            </BenModal>
            }
            </div>
        </div>

    )
}

export default NewLearnTabs;
