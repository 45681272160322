import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import APIList from "api";
import ButtonM from "components/common/Button";
import GetCostVersion2 from "components/pages/BasicMedical/ViewCost/Version2";
import { CurrencyFormat } from "components/utils/numberFormatter";
import { getCoveragePlan, getPayCheckFrequency, getPlanDependantWithRespectToRelation } from "components/utils/utils";
import { useEffect, useRef, useState } from "react";
import { BsChevronDown, BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { actions as getPlanActions } from "../../../../../common/Plans/redux/actions";
import { FormattedMessage } from "react-intl";
var classNames = require("classnames");
// import { selectedPlan } from "../../utils";

const SingleDependant = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const selectedPlan = useSelector((state) => state?.planReducer?.planData);
    const [coveragePlan, setCoveragePlan] = useState(null);
    const [selectedCoverageCards, setSelectedCoverageCards] = useState([]);
    const [electionChecked, setElectionChecked] = useState(false);

    const [getCostLoader, setGetCostLoader] = useState(false);  
    const [enrollLoader, setEnrollLoader] = useState(false);

    const [viewCost, setViewCost] = useState(false);
    const [costData, setCostData] = useState([]);
    
    // console.log("selectedCoverageCards",selectedCoverageCards);

    const { selectedCategories, individualSelectedDependantsList, selectedPlanDependants} = useSelector((state) => state?.voluntryReducer); 
    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const currency = employeeDetails?.company?.currency;    
    const enrolledPlanData = useSelector((state) => state?.planReducer?.enrolledPlanData);
    const benefitStartDate = useSelector((state) => state?.planReducer?.benefitStartDate);

  useEffect(() => {       
    setSelectedCoverageCards([]);
    setCoveragePlan(getCoveragePlan(selectedPlan, employeeDetails));    
  }, []);

  function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setViewCost(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);

  const getSelfRatecards = () => {
    const arates = selectedPlan?.rateCard?.rateCardItems?.filter(
      (x) =>
        (x?.sumInsured >= (coveragePlan?.minCoverage || 0)) &&
        (x?.sumInsured <= (coveragePlan?.maxCoverage || 9999999999)) 
    );
arates.sort((a, b) => a.sumInsured - b.sumInsured);

return arates;

  }

  const getAllowedRatecards = (dependant) => {
      let rateCards12 = selectedPlan?.rateCard?.rateCardItems?.filter((x) => ((x.sumInsured >= (getPlanDependantWithRespectToRelation(dependant?.relation, selectedPlanDependants)?.minCoverage || 0)) && (x.sumInsured <= (getPlanDependantWithRespectToRelation(dependant?.relation, selectedPlanDependants)?.maxCoverage || 9999999999))));
      rateCards12.sort((a, b) => a.sumInsured - b.sumInsured);
    return rateCards12.filter((v,i,a)=>a.findIndex(t=>(t.sumInsured === v.sumInsured))===i); //restricting ratecards with same coverage appering more than once.
  };

  const getDependantSplit = (ratecard, dependant) => {
    let split = null;
    selectedPlanDependants?.forEach((x) => {

    if((x.relation.includes("Parent")) && ["Mother", "Father"].includes(dependant.relation)){
         
          if(x.employeeSplitinPerc == null || x.employeeSplitinPerc == "NA"){
            split = 0; 
         }else{
          if(selectedPlan?.deductionMethod == "Percent"){
            split = ((ratecard.premium) * x.employeeSplitinPerc)/100;
          }else{
          split = x.employeeSplitinPerc;
         }
        }
    }else if((x.relation.includes("PIL")) && ["FIL", "MIL"].includes(dependant.relation)){
          
          if(x.employeeSplitinPerc == null || x.employeeSplitinPerc == "NA"){
            split = 0; 
         }else{
          if(selectedPlan?.deductionMethod == "Percent"){
            split = ((ratecard.premium) * x.employeeSplitinPerc)/100;
          }else{
          split = x.employeeSplitinPerc;
         }
         }
    }else if(x.relation?.includes(dependant.relation)){
      
          if(x.employeeSplitinPerc == null || x.employeeSplitinPerc == "NA"){
            split = 0; 
         }else{
          if(selectedPlan?.deductionMethod == "Percent"){
            split = ((ratecard.premium) * x.employeeSplitinPerc)/100;
          }else{
          split = x.employeeSplitinPerc;
         }
         }
    }}
    )
    return CurrencyFormat(split,currency);
  };

  const getEmplyeeSplit = (ratecard) => {
    let split = null;
    if(selectedPlan?.deductionMethod == "Percent"){
      split = ((ratecard.premium) * coveragePlan?.employeeSplitPerc)/100;
    }else{
    split = coveragePlan?.employeeSplitPerc;
   }
    return CurrencyFormat(split,currency);
  };

  const handleSelectCoverageForSelf = (ratecard) => {

    if(selectedCoverageCards.filter(x => (x.dependantId == employeeDetails?.id)).length > 0){

      let tempArr = JSON.parse(JSON.stringify(selectedCoverageCards));

      const index = tempArr.findIndex(x => x.dependantId == employeeDetails?.id);
      // console.log("preventDuplicate",tempArr,index);
      tempArr[index] = {
                     name:employeeDetails?.firstName,
                     dependantId:employeeDetails?.id,
                     relation:"Self",
                     coverageId:ratecard.id,
                     amount:ratecard.sumInsured,
                     };

      setSelectedCoverageCards([...tempArr]);

    }else{
  let selected = {
                   name:employeeDetails?.firstName,
                   dependantId:employeeDetails?.id,
                   relation:"Self",
                   coverageId:ratecard.id,
                   amount:ratecard.sumInsured,
                   };
  setSelectedCoverageCards([...selectedCoverageCards, selected]);
}

  }; 

  const handleSelectCoverage = (ratecard, dependant) => {
    
      if(selectedCoverageCards.filter(x => (x.dependantId == dependant?.id)).length > 0){

        let tempArr = JSON.parse(JSON.stringify(selectedCoverageCards));

        const index = tempArr.findIndex(x => x.dependantId == dependant?.id);
        // console.log("preventDuplicate",tempArr,index);
        tempArr[index] = {
          name:dependant?.firstName,
          dependantId:dependant?.id,
          planDependantId:getPlanDependantWithRespectToRelation(dependant?.relation, selectedPlanDependants)?.id,
          relation:dependant?.relation,
          coverageId:ratecard.id,
          amount:ratecard.sumInsured,
         };

        setSelectedCoverageCards([...tempArr]);

      }else{
    let selected = {
                     name:dependant?.firstName,
                     dependantId:dependant?.id,
                     planDependantId:getPlanDependantWithRespectToRelation(dependant?.relation, selectedPlanDependants)?.id,
                     relation:dependant?.relation,
                     coverageId:ratecard.id,
                     amount:ratecard.sumInsured,
                    };
    setSelectedCoverageCards([...selectedCoverageCards, selected]);
}
  };

  const handleViewCost = () => {

    setViewCost(true);
    const employee = selectedCoverageCards.filter(x => x.dependantId == employeeDetails.id);
    const dependants = selectedCoverageCards.filter(x => x.dependantId != employeeDetails.id);

    setGetCostLoader(true);

    APIList.getCost({
      employee:{
        id: employeeDetails.id
      },
      isEmployeeEnrolled: employee?.length > 0,
      selectedCoverage:{
        id: selectedPlan?.coveragePlans[0]?.id
      },
      employeeCoverage:  (employee?.length > 0) ? employee[0].amount : null,
      plan:{
        id: selectedPlan.id,
        className: selectedPlan.className
      },
      enrollmentDate: new Date(),
      dependantEnrollments: dependants.map((x) => {return({
                                dependants:{
                                  id: x.dependantId
                                },
                                dependantEnrollmentDate: new Date(),
                                selectedPlan:{
                                  id: x.planDependantId
                                },
                                dependentCoverage: x.amount
                              })})
    }).then((res) => {
      // res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;{}</div>);
      setCostData(res.data);
      setGetCostLoader(false);
    })
    .catch((err) =>{
      toast.error(
        <div className='flex flex-row'>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{err?.title}
        </div>
      );      
      setGetCostLoader(false);      
    }
    );
  };

  const isEdit = () => {
    let edit = false;
    if(enrolledPlanData?.plan?.id == selectedPlan?.id){
       edit = true;
    }
    return edit;
  };

  const handleEnrollment = () => {
    const employee = selectedCoverageCards.filter(x => x.dependantId == employeeDetails.id);
    const dependants = selectedCoverageCards.filter(x => x.dependantId != employeeDetails.id);

    setEnrollLoader(true);

    if(isEdit()){ 
    APIList.editHealthInsuranceEnroll({
      id:enrolledPlanData?.id,
      benefitStartDate:benefitStartDate,
      employee:{
        id: employeeDetails.id
      },
      isDraft: true,
      status: "ISDRAFT",
      isEmployeeEnrolled: employee?.length > 0,
      selectedCoverage:{
        id: selectedPlan?.coveragePlans[0]?.id
      },
      employeeCoverage: (employee?.length > 0) ? employee[0].amount : null,
      plan:{
        id: selectedPlan.id,
        className: selectedPlan.className
      },
      enrollmentDate: new Date(),
      dependantEnrollments: dependants.map((x) => {return({
                                benefitStartDate:benefitStartDate,
                                dependants:{
                                  id: x.dependantId
                                },
                                dependantEnrollmentDate: new Date(),
                                selectedPlan:{
                                  id: x.planDependantId
                                },
                                dependentCoverage: x.amount
                              })})                        
   }).then((res) => {
     res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Enrolled in {selectedPlan?.displayName} successfully</div>);
     setEnrollLoader(false);
     history.push({ pathname: "/plans", state: { fromAddress: true } });
   })
   .catch((err) =>{
     toast.error(
       <div className='flex flex-row'>
         <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
         &nbsp;&nbsp;{err?.title}
       </div>
     );      
     setEnrollLoader(false);      
   }
   )
   }else{
    APIList.healthInsuranceEnroll({
       employee:{
         id: employeeDetails.id
       },
       benefitStartDate:benefitStartDate,
       isDraft: true,
       status: "ISDRAFT",
       isEmployeeEnrolled: employee?.length > 0,
       selectedCoverage:{
         id: selectedPlan?.coveragePlans[0]?.id
       },
       employeeCoverage: (employee?.length > 0) ? employee[0].amount : null,
       plan:{
         id: selectedPlan.id,
         className: selectedPlan.className
       },
       enrollmentDate: new Date(),
       dependantEnrollments: dependants.map((x) => {return({
                                 benefitStartDate:benefitStartDate,
                                 dependants:{
                                   id: x.dependantId
                                 },
                                 dependantEnrollmentDate: new Date(),
                                 selectedPlan:{
                                   id: x.planDependantId
                                 },
                                 dependentCoverage: x.amount
                               })})                        
    }).then((res) => {
      res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Enrolled in {selectedPlan?.displayName} successfully</div>);
      setEnrollLoader(false);
      // history.push({ pathname: "/plans", state: { fromAddress: true } });
      dispatch(getPlanActions.PlanDataRequest("clearData"));
      history.push({pathname:'/loading', state:{planName:selectedPlan?.displayName}});
    })
    .catch((err) =>{
      toast.error(
        <div className='flex flex-row'>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{err?.title}
        </div>
      );      
      setEnrollLoader(false);      
    }
    );
  }

  };

  const getIsValid = () => {
    let valid = false;
    if(selectedCategories?.includes("self")){
      if((individualSelectedDependantsList?.length + 1 - getNoOfDepdantsWithNoPlan()) === selectedCoverageCards?.length){
        valid = true;
      }
    }else{
      if((individualSelectedDependantsList?.length - getNoOfDepdantsWithNoPlan()) === selectedCoverageCards?.length){
        valid = true;
      }
    }

    return valid;
  };

  const getNoOfDepdantsWithNoPlan = () => {
    let arr = [];
    individualSelectedDependantsList?.forEach((dependant) => {
      if(getAllowedRatecards(dependant)?.length == 0){
        arr.push(dependant);
      } 
    });

    return arr?.length;
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<BsChevronDown sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'var(--tertiary)',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(270deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
 
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  
    const [expanded, setExpanded] = useState('');
  
    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div className="singleDependantIndividual w-full">
      <div
        className="w-full flex justify-between group mt-4 mb-5 bg-[var(--secondary)] px-4"
        style={{ paddingTop: 15, paddingBottom: 15 }}
      >
        <p className="text-lime-800 font-semibold text-xs">
          Individual: Each dependent has their own coverage limits.
          {/* <p style={{color:"rgba(0, 0, 0, 0.37)"}}>This plan covers your{(currentlyEnrolled[0] != "Self") ? `${" "}${currentlyEnrolled[currentlyInprocessEnrolled?.length]?.relation}(${currentlyEnrolled[currentlyInprocessEnrolled?.length]?.firstName})` : "self"}</p> */}
        </p>
      </div>

      {selectedCategories?.includes("self") && (
        <div className="mainCoverageSelection">
          <Accordion
            expanded={expanded === employeeDetails.id}
            onChange={handleChange(employeeDetails.id)}
          >
            <AccordionSummary
              expandIcon={<BsChevronDown />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="w-full">
              {
                    (selectedCoverageCards?.filter((x) => x.dependantId == employeeDetails.id)?.length > 0) ?
                    <span className="coverageSelectHead">

                      <span style={{color: "var(--secondary)", textTransform:"capitalize"}}> Self </span>

                      <span className="inline-flex">                      
                        <span>
                        {CurrencyFormat(selectedCoverageCards?.filter((x) => x.dependantId == employeeDetails.id)[0].amount, currency)}
                        </span>
                        <BsFillCheckCircleFill style={{color: "var(--primary)", margin:"0rem 0.25rem", fontSize:"1.15rem"}} />
                      </span>     

                    </span>        
                    :
                    <span className="coverageSelectHead1"> Select coverage for &nbsp;<span style={{color: "var(--secondary)", textTransform:"capitalize"}}> Self </span> </span>       
                }           
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {getSelfRatecards()?.length == 0 ? (
                <div className="my-16 text-center"> No plan available </div>
              ) : (
                <>
                  <div className="w-full flex flex-row mt-4 relative">
                    <div className="w-full mx-4 ml-4 costsHead">
                      Coverage Amount
                    </div>
                    <div className="w-full mx-4 ml-4 costsHead">
                      Employee Cost
                    </div>
                  </div>

                  {getSelfRatecards()?.map((ratecard) => {
                    return (
                      <div
                        key={ratecard.id}
                        // className="w-full flex flex-row card mt-4 relative"
                        className={classNames(
                          "w-full flex flex-row card mt-4 relative",
                          {
                            activeCard:
                              selectedCoverageCards?.filter(
                                (x) =>
                                  x.dependantId == employeeDetails.id &&
                                  x.coverageId == ratecard.id
                              ).length > 0,
                          }
                        )}
                      >
                        <div className="w-full flex justify-between items-center sm:mx-4 mr-2 cardInner">
                          <div className="left">
                            <p className="price">
                              {CurrencyFormat(ratecard.sumInsured, currency)}
                            </p>
                          </div>
                          <div className="right">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M23.0677 11.9929L18.818 7.75739L17.4061 9.17398L19.2415 11.0032L0.932469 11.0012L0.932251 13.0012L19.2369 13.0032L17.4155 14.8308L18.8321 16.2426L23.0677 11.9929Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="w-full flex justify-between items-center sm:mx-4 ml-2 cardInner2">
                          <div className="left">
                            <p className="price">                              
                              {getEmplyeeSplit(ratecard)}
                            </p>
                          </div>

                          {selectedCoverageCards?.filter(
                            (x) =>
                              x.dependantId == employeeDetails.id &&
                              x.coverageId == ratecard.id
                          ).length > 0 ? (
                            // <BsFillCheckCircleFill
                            //   style={{
                            //     width: "2rem",
                            //     height: "2rem",
                            //     color: "var(--primary)",
                            //   }}
                            // />
                            <button
                              className="coverageSelectButtonActive"
                              // onClick={() => handleSelectCoverageForSelf(ratecard)}
                            >
                              Select
                            </button>
                          ) : (
                            <button
                              className="coverageSelectButton"
                              onClick={() =>
                                handleSelectCoverageForSelf(ratecard)
                              }
                            >
                              Select
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      {individualSelectedDependantsList?.map((dependant) => {
        return (
          <div className="mainCoverageSelection">
            <Accordion
              expanded={expanded === dependant.id}
              onChange={handleChange(dependant.id)}
            >
              <AccordionSummary
                expandIcon={<BsChevronDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="w-full">
                
                  {
                    (selectedCoverageCards?.filter((x) => x.dependantId == dependant.id)?.length > 0) ?
                    <span className="coverageSelectHead">

                      <span style={{color: "var(--secondary)", textTransform:"capitalize"}}> {dependant.firstName} </span>

                      <span className="inline-flex">                      
                        <span>
                        {CurrencyFormat(selectedCoverageCards?.filter((x) => x.dependantId == dependant.id)[0].amount, currency)}
                        </span>
                        <BsFillCheckCircleFill style={{color: "var(--primary)", margin:"0rem 0.25rem", fontSize:"1.15rem"}} />
                      </span>     

                    </span>        
                    :
                    <span className="coverageSelectHead1"> Select coverage for &nbsp;<span style={{color: "var(--secondary)", textTransform:"capitalize"}}> {dependant.firstName} </span> </span>       
                }
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {getAllowedRatecards(dependant)?.length == 0 ? (
                  <div className="my-16 text-center"> No plan available </div>
                ) : (
                  <>
                    <div className="w-full flex flex-row mt-4 relative">
                      <div className="w-full mx-4 ml-4 costsHead">
                        Coverage Amount
                      </div>
                      <div className="w-full mx-4 ml-4 costsHead">
                        Employee Cost
                      </div>
                    </div>

                    {getAllowedRatecards(dependant)?.map((ratecard) => {
                      return (
                        <div
                          key={ratecard.id}
                          // className="w-full flex flex-row card mt-4 relative"
                          className={classNames(
                            "w-full flex flex-row card mt-4 relative",
                            {
                              activeCard:
                                selectedCoverageCards?.filter(
                                  (x) =>
                                    x.dependantId == dependant.id &&
                                    x.coverageId == ratecard.id
                                ).length > 0,
                            }
                          )}
                        >
                          <div className="w-full flex justify-between items-center sm:mx-4 mr-2 cardInner">
                            <div className="left">
                              <p className="price">
                                {CurrencyFormat(ratecard.sumInsured, currency)}
                              </p>
                            </div>
                            <div className="right">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M23.0677 11.9929L18.818 7.75739L17.4061 9.17398L19.2415 11.0032L0.932469 11.0012L0.932251 13.0012L19.2369 13.0032L17.4155 14.8308L18.8321 16.2426L23.0677 11.9929Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="w-full flex justify-between items-center sm:mx-4 ml-2 cardInner2">
                            <div className="left">
                              <p className="price">
                                {getDependantSplit(ratecard, dependant)}
                              </p>
                            </div>

                            {selectedCoverageCards?.filter(
                              (x) =>
                                x.dependantId == dependant.id &&
                                x.coverageId == ratecard.id
                            ).length > 0 ? (
                              // <BsFillCheckCircleFill
                              //   style={{
                              //     width: "2rem",
                              //     height: "2rem",
                              //     color: "var(--primary)",
                              //   }}
                              // />
                              <button
                                className="coverageSelectButtonActive"
                                // onClick={() => handleSelectCoverageForSelf(ratecard)}
                              >
                                Select
                              </button>
                            ) : (
                              <button
                                className="coverageSelectButton"
                                onClick={() => {
                                  handleSelectCoverage(ratecard, dependant);
                                }}
                              >
                                Select
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
      {
        <p className="linksOuter w-full flex justify-end mx-auto my-4">
          <span
            className="viewLink cursor-pointer"
            onClick={() => handleViewCost()}
          >
            View Cost
          </span>
        </p>
      }

      <div className="w-full">
        <p
          className="mt-2 peer-invalid:visible text-gray-400 text-sm"
          style={{ marginBottom: "-1rem" }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  checked={electionChecked}
                  onChange={() => setElectionChecked(!electionChecked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Click here to confirm"
            />
          </FormGroup>
        </p>
        <ButtonM
          text={
            <FormattedMessage id="common.button.PLANSUBMIT" defaultMessage="Save as Draft"/>
          }
          onpress={() => handleEnrollment()}
          disabled={!getIsValid() || !electionChecked}
          loading={enrollLoader}
          bgcolor={!getIsValid() || !electionChecked ? "gray" : ""}
        />
      </div>

      {viewCost && (
        <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-full h-full popupWrapper">
            <div className="totalCostPopup" ref={wrapperRef}>
              <GetCostVersion2
                onBack={() => setViewCost(false)}
                loader={
                  getCostLoader
                  // true
                }
                costData={costData}
                payCheckFrequency={getPayCheckFrequency(
                  selectedPlan,
                  employeeDetails
                )}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SingleDependant;
