import { Box } from '@mui/material';
import { useHistory } from "react-router-dom";
import ButtonM from '../../../../common/Button';
import PolicyCoverForPlans from 'components/common/PolicyCoverForPlans';

const PensionCover = () => {

    const history = useHistory();

    const handleNext = () => history.push('/pension-plan');

    return (
        <Box>
            <PolicyCoverForPlans />
            <div className="bottomButtonReq">
                <ButtonM text="NEXT" onpress={() => handleNext()} />
            </div>
        </Box>
    )
}


export default PensionCover;