const { createAction } = require('redux-actions');
const DEPENDANT_ENROLL_INFO_REQUEST = 'BENEVOLVE/DEPENDANT_ENROLL_INFO_REQUEST';
const DEPENDANT_ENROLL_INFO_REQUEST_SUCCESS = 'BENEVOLVE/DEPENDANT_ENROLL_INFO_REQUEST_SUCCESS';
const DEPENDANT_ENROLL_INFO_REQUEST_FAILED = 'BENEVOLVE/DEPENDANT_ENROLL_INFO_REQUEST_FAILED';

const DependantEnrollInfoRequest = createAction(DEPENDANT_ENROLL_INFO_REQUEST);
const DependantEnrollInfoRequestSuccess = createAction(DEPENDANT_ENROLL_INFO_REQUEST_SUCCESS);
const DependantEnrollInfoRequestFailed = createAction(DEPENDANT_ENROLL_INFO_REQUEST_FAILED);



export const actions = {
    DependantEnrollInfoRequest,
    DependantEnrollInfoRequestSuccess,
    DependantEnrollInfoRequestFailed,
};

export const types = {
    DEPENDANT_ENROLL_INFO_REQUEST,
    DEPENDANT_ENROLL_INFO_REQUEST_SUCCESS,
    DEPENDANT_ENROLL_INFO_REQUEST_FAILED,
    
};
