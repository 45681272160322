import success from "../../../../assets/enrollmentSuccess/success.png";
import loaderImg from "../../../../assets/enrollmentSuccess/loading.png";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { actions as EmployeeDataActions } from "../../Header/redux/actions";
import { actions as getPlanActions } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyNullUndefined } from "components/utils/utils";
import APIList from "api";
import { actions as BeneficiaryActions } from "../../../pages/AddBeneficiaryAfterPlanENroll/redux/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const LoadingPage = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const employeeDetails = useSelector((state) => state && state.empData);  
  // const { employeeEnrolled, employeeEnrolledLoader } = useSelector((state) => state && state.empData);
  // const loopPlans = useSelector((state) => state?.planReducer?.loopPlans);
  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const selectedPlanLoader = useSelector((state) => state?.planReducer?.loader);

  const [progress, setProgress] = useState(0);
  const [nextPlan, setNextPlan] = useState(null);
  const [loopPlans, setLoopPlans] = useState(null);
  // const [employeeEnrolled, setEmployeeEnrolled] = useState(null);

  useEffect(() => { 
    dispatch(BeneficiaryActions.TempBenAllocationInfo(null));
    APIList.getEmployeeEnrolled({ empId : employeeDetails?.empData?.id })
    .then((res) => {
      setLoopPlans((res?.data?.Plans?.length > 0) && (res?.data?.Plans?.filter((item) => (item.enrollmentStatus == 'No'))));
    })
    .catch((err) => {
      console.log(err);
    });

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    }; 
  }, []);  

  useEffect(() => {
    if(loopPlans !== null){
      if(loopPlans?.length > 0){
        setNextPlan(loopPlans[0]);
      }else{
        history.push("/plans");
      } 
    }       
  }, [loopPlans]);

  useEffect(() => {
    if(nextPlan !== null){
    if(nextPlan?.className === "reimbursements"){
      setTimeout(() => {
        history.push(`/reimbursement/enroll/${nextPlan?.planId}`)
      }, 2000);      
   } else {
      dispatch(
        getPlanActions.PlanDataRequest({
          className: nextPlan?.className,
          Id: nextPlan?.planId,
          empId: employeeDetails?.empData?.id,
          eventId: nextPlan?.eventId || null,
          lifeEventId: nextPlan?.lifeEventId || null
        })
      );
   } 
   }
  }, [nextPlan]) 

  useEffect(() => {
    setTimeout(() => {
      if(Object.keys(selectedPlan)?.length > 0){
        if ( selectedPlan?.planType === "Base" && selectedPlan?.benefits?.subBenefitType === "Medical Insurance Plan" ) {
          // history.push("/base-cover");
          history.push("/basicMedical");
        } else if ( selectedPlan?.planType === "Base" &&
          (selectedPlan?.benefits?.subBenefitType === "Life Insurance" ||
            selectedPlan?.benefits?.subBenefitType === "Disability Insurance" ||
            selectedPlan?.benefits?.subBenefitType === "Accident and Death")
        ) {
          // history.push("/life-insurance-cover");
          history.push("/life-insurance");
        } else if ( selectedPlan?.planType === "Voluntary" && selectedPlan?.benefits?.subBenefitType === "Medical Insurance Plan" ) {
          // history.push("/voluntry-cover");
          history.push("/voluntry");
        } else if ( selectedPlan?.planType === "Base" && selectedPlan?.benefits?.subBenefitType === "Healthcare Reimbursement Account" && selectedPlan?.className === "healthinsurance") {
          // history.push("/base-cover");
          history.push("/basicMedical");
        }else if ( selectedPlan?.planType === "Base" && selectedPlan?.className === "pension" ) {
          // history.push("/pension-cover");
          history.push("/pension-plan");
        }else if ( selectedPlan?.planType === "Base" && selectedPlan?.className === "displayplan" ) {
          // history.push("/display-plan-cover");
          history.push("/display-plan");
        } 
        // else {
        //   history.push("/home");
        // }
       }
    }, 2000);
   
  }, [selectedPlan])
  
return(
    <div className="h-full mx-auto flex flex-col justify-between">
        
    <div className="enrollmentSuccess">
      <div className="enrollmentSuccessInner">
        <img src={success} alt="Success" title="Success" class="successImg" />
        <p className="title">Submitted {location?.state?.planName} successfully</p>
        <p className="subTitle">Loading next PLAN</p>
        {/* <div className="boxOuter">
          <p className="text flex justify-between"> <span> BASIC MEDICAL </span>  <span>200 coins</span></p>
          <p className="text flex justify-between"><span>LIFE INSURANCE </span>  <span> 600 coins</span></p>
        </div> */}
        {/* <img src={loaderImg} alt="Loader" title="Loader" class="loaderImg" />
        <p className="loadingText">Landing to home page...please wait</p> */}
        <Box sx={{ width: '100%', marginTop:"1rem" }}>
          <LinearProgress sx={{height:"1rem", backgroundColor:"#e1f7e2", borderRadius:"1rem"}} color="success" variant="determinate" value={progress} />
        </Box>
      </div>
    </div>
    </div>
)
}

export default LoadingPage;