import { useEffect, useRef, useState } from "react";
import Rectangle from "../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../assets/radioCheck.svg";
import ButtonM from "../../common/Button";
import PageTitleAndDescription from "../../common/PageTitleAndDescription";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GetCostVersion2 from "../BasicMedical/ViewCost/Version2";
import APIList from "api";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { getCoveragePlan, getCoverageRule, getPayCheckFrequency } from "components/utils/utils";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";
import PrimaryForLifePage from "../AddBeneficiaryAfterPlanENroll/PrimaryForLifePage";
import { actions as getPlanActions } from "../../common/Plans/redux/actions";
import { FormattedMessage } from "react-intl";
var classNames = require('classnames');

const LifeInsurance = () => {
  const dispatch = useDispatch();
  const history = useHistory();  

  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  
  const [dontWantEnroll, setDontWantEnroll] = useState(false);
  const [enroll, setEnroll] = useState(true);
  const [viewCost, setViewCost] = useState(false);  
  const [getCostLoader, setGetCostLoader] = useState(false);
  const [coveragePlan, setCoveragePlan] = useState(null);
  const [costData, setCostData] = useState([]);
  const [enrollMentLoader, setEnrollMentLoader] = useState(false);
  const [PayCheckFreq, setPayCheckFreq] = useState(null);
  const [electionChecked, setElectionChecked] = useState(false);
  const enrolledPlanData = useSelector((state) => state?.planReducer?.enrolledPlanData);  
  const beneficiaries = useSelector((state) => state?.beneficiary?.beneficiaries);
  const benefitStartDate = useSelector((state) => state?.planReducer?.benefitStartDate);

  const [isAllowedForContengent, setIsAllowedForContengent] = useState(false);
  const [totalPercentagePrimary, setTotalPercentagePrimary] = useState(0);
  const [dontWantToAddBeneficiary, setDontWantToAddBeneficiary] = useState(false);
  const [addedBeneficiaries, setAddedBeneficiaries] = useState([]);
  
  useEffect(() => {
    if(Object.keys(selectedPlan)?.length == 0){
        history.push("/plans");
      }
  }, []);

  useEffect(() => {
    setCoveragePlan(getCoverageRule(selectedPlan, employeeDetails));
    if(selectedPlan?.autoRenewal) {
      setEnroll(true)
    }
  }, []);

  useEffect(() => {
    setPayCheckFreq(getPayCheckFrequency(selectedPlan, employeeDetails));
  }, [selectedPlan]);  

  const isSame = () => {
    if(addedBeneficiaries?.length > beneficiaries?.length){
     return false;
    }
    let same = true;
    addedBeneficiaries?.forEach((ab) => {
      beneficiaries && beneficiaries?.forEach((bb) => {
          if(addedBeneficiaries?.length === beneficiaries?.length){
           
            if(ab?.dependants?.id === bb?.dependants?.id){
              
              if(ab?.percentage != bb?.percentage){
               
                same = false;
              }
            }
          }else{
            if(Math.round(addedBeneficiaries?.filter((x) => x?.primary)?.reduce((a, b) => a + Number(b.percentage), 0)) == 100){
              same = false;
            }
          }
        })
    })

    return same;
  };
  
  function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          
          setViewCost(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);

  const isEdit = () => {
    let edit = false;
    if(enrolledPlanData?.plan?.id == selectedPlan?.id){
       edit = true;
    }
    return edit;
  };

  const handleAddEnroll = () => {
    setEnrollMentLoader(true);
    let benWithZeroPercentIsPresent = addedBeneficiaries?.filter((ben) => ben?.percentage === 0)?.length > 0 ? true : false;
      if(benWithZeroPercentIsPresent){
        toast.info(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;Beneficiary with 0 % is not allowed.
          </div>
        );
        setEnrollMentLoader(false);
      } else {
    if(!dontWantEnroll){
      if(Math.round(addedBeneficiaries?.filter((x) => x?.primary)?.reduce((a, b) => a + Number(b.percentage), 0)) == 100){
        if(isEdit()){ 
          APIList.editHealthInsuranceEnroll({
            id:enrolledPlanData?.id,
            employee:{
              id: employeeDetails.id
            },
            benefitStartDate: benefitStartDate,
            isDraft: true,
            status: "ISDRAFT",
            isEmployeeEnrolled: !dontWantEnroll,
            planOptOut:dontWantEnroll,
            selectedCoverageRules:
            // null,
            {
              id: dontWantEnroll ? null : coveragePlan?.id     
          },
            employeeCoverage: dontWantEnroll ? null : coveragePlan?.amount,
            plan:{
              id: selectedPlan.id,
              className: selectedPlan.className
            },
            enrollmentDate: new Date(),
      
          }).then((res) => {
            res &&
                toast.success(
                  <div className="flex flex-row">
                    <BsFillCheckCircleFill
                      style={{ width: "2rem", height: "2rem" }}
                    />
                    &nbsp;&nbsp;Updated Successfully
                  </div>
                );
                  addBenficiariesToPlan();
          })
          .catch((err) =>{
            toast.error(
              <div className='flex flex-row'>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
              </div>
            );      
            setEnrollMentLoader(false);      
          }
          );
        }else{
        APIList.healthInsuranceEnroll({
          employee:{
            id: employeeDetails.id
          },
          benefitStartDate: benefitStartDate,
          isDraft: true,
          status: "ISDRAFT",
          isEmployeeEnrolled: !dontWantEnroll,
          planOptOut:dontWantEnroll,
          selectedCoverageRules:
          // null,
          {
            id: dontWantEnroll ? null : coveragePlan?.id     
        },
          employeeCoverage: dontWantEnroll ? null : coveragePlan?.amount,
          plan:{
            id: selectedPlan.id,
            className: selectedPlan.className
          },
          enrollmentDate: new Date(),
    
        }).then((res) => {
          res &&
              toast.success(
                <div className="flex flex-row">
                  <BsFillCheckCircleFill
                    style={{ width: "2rem", height: "2rem" }}
                  />
                  &nbsp;&nbsp;Submitted Successfully
                </div>
              );
              addBenficiariesToPlan();
        })
        .catch((err) =>{
          toast.error(
            <div className='flex flex-row'>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
            </div>
          );      
          setEnrollMentLoader(false);      
        }
        );
      } 
      }else{
        toast.info(
            <div className="flex flex-row">
              <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
              &nbsp;&nbsp;
              {
                addedBeneficiaries?.length > 0 ?
                "Total of percentage is not 100%."
                :
                "Add at least one beneficiary"
              }
                         
            </div>
          );
          setEnrollMentLoader(false);
        }
    }else{
      if(isEdit()){ 
        APIList.editHealthInsuranceEnroll({
          id:enrolledPlanData?.id,
          employee:{
            id: employeeDetails.id
          },
          benefitStartDate: benefitStartDate,
          isDraft: true,
          status: "ISDRAFT",
          isEmployeeEnrolled: !dontWantEnroll,
          planOptOut:dontWantEnroll,
          selectedCoverageRules:
          // null,
          {
            id: dontWantEnroll ? null : coveragePlan?.id     
        },
          employeeCoverage: dontWantEnroll ? null : coveragePlan?.amount,
          plan:{
            id: selectedPlan.id,
            className: selectedPlan.className
          },
          enrollmentDate: new Date(),
    
        }).then((res) => {
          res &&
              toast.success(
                <div className="flex flex-row">
                  <BsFillCheckCircleFill
                    style={{ width: "2rem", height: "2rem" }}
                  />
                  &nbsp;&nbsp;Updated Successfully
                </div>
              );
              setEnrollMentLoader(false);
                dispatch(getPlanActions.PlanDataRequest("clearData")); 
                history.push('/plans');
        })
        .catch((err) =>{
          toast.error(
            <div className='flex flex-row'>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
            </div>
          );      
          setEnrollMentLoader(false);      
        }
        );
      }else{
      APIList.healthInsuranceEnroll({
        employee:{
          id: employeeDetails.id
        },
        benefitStartDate: benefitStartDate,
        isDraft: true,
        status: "ISDRAFT",
        isEmployeeEnrolled: !dontWantEnroll,
        planOptOut:dontWantEnroll,
        selectedCoverageRules:
        // null,
        {
          id: dontWantEnroll ? null : coveragePlan?.id     
      },
        employeeCoverage: dontWantEnroll ? null : coveragePlan?.amount,
        plan:{
          id: selectedPlan.id,
          className: selectedPlan.className
        },
        enrollmentDate: new Date(),
  
      }).then((res) => {
        res &&
            toast.success(
              <div className="flex flex-row">
                <BsFillCheckCircleFill
                  style={{ width: "2rem", height: "2rem" }}
                />
                &nbsp;&nbsp;Submitted Successfully
              </div>
            );
            setEnrollMentLoader(false);
              dispatch(getPlanActions.PlanDataRequest("clearData"));
              history.push({pathname:'/loading', state:{planName:selectedPlan?.displayName}});
      })
      .catch((err) =>{
        toast.error(
          <div className='flex flex-row'>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );      
        setEnrollMentLoader(false);      
      }
      );
    } 
    }  
    }  
  };

  const addBenficiariesToPlan = () => {
        APIList.addBeneficiaryAllocation({addedBeneficiaries})
        .then((res) => {
          setEnrollMentLoader(false);
          dispatch(getPlanActions.PlanDataRequest("clearData")); 
          if(isEdit()){
            history.push('/plans');
          }else{
            history.push({pathname:'/loading', state:{planName:selectedPlan?.displayName}});
          }          
              
          })
          .catch((err) => {
            toast.error(
              <div className="flex flex-row">
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;
                {/* {err?.message}             */}
                Enrolled in Plan, but beneficiaries are not added, you can add later.
              </div>
            );
          });
  }

  const handleViewCost = () => {

    setViewCost(true);

    setGetCostLoader(true);

    APIList.getCost({
      employee:{
        id: employeeDetails.id
      },
      isEmployeeEnrolled: true,
      selectedCoverage:
      // null,
      {
        id: coveragePlan?.id
      },
      employeeCoverage: 
      null,
      // selectedPlan?.coveragePlans[0]?.amount,
      plan:{
        id: selectedPlan.id,
        className: selectedPlan.className
      },
      enrollmentDate: new Date(),
    }).then((res) => {
      // res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;{}</div>);
      setCostData(res.data);
      setGetCostLoader(false);
    })
    .catch((err) =>{
      toast.error(
        <div className='flex flex-row'>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{err?.title}
        </div>
      );      
      setGetCostLoader(false);      
    }
    );

  };

  const handleDontWannaEnroll = () => {
    setDontWantEnroll(!dontWantEnroll)
    setEnroll(!enroll)
  }

  return (
    <div className="lifeInsurancePage">
      <h4 className="page-heading-navbar">{selectedPlan?.displayName}</h4>
      <PageHeaderForPlans />
      <div className="data">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <PageTitleAndDescription
              title={"Choose your plan"}
            // description={`Benelove offers you the option to add your dependents to the medical plan.`}
            />
            <div className="w-full">
              <div className="radioButtonOuter">
                { selectedPlan?.planOptOut &&
                <button onClick={() => handleDontWannaEnroll()} className={classNames("radioButton", { 'active': dontWantEnroll })}>
                  <div className="radioOuter">
                    <img src={Rectangle} alt="" className="radio" />
                    <img src={radioCheckIcon} alt="" className="radioCheck" />
                  </div>
                  I don't want to enroll
                </button>
                }
                {
                  !dontWantEnroll && 
                  <button 
                  // onClick={() => 
                  // !(selectedPlan?.autoRenewal) && selectedPlan?.planOptOut &&
                  // setEnroll(!enroll)} 
                  className={classNames("radioButton", { 'active': enroll })}>
                  <div className="radioOuter">
                    <img src={Rectangle} alt="" className="radio" />
                    <img src={radioCheckIcon} alt="" className="radioCheck" />
                  </div>
                  {employeeDetails?.firstName}{" "}{employeeDetails?.lastName}
                </button>
                }
                
              </div>
              <div className="w-full linksOuter">
                {/* <p className="viewLink" onClick={() => handleViewCost()}>
                  View Cost
                </p> */}
              </div>
            </div>          

            {
              enroll && selectedPlan?.benefits?.beneficiaryRequired && !dontWantToAddBeneficiary &&
              <div className="AddBeneficiaryMainPage">
              <PrimaryForLifePage 
                setIsAllowedForContengent={setIsAllowedForContengent} 
                setTotalPercentagePrimary={setTotalPercentagePrimary} 
                setDontWantToAddBeneficiary={setDontWantToAddBeneficiary}
                setAddedBeneficiaries={setAddedBeneficiaries}
                addedBeneficiaries={addedBeneficiaries}
                isEdit={isEdit()}
                addBenPath={"/life-insurance"}
              />
              </div>
            }

            <div className="peer-invalid:visible text-gray-400 text-sm">
              Employees have option to change beneficiary at any time
            </div>

            <div className="bottomButtonReq">
            <p
          className="mt-2 peer-invalid:visible text-gray-400 text-sm"
          style={{ marginBottom: "-1rem" }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  checked={electionChecked}
                  onChange={() => setElectionChecked(!electionChecked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Click here to confirm"
            />
          </FormGroup>
        </p>
              {/* <button className="next skip">I want to skip for now</button> */}
              <ButtonM 
              text={
                <FormattedMessage id="common.button.PLANSUBMIT" defaultMessage="Save as Draft"/>
              } 
              onpress={() => handleAddEnroll()} 
              disabled={(!dontWantEnroll && !enroll) || enrollMentLoader || !electionChecked} 
              loading={enrollMentLoader} 
              bgcolor={
                // dontWantEnroll === false &&
                  ((!dontWantEnroll && !enroll) || !electionChecked)
                  ? "gray"
                  : ""
              }
              />
            </div>
          </div>
        </div>
      </div>      

      {viewCost && (
          <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-full h-full popupWrapper">
            <div className="totalCostPopup" ref={wrapperRef}>
              <GetCostVersion2 
              onBack={() => setViewCost(false)}
              loader={
                getCostLoader
                // true
              }
              costData = {costData}
              payCheckFrequency={PayCheckFreq}
              />
            </div>
          </div>
        </div>
      
      )}

    </div>
  );
};

export default LifeInsurance;
