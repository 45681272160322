import React from 'react'

const index = ({pageName , PageHeading}) => {
  return (
    
    <div className="BMheader w-full px-2">
        <div className="flex flex-wrap">
            <div className="w-full">
                <div className="pageheaderdata">
                    <div className="pageheaderDataInneer w-4/5">
                        <h3 className="title">
                            {pageName}
                            
                        </h3>
                        <p className="desc">
                            {PageHeading}
                          
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

  )
}

export default index