
const PageTitleAndDescription = ({
  title,
  description,
}) => {
  return (
    <div className="PageTitle-And-Description w-full mt-4 mx-4">
      <div className="details">
        <h3 className="title mb-2">{title}</h3>
        {
          description &&
          <p className="subTitle">
          {description}{" "}
        </p>
        }
        
      </div>
    </div>
  );
};

export default PageTitleAndDescription;
