const { createAction } = require('redux-actions');
const COMPANY_PLAN_DATA_REQUEST = 'BENEVOLVE/COMPANY_PLAN_DATA_REQUEST';
const COMPANY_PLAN_DATA_REQUEST_SUCCESS = 'BENEVOLVE/COMPANY_PLAN_DATA_REQUEST_SUCCESS';
const COMPANY_PLAN_DATA_REQUEST_FAILED = 'BENEVOLVE/COMPANY_PLAN_DATA_REQUEST_FAILED';

const EVENT_DETAIL_REQUEST = 'BENEVOLVE/EVENT_DETAIL_REQUEST';
const EVENT_DETAIL_REQUEST_SUCCESS = 'BENEVOLVE/EVENT_DETAIL_REQUEST_SUCCESS';
const EVENT_DETAIL_REQUEST_FAILED = 'BENEVOLVE/EVENT_DETAIL_REQUEST_FAILED';
 

const POST_EVENT_DETAIL_REQUEST = 'BENEVOLVE/POST_EVENT_DETAIL_REQUEST';
const POST_EVENT_DETAIL_REQUEST_SUCCESS = 'BENEVOLVE/POST_EVENT_DETAIL_REQUEST_SUCCESS';
const POST_EVENT_DETAIL_REQUEST_FAILED = 'BENEVOLVE/POST_EVENT_DETAIL_REQUEST_FAILED';

const SELECT_EVENT_OPTION = "SELECT_EVENT_OPTION";
const CLEAR_SELECTED_EVENT_OPTION = "CLEAR_SELECTED_EVENT_OPTION";

const CompanyPlanDataRequest = createAction(COMPANY_PLAN_DATA_REQUEST);
const CompanyPlanDataRequestSuccess = createAction(COMPANY_PLAN_DATA_REQUEST_SUCCESS);
const CompanyPlanDataRequestFailed = createAction(COMPANY_PLAN_DATA_REQUEST_FAILED);

//event list request  
 
const EventDetailRequest = createAction(EVENT_DETAIL_REQUEST);
const EventDetailRequestSuccess = createAction(EVENT_DETAIL_REQUEST_SUCCESS);
const EventDetailRequestFailed = createAction(EVENT_DETAIL_REQUEST_FAILED);

//POST EVENT
const PostEventDetailRequest = createAction(POST_EVENT_DETAIL_REQUEST);
const PostEventDetailRequestSuccess = createAction(POST_EVENT_DETAIL_REQUEST_SUCCESS);
const PostEventDetailRequestFailed = createAction(POST_EVENT_DETAIL_REQUEST_FAILED);


//SELECT EVENT OPTION
const SelectEventOptionRequest = createAction(SELECT_EVENT_OPTION);
const ClearSelectedEventOptionRequest = createAction(
  CLEAR_SELECTED_EVENT_OPTION
);

export const actions = {
    CompanyPlanDataRequest,
    CompanyPlanDataRequestSuccess,
    CompanyPlanDataRequestFailed,

    EventDetailRequest,
    EventDetailRequestSuccess,
    EventDetailRequestFailed,

    PostEventDetailRequest,
    PostEventDetailRequestSuccess,
    PostEventDetailRequestFailed,

  SelectEventOptionRequest,
  ClearSelectedEventOptionRequest,
};

export const types = {
    COMPANY_PLAN_DATA_REQUEST,
    COMPANY_PLAN_DATA_REQUEST_SUCCESS,
    COMPANY_PLAN_DATA_REQUEST_FAILED,
    EVENT_DETAIL_REQUEST,
    EVENT_DETAIL_REQUEST_SUCCESS,
    EVENT_DETAIL_REQUEST_FAILED,
    POST_EVENT_DETAIL_REQUEST,
    POST_EVENT_DETAIL_REQUEST_SUCCESS,
  POST_EVENT_DETAIL_REQUEST_FAILED,
  SELECT_EVENT_OPTION,
  CLEAR_SELECTED_EVENT_OPTION,
};
