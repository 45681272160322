import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CurrencyFormat } from "../../../utils/numberFormatter";
import checkRight from "../../../../assets/HomeOld/checkOrange.svg";
import { BiMoney } from "react-icons/bi";
import lifeInsurance from "../../../../assets/HomeOld/lifeInsurance.png";
import dot from "../../../../assets/HomeOld/dot.png";
import { actions as employeePlanActions } from "../reedux/action";
import { Skeleton } from "@mui/material";
import { BsCheckAll } from "react-icons/bs";
import moment from "moment";

const AllPlansPage = () => {
  const location = useLocation();

  const [planId, setPLanId] = useState({ id: "", show: false });
  const dispatch = useDispatch();

  const loader = useSelector(
    (state) => state && state.empData && state.empData.loader
  );
  const company = useSelector((state) => state?.empData?.empData?.company?.displayName);
  const employeeDetails = useSelector((state) => state && state?.empData);
  const eligibleplans = useSelector((state) => state && state?.empData?.eligibleplans);
  const elligiblePlanLoader = useSelector((state) => state && state?.empData?.elligiblePlanLoader);

  const { loader: eventLoader, eventData } = useSelector(
    (state) => state?.homepageReducer
  );

  const currenyCode = employeeDetails?.empData?.company?.currency;
  const history = useHistory();
  const [selectedEnrollPlan, setEnrollPlan] = useState({
    displayName: "Group Health Insurance",
    enrollmentStatus: "yes",

    dependents: [],
  });

  const handelSelectEnrollement = (event) => {
    if(event?.className === "reimbursements"){
      history.push(`/reimbursement/${event?.planId}`)
   } else {
    setEnrollPlan({ ...selectedEnrollPlan, ...event });

    if (planId?.id === event.planId) {
      setPLanId({ ...planId, show: !planId.show });
    } else {
      setPLanId({ id: event.planId, show: true });
      const payloadBody = {
        employeeId: employeeDetails?.empData?.id,
        planClassname: event?.className,
        planId: event?.planId,
      };

      if (event?.enrollmentStatus?.toLowerCase() === "yes") {
        dispatch(
          employeePlanActions.EmployeePlanEnrollmentRequest(payloadBody)
        );
      }
    }
  }
  };

  return (
    <div className="homePage homePageOld">
      <h4 className="page-heading-navbar">{company}</h4>

      {(loader || elligiblePlanLoader)? (
        <div className=" grid grid-cols-4 gap-4 px-4 mt-8">

          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
        </div>
      ) : (
        <>
          {/* Old Home page content */}
          {elligiblePlanLoader ? (
            <div className=" grid grid-cols-3 gap-4 px-4 mt-8">

              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ height: { md: "125px", xs: "70px" } }}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ height: { md: "125px", xs: "70px" } }}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ height: { md: "125px", xs: "70px" } }}
              />
            </div>
          ) : (
            <div className="healthPlans px-4 mt-8 py-4">
              <div className="flex justify-between pb-4">
                <h1
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "1rem",
                    lineHeight: "18px",
                    background: "var(--tertiary)",
                    color: "var(--primary)",
                    padding: "2rem 20px",
                  }}
                  className="title "
                >
                  All Benefits{" "}
                </h1>
              </div>

              <ul class="tile_grid  block1 block2 gap-2 py-4">
                {eligibleplans?.length > 0 &&
                  eligibleplans?.map((val, index) => (
                    <>
                      <li>
                        <button
                          data-value={val?.displayName}
                          style={{ width: "100%" }}
                          onClick={() => handelSelectEnrollement(val)}
                          key={val?.planId}
                          className={
                            planId?.id === val?.planId && planId?.show
                              ? `boxShadow block-div color1
                                )}`
                              : `block-div color3`
                          }
                        >
                          <div className=" block2-image-div">
                            <img
                              src={val?.icon}
                              alt="Image1"
                              className="block-image"
                            />
                          </div>
                          <p className="block2-text">{val?.displayName}</p>
                          <p className="block2-sub_text">
                            {val?.enrollmentStatus?.toLowerCase() === "yes"
                              ? <span className="flex justify-center items-center" style={{color:"var(--primary)"}}> Enrolled <BsCheckAll style={{fontSize:"1.1rem"}} /> </span>
                              : "Not Enrolled"}{" "}
                          </p>
                        </button>
                      </li>

                      <li
                        id={selectedEnrollPlan?.displayName}
                        className={`${
                          planId?.id === val?.planId && planId?.show
                            ? "fullwidth"
                            : "is-hidden"
                        }`}
                      >
                        {eventLoader ? (
                          <div className=" grid grid-cols-1 gap-4 px-4 mt-8">
                            {/* <Loader size='100' /> */}

                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              sx={{ height: { md: "125px", xs: "70px" } }}
                            />
                          </div>
                        ) : (
                          <div className="box px-4 py-4 ">
                            <div className="flex justify-between">
                              <div className="flex sm:flex-row flex-col">
                                <img
                                  className="lifeInsurance invert"
                                  src={selectedEnrollPlan?.icon || lifeInsurance}
                                  alt="more"
                                />
                                <div className="px-4">
                                  <div>
                                    <h1 className="boxTitle">
                                      {selectedEnrollPlan?.displayName}
                                    </h1>
                                  </div>
                                  <div className="flex pt-2">
                                    <p className="mr-4">
                                      {selectedEnrollPlan?.enrollmentStatus?.toLocaleLowerCase() ===
                                      "yes"
                                        ? <span className="flex justify-center items-center" data-value="employee-is-enrolled"> Enrolled <BsCheckAll style={{fontSize:"1.1rem"}} /> </span>
                                        : "Not Enrolled"}{" "}
                                    </p>
                                    <button onClick={() =>
                                               history.push(`/new-learning-page-tabs/${selectedEnrollPlan?.className?.toLowerCase()}/${selectedEnrollPlan?.planId}`)
                                      } 
                                      className="more">More Details</button>
                                  </div>
                                </div>
                              </div>
                            
                              {selectedEnrollPlan?.enrollmentStatus?.toLocaleLowerCase() ===
                              "yes" ? (
                                <>  <div>
                                Benefit Start Date :{" "}
                                {moment(
                                  eventData?.benefitStartDate?.slice(0,10)
                                ).format(
                                  `${
                                    employeeDetails?.empData?.company
                                      ?.dateFormat
                                      ? employeeDetails?.empData?.company?.dateFormat?.toUpperCase()
                                      : "DD/MM/YYYY"
                                  }`
                                )}
                              </div>
                              <img
                                  className="checkRight"
                                  src={checkRight}
                                  alt="more"
                                />
                                </>
                                
                              ) : null}
                            </div>
                            <div className="grid grid-cols-2 gap-2 pt-8">
                              <div className="box1">
                                <div className="flex-1 borderR">
                                  {/* <p className="box1Title">Coverage Limit</p> */}
                                  <p className="box1Title">What is covered</p>
                                  <div
                                    className="flex pt-2"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <BiMoney />
                                    <span className="date ml-2">
                                      {selectedEnrollPlan?.enrollmentStatus?.toLocaleLowerCase() ===
                                        "yes" && eventData?.Coverage
                                        ? CurrencyFormat(
                                            eventData?.Coverage,
                                            currenyCode
                                          )
                                        : //  CurrencyFormat(500000,currenyCode)
                                          "No coverage Limit"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="box1">
                                {/* <p class="box1Title">Dependent</p> */}
                                <p class="box1Title">Who is covered</p>
                                <div class="grid grid-cols-2 gap-1 pt-2">
                                  {selectedEnrollPlan?.enrollmentStatus?.toLocaleLowerCase() ===
                                    "yes" &&
                                  eventData?.Dependants &&
                                  eventData?.Dependants?.length > 0 ? (
                                    eventData?.Dependants?.map((val, ind) => (
                                      <p key={ind} class="name flex">
                                        <img src={dot} alt="" className="dot" />
                                        {val?.Name}
                                      </p>
                                    ))
                                  ) : (
                                    <p>No dependent</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    </>
                  ))}

                {/* <button className="block-div color2">
                    <div className="block2-image-div">
                      <img src={dental} alt="Image1" className="block-image" />
                    </div>
                    <p className="block2-text">Dental</p>
                    <p className="block2-sub_text">Not Enrolled</p>
                  </button>
                  <button className="block-div color3">
                    <div className="block2-image-div">
                      <img src={vision} alt="Image1" className="block-image" />
                    </div>
                    <p className="block2-text">Vision</p>
                    <p className="block2-sub_text">Not Enrolled</p>
                  </button> */}
              </ul>
            </div>
          )}
          {/* <div className="healthPlans px-4 mt-8 py-4">



              <h1 className="moreBenefits">More Benefits</h1>
              <div class="moreBenefitsBox grid grid-cols-3 block1 block2 gap-2 py-4">
                <button className="block-div color4">
                  <div className="block2-image-div">
                    <img src={additionalInsurance} alt="Image1" className="block-image" />
                  </div>
                  <p className="block2-text">Additional Insurance</p>
                  <p className="block2-sub_text">Not Enrolled</p>
                </button>
                <button className="block-div color4">
                  <div className="block2-image-div">
                    <img src={shortTermDisabiity} alt="Image1" className="block-image" />
                  </div>
                  <p className="block2-text">Short Term Disabiity</p>
                  <p className="block2-sub_text">Not Enrolled</p>
                </button>
                <button className="block-div color4">
                  <div className="block2-image-div">
                    <img src={longTermDisabiity} alt="Image1" className="block-image" />
                  </div>
                  <p className="block2-text">Long Term Disabiity</p>
                  <p className="block2-sub_text">Not Enrolled</p>
                </button>
                <button className="block-div color4">
                  <div className="block2-image-div">
                    <img src={gymMembership} alt="Image1" className="block-image" />
                  </div>
                  <p className="block2-text">Gym Membership</p>
                  <p className="block2-sub_text">Not Enrolled</p>
                </button>
              </div>
            </div> */}
        </>
      )}
    </div>
  );
};

export default AllPlansPage;
