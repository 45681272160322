import { Button, CircularProgress, Skeleton } from "@mui/material";
import APIList from "api";
import moment from "moment";
import { useEffect, useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { RiDeleteBin5Fill, RiErrorWarningFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import arrowRight from "../../../../assets/reimbuersement/arrowRight.png";
import ReimModal from "../Modal/ReimModal";
import { reimburseActions as reimActions } from "../redux/action";

const ClaimHistory = () => {

  const dispatch = useDispatch();

  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const allClaimsData = useSelector((state) => state?.reimReducer?.allClaims);
  const allClaimLoader = useSelector((state) => state?.reimReducer?.allClaimLoader);

  const [claimData, setclaimData] = useState(null);
  const [claimsLoader, setclaimsLoader] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

    useEffect(() => {
      getAllClaims();
    }, []);

    const getAllClaims = () => {
      dispatch(
        reimActions.getAllClaimsRequest({
          id: employeeDetails?.id ,
        })
      ) 
    }

    const blockClick = (claim) => {
        setViewDetails(true);
        setclaimsLoader(true);

        APIList.getClaim({
          id: claim?.id
        }).then((res) => {
          setclaimData(res.data);
          setclaimsLoader(false);
        }).catch((err) => {
          toast.error(
            <div className="flex flex-row">
              <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
              &nbsp;&nbsp;
              {err?.message}            
            </div>
          );
          setclaimsLoader(false);
        })
      };

      const deleteClaim =(claim) => {
        setDeleteLoader(true);
        APIList.deleteClaim({
          id:claim?.id
        }).then((res) => {          
        getAllClaims();
        setDeleteLoader(false);
        }).catch((err) => {
          toast.error(
            <div className="flex flex-row">
              <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
              &nbsp;&nbsp;
              {err?.message}            
            </div>
          );
          setDeleteLoader(false);
        })
      }

    return(
        <div className="claimHistory m-4">
             <p className="mainTitle">Claims History ({allClaimLoader ? <CircularProgress size={20} /> :allClaimsData?.length})</p>
            <div className="cards">

              {
                allClaimLoader ? 
                
                  [ ...Array(4).keys() ].map((val) => {
                      return (
                          <div className="border border-gray-300 p-2 my-2 flex items-center justify-between">                              
                            <Skeleton key={val} variant="rounded" style={{ margin: "5px 1rem" }} animation="wave" sx={{ height: { md: "25px", xs: "20px" }, width:"50%" }} />
                            {/* <MdArrowForwardIos style={{color:"gray"}} /> */}
                          </div>
                      )
                  })
              :
                allClaimsData?.map((claim) => {
                  return (
                    <div className="cardsOuterOuter">
                    <div
                      className="cardsOuter"
                    >
                      <div className="left">
                        <p className="name">
                          {claim?.categoryRules?.map((x, index) => {
                            return(
                              <p>
                                {(typeof x === 'object') ? x?.categoryName : ""}{" "}
                              </p>
                            )
                          })}
                        </p>
                        <p className="date">
                          {moment(claim?.submittedDate?.slice(0,10)).format(
                            `${
                              employeeDetails?.empData?.company?.dateFormat
                                ? employeeDetails?.empData?.company?.dateFormat?.toUpperCase()
                                : "DD-MM-YYYY"
                            }`
                          )}
                        </p>
                        
                      </div>
                      
                      <div className="right">
                        <div className="claimBtns">
                        <div className="viewEvent"><Button variant="contained" onClick={() => blockClick(claim)}>View</Button></div>
                        {
                      claim?.approvalStatus == "Pending" && 
                      <div className="deleteIconEvent"><Button variant="contained" onClick={() => deleteClaim(claim)}>{deleteLoader ? <CircularProgress size={20} /> : "Cancel"}</Button></div>
                    } 
                        </div>
                        <div className="statusOuter">
                          <p className="status">Status</p>
                          {claim?.approvalStatus == "Pending" ? (
                            <div className="statusInner">
                              <p className="circle pending">&nbsp;</p>
                              <p className="statusDetails pending">
                                {claim?.approvalStatus}
                              </p>
                            </div>
                          ) : (
                            <div className="statusInner">
                              <p className="circle paid">&nbsp;</p>
                              <p className="statusDetails paid">
                                {claim?.approvalStatus}
                              </p>
                            </div>
                          )}
                        </div>
                        {/* <img
                          src={arrowRight}
                          alt="Line"
                          className="arrowRight"
                        /> */}
                      </div>
                      
                    </div>                                      
                    
                    </div>
                  );
                })
              }
              
            </div>
            {viewDetails && 
            <ReimModal setViewDetails={setViewDetails} 
            >

              {
                claimsLoader ?
                <div className="flex justify-center items-center h-full w-full">
                  <CircularProgress color="success" size={50} />
                </div>
                :
                <>
                <div className="statusOuter">
                  <p className="mainTitle">{claimData?.categoryRules?.map((x, index) => {
                            return(
                              <span>
                                {(typeof x === 'object') ? x?.categoryName : ""}{" "}
                              </span>
                            )
                          })}</p>

                  {claimData?.approvalStatus == "Pending" ? (
                  <div className="statusInner">
                    <p className="circle pending">&nbsp;</p>
                    <p className="statusDetails pending">
                      {claimData?.approvalStatus}
                    </p>
                  </div>
                ) : (
                  <div className="statusInner">
                    <p className="circle paid">&nbsp;</p>
                    <p className="statusDetails paid">
                      {claimData?.approvalStatus}
                    </p>
                  </div>
                )}               

                </div>
                <p className="title">Description</p>
                <p className="description">{claimData?.description}</p>
                <hr></hr>
                <div className="dataOuter">
                  <p className="title">Service Date</p>
                  <p className="description">
                  {moment(claimData?.createdDate?.slice(0,10))
                    .format(
                      `${
                        employeeDetails?.empData?.company?.dateFormat ?
                        employeeDetails?.empData?.company?.dateFormat?.toUpperCase() : 'DD-MM-YYYY'
                      }`
                  )}
                  </p>
                </div>
                <div className="dataOuter">
                  <p className="title">Submitted date</p>
                  <p className="description">
                  {moment(claimData?.submittedDate?.slice(0,10))
                    .format(
                      `${
                        employeeDetails?.empData?.company?.dateFormat ?
                        employeeDetails?.empData?.company?.dateFormat?.toUpperCase() : 'DD-MM-YYYY'
                      }`
                  )}
                  </p>
                </div>
                <div className="dataOuter">
                  <p className="title">Provider</p>
                  <p className="description">{claimData?.reimbursementsId?.serviceProvider?.name}</p>
                </div>
                <div className="dataOuter">
                  <p className="title">Receipt</p>
                  <p className="description">
                    {
                      claimData?.receipts?.length === 0 && 
                      "No receipts attached"
                    }                      
                  </p> 
                </div>
                <div className="receiptsImages">
                  {                    
                    claimData?.receipts?.map((rec) => {
                      return(
                        <img src={rec?.filePath?.path} alt="receipt" />
                      )
                    })                     
                  }
                </div>

                <div className="claimDetails dataOuter">
                  <div className="claimDetailsInner">
                    <p className="claimFor">Claim for</p>
                    <p className="claimPerson">{claimData?.categoryRules?.map((x, index) => {
                            return(
                              <span>
                                {(typeof x === 'object') ? x?.categoryName : ""}{" "}
                              </span>
                            )
                          })}</p>
                  </div>
                  <div className="claimDetailsInner">
                    <p className="amount">Amount</p>
                    <p className="price">{claimData?.amount}{" "}{employeeDetails?.company?.currency}</p>
                  </div>
                </div>
                </>
              }

               
              </ReimModal>
              }

        </div>
    )
}

export default ClaimHistory;