import { handleActions } from 'redux-actions';
import { types } from "./action"


const initialState = {
    planLoader: false,
    bannerLoader: false,
    eventData: {},
    error: {}
}

const actionHandler = {
    [types.EMPLOYEE_PLAN_ENROLLMENT_REQUEST]: (state) => ({
        ...state,
        planLoader: true,
    }),
    [types.EMPLOYEE_PLAN_ENROLLMENT_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        planLoader: false,
        eventData: payload || {},
    }),
    [types.EMPLOYEE_PLAN_ENROLLMENT_REQUEST_FAILED]: (state , {payload}) => ({
        ...state,
        planLoader: false,
        error:payload|| {title:"internal server error"}
    }),
    [types.COMPANY_BANNER_REQUEST]: (state) => ({
        ...state,
        bannerLoader: true,
    }),
    [types.COMPANY_BANNER_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        bannerLoader: false,
        bannerData: payload.map(a => a.fileDetails) || {},
    }),
    [types.COMPANY_BANNER_REQUEST_FAILED]: (state , {payload}) => ({
        ...state,
        bannerLoader: false,
        error:payload|| {title:"internal server error"}
    }),
    
};
export default handleActions(actionHandler, initialState);