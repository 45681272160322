import childCare from "../../../assets/reimbuersement/childCare.png";
import childCareActive from "../../../assets/reimbuersement/childCareActive.png";
import gym from "../../../assets/reimbuersement/gym.png";
import gymActive from "../../../assets/reimbuersement/gymActive.png";
import internet from "../../../assets/reimbuersement/internet.png";
import internetActive from "../../../assets/reimbuersement/internetActive.png";
import childImg from "../../../assets/reimbuersement/child.png";
import check from "../../../assets/carLeaseProgram/active.png";
import Rectangle from "../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../assets/radioCheck.svg";
import { useState } from "react";
import ButtonM from "components/common/Button";
import { useHistory } from "react-router-dom";

var classNames = require('classnames');

// const parse = require("html-react-parser");
const ReimbursementD = () =>{

  const history = useHistory();

  const [activeTab, setActiveTab] = useState("");
  const [activeItem, setActiveItem] = useState("");
  const [activeChild, setActiveChild] = useState("");

  return (
    <div>
      <div className="reimbursement p-4">
        <div>
          <div className="claimBlock">
            <span className="claimTitle" onClick={() => history.push("/claimHistoryD")}>Claim History</span>          
          </div>
          <div>
            <p className="mainTitle">Select the reimbursement types</p>
            <div className="grid grid-cols-3 gap-2 py-4 cardOuter">
              <div onClick={() => setActiveTab("child")} className={classNames("card",{'active': activeTab==='child'})}>
                <div className="imageOuter">
                  <img
                    src={childCare}
                    alt="Line"
                    className="cardImg"
                  />
                  <img
                    src={childCareActive}
                    alt="Line"
                    className="cardImg active"
                  />
                </div>
                <p className="title">Child Care</p>
                <img
                  src={check}
                  alt="Line"
                  className="activeImg"
                />
              </div>
              <div onClick={() => setActiveTab("gym")} className={classNames("card",{'active': activeTab==='gym'})}>
                <div className="imageOuter">
                  <img
                    src={gym}
                    alt="Line"
                    className="cardImg"
                  />
                  <img
                    src={gymActive}
                    alt="Line"
                    className="cardImg active"
                  />
                </div>
                <p className="title">Gym Membership</p>
                <img
                  src={check}
                  alt="Line"
                  className="activeImg"
                />
              </div>
              <div onClick={() => setActiveTab("internet")} className={classNames("card",{'active': activeTab==='internet'})}>
                <div className="imageOuter">
                  <img
                    src={internet}
                    alt="Line"
                    className="cardImg"
                  />
                  <img
                    src={internetActive}
                    alt="Line"
                    className="cardImg active"
                  />
                </div>
                <p className="title">Internet</p>
                <img
                  src={check}
                  alt="Line"
                  className="activeImg"
                />
              </div>

            </div>

            {
              activeTab != "" && 
              <div className="description">
                You are eligible to claim child care expense upto a limit of Rs 4500 per month for maximum of two kids
              </div>  
            }

            {
              activeTab != "" && 
              <div className="questions mt-6">
                What type of expense is this claim for?
              </div>  
            }

            {
              activeTab != "" && 
              <div className="grid grid-cols-3 gap-1 radioButtonOuter">
                <button onClick={() => setActiveItem("day")} className={classNames("radioButton",{'active': activeItem==='day'})}>
                  <div className="radioOuter">
                    <img src={Rectangle} alt="" className="radio" />
                    <img src={radioCheckIcon} alt="" className="radioCheck" />
                  </div>
                  Day Care
                </button>
                <button onClick={() => setActiveItem("nanny")} className={classNames("radioButton",{'active': activeItem==='nanny'})}>
                  <div className="radioOuter">
                    <img src={Rectangle} alt="" className="radio" />
                    <img src={radioCheckIcon} alt="" className="radioCheck" />
                  </div>
                  In home nanny support
                </button>
              </div>  
            }
            
            {
              activeItem != "" && 
              <>
              <div className="questions mt-4">
                Who was the expense spent on?             
              </div>  
              <div className="expenseSpendDivD">
                {
                  ["Child One", "Child Two"].map((child) => {
                    return(
                      <div 
                        onClick={() => setActiveChild(child)} 
                        className={classNames("childBlock expOptions",{'active': activeChild===child})}
                      >
                         <img
                            src={childImg}
                            alt="Line"
                            className="cardImg"
                          />
                        {child}
                        <img
                          src={check}
                          alt="Line"
                          className="activeImg"
                        />
                      </div>
                    )
                  })
                }                
                <div className="childBlock addOption">
                Add child +
                </div>
              </div>
              </>
            }
            {
              activeChild != "" &&
              <ButtonM text="UPDATE DETAILS" onpress={() => history.push("/updateDetailsD")} />

            }


          </div>
        </div>

      </div>

    </div>
  )
} 
export default ReimbursementD;