import React, { useEffect, useState } from 'react';
import ButtonM from "../../../common/Button";
import { AiFillInfoCircle } from "react-icons/ai"
import { FormHelperText, Skeleton, Stack, TextField } from "@mui/material";
import { actions as companyPlanActions } from "../redux/actions";
import Model from 'components/common/Model';
import { indigo } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"
import axios from "axios"
import glassImg from "../../../../assets/events/events_glasses.png"
import { combineActions } from 'redux-actions';
import { eventDetails } from "../../../utils/dummyData/data";
import { styled } from '@mui/material/styles';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { MdArrowForwardIos } from 'react-icons/md';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import APIList from "api";
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { RiErrorWarningFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isEmptyNullUndefined } from 'components/utils/utils';

    // ***** For Accordian *****
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<MdArrowForwardIos sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
  // ***** Accordian code end ***** 


const Report = () => {

  var pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - 29);

  var pastDateVal = new Date();
  pastDateVal.setDate(pastDate.getDate() - 31);
   
    // ***** For Accordian *****
    const [expanded, setExpanded] = React.useState('');
    const [selectedEvent, setSelectedEvent] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel?.id : false);
    setSelectedEvent(newExpanded ? panel : null);
  };
  // ***** Accordian code end ***** 

    const history = useHistory();
    const dispatch = useDispatch();
    const state = useSelector((state) => state);

    const [date, setDate] = useState({ value: "", eventId:"" });
    
    const companyDetails = state && state.empData && state.empData.empData && state.empData.empData.company;
    const companyPlandata = state?.companyPlanReducer;
    const EmployeeData = state && state.empData;

    const { loader, companyPlanData: data } = companyPlandata;

    const Events = data ?? [];

    useEffect(() => {
        getEventList();
    }, [])

    const getEventList = () => {
        dispatch(companyPlanActions.CompanyPlanDataRequest({
            companyId: companyDetails?.id,
        }))
    }    

const [eventCreateLoader, setEventCreateLoader] = useState(false);

const handelClick = () => {
  setEventCreateLoader(true);
    APIList.registerEvent({
      employee: {
        id: EmployeeData?.empData?.id,
      },
      event: {
        id: expanded,
      },
      eventDate: new Date(date.value).toISOString(),
    })
      .then((res) => {
        res &&
          toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;
              {
                !isEmptyNullUndefined(selectedEvent) && 
                selectedEvent?.name?.toLowerCase()?.includes("marriage") ? 
                "You will now be able to make necessary change to your enrollment. However please ensure to update marital status in your HR portal."
                :
                "Life event reported sucessfully"
              }              
            </div>
          );
        setEventCreateLoader(false);
        history.push({ pathname: "/plans" });
      })
      .catch((error) => {        
        setEventCreateLoader(false);
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{error?.message}
          </div>
        );
      });
  };

  const [dateErr, setDateErr] = useState(false);

  const change = (e, id) => {
    setDate({ value: e, eventId:id });
    if(new Date(e) > new Date() || new Date(e) < pastDateVal){
      setDateErr(true);
    }else{
      setDateErr(false);
    }
  };
  const colorStyle = {
    color: "var(--primary)",
  };

    let skel = [1, 2, 3, 4, 5, 6];

    return (
        <div className='reportAEventPage'>
            {
                loader ? <div className=" grid grid-cols-1 px-4 mt-8">

                    {
                        skel.map((val) => {
                            return (
                                <div className="border border-gray-300 p-2 my-2 flex items-center">
                                    <MdArrowForwardIos style={{color:"gray"}} />
                                <Skeleton key={val} variant="rounded" style={{ margin: "5px 1rem" }} animation="wave" sx={{ height: { md: "25px", xs: "20px" }, width:"50%" }} />
                                </div>
                            )
                        })
                    }

                </div> : <>
                    <div className="page-heading-navbar">
                        <h4 >Report a life event</h4>
                    </div>


                    <p className="font_bold  block-text mx-4  mt-4" style={{ color: "var(--primary)" }}>Select the event you want to report</p>
                    
                    <p className='reportNote'>NOTE : Report a single life event.</p>

                    <div className='eventList mx-4 mt-4'>
                        <FormControl style={{ width: "100%" }}>
                            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="event"
                                name="radio-buttons-group"
                            >
                                {
                                    Events?.map((val) => {
                                        return (
                                           <Stack sx={{marginTop:"1rem"}}>
                                            <Accordion expanded={expanded === val?.id} onChange={handleChange(val)} data-value={val?.name}>
                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                              <div className="accorEventName">{val?.name}</div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                            <form className="mx-4">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    inputFormat= {companyDetails?.dateFormat || "dd/MM/yyyy"}
                                                    maxDate={new Date()}
                                                    minDate={pastDate}
                                                    value={(date.eventId != val.id) ? null : date.value}
                                                    id={val.id}
                                                    style={colorStyle}
                                                    label="Date of Event"
                                                    name="dateofEvent"
                                                    onChange={(e) => change(e, val.id)}
                                                    renderInput={(params) => (
                                                    <TextField
                                                        name="date-of-Event"
                                                        variant="outlined"
                                                        sx={{
                                                        my: 2,
                                                        width: "100%",
                                                        color: colorStyle.color,
                                                        backgroundColor: "#ffffff",

                                                        "& fieldset": {
                                                            borderColor: "#979797 !important",
                                                        },
                                                        // "& .MuiOutlinedInput-notchedOutline": {
                                                        //     borderColor: "#979797 !important",
                                                        // },
                                                        " & label ": {
                                                            color: "var(--primary) !important",
                                                        },
                                                        " & input ": {
                                                            color: "var(--primary) !important",
                                                        },
                                                        // "& .MuiInputBase-input:focus": {
                                                        //     border: "none", // focus
                                                        // },
                                                        // "& .css-k4qjio-MuiFormHelperText-root": {
                                                        //     backgroundColor: "#ffffff",
                                                        //     margin: "0px",
                                                        //     paddingLeft: "0.5rem",
                                                        // },
                                                        }}
                                                        {...params}
                                                    />
                                                    )}
                                                />
                                                {
                                                  dateErr && 
                                                  <FormHelperText sx={{ color: "#d32f2f" }}>
                                                    Please enter proper date
                                                  </FormHelperText>
                                                }
                                                </LocalizationProvider>
                                                
                                            </form>
                                            <div className="bottomButtonReq" data-value={`${val?.name} button`}>
                                                <ButtonM text="REPORT A LIFE EVENT" 
                                                bgcolor={((date?.value == "") || (date.eventId != val.id)) || dateErr ? "#f6de97" : ""} 
                                                disabled={((date?.value == "") || (date?.eventId != val.id) || eventCreateLoader || dateErr)} 
                                                loading={eventCreateLoader}
                                                onpress={handelClick} />
                                            </div>
                                            </AccordionDetails>
                                          </Accordion>
                                          </Stack>
                                        )
                                    })
                                }

                            </RadioGroup>
                        </FormControl>
                    </div>
                </>

            }

        </div>
    )
}

export default Report