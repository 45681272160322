import dummyIcon from '../../assets/Used/balance.png';

export const relations = ["Mother","Father","Siblings","Spouse","Child","FIL","MIL"];

export const relationsNew = ["Parent","Siblings","Spouse","Child","PIL"];

export const relationsBen = ["Mother","Father","Siblings","Spouse","Child","FIL","MIL", "Trust", "Estate"];

export const idType = [{name:"NRIC",value:"NRIC"},{name:"Passport",value:"Passport"}, {name:"FIN Number",value:"FINNumber"}];

export const genders = [{name: "Male", value: "MALE"},{name: "Female", value: "FEMALE"}, {name: "Others", value: "OTHERS"},
//  {name: "Do not wish to disclose", value: "DO_NOT_WISH_TO_DISCLOSE"}
];

export const isEmptyNullUndefined = (value) => {
  if (
    value === undefined ||
    value === "" ||
    (value && value.toString().trim() === "") ||
    value === null
  ) {
    return true;
  } else {
    return false;
  }
};

export const getPayCheckFrequency = (selectedPlan, employeeDetails) => {
    let payFreq = null;
    
      selectedPlan?.payCheckRules?.forEach((payRule) => {
          payRule?.grade?.forEach((grade) => {
            if (
              grade?.id === employeeDetails?.employeeRole?.id
            ) {
              payFreq = payRule?.payCheckFrequency;
            }
          });
      });

    return payFreq;
  };

export const getWaitingPeriod = (selectedPlan) => {

  let waitPeriod = null;
  
    selectedPlan?.waitingRules?.forEach((x) => {
        x?.event?.forEach((y) => {
        if (y.name === "New Joiner") {
          // console.log("gfgfgfgfgfgf",x);
          waitPeriod = x.waitingPeriod ;
        }
      })
    })

  return waitPeriod;  
};

export const yellowText = (text) => {
  // console.log("lllllll",text);
   return <span style={{color: "var(--secondary)"}}>{text}</span>
};

export const getCoveragePlan = (selectedPlan, employeeDetails) => {
  let cplan = null;
  
    selectedPlan?.coveragePlans?.forEach(
      (x) => x?.employeeRoles?.forEach(
          (y) =>{
            if(y.id === employeeDetails?.employeeRole?.id) {
              if(x?.relation?.includes("Self")) {
                cplan = x
              }            
        }
        })
    );

  return cplan;  
};
export const getCoverageRule = (selectedPlan, employeeDetails) => {
  let cplan = null;
  
    selectedPlan?.coverageRules?.forEach(
      (x) => x?.employeeRoles?.forEach(
          (y) =>{
            if(y.id === employeeDetails?.employeeRole?.id) {
            cplan = x
        }
        })
    );

  return cplan;  
};

export const getPensionCoverageRule = (selectedPlan, employeeDetails) => {
  let cplan = null;
  
    selectedPlan?.pensionCoverageRules?.forEach(
      (x) => x?.employeeRoles?.forEach(
          (y) =>{
            if(y.id === employeeDetails?.employeeRole?.id) {
            cplan = x
        }
        })
    );

  return cplan;  
};

export const getReimCoverageRule = (selectedPlan, employeeDetails) => {
  let cplan = null;
  
    selectedPlan?.reimbursementCoverageRules?.forEach(
      (x) => x?.employeeRoles?.forEach(
          (y) =>{
            if(y.id === employeeDetails?.employeeRole?.id) {
            cplan = x
        }
        })
    );

  return cplan;  
};

export const getRatecard = (selectedPlan, coveragePlan) => {
  let cards = [];
    selectedPlan?.rateCard?.rateCardItems?.forEach((card) => {
      if (
         card?.sumInsured === coveragePlan?.amount
      ) {
         cards.push(card);
      }
    });
    return cards;
};

export const getElligiblePlanDependant = (selectedPlan, employeeDetails) => {
  let legitDependents = [];

    selectedPlan?.coveragePlans?.forEach(
      (x) =>
        x?.employeeRoles?.forEach(
          (y) =>{
            if(y?.id === employeeDetails?.employeeRole?.id ){
            legitDependents.push(x)
        }
      }
        )
    );
  return legitDependents.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
};

export const getAllowedEmp = (empDependant, elligiblePlanDependant, lifeEventConfiguratorDTO) => {
  let allowed = [];
  let eventConfigRelations = lifeEventConfiguratorDTO?.relationships;

  if(eventConfigRelations?.includes("Parent")){
    eventConfigRelations = [...eventConfigRelations, "Mother","Father"]
  }
  if(eventConfigRelations?.includes("PIL")){
    eventConfigRelations = [...eventConfigRelations, "MIL","FIL"]
  }
// console.log("eventConfigRelations", eventConfigRelations)
  if((lifeEventConfiguratorDTO != null) && (Object.keys(lifeEventConfiguratorDTO)?.length > 0) && !eventConfigRelations?.includes("NA")){
    empDependant?.forEach((dep) => {        
      elligiblePlanDependant?.forEach((pd) => {
        let relii = pd?.relation;
        if(relii.includes("Parent")){
          relii = [...relii, "Mother","Father"]
        }
        if(relii.includes("PIL")){
          relii = [...relii, "MIL","FIL"]
        }
        if(eventConfigRelations?.includes("NA")){
          // if (
          //   (getAge(dep.dateOfBirth) <
          //   (pd.maxAge !== null ? pd.maxAge : 1000)) &&
          //   (getAge(dep.dateOfBirth) > (pd.minAge !== null ? pd.minAge : -1))
          // ) {
            allowed.push(dep);
          // }
        } else if (relii?.includes(dep.relation) && eventConfigRelations?.includes(dep.relation)) {
          // if (
          //   (getAge(dep.dateOfBirth) <
          //   (pd.maxAge !== null ? pd.maxAge : 1000)) &&
          //   (getAge(dep.dateOfBirth) > (pd.minAge !== null ? pd.minAge : -1))
          // ) {
            allowed.push(dep);
          // }
        }
      });
  });
  }else{
    empDependant?.forEach((dep) => {        
        elligiblePlanDependant?.forEach((pd) => {
          let relii = pd?.relation;
          if(relii.includes("Parent")){
            relii = [...relii, "Mother","Father"]
          }
          if(relii.includes("PIL")){
            relii = [...relii, "MIL","FIL"]
          }
          if(relii.includes("NA")){
            // if (
            //   (getAge(dep.dateOfBirth) <
            //   (pd.maxAge !== null ? pd.maxAge : 1000)) &&
            //   (getAge(dep.dateOfBirth) > (pd.minAge !== null ? pd.minAge : -1))
            // ) {
              allowed.push(dep);
            // }
          }else
          if (relii.includes(dep.relation)) {
            // if (
            //   (getAge(dep.dateOfBirth) <
            //   (pd.maxAge !== null ? pd.maxAge : 1000)) &&
            //   (getAge(dep.dateOfBirth) > (pd.minAge !== null ? pd.minAge : -1))
            // ) {
              allowed.push(dep);
            // }
          }
        });
    });
    }
  return allowed?.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
};

export const getNoOfDependantEnrollFromThisPlan = (planDependant, enrolledDependants) => {

  const arr = enrolledDependants?.map(x => x.relation);

  let relation = planDependant?.relation;

  // if(relation.includes("Parent")){
  //    relation = [...relation, "Mother","Father"]
  // }

  // if(relation.includes("PIL")){
  //    relation = [...relation, "MIL","FIL"]
  // }
  
  const reqArr = arr?.filter(x => relation?.includes(x));
// console.log("asasasasasaasaasasas",planDependant, enrolledDependants)
  return reqArr.length
};

export const getPlanIcon = (enrolledData, id) => {

  let icon = dummyIcon;

  enrolledData?.forEach((x) => {
    if(x?.planId == id){
      icon = x.icon
    }
  });

  return icon;

};

export const getPlanDependantWithRespectToRelation = (rel, elligiblePlanDependant) => {
  let dep = [];

  elligiblePlanDependant?.forEach((x) => {
    if(x.relation?.includes("PIL") && ["FIL", "MIL"].includes(rel)){
      dep = x;
    }
    if(x.relation?.includes("Parent") && ["Mother", "Father"].includes(rel)){
      dep = x;
    }
    if(x.relation?.includes(rel) || x.relation?.includes("NA")){
      dep = x;
    }
})

  return dep;
};

// used in voluntry first page to identify if any relation is present in more than one selection.
export const getInValidRelations = (selectedRelations) => {
  let selectedRel = [];
    if(selectedRelations.filter(x => x == "Parent").length > 1){
      return ["Parent"];
    }
    if(selectedRelations.filter(x => x == "PIL").length > 1){
      return ["PIL"];
    }
    if(selectedRelations?.includes("Parent")){
      const ppArr = selectedRelations.filter(x => x != "Parent");
      selectedRel = [...ppArr, "Mother", "Father"];
    }else if(selectedRelations?.includes("PIL")){
      const ppArr = selectedRelations.filter(x => x != "PIL");
      selectedRel = [...ppArr, "MIL", "FIL"];
    }else{
      selectedRel = selectedRelations;
    }
    
    let arr = (selectedRel?.length > 0) && selectedRel?.map((x) => {
        if(selectedRel.filter(y => y == x).length > 1){
           return x;
        } else{
          return "";
        }
      });

    let arr1 = (arr?.length > 0) && arr?.filter(x => x != "");
    // console.log("arr1",arr1)

 return (arr1?.length > 0) ? arr1?.filter((v,i,a)=>a.findIndex(t=>(t === v))===i) : [];
};


export const getcurrentDependant = (selectedCategories) => {
  let arr = [];

  selectedCategories?.forEach(x => x.split("-").forEach(y => arr.push(y)));
  if(arr?.includes("Parent")){
    arr = arr?.filter(x => x !="Parent");
    arr.push("Mother","Father");
  }
  if(arr?.includes("PIL")){
    arr = arr?.filter(x => x !="PIL");
    arr.push("FIL","MIL");
  }
  return arr;

} ;

export const getRelationName = (item) => {
  if(item == "PIL"){
    return "Parents-in-law";
  }
  if(item == "MIL"){
    return "Mother-in-law";
  }
  if(item == "FIL"){
    return "Father-in-law";
  }
 
  return item;
};
export const getRelationNames = (item) => {
  let itemR = item;
  if(itemR?.includes("PIL")){
    itemR = itemR?.filter((x) => x !== "PIL");
    itemR.push("Parent-in-law");
  }
  return itemR?.filter((x) => x !== "Self");
};

export const getEventName = (employeeEnrolled, id) => {

  return employeeEnrolled?.filter(x => x.planId === id)[0].eventName;

}

// export function getAge(dateString) {
//   var today = new Date();
//   var birthDate = new Date(dateString);
//   var age = today.getFullYear() - birthDate.getFullYear();
//   var m = today.getMonth() - birthDate.getMonth();
//   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//     age--;
//   }
//   return age;
// };

export function getAge(dateString) {
  // var today = new Date();
  // var DOB = new Date(dateString);
  // var totalMonths = (today.getFullYear() - DOB.getFullYear()) * 12 + today.getMonth() - DOB.getMonth();
  // totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
  // var years = today.getFullYear() - DOB.getFullYear();
  // if (DOB.getMonth() > today.getMonth())
  //     years = years - 1;
  // else if (DOB.getMonth() === today.getMonth())
  //     if (DOB.getDate() > today.getDate())
  //         years = years - 1;

  // var days;
  // var months;

  // if (DOB.getDate() > today.getDate()) {
  //     months = (totalMonths % 12);
  //     if (months == 0)
  //         months = 11;
  //     var x = today.getMonth();
  //     switch (x) {
  //         case 1:
  //         case 3:
  //         case 5:
  //         case 7:
  //         case 8:
  //         case 10:
  //         case 12: {
  //             var a = DOB.getDate() - today.getDate();
  //             days = 31 - a;
  //             break;
  //         }
  //         default: {
  //             var a = DOB.getDate() - today.getDate();
  //             days = 30 - a;
  //             break;
  //         }
  //     }

  // }
  // else {
  //     days = today.getDate() - DOB.getDate();
  //     if (DOB.getMonth() === today.getMonth())
  //         months = (totalMonths % 12);
  //     else
  //         months = (totalMonths % 12) + 1;
  // }
  // if(years == 0 && months != 0){
  //   return months/12;
  // }else
  // if(years == 0 && months == 0){
  //   return days/365;
  // }else{
  //   return years;
  // }
 
  // Create a Date object from the provided date string
  const birthDate = new Date(dateString);
  
  // Get the current date
  const currentDate = new Date();
  
  // Calculate the difference in milliseconds between the current date and the birth date
  const ageInMillis = currentDate - birthDate;
  
  // Convert the milliseconds to years (assuming a year has 365.25 days)
  const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
  
  // Return the age in decimal format with two decimal places
  return ageInYears.toFixed(7);
};

export const getAllowedEmpReim = (empDependant, elligiblePlanDependant) => {
  let allowed = [];

    empDependant?.forEach((dep) => {        
        elligiblePlanDependant?.forEach((pd) => {
          let relii = pd?.relationships;
          if(relii.includes("Parent")){
            relii = [...relii, "Mother","Father"]
          }
          if(relii.includes("PIL")){
            relii = [...relii, "MIL","FIL"]
          }
          if(relii.includes("NA")){
            if (
              (getAge(dep.dateOfBirth) <
              (pd.maxAge !== null ? pd.maxAge : 1000)) &&
              (getAge(dep.dateOfBirth) > (pd.minAge !== null ? pd.minAge : -1))
            ) {
              allowed.push(dep);
            }
          }else
          if (relii.includes(dep.relation)) {
            if (
              (getAge(dep.dateOfBirth) <
              (pd.maxAge !== null ? pd.maxAge : 1000)) &&
              (getAge(dep.dateOfBirth) > (pd.minAge !== null ? pd.minAge : -1))
            ) {
              allowed.push(dep);
            }
          }
        });
    });
    
  return allowed?.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
};


export const requiredStyled = {
  // backgroundColor: 'white',
  // mb: 2,
  // width: {xs: '100%', xl: '60%', md: '75%'},
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderLeftColor: 'red',
      borderLeftWidth: 3,
    },
  },
};

export const requiredSelectStyled = {
  // backgroundColor: 'white',
  // mb: 2,
  // width: {xs: '100%', xl: '60%', md: '75%'},
  '& fieldset': {
    borderLeftColor: 'red',
    borderLeftWidth: 3,
  },
};

export const isDependantFinalised = (data) => {

  let finalised = false;

  !isEmptyNullUndefined(data) && data?.forEach((plan) => {
    if (plan?.dependantEnrollmentStatus === "Finalized") {
      finalised = true;
    } else {
      finalised = false;
    }
  });

  return finalised;
 
};

export const isShowExitPopup = (isShow) => {
  if(
    isShow && 
       (window.location.pathname === "/plans") 
    || (window.location.pathname === "/loading") 
    || (window.location.pathname === "/voluntry") 
    || (window.location.pathname === "/voluntry/add-coverage-type") 
    || (window.location.pathname === "/voluntry/bundle-add-parents-new") 
    || (window.location.pathname === "/voluntry/bundle-coverage-selection-new") 
    || (window.location.pathname === "/voluntry/bundle-final-new") 
    || (window.location.pathname === "/voluntry/familyAddParents") 
    || (window.location.pathname === "/voluntry/FamilyCoverageSelection") 
    || (window.location.pathname === "/life-insurance") 
    || (window.location.pathname === "/voluntry/bundle-final-new-edit") 
    || (window.location.pathname === "/voluntry/bundle-add-parents-new-edit") 
    || (window.location.pathname === "/voluntry/bundle-coverage-selection-new-edit") 
    || (window.location.pathname === "/adddependent") 
    || (window.location.pathname === "/adddependentaddress") 
    || (window.location.pathname === "/reimburseAddDependant") 
    || (window.location.pathname === "/reimburseAddDependantAddress") 
    || (window.location.pathname === "/beneficiaryAddDependent") 
    || (window.location.pathname === "/beneficiaryAddDependantAdress") 
    || (window.location.pathname === "/voluntry/bundle-addDep-new-edit") 
    || (window.location.pathname === "/voluntry/bundle-addDepAddress-new-edit") 
    || (window.location.pathname === "/voluntry/bundle-addDep-new") 
    || (window.location.pathname === "/voluntry/bundle-addDepAddress-new") 
    || (window.location.pathname === "/voluntry/bundle-add-dependant") 
    || (window.location.pathname === "/voluntry/bundle-add-dependant-address") 
    || (window.location.pathname === "/voluntry/family-add-dependant") 
    || (window.location.pathname === "/voluntry/family-add-dependant-address") 
    || (window.location.pathname === "/voluntry/adddependent") 
    || (window.location.pathname === "/voluntry/adddependentaddress") 
    || (window.location.pathname === "/add-beneficiary") 
    || (window.location.pathname === "/add-beneficiary-address") 
    || (window.location.pathname.includes("basicMedical")) 
  ){
    return true;
  } else {
    return false;
  }
}

export const isFromAddress = () => {
  if (
    window.location.pathname === "/reimburseAddDependantAddress"
    || window.location.pathname === "/beneficiaryAddDependantAdress"
    || window.location.pathname === "/voluntry/bundle-addDepAddress-new-edit"
    || window.location.pathname === "/voluntry/bundle-addDepAddress-new"
    || window.location.pathname === "/voluntry/bundle-add-dependant-address"
    || window.location.pathname === "/voluntry/family-add-dependant-address"
    || window.location.pathname === "/voluntry/adddependentaddress"
    ) {
    return true;
  } else {
    return false;
  }
};

export const week = [
  {key: 'SUNDAY', name: 'SUNDAY', value: 'SUNDAY'},
  {key: 'MONDAY', name: 'MONDAY', value: 'MONDAY'},
  {key: 'TUESDAY', name: 'TUESDAY', value: 'TUESDAY'},
  {key: 'WEDNESDAY', name: 'WEDNESDAY', value: 'WEDNESDAY'},
  {key: 'THURSDAY', name: 'THURSDAY', value: 'THURSDAY'},
  {key: 'FRIDAY', name: 'FRIDAY', value: 'FRIDAY'},
  {key: 'SATURDAY', name: 'SATURDAY', value: 'SATURDAY'},
]

export const getIdListFromObjects = (objectList, fieldName) => {
  const idList = [];
  if (objectList != null && objectList != undefined) {
    objectList.forEach((item) => {
      if (item[fieldName]) {
        idList.push(item[fieldName]);
      }
    });
  }
  return idList;
};

