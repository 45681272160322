import React from "react";
import successUpload from "../../../../assets/carLeaseProgram/uploadSuccess.png";

const DocUploadSuccessful = () => {
  return (
    <div>
      <div className="carLeaseProgram p-4">
        <div className="p-4">
          <div>
            <div className="successs">
              <img src={successUpload} alt="" className="successUpload" />
              <p className="text1">
                Congratulations your document has been successfully upload
              </p>
              <p className="text2">
                Please click on view cost to see cost sechudule
              </p>
              <a href="#" className="viewCost">
                VIEW COST
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocUploadSuccessful;
