import { toast } from "react-toastify";
import APIList from "api";
import { reimburseTypes, reimburseActions } from "./action";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { call, put, takeLatest } from 'redux-saga/effects';

const getReimbursePlan = function* getReimbursePlan({ payload }) {
    try {
        const { data } = yield call(APIList.getPlanDetails, payload);
        if (data) {
            yield put(reimburseActions.getReimbursePlanRequest_success(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(reimburseActions.getReimbursePlanRequest_failed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(reimburseActions.getReimbursePlanRequest_failed());
    }
};
const getAllClaimsRequest = function* getAllClaimsRequest({ payload }) {
    try {
        const { data } = yield call(APIList.getAllReimClaims, payload);
        if (data) {
            yield put(reimburseActions.getAllClaimsRequestSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(reimburseActions.getAllClaimsRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(reimburseActions.getAllClaimsRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(reimburseTypes.GET_REIMBURSE_PLAN_REQUEST, getReimbursePlan);
    yield takeLatest(reimburseTypes.GET_ALL_CLAIMS_REQUEST, getAllClaimsRequest);
}