import React, { Suspense, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory, useLocation } from "react-router-dom";
import Header from "../components/common/Header";
import Dependents from "components/pages/Dependents";
import Footer from "../components/common/Footer";
import EnrollmentIntro from "../components/pages/EnrollmentIntro";
import LogIn from "../components/pages/Login";
import ViewChat from "components/pages/Inbox/view-case";
import BasicMedical from "../components/pages/BasicMedical";
import Events from "../components/pages/Events/index";
import Report from "../components/pages/Events/Report/index";
import Beneficiaries from "../components/pages/Beneficiaries";
import Home from "../components/pages/Home";
import SelectPlans from "../components/common/Plans";
import Inbox from "../components/pages/Inbox";
import ContactUs from "../components/pages/ContactUs";
import Profile from "../components/pages/Profile";
import AddDependent from "../components/pages/BasicMedical/AddDependent";
import AddDependentAddress from "../components/pages/BasicMedical/AddDependentAddress";
import Voluntry from "../components/pages/Voluntry";
import VoluntryAddDependent from "../components/pages/Voluntry/IndividualType/AddDependent";
import VoluntryAddDependentAddress from "../components/pages/Voluntry/IndividualType/AddDependentAddress";

import VoluntryAddParents from "../components/pages/Voluntry/IndividualType/AddParents";
import CoverageType from "../components/pages/Voluntry/IndividualType/CoverageType/index";
import Disability from "../components/pages/Disability";
import LongTermDisability from "../components/pages/Disability/LongTerm";
import ShortTermDisability from "../components/pages/Disability/ShortTerm";
import LifeInsurance from "../components/pages/LifeInsurance";
import GroupCover from "../components/pages/BasicMedical/Cover/GroupMedical";
import { actions as logInActions } from "../components/pages/Login/redux/actions";
import ForgotPassword from "../components/pages/ForgotPassword";
import ResetPassword from "../components/pages/ForgotPassword/ResetPassword";
import LifeInsuranceCover from "../components/pages/LifeInsurance/Cover";
import PublicRoute from "./PublicRoute";
import UpdateProfile from "components/pages/Profile/components/UpdateProfile";
import MyDocuments from "components/pages/Documents";
import VoluntryCover from "components/pages/Voluntry/Cover";
import LongTermCover from "components/pages/Disability/LongTerm/Cover";
import ShortTermCover from "components/pages/Disability/ShortTerm/Cover";
import EditVolParents from "components/pages/Voluntry/IndividualType/AddParents/Edit/Edit";
import FamilyTypeAddParents from "components/pages/Voluntry/FamilyType/AddParents";
import FamilyCoverageSelection from "components/pages/Voluntry/FamilyType/FamilyCoverageSelection";
import BundleTypeAddParents from "components/pages/Voluntry/BundleType/AddParents";
import VoluntryBundleAddDependent from "components/pages/Voluntry/BundleType/AddDependent";
import VoluntryBundleAddDependentAddress from "components/pages/Voluntry/BundleType/AddDependentAddress";
import BundleCoverageType from "components/pages/Voluntry/BundleType/BundleCoverage";
import BundleIndividualPlanFinal from "components/pages/Voluntry/BundleType/BundleCoverage/IndividualPlan/IndividualPlanFinal";
import EditBundleTypeAddParents from "components/pages/Voluntry/BundleType/AddParents/Edit";
import EditBundleCoverageType from "components/pages/Voluntry/BundleType/BundleCoverage/Edit/Edit";
import EditBasicMedical from "components/pages/BasicMedical/EditBasicMedical";
import AddDependentFamily from "components/pages/Voluntry/FamilyType/AddDependent";
import AddDependentAddressFamily from "components/pages/Voluntry/FamilyType/AddDependentAddress";
import PensionCover from "components/pages/AllPlans/Pension/Cover";
import PensionPlan from "components/pages/AllPlans/Pension";
import DisplayPlanCover from "components/pages/AllPlans/DisplayPlan/Cover";
import DisplayPlan from "components/pages/AllPlans/DisplayPlan";
import EventLogs from "components/common/EventLogs";
import AllPlansPage from "components/pages/Home/AllPlansPage";
import Reimbursement from "components/pages/Reimbursement";
import CarLeaseProgram from "components/pages/CarLeaseProgram";
import { useState } from "react";
import ClaimHistory from "components/pages/Reimbursement/ClaimHistory";
import UpdateDetails from "components/pages/Reimbursement/UpdateDeatails";
import UploadDocuments from "components/pages/CarLeaseProgram/UploadDocuments";
import DocUploadSuccessful from "components/pages/CarLeaseProgram/Success";
import AddDependentReim from "components/pages/Reimbursement/AddDependent";
import AddDependentAddressReim from "components/pages/Reimbursement/AddDependentAddress";
import BenefitSummary from "components/pages/BenefitSummary";
import ReimbursementEnroll from "components/pages/Reimbursement/Enroll";
import CoinWizards from "components/pages/CoinFlow";
import SuccessPage from "components/pages/CoinFlow/SuccessPage";
import CoinTable from "components/pages/CoinFlow/CoinTable";
import PlanEnroll from "components/pages/CoinFlow/PlanEnroll";

import NewLearningPages from "components/pages/newLearn";
import NewLearnTabs from "components/pages/newLearn/PlanLearn";
import LoadingPage from "components/common/Plans/LoadingPage";

import messages_en from "../translations/en.json";
import messages_es from "../translations/es.json";
import messages_hi from "../translations/hi.json";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
import hi from "react-intl/locale-data/hi";
import { addLocaleData, IntlProvider } from "react-intl";
import ReimbursementD from "components/pages/ReimDemo";
import ClaimHistoryD from "components/pages/ReimDemo/ClaimHistory";
import UpdateDetailsD from "components/pages/ReimDemo/UpdateDeatails";
import NiumIndiaBundleTypeAddParents from "components/pages/Voluntry/BundleType/NiumIndiaFlow/AddParents";
import NiumIndBundleCoverageSelection from "components/pages/Voluntry/BundleType/NiumIndiaFlow/CoverageSelection";
import NiumIndBundleFinal from "components/pages/Voluntry/BundleType/NiumIndiaFlow/FinalPage";
import VoluntryBundleAddDependentNew from "components/pages/Voluntry/BundleType/NiumIndiaFlow/AddDependent";
import VoluntryBundleAddDependentAddressNew from "components/pages/Voluntry/BundleType/NiumIndiaFlow/AddDependentAddress";
import NiumIndiaBundleTypeAddParentsEdit from "components/pages/Voluntry/BundleType/NiumIndiaFlowEdit/AddParents";
import NiumIndBundleCoverageSelectionEdit from "components/pages/Voluntry/BundleType/NiumIndiaFlowEdit/CoverageSelection";
import NiumIndBundleFinalEdit from "components/pages/Voluntry/BundleType/NiumIndiaFlowEdit/FinalPage";
import VoluntryBundleAddDependentNewEdit from "components/pages/Voluntry/BundleType/NiumIndiaFlowEdit/AddDependent";
import VoluntryBundleAddDependentAddressNewEdit from "components/pages/Voluntry/BundleType/NiumIndiaFlowEdit/AddDependentAddress";
import AddBeneficiary from "components/common/AddBeneficiary/AddBen";
import AddBeneficiaryAddress from "components/common/AddBeneficiary/AddAddress";
import BenAllocation from "components/pages/Beneficiaries/BenAllocation";
import Calender from "components/common/Calender";
import { isEmptyNullUndefined } from "components/utils/utils";

const AppRouters = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userToken = useSelector(
    (state) => state && state.user && state.user.userToken
  );
  const companyLanguage = useSelector((state) => state?.empData?.empData?.company?.defaultLanguage);
  const userLanguage = useSelector((state) => state?.empData?.empData?.defaultLanguage);

  const [notfcnIntrvlId, setNotfcnIntrvlId] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    if (
      userToken == null &&
      !["/forgot-password", "/account/reset/finish","/login"].includes(location.pathname)
    ) {
      history.push("/login");
    }
  }, [userToken]);

  const language = !isEmptyNullUndefined(userLanguage) ? userLanguage : companyLanguage;
  
  addLocaleData([...en, ...es, ...hi]);
  const messages = {
    es: messages_es,
    en: messages_en,
    hi: messages_hi,
  };

  return (
    <Suspense fallback={<div></div>}>
       <IntlProvider
          locale={language}
          messages={messages[language]}
          onError={(err) => {
            if (err && err.code === "MISSING_TRANSLATION") {
              console.warn("Missing translation", err && err.message);
              return;
            }
          }}
        >
          <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
      />
      <div>
        {window.location.pathname !== "/login" &&
          window.location.pathname !== "/" &&
          // window.location.pathname !== "/base-cover" &&
          window.location.pathname !== "/forgot-password" &&
          // window.location.pathname !== "/life-insurance-cover" &&
          // window.location.pathname !== "/voluntry-cover" &&
          // window.location.pathname !== "/disability/long-term-cover" &&
          window.location.pathname !== "/account/reset/finish" && 
          (
            <Header
              setNotfcnIntrvlId={setNotfcnIntrvlId}
              routeName={window.location.pathname}
            />
          )}

        <div
          className="mx-auto bg-white"
          style={{
            paddingBottom: "6rem",
            paddingTop: "3rem",
            width: "100%",
            minHeight: "100vh",
            maxWidth: "768px",
            position: "relative",
          }}
        >
          <Route exact path="/login" component={LogIn} />
          <Route exact path="/login/:xyz" component={LogIn} />
          <Route exact path="/" component={LogIn} />
          {/* Adding "restricted" to PublicRoute component means you cannot access route if you are logged in */}
          <PublicRoute
            restricted
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PublicRoute
            restricted
            exact
            path="/account/reset/finish"
            component={ResetPassword}
          />

          <Route exact path="/home" component={Home} />
          {/* <Route exact path="/homeOld" component={HomeOld} /> */}
          <Route exact path="/plans" component={SelectPlans} />
          <Route exact path="/loading" component={LoadingPage} />
          <Route exact path="/inbox" component={Inbox} />
          <Route exact path="/contactus" component={ContactUs} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/update-profile" component={UpdateProfile} />
          <Route exact path="/enrollmentIntro" component={EnrollmentIntro} />
          <Route exact path="/base-cover" component={GroupCover} />
          {/* <Route exact path="/choose-group-plan" component={ChoosePlan} /> */}
          <Route exact path="/basicMedical" component={BasicMedical} />
          <Route exact path="/basicMedical/:id" component={EditBasicMedical} />
          <Route exact path="/all-benefits" component={AllPlansPage} />

          {/* benCoin start */}
          <Route exact path="/coinWizards" component={CoinWizards} />
          <Route exact path="/SuccessPage" component={SuccessPage} />
          <Route exact path="/CoinTable" component={CoinTable} />
          <Route exact path="/PlanEnroll" component={PlanEnroll} />
          {/* benCoin start */}

          {/* beneficiary new flow start */}          
          <Route exact path="/my-beneficiaries" component={Beneficiaries} />
          <Route exact path="/my-beneficiaries-allocation/:id" component={BenAllocation} />
          {/* beneficiary new flow end */}

          {/* <Route
            exact
            path="/my-beneficiaries/landing-page"
            component={BeneficiariesLanding}
          /> */}

          <Route exact path="/adddependent" component={AddDependent} />
          <Route
            exact
            path="/adddependentaddress"
            component={AddDependentAddress}
          />
          <Route exact path="/voluntry-cover" component={VoluntryCover} />
          <Route exact path="/voluntry" component={Voluntry} />
          <Route
            exact
            path="/voluntry/add-parents"
            component={VoluntryAddParents}
          />
          <Route
            exact
            path="/voluntry/adddependent"
            component={VoluntryAddDependent}
          />
          <Route
            exact
            path="/voluntry/adddependentaddress"
            component={VoluntryAddDependentAddress}
          />
          <Route
            exact
            path="/voluntry/add-coverage-type"
            component={CoverageType}
          />
          {/* <Route
            exact
            path="/voluntry/group-plan-final"
            component={GroupPlanFinal}
          /> */}
          {/* <Route
            exact
            path="/voluntry/ask-for-individual"
            component={AskForIndividual}
          /> */}
          {/* <Route
            exact
            path="/voluntry/individual-plan-final"
            component={IndividualPlanFinal}
          /> */}
          <Route
            exact
            path="/voluntry/edit-dependant/:category"
            component={EditVolParents}
          />
          {/* <Route
            exact
            path="/voluntry/edit-coverageType"
            component={EditCoverageType}
          /> */}
          <Route
            exact
            path="/voluntry/familyAddParents"
            component={FamilyTypeAddParents}
          />
          {/* <Route
            exact
            path="/voluntry/familyPlanFinal"
            component={FamilyPlanFinal}
          /> */}
          <Route
            exact
            path="/voluntry/FamilyCoverageSelection"
            component={FamilyCoverageSelection}
          />
          <Route
            exact
            path="/voluntry/family-add-dependant"
            component={AddDependentFamily}
          />
          <Route
            exact
            path="/voluntry/family-add-dependant-address"
            component={AddDependentAddressFamily}
          />
          <Route
            exact
            path="/voluntry/bundle-add-parents"
            component={BundleTypeAddParents}
          />          
          <Route
            exact
            path="/voluntry/bundle-add-dependant"
            component={VoluntryBundleAddDependent}
          />
          <Route
            exact
            path="/voluntry/bundle-add-dependant-address"
            component={VoluntryBundleAddDependentAddress}
          />
          <Route
            exact
            path="/voluntry/BundleCoverageType"
            component={BundleCoverageType}
          />
          <Route
            exact
            path="/voluntry/EditBundleCoverageType"
            component={EditBundleCoverageType}
          />
          <Route
            exact
            path="/voluntry/BundleIndividualPlanFinal"
            component={BundleIndividualPlanFinal}
          />
          <Route
            exact
            path="/voluntry/bundle-edit-parents/:category"
            component={EditBundleTypeAddParents}
          />

          {/* Bundle Nium India flow start */}

          <Route
            exact
            path="/voluntry/bundle-add-parents-new"
            component={NiumIndiaBundleTypeAddParents}
          />
          <Route
            exact
            path="/voluntry/bundle-coverage-selection-new"
            component={NiumIndBundleCoverageSelection}
          />
          <Route
            exact
            path="/voluntry/bundle-final-new"
            component={NiumIndBundleFinal}
          />
          <Route
            exact
            path="/voluntry/bundle-addDep-new"
            component={VoluntryBundleAddDependentNew}
          />
          <Route
            exact
            path="/voluntry/bundle-addDepAddress-new"
            component={VoluntryBundleAddDependentAddressNew}
          />

                        {/* edit  */}

          <Route
            exact
            path="/voluntry/bundle-add-parents-new-edit"
            component={NiumIndiaBundleTypeAddParentsEdit}
          />
          <Route
            exact
            path="/voluntry/bundle-coverage-selection-new-edit"
            component={NiumIndBundleCoverageSelectionEdit}
          />
          <Route
            exact
            path="/voluntry/bundle-final-new-edit"
            component={NiumIndBundleFinalEdit}
          />
          <Route
          exact
          path="/voluntry/bundle-addDep-new-edit"
          component={VoluntryBundleAddDependentNewEdit}
        />
        <Route
          exact
          path="/voluntry/bundle-addDepAddress-new-edit"
          component={VoluntryBundleAddDependentAddressNewEdit}
        />

          {/* Bundle Nium India flow end */}

          <Route exact path="/disability" component={Disability} />
          <Route
            exact
            path="/disability/long-term-cover"
            component={LongTermCover}
          />
          <Route
            exact
            path="/disability/long-term"
            component={LongTermDisability}
          />
          <Route
            exact
            path="/disability/short-term-cover"
            component={ShortTermCover}
          />
          <Route
            exact
            path="/disability/short-term"
            component={ShortTermDisability}
          />
          <Route
            exact
            path="/life-insurance-cover"
            component={LifeInsuranceCover}
          />
          <Route exact path="/life-insurance" component={LifeInsurance} />
          {/* <Route
            exact
            path="/addBeneficiariesAterPlanEnroll"
            component={AddBeneficiaryAfterPlanENroll}
          /> */}
          {/* <Route
            exact
            path="/beneficiaryAddDependent"
            component={BeneficiaryAddDependent}
          />
          <Route
            exact
            path="/beneficiaryAddDependantAdress"
            component={BeneficiaryAddDependentAddress}
          /> */}
          {/* <Route exact path="/add-beneficiary" component={AddBeneficiary} /> */}
          {/* <Route
            exact
            path="/life-insurance/choose-beneficiary"
            component={ChooseBeneficiary}
          /> */}
          <Route exact path="/pension-cover" component={PensionCover} />
          <Route exact path="/pension-plan" component={PensionPlan} />
         <Route
            exact
            path="/display-plan-cover"
            component={DisplayPlanCover}
          />
          <Route exact path="/display-plan" component={DisplayPlan} />

          
          

          <Route exact path="/my-documents" component={MyDocuments} />

          <Route exact path="/inbox/view-case/:id" component={ViewChat} />

          <Route exact path="/events" component={Events} />
          <Route exact path="/my-dependents" component={Dependents} />
          <Route exact path="/event/event-report" component={Report} />
          
          {/** EVENT LOGS */}
          <Route exact path="/event-log" component={EventLogs} />

          {/** reimbursement start */}       
          <Route exact path="/reimbursement/:id" component={Reimbursement} />
          <Route exact path="/reimbursement/:type/:id" component={ReimbursementEnroll} />
          <Route exact path="/claimHistory" component={ClaimHistory} />
          <Route exact path="/updateDetails" component={UpdateDetails} />
          <Route exact path="/reimburseAddDependant" component={AddDependentReim} />
          <Route exact path="/reimburseAddDependantAddress" component={AddDependentAddressReim} />
          {/** reimbursement end */} 

          {/** reimbursement demo start */}       
          <Route exact path="/reimbursementD" component={ReimbursementD} />
          <Route exact path="/claimHistoryD" component={ClaimHistoryD} />
          <Route exact path="/updateDetailsD" component={UpdateDetailsD} />
          {/* <Route exact path="/reimburseAddDependant" component={AddDependentReim} />
          <Route exact path="/reimburseAddDependantAddress" component={AddDependentAddressReim} /> */}
          {/** reimbursement demo end */}   

          {/** benefit summary start */}       
          <Route exact path="/benefit-summary" component={BenefitSummary} />
          {/** benefit summary end */}  

          {/** beneficiary start */}       
          <Route exact path="/add-beneficiary" component={AddBeneficiary} />
          <Route exact path="/add-beneficiary-address" component={AddBeneficiaryAddress} />
          {/** beneficiary end */}   

          <Route exact path="/carLeaseProgram" component={CarLeaseProgram} />
         
          <Route
            exact
            path="/carLeaseProgram/upload-car-lease-docs"
            component={UploadDocuments}
          />
          <Route
            exact
            path="/carLeaseProgram/upload-car-lease-docs-success"
            component={DocUploadSuccessful}
          />

        <Route exact path="/new-learning-page" component={NewLearningPages} />
        <Route exact path="/new-learning-page-tabs/:classname/:id" component={NewLearnTabs} />

        <Route exact path="/calender" component={Calender} />

        </div>
        {window.location.pathname !== "/login" &&
          window.location.pathname !== "/" &&
          window.location.pathname !== "/base-cover" &&
          window.location.pathname !== "/disability/long-term-cover" &&
          window.location.pathname !== "/life-insurance-cover" &&
          window.location.pathname !== "/forgot-password" &&
          window.location.pathname !== "/account/reset/finish" && (
            <Footer notfcnIntrvlId={notfcnIntrvlId} />
          )}
      </div>
      </>
      </IntlProvider>
    </Suspense>
  );
};

export default AppRouters;
