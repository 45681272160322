import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.VOLUNTRY_ENROLL_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.VOLUNTRY_ENROLL_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        voluntryEnroll: payload || {},
    }),
    [types.VOLUNTRY_ENROLL_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),

    [types.SET_SELECTED_CATEGORIES]: (state, { payload }) => ({
        ...state,
        selectedCategories: payload,
    }),
    [types.SET_PROGRESS_ARRAY]: (state, { payload }) => ({
        ...state,
        progressArray: payload,
    }),
    [types.SET_RATECARD_SELECTION]: (state, { payload }) => ({
        ...state,
        selectedRatecards: payload,
    }),
    [types.SET_CURRENTLY_ENROLLED]: (state, { payload }) => ({
        ...state,
        currentlyEnrolled: payload,
    }),
    [types.SET_CURRENTLY_INPROCESS_ENROLLED]: (state, { payload }) => ({
        ...state,
        currentlyInprocessEnrolled: payload,
    }),
    [types.ALL_SELECTED_PLAN_DEPENDANTS]: (state, { payload }) => ({
        ...state,
        selectedPlanDependants: payload,
    }),
    [types.ALL_SELECTED_COVERAGES]: (state, { payload }) => ({
        ...state,
        selectedCoverages: payload,
    }),
    [types.SET_FAMILY_SELECTED_COVERAGES]: (state, { payload }) => ({
        ...state,
        familySelectedCoverages: payload,
    }),
    [types.SET_BUNDLE_ENROLL_LIST]: (state, { payload }) => ({
        ...state,
        bundleEnrolls: payload,
    }),
    [types.SET_INDIVIDUAL_SELECTED_DEPENDANTS_LIST]: (state, { payload }) => ({
        ...state,
        individualSelectedDependantsList: payload,
    }),



    [types.SET_DEPENDENT_NAME]: (state, { payload }) => ({
        ...state,
        dependentname: payload,
    }),
    [types.SET_PARENT_FLOW]: (state, { payload }) => ({
        ...state,
        isParentFlow: payload,
    }),
    [types.SET_ONLY_PARENT_FLOW]: (state, { payload }) => ({
        ...state,
        onlyParentFlow: payload,
    }),
    [types.SET_MULTIPLE_DEPENDENT]: (state, { payload }) => ({
        ...state,
        multipleDependent: payload,
    }),
    [types.SET_GROUP_SELECTION]: (state, { payload }) => ({
        ...state,
        isGroupSelection: payload,
    }),
    [types.SET_INDIVIDUAL_SELECTION]: (state, { payload }) => ({
        ...state,
        isInvidualSelection: payload,
    }),
    [types.VOLUNTRY_DELETE_DEPENDANT_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.VOLUNTRY_DELETE_DEPENDANT_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        deletedDependentId: payload,
    }),
    [types.VOLUNTRY_DELETE_DEPENDANT_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),
    [types.IS_EDIT_DEPENDENT]: (state, { payload }) => ({
        ...state,
        isEdit: payload,
    }),
    [types.EDIT_FROM]: (state, { payload }) => ({
        ...state,
        editFrom: payload,
    }),
    [types.DEPENDENT_EDIT_DETAILS]: (state, { payload }) => ({
        ...state,
        dependentEditDetails: payload,
    }),

    [types.VIEWED_PREMIUM_RATES]: (state, { payload }) => ({
        ...state,
        isShowRates: payload,
    }),
};

export default handleActions(actionHandler, {
    loader: false,
    voluntryEnroll: {},
    selectedCategories: [],
    progressArray: [],
    selectedRatecards: [],
    currentlyEnrolled: [],
    currentlyInprocessEnrolled: [],
    selectedPlanDependants: [],
    selectedCoverages: [],
    familySelectedCoverages: [],
    bundleEnrolls:[],
    individualSelectedDependantsList:[],

    dependentname: {},
    isParentFlow: false,
    onlyParentFlow: false,
    multipleDependent: [],
    isGroupSelection: false,
    isInvidualSelection: false,
    deletedDependentId: null,
    isEdit: false,
    editFrom: '',
    dependentEditDetails: [],
    isShowRates:false,
});
