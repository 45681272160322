import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  TextField,
} from "@mui/material";
import { getRelationName, isEmptyNullUndefined } from "components/utils/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as BeneficiaryActions } from "../redux/actions";
import APIList from "api";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { RiDeleteBinLine, RiErrorWarningFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { actions as DependantActions } from "../../BasicMedical/AddDependentAddress/redux/actions";
import DialogPopup from "components/common/Dialog";
import { FiEdit } from "react-icons/fi";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FormattedMessage } from "react-intl";
var classNames = require("classnames");

const PrimaryForLifePage = ({
  setIsAllowedForContengent,
  setTotalPercentagePrimary,
  setDontWantToAddBeneficiary,
  setAddedBeneficiaries,
  addedBeneficiaries,
  isEdit,
  addBenPath,
  planId
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // const empDependant = useSelector((state) => state?.dependents?.allDependents);
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const allBeneficiaies = useSelector(
    (state) => state?.beneficiary?.allBeneficiaries
  );
  const allBeneficiaiesLoader = useSelector((state) => state?.beneficiary?.allBenLoader);
  const tempBeneficiaries = useSelector((state) => state?.beneficiary?.tempAllocatedBeneficiaries);

  const [beneficiaries, setBeneficiaries] = useState([]);
  const [beneficiariesLoader, setBeneficiariesLoader] = useState(false);
  const [currentBeneficiary, setCurrentBeneficiary] = useState(null);
  const [editBeneficiary, setEditBeneficiary] = useState({});
  const [isChecked, setIsChecked] = useState(true);
  const [percent, setPercent] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [percenrErrorMsg, setPercenrErrorMsg] = useState("");
  const [allocateLoader, setAllocateLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [editPercent, setEditPercent] = useState("");
  const [editPercentErr, setEditPercentErr] = useState("");
  const [enrollPlansId, setEnrollPlansId] = useState(null);
  const [startAction, setStartAction] = useState("");
  const [showSelectedDropdownValue, setShowSelectedDropdownValue] = useState(true);
  // const [allBeneficiaies, setAllBeneficiaies] = useState([]);
  // const [allBeneficiaiesLoader, setAllBeneficiaiesLoader] = useState(false);

useEffect(() => {  
  setStartAction("start");
  if(!isEmptyNullUndefined(tempBeneficiaries)){
    setAddedBeneficiaries(tempBeneficiaries?.addedBeneficiaries);    
  } 
  dispatch(BeneficiaryActions.GetAllBeneficiariesRequest({empId:employeeDetails?.id}));  
  setStartAction("end");
}, []);

useEffect(() => {
  
  if((startAction === "end") && location?.state?.fromBenAddress && !allBeneficiaiesLoader){
  addLatestBen();

}
}, [allBeneficiaies])


useEffect(() => {  
  if(!isEmptyNullUndefined(planId)){
    setEnrollPlansId(planId);
  } else {
    setEnrollPlansId(selectedPlan?.id);
  }
}, []);

  useEffect(() => {
    if(isEmptyNullUndefined(tempBeneficiaries)){
    if(!isEmptyNullUndefined(enrollPlansId)){
    setAddedBeneficiaries([]);
    setBeneficiariesLoader(true);
      APIList.getBenficiaryAllocationByEmployeeId({
        planId: enrollPlansId,
        employeeId: employeeDetails?.id,
      }).then((res) => {
        setBeneficiaries(res?.data);
        setBeneficiariesLoader(false);
      }).catch((err) => {
        setBeneficiariesLoader(false);
      })    
    }
  }
  }, [enrollPlansId]);

  useEffect(() => {
    if (beneficiaries?.length > 0) {
      let adben = JSON.parse(JSON.stringify(addedBeneficiaries));
      let ben = JSON.parse(JSON.stringify(beneficiaries));
      const arr = [...ben, ...adben];
      setAddedBeneficiaries(
        arr?.length > 0 &&
          arr?.filter(
            (v, i, a) =>
              a.findIndex((t) => t?.beneficiary?.id === v?.beneficiary?.id) === i
          )
      );
    }
  }, [beneficiaries]);

  useEffect(() => {
    if (
      Math.round(
        addedBeneficiaries
          ?.filter((x) => x?.primary)
          ?.reduce((a, b) => a + b.percentage, 0)
      ) == 100 &&
      isSame()
    ) {
      setIsAllowedForContengent(true);
    } else {
      setIsAllowedForContengent(false);
    }
    setTotalPercentagePrimary(
      Math.round(
        addedBeneficiaries
          ?.filter((x) => x?.primary)
          ?.reduce((a, b) => a + b.percentage, 0)
      )
    );
  }, [addedBeneficiaries]);

  useEffect(() => {
    if (isEmptyNullUndefined(percent)) {
      setPercenrErrorMsg("");
    } else {
      if (totalPercent() + Number(percent) < 101) {
        setPercenrErrorMsg("");
      } else {
        setPercenrErrorMsg(
          `Only ${
            100 - totalPercent() < 0 ? 0 : 100 - totalPercent()
          } % is remaining`
        );
      }
    }
  }, [percent]);

  useEffect(() => {
    if (isEmptyNullUndefined(editPercent)) {
      setEditPercentErr("");
    } else {
      if (
        totalPercent() -
          Number(editBeneficiary?.percentage) +
          Number(editPercent) <
        101
      ) {
        setEditPercentErr("");
      } else {
        setEditPercentErr(
          `Only ${
            100 - totalPercent() + Number(editBeneficiary?.percentage) < 0
              ? 0
              : 100 - totalPercent() + Number(editBeneficiary?.percentage)
          } % is remaining`
        );
      }
    }
  }, [editPercent]);

  const totalPercent = () => {
    return Math.round(
      addedBeneficiaries
        ?.filter((x) => x?.primary)
        ?.reduce((a, b) => Number(a) + Number(b.percentage), 0)
    );
  };

  const addLatestBen = () => {
    const latestDate = new Date(Math.max(...allBeneficiaies.map(item => new Date(item?.lastModifiedDate))));
    const currentBeneficiaryqq = allBeneficiaies?.filter(item => (new Date(item?.lastModifiedDate)).getTime() === latestDate?.getTime())[0];
    
    let benExist = addedBeneficiaries?.filter((ben) => ben?.beneficiary?.id === currentBeneficiaryqq?.id)?.length > 0 ? true : false;
    if(!benExist){
      let ben = {
        lifeInsurance: {
          id: enrollPlansId,
        },
        percentage: 0,
        primary: isChecked,
        status: "ACTIVE",
        beneficiary: {
          id: currentBeneficiaryqq?.id,
          firstName: currentBeneficiaryqq?.firstName,
          lastName: currentBeneficiaryqq?.lastName,
          relation: currentBeneficiaryqq?.relation,
        },
        employee: {
          id: employeeDetails.id,
        },
      };  
      setAddedBeneficiaries([...addedBeneficiaries, ben]);
      // setCurrentBeneficiary(null);
      // setPercent(0);
      setPercenrErrorMsg("");
    } else {
      let existedBen = addedBeneficiaries?.filter((ben) => ben?.beneficiary?.id === currentBeneficiaryqq?.id)[0];
      let indexB = addedBeneficiaries.indexOf(existedBen);
    
      let tempBeneficiaries = JSON.parse(JSON.stringify(addedBeneficiaries));
      let ben = {
        lifeInsurance: {
          id: enrollPlansId,
        },
        percentage: existedBen?.percentage,
        primary: isChecked,
        status: "ACTIVE",
        beneficiary: {
          id: currentBeneficiaryqq?.id,
          firstName: currentBeneficiaryqq?.firstName,
          lastName: currentBeneficiaryqq?.lastName,
          relation: currentBeneficiaryqq?.relation,
        },
        employee: {
          id: employeeDetails.id,
        },
      };
      tempBeneficiaries[indexB] = ben;
      setAddedBeneficiaries(tempBeneficiaries);
      // setCurrentBeneficiary(null);
      // setPercent(0);
      setPercenrErrorMsg("");
    }
  };

  const handleAssignPercent = (event, index) => {
    let tempBeneficiaries = JSON.parse(JSON.stringify(addedBeneficiaries));
    tempBeneficiaries[index].percentage = event.target.value.replace(/[^0-9 .]/g, "");
    // if (
    //   totalPercent() -
    //     Number(addedBeneficiaries[index].percentage) +
    //     Number(event.target.value) <
    //   101
    // ) {
      setAddedBeneficiaries(tempBeneficiaries);
    //   setEditPercentErr("");
    // } else {
    //   setEditPercentErr(
    //     `${
    //       100 - totalPercent() + Number(editBeneficiary?.percentage) < 0
    //         ? 0
    //         : 100 - totalPercent() + Number(editBeneficiary?.percentage)
    //     } % remaining`
    //   );
    // }
  };

  const addBenToAllocate = (currentBeneficiary) => {
    let ben = {
      lifeInsurance: {
        id: enrollPlansId,
      },
      percentage: 0,
      primary: isChecked,
      status: "ACTIVE",
      beneficiary: {
        id: currentBeneficiary?.id,
        firstName: currentBeneficiary?.firstName,
        lastName: currentBeneficiary?.lastName,
        relation: currentBeneficiary?.relation,
      },
      employee: {
        id: employeeDetails.id,
      },
    };

    setAddedBeneficiaries([...addedBeneficiaries, ben]);
    // setCurrentBeneficiary(null);
    // setPercent(0);
    setPercenrErrorMsg("");
  }

  const handleSelectBeneficiaryFromDependant = (event) => {
    setShowSelectedDropdownValue(true);
    if (Object.keys(editBeneficiary).length == 0) {
      // setCurrentBeneficiary(event.target.value);
      addBenToAllocate(event.target.value);
      // setPercent(0);
    } else {
      // alert("Finish editing first")
      setOpenDialog(true);
      setDialogContent("Please finish editing first");
    }
  };

  const handleAddBeneficiary = () => {
    if (percent == 0 || isEmptyNullUndefined(currentBeneficiary?.id)) {
      if(isEmptyNullUndefined(currentBeneficiary?.id)){
        setPercenrErrorMsg("Please select beneficiary")
      } else {
        setPercenrErrorMsg(`It can not be ${percent} %.`);
      }      
    } else {
      let ben = {
        lifeInsurance: {
          id: enrollPlansId,
        },
        percentage: percent,
        primary: isChecked,
        status: "ACTIVE",
        beneficiary: {
          id: currentBeneficiary?.id,
          firstName: currentBeneficiary?.firstName,
          lastName: currentBeneficiary?.lastName,
          relation: currentBeneficiary?.relation,
        },
        employee: {
          id: employeeDetails.id,
        },
      };

      setAddedBeneficiaries([...addedBeneficiaries, ben]);
      setCurrentBeneficiary(null);
      setPercent(0);
      setPercenrErrorMsg("");
    }
  };

  const handleEditBeneficiary = (id) => {
    setPercent(0);
    const index = addedBeneficiaries?.findIndex((x) => x?.beneficiary?.id == id);

    setEditBeneficiary(addedBeneficiaries[index]);
    setEditPercent(addedBeneficiaries[index]?.percentage);
    setIsChecked(addedBeneficiaries[index]?.primary);
  };
  const handleAddEditedBeneficiary = () => {
    if (editPercent == 0) {
      setPercenrErrorMsg(`It can not be ${editPercent} %.`);
    } else {
      var allBeneficiaries = JSON.parse(JSON.stringify(addedBeneficiaries));
      let ben = allBeneficiaries?.filter(
        (x) => x?.beneficiary?.id != editBeneficiary?.beneficiary?.id
      );
      let reqBen = allBeneficiaries?.filter(
        (x) => x?.beneficiary?.id == editBeneficiary?.beneficiary?.id
      )[0];
      reqBen["percentage"] = editPercent;
      reqBen["primary"] = isChecked;
      setAddedBeneficiaries([...ben, reqBen]);
      setEditBeneficiary({});
      setPercenrErrorMsg("");
    }
  };

  const isSame = () => {
    if (addedBeneficiaries?.length > beneficiaries?.length) {
      return false;
    }
    let same = true;
    addedBeneficiaries?.forEach((ab) => {
      beneficiaries?.forEach((bb) => {
        if (addedBeneficiaries?.length === beneficiaries?.length) {
          
          if (ab?.beneficiary?.id === bb?.beneficiary?.id) {
           
            if (ab?.percentage != bb?.percentage) {
          
              same = false;
            }
          }
        } else {
          if (
            Math.round(
              addedBeneficiaries
                ?.filter((x) => x?.primary)
                ?.reduce((a, b) => a + b.percentage, 0)
            ) == 100
          ) {
            same = false;
          }
        }
      });
    });

    return same;
  };

  const handleAssignEqualPercent = () => {
    if (
      // Object.keys(currentBeneficiary).length == 0 &&
      Object.keys(editBeneficiary).length == 0
    ) {
      const len = addedBeneficiaries?.filter((x) => x?.primary)?.length;
      const equalDistribution = parseFloat(100 / len).toFixed(2);
      let tempBenificiary = addedBeneficiaries?.filter((x) => x?.primary);
      let tempBenificiaryContengent = addedBeneficiaries?.filter(
        (x) => !x?.primary
      );
      tempBenificiary?.forEach((ben) => {
        ben.percentage = Number(equalDistribution);
      });

      setAddedBeneficiaries([...tempBenificiary, ...tempBenificiaryContengent]);
      setPercenrErrorMsg("");
    } else {
      setOpenDialog(true);
      setDialogContent("Please finish editing first");
    }
  };

  const handleChangePercent = (event) => {
    if (!isEmptyNullUndefined(event.target.value)) {
      if (event.target.value > 0 && event.target.value < 101) {
        setPercent(event.target.value.replace(/[^0-9]/g, ""));
      }
    } else {
      setPercent("");
    }
  };
  const handleChangeEditPercent = (event) => {
    if (!isEmptyNullUndefined(event.target.value)) {
      if (event.target.value > 0 && event.target.value < 101) {
        setEditPercent(event.target.value.replace(/[^0-9]/g, ""));
      }
    } else {
      setEditPercent("");
    }
  };

  const handleDelete = (beneficiary) => {
    setShowSelectedDropdownValue(false);
    setDeleteId(beneficiary?.beneficiary?.id);

    if (beneficiary.id != undefined) {
      APIList.deleteBeneficiaryAllocation({ id: beneficiary.id })
        .then((res) => {
          res &&
            toast.success(
              <div className="flex flex-row">
                <BsFillCheckCircleFill
                  style={{ width: "2rem", height: "2rem" }}
                />
                &nbsp;&nbsp;Deleted Successfully
              </div>
            );
          setAddedBeneficiaries(
            addedBeneficiaries?.filter(
              (x) => x?.beneficiary?.id != beneficiary?.beneficiary?.id
            )
          );
          setDeleteId("");
          dispatch(
            BeneficiaryActions.PlanBeneficiariesDataRequest({
              planId: enrollPlansId,
              employeeId: employeeDetails?.id,
            })
          );
        })
        .catch((err) => {
          toast.error(
            <div className="flex flex-row">
              <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
              &nbsp;&nbsp;
              {err?.message}
            </div>
          );
          setDeleteId("");
        });
    } else {
      setTimeout(() => {
        setAddedBeneficiaries(
          addedBeneficiaries?.filter(
            (x) => x?.beneficiary?.id != beneficiary?.beneficiary?.id
          )
        );
        setDeleteId("");
      }, 1000);
    }
  };

  const allocateBeneficiaries = () => {
    if (
      Math.round(
        addedBeneficiaries
          ?.filter((x) => x?.primary)
          ?.reduce((a, b) => a + b.percentage, 0)
      ) == 100
    ) {
      setAllocateLoader(true);
      APIList.addBeneficiaryAllocation({ addedBeneficiaries })
        .then((res) => {
          res &&
            toast.success(
              <div className="flex flex-row">
                <BsFillCheckCircleFill
                  style={{ width: "2rem", height: "2rem" }}
                />
                &nbsp;&nbsp;Added Successfully
              </div>
            );
          dispatch(
            BeneficiaryActions.PlanBeneficiariesDataRequest({
              planId: enrollPlansId,
              employeeId: employeeDetails?.id,
            })
          );
          setAllocateLoader(false);
          //   history.push('/plans');
        })
        .catch((err) => {
          toast.error(
            <div className="flex flex-row">
              <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
              &nbsp;&nbsp;
              {err?.message}
            </div>
          );
          setAllocateLoader(false);
        });
    } else {
      toast.info(
        <div className="flex flex-row">
          <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
          &nbsp;&nbsp; Total of percentage is not 100%.
        </div>
      );
    }
  };

  function isPercentEqual(arr) {
    return arr.every((val) => val?.percentage === arr[0]?.percentage);
  };

  const handleRedirectToAddBen = () => {    
    dispatch(BeneficiaryActions.TempBenAllocationInfo({addedBeneficiaries}));
    dispatch(DependantActions.SaveDependantInfo(null));                        
    dispatch(DependantActions.SaveDependantAddress(null));
    history.push({pathname: "/add-beneficiary", state:{addBenPath: addBenPath}})
  }

  //   window.onpopstate = () => {
  //     dispatch(planActions.PlanDataRequest("clearData"));
  //  };

  // Pushing Dynamic CSS Rules for Pie Chart
  const css = document.styleSheets[1];

  for (
    let i = 0;
    i < addedBeneficiaries?.filter((x) => x?.primary)?.length;
    i++
  ) {
    css.insertRule(
      `

    @keyframes donut${i} {
       0% {
    stroke-dasharray: 0, 100;
  }
  100% {
    stroke-dasharray: ${
      addedBeneficiaries?.filter((x) => x?.primary)[i].percentage
    },
      ${Number(
        100 - addedBeneficiaries?.filter((x) => x?.primary)[i].percentage
      )};
  }
    }
    `,
      css.cssRules.length
    );
    css.insertRule(
      `
      .donut-segment-${i} {
          transform-origin: center;
  stroke: var(--primary);
  animation: donut${i} 3s;
}`
    );
  }

  return (
    <div className="beneficiaryPrimary">
      {
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={allocateLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
          <div className="flex justify-end">
            <FormControl sx={{width:"50%", marginTop:"1rem"}} >
              <InputLabel size="small" id="selectDependant">
                {/* Add Beneficiary */}
                <FormattedMessage id='myBeneficiaries.addBeneficiary' defaultValue='Add Beneficiary'/>
              </InputLabel>
              <Select
                size="small"
                labelId="selectDependant"
                id="Add Beneficiary"
                value={!showSelectedDropdownValue && null}
                // label="Add Beneficiary"
                label={<FormattedMessage id='myBeneficiaries.addBeneficiary' defaultValue='Add Beneficiary'/>}
                onChange={handleSelectBeneficiaryFromDependant}
                sx={{
                  width: "100%",
                  backgroundColor: "#ffffff",
                  "& fieldset": {
                    //border: "1px solid #dedede",
                  },                
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid rgb(59 130 246 / 0.5)", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                }}
              >
                {allBeneficiaies?.length > 0 &&
                  allBeneficiaies?.map((dependant) => {
                    return (
                      <MenuItem
                        value={dependant}
                        disabled={
                          addedBeneficiaries?.findIndex(
                            (x) => x?.beneficiary?.id == dependant.id
                          ) != -1
                        }
                        sx={{display:"flex", justifyContent:"space-between"}}
                      >
                        {dependant.firstName} {dependant.lastName}
                        
                      </MenuItem>
                    );
                  })}

                <MenuItem
                  onClick={() => handleRedirectToAddBen()}
                >
                  {/* Add Beneficiary */}
                  <FormattedMessage id='myBeneficiaries.addBeneficiary' defaultValue='Add Beneficiary'/>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
      {/* <div className="beneficiaryEditMode">
        <p className="ben-ben-subtext">Add Beneficiary</p>
        <div className="row1">
          <div className="name">
            <FormControl sx={{  "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {display: "none"},}} fullWidth>
              <InputLabel size="small" id="selectDependant">
                Add Beneficiary
              </InputLabel>
              <Select
                size="small"
                labelId="selectDependant"
                id="Add Beneficiary"
                value={currentBeneficiary}
                label="Add Beneficiary"
                onChange={handleSelectBeneficiaryFromDependant}
                sx={{
                  width: "100%",
                  backgroundColor: "#ffffff",
                  "& fieldset": {
                    //border: "1px solid #dedede",
                  },                
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid rgb(59 130 246 / 0.5)", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                }}
              >
                {allBeneficiaies?.length > 0 &&
                  allBeneficiaies?.map((dependant) => {
                    return (
                      <MenuItem
                        value={dependant}
                        disabled={
                          addedBeneficiaries?.findIndex(
                            (x) => x?.beneficiary?.id == dependant.id
                          ) != -1
                        }
                        sx={{display:"flex", justifyContent:"space-between"}}
                      >
                        {dependant.firstName} {dependant.lastName}
                        
                      </MenuItem>
                    );
                  })}

                <MenuItem
                  onClick={() => {
                    dispatch(DependantActions.SaveDependantInfo(null));                        
                    dispatch(DependantActions.SaveDependantAddress(null));
                    history.push({pathname: "/add-beneficiary", state:{addBenPath: addBenPath}})
                  }}
                >
                  Add Beneficiary
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <p className="ben-percent-subtext">
            Percentage designated to the Beneficiary
          </p>
          <div className="pdiv">
            <div className="selectPercentage">
              <TextField
                size="small"
                name="percent"
                label="Percentage"
                id="outlined-basic"
                onChange={(event) => handleChangePercent(event)}
                variant="outlined"
                error={percenrErrorMsg != ""}
                helperText={percenrErrorMsg}
                value={percent}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                sx={{
                  my: 2,
                  backgroundColor: "#ffffff",
                  "& fieldset": {
                    //border: "1px solid #dedede",
                  },
                  "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                    display: "none",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid rgb(59 130 246 / 0.5)", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                  "& .MuiFormHelperText-root": {
                    backgroundColor: "var(--primary)",
                    fontSize: "0.9rem",
                  },
                }}
              />
            </div>
            <div className="row3">
              <button
                onClick={() => handleAddBeneficiary()}
                disabled={percenrErrorMsg != ""}
                className="btn-done"
              >
                <FaCheck />
              </button>
              <button
                onClick={() => {setCurrentBeneficiary(null); setPercent(0)}}
                className="btn-cancel"
              >
                <FaTimes />
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div className="addedBeneficiaries">
        {beneficiariesLoader || allBeneficiaiesLoader ? (
          <div>
            <Skeleton
              variant="rectangular"
              height={125}
              sx={{ margin: "1rem 0rem" }}
            />
            <Skeleton
              variant="rectangular"
              height={125}
              sx={{ margin: "1rem 0rem" }}
            />
          </div>
        ) : (
          addedBeneficiaries
            ?.filter((x) => x?.primary)
            ?.map((beneficiary, index) => {
              return (
                <>
                  {
                  // !isEmptyNullUndefined(editBeneficiary) && 
                  // (editBeneficiary?.beneficiary?.id ==
                  // beneficiary?.beneficiary?.id) ? (
                  //   <div className="beneficiaryEditMode">
                  //     <div className="row1">
                  //       <div className="name">
                  //         {editBeneficiary?.beneficiary?.firstName}{" "}
                  //         {editBeneficiary?.beneficiary?.lastName}
                  //         <span 
                  //         className="edit-ben-dependant"
                  //         onClick={() => {
                  //           dispatch(DependantActions.SaveDependantInfo(null));
                  //           dispatch(DependantActions.SaveDependantAddress(null));
                  //            history.push({pathname: "/add-beneficiary", state:{addBenPath: addBenPath, isDepEdit: true, id: editBeneficiary?.beneficiary?.id}})
                  //         }}
                  //         >
                  //           Edit Beneficiary Info
                  //         </span>                          
                  //         <p className="relation">
                  //           {getRelationName(
                  //             editBeneficiary?.beneficiary?.relation
                  //           )}
                  //         </p>
                          
                  //       </div>
                  //       <div className="pdiv">
                  //         <div className="selectPercentage">
                  //           <TextField
                  //             size="small"
                  //             name="percent"
                  //             label="Percentage"
                  //             id="outlined-basic"
                  //             onChange={(event) =>
                  //               handleChangeEditPercent(event)
                  //             }
                  //             variant="outlined"
                  //             error={editPercentErr != ""}
                  //             helperText={editPercentErr}
                  //             value={editPercent}
                  //             InputProps={{
                  //               endAdornment: (
                  //                 <InputAdornment position="end">
                  //                   %
                  //                 </InputAdornment>
                  //               ),
                  //             }}
                  //             sx={{
                  //               my: 2,
                  //               backgroundColor: "#ffffff",
                  //               "& fieldset": {
                  //                 //border: "1px solid #dedede",
                  //               },
                  //               "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                  //                 display: "none",
                  //               },
                  //               "& .css-k4qjio-MuiFormHelperText-root": {
                  //                 backgroundColor: "#ffffff",
                  //                 margin: "0px",
                  //                 paddingLeft: "0.5rem",
                  //               },
                  //               "& .MuiFormHelperText-root": {
                  //                 backgroundColor: "var(--primary)",
                  //                 fontSize: "0.9rem",
                  //               },
                  //             }}
                  //           />
                  //         </div>
                  //         <div className="row3">
                  //           <button
                  //             onClick={() => handleAddEditedBeneficiary()}
                  //             disabled={editPercentErr != ""}
                  //             className="btn-done"
                  //           >
                  //             <FaCheck />
                  //           </button>
                  //           <button
                  //             onClick={() => setEditBeneficiary({})}
                  //             className="btn-cancel"
                  //           >
                  //             <FaTimes />
                  //           </button>
                  //         </div>
                  //       </div>
                  //     </div>

                  //     {/* <div className="row2">
                  //             <div className="type" style={{color:"var(--secondary)"}}>
                  //               {isChecked ? "Primary" : "Contengent"}
                  //             </div>
                  //             <div className="switch">
                  //               <Switch
                  //                 onChange={(event) =>
                  //                   setIsChecked(event.target.checked)
                  //                 }
                  //                 defaultChecked
                  //               />
                  //             </div>
                  //           </div>

                  //           <div className="row3">
                  //             <div className="beneficiary-message">
                  //               Life Insurance pays money to your beneficiary
                  //               (any person you choose) in the unexpected event
                  //               you die.
                  //             </div>
                  //             <button
                  //               onClick={() => handleAddEditedBeneficiary()}
                  //               className="btn-done"
                  //             >
                  //               <FaCheck />
                  //             </button>
                  //             <button onClick={() => setEditBeneficiary({})} className="btn-cancel">
                                
                  //               <FaTimes />
                  //             </button>
                  //           </div> */}
                  //   </div>
                  // ) : (
                    <div
                      className={classNames("benCard", {
                        deletingCard: deleteId == beneficiary?.beneficiary?.id,
                      })}
                    >
                      <div className="col1">
                        <div className="name">
                          {beneficiary?.beneficiary?.firstName}{" "}
                          {beneficiary?.beneficiary?.lastName}
                          <p className="relation">
                            {getRelationName(beneficiary?.beneficiary?.relation)}
                          </p>
                        </div>
                        {/* <div className="type">
                                {beneficiary?.primary
                                  ? "Primary"
                                  : "Contengent"}
                              </div> */}
                        <div className="row2"></div>
                      </div>
                      <div className="col2">
                        {/* <div className=" svg-item">
                          <svg
                            width="100%"
                            height="4.25rem"
                            viewBox="0 0 40 40"
                            className="donut  "
                          >
                            <circle
                              className="donut-hole"
                              cx="20"
                              cy="20"
                              r="15.91549430918954"
                              fill="var(--tertiary)"
                            ></circle>
                            <circle
                              className="donut-ring"
                              cx="20"
                              cy="20"
                              r="15.91549430918954"
                              fill="transparent"
                              stroke-width="3.5"
                            ></circle>
                            <circle
                              className={`donut-segment donut-segment-${index}`}
                              cx="20"
                              cy="20"
                              r="15.91549430918954"
                              fill="transparent"
                              stroke-width="3.5"
                              stroke-dasharray={`${beneficiary.percentage} ${
                                100 - Number(beneficiary.percentage)
                              }`}
                              stroke-dashoffset="25"
                            ></circle>
                            <g className="donut-text donut-text-1">
                              <text y="50%" transform="translate(0, 2)">
                                <tspan
                                  x="50%"
                                  text-anchor="middle"
                                  className="donut-percent"
                                >
                                  {`${parseFloat(beneficiary.percentage)
                                    .toFixed(2)
                                    .replace(/[.,]00$/, "")}%`}
                                </tspan>
                              </text>
                              <text y="60%" transform="translate(0, 2)"></text>
                            </g>
                          </svg>
                        </div> */}
                         <TextField
                              size="small"
                              name="percent"
                              // label="Percentage"
                              label={<FormattedMessage id='myBeneficiaries.percentage' defaultValue='Percentage'/>}
                              id="outlined-basic"
                              onChange={(event) =>
                                handleAssignPercent(event, index)
                              }
                              variant="outlined"
                              error={editPercentErr != ""}
                              helperText={editPercentErr}
                              value={beneficiary?.percentage}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              sx={{
                                // my: 2,
                                width:"9rem",
                                backgroundColor: "#ffffff",
                                margin:"auto",
                                "& fieldset": {
                                  //border: "1px solid #dedede",
                                },
                                // "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                //   display: "none",
                                // },
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                  backgroundColor: "#ffffff",
                                  margin: "0px",
                                  paddingLeft: "0.5rem",
                                },
                                "& .MuiFormHelperText-root": {
                                  backgroundColor: "var(--tertiary)",
                                  fontSize: "0.75rem",
                                },
                              }}
                           />
                        <div className="forStyleOnly"></div>
                        <div className="editDelete">
                          <FiEdit
                            // onClick={() =>
                            //   handleEditBeneficiary(beneficiary?.beneficiary?.id)
                            // }
                            onClick={() => {
                              dispatch(BeneficiaryActions.TempBenAllocationInfo({addedBeneficiaries}));
                              dispatch(DependantActions.SaveDependantInfo(null));                        
                              dispatch(DependantActions.SaveDependantAddress(null));
                              history.push({pathname: "/add-beneficiary", state:{addBenPath: addBenPath, isDepEdit: true, id: beneficiary?.beneficiary?.id}});                            }}
                          />
                          <RiDeleteBinLine
                            onClick={() => handleDelete(beneficiary)}
                          />
                        </div>
                      </div>
                    </div>
                  // )
                  }
                </>
              );
            })
        )}
      </div>
      <div className="agree">
        {isPercentEqual(addedBeneficiaries) &&
        Math.round(
          addedBeneficiaries
            ?.filter((x) => x?.primary)
            ?.reduce((a, b) => a + b.percentage, 0)
        ) == 100 ? (
          <></>
        ) : (
          <div className="aggreText">
            {/* <h3 className="title-allocate">Allocate Beneficiaries - </h3> */}
            {
              addedBeneficiaries?.length > 0 && 
                <h3 className="title flex flex-row items-center">
                  <FormGroup>
                    <FormControlLabel
                      sx={{ marginRight: 0 }}
                      control={
                        <Checkbox
                          color="success"
                          onChange={() => handleAssignEqualPercent()}
                          checked={
                            isPercentEqual(addedBeneficiaries) &&
                            Math.round(
                              addedBeneficiaries
                                ?.filter((x) => x?.primary)
                                ?.reduce((a, b) => a + b.percentage, 0)
                            ) == 100
                          }
                          // disabled={
                          //   isPercentEqual(addedBeneficiaries) &&
                          //   Math.round(
                          //     addedBeneficiaries
                          //       ?.filter((x) => x?.primary)
                          //       ?.reduce((a, b) => a + b.percentage, 0)
                          //   ) == 100
                          // }
                        />
                      }
                    />
                  </FormGroup>
                  {/* Assign percentage equally */}
                  <FormattedMessage id='myBeneficiaries.assignPercentageEqually' defaultValue='Assign percentage equally'/>
                </h3>
            }
            
            {/* <h5 className="subTitle">
             By clicking on you agree to apply equal percentage to all
             beneficiaries
           </h5> */}
          </div>
        )}
      </div>

      <div>
        <div className="flex flex-wrap">
          {/* <div className="w-full px-5">
              <ButtonM
                text="SUBMIT"
                onpress={() => allocateBeneficiaries()}
                disabled={((addedBeneficiaries?.filter((x) => x?.primary)?.length == 0) || isSame())}
                bgcolor={((addedBeneficiaries?.filter((x) => x?.primary)?.length == 0) || isSame())? "gray" : ""}
              />
            </div> */}
        </div>
        {/* <div className="flex flex-wrap">
          {!isEdit && addedBeneficiaries?.length === 0 && (
            <div className="w-full px-5">
              <ButtonM
                onpress={() => setDontWantToAddBeneficiary(true)}
                text="I'm not ready to add Beneficiary"
                variant="outlined"
                textsize="0.75rem"
                bghover="#ffffff"
              />
            </div>
          )}
        </div> */}
      </div>
      {/* <ModalBen
          open={open}
          handleClose = {handleClose}
          yesRedirect = "/plans"
          header = "Are you sure ?"
          msg1 = "You want to leave this page."
          msg2 = {Math.round(addedBeneficiaries?.filter((x) => x?.primary)?.reduce((a, b) => a + b.percentage, 0)) != 100 ? "The total is not 100%" : ""}
        /> */}
      {openDialog && (
        <DialogPopup
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          content={dialogContent}
        />
      )}
    </div>
  );
};

export default PrimaryForLifePage;