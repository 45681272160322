import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import APIList from 'api';
import { toast } from "react-toastify";
import { FaFileUpload } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { employeeActions } from "./reedux/action";
import { isEmptyNullUndefined, requiredStyled } from 'components/utils/utils';

function AddcaseTable({ caseFunction }) {
    const { addCase, setAddcase } = caseFunction;
    const { loader, empData: { id: employeeId } } = useSelector((state) => state.empData);
    const dispatch = useDispatch();
    const [raiseTicket, setRaisedTicket] = useState({
        title: "",
        raisedBy: {
            id: employeeId
        },
        message: "",

        status: "ACTIVE",
        file: null

    })

    const [msgLengthOutOfLimit, setMsgLengthOutOfLimit] = useState(false);
    const [titleErr, setTitleErr] = useState(false);
    const [msgErr, setMsgErr] = useState(false);

    const handelChangeEvent = (event) => {
        setMsgLengthOutOfLimit(false);
        setTitleErr(false);
        setMsgErr(false);
        const { name, value, type } = event.target;
   
        if (type == "file") {
            const file = event.target.files.length > 0 ? event.target.files[0] : undefined;
           
            if (file.type.match("image/jpeg") || file.type.match("image/jpg") || file.type.match("image/svg+xml") || file.type.match("image/png") || file.type.match("application/pdf")) {
                if (file) {
                    if (file.size > 2097152) {
                        setRaisedTicket({
                            ...raiseTicket, file: {
                                fName: file.name,
                                fileError: "Please upload a file smaller than 2 MB"
                            }
                        })
                        return;
                    } else {
                        setRaisedTicket({
                            ...raiseTicket, file: {
                                fName: file.name,
                                fileError: ""
                            }
                        })
    
                        setTimeout(() => uploadFile(file), 300)
    
                    }
                }
    
            } else {
                setRaisedTicket({
                    ...raiseTicket, file: {
                        fName: file.name,
                        fileError: "Please upload file of type jpeg, jpg, svg, pdf or png"
                    }
                })
                    return;
                  
            }

            
        } else {
            setRaisedTicket({
                ...raiseTicket,
                [name]: value
            })
        }

    }

    const uploadFile = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const body = {
                formData,
                headers: { ContentType: 'multipart/form-data' }
            }
            const { status, data } = await APIList.fileUpload(body);
            if (status == 201) {
                // toast.success("image uploaded ")
                setRaisedTicket({
                    ...raiseTicket,
                    file: { fName: data.fname, path: data?.path, fileId: data?.id, fileError: "" }
                })
            }

        } catch (error) {
            toast.success("image could not  uploaded ")
        }
    };

    const isValidate = (title, message) => {
        let valid = true;

        if(isEmptyNullUndefined(title)){
           setTitleErr(true);
           valid = false;
        }
        if(isEmptyNullUndefined(message)){
           setMsgErr(true);
           valid = false;
        }
        if(message.length > 250){
           setMsgLengthOutOfLimit(true);
           valid = false;
        }

        return valid;
    }

    const addTicket = async (event) => {
        event.preventDefault();

        const { file, title, message, raisedBy, status: dataStatus } = raiseTicket;
        // && file?.fileId !== undefined
        if (isValidate(title, message)) {
            if (title !== "" && message !== "") {

                const ApiBody = {
                    title,
                    raisedBy,
                    message,
                    fileUrl: file?.fileId ? { id: file?.fileId } : null,
                    status: dataStatus,
                }
    
                try {
                    const { status } = await APIList.raiseTicket(ApiBody);
    
                    if (status == 201 || status == 200) {
                        toast.success("Ticket raised succesfully!");
                        dispatch(employeeActions.getEmployeeCaseRequest({
                            id: employeeId
                        }));
    
                        setAddcase(!addCase);
                        setRaisedTicket({
                            title: "",
                            raisedBy: {
                                id: employeeId
                            },
                            message: "",
                            status: "ACTIVE",
                            file: { fName: "Upload file" }
                        })
                    }
                } catch (error) {
    
                    toast.error(error?.detail)
                }
    
            } else {
                // toast.warn(" Title and Message  are mandatory ")
            }
        } 
    }

    return (
        <Box component="form" onSubmit={addTicket}>
            <Stack mx={2} justifyContent="space-between" direction="row">
                <Stack direction="column" width="45%" justifyContent="space-between" component={Box} >
                    <TextField
                        sx={{ my: 2, width: "100%", ...requiredStyled }}
                        size='small'
                        name="title"
                        value={raiseTicket.title}
                        onChange={(event) => handelChangeEvent(event)}
                        id="outlined-basic" label="Title"
                        variant="outlined" 
                        error={titleErr}
                        helperText={titleErr && "Title is required"}
                        />
                    <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        name="message"
                        value={raiseTicket.message}
                        onChange={(event) => handelChangeEvent(event)}
                        multiline
                        rows={3}

                        sx={{ my: 2, width: "100%" , ...requiredStyled} }
                        placeholder="Write desciption here"
                        error={msgLengthOutOfLimit || msgErr}
                        helperText={msgLengthOutOfLimit && "Description must be 250 characters only" || msgErr && "Description is required"}
                    />

                </Stack>
                <Stack direction="column" width="45%" justifyContent="flex-start" component={Box} >
                    <Stack direction="row" my={2} alignItems="center" justifyContent="space-between" p={1} sx={{ borderRadius: 2, border: "1px solid gainsboro" }}>
                        
                        <label htmlFor='upload' style={{ cursor: "pointer", width:"100%", display:"flex", justifyContent:"space-between" }}>
                        <Typography > {raiseTicket.file?.fName ?? "Upload file"}</Typography>
                            <FaFileUpload />
                        </label>

                        <input
                            onChange={(event) => handelChangeEvent(event)}
                            type="file"

                            id="upload"
                            hidden
                        />

                    </Stack>
                    <Typography color="red" variant="body2" size="small" >{raiseTicket?.file?.fileError}</Typography>
                    <Stack>
                        {
                            raiseTicket?.file?.path && !raiseTicket?.file?.path?.includes(".pdf") &&
                            <img
                                src={raiseTicket?.file?.path}
                                alt="preview-img"
                                width="130px"
                            />
                        }
                    </Stack>
                </Stack>


            </Stack>
            <Stack direction="row" justifyContent="flex-end" mx={2}>
            
              <Button sx={{marginRight:"1rem"}} variant="contained" onClick={() => setAddcase(false)}>
               Cancel
               </Button>
           
                <Button
                    disabled={raiseTicket?.file !== null && raiseTicket?.file?.fileError !==""}
                    variant='contained'
                    type="submit"
                    color='success'
                    sx={{backgroundColor:"var(--primary)", color:"var(--secondary)"}}
                >Submit</Button>
            </Stack>
        </Box>

    )

}

export default AddcaseTable