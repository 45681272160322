const { createAction } = require('redux-actions');

const ENROLL_REQUEST = 'BENEVOLVE/ENROLL_REQUEST';
const ENROLL_REQUEST_SUCCESS = 'BENEVOLVE/ENROLL_REQUEST_SUCCESS';
const ENROLL_REQUEST_FAILED = 'BENEVOLVE/ENROLL_REQUEST_FAILED';

const EnrollRequest = createAction(ENROLL_REQUEST);
const EnrollRequestSuccess = createAction(ENROLL_REQUEST_SUCCESS);
const EnrollRequestFailed = createAction(ENROLL_REQUEST_FAILED);

const DELETE_DEPENDANT_REQUEST = 'BENEVOLVE/DELETE_DEPENDANT_REQUEST';
const DELETE_DEPENDANT_REQUEST_SUCCESS = 'BENEVOLVE/DELETE_DEPENDANT_REQUEST_SUCCESS';
const DELETE_DEPENDANT_REQUEST_FAILED = 'BENEVOLVE/DELETE_DEPENDANT_REQUEST_FAILED';

const DeleteDependantRequest = createAction(DELETE_DEPENDANT_REQUEST);
const DeleteDependantRequestSuccess = createAction(DELETE_DEPENDANT_REQUEST_SUCCESS);
const DeleteDependantRequestFailed = createAction(DELETE_DEPENDANT_REQUEST_FAILED);

export const actions = {
    EnrollRequest,
    EnrollRequestSuccess,
    EnrollRequestFailed,

    DeleteDependantRequest,
    DeleteDependantRequestSuccess,
    DeleteDependantRequestFailed
};

export const types = {
    ENROLL_REQUEST,
    ENROLL_REQUEST_SUCCESS,
    ENROLL_REQUEST_FAILED,

    DELETE_DEPENDANT_REQUEST,
    DELETE_DEPENDANT_REQUEST_SUCCESS,
    DELETE_DEPENDANT_REQUEST_FAILED
};
