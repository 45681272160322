import React, { useEffect, useState } from "react";
import PageNavigator from "../../common/PageNavigator/index";
import { useSelector } from "react-redux";
import ButtonM from "components/common/Button";
import ModalBeneficiary from "./modal";
import DependentModel from "components/common/DependentModel";
import APIList from "api";
import {
  initialFormErrorList,
  initialEmptyDependent,
  initialAddressFormErrorList,
  initialAddressForm,
} from "./utils";
import { toast } from "react-toastify";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { actions as DependantActions } from "../BasicMedical/AddDependentAddress/redux/actions";
import { actions as DependantInfoActions } from "./redux/actions";
import { RiErrorWarningFill } from "react-icons/ri";
import moment from "moment";
import { getAge, getPlanIcon, isDependantFinalised } from "components/utils/utils";
import { isEmptyNullUndefined } from "components/utils/utils";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Skeleton } from "@mui/material";
import { FormattedMessage } from "react-intl";
import BenModal from "components/common/Modal/BenModal";
import DeletePopUp from "components/common/DeletePopUp";

const Dependents = () => {
  const dispatch = useDispatch();

  const dependentsEnrollmentInfo = useSelector(
    (state) => state?.dependantEnrollInfo?.dependantEnrollInfo
  );
  const dependentsEnrollmentInfoLoader = useSelector(
    (state) => state?.dependantEnrollInfo?.loader
  );

  const state = useSelector((state) => state);
  const empDetails = useSelector((state) => state && state.empData);
  const companyDetails =
    state &&
    state.empData &&
    state.empData.empData &&
    state.empData.empData.company;
  const employeeDetails = state && state.empData;
  const employeeId = employeeDetails?.empData?.id;
  const allDependantsList = useSelector(
    (state) => state?.dependents?.allDependents
  );
  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const eligibleplans = useSelector((state) => state && state?.empData?.eligibleplans);
  const elligiblePlanLoader = useSelector((state) => state && state?.empData?.elligiblePlanLoader);
  const [canEditDependant, setCanEditDependant] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  const [depToView, setDepToView] = useState(null);
  const [isViewDep, setIsViewDep] = useState(false);

  const [showModel, setShowModel] = useState(false);
  const [isFormOneFilled, setIsFormOneFilled] = useState(false);

  useEffect(() => {
    if(!showModel){
      setIsViewDep(false);
    }
  }, [showModel])
  

  useEffect(() => {
    if (!isEmptyNullUndefined(employeeId)) {
      dispatch(
        DependantInfoActions.DependantEnrollInfoRequest({ empId: employeeId })
      );
    }
    APIList.canEditDependant({id:employeeId})
    .then((res) => {
      if(employeeDetails?.empData?.company?.isDependantEditable){
        setCanEditDependant(res?.data);
      } 
    })
  }, [employeeId]);

  const [addLoading, setAddLoading] = React.useState(false);

  const handelbutton = () => {
    setShowModel(true);
    setDependentId("newDependents");
    setDependentAddress(initialAddressForm);
  };

  const [formError, setFormError] = useState(initialFormErrorList);
  const [formAddressError, setFormAddressError] = useState(
    initialAddressFormErrorList
  );
  const [checked, setChecked] = React.useState(false);
  const [dependentId, setDependentId] = useState(null);
  const [dependent, setDependent] = React.useState(initialEmptyDependent);
  const [dependentAddress, setDependentAddress] =
    React.useState(initialAddressForm);

  const [depEnrollInfo, setdepEnrollInfo] = useState(null);  

  //
  const getCompanyDateFormat = () => {
    return companyDetails &&
      companyDetails.dateFormat != null &&
      companyDetails.dateFormat != ""
      ? companyDetails.dateFormat
      : "dd/MM/yyyy";
  };

  const handleResetForm = () => {
    if (!isFormOneFilled) {
      //   const tempInitDependent = JSON.parse(
      //     JSON.stringify(initialEmptyDependent)
      //   );
      const tempInitError = JSON.parse(JSON.stringify(initialFormErrorList));
      // const tempInitError = structuredClone(initialFormErrorList);
      //   setDependent(tempInitDependent);
      setFormError(tempInitError);
    } else {
    }
  };

  //handle dependent change function
  const handleChangeDependent = async (event, fieldType) => {
    const tempDependent = JSON.parse(JSON.stringify(dependent));
    // const tempDependent = structuredClone(dependent);
    // const tempError = JSON.parse(JSON.stringify(formError));
    // const tempError = structuredClone(formError);
    const tempError = {...formError};
    if (fieldType === "textfield" || fieldType === "dropdown") {
      tempDependent[event.target.name] = event.target.value;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    } else if (fieldType === "birthdate") {
      tempDependent.dateOfBirth = event;
      tempError.dateOfBirth.isError = false;
      tempError.dateOfBirth.errorMessage = "";
    }else if (fieldType === "checkbox") {
      tempDependent[event.target.name] = event.target.value === "true" ? true : false;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    }

    // if(!tempDependent["isRelativeWorking"]){
    //   tempDependent["isRelativeWorkingInNium"] = null;
    //   tempDependent["employeeIdOfRelative"] = null;
    // }
    if(!tempDependent["isRelativeWorkingInNium"]){
      tempDependent["employeeIdOfRelative"] = null;
    }

    setDependent(tempDependent);
    setFormError(tempError);
  };

  // handle update dependents
  const handleUpdateDependent = () => {
    setAddLoading(true);
    APIList.updateDependant({
      firstName: dependent && dependent.firstName,
      middleName: (dependent && dependent.middleName) || "",
      lastName: dependent && dependent.lastName,
      relation: dependent && dependent.relation,
      dateOfBirth: dependent && dependent.dateOfBirth,
      nationalId: dependent && dependent.nationalId,
      id: dependent.id,
      idType: dependent && dependent.idType,
      gender: dependent && dependent.gender,
      nationality: dependent && dependent.nationality,
      isRelativeWorking: dependent && dependent.isRelativeWorking,
      isRelativeWorkingInNium: dependent && dependent.isRelativeWorkingInNium,
      employeeIdOfRelative: dependent && dependent.employeeIdOfRelative,
      status:"ACTIVE",
      employee: {
        id: empDetails && empDetails.empData && empDetails.empData.id,
      },
      address: !checked
        ? {
            streetLineOne: dependentAddress && dependentAddress.streetLineOne,
            streetLineTwo: dependentAddress && dependentAddress.streetLineTwo,
            city: dependentAddress && dependentAddress.city,
            state: dependentAddress && dependentAddress.state,
            postalCode: dependentAddress && dependentAddress.postalCode,
          }
        : empDetails && empDetails.empData && empDetails.empData.address,
    })
      .then((res) => {
        (employeeDetails?.empData?.company?.isDependantsApprovalRequired && isDependantFinalised(depEnrollInfo)) ?
          toast.info(
            <div className="flex flex-row">
              Your request is under review. Request has been sent to HR /admin for approval, if the dependents is also a beneficiary, please make necessary changes under `My Beneficiary' tab.
            </div>
          ):
          toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;Updated&nbsp;
              {dependent && dependent.relation} successfully
            </div>
          );
          setDependent(initialEmptyDependent);
          setDependentAddress(initialAddressForm);
          setChecked(false);
        dispatch(
          DependantInfoActions.DependantEnrollInfoRequest({
            empId: employeeId,
          })
        );
        setAddLoading(false);
        setShowModel(false);
        setIsFormOneFilled(false);
      })
      .catch((err) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.message}
          </div>
        );
        setAddLoading(false);
      });
  };
  //handel another functon
  const handleValidateDependent = async () => {
    let isValid = true;
    // const tempError = JSON.parse(JSON.stringify(formError));
    const tempError = {...formError};
    const tempDependent = JSON.parse(JSON.stringify(dependent));

    if (
      tempDependent.relation === null ||
      tempDependent?.relation?.trim() === ""
    ) {
      tempError.relation.isError = true;
      // tempError.relation.errorMessage = "Please select Relationship";
      tempError.relation.errorMessage = <FormattedMessage id='error.pleaseSelectRelationaship' defaultValue='Please select Relationship'/>;
      isValid = false;
    }
    if (
      tempDependent.firstName === null ||
      tempDependent?.firstName?.trim() === ""
    ) {
      tempError.firstName.isError = true;
      // tempError.firstName.errorMessage = "Please enter First Name";
      tempError.firstName.errorMessage = <FormattedMessage id='error.pleaseEnterFirstName' defaultValue='Please enter First Name'/>;
      isValid = false;
    }
    // if (
    //   tempDependent.middleName === null ||
    //   tempDependent.middleName.trim() === ""
    // ) {
    //   tempError.middleName.isError = true;
    //   tempError.middleName.errorMessage = "Please enter Middle Name";
    //   tempError.middleName.errorMessage = <FormattedMessage id='error.pleaseEnterMiddleName' defaultValue='Please enter Middle Name'/>;
    //   isValid = false;
    // }

    if (
      tempDependent.lastName === null ||
      tempDependent?.lastName?.trim() === ""
    ) {
      tempError.lastName.isError = true;
      // tempError.lastName.errorMessage = "Please enter Last Name";
      tempError.lastName.errorMessage = <FormattedMessage id='error.pleaseEnterLastName' defaultValue='Please enter Last Name'/>;
      isValid = false;
    }

    if (tempDependent.gender === null || tempDependent?.gender?.trim() === "") {
      tempError.gender.isError = true;
      // tempError.gender.errorMessage = "Please select Gender";
      tempError.gender.errorMessage = <FormattedMessage id='error.pleaseSelectGender' defaultValue='Please select Gender'/>;
      isValid = false;
    }
    // if (tempDependent.idType === null || tempDependent?.idType?.trim() === "") {
    //   tempError.idType.isError = true;
    //   tempError.idType.errorMessage = "Please select idType";
    //   isValid = false;
    // }
    // if (
    //   tempDependent.nationalId === null ||
    //   tempDependent.nationalId.trim() === ""
    // ) {
    //   tempError.nationalId.isError = true;
    //   tempError.nationalId.errorMessage = "Please enter National ID";
    //   isValid = false;
    // }
    if (
      tempDependent.dateOfBirth == null ||
      tempDependent?.dateOfBirth?.trim() == ""
    ) {
      tempError.dateOfBirth.isError = true;
      // tempError.dateOfBirth.errorMessage = "Please enter Date of Birth";
      tempError.dateOfBirth.errorMessage = <FormattedMessage id='error.pleaseEnterDateofBirth' defaultValue='Please enter Date of Birth'/>;
      isValid = false;
    }
    if (
      getAge(tempDependent.dateOfBirth) < 0
    ) {
      tempError.dateOfBirth.isError = true;
      // tempError.dateOfBirth.errorMessage = "Please enter correct Date of Birth";
      tempError.dateOfBirth.errorMessage = <FormattedMessage id='error.pleaseEnterCorrectDateofBirth' defaultValue='Please enter correct Date of Birth'/>;
      isValid = false;
    }
    // if (
    //   tempDependent.idType !== null && (tempDependent.nationalId == null ||
    //   tempDependent?.nationalId?.trim() == "")
    // ) {
    //   tempError.nationalId.isError = true;
    //   tempError.nationalId.errorMessage = `Please enter ID (${tempDependent?.idType})`;
    //   isValid = false;
    // }
    // if (
    //   tempDependent.nationality == null ||
    //   tempDependent?.nationality?.trim() == ""
    // ) {
    //   tempError.nationality.isError = true;
    //   tempError.nationality.errorMessage = "Please enter nationality";
    //   isValid = false;
    // }
    // if (
    //   tempDependent.isRelativeWorking == null 
    // ) {
    //   tempError.isRelativeWorking.isError = true;
    //   tempError.isRelativeWorking.errorMessage = "Please select yes/no";
    //   isValid = false;
    // }
    if (
      tempDependent.isRelativeWorkingInNium == null
    ) {
      tempError.isRelativeWorkingInNium.isError = true;
      // tempError.isRelativeWorkingInNium.errorMessage = "Please select yes/no";
      tempError.isRelativeWorkingInNium.errorMessage = <FormattedMessage id='error.pleaseSelectYesNo' defaultValue='Please select yes/no'/>;
      isValid = false;
    }
    if (
      tempDependent.isRelativeWorkingInNium && (tempDependent.employeeIdOfRelative == null || tempDependent?.employeeIdOfRelative?.trim() == "")
    ) {
      tempError.employeeIdOfRelative.isError = true;
      // tempError.employeeIdOfRelative.errorMessage = "Please enter employe-id of dependant";
      tempError.employeeIdOfRelative.errorMessage = <FormattedMessage id='error.pleaseEnteremployeIdDependant' defaultValue='Please enter employe-id of dependant'/>;
      isValid = false;
    }
    //do Submit
    if (isValid) {
      handleResetForm();
      setIsFormOneFilled(true);
    } else {
      setFormError(tempError);
    }
  };

  const handleValidateDependentSubmit = async () => {
    let isValid = true;
    // const tempError = JSON.parse(JSON.stringify(formAddressError));
    // const tempDependentAddress = JSON.parse(JSON.stringify(dependentAddress));

    // if (
    //   tempDependentAddress.streetLineOne === null ||
    //   tempDependentAddress?.streetLineOne?.trim() === ""
    // ) {
    //   tempError.streetLineOne.isError = true;
    //   tempError.streetLineOne.errorMessage =
    //     "Please enter street address line 1";
    //   isValid = false;
    // }
    // if (
    //   tempDependentAddress.streetLineTwo === null ||
    //   tempDependentAddress?.streetLineTwo?.trim() === ""
    // ) {
    //   tempError.streetLineTwo.isError = true;
    //   tempError.streetLineTwo.errorMessage =
    //     "Please enter street address line 2";
    //   isValid = false;
    // }

    // if (
    //   tempDependentAddress.city === null ||
    //   tempDependentAddress?.city?.trim() === ""
    // ) {
    //   tempError.city.isError = true;
    //   tempError.city.errorMessage = "Please enter City";
    //   isValid = false;
    // }
    // if (
    //   tempDependentAddress.state === null ||
    //   tempDependentAddress?.state?.trim() === ""
    // ) {
    //   tempError.state.isError = true;
    //   tempError.state.errorMessage = "Please enter State";
    //   isValid = false;
    // }
    // if (
    //   tempDependentAddress.postalCode === null ||
    //   tempDependentAddress?.postalCode?.toString().trim() === ""
    // ) {
    //   tempError.postalCode.isError = true;
    //   tempError.postalCode.errorMessage = "Please enter Postal Code";
    //   isValid = false;
    // }
    

    //do Submit
    if (isValid || checked) {
      // if (isEdit) {
      //   updateDependent();
      // } else {
      if (dependent.id) {
        handleUpdateDependent();
        
      } else {
        submitDependent();
        
      }
      // }
    } 
    // else {
    //   setFormAddressError(tempError);
    // }
  };

  const submitDependent = () => {
    setAddLoading(true);
    APIList.addDependant({
      firstName: dependent && dependent.firstName,
      middleName: (dependent && dependent.middleName) || "",
      lastName: dependent && dependent.lastName,
      relation: dependent && dependent.relation,
      dateOfBirth: dependent && dependent.dateOfBirth,
      nationalId: dependent && dependent.nationalId,
      idType: dependent && dependent.idType,
      gender: dependent && dependent.gender,
      nationality: dependent && dependent.nationality,
      isRelativeWorking: dependent && dependent.isRelativeWorking,
      isRelativeWorkingInNium: dependent && dependent.isRelativeWorkingInNium,
      employeeIdOfRelative: dependent && dependent.employeeIdOfRelative,
      isRelativeWorking: dependent && dependent.isRelativeWorking,
      isRelativeWorkingInNium: dependent && dependent.isRelativeWorkingInNium,
      employeeIdOfRelative: dependent && dependent.employeeIdOfRelative,
      status:"ACTIVE",
      employee: {
        id: empDetails && empDetails.empData && empDetails.empData.id,
      },
      address: !checked
        ? {
            streetLineOne: dependentAddress && dependentAddress.streetLineOne,
            streetLineTwo: dependentAddress && dependentAddress.streetLineTwo,
            city: dependentAddress && dependentAddress.city,
            state: dependentAddress && dependentAddress.state,
            postalCode: dependentAddress && dependentAddress.postalCode,
          }
        : empDetails && empDetails.empData && empDetails.empData.address,
    })
      .then((res) => {
        res &&
          toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;Added&nbsp;
              {dependent && dependent.relation} successfully
            </div>
          );
          setDependentAddress(initialAddressForm);
          setDependent(initialEmptyDependent);
          setChecked(false);
        dispatch(
          DependantInfoActions.DependantEnrollInfoRequest({
            empId: employeeId,
          })
        );
        setAddLoading(false);
        setShowModel(false);
        setIsFormOneFilled(false);
      })
      .catch((err) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );
        setAddLoading(false);
      });
  
  };

  const handleEdit = (id, i) => {
    // const item = allDependantsList.filter((x) => x.id == id)[0];
    const item = dependentsEnrollmentInfo[i]?.dependantInfo;
    const itemPlans = dependentsEnrollmentInfo[i]?.plans;
    setdepEnrollInfo(itemPlans);
    setFormError(initialFormErrorList);
    setDependent(item);
    setDependentAddress(item?.address);
    setShowModel(true);
  };

  const handleChangeDependentAddress = async (event, fieldType) => {
    const tempDependentAddress = JSON.parse(JSON.stringify(dependentAddress));
    // const tempDependentAddress = structuredClone(dependentAddress);
    const tempError = JSON.parse(JSON.stringify(formAddressError));
    // const tempError = structuredClone(formAddressError);
    if (fieldType === "textfield" || fieldType === "dropdown") {
      tempDependentAddress[event.target.name] = event.target.value;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    } else if (fieldType == "onlyNumber") {
      tempDependentAddress[event.target.name] = event.target.value.replace(
        /[^0-9]/g,
        ""
      );
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    }
    setDependentAddress(tempDependentAddress);
    setFormAddressError(tempError);
  };

  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteDepId, setDeleteDepId] = useState("");
  const [depToDelete, setDepToDelete] = useState(null);
  const [viewDeletePopUp, setViewDeletePopUp] = useState(false);

    const handleClose = () => {
      setViewDeletePopUp(false);
  }

  const proceedForDelete = (dep) => {
    setViewDeletePopUp(true);
    setDepToDelete(dep);
  }

  const deleteDependant = (dep) => {
    setDeleteLoader(true);
    setViewDeletePopUp(false);
    setDeleteDepId(dep?.id);
    APIList.deleteDependant({
      depId:dep?.id
    }).then((res) => {
      dispatch(
        DependantInfoActions.DependantEnrollInfoRequest({ empId: employeeId })
      );
      setDeleteLoader(false);
      setDeleteDepId("");
      setDepToDelete(null);
    }).catch((err) => {
      toast.error(
        <div className="flex flex-row">
          <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
          &nbsp;&nbsp;
          {err?.message}
        </div>
      );
      setDeleteLoader(false);
      setDeleteDepId("");
      setDepToDelete(null);
    })

  }

  const viewDep = (id,i) => {
    setIsViewDep(true);
      setShowModel(true);
      const item = dependentsEnrollmentInfo[i]?.dependantInfo;
      setDepToView(item);
  }

  return (
    <div className=" learningPageCoverageDetails learningPage beneficiaries">
      <PageNavigator
        // pageName="Dependent Summary Page"
        pageName={<FormattedMessage id='myDependent.pageTitle'  defaultValue='Dependent Summary Page'/>}
        // PageHeading="Review your dependents and their coverage. Any change to `Dependents' enrolled into the plan, will have to be reported to HR for validation and approval. `Beneficiary' information may be changed at any point."
        PageHeading={<FormattedMessage id='sidebar.MyDependents.PageHeading'  defaultValue='Review your dependents and their coverage. Any change to `Dependents` enrolled into the plan, will have to be reported to HR for validation and approval. `Beneficiary` information may be changed at any point.'/>}
      />

      {
        dependentsEnrollmentInfoLoader ? 
        <div className="mx-4">
            <Skeleton
              variant="rectangular"
              height={110}
              sx={{ margin: "1rem 0rem" }}
            />
            <Skeleton
              variant="rectangular"
              height={110}
              sx={{ margin: "1rem 0rem" }}
            />
          </div>
        :
        <div className=" flex flex-wrap justify-between">
        {Object.keys(dependentsEnrollmentInfo)?.map((i, index) => {
          return (
            <div
              style={{ width: "100%" }}
              className="box box2 mx-4 my-4 first"
              key={`${index}`}
            >
              <div className="boxInner flex flex-wrap pt-4 mx-4 justify-between">
                <div className="nameOuter">
                  <h4 className="name mb-2">{dependentsEnrollmentInfo[i]?.dependantInfo?.firstName} {dependentsEnrollmentInfo[i]?.dependantInfo?.lastName}</h4>
                  <h6 className="relation mb-2">{dependentsEnrollmentInfo[i]?.dependantInfo?.relation
                  && dependentsEnrollmentInfo[i]?.dependantInfo?.relation === "PIL" ? "Parent-in-law" : dependentsEnrollmentInfo[i]?.dependantInfo?.relation
                  }</h6>
                  <h6 className="relation mb-2">
                    DOB : &nbsp;
                    {
                   moment(dependentsEnrollmentInfo[i]?.dependantInfo?.dateOfBirth).format(
                            `${
                              employeeDetails?.empData?.company?.dateFormat ? 
                              employeeDetails?.empData?.company?.dateFormat?.toUpperCase() : 
                              'DD-MM-YYYY'
                            }`)
                    }
                    </h6>
                </div>
                <div className="imgOuterMainDependantpage">
                  {
                    !isDependantFinalised(dependentsEnrollmentInfo[i]?.plans) ?
                    <div
                    className="edtbtn"
                    onClick={() => !deleteLoader && 
                      handleEdit(
                        dependentsEnrollmentInfo[i]?.dependantInfo?.id,
                        i
                      )
                    }
                  >
                    {/* Edit */}
                    <FormattedMessage id='common.button.edit' defaultValue='Edit'/>
                  </div>
                  :
                  (isDependantFinalised(dependentsEnrollmentInfo[i]?.plans) && employeeDetails?.empData?.company?.isDependantEditable) ?
                  <div
                    className="edtbtn"
                    onClick={() => !deleteLoader && 
                      handleEdit(
                        dependentsEnrollmentInfo[i]?.dependantInfo?.id,
                        i
                      )
                    }
                  >
                    {/* Edit */}
                    <FormattedMessage id='common.button.edit' defaultValue='Edit'/>
                  </div>
                  :
                  <div
                    className="edtbtn"
                    onClick={() => !deleteLoader && 
                      viewDep(
                        dependentsEnrollmentInfo[i]?.dependantInfo?.id,
                        i
                      )
                    }
                  >
                    {/* Edit */}
                    <FormattedMessage id='common.button.view' defaultValue='View'/>
                  </div>
                  }
                  {
                    dependentsEnrollmentInfo[i]?.plans?.length === 0 && 
                    <div
                    className="deletebtn"
                    onClick={() => !deleteLoader && proceedForDelete(dependentsEnrollmentInfo[i]?.dependantInfo)}
                  >
                    {
                      deleteLoader && (dependentsEnrollmentInfo[i]?.dependantInfo?.id === deleteDepId) ?
                      <CircularProgress color="primary" size={18} />
                      :
                      // "Delete"
                      <FormattedMessage id='common.button.delete' defaultValue='Delete'/>
                    }
                    
                  </div>
                  }
                  
                </div>
              </div>
              <div className="flex flex-wrap justify-between">
                {dependentsEnrollmentInfo[i]?.plans?.map((plan) => {
                  return (
                    <div
                      style={{ width: "40%" }}
                      className="flex mx-4 my-4 flex-wrap"
                    >
                      <div className="flex " style={{ alignItems: "center" }}>
                        <img
                          src={getPlanIcon(eligibleplans, plan?.planId)}
                          alt="Image1"
                          className="summaryImg deleteImage "
                          style={{
                            background: "#fff",
                            /* border: 1px solid; */
                            height: "3rem",
                            padding: "0.3rem",
                            margin: 0,
                            marginRight: "0.5rem",
                            mixBlendMode: "multiply",
                          }}
                        />

                        <div
                          style={{
                            fontFamily: "poppins",
                          }}
                        >
                          <p
                            style={{
                              marginBottom: "5px",
                              fontSize: "0.9rem",
                            }}
                          >
                            {plan.displayName}
                          </p>
                          <p
                            style={{
                              fontSize: "0.75rem",
                              color: "rgba(0, 0, 0, 0.37)",
                            }}
                          >
                            {/* Enrolled on - */}
                            <FormattedMessage id='sidebar.MyDependents.EnrolledOn' defaultValue='Enrolled on' />-
                            {moment(plan.enrollmentDate?.slice(0,10)).format(
                              "DD MMMM, YYYY"
                            )}
                            {/* 24 September, 2022 */}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      }
      
      <div className="bottomButtonReq" style={{ paddingInline: "1rem" }} id="add-dependant-button">
        <ButtonM
          // text="ADD DEPENDENT"
          text={<FormattedMessage id='sidebar.MyDependents.ADDDEPENDENT' defaultValue='ADD DEPENDENT' />}
          //   disabled={
          //     // dontWantEnroll === false &&
          //     (!elligibleToEnroll || coveragePlan === null || enrollMentLoader || !electionChecked)
          //   }
          //   loading={enrollMentLoader}
          //   bgcolor={
          //     // dontWantEnroll === false &&
          //       (!elligibleToEnroll || coveragePlan === null || !electionChecked)
          //       ? "gray"
          //       : ""
          //   }
          onpress={handelbutton}
        />
      </div>
      {showModel && !isViewDep &&(
        <ModalBeneficiary
          isFormOneFilled={isFormOneFilled}
          setIsFormOneFilled={setIsFormOneFilled}
          setViewModal={setShowModel}
          beneficiaryId={dependentId}
          employeeDetails={employeeDetails}
          setBeneficiaryId={setDependentId}
          formError={formError}
          setFormError={setFormError}
          dependentAddress={dependentAddress}
          setDependentAddress={setDependentAddress}
          initialAddressForm={initialAddressForm}
          initialAddressFormErrorList={initialAddressFormErrorList}
          initialFormErrorList={initialFormErrorList}
          handleChangeDependent={handleChangeDependent}
          handleChangeDependentAddress={handleChangeDependentAddress}
          formAddressError={formAddressError}
          setFormAddressError={setFormAddressError}
          dependent={dependent}
          getCompanyDateFormat={getCompanyDateFormat}
          setDependent={setDependent}
          initialEmptyDependent={initialEmptyDependent}
          handleValidateDependent={handleValidateDependent}
          handleValidateDependentSubmit={handleValidateDependentSubmit}
          addLoading={addLoading}
          setAddLoading={setAddLoading}
          title="Dependent Details"
          checked={checked}
          setChecked={setChecked}
        />
      )}
      
      {showModel && isViewDep && (
        <div className="dep-info-container">
        <BenModal setViewDetails={setShowModel}>
          <div>
            <div className="dep-info-row"><p><FormattedMessage id='myDependent.addDependent.Relation' defaultValue='Relation' /> : <span>{depToView?.relation
            && depToView?.relation === "PIL" ? "Parent-in-law" : depToView?.relation
            }</span></p></div>

            <div className="dep-info-row"><p><FormattedMessage id='myDependent.addDependent.firstName' defaultValue='First Name' /> : <span>{depToView?.firstName}</span></p></div>

            <div className="dep-info-row"><p><FormattedMessage id='myDependent.addDependent.middleName' defaultValue='Middle Name' /> : <span>{!isEmptyNullUndefined(depToView?.middleName) ? depToView?.middleName : "NA" }</span></p></div>

            <div className="dep-info-row"><p><FormattedMessage id='myDependent.addDependent.lastName' defaultValue='Last Name' /> : <span>{depToView?.lastName}</span></p></div>
            
            <div className="dep-info-row"><p><FormattedMessage id='basicMedical.dateOfBirth' defaultValue='Date of birth' /> : <span>{
             moment(depToView?.dateOfBirth).format(
              `${
                employeeDetails?.empData?.company?.dateFormat ? 
                employeeDetails?.empData?.company?.dateFormat?.toUpperCase() : 
                'DD-MM-YYYY'
              }`)
            }</span></p></div>
            
            <div className="dep-info-row"><p><FormattedMessage id='myDependent.addDependent.gender' defaultValue='Gender' /> : <span>{depToView?.gender}</span></p></div>
            
            <div className="dep-info-row"><p><FormattedMessage id='myDependent.addDependent.nationality' defaultValue='Nationality' /> : <span>{!isEmptyNullUndefined(depToView?.nationality) ? depToView?.nationality : "NA"}</span></p></div>

            {/* <div className="dep-info-row"><p>National ID : <span>{depToView?.nationalId}</span></p></div> */}

            {/* <div className="dep-info-row"><p>Is Working : <span>{depToView?.isRelativeWorking ? "Yes" : "No"}</span></p></div> */}
            
            <div className="dep-info-row"><p><span><FormattedMessage id='myBeneficiaries.IsWorkingIn' defaultValue='Is Working in' /> {companyDetails?.displayName}</span> : {depToView?.isRelativeWorkingInNium ? <FormattedMessage id='common.button.YES' defaultMessage="YES"/> : <FormattedMessage id='common.button.NO' defaultMessage="NO"/>}</p></div>

            <div className="dep-info-row"><p><FormattedMessage id='myBeneficiaries.EmployeeID' defaultValue='Employee ID' /> : <span>{!isEmptyNullUndefined(depToView?.employeeIdOfRelative) ? depToView?.employeeIdOfRelative : "NA"}</span></p></div>

            <div className="dep-info-row"><p><FormattedMessage id='myBeneficiaries.streetAddressLine1' defaultValue='Street address line 1' /> : <span>{!isEmptyNullUndefined(depToView?.address?.streetLineOne) ? depToView?.address?.streetLineOne : "NA"}</span></p></div>
            <div className="dep-info-row"><p><FormattedMessage id='myBeneficiaries.streetAddressLine2' defaultValue='Street address line 2' /> : <span>{!isEmptyNullUndefined(depToView?.address?.streetLineTwo) ? depToView?.address?.streetLineTwo : "NA"}</span></p></div>
            <div className="dep-info-row"><p><FormattedMessage id='myBeneficiaries.city' defaultValue='City' /> : <span>{!isEmptyNullUndefined(depToView?.address?.city) ? depToView?.address?.city : "NA"}</span></p></div>
            <div className="dep-info-row"><p><FormattedMessage id='myBeneficiaries.country' defaultValue='Country' /> : <span>{!isEmptyNullUndefined(depToView?.address?.country) ? depToView?.address?.country : "NA"}</span></p></div>
            <div className="dep-info-row"><p><FormattedMessage id='myBeneficiaries.postalCode' defaultValue='Postal code' /> : <span>{!isEmptyNullUndefined(depToView?.address?.postalCode) ? depToView?.address?.postalCode : "NA"}</span></p></div>

          </div>
        </BenModal>
        </div>
      )}
      <Dialog
        open={viewDeletePopUp}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <div id="alert-dialog-description">
            {/* Are you sure you want to delete {depToDelete?.firstName} {depToDelete?.lastName} ? */}
            <FormattedMessage id='error.AreYouSureYouWantToDelete' defaultMessage="Are you sure you want to delete"/> {depToDelete?.firstName} {depToDelete?.lastName} ?
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button
           onClick={() => {deleteDependant(depToDelete); handleClose();}} 
           sx={{backgroundColor:"var(--primary)", color:"var(--secondary)", "&:hover":{backgroundColor:"var(--primary)"}, fontFamily:"poppins"}}
          //  autoFocus
           >
            {/* YES */}
            <FormattedMessage id='common.button.YES' defaultMessage="YES"/>
          </Button>
          <Button
           onClick={handleClose} 
           sx={{backgroundColor:"var(--secondary)", color:"var(--primary)", "&:hover":{backgroundColor:"var(--secondary)"}, fontFamily:"poppins"}}
          //  autoFocus
           >
            {/* NO */}
            <FormattedMessage id='common.button.NO' defaultMessage="NO"/>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Dependents;
