import Rectangle from "../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../assets/radioCheck.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ButtonM from "../../common/Button";
import PageTitleAndDescription from "../../common/PageTitleAndDescription";
import { useDispatch, useSelector } from "react-redux";
import { actions as DependantActions } from "./AddDependentAddress/redux/actions";
import { actions as getPlanActions } from "../../common/Plans/redux/actions";
import { RiDeleteBin5Fill, RiErrorWarningFill } from "react-icons/ri";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import APIList from "../../../api/index";
import {Backdrop, Checkbox, CircularProgress, FormControlLabel, FormGroup, Skeleton} from "@mui/material";
import GetCostVersion2 from "./ViewCost/Version2";
import {getAge, getAllowedEmp, getCoveragePlan, getElligiblePlanDependant, getNoOfDependantEnrollFromThisPlan, getPayCheckFrequency, getPlanDependantWithRespectToRelation, getRatecard, getRelationName, getRelationNames} from "components/utils/utils";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";
import { FormattedMessage } from "react-intl";
import { FiEdit } from "react-icons/fi";
var classNames = require("classnames");

const BasicMedical = () => {
  
  const history = useHistory();
  const dispatch = useDispatch();

  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const user = useSelector((state) => state?.user?.user);
  const empDependant = useSelector((state) => state?.dependents?.allDependents);
  const loading = useSelector((state) => state?.dependents?.loader);
  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const lifeEventConfiguratorDTO = useSelector((state) => state?.planReducer?.lifeEventConfiguratorDTO);
  const benefitStartDate = useSelector((state) => state?.planReducer?.benefitStartDate);
  const planDisplayName = selectedPlan?.displayName;

  const [viewCost, setViewCost] = useState(false);
  const [isEmployeeEnroll, setIsEmployeeEnroll] = useState(true);
  const [dontWantEnroll, setDontWantEnroll] = useState(false);
  const [coveragePlan, setCoveragePlan] = useState(null);
  const [allowedEmp, setAllowedEmp] = useState(null);
  const [elligibleToEnroll, setElligibleToEnroll] = useState(false);
  const [applicableRatecards, setapplicableRatecardss] = useState([]);
  const [PayCheckFreq, setPayCheckFreq] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [electionChecked, setElectionChecked] = useState(false);
  const [getCostLoader, setGetCostLoader] = useState(false);
  const [costData, setCostData] = useState([]);
  const [enrollMentLoader, setEnrollMentLoader] = useState(false);
  const [elligiblePlanDependant, setElligiblePlanDependant] = useState([]);
  const [enrolledDependants, setEnrolledDependants] = useState(allowedEmp || []);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    if (allowedEmp?.length === enrolledDependants.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [allowedEmp, enrolledDependants]);

  useEffect(() => {
    if (selectAllChecked) {
      setEnrolledDependants(allowedEmp);
    }
  }, [selectAllChecked]);

  useEffect(() => {
    if (Object.keys(selectedPlan)?.length == 0) {
      history.push("/plans");
    }
  }, []);

  function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setViewCost(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);

  useEffect(() => {
    // if(user?.id != undefined){
    dispatch(
      DependantActions.GetDependentRequest({
        empId: employeeDetails && employeeDetails.id,
      })
    );
    // }
  }, []);

  useEffect(() => {
    setCoveragePlan(getCoveragePlan(selectedPlan, employeeDetails));
    setPayCheckFreq(getPayCheckFrequency(selectedPlan, employeeDetails));
    setElligiblePlanDependant(
      getElligiblePlanDependant(selectedPlan, employeeDetails)
    );
  }, []);

  useEffect(() => {
    setapplicableRatecardss(getRatecard(selectedPlan, coveragePlan));
    setEnrolledDependants(
      getAllowedEmp(
        empDependant,
        elligiblePlanDependant,
        lifeEventConfiguratorDTO
      )
    );
  }, [coveragePlan, employeeDetails, empDependant]);

  useEffect(() => {
    setAllowedEmp(
      getAllowedEmp(
        empDependant,
        elligiblePlanDependant,
        lifeEventConfiguratorDTO
      )
    );
  }, [empDependant, elligiblePlanDependant]);

  useEffect(() => {
    getIsValidToEnroll();
  }, [enrolledDependants]);

  const getIsValidToEnroll = () => {
    if (enrolledDependants?.length === 0) {
      setElligibleToEnroll(true);
    } else {
      if (enrolledDependants?.length > selectedPlan?.totalLiveMaxLimit) {
        setElligibleToEnroll(false);
      } else {
        elligiblePlanDependant?.forEach((dep) => {
          getNoOfDependantEnrollFromThisPlan(dep, enrolledDependants) >
          dep.dependantLimit
            ? setElligibleToEnroll(false)
            : setElligibleToEnroll(true);
        });
      }
    }
  };

  const handleDependentSelection = (dep) => {
    enrolledDependants.filter((x) => x.id === dep.id).length > 0
      ? setEnrolledDependants(enrolledDependants.filter((x) => x.id !== dep.id))
      : setEnrolledDependants([...enrolledDependants, dep]);
  };

  const handleEnrollment = () => {
    setEnrollMentLoader(true);

    APIList.healthInsuranceEnroll({
      employee: {
        id: employeeDetails?.id,
      },
      benefitStartDate: benefitStartDate,
      isDraft: true,
      status: "ISDRAFT",
      isEmployeeEnrolled: !dontWantEnroll,
      planOptOut: dontWantEnroll,
      selectedCoverage: dontWantEnroll
        ? null
        : {
            id: coveragePlan?.id,
          },
      employeeCoverage: dontWantEnroll ? null : coveragePlan?.amount,
      plan: {
        id: selectedPlan.id,
        className: selectedPlan.className,
      },
      enrollmentDate: new Date(),
      dependantEnrollments: !dontWantEnroll
        ? enrolledDependants.map((x) => {
            return {
              benefitStartDate: benefitStartDate,
              dependants: {
                id: x.id,
              },
              dependantEnrollmentDate: new Date(),
              selectedCoverage: {
                id: getPlanDependantWithRespectToRelation(
                  x.relation,
                  elligiblePlanDependant
                )?.id,
              },
              dependentCoverage: getPlanDependantWithRespectToRelation(
                x.relation,
                elligiblePlanDependant
              )?.maxCoverage,
            };
          })
        : null,
    })
      .then((res) => {
        res &&
          toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;Submitted Successfully
            </div>
          );
        setEnrollMentLoader(false);
        dispatch(getPlanActions.PlanDataRequest("clearData"));
        history.push({
          pathname: "/loading",
          state: { planName: selectedPlan?.displayName },
        });
      })
      .catch((err) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {err?.message}
          </div>
        );
        setEnrollMentLoader(false);
      });
  };

  const deleteDependant = (id) => {
    setDeleteLoader(true);

    APIList.deleteDependant({
      depId: id,
    })
      .then((res) => {
        res &&
          toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;Dependant deleted
            </div>
          );
        dispatch(
          DependantActions.GetDependentRequest({
            empId: employeeDetails && employeeDetails.id,
          })
        );
        setDeleteLoader(false);
      })
      .catch((err) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {/* {err?.title} */}
            Dependant can not be deleted, This Dependant is already enrolled.
          </div>
        );
        setDeleteLoader(false);
      });
  };

  const handleViewCost = () => {
    setViewCost(true);

    setGetCostLoader(true);

    APIList.getCost({
      employee: {
        id: employeeDetails?.id,
      },
      isEmployeeEnrolled: isEmployeeEnroll,
      selectedCoverage: {
        id: coveragePlan?.id,
      },
      employeeCoverage: coveragePlan?.amount,
      plan: {
        id: selectedPlan.id,
        className: selectedPlan.className,
      },
      enrollmentDate: new Date(),
      benefitStartDate: benefitStartDate,
      dependantEnrollments: enrolledDependants.map((x) => {
        return {
          dependants: {
            id: x.id,
          },
          enrollmentDate: new Date(),
          selectedCoverage: {
            id: getPlanDependantWithRespectToRelation(
              x.relation,
              elligiblePlanDependant
            ).id,
          },
          dependentCoverage: coveragePlan?.amount,
        };
      }),
    })
      .then((res) => {
        // res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;{}</div>);
        setCostData(res.data);
        setGetCostLoader(false);

        // history.push({ pathname: "/plans", state: { fromAddress: true } });
      })
      .catch((err) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );
        setGetCostLoader(false);
      });
  };

  const isAgeMismatch = useMemo(() => {
    let mismatch = "no err";
    enrolledDependants?.forEach((dep) => {
      elligiblePlanDependant.forEach((pd) => {
        if (dep?.relation === "Father" || dep?.relation === "Mother") {
          if (
            pd?.relation?.includes("Parent") ||
            pd?.relation?.includes(dep.relation)
          ) {
            if (
              getAge(dep.dateOfBirth) >
                (pd.maxAge !== null ? pd.maxAge : 1000) ||
              getAge(dep.dateOfBirth) < (pd.minAge !== null ? pd.minAge : -1)
            ) {
              mismatch = `this plan allows ${dep?.relation} between ${
                pd.minAge !== null ? pd.minAge : 0
              }-${pd.maxAge} years of age only`;
            }
          }
        } else if (dep?.relation === "FIL" || dep?.relation === "MIL") {
          if (
            pd?.relation?.includes("PIL") ||
            pd?.relation?.includes(dep.relation)
          ) {
            if (
              getAge(dep.dateOfBirth) >
                (pd.maxAge !== null ? pd.maxAge : 1000) ||
              getAge(dep.dateOfBirth) < (pd.minAge !== null ? pd.minAge : -1)
            ) {
              mismatch = `this plan allows ${dep?.relation} between ${
                pd.minAge !== null ? pd.minAge : 0
              }-${pd.maxAge} years of age only`;
            }
          }
        } else if (pd?.relation?.includes(dep.relation)) {
          if (
            getAge(dep.dateOfBirth) > (pd.maxAge !== null ? pd.maxAge : 1000) ||
            getAge(dep.dateOfBirth) < (pd.minAge !== null ? pd.minAge : -1)
          ) {
            mismatch = `this plan allows ${dep?.relation} between ${
              pd.minAge !== null ? pd.minAge : 0
            }-${pd.maxAge} years of age only`;
          }
        }
      });
    });
    return mismatch;
  }, [enrolledDependants]);

  const showWorkingDeclarationErr = useMemo(() => {
    let response = "no err";
    if (!selectedPlan?.allowDependentInSameCompany) {
      enrolledDependants?.forEach((dep) => {
        if (dep?.isRelativeWorkingInNium) {
          response = `This plan does not allow to add dependents working in ${employeeDetails?.company?.displayName}`;
        }
      });
    }
    return response;
  }, [enrolledDependants]);

  const handleEditDependant = (id) => {
    dispatch(DependantActions.SaveDependantInfo(null));
    dispatch(DependantActions.SaveDependantAddress(null));
    history.push({
      pathname: "/adddependent",
      state: { isDepEdit: true, id: id },
    });
  };

  return (
    <div className="basicMedicalPage">
      <h4 className="page-heading-navbar">{planDisplayName}</h4>

      {
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={deleteLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }

      {/* {!loading ? ( */}
      <div className="data h-full flex flex-col justify-between overflow-y-auto">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <PageHeaderForPlans
            // btnRedirect={() =>
            //   setOpenModal(true)
            // }
            />

            <PageTitleAndDescription
              title={"Enrollment Details"}
              description={
                coveragePlan?.isDependantAllowed
                  ? `Add details for your dependents you wish to cover (be sure to read the eligibility rules) 
                  and check the box next to the name to enroll.${
                    employeeDetails && employeeDetails.planOptOut
                      ? "You also have the option to waive this coverage for yourself."
                      : ""
                  }`
                  : "You will be auto enrolled in this plan"
              }
            />

            <div className="selectAllDependants w-full mx-4">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-value="select-all-check"
                      color="success"
                      checked={selectAllChecked}
                      onChange={() => setSelectAllChecked(true)}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={selectAllChecked}
                    />
                  }
                  // labelPlacement="start"
                  label="Select All"
                />
              </FormGroup>
            </div>

            <div className="w-full mx-4">
              {
                <div className="radioButtonOuter">
                  {selectedPlan && selectedPlan.planOptOut && (
                    <button
                      className={classNames("radioButton", {
                        active: dontWantEnroll,
                      })}
                      onClick={() => {
                        selectedPlan &&
                          selectedPlan.planOptOut &&
                          setDontWantEnroll(!dontWantEnroll);
                        setElligibleToEnroll(true);
                      }}
                    >
                      <div className="radioOuter">
                        <img src={Rectangle} alt="" className="radio" />
                        <img
                          src={radioCheckIcon}
                          alt=""
                          className="radioCheck"
                        />
                      </div>
                      <FormattedMessage
                        id="dont.enroll"
                        defaultMessage="I don't want to enroll"
                      />
                    </button>
                  )}
                  {!dontWantEnroll && (
                    <button
                      className={classNames("radioButton", {
                        active: true,
                        //  isEmployeeEnroll,
                      })}
                      // onClick={() =>
                      //   selectedPlan &&
                      //   selectedPlan.planOptOut &&
                      //   setIsEmployeeEnroll(!isEmployeeEnroll)
                      // }
                    >
                      <div className="radioOuter">
                        <img src={Rectangle} alt="" className="radio" />
                        <img src={radioCheckIcon} alt="" className="radioCheck" />
                      </div>
                      {employeeDetails && employeeDetails.firstName}{" "}
                      {employeeDetails && employeeDetails.lastName}
                    </button>
                  )}

                  {loading ? (
                    <>
                      <Skeleton width={"48%"} height={69} />
                      <Skeleton width={"48%"} height={69} />
                      <Skeleton width={"48%"} height={69} />
                      <Skeleton width={"48%"} height={69} />
                    </>
                  ) : (
                    !dontWantEnroll &&
                    // selectedPlan?.allowDependents &&
                    // coveragePlan?.isDependantAllowed &&
                    allowedEmp?.map((dependent, i) => {
                      return (
                        <button
                          id="dependant-selection-button"
                          data-value={
                            enrolledDependants.filter(
                              (x) => x.id === dependent.id
                            ).length > 0
                              ? "selected-dependant"
                              : "not-selected-dependant"
                          }
                          className={classNames("radioButton justify-between", {
                            active:
                              enrolledDependants.filter(
                                (x) => x.id === dependent.id
                              ).length > 0,
                          })}
                          onClick={() => {
                            handleDependentSelection(dependent);
                          }}
                        >
                          <div className="flex flex-row justify-start capitalize">
                            <div className="radioOuter">
                              <img src={Rectangle} alt="" className="radio" />
                              <img
                                src={radioCheckIcon}
                                alt=""
                                className="radioCheck"
                              />
                            </div>
                            <div>
                              {dependent && dependent.firstName}{" "}
                              {dependent && dependent.lastName}
                            </div>
                          </div>
                          <span
                            onClick={() => handleEditDependant(dependent.id)}
                          >
                            <FiEdit />
                          </span>
                        </button>
                      );
                    })
                  )}
                </div>
              }
            </div>

            {isAgeMismatch !== "no err" && (
              <p className="enrollValidations">{isAgeMismatch}</p>
            )}
            {showWorkingDeclarationErr !== "no err" && (
              <p className="enrollValidations">{showWorkingDeclarationErr}</p>
            )}

            {dontWantEnroll === false &&
            (enrolledDependants && enrolledDependants.length) >
              (selectedPlan && selectedPlan.totalLiveMaxLimit) ? (
              <p className="enrollValidations">
                {/* <BsExclamationTriangle />   */}
                This plan policy does not allow to add more than{" "}
                {selectedPlan && selectedPlan.totalLiveMaxLimit} dependents{" "}
              </p>
            ) : (
              dontWantEnroll === false && (
                <p className="enrollValidations">
                  {elligiblePlanDependant &&
                    elligiblePlanDependant.map((dep) => {
                      return (
                        // dep && dep.relation &&
                        // dep.relation.map((relation) => {
                        //   return (
                        <p>
                          {
                            // getNoOfTimesRelationRepeated(relation)
                            getNoOfDependantEnrollFromThisPlan(
                              dep,
                              enrolledDependants
                            ) >
                            (dep.dependantLimit == null
                              ? 1000
                              : dep.dependantLimit)
                              ? `This plan policy does not allow to add more than ${
                                  dep && dep.dependantLimit
                                }${" "}${getRelationNames(dep.relation)}`
                              : ""
                          }
                        </p>
                        //   );
                        // })
                      );
                    })}
                </p>
              )
            )}
            {coveragePlan === null && (
              <p className="enrollValidations">
                {/* You don't have any coverage plan */}
                <FormattedMessage
                  id="basicMedical.youDon'tHaveAnyCoveragePlan"
                  defaultMessage="You don't have any coverage plan"
                />
              </p>
            )}

            {selectedPlan &&
            // selectedPlan.allowDependents &&
            coveragePlan &&
            // coveragePlan.isDependantAllowed ? (
              <div className="w-full linksOuter mx-4 mb-4">
                {dontWantEnroll === false && (
                  <p
                    data-value="add-dependant"
                    className="addLink"
                    onClick={() => {
                      dispatch(DependantActions.SaveDependantInfo(null));
                      dispatch(DependantActions.SaveDependantAddress(null));
                      history.push({
                        pathname: "/adddependent",
                        state: { addedDependent: enrolledDependants },
                      });
                    }}
                  >
                    {/* Add Dependent */}
                    <FormattedMessage
                      id="basicMedical.addDependent"
                      defaultMessage="Add Dependent"
                    />
                  </p>
                )}
                {dontWantEnroll === false && selectedPlan?.showCost && (
                    <p className="viewLink" onClick={() => handleViewCost()}>
                      {elligibleToEnroll && "View Cost"}
                    </p>
                  )}
              </div>
            // ) : (
            //   <div
            //     className="w-full linksOuter mx-4 mb-4"
            //     style={{ display: "flex", justifyContent: "flex-end" }}
            //   >
            //     {dontWantEnroll === false && selectedPlan?.showCost && (
            //         <p 
            //         data-value="view-cost"
            //         className="viewLink" 
            //         onClick={() => handleViewCost()}>
            //           {elligibleToEnroll &&
            //             coveragePlan !== null &&
            //             "View Cost"}
            //         </p>
            //       )}
            //   </div>
            // )
            }
          </div>
        </div>

        <div className="bottomButtonReq px-4">
          {/* <button className="next skip">I want to skip for now</button> */}
          <p className="mt-2 peer-invalid:visible text-gray-400 text-sm" style={{ marginBottom: "-1rem" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    checked={electionChecked}
                    onChange={() => setElectionChecked(!electionChecked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                // label="Click here to confirm"
                label={
                  <FormattedMessage
                    id="basicMedical.clickHereToConfirm"
                    defaultMessage="Click here to confirm"
                  />
                }
              />
            </FormGroup>
          </p>

          <ButtonM
            // text="SUBMIT"
            text={
              <FormattedMessage
                id="common.button.PLANSUBMIT"
                defaultMessage="Save as Draft"
              />
            }
            disabled={
              // dontWantEnroll === false &&
              !elligibleToEnroll ||
              coveragePlan === null ||
              enrollMentLoader ||
              !electionChecked ||
              isAgeMismatch !== "no err" ||
              showWorkingDeclarationErr !== "no err"
            }
            loading={enrollMentLoader}
            bgcolor={
              // dontWantEnroll === false &&
              !elligibleToEnroll ||
              coveragePlan === null ||
              !electionChecked ||
              isAgeMismatch !== "no err" ||
              showWorkingDeclarationErr !== "no err"
                ? "gray"
                : ""
            }
            onpress={() => handleEnrollment()}
          />
        </div>
      </div>
      {/* ) : (
        <Loader size="100" />
        
                    
      )} */}

      {viewCost && (
        <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-full h-full popupWrapper">
            <div className="totalCostPopup" ref={wrapperRef}>
              <GetCostVersion2
                onBack={() => setViewCost(false)}
                loader={
                  getCostLoader
                  // true
                }
                costData={costData}
                payCheckFrequency={PayCheckFreq}
              />
            </div>
          </div>
        </div>
      )}
      {/* {openModal && <GroupMedicalModal setOpenModal={setOpenModal} isDependantAllowed={coveragePlan?.isDependantAllowed} />} */}
    </div>
  );
};

export default BasicMedical;