import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../api/index';
import {actions , types} from "./action"
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";


const EmployeePlanEnrollmentRequest = function* EmployeePlanEnrollmentRequest({ payload }) {
    try {
        const { data } = yield call(api.getEmployeePlanEnroll, payload);
        if (data) {
            yield put(actions.EmployeePlanEnrollmentSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.EmployeePlanEnrollmentFailed());
        }
    } catch (err) {
        toast.info(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.detail : 'Something went wrong Please try again'}</div>);
        yield put(actions.EmployeePlanEnrollmentFailed());
    }
};
const CompanyBannerRequest = function* CompanyBannerRequest({payload}){
    try{
        const {data} = yield call(api.getAllBanners,payload);
        if(data){
            yield put(actions.CompanyBannerRequestSuccess(data));
        } else {
             toast.error(data.message);
            yield put(actions.CompanyBannerRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.CompanyBannerRequestFailed());
    }

};


export default function* sagas() {
    yield takeLatest(types.EMPLOYEE_PLAN_ENROLLMENT_REQUEST, EmployeePlanEnrollmentRequest);
    yield takeLatest(types.COMPANY_BANNER_REQUEST,CompanyBannerRequest)
    
}