import { useEffect, useState } from "react";
import Rectangle from "../../../../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../../../../assets/radioCheck.svg";
import ButtonM from "../../../../../common/Button";
// import VoluntaryParentalModal from "../../../ViewModal/index";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as VolutryActions } from "../../../redux/actions";
import { actions as DependantActions } from "../../../../BasicMedical/AddDependentAddress/redux/actions";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import { BiError } from "react-icons/bi";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";
import { getAllowedEmp, getElligiblePlanDependant, getEventName } from "components/utils/utils";

const classNames = require("classnames");

const EditBundleTypeAddParents = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    selectedCategories,
    progressArray,
    bundleEnrolls
  } = useSelector((state) => state?.voluntryReducer);

  const selectedPlan = useSelector((state) => state?.planReducer?.planData);

  const [openModal, setOpenModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [elligiblePlanDependant, setElligiblePlanDependant] = useState([]);
  const [allowedEmp, setAllowedEmp] = useState(null);
  const [allowedCurrentEmpDependants, setAllowedCurrentEmpDependants] = useState(null);
  const [enrolledDependants, setEnrolledDependants] = useState([]);
  const [currentPlanDependant, setCurrentPlanDependant] = useState({});
  const [preBundleEnolls, setPreBundleEnolls] = useState([]);
  const [currentNames, setCurrentNames] = useState('')

  const lifeEventConfiguratorDTO = useSelector((state) => state?.planReducer?.lifeEventConfiguratorDTO);
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const currentDependants = params?.category?.includes(
    "-"
  )
    ? params?.category.split("-")
    : params?.category;
  const empDependant = useSelector(
    (state) => state && state.dependents && state.dependents.allDependents
  );
  const dependantLoader = useSelector((state) => state?.dependents?.loader);
  const enrolledPlanData = useSelector((state) => state?.planReducer?.enrolledPlanData);
  const { employeeEnrolled, employeeEnrolledLoader } = useSelector((state) => state && state.empData);


  useEffect(() => {
     // saving previously selected coverages.
     setPreBundleEnolls(bundleEnrolls);
    
     // removing the category which is selected for edit from progressArray.
     const pArr = progressArray?.filter(x => x != params?.category);
     dispatch(VolutryActions.SetProgressArray(pArr));
 
     // removing the coverages which user wants to edit.
     const coverages = bundleEnrolls?.filter(x => x.category != params?.category);
     dispatch(VolutryActions.setBundleEnrollList(coverages));
    dispatch(
      DependantActions.GetDependentRequest({
        empId: employeeDetails && employeeDetails.id,
      })
    );
  }, []);

  useEffect(() => {
    const current = selectedCategories?.filter((x) => !progressArray.includes(x))[0];
    setCurrentNames(current);
  }, [progressArray]);

  useEffect(() => {
    getcurrentPlanDependant();
  }, [currentNames,progressArray]);

  useEffect(() => {
    if(selectedCategories?.length < 1){
    history.push("/voluntry");
  }
}, [])
  

  Array.prototype.equals = function (array, strict) {
    if (!array) return false;

    if (arguments.length == 1) strict = true;

    if (this.length != array.length) return false;

    for (var i = 0; i < this.length; i++) {
      if (this[i] instanceof Array && array[i] instanceof Array) {
        if (!this[i].equals(array[i], strict)) return false;
      } else if (strict && this[i] != array[i]) {
        return false;
      } else if (!strict) {
        return this.sort().equals(array.sort(), true);
      }
    }
    return true;
  };

  const getcurrentPlanDependant = () => {
    let obj = {};
    selectedPlan?.planDependants?.forEach((x) => {
        if (x.relation.includes(currentDependants)) {
          obj = x;
        }
    });
    setCurrentPlanDependant(obj);
  };

  useEffect(() => {
    if (currentDependants == "self") {
      !selectedPlan?.planOptOut && setEnrolledDependants(["Self"]);
    }
    setElligiblePlanDependant(getElligiblePlanDependant(selectedPlan, employeeDetails));
    
  }, [employeeDetails,progressArray]);

  useEffect(() => {
    setAllowedEmp(getAllowedEmp(empDependant, elligiblePlanDependant, lifeEventConfiguratorDTO));
  }, [elligiblePlanDependant, empDependant]);

  useEffect(() => {
    getallowedCurrentEmpDependants();
  }, [allowedEmp]);

  const handleDependentSelection = (dep) => {
    if (dep == "Self") {
      enrolledDependants?.includes(dep)
        ? setEnrolledDependants(enrolledDependants.filter((x) => x !== dep))
        : setEnrolledDependants([...enrolledDependants, dep]);
    } else {
      //to get already enrolled dependants
      if(lifeEventConfiguratorDTO != null){

        if(lifeEventConfiguratorDTO?.changeAllowed && !lifeEventConfiguratorDTO?.addAllowed) {
          (enrolledPlanData?.dependantEnrollments?.filter(x => x?.dependants?.id === dep.id)?.length > 0) &&
          (enrolledDependants.filter((x) => x.id === dep.id).length > 0
        ? setEnrolledDependants(enrolledDependants.filter((x) => x.id !== dep.id))
        : setEnrolledDependants([...enrolledDependants, dep]));        
      } 

        if(!lifeEventConfiguratorDTO?.changeAllowed && lifeEventConfiguratorDTO?.addAllowed) {
          (enrolledPlanData?.dependantEnrollments?.filter(x => x?.dependants?.id === dep.id)?.length === 0) &&
          (enrolledDependants.filter((x) => x.id === dep.id).length > 0
        ? setEnrolledDependants(enrolledDependants.filter((x) => x.id !== dep.id))
        : setEnrolledDependants([...enrolledDependants, dep])); 

      } 
        if(lifeEventConfiguratorDTO?.changeAllowed && lifeEventConfiguratorDTO?.addAllowed) {
          enrolledDependants.filter((x) => x.id === dep.id).length > 0
          ? setEnrolledDependants(enrolledDependants.filter((x) => x.id !== dep.id))
          : setEnrolledDependants([...enrolledDependants, dep]);
      } 

      }else{
         enrolledDependants.filter((x) => x.id === dep.id).length > 0
        ? setEnrolledDependants(
            enrolledDependants.filter((x) => x.id !== dep.id)
          )
        : setEnrolledDependants([...enrolledDependants, dep]);
      }
    }
  };

  const getallowedCurrentEmpDependants = () => {
    let arr = [];
    if (currentDependants == "PIL") {
      arr = allowedEmp?.filter((x) => ["MIL", "FIL"]?.includes(x.relation));
    } else if (currentDependants == "Parent") {
      arr = allowedEmp?.filter((x) =>
        ["Mother", "Father"]?.includes(x.relation)
      );
    } else {
      arr = allowedEmp?.filter(
        (x) =>
          currentDependants == x.relation ||
          currentDependants?.includes(x.relation)
      );
    }
    setAllowedCurrentEmpDependants(arr);
//to get already enrolled dependants
    if(lifeEventConfiguratorDTO != null && Object.keys(enrolledPlanData)?.length > 0){
      let arr1 = JSON.parse(JSON.stringify(enrolledPlanData?.dependantEnrollments?.map(x => x.dependants)?.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)));
      let arr2 = [];
     if (currentDependants == "PIL") {
      arr2 = arr1?.filter((x) => ["MIL", "FIL"]?.includes(x.relation));
    }
    if (currentDependants == "Parent") {
      arr2 = arr1?.filter((x) =>
        ["Mother", "Father"]?.includes(x.relation)
      );
    }    
    (arr?.length > 0) ? 
    setAllowedCurrentEmpDependants([...arr,...arr2]?.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i))
    :
    setAllowedCurrentEmpDependants(arr2?.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i));

    setEnrolledDependants(arr2?.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i))
    // console.log("enrolledPlanData",allowedCurrentEmpDependants);
    }
  };

  const handleAddDependent = (relation, type) => {
    if (type != null) {
      history.push({
        pathname: "/voluntry/bundle-add-dependant",
        state: { relation: relation, type: type },
      });
    } else {
      history.push({
        pathname: "/voluntry/bundle-add-dependant",
        state: { relation: relation },
      });
    }
  };

  const handleNext = () => {
    
    dispatch(VolutryActions.SetCurrentlyEnrolled(enrolledDependants));
    history.push("/voluntry/EditBundleCoverageType");
  };

  window.onpopstate = () => {
    // if user want to go back to individual final page then adding previous selction to "progressArray" and "selectedCoverages".
    dispatch(VolutryActions.SetProgressArray([...progressArray, params?.category]));
    dispatch(VolutryActions.setBundleEnrollList(preBundleEnolls));
};

  return (
    <div className="voluntryAddParentsPage">
      {
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={deleteLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }

      {selectedPlan && (
        <div>
          <h4 className="page-heading-navbar">{selectedPlan.displayName}</h4>
          <PageHeaderForPlans 
          // btnRedirect={() => setOpenModal(true)} 
          />
        </div>
      )}
      <div className="data">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <span
              className="PageTitle-And-Description w-full mt-4 mx-4"
              style={{ fontSize: "1rem" }}
            >
              <span className="details">
                <span className="title mb-2">
                  Add{" "}
                  {!(currentDependants instanceof Array) ? (
                    currentDependants == "self" ? (
                      <span style={{ color: "#edaf00" }}>
                        {employeeDetails?.firstName}
                      </span>
                    ) : (
                      <span style={{ color: "#edaf00" }}>
                        {currentDependants}
                      </span>
                    )
                  ) : (
                    currentDependants?.map((item, index) => {
                      return currentDependants.length - 1 === index ? (
                        <span style={{ color: "#edaf00" }}> and {item}</span>
                      ) : (
                        <span style={{ color: "#edaf00" }}>
                          {index !== 0 && ", "}
                          {item}
                        </span>
                      );
                    })
                  )}
                </span>
              </span>
            </span>

            <div className="w-full">
              <div className="radioButtonOuter">
                {dependantLoader ? (
                  <>
                    <Skeleton width={"48%"} height={69} />
                    <Skeleton width={"48%"} height={69} />
                    <Skeleton width={"48%"} height={69} />
                    <Skeleton width={"48%"} height={69} />
                  </>
                ) : (
                  allowedCurrentEmpDependants?.map((dependent, i) => {
                    return (
                      <button
                        className={classNames("radioButton justify-between", {
                          active:
                            enrolledDependants.filter(
                              (x) => x.id === dependent.id
                            ).length > 0,
                        })}
                        onClick={() => {
                          handleDependentSelection(dependent);
                        }}
                      >
                        <div className="flex flex-row justify-start capitalize">
                          <div className="radioOuter">
                            <img src={Rectangle} alt="" className="radio" />
                            <img
                              src={radioCheckIcon}
                              alt=""
                              className="radioCheck"
                            />
                          </div>
                          <div>
                            {dependent && dependent.firstName}{" "}
                            {dependent && dependent.lastName}
                          </div>
                        </div>
                      </button>
                    );
                  })
                )}

              {
                (lifeEventConfiguratorDTO!=null) && ((!lifeEventConfiguratorDTO?.addAllowed)||(!lifeEventConfiguratorDTO?.changeAllowed)) && 
                <p className="enrollValidations" style={{color:"var(--primary)"}}>
                  This plan does not allow to 
                  {(!lifeEventConfiguratorDTO?.addAllowed) &&  " add "}                    
                  {(!lifeEventConfiguratorDTO?.changeAllowed) && (!lifeEventConfiguratorDTO?.addAllowed) && " / "}
                  {(!lifeEventConfiguratorDTO?.changeAllowed) &&  " change "}
                    
                  dependent upon reporting a {getEventName(employeeEnrolled, selectedPlan?.id)}.
                </p>
              }

                {enrolledDependants?.length >
                  currentPlanDependant?.dependantLimit && (
                  <p className="flex items-center mx-auto my-4" style={{color:"var(--warning)"}}>
                    <BiError style={{ fontSize: "1.2rem" }} />
                    &nbsp; Can not select more than{" "}
                    {currentPlanDependant?.dependantLimit} dependants in this
                    category
                  </p>
                )}
                <>
                  {currentDependants == "self" && (
                    <button
                      className={classNames(
                        "radioButton justify-between items-center",
                        {
                          active:
                            // selectedPlan?.planOptOut ?
                            enrolledDependants.includes("Self"),
                          //     : true,
                        }
                      )}
                      onClick={() => {
                        selectedPlan?.planOptOut &&
                          handleDependentSelection("Self");
                      }}
                    >
                      <div className="flex flex-row">
                        <div className="radioOuter">
                          <img src={Rectangle} alt="" className="radio" />
                          <img
                            src={radioCheckIcon}
                            alt=""
                            className="radioCheck"
                          />
                        </div>
                        <div className="flex flex-col items-start">
                          <div className="text-left">
                            {employeeDetails?.firstName}{" "}
                            {employeeDetails?.lastName}
                          </div>
                          <div className="subTitle">{currentDependants}</div>
                        </div>
                      </div>
                      <span
                      // onClick={() => deleteDependant(item.id)}
                      >
                        {/* <RiDeleteBin5Fill /> */}
                      </span>
                    </button>
                  )}
                  {currentDependants == "PIL" && !dependantLoader && (
                    <div className="w-full linksOuter mx-4 my-4">
                      <p
                        className="addLink"
                        onClick={() =>
                          handleAddDependent([`FIL`, `MIL`], "PIL")
                        }
                      >
                        Add Dependent
                      </p>
                    </div>
                  )}
                  {currentDependants?.toLowerCase() == "parent" && !dependantLoader && (
                      <div className="w-full linksOuter mx-4 mb-8">
                        <p
                          className="addLink"
                          onClick={() => {
                            handleAddDependent(["Father", "Mother"], "Parent");
                          }}
                        >
                          Add Dependent
                        </p>
                      </div>
                    )}
                </>
              </div>
            </div>
            <div className="bottomButtonReq">
              <ButtonM
                text="NEXT"
                disabled={
                  enrolledDependants?.length >
                    currentPlanDependant?.dependantLimit ||
                  enrolledDependants?.length == 0
                }
                bgcolor={
                  enrolledDependants?.length >
                    currentPlanDependant?.dependantLimit ||
                  enrolledDependants?.length == 0
                    ? "gray"
                    : ""
                }
                onpress={() => handleNext()}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {openModal && <VoluntaryParentalModal setOpenModal={setOpenModal} />} */}
    </div>
  );
};

export default EditBundleTypeAddParents;
