import { useEffect, useRef } from "react";
import BenSumTable from "components/pages/BenefitSummary/BenSumTable/BenSumTable";
import LineGreenIcon from "../../../../assets/LineGreen.svg";
import { CircularProgress, LinearProgress } from "@mui/material";

const BenSumModal = ({ setOpenModal, loadingSummary, summaryData, enrollMentLoader, handleEnrollment, location }) => {
  function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenModal(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);

  return (
    <div className="learningPageCoverageDetails">
      <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mr-auto md:ml-auto max-w-3xl">
          <div className="CoverageDetailsPopup" ref={wrapperRef}>
            <div className="content">
              <div className="container benSumInFinalEnroll">
                <div className="fex flex-wrap">
                  <img src={LineGreenIcon} alt="Line" className="lineGreen" />
                </div>
                <BenSumTable
                  loadingSummary={loadingSummary}
                  summaryData={summaryData}
                  location={location}
                />
              </div>
              <div className="flex flex-wrap justify-between w-full total">
                {/* <button className="backBtn" onClick={() => setOpenModal(false)}>
                  Back
                </button> */}
                <p className="userDisclaimer mt-4">
                {/* I agree the information provided above is true and correct. */}
                 {/* I acknowledge on final submission that any inaccurate data or misrepresentation will be cause for refusal of Enrollment. */}
                 I acknowledge on final submission, that the information furnished is accurate and true to the best of my knowledge. I agree that any inaccurate data or misrepresentation will be cause for refusal of enrollment and or cancellation of any claims / policy coverage, including premium refunds (if applicable).                 <br /><br />
                 In case of opting into Voluntary Parental Plan, I agree for the premiums to be deducted from my payroll in applicable months as communicated already.
                 </p>

                {enrollMentLoader ? (
                <div className="mt-4 mx-auto">
                  <CircularProgress color="success" size={29} />
                </div>
              ) : (
                <>                
                 {
                  !loadingSummary && 
                  <div className="planPopUpBtns mt-4">
                  <button className="yesbtn" onClick={() => handleEnrollment()} >
                    Confirm
                  </button>
                  <button className="nobtn" onClick={() => setOpenModal(false)} >
                    Decline
                  </button>
                </div>
                 }
                
                </>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenSumModal;
