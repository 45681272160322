import { handleActions } from 'redux-actions';
import { types } from './action';

const actionHandler = {
    [types.GET_NOTIFICATION_COUNT]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.GET_NOTIFICATION_COUNT_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        NotificationCount: payload,
    }),
    [types.GET_NOTIFICATION_COUNT_FAILED]: (state, { payload }) => ({
        ...state,
        loader: false,
        error: payload
    }),

    [types.GET_NOTIFICATION_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.GET_NOTIFICATION_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        Notifications: payload || {},

    }),
    [types.GET_NOTIFICATION_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,

    }),



};

export default handleActions(actionHandler, {
    loader: false,
    Notifications: [],
    NotificationCount: "",
});
