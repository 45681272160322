import { useEffect } from "react";
import * as React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import ButtonM from "../../../common/Button";
import { useDispatch, useSelector } from "react-redux";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from 'react-toastify';
import APIList from '../../../../api/index';
import { isEmptyNullUndefined, requiredStyled } from "components/utils/utils";
import { actions as DependantActions } from "./redux/actions";
import { FormattedMessage } from "react-intl";

const AddDependentAddress = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const empDetails = useSelector((state) => state && state.empData);
  const dependantDetails = location && location.state && location.state.dependant;
  const [checked, setChecked] = React.useState(false);
  const [addLoading, setAddLoading] = React.useState(false);

  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const enrolledPlanData = useSelector((state) => state?.planReducer?.enrolledPlanData);
  const tempDependantAddress = useSelector((state) => state?.dependents?.tempDependantAddress);


  useEffect(
    () => {
      location &&
        (!location.state || (location.state && !location.state.dependant)) &&
        history.push("/adddependent");
    },
    [
      // dispatch
    ]
  );

  const initialEmptyDependentAddress = {
    streetLineOne: null,
    streetLineTwo: null,
    city: null,
    state: null,
    postalCode: null,
  };

  const initialFormErrorList = {
    streetLineOne: { isError: false, errorMessage: "" },
    streetLineTwo: { isError: false, errorMessage: "" },
    city: { isError: false, errorMessage: "" },
    state: { isError: false, errorMessage: "" },
    postalCode: { isError: false, errorMessage: "" },
  };

  const [dependentAddress, setDependentAddress] = React.useState({});
  const [formError, setFormError] = React.useState(initialFormErrorList);

  useEffect(() => {
    if(location?.state?.isDepEdit){
      if(!isEmptyNullUndefined(tempDependantAddress)){
        setDependentAddress(tempDependantAddress);
      } else {
        setDependentAddress(location?.state?.dependant?.address);
      }         
    } else {
      if(!isEmptyNullUndefined(tempDependantAddress)){
        setDependentAddress(tempDependantAddress);
      } else {
        setDependentAddress(initialEmptyDependentAddress);
      }      
    }
  }, [])
  

  const handleResetForm = () => {
    const tempInitDependent = JSON.parse(JSON.stringify(initialEmptyDependentAddress));

    const tempInitError = JSON.parse(JSON.stringify(initialFormErrorList));
    setDependentAddress(tempInitDependent);
    setFormError(tempInitError);
  };

  const handleChangeDependentAddress = async (event, fieldType) => {
    const tempDependentAddress = JSON.parse(JSON.stringify(dependentAddress));
    const tempError = JSON.parse(JSON.stringify(formError));
    if (fieldType === "textfield" || fieldType === "dropdown") {
      tempDependentAddress[event.target.name] = event.target.value;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    } else if (fieldType == "onlyNumber") {
      tempDependentAddress[event.target.name] = event.target.value.replace(
        /[^0-9]/g,
        ""
      );
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    }
    
    setDependentAddress(tempDependentAddress);
    setFormError(tempError);
    dispatch(DependantActions.SaveDependantAddress(tempDependentAddress));
  };

  const handleValidateDependent = async () => {
    let isValid = true;
    // const tempError = JSON.parse(JSON.stringify(formError));
    // const tempDependentAddress = JSON.parse(JSON.stringify(dependentAddress));

    // if (
    //   tempDependentAddress.streetLineOne === null ||
    //   tempDependentAddress.streetLineOne.trim() === ""
    // ) {
    //   tempError.streetLineOne.isError = true;
    //   tempError.streetLineOne.errorMessage =
    //     "Please enter stree address line 1";
    //   isValid = false;
    // }
    // if (
    //   tempDependentAddress.streetLineTwo === null ||
    //   tempDependentAddress.streetLineTwo.trim() === ""
    // ) {
    //   tempError.streetLineTwo.isError = true;
    //   tempError.streetLineTwo.errorMessage =
    //     "Please enter stree address line 2";
    //   isValid = false;
    // }

    // if (
    //   tempDependentAddress.city === null ||
    //   tempDependentAddress.city.trim() === ""
    // ) {
    //   tempError.city.isError = true;
    //   tempError.city.errorMessage = "Please enter City";
    //   isValid = false;
    // }
    // if (
    //   tempDependentAddress.state === null ||
    //   tempDependentAddress.state.trim() === ""
    // ) {
    //   tempError.state.isError = true;
    //   tempError.state.errorMessage = "Please enter State";
    //   isValid = false;
    // }
    // if (
    //   tempDependentAddress.postalCode === null ||
    //   tempDependentAddress.postalCode.toString().trim() === ""
    // ) {
    //   tempError.postalCode.isError = true;
    //   tempError.postalCode.errorMessage = "Please enter Postal Code";
    //   isValid = false;
    // }

    //do Submit
    if (isValid || checked) {
      // if (isEdit) {
      //   updateDependent();
      // } else {
      submitDependent();
      // }
    } 
    // else {
    //   setFormError(tempError);
    // }
  };

  const isEdit = () => {
    let edit = false;
    if(enrolledPlanData?.plan?.id == selectedPlan?.id){
       edit = true;
    }
    return edit;
  };

  const submitDependent = () => {
    setAddLoading(true);
    if(location?.state?.isDepEdit){
      APIList.updateDependant({
        id: dependantDetails?.id,
        firstName: dependantDetails && dependantDetails.firstName,
        middleName: (dependantDetails && dependantDetails.middleName) || "",
        lastName: dependantDetails && dependantDetails.lastName,
        relation: dependantDetails && dependantDetails.relation,
        dateOfBirth: dependantDetails && dependantDetails.dateOfBirth,
        nationalId: dependantDetails && dependantDetails.nationalId,
        idType: dependantDetails && dependantDetails.idType,
        gender: dependantDetails && dependantDetails.gender,
        nationality: dependantDetails && dependantDetails.nationality,
        isRelativeWorking: dependantDetails && dependantDetails.isRelativeWorking,
        isRelativeWorkingInNium: dependantDetails && dependantDetails.isRelativeWorkingInNium,
        employeeIdOfRelative: dependantDetails && dependantDetails.employeeIdOfRelative,
        employee: {
          id: empDetails && empDetails.empData && empDetails.empData.id,
        },
        address: !checked
          ? {
              streetLineOne:
                dependentAddress && dependentAddress.streetLineOne,
              streetLineTwo:
                dependentAddress && dependentAddress.streetLineTwo,
              city: dependentAddress && dependentAddress.city,
              state: dependentAddress && dependentAddress.state,
              postalCode: dependentAddress && dependentAddress.postalCode,
            }
          : empDetails && empDetails.empData && empDetails.empData.address,
      })
      .then((res) => {
        res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Updated {dependantDetails && dependantDetails.relation} successfully</div>);
        dispatch(DependantActions.SaveDependantInfo(null));
        dispatch(DependantActions.SaveDependantAddress(null));
        setAddLoading(false);
        isEdit() ? 
        history.push({ pathname: `/basicMedical/${selectedPlan?.id}`, state: { fromAddress: true } })
        :
        history.push({ pathname: "/basicMedical", state: { fromAddress: true } });
      })
      .catch((err) =>{
        toast.error(
          <div className='flex flex-row'>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );      
        setAddLoading(false);      
      }
      );
    } else {
      APIList.addDependant({
        firstName: dependantDetails && dependantDetails.firstName,
        middleName: (dependantDetails && dependantDetails.middleName) || "",
        lastName: dependantDetails && dependantDetails.lastName,
        relation: dependantDetails && dependantDetails.relation,
        dateOfBirth: dependantDetails && dependantDetails.dateOfBirth,
        nationalId: dependantDetails && dependantDetails.nationalId,
        idType: dependantDetails && dependantDetails.idType,
        gender: dependantDetails && dependantDetails.gender,
        nationality: dependantDetails && dependantDetails.nationality,
        isRelativeWorking: dependantDetails && dependantDetails.isRelativeWorking,
        isRelativeWorkingInNium: dependantDetails && dependantDetails.isRelativeWorkingInNium,
        employeeIdOfRelative: dependantDetails && dependantDetails.employeeIdOfRelative,
        employee: {
          id: empDetails && empDetails.empData && empDetails.empData.id,
        },
        address: !checked
          ? {
              streetLineOne:
                dependentAddress && dependentAddress.streetLineOne,
              streetLineTwo:
                dependentAddress && dependentAddress.streetLineTwo,
              city: dependentAddress && dependentAddress.city,
              state: dependentAddress && dependentAddress.state,
              postalCode: dependentAddress && dependentAddress.postalCode,
            }
          : empDetails && empDetails.empData && empDetails.empData.address,
      })
      .then((res) => {
        res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Added {dependantDetails && dependantDetails.relation} successfully</div>);
        dispatch(DependantActions.SaveDependantInfo(null));
        dispatch(DependantActions.SaveDependantAddress(null));
        setAddLoading(false);
        isEdit() ? 
        history.push({ pathname: `/basicMedical/${selectedPlan?.id}`, state: { fromAddress: true } })
        :
        history.push({ pathname: "/basicMedical", state: { fromAddress: true } });
      })
      .catch((err) =>{
        toast.error(
          <div className='flex flex-row'>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );      
        setAddLoading(false);      
      }
      );
    }
    
  };

  return (
    <div className="AddDependentPage h-full">
      <h4 className="page-heading-navbar">
        {/* Add Dependent */}
        {location?.state?.isDepEdit ? <FormattedMessage id="basicMedical.editDependent" defaultMessage="Edit Dependent"/> : <FormattedMessage id="basicMedical.addDependent" defaultMessage="Add Dependent"/>}
      </h4>

      <Stack sx={{ mx: { xs: 0, xl: 10, md: 5 } }} height="100%">
        <Box sx={{ my: 2 }} height="100%">
          <Stack
            display={{ xs: "flex", sm: "flex" }}
            direction={{ xs: "column", sm: "column" }}
            justifyContent="space-between"
            alignItems={{ xs: "center", sm: "center" }}
            spacing={2}
            height="100%"
          >
            <Stack width={{ xs: "90%", sm: "75%" }} height="100%">
              <div className="h-full flex flex-col justify-between">
                <div>
                  <div className="mt-4">
                    <div className="flex flex-wrap">
                      <div className="w-full flex justify-between">
                        <div className="details">
                          <h3 className="title">
                            {/* Address */}
                            <FormattedMessage id="basicMedical.address" defaultMessage="Address"/>
                          </h3>
                          <p className="subTitle">
                            {/* Add the address for your dependent.{" "} */}
                            <FormattedMessage id="basicMedical.addressForYourDependent" defaultMessage="Add the address for your dependent."/>{" "}
                          </p>
                        </div>

                      </div>
                    </div>
                  </div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="success"
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      // label="Address same as me"
                      label={<FormattedMessage id="basicMedical.addressSameAsMe" defaultMessage="Address same as me"/>}
                    />
                  </FormGroup>
                  {!checked && (
                    <>
                      <TextField
                        size="small"
                        name="streetLineOne"
                        // label="Street Address Line 1"
                        label={<FormattedMessage id="basicMedical.streetAddressLine1" defaultMessage="Street Address Line 1"/>}
                        id="outlined-basic"
                        onChange={(event) =>
                          handleChangeDependentAddress(event, "textfield")
                        }
                        variant="outlined"
                        error={formError.streetLineOne.isError}
                        helperText={formError.streetLineOne.errorMessage}
                        value={
                          dependentAddress.streetLineOne
                            ? dependentAddress.streetLineOne
                            : ""
                        }
                        sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                            border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          // ...requiredStyled
                        }}
                      />
                      <TextField
                        size="small"
                        name="streetLineTwo"
                        // label="Street Address Line 2"
                        label={<FormattedMessage id="basicMedical.streetAddressLine2" defaultMessage="Street Address Line 2"/>}
                        id="outlined-basic"
                        onChange={(event) =>
                          handleChangeDependentAddress(event, "textfield")
                        }
                        variant="outlined"
                        error={formError.streetLineTwo.isError}
                        helperText={formError.streetLineTwo.errorMessage}
                        value={
                          dependentAddress.streetLineTwo
                            ? dependentAddress.streetLineTwo
                            : ""
                        }
                        sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                            border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          // ...requiredStyled
                        }}
                      />
                      <TextField
                        size="small"
                        name="city"
                        // label="City"
                        label={<FormattedMessage id="basicMedical.city" defaultMessage="City"/>}
                        id="outlined-basic"
                        onChange={(event) =>
                          handleChangeDependentAddress(event, "textfield")
                        }
                        variant="outlined"
                        error={formError.city.isError}
                        helperText={formError.city.errorMessage}
                        value={
                          dependentAddress.city ? dependentAddress.city : ""
                        }
                        sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                            border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          // ...requiredStyled
                        }}
                      />

                      <TextField
                        size="small"
                        name="state"
                        // label="State"
                        label={<FormattedMessage id="basicMedical.state" defaultMessage="State"/>}
                        id="outlined-basic"
                        onChange={(event) =>
                          handleChangeDependentAddress(event, "textfield")
                        }
                        variant="outlined"
                        error={formError.state.isError}
                        helperText={formError.state.errorMessage}
                        value={
                          dependentAddress.state ? dependentAddress.state : ""
                        }
                        sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                            border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          // ...requiredStyled
                        }}
                      />

                      <TextField
                        size="small"
                        name="postalCode"
                        // label="Postal Code"
                        label={<FormattedMessage id="basicMedical.postalCode" defaultMessage="Postal Code"/>}
                        id="outlined-basic"
                        onChange={(event) =>
                          handleChangeDependentAddress(event, "onlyNumber")
                        }
                        variant="outlined"
                        error={formError.postalCode.isError}
                        helperText={formError.postalCode.errorMessage}
                        value={
                          dependentAddress.postalCode
                            ? dependentAddress.postalCode
                            : ""
                        }
                        sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                            border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          // ...requiredStyled
                        }}
                      />
                    </>
                  )}
                </div>
                <div className="bottomButtonReq">
                  <ButtonM
                    // text="SUBMIT"
                    text={<FormattedMessage id="basicMedical.SUBMIT" defaultMessage="SUBMIT"/>}
                    disabled={addLoading}
                    loading={addLoading}
                    onpress={() => handleValidateDependent()}
                  />
                </div>
              </div>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </div>
  );
};

export default AddDependentAddress;
