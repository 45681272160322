import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../api/index';
import { actions, types } from './actions';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
 

const CompanyPlanDataRequest = function* CompanyPlanDataRequest({ payload }) {
    try {
        const { data } = yield call(api.getCompanyPlanData, payload);
        if (data) {
            yield put(actions.CompanyPlanDataRequestSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.CompanyPlanDataRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.CompanyPlanDataRequestFailed());
    }
};

const EventDetailRequest = function* EventDetailRequest({ payload }) {
    try {
        const { data } = yield call(api.getEventDetails, payload);
        if (data) {
            yield put(actions.EventDetailRequestSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.EventDetailRequestFailed());
        }
    } catch (err) {
        toast.info(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;  No life event  configuration found  </div>);
        yield put(actions.EventDetailRequestFailed());
    }
};


const PostEventDetailRequest = function* PostEventDetailRequest({ payload }) {
    try {
        const { data } = yield call(api.getDeEnroll, payload);
        if (data) {
            yield put(actions.PostEventDetailRequestSuccess(data));
         data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
             toast.error(data.message);
            yield put(actions.PostEventDetailRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.PostEventDetailRequestFailed());
    }
};



export default function* sagas() {
    yield takeLatest(types.COMPANY_PLAN_DATA_REQUEST, CompanyPlanDataRequest);
    yield takeLatest(types.EVENT_DETAIL_REQUEST, EventDetailRequest);
     yield takeLatest (types.POST_EVENT_DETAIL_REQUEST, PostEventDetailRequest);
     
}
