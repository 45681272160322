import { useEffect, useRef } from "react";

const ReimModal = ({setViewDetails, children }) => {

  function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // console.log("clicked :- ");
          setViewDetails(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);
  
  return (
    <div className="learningPageCoverageDetails reimbursementModal">
      <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mr-auto md:ml-auto max-w-3xl">
          <div className="CoverageDetailsPopup" ref={wrapperRef}>
            <div className="content">
              <div className="container h-full">
            
                {children}

              </div>
              <div className="flex flex-wrap justify-between w-full total">
                <button
                  className="backBtn"
                  onClick={() => setViewDetails(false)}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReimModal;
