const { Skeleton } = require("@mui/material")

const PlanSkeleton = () => {
    return(
        <div className="planSkeletonOnplansPage">
        {/* <div className="largeScreenTimeline">

          <div className="flex justify-end flex-row-reverse  my-8">
            <Skeleton variant="circular" sx={{margin:"1rem"}} width={50} height={50} />
            <Skeleton variant="rounded" sx={{width:"45%"}} height={115} />
          </div>
          <div className="flex justify-end  my-8">
            <Skeleton variant="circular" sx={{margin:"1rem"}} width={50} height={50} />
            <Skeleton variant="rounded" sx={{width:"45%"}} height={115} />
          </div>
          <div className="flex justify-end flex-row-reverse  my-8">
            <Skeleton variant="circular" sx={{margin:"1rem"}} width={50} height={50} />
            <Skeleton variant="rounded" sx={{width:"45%"}} height={115} />
          </div>
          <div className="flex justify-end  my-8">
            <Skeleton variant="circular" sx={{margin:"1rem"}} width={50} height={50} />
            <Skeleton variant="rounded" sx={{width:"45%"}} height={115} />
          </div>

        </div> */}
        <div className="smallScreenTimeline">

          <div className="flex justify-around  my-8">
            <Skeleton variant="circular" width={50} height={50} />
            <Skeleton variant="rounded" sx={{width:"90%"}} height={115} />
          </div>
          <div className="flex justify-around  my-8">
            <Skeleton variant="circular" width={50} height={50} />
            <Skeleton variant="rounded" sx={{width:"90%"}} height={115} />
          </div>
          <div className="flex justify-around  my-8">
            <Skeleton variant="circular" width={50} height={50} />
            <Skeleton variant="rounded" sx={{width:"90%"}} height={115} />
          </div>
          <div className="flex justify-around  my-8">
            <Skeleton variant="circular" width={50} height={50} />
            <Skeleton variant="rounded" sx={{width:"90%"}} height={115} />
          </div>

        </div>
        </div>
    )
}

export default PlanSkeleton;