import { type } from "@testing-library/user-event/dist/type";
import api from "./apiServices";
import { axiosInstanceCms } from "./apiServices";

/// Learning page medical Insurance api used in basics component
const getLearningPageData = (planId, roleId) => axiosInstanceCms.get(`/plan/applicable-tile?planId=${planId}&roleId=${roleId}`)
const leaveLearn = (payload) => {
  return axiosInstanceCms.get(`/plan/${payload}`);
};
const serviceProvider = (payload) => {
  return axiosInstanceCms.get(`/service-provider/${payload?.id}`);
};

const getEmployeeData = (payload) =>
  api.get(`/employee/email`);

const getEmployeeEnrolled = (payload) =>
  api.get(`/employee/employeeId/v2/${payload.empId}`);

const getFinalEnrolledPlans = (payload) =>
  api.get(`/employee/employee-enrollments/${payload.empId}`);

const getPlanByEmployeeId = (payload) =>
  api.get(`/interfaceplan/plan/${payload.empId}`);

const getAllDependents = (payload) =>
  api.get(`/dependants/employee/${payload.empId}?status=ACTIVE`);

const plansEnroll = (payload) => api.post(`/enrollment/isdraft/${payload?.empId}`, {enrollmentIds:payload?.enrollmentIds});

const pensionPlanEmployerContri = (payload) => api.get(`/interfaceplan/contribution/${payload?.id}?employeecontribution=${payload?.amount}&employeeId=${payload?.empId}&employeeContributionType=${payload?.coverageType}`);

//Dependant list with thier enroll info
const getDependantEnrollInfo = (payload) =>
  api.get(`/employee/dependant/enrolled/${payload.empId}`);

const getAllBanners = (payload) =>
  api.get(`/company-banners/company/${payload.companyId}?status=ACTIVE`);

//company plan
const getCompanyPlanData = (payload) =>
  api.get(`/company/lifeevent/${payload.companyId}`);

//company's event event/comapnyid

const getEventDetails = (payload) =>
  api.get(
    `/lifeeventconfigurator?companyId=${payload.companyId}&eventId=${payload.eventId}`
  );
//get dernroll

const getDeEnroll = (payload) =>
  api.get(`/enrollment/de-enroll?employeeId=${payload?.id}`);

//get employeeplanEnrollDetails
const getEmployeePlanEnroll = ({ employeeId, planClassname, planId }) =>
  api.get(
    `/employee/enrollment?employeeId=${employeeId}&planId=${planId}&className=${planClassname}`
  );
//GET EMPLOYEE CASE 
const getEmployeeCase = ({ id }) => api.get(`/case/employee/${id}`);
const getCaseDetail = ({ id }) => api.get(`/case/${id}`)
const updateEmployeeData = (id, payload) => api.put(`/employee/${id}`, payload);

const getEmpHealthInsurance = (payload) =>
  api.get(`/employee/healthinsurance/${payload.empId}`);

const getEnrollWizards = (payload) =>
  api.get(`/companyeventpolicy/${payload.Id}`);

const getPlanDetails = (payload) =>
  api.get(
    `/plans-messages?className=${payload.className}&employeeId=${payload.empId}&planId=${payload.Id}${payload.eventId != null ? `&eventId=${payload.eventId}&lifeEventId=${payload.lifeEventId}` : ""}`
  );

const getEligiblePlans = (payload) => {
 
  return api.get(`/employee/eligiblePlans/v2/${payload}?version=Current`)
}
const getFutureEligiblePlans = (payload) => {
 
  return api.get(`/employee/eligiblePlans/v2/${payload}?version=Future`)
}
///api/plans-messages?className=healthinsurance&employeeId={{employeeID}}&planId=d9829a68-d4a9-48c4-9299-5f88526abcf8

const getEnrolledPlanDetails = (payload) =>
  api.get(`/enrollment/${payload.enrollmentId}`);

const getCost = (payload) => api.post(`/interfaceplan/calculate-cost`, payload);

const authenticateUser = (payload) => api.post(`/authenticate`, payload);

const lookUpAccount = () => api.get(`/account`);

const addDependant = (payload) => api.post(`/dependants`, payload);

const updateDependant = (payload) =>
  api.put(`/dependants/${payload.id}`, payload);

const addBeneficiary = (payload) => api.post(`/beneficiary`, payload);

const getBenficiaryAllocationByEmployeeId = (payload) =>
  api.get(
    `/beneficiary-allocation/find-by?planId=${payload.planId}&employeeId=${payload.employeeId}&status=ACTIVE`
  );

const getEnrollmentById = (payload) =>
  api.get(`/employee/enrolled/employeeId/${payload.employeeId}`);

const addBeneficiaryAllocation = (payload) =>
  api.post(`/beneficiary-allocation`, payload.addedBeneficiaries);

const updateBeneficiaryAllocation = (payload) =>
  api.put(`/beneficiary-allocation/${payload.id}`, payload);

const deleteBeneficiaryAllocation = (payload) =>
  api.delete(`/beneficiary-allocation/${payload.id}`);

const healthInsuranceEnroll = (payload) =>
  api.post(`/enrollment?eventId=1`, payload);

const editHealthInsuranceEnroll = (payload) =>
  api.put(`/enrollment/${payload.id}`, payload);

const deleteDependant = (payload) => api.put(`/dependants/inactivate/${payload.depId}`);

const eventDelete = (payload) => api.delete(`/lifeEvent/${payload.Id}`);

const lifeInsurance = () =>
  api.get(
    `http://admin.benevolve.com/api/lifeinsurance/4dbc0f0d-4a71-4f6b-ba68-791754835a05`
  );

const config = {
  headers: {
    "Content-Type": "text/plain",
  },
};
const forgotPassword = (payload) =>
  api.post("/account/reset-password/init", payload, config);

const resetPassword = (payload) =>
  api.post("/account/reset-password/finish", payload);

const registerEvent = (payload) => api.post("/lifeEvent", payload);

const getEnums = (payload) =>
  api.get(`employee/beneficiaries/employeeId/${payload.empId}`);;

const fileDocUpload = (payload) => api.post(`/file/upload`, payload);
const enrollmentDocumentUpload = (payload) =>
  api.post(
    `/enrollment/upload?fileId=${payload.fileId}&enrollmentDocumentId=${payload.enrollmentDocumentId}`
  );
const fileUpload = (body) => {
  return api.post(`/file/upload`,
    body.formData,
    { headers: { ContentType: 'multipart/form-data' } })

};

const getAllReimClaims = (payload) => {
  return api.get(`/claim/employee/${payload.id}`)
}
const deleteClaim = (payload) => {
  return api.delete(`/claim/${payload.id}`)
}
const getClaim = (payload) => {
  return api.get(`/claim/${payload.id}`)
}
const reimburseClaims = (payload) => {
  return api.post(`/claim`, payload)
}
const remainingAmount = (payload) => {
  return api.get(`/claim/remaining-amount?employeeId=${payload.empId}&reimbursmentsId=${payload.reimId}`)
}
const commentApi = (body) => {
  return api.post(`/comments`, body)
}

const getEventLogs = (payload) => {
  return api.get(`/lifeEvent/${payload.id}`)
}
const raiseTicket = (body) => api.post(`/case`, body)

const changeAvatar = (body, employeeId) => {
  return api.put(`/employee/profile-photo/${employeeId}`, body)
}

const getPlans = (id) => {
  return api.get(`/employee/eligiblePlans/${id}`)
}

const getNotificationCount  = (payload) => {
  return api.get(`/notification/count/${payload}`)
}

const getNotification  = (payload) => {
 
  return api.get(`/notification/${payload.employeeId}?readStatus=${payload.type}`)
}

const UpdateNotification = (payload) => {
 
 console.log("payload",payload)
   return api.put(`/notification/`,payload)
}
const getAllPlanCost = (payload) => {
   return api.post(`/dashboard/employeecost`,payload)
}
const getFileFromURI = (payload) => {
  return api.get(`/file/download/path?path=${payload.filepath}`, {
    responseType: "arraybuffer",
  });
};
const benefitSummary = (payload) => {
  return api.get(`/employee/benefit-summary/${payload.empId}`);
};
const idTypes = (payload) => {
  return api.get(`/idType/companyID/${payload.id}?status=ACTIVE`);
};
const leaveHeader = (payload) => {
  return api.get(`/plans-messages?className=leave&employeeId=${payload?.empId}&planId=leaveHeaderdata`);
};

// Beneficiaries start
const getAllBeneficiaries = (payload) => api.get(`/beneficiary/employee/${payload.empId}?status=ACTIVE`);
const getBeneficiaryById = (payload) => api.get(`/beneficiary/${payload.benId}`);
const addNewBeneficiary = (payload) => api.post(`/beneficiary`, payload);
const updateBeneficiary = (payload) => api.put(`/beneficiary/${payload.id}`, payload);
// Beneficiaries end

const canEditDependant = (payload) => api.get(`/dependants/employee/canedit/${payload.id}`);

// calender start
const getCompanyEvents = (payload) => api.get(`/CompanyEvent/companyId/${payload?.companyId}`);
const getEmployeeEvents = (payload) => api.get(`/CompanyEvent/employeeId/${payload?.empId}`);
const addCompanyEvents = (payload) => api.post(`/CompanyEvent/addEvent`, payload);
const deleteCompanyEvents = (payload) => api.delete(`/CompanyEvent/${payload?.id}`);
const updateCompanyEvents = (payload) => api.put(`/CompanyEvent/${payload?.id}`, payload?.payload);
// calender end

// employee language start
const updateLanguage = (payload) => api.put(`/employee/default-language/${payload?.code}`);
// employee language end

const APIList = {
  getEmployeeData,
  getEmployeeEnrolled,
  getPlanByEmployeeId,
  getAllDependents,
  getEmpHealthInsurance,
  authenticateUser,
  lookUpAccount,
  addDependant,
  getLearningPageData,
  updateDependant,
  healthInsuranceEnroll,
  forgotPassword,
  resetPassword,
  updateEmployeeData,
  deleteDependant,
  addBeneficiary,
  getEnrollWizards,
  lifeInsurance,
  getCost,
  getPlanDetails,
  addBeneficiaryAllocation,
  updateBeneficiaryAllocation,
  getBenficiaryAllocationByEmployeeId,
  deleteBeneficiaryAllocation,
  getEnrollmentById,
  getCompanyPlanData,
  getEventDetails,
  registerEvent,
  getDeEnroll,
  getEnrolledPlanDetails,
  getEmployeePlanEnroll,
  getAllBanners,
  getEmployeeCase,
  fileDocUpload,
  enrollmentDocumentUpload,
  getEnums,
  getDependantEnrollInfo,
  fileUpload,
  raiseTicket,
  getCaseDetail,
  editHealthInsuranceEnroll,
  commentApi,
  getEventLogs,
  changeAvatar,
  getPlans,
  getEligiblePlans,
  getFutureEligiblePlans,
  getNotificationCount,
  getNotification,
  UpdateNotification,
  plansEnroll,
  getFinalEnrolledPlans,
  eventDelete,
  getAllPlanCost,
  getFileFromURI,
  reimburseClaims,
  getAllReimClaims,
  getClaim,
  benefitSummary,
  remainingAmount,
  pensionPlanEmployerContri,
  deleteClaim,
  idTypes,
  leaveLearn,
  leaveHeader,
  getAllBeneficiaries,
  getBeneficiaryById,
  addNewBeneficiary,
  updateBeneficiary,
  canEditDependant,
  getCompanyEvents,
  addCompanyEvents,
  getEmployeeEvents,
  deleteCompanyEvents,
  updateCompanyEvents,
  updateLanguage,
  serviceProvider,
};

export default APIList;
