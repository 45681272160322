import calendarIcon from "../../../assets/calendar.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import { getPlanIcon, getWaitingPeriod } from "components/utils/utils";
import ModalHeader from "../PageHeader/modal/ModalHeader";
import { useEffect, useState } from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import APIList from "api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import BenModal from "../Modal/BenModal";
import { CircularProgress } from "@mui/material";
const parse = require('html-react-parser');

const PageHeaderForPlans = ({ btnRedirect }) => {
  const history = useHistory();

  const employeeDetails = useSelector((state) => state && state.empData);
  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const messages = useSelector((state) => state?.planReducer?.planMsg);
  const benefitStartDate = useSelector((state) => state?.planReducer?.benefitStartDate);
  const eligibleplans = useSelector((state) => state && state?.empData?.eligibleplans);
  const elligiblePlanLoader = useSelector((state) => state && state?.empData?.elligiblePlanLoader);

  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [learningPageData, setLearningPageData] = useState(null);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    getBackendDataLearningPage()
  }, [])
  

  const getBackendDataLearningPage = () => {
    setLoader(() => true)
    APIList.getLearningPageData(selectedPlan?.id, employeeDetails?.empData?.employeeRole?.id)
    .then((response) => {
      setLearningPageData(() => response.data)
      setLoader(() => false)
    })
    .catch((error) => {      
      setFailedToLoad(true);
      // toast.error(
      //   <div className='flex flex-row'>
      //     <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
      //     &nbsp;&nbsp;{error?.title}
      //   </div>
      // ); 
      setLoader(() => false)
    })
    
  }

  // const getLaterDate = () => {
  
  //   const date1 = (moment(employeeDetails?.dateOfJoining).add((getWaitingPeriod(selectedPlan) || 0), "days"));     
                          
  //   const date2 = moment(selectedPlan?.planStartDate);
     
  //   if(date1 > date2){
  //     return date1.format(
  //       `${
  //         employeeDetails?.empData?.company?.dateFormat ? 
  //         employeeDetails?.empData?.company?.dateFormat?.toUpperCase() : 
  //         'DD-MM-YYYY'
  //       }`
  //     );
  //   }else{
  //     return date2.format(
  //       `${
  //         employeeDetails?.empData?.company?.dateFormat ? 
  //         employeeDetails?.empData?.company?.dateFormat?.toUpperCase() : 
  //         'DD-MM-YYYY'
  //       }`);
  //   }                                             
  // };

  return (
    <div className="BMheader w-full px-2">
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="pageheaderdata">
            <div className="pageheaderDataInneer w-4/5">
              {/* <h3 className="title">{heading}</h3> */}

              {messages?.map((item) => {
                return (
                  <>
                    {item.pageNo == "PAGE_2" && (
                      <p className="desc">{parse(item?.details)} </p>
                    )}
                  </>
                );
              })}

              <div className="flex-1 borderR">
                <p className="name">Benefits Start Date</p>
                <div className="flex">
                  <img src={calendarIcon} alt="" className="calendar" />
                  <span className="date">
                    {moment(benefitStartDate?.slice(0,10)).format(
                      `${
                        employeeDetails?.empData?.company?.dateFormat
                          ? employeeDetails?.empData?.company?.dateFormat?.toUpperCase()
                          : "DD/MM/YYYY"
                      }`
                    )}
                  </span>
                </div>
              </div>
            </div>

            <img
              src={getPlanIcon(eligibleplans, selectedPlan?.id)}
              alt="Medical Insurance"
              className="medicalImage h-1/2 w-20"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between pageheaderdatadata2">
        <div className="infoContainerDiv flex items-center">
        <button 
        onClick={() => setShowModal(true)}
        // onClick={() => history.push(`/learning-page/healthinsurance/base/${selectedPlan?.id}`)} 
        className="moreDetails">
          Click for more details
        </button>
        {/* <BsInfoCircleFill onClick={() => btnRedirect()} /> */}
        </div>

        <div className="eclipseOuter">
          <div className="righttovarybtn" onClick={() => setShowDisclaimer(true)}>
            Disclaimer
          </div>
          </div>
      </div>
      <ModalHeader openAnc={showDisclaimer} showModal={setShowDisclaimer} />
      <div className="new-learn-main-container">
      <div className="learn-modal-container">
      {
        showModal && 
          <BenModal setViewDetails={setShowModal}>
            {
              loader ? 
              <div className="flex justify-center">
              <CircularProgress />
              </div>
              :
              <div>
                {
                  failedToLoad ? 
                  "Learn pages are not created for this plan"
                  :
                  <div>
                    {
                      learningPageData?.tabs?.map((tab) => {
                        return(
                          
                          tab?.tabTitle === "The Basics" &&
                          tab?.tiles?.map((tile) => {
                            return(     
                              !(tile?.isPrimaryTile) &&                                                 
                                <div>
                                  {
                                    tile?.icon?.path && 
                                    <img src={tile?.icon?.path} alt="icon" />
                                  }
                                    <div className="tile-title">{tile?.tileTitle}</div>
                                    <div className="content-description">
                                        {
                                        tile?.description && parse(tile?.description)
                                        }
                                    </div>
                                </div>
                              )
                          })
                        )
                        
                        
                      })
                    }
                  </div>
                }
              </div>
            }
          </BenModal>
      }
      </div>
      </div>
    </div>
  );
};

export default PageHeaderForPlans;
