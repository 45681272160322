import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import createSagaMiddleware from "redux-saga";
import createRootReducer from "./reducers";
import sagas from "./sagas";
// import {applyMiddleware, createStore,} from "redux"
// import { composeWithDevTools } from "redux-devtools-extension";

export const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["homepageReducer", "user", "empData"],
};

export default function globalStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();
  const rootReducer = createRootReducer(history);
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [sagaMiddleware, routerMiddleware(history)],
    preloadedState
  }
    // persistedReducer, // root reducer with router state
    // preloadedState,
    // composeWithDevTools(
    //   applyMiddleware(sagaMiddleware, routerMiddleware(history))
    // )
  );
  sagaMiddleware.run(sagas);
  const persistor = persistStore(store);
  return {
    store,
    persistor,
  };
}
