import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.DEPENDANT_ENROLL_INFO_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.DEPENDANT_ENROLL_INFO_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        dependantEnrollInfo: payload || {},
    }),
    [types.DEPENDANT_ENROLL_INFO_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),

};

export default handleActions(actionHandler, {
    loader: false,
    dependantEnrollInfo: {},
});
