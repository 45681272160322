export const initialFormErrorList = {
  relation: { isError: false, errorMessage: "" },
  firstName: { isError: false, errorMessage: "" },
  middleName: { isError: false, errorMessage: "" },
  lastName: { isError: false, errorMessage: "" },
  gender: { isError: false, errorMessage: "" },
  idType: { isError: false, errorMessage: "" },
  nationalId: { isError: false, errorMessage: "" },
  dateOfBirth: { isError: false, errorMessage: "" },
  nationality: { isError: false, errorMessage: "" },
  isRelativeWorking: { isError: false, errorMessage: "" },
  isRelativeWorkingInNium: { isError: false, errorMessage: "" },
  employeeIdOfRelative: { isError: false, errorMessage: "" },
};

export const initialEmptyDependent = {
  relation: null,
  firstName: null,
  middleName: null,
  lastName: null,
  gender: null,
  idType: null,
  nationalId: null,
  dateOfBirth: null,
  nationality:null,
  isRelativeWorking:null,
  isRelativeWorkingInNium:null,
  employeeIdOfRelative:null,
};

export const initialAddressFormErrorList = {
  streetLineOne: { isError: false, errorMessage: "" },
  streetLineTwo: { isError: false, errorMessage: "" },
  city: { isError: false, errorMessage: "" },
  state: { isError: false, errorMessage: "" },
  postalCode: { isError: false, errorMessage: "" },
};

export const initialAddressForm = {
  streetLineOne: null,
  streetLineTwo: null,
  city: null,
  state: null,
  postalCode: null,
};





 