import * as yup from 'yup';

export const validationSchema = yup.object({
  firstName: yup
    .string('Enter your First Name')
    .required('First Name is required'),
  lastName: yup
    .string('Enter your Last Name')
    .required('Last Name is required'),
  dateOfBirth: yup
    .string('Enter date of Birth')
    .required('Date of Birth is required'),
  email: yup
    .string('Enter your Email')
    .email('Enter a valid email')
    .required('Email is required'),
  dateOfJoining: yup
    .string('Enter date of joining')
    .required('Date of Joining is required'),
  gender: yup.string('Enter your Gender').required('Gender is required'),
  streetLineOne: yup
    .string('Enter your Address Line One')
    .required('Address Line 1 is required'),
  streetLineTwo: yup
    .string('Enter your Address Line Two')
    .required('Address Line 2 is required'),
  city: yup.string('Enter your City').required('City is required'),
  state: yup.string('Enter your State').required('State is required'),
  postalCode: yup
    .string('Enter your Postal Code')
    .required('Postal Code is required'),
});
