import calendarIcon from "../../../assets/calendar.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import LeaveModel from "../Model/LeaveModel";
import { useState } from "react";
import ModalHeader from "./modal/ModalHeader";
import { isEmptyNullUndefined } from "components/utils/utils";
import { CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";
const parse = require("html-react-parser");

const PageHeader = ({
  heading,
  description,
  btnText,
  pageNumber,
  eIcon,
  planIcon,
  btnRedirect,
  date,
  waitingPeriod,
  type,
  leaveLoading
}) => {
  const employeeDetails = useSelector((state) => state && state.empData);
  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const messages = useSelector((state) => state?.planReducer?.planMsg);
  const [viewModel, setViewModel] = useState();
  const [showModal, setShowModal] = useState(false);

  const getLaterDate = () => {
    const date1 = moment(date?.slice(0,10)).add(waitingPeriod || 0, "days");

    const date2 = moment(selectedPlan?.planStartDate?.slice(0,10));

    if (date1 > date2) {
      return date1.format(
        `${
          employeeDetails?.empData?.company?.dateFormat
            ? employeeDetails?.empData?.company?.dateFormat?.toUpperCase()
            : "DD/MM/YYYY"
        }`
      );
    } else {
      return date2.format(
        `${
          employeeDetails?.empData?.company?.dateFormat
            ? employeeDetails?.empData?.company?.dateFormat?.toUpperCase()
            : "DD/MM/YYYY"
        }`
      );
    }
  };

  return (
    <div className="BMheader w-full px-2">
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="pageheaderdata">
            <div className="pageheaderDataInneer w-4/5">
              {/* <h3 className="title">{heading}</h3> */}

              {
              type && (type == "leave" || type == "learnPage") ? (
                
                  leaveLoading ? 
                  <CircularProgress />
                  :
                  <p className="desc">{!isEmptyNullUndefined(description) && parse(description)} </p>
                
                
              ) : (
                messages?.map((item) => {
                  return (
                    <>
                      {item.pageNo == "PAGE_2" && (
                        <p className="desc">{parse(item?.details)} </p>
                      )}
                    </>
                  );
                })
              )}

              {viewModel && (
                <LeaveModel data={{ setViewDetails: setViewModel }} />
              )}
              {date && (
                <div className="flex-1 borderR">
                  <p className="name">Benefits Start Date</p>
                  <div className="flex">
                    <img src={calendarIcon} alt="" className="calendar" />
                    <span className="date">{getLaterDate()}</span>
                  </div>
                </div>
              )}
            </div>
            {
              planIcon && 
               <img
              src={planIcon}
              alt="Medical Insurance"
              className="medicalImage h-1/2 w-20"
            />
            }           
          </div>
        </div>
      </div>

      {type && type == "leave" ? (
        <div className="flex justify-between pageheaderdatadata2">
          {btnText && (
            <button onClick={() => btnRedirect()} className="moreDetails">
              {btnText}
            </button>
          )}

          <div className="eclipseOuter">
            <div onClick={() => setViewModel(true)} className="righttovarybtn invisible">
              Summary of leave benefit
            </div>
          </div>

          <div className="eclipseOuter">
            <div onClick={() => setShowModal(true)} className="righttovarybtn">
              {/* Rights to vary program */}
              <FormattedMessage 
                    id="policyDetails.disclaimer.rightsToVaryProgram"
                    defaultMessage="Rights to vary program" 
                />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-end pageheaderdatadata2">
          {btnText && (
            <button onClick={() => btnRedirect()} className="moreDetails">
              {btnText}
            </button>
          )}

          <div className="eclipseOuter">
            <div onClick={() => setShowModal(true)} className="righttovarybtn">
            {/* Disclaimer */}
            <FormattedMessage 
                    id="policyDetails.disclaimer"
                    defaultMessage="Disclaimer" 
                />
            </div>
          </div>
        </div>
      )}

      <ModalHeader openAnc={showModal} showModal={setShowModal} />
    </div>
  );
};

export default PageHeader;
