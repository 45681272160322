import { employeeTypes } from "./action"
import { handleActions } from 'redux-actions';

const initialState = {
    loader: true,
    caseData: {},
    error: false,
    individualCase:{},

}

const reducerHandller = {
    [employeeTypes.GET_EMPLOYEE_CASE_REQUEST]: (state) => {
        return {
            ...state,
            loader: true
        }
    },
    [employeeTypes.GET_EMPLOYEE_CASE_REQUEST_SUCCESS]: (state, { payload }) => {
        return {
            loader: false,
            caseData: payload,
             }
    },
    [employeeTypes.GET_EMPLOYEE_CASE_REQUEST_FAIL]: (state, { payload }) => {

        return {
            loader: false,
            error: payload,
            }
    },


    [employeeTypes.GET_CASE_REQUEST]: (state) => {
        return {
            ...state,
            loader: true
        }
    },
    [employeeTypes.GET_CASE_REQUEST_SUCCESS]: (state, { payload }) => {
        return {
            loader: false,
            individualCase: payload,
             }
    },
    [employeeTypes.GET_CASE_REQUEST_FAIL]: (state, { payload }) => {

        return {
            loader: false,
            error: payload,
            }
    },
    

}

export default handleActions(reducerHandller, initialState)