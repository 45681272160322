import {
  Grid,
  Card,
  CardContent,
  Stack,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import ButtonM from "components/common/Button";
import { useSelector, useDispatch } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import APIList from "api";
import { toast } from "react-toastify";
import { actions as EmployeeDataActions } from "../../common/Header/redux/actions";
import { useEffect, useState } from "react";
import MaleLogo from "../../../assets/Used/manlogo.png";
import FemaleLogo from "../../../assets/Used/femalelogo.jpg";
import moment from "moment";
import axios from "axios";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiOutlineRight } from "react-icons/ai";
import { GiClick } from "react-icons/gi";
import { isEmptyNullUndefined } from "components/utils/utils";
import { AUTHENTICATE_URL } from "api/apiServices";
import { RiErrorWarningFill } from "react-icons/ri";
import Loader from "components/utils/Loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FormattedMessage } from "react-intl";

const Profile = () => {
  const history = useHistory();

  const { empData } = useSelector((state) => state.empData);
  const empDataLoader = useSelector((state) => state?.empData?.empData?.loader);
  // const user = useSelector((state) => state && state.user && state.user.user);

  const [uploadFileState, setUploadFileState] = useState({});
  const [isImgUploading, setIsImgUploading] = useState(false);

  const [isEditBankDetails, setIsEditBankDetails] = useState(false);
  const [updateBankLoader, setUpdateBankLoader] = useState(false);
  const [bankName, setBankName] = useState(null);
  // const [bankNameErr, setBankNameErr] = useState(false);
  const [bankBranchCode, setBankBranchCode] = useState(null);
  // const [bankBranchCodeErr, setBankBranchCodeErr] = useState(false);
  const [accountNumber, setAccountNumber] = useState(null);
  // const [accountNumberErr, setAccountNumberErr] = useState(false);

  const initialEmptyChangePasswordForm = {
    currentPassword: null,
    newPassword: null,
  };
  const initialEmptyChangePasswordFormErrorList = {
    currentPassword: { isError: false, errorMessage: "" },
    newPassword: { isError: false, errorMessage: "" },
  };

  const [changePasswordDetails, setChangePasswordDetails] = useState(initialEmptyChangePasswordForm);
  const [formError, setFormError] = useState(initialEmptyChangePasswordFormErrorList);
  const state = useSelector((state) => state);
  const [resetPasswordAcc, setResetPasswordAcc] = useState(false);

  const changePasswordApi = axios.create({baseURL: AUTHENTICATE_URL,});

  const isUserToken = () => {
    const userToken = state && state.user && state.user.userToken;
    return !!(userToken && Object.keys(userToken).length > 0);
  };

  useEffect(() => {
    setBankName(empData?.bankName);
    setBankBranchCode(empData?.bankBranchCode);
    setAccountNumber(empData?.accountNo);
  }, [empData]);

  changePasswordApi.interceptors.request.use((request) => {
    // Do something before request is sent
    request.baseURL = AUTHENTICATE_URL;
    const isToken = isUserToken();
    if (isToken) {
      const userToken =
        state &&
        state.user &&
        state.user.userToken &&
        state.user.userToken.id_token;
      request.headers.Authorization = "Bearer " + userToken;
    } else {
      request.headers = {};
    }
    return request;
  });

  const dispatch = useDispatch();
  const handelChangeEvent = (event) => {
    const { name, value, type } = event.target;

    if (type == "file") {
      const file =
        event.target.files.length > 0 ? event.target.files[0] : undefined;
      if (
        file.type.match("image/jpeg") ||
        file.type.match("image/jpg") ||
        file.type.match("image/svg") ||
        file.type.match("image/png")
      ) {
        if (file) {
          if (file.size > 2097152) {
            setUploadFileState({
              fname: file.name,
              fileError: "Please upload an Image smaller than 2 MB",
            });
            return;
          } else {
            setUploadFileState({
              fname: file.name,
              fileError: "",
            });

            setTimeout(() => uploadFile(file), 300);
          }
        }
      } else {
        setUploadFileState({
          fname: file.name,
          fileError: "Please upload file of type jpeg, jpg, svg or png",
        });
        return;
      }
    }
  };

  const uploadFile = async (file) => {
    setIsImgUploading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const body = {
        formData,
        headers: { ContentType: "multipart/form-data" },
      };
      const { status, data } = await APIList.fileUpload(body);
      if (status == 201) {
        setUploadFileState({
          ...data,
        });
      }

      let Apibody = {
        profilePhoto: {
          id: data?.id,
        },
      };
      let employeeId = empData?.id;

      try {
        const { status, data } = await APIList.changeAvatar(
          Apibody,
          employeeId
        );
        if (status == 201 || status == 200) {
          setIsImgUploading(false);
          dispatch(
            EmployeeDataActions.isProfilePicChange({
              action: true,
            })
          );
          toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;
              {"Image uploaded successfully!"}
            </div>
          );
          const { email } = JSON.parse(localStorage.getItem("userDetails"));
          setTimeout(() => {
            dispatch(EmployeeDataActions.EmployeeDataRequest());
          }, 2000);
        }
      } catch (err) {
        // toast.error(
        //   <div className='flex flex-row'>
        //     <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
        //     &nbsp;&nbsp;{"aaaaaa"}
        //   </div>
        // );
      }
    } catch (err) {
      toast.error(
        <div className="flex flex-row">
          <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
          &nbsp;&nbsp;{"Failed to upload"}
        </div>
      );
      setIsImgUploading(false);
    }
  };

  const handleChangePasswordForm = async (event, fieldType) => {
    const tempChangePasswordDetails = JSON.parse(
      JSON.stringify(changePasswordDetails)
    );
    const tempError = JSON.parse(JSON.stringify(formError));
    if (fieldType === "currentPassword") {
      tempChangePasswordDetails[event.target.name] = event.target.value;
      tempError.currentPassword.isError = false;
      tempError.currentPassword.errorMessage = "";
    }
    if (fieldType === "newPassword") {
      tempChangePasswordDetails[event.target.name] = event.target.value;
      tempError.newPassword.isError = false;
      tempError.newPassword.errorMessage = "";
    }

    setChangePasswordDetails(tempChangePasswordDetails);
    setFormError(tempError);
  };

  const handleValidateChangePasswordFormData = async () => {
    let isValid = true;
    const tempChangePasswordDetails = JSON.parse(
      JSON.stringify(changePasswordDetails)
    );
    const tempError = JSON.parse(JSON.stringify(formError));

    if (
      tempChangePasswordDetails.currentPassword === null ||
      tempChangePasswordDetails.currentPassword.trim() === ""
    ) {
      tempError.currentPassword.isError = true;
      tempError.currentPassword.errorMessage =
        "Please enter your current password";
      isValid = false;
    }
    if (isEmptyNullUndefined(tempChangePasswordDetails.newPassword)) {
      tempError.newPassword.isError = true;
      tempError.newPassword.errorMessage = "Please enter new password";
      isValid = false;
    }

    if (!isEmptyNullUndefined(tempChangePasswordDetails.newPassword)) {
      if (
        !tempChangePasswordDetails.newPassword.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        )
      ) {
        tempError.newPassword.isError = true;
        tempError.newPassword.errorMessage =
          "must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
        isValid = false;
      }
    }

    //do Submit
    if (isValid) {
      changePasswordApi
        .post("/account/change-password", changePasswordDetails)
        .then((res) => {
          toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;Password
              {` changed successfully`}
            </div>
          );
          setChangePasswordDetails(initialEmptyChangePasswordForm);
          setFormError(initialEmptyChangePasswordFormErrorList);
        })
        .catch((e) => {
          toast.error(e?.response?.data?.title);
        });
    } else {
      setFormError(tempError);
    }
  };

  // const handleEditBankDetails = () => {
  //   setUpdateBankLoader(true);
  //   let data = JSON.parse(JSON.stringify(empData));

  //   data["bankName"] = bankName;
  //   data["bankBranchCode"] = bankBranchCode;
  //   data["accountNo"] = accountNumber;

  //   APIList.updateEmployeeData(empData?.id, data)
  //     .then((res) => {
  //       setUpdateBankLoader(false);
  //       setIsEditBankDetails(false);
  //       dispatch(EmployeeDataActions.EmployeeDataRequest());
  //       toast.success(
  //         <div className="flex flex-row">
  //           <BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }} />
  //           &nbsp;&nbsp;
  //           {"Profile updated successfully!"}
  //         </div>
  //       );
  //     })
  //     .catch((err) => {
  //       setUpdateBankLoader(false);
  //       setIsEditBankDetails(false);
  //       toast.error(
  //         <div className="flex flex-row">
  //           <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
  //           &nbsp;&nbsp;{err?.response?.data?.title}
  //         </div>
  //       );
  //     });
  // };

  const [showCurrentPassword, setshowCurrentPassword] = useState(false);
  const [showNewPassword, setshowNewPassword] = useState(false);

  return (
    <>
      {empDataLoader ? (
        <Loader size="100" />
      ) : (
        <div className="homePage">
          <h4 className="page-heading-navbar ProfileTitle">
            {/* Profile */}
            <FormattedMessage id="profile" defaultMessage="Profile" />
          </h4>
          <div className="p-4 m-4">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container xs={12}>
                  <div className="profilePic">
                    {
                      //   !isEmptyNullUndefined(uploadFileState?.fileError) ?
                      //   <div className="profile-pic-upload-err-circle">
                      //     ! Error
                      //   </div>
                      // :
                      isImgUploading ? (
                        <div className="profile-pic-upload-err-circle">
                          <CircularProgress />
                        </div>
                      ) : uploadFileState?.path ? (
                        <img
                          src={uploadFileState?.path}
                          alt="alert"
                          width="120px"
                          className="alertImg"
                        />
                      ) : empData?.profilePhoto &&
                        empData?.profilePhoto?.path ? (
                        <img
                          src={empData?.profilePhoto?.path}
                          alt="alert"
                          width="100px"
                          className="alertImg"
                        />
                      ) : empData?.gender == "FEMALE" ? (
                        <img
                          src={FemaleLogo}
                          alt="alert"
                          className="alertImg"
                        />
                      ) : (
                        <img src={MaleLogo} alt="alert" className="alertImg" />
                      )
                    }
                    <div className="uploadImg">
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <label
                          style={{ fontSize: "1.2rem" }}
                          htmlFor="uplodAvatar"
                        >
                          <FiEdit />
                        </label>
                      </Stack>

                      <input
                        type="file"
                        id="uplodAvatar"
                        hidden
                        onChange={handelChangeEvent}
                      />
                      {/* <Typography>{uploadFileState?.fname}</Typography> */}
                    </div>
                  </div>
                </Grid>
              </Grid>
              {!isEmptyNullUndefined(uploadFileState?.fileError) && (
                <div className="profile-pic-upload-err">
                  {uploadFileState?.fileError}
                </div>
              )}
              <Grid item xs={12}>
                <Card>
                  <h3 className="py-2 pb-30 font-semibold text-gray-600 font-20">
                    {/* Personal Info */}
                    <FormattedMessage
                      id="profile.personalInfo"
                      defaultMessage="Personal Info"
                    />
                  </h3>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <div>
                          <span className="font-semibold text-gray-500 text-md mr-3">
                            {/* First Name: */}
                            <FormattedMessage
                              id="profile.firstName"
                              defaultMessage="First Name :"
                            />
                          </span>{" "}
                          <span className="font-normal text-gray-600">
                            {empData?.firstName}
                          </span>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <div>
                          <span className="font-semibold text-gray-500 text-md mr-3">
                            {/* Last Name: */}
                            <FormattedMessage
                              id="profile.lastName"
                              defaultMessage="Last Name :"
                            />
                          </span>{" "}
                          <span className="font-normal text-gray-600">
                            {empData?.lastName}
                          </span>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <div>
                          <span className="font-semibold text-gray-500 text-md mr-3">
                            {/* Contact Number : */}
                            <FormattedMessage
                              id="profile.contactNumber"
                              defaultMessage=" Contact Number :"
                            />
                          </span>{" "}
                          <span className="font-normal text-gray-600">
                            {empData?.mobileNumber}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div>
                          <span className="font-semibold text-gray-500 text-md mr-3">
                            {/* Date of Birth: */}
                            <FormattedMessage
                              id="profile.dateOfBirth"
                              defaultMessage="Date Of Birth :"
                            />
                          </span>{" "}
                          <span className="font-normal text-gray-600">
                            {moment(empData?.dateOfBirth?.slice(0,10)).format(
                              `${
                                empData?.company?.dateFormat
                                  ? empData?.company?.dateFormat?.toUpperCase()
                                  : "DD/MM/YYYY"
                              }`
                            )}
                          </span>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <div>
                          <span className="font-semibold text-gray-500 text-md mr-3">
                            {/* Email: */}
                            <FormattedMessage
                              id="profile.email"
                              defaultMessage="Email :"
                            />
                          </span>{" "}
                          <span className="font-normal text-gray-600">
                            {empData?.email}
                          </span>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <div>
                          <span className="font-semibold text-gray-500 text-md mr-3">
                            {/* Gender: */}
                            <FormattedMessage
                              id="profile.gender"
                              defaultMessage="Gender :"
                            />
                          </span>{" "}
                          <span className="font-normal text-gray-600">
                            {empData?.gender}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div>
                          <span className="font-semibold text-gray-500 text-md mr-3">
                            {/* National id : */}
                            <FormattedMessage
                              id="profile.nationalID"
                              defaultMessage="National Id :"
                            />
                          </span>{" "}
                          <span className="font-normal text-gray-600">
                            {empData?.nationalId}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <h3 className="py-2 pb-30 font-semibold text-gray-600 font-20">
                    {/* Job Details */}
                    <FormattedMessage
                      id="profile.jobDetails"
                      defaultMessage="Job Details"
                    />
                  </h3>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <div>
                          <span className="font-semibold text-gray-500 text-md mr-3">
                            {/* Date of Joining: */}
                            <FormattedMessage
                              id="profile.dateOfJoining"
                              defaultMessage="Date of Joining :"
                            />
                          </span>{" "}
                          <span className="font-normal text-gray-600">
                            {moment(empData?.dateOfJoining?.slice(0,10)).format(
                              `${
                                empData?.company?.dateFormat
                                  ? empData?.company?.dateFormat?.toUpperCase()
                                  : "DD/MM/YYYY"
                              }`
                            )}
                          </span>
                        </div>
                      </Grid>

                      {/* <Grid item xs={12} md={6}>
                    <div>
                      <span className="font-semibold text-gray-500 text-md mr-3">
                        Designation :
                      </span>{" "}
                      <span className="font-normal text-gray-600">
                        {empData?.employeeDesignation}
                      </span>
                    </div>
                  </Grid> */}

                      <Grid item xs={12} md={6}>
                        <div>
                          <span className="font-semibold text-gray-500 text-md mr-3">
                            {/* Grade/Level : */}
                            <FormattedMessage
                              id="profile.grades/level"
                              defaultMessage="Grades/Level :"
                            />
                          </span>{" "}
                          <span className="font-normal text-gray-600">
                            {empData?.employeeRole?.name}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {/* <Grid item xs={12}>
            <Card>
              <h3 className="py-2 pb-30 font-semibold text-gray-600 font-20">
                      <FormattedMessage 
                            id="profile.address"
                            defaultMessage="Address" 
                      />
              </h3>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <div>
                      <span className="font-semibold text-gray-500 text-md mr-3">
                        <FormattedMessage 
                            id="profile.addressLine1"
                            defaultMessage="Address Line 1 :" 
                        />
                      </span>{" "}
                      <span className="font-normal text-gray-600">
                        {empData?.address.streetLineOne}
                      </span>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div>
                      <span className="font-semibold text-gray-500 text-md mr-3">
                        <FormattedMessage 
                            id="profile.addressLine2"
                            defaultMessage="Address Line 2 :" 
                        />
                      </span>{" "}
                      <span className="font-normal text-gray-600">
                        {empData?.address.streetLineTwo}
                      </span>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div>
                      <span className="font-semibold text-gray-500 text-md mr-3">
                        <FormattedMessage 
                            id="profile.city"
                            defaultMessage="City :" 
                        />
                      </span>{" "}
                      <span className="font-normal text-gray-600">
                        {empData?.address.city}
                      </span>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div>
                      <span className="font-semibold text-gray-500 text-md mr-3">
                        <FormattedMessage 
                            id="profile.state"
                            defaultMessage="State :" 
                        />
                      </span>{" "}
                      <span className="font-normal text-gray-600">
                        {empData?.address.state}
                      </span>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div>
                      <span className="font-semibold text-gray-500 text-md mr-3">
                        <FormattedMessage 
                            id="profile.postalCode"
                            defaultMessage="Postal Code :" 
                        />
                      </span>{" "}
                      <span className="font-normal text-gray-600">
                        {empData?.address.postalCode}
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid> */}
              {/* {
            !isEditBankDetails ? 
            <Grid item xs={12}>
            <Card>
              <div className="flex justify-between">
              <h3 className="py-2 pb-30 font-semibold text-gray-600 font-20">
                <FormattedMessage 
                      id="profile.bankDetails"
                      defaultMessage="Bank Details" 
                />
              </h3>
              <p className="bankEditBTN" onClick={() => setIsEditBankDetails((val) => !val)}>Edit Bank Details</p>
              </div>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <div>
                      <span className="font-semibold text-gray-500 text-md mr-3">
                        <FormattedMessage 
                              id="profile.bankName"
                              defaultMessage="Bank name :" 
                        />
                      </span>{" "}
                      <span className="font-normal text-gray-600">
                        {empData?.bankName}
                      </span>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div>
                      <span className="font-semibold text-gray-500 text-md mr-3">
                        <FormattedMessage 
                              id="profile.bankBranchCode"
                              defaultMessage="Bank branch code :" 
                        />
                      </span>{" "}
                      <span className="font-normal text-gray-600">
                        {empData?.bankBranchCode}
                      </span>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div>
                      <span className="font-semibold text-gray-500 text-md mr-3">
                        <FormattedMessage 
                              id="profile.accountNumber"
                              defaultMessage="Account Number :" 
                        />
                      </span>{" "}
                      <span className="font-normal text-gray-600">
                        {empData?.accountNo}
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          :
          <Grid xs={12} sx={{mb:4, mx:4}}>
            <div className="flex justify-between">
              <h3 className="py-2 pb-30 font-semibold text-gray-600 font-20">
                <FormattedMessage 
                      id="profile.editBankDetails"
                      defaultMessage="Edit Bank Details" 
                />
              </h3>
              <p className="bankEditBTN" onClick={() => setIsEditBankDetails((val) => !val)}>
                <FormattedMessage 
                      id="profile.editBankDetails.cancelEdit"
                      defaultMessage="Cancel Edit" 
                />
              </p>
            </div>
            <TextField
                        size="small"
                        name="bankName"
                        value={bankName}
                        onChange={(event) =>
                          setBankName(event.target.value)
                        }
                        label={<FormattedMessage 
                          id="profile.editBankDetails.bankName"
                          defaultMessage="Bank name" 
                        />}
                        id="bankName"
                        variant="outlined"
                        // error={formError.currentPassword.isError}
                        // helperText={formError.currentPassword.errorMessage}
                        sx={{
                          my: 2,
                          width: "100%",
                          backgroundColor: "#fff",
                          "& fieldset": {
                            //border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                        }}
                      />
                      <TextField
                        size="small"
                        name="bankBranchCode"
                        value={bankBranchCode}
                        onChange={(event) =>
                          setBankBranchCode(event.target.value)
                        }
                        // label="Bank branch code"
                        label={<FormattedMessage 
                          id="profile.editBankDetails.bankBranchCode"
                          defaultMessage="Bank branch code" 
                        />}
                        id="bankBranchCode"
                        variant="outlined"
                        // error={formError.newPassword.isError}
                        // helperText={formError.newPassword.errorMessage}
                        sx={{
                          my: 2,
                          width: "100%",
                          backgroundColor: "#fff",
                          "& fieldset": {
                            //border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                        }}
                      />
                      <TextField
                        size="small"
                        name="accountNumber"
                        value={accountNumber}
                        onChange={(event) =>
                          setAccountNumber(event.target.value)
                        }
                        // label="Account Number"
                        label={<FormattedMessage 
                          id="profile.editBankDetails.accountNumber"
                          defaultMessage="Account Number" 
                        />}
                        id="accountNumber"
                        variant="outlined"
                        // error={formError.newPassword.isError}
                        // helperText={formError.newPassword.errorMessage}
                        sx={{
                          my: 2,
                          width: "100%",
                          backgroundColor: "#fff",
                          "& fieldset": {
                            //border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                        }}
                      />
                      <ButtonM
                        // text="Submit Bank Details"
                        text={<FormattedMessage 
                          id="profile.editBankDetails.submitBankDetails"
                          defaultMessage="Submit Bank Details" 
                        />}
                        onpress={() => handleEditBankDetails()}
                        disabled={updateBankLoader}
                        loading={updateBankLoader}
                        bgcolor={updateBankLoader ? "gray" : ""}
                      />
          </Grid>
          } */}

              <Grid item xs={12}>
                <Card>
                  <div
                    onClick={() => setResetPasswordAcc(!resetPasswordAcc)}
                    className="p-4 cursor-pointer flex w-full items-center justify-between bg-slate-100"
                  >
                    <h3 className=" font-semibold text-gray-600 font-20 ">
                      {/* Change Password */}
                      <FormattedMessage
                        id="profile.changePassword"
                        defaultMessage="Change Password"
                      />
                    </h3>
                    {/* {!resetPasswordAcc && (
                  <GiClick className="font-semibold text-gray-600 font-20 animate-bounce" />
                )} */}
                    <AiOutlineRight
                      className={
                        resetPasswordAcc
                          ? "transition-transform ease-in-out duration-700 rotate-90"
                          : "transition-transform ease-in-out duration-700"
                      }
                    />
                  </div>
                  {/* <div
                className={
                  !resetPasswordAcc
                    ? "flex w-full items-center justify-between text-gray-600 font-20 text-md mr-3 transition-all ease-in-out duration-700 h-auto opacity-100 "
                    : "font-semibold text-gray-500 text-md mr-3 transition-all ease-in-out duration-700 h-0 hidden opacity-0 "
                }
              >
              
              </div> */}

                  <CardContent
                    className={
                      resetPasswordAcc
                        ? "transition-all ease-in-out duration-700 h-auto opacity-100"
                        : "transition-all ease-in-out duration-700 h-0 hidden opacity-0"
                    }
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <div className="password-profile-div">
                          <TextField
                            size="small"
                            name="currentPassword"
                            type={showCurrentPassword ? "text" : "password"}
                            value={
                              changePasswordDetails.currentPassword
                                ? changePasswordDetails.currentPassword
                                : ""
                            }
                            onChange={(event) =>
                              handleChangePasswordForm(event, "currentPassword")
                            }
                            // label="Current Password"
                            label={
                              <FormattedMessage
                                id="profile.currentPassword"
                                defaultMessage="Current Password"
                              />
                            }
                            id="currentPassword"
                            variant="outlined"
                            error={formError.currentPassword.isError}
                            helperText={formError.currentPassword.errorMessage}
                            sx={{
                              my: 2,
                              width: "100%",
                              backgroundColor: "#fff",
                              "& fieldset": {
                                //border: "1px solid #dedede",
                              },
                              "& .MuiInputBase-input:focus": {
                                border: "2px solid #fcd25e", // focus
                              },
                              "& .css-k4qjio-MuiFormHelperText-root": {
                                backgroundColor: "#ffffff",
                                margin: "0px",
                                paddingLeft: "0.5rem",
                              },
                            }}
                          />
                          <span
                            class="eyeIcon"
                            onClick={() =>
                              setshowCurrentPassword(!showCurrentPassword)
                            }
                          >
                            {showCurrentPassword ? <FaEye /> : <FaEyeSlash />}
                          </span>
                        </div>
                        <div className="password-profile-div">
                          <TextField
                            size="small"
                            name="newPassword"
                            type={showNewPassword ? "text" : "password"}
                            value={
                              changePasswordDetails.newPassword
                                ? changePasswordDetails.newPassword
                                : ""
                            }
                            onChange={(event) =>
                              handleChangePasswordForm(event, "newPassword")
                            }
                            // label="New Password"
                            label={
                              <FormattedMessage
                                id="profile.newPassword"
                                defaultMessage="New Password"
                              />
                            }
                            id="newPassword"
                            variant="outlined"
                            error={formError.newPassword.isError}
                            helperText={formError.newPassword.errorMessage}
                            sx={{
                              my: 2,
                              width: "100%",
                              backgroundColor: "#fff",
                              "& fieldset": {
                                //border: "1px solid #dedede",
                              },
                              "& .MuiInputBase-input:focus": {
                                border: "2px solid #fcd25e", // focus
                              },
                              "& .css-k4qjio-MuiFormHelperText-root": {
                                backgroundColor: "#ffffff",
                                margin: "0px",
                                paddingLeft: "0.5rem",
                              },
                            }}
                          />
                          <span
                            class="eyeIcon"
                            onClick={() => setshowNewPassword(!showNewPassword)}
                          >
                            {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                          </span>
                          <ButtonM
                            // text="Change Password"
                            text={
                              <FormattedMessage
                                id="profile.changePassword"
                                defaultMessage="Change Password"
                              />
                            }
                            onpress={() =>
                              handleValidateChangePasswordFormData()
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
