import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../../api/index';
import { actions, types } from './actions';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { getRelationName } from 'components/utils/utils';
// import { useHistory } from 'react-router-dom';

const AddDependantRequest = function* AddDependantRequest({payload}) {  
    // const history = useHistory();

    try {
        const { data } = yield call(api.addDependant, payload.payload);
        if (data) {
            yield put(actions.AddDependantRequestSuccess(data));
            
            data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Added {getRelationName(payload.relation)} successfully</div>);
            // data && history.push({pathname:'/basicMedical', state: {fromAddress:true}});
        } else {
            // toast.error(data.message);
            yield put(actions.AddDependantRequestFailed());            
        }
    } catch (err) {        
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);       
        yield put(actions.AddDependantRequestFailed(err));
    }
};

const GetDependentRequest = function* GetDependentRequest({payload}) {  
    try {
        const { data } = yield call(api.getAllDependents, payload);
        if (data) {
            yield put(actions.GetDependentRequestSuccess(data));
            
        } else {
            yield put(actions.GetDependentRequestFailed());            
        }
    } catch (err) {        
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);       
        yield put(actions.GetDependentRequestFailed(err));
    }
};

export default function* sagas() {
    yield takeLatest(types.ADD_DEPENDANT_REQUEST, AddDependantRequest);
    yield takeLatest(types.GET_DEPENDENTS_REQUEST, GetDependentRequest);
}
