import { useState } from "react";
import ellipseIcon from "../../../../../assets/Ellipse2.png";
import PageHeader from "../../../../common/PageHeader";
// import VoluntaryParentalModal from "../../ViewModal/index";
import { useSelector } from "react-redux";
import { actions as VolutryActions } from "../../redux/actions";
import coverImg from "../../../../../assets/groupMedical/cover.png";
import SingleDependant from "./SingleDependant";
import { getWaitingPeriod } from "components/utils/utils";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";

const classNames = require("classnames");

const CoverageType = () => {

  const [isGroup, setIsGroup] = useState(true);
  const [isIndividual, setIsIndividual] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  
  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const planDependants = selectedPlan?.planDependants;
  const rateDiffer = selectedPlan?.rateCard?.rateDiffer;
  const { selectedCategories, progressArray, selectedRatecards, currentlyEnrolled,currentlyInprocessEnrolled} = useSelector((state) => state?.voluntryReducer); 
  const currentNames = selectedCategories[progressArray?.length];
  const currentDependants = selectedCategories[progressArray?.length]?.includes("-") ? selectedCategories[progressArray?.length].split("-") : selectedCategories[progressArray?.length]

  const employeeDetails = useSelector((state) => state?.empData?.empData);
  
  Array.prototype.equals = function (array, strict) {
    if (!array)
        return false;

    if (arguments.length == 1)
        strict = true;

    if (this.length != array.length)
        return false;

    for (var i = 0; i < this.length; i++) {
        if (this[i] instanceof Array && array[i] instanceof Array) {
            if (!this[i].equals(array[i], strict))
                return false;
        }
        else if (strict && this[i] != array[i]) {
            return false;
        }
        else if (!strict) {
            return this.sort().equals(array.sort(), true);
        }
    }
    return true;
}
  
const getMinCoverageLimits = () => {
  let minCoverage = 0;

  if(currentDependants instanceof Array){
  planDependants?.map((item) => {
    if(item.relation?.equals(currentDependants,false)){
      if(item.minCoverage != "NA" || item.minCoverage != null){ 
      minCoverage = item.minCoverage;
      }
    }
  })}else{
    planDependants?.map((item) => {
    if(item.relation?.includes(currentDependants)){
      if(item.minCoverage != "NA" || item.minCoverage != null){ 
      minCoverage = item.minCoverage;
      }
    }
  })
  }
  return minCoverage;
}

const getMaxCoverageLimits = () => {
  let maxCoverage = 999999999;

  if(currentDependants instanceof Array){
    planDependants?.map((item) => {
      if(item.relation?.equals(currentDependants,false)){
        if(item.maxCoverage != "NA" || item.maxCoverage != null){ 
        maxCoverage = item.maxCoverage;
      }
      }
    })}else{
      planDependants?.map((item) => {
      if(item.relation?.includes(currentDependants)){
        if(item.maxCoverage != "NA" || item.maxCoverage != null){ 
        maxCoverage = item.maxCoverage;
        }
      }
    })
    }
  return maxCoverage;

} 

const rateCards = selectedPlan?.rateCard?.rateCardItems?.filter((x) => ((x.sumInsured >= getMinCoverageLimits()) && (x.sumInsured <= getMaxCoverageLimits())))
  // {  console.log("currentDependants",selectedPlan)}

  return (
    <div className="coverageTypePage">
      {selectedPlan && (
        <div>
          <h4 className="page-heading-navbar">{selectedPlan.displayName}</h4>
          <PageHeaderForPlans 
          // btnRedirect={() => setOpenModal(true)} 
          />
        </div>
      )}
      <div className="data">
        <div className="container mx-auto">
          <div className="flex flex-wrap ">
            
            {rateDiffer == "individual" && (              
                    
                    <SingleDependant
                    rateCards={rateCards}
                    currentNames={currentNames}
                    isEdit={false}
                    />                
             
            )}
         
          </div>
        </div>
      </div>
      {/* {openModal && <VoluntaryParentalModal setOpenModal={setOpenModal} />} */}
    </div>
  );
};

export default CoverageType;
