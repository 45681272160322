import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import banner1 from "../../../../assets/Banners/1.png";
import { useSelector } from "react-redux";
import BannerImage from './BannerImage';

const ImgSlider = () => {

    const companyBanners = useSelector((state) => state?.homepageReducer?.bannerData) ?? [];

    const BannerSettings = {
        autoplay: true,
        lazyLoad: true,
        arrows: false,
        autoplaySpeed: 3000,
        // fade:true,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1500,
        vertical: false,
    };

    if (companyBanners?.length === 0) {
        return (
            <div className="place-list center">
                <div className="bannerImage">
                    <img className="banner-image-hight" src={banner1} alt="" />
                </div>
            </div>
        );
    }

    return (
        <div className="homeBannerImgSlider">
            <Slider  {...BannerSettings}>
                {companyBanners.map(banner => (
                    <BannerImage
                        key={banner.id}
                        id={banner.id}
                        path={banner.path}
                        fname={banner.fname}

                    />
                ))}
            </Slider>
        </div>

    );
}
export default ImgSlider;