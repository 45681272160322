// import { useDispatch, useSelector } from "react-redux";
// import { actions as companyListsActions } from "./redux/actions";
import { useEffect, useState } from "react";
import { FaLaptopCode, FaMobileAlt } from "react-icons/fa";
import { MdEmail, MdOutlineMailOutline, MdPhoneInTalk } from "react-icons/md";
import { useHistory } from "react-router-dom";
import MaleLogo from "../../../assets/Used/manlogo.png";
import { FormattedMessage } from "react-intl";
import APIList from "api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { useSelector } from "react-redux";

const parse = require("html-react-parser");

const ContactUs = () => {

  const history = useHistory();

  const employeeDetails = useSelector((state) => state?.empData?.empData);

  const [providerData, setProviderData] = useState([]);

  useEffect(() => {
    APIList.serviceProvider({id : employeeDetails?.company?.id})
    .then((res) => {
      setProviderData(res.data);
    })
    .catch((err) => {
      toast.error(
          <div className='flex flex-row'>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        ); 
  })
  }, []);

  return (
    <>
      <div className="contactUsPage">
        {/* <h1 className="page-heading-navbar">Contact Provider</h1> */}
        <h1 className="page-heading-navbar"><FormattedMessage id="contactProvider.pageHeading" defaultMessage="Contact Provider"/></h1>
     
        <div className="service-provider-wrapper">
          {providerData?.length > 0 && parse(providerData[0]?.description)}
        </div>
        
      </div>
    </>
  );
};

export default ContactUs;
