// import calendarIcon from "../../../assets/calendar.svg";
import enrollIcon from "../../../assets/enrollIntro.png";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import ButtonM from "../../common/Button";
import { CircularProgress, Skeleton } from "@mui/material";
import APIList from "api";
import { useEffect, useState } from "react";
// import moment from "moment";

const EnrollmentIntro = () => {

  const history = useHistory();

  const employeeDetails = useSelector((state) => state && state.empData);
  const company = useSelector((state) => state && state.empData && state.empData.empData && state.empData.empData.company && state.empData.empData.company.displayName);
  const loading = useSelector((state) => state && state.empGroup && state.empGroup.loader);
  const data = useSelector((state) => state && state.empGroup);
  const firstName = useSelector((state) => state && state.empData && state.empData.empData && state.empData.empData.firstName);
  const { employeeEnrolled, employeeEnrolledLoader, enrollmentDeadline } = useSelector((state) => state && state.empData);

  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    APIList.getEmployeeEnrolled({empId:employeeDetails?.empData?.id})
    .then((res) => {
      setPlans(res?.data?.Plans);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setIsLoading(false);
    })
  }, [])
  
  const handleGroup = () => {
      history.push('/plans');
  };

  const handleGoHome = () => {
      history.push('/home');
  };

  return (
    <div className="enrollmentIntroData h-full">
      <h4 className="page-heading-navbar">Enroll</h4>
      {
                (employeeEnrolledLoader || isLoading) ? 
                <div>
                    <Skeleton variant="rectangular" height={200} sx={{margin:"2rem 1rem"}} />
                    <Skeleton variant="rectangular" height={75} sx={{margin:"2rem 1rem"}} />
                    <Skeleton variant="rectangular" height={75} sx={{margin:"2rem 1rem"}} />
                </div>
                :
      <div className="h-full mx-auto flex flex-col justify-between">
        

        
        <div className="flex flex-wrap">
          <div className="w-full">
            {
              plans?.length < 1 ? 
              <div className="data2">
                <div className="completedEnrollHead"> 
                  Your enrollment is complete
                </div>
              </div>
              :
              <div className="data1">
              <div className="data1Inner">
                <img className="mx-auto" src={enrollIcon} alt="enrollIcon" />
                {loading ? (
                  <div style={{ padding: "7% 50%" }}>
                    <CircularProgress color="success" size={29} />
                  </div>
                ) : (
                  <div className="dataInneer">
                    <h3 className="title">Welcome {firstName},</h3>
                    <p className="desc">
                      {company} provides you with a breadth of benefits to
                      support you and your family. Some of these benefits are
                      automatically offered to you, others require you to
                      actively enroll.
                      <p className="my-2">
                      The enrollment process will take you approximately
                      <span>
                        {" "}
                        {employeeEnrolled?.length}-
                        {(employeeEnrolled?.length) * 2}{" "}
                      </span>                      
                      minutes to complete.
                      </p>
                      <p><span className="underline">NOTE</span> : </p>
                      <ul className="list-disc ml-6">
                      <li className="my-2">Make sure the details shared are completely accurate.</li>
                      <li className="my-2"> Enrollment will not be allowed post the enrollment window timeline.</li>
                      <li className="my-2">Details once submitted cannot be withdrawn or changed.</li>
                      </ul>
                    </p>
                      
                    {/* <button className="moreDetails">Click here for more details</button>  */}
                  </div>
                )}                
              </div>
            </div>
            }
           
          </div>
        </div>

        <div>
          {/* <div className="flex flex-wrap">
            <div className="w-full px-5">
              <ButtonM
                onpress={() => history.push(`/new-learning-page`)}
                text="I'm not ready to enroll, but I want to learn more"
                variant="outlined"
                textsize="0.75rem"
                bghover="#ffffff"
              />
            </div>
          </div> */}
          {
              plans?.length < 1 ? 
              <div className="flex flex-wrap">
            <div className="w-full px-4">
              <ButtonM
                text="go to homepage"
                onpress={() => handleGoHome()}
                loading={loading}
                disabled={loading}
              />
            </div>
          </div>
              :
          <div className="flex flex-wrap">
            <div className="w-full px-5">
              <ButtonM
                text="next"
                onpress={() => handleGroup()}
                loading={loading}
                disabled={loading}
              />
            </div>
          </div>
        }
        </div>
          
      </div>
}
      {/* {success && <Plans />} */}
    </div>
  );
};

export default EnrollmentIntro;
