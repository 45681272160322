import { useEffect } from "react";
import * as React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import ButtonM from "../../../common/Button";
import { useDispatch, useSelector } from "react-redux";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from 'react-toastify';
import APIList from '../../../../api/index';
import { requiredStyled } from "components/utils/utils";

const AddDependentAddressReim = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const empDetails = useSelector((state) => state && state.empData);
  const dependantDetails = location && location.state && location.state.dependant;
  const [checked, setChecked] = React.useState(false);
  const [addLoading, setAddLoading] = React.useState(false);

  const ReimbursementPlanData = useSelector((state) => state?.reimReducer?.reimbursePlanData?.plan);

  useEffect(
    () => {
      location &&
        (!location.state || (location.state && !location.state.dependant)) &&
        history.push("/adddependent");
    },
    [
      // dispatch
    ]
  );

  const initialEmptyDependent = {
    streetAddressLine1: null,
    streetAddressLine2: null,
    city: null,
    state: null,
    postalCode: null,
  };

  const initialFormErrorList = {
    streetAddressLine1: { isError: false, errorMessage: "" },
    streetAddressLine2: { isError: false, errorMessage: "" },
    city: { isError: false, errorMessage: "" },
    state: { isError: false, errorMessage: "" },
    postalCode: { isError: false, errorMessage: "" },
  };

  const [dependentAddress, setDependentAddress] = React.useState(
    initialEmptyDependent
  );
  const [formError, setFormError] = React.useState(initialFormErrorList);

  const handleResetForm = () => {
    const tempInitDependent = JSON.parse(JSON.stringify(initialEmptyDependent));

    const tempInitError = JSON.parse(JSON.stringify(initialFormErrorList));
    setDependentAddress(tempInitDependent);
    setFormError(tempInitError);
  };

  const handleChangeDependentAddress = async (event, fieldType) => {
    const tempDependentAddress = JSON.parse(JSON.stringify(dependentAddress));
    const tempError = JSON.parse(JSON.stringify(formError));
    if (fieldType === "textfield" || fieldType === "dropdown") {
      tempDependentAddress[event.target.name] = event.target.value;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    } else if (fieldType == "onlyNumber") {
      tempDependentAddress[event.target.name] = event.target.value.replace(
        /[^0-9]/g,
        ""
      );
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    }
    setDependentAddress(tempDependentAddress);
    setFormError(tempError);
  };

  const handleValidateDependent = async () => {
    let isValid = true;
    // const tempError = JSON.parse(JSON.stringify(formError));
    // const tempDependentAddress = JSON.parse(JSON.stringify(dependentAddress));

    // if (
    //   tempDependentAddress.streetAddressLine1 === null ||
    //   tempDependentAddress.streetAddressLine1.trim() === ""
    // ) {
    //   tempError.streetAddressLine1.isError = true;
    //   tempError.streetAddressLine1.errorMessage =
    //     "Please enter stree address line 1";
    //   isValid = false;
    // }
    // if (
    //   tempDependentAddress.streetAddressLine2 === null ||
    //   tempDependentAddress.streetAddressLine2.trim() === ""
    // ) {
    //   tempError.streetAddressLine2.isError = true;
    //   tempError.streetAddressLine2.errorMessage =
    //     "Please enter stree address line 2";
    //   isValid = false;
    // }

    // if (
    //   tempDependentAddress.city === null ||
    //   tempDependentAddress.city.trim() === ""
    // ) {
    //   tempError.city.isError = true;
    //   tempError.city.errorMessage = "Please enter City";
    //   isValid = false;
    // }
    // if (
    //   tempDependentAddress.state === null ||
    //   tempDependentAddress.state.trim() === ""
    // ) {
    //   tempError.state.isError = true;
    //   tempError.state.errorMessage = "Please enter State";
    //   isValid = false;
    // }
    // if (
    //   tempDependentAddress.postalCode === null ||
    //   tempDependentAddress.postalCode.toString().trim() === ""
    // ) {
    //   tempError.postalCode.isError = true;
    //   tempError.postalCode.errorMessage = "Please enter Postal Code";
    //   isValid = false;
    // }

    //do Submit
    if (isValid || checked) {
      // if (isEdit) {
      //   updateDependent();
      // } else {
      submitDependent();
      // }
    } 
    // else {
    //   setFormError(tempError);
    // }
  };

  const submitDependent = () => {
    setAddLoading(true);
    APIList.addDependant({
      firstName: dependantDetails && dependantDetails.firstName,
      middleName: (dependantDetails && dependantDetails.middleName) || "",
      lastName: dependantDetails && dependantDetails.lastName,
      relation: dependantDetails && dependantDetails.relationship,
      dateOfBirth: dependantDetails && dependantDetails.dateOfBirth,
      nationalId: dependantDetails && dependantDetails.nationalId,
      idType: dependantDetails && dependantDetails.idType,
      gender: dependantDetails && dependantDetails.gender,
      nationality: dependantDetails && dependantDetails.nationality,
      isRelativeWorking: dependantDetails && dependantDetails.isRelativeWorking,
      isRelativeWorkingInNium: dependantDetails && dependantDetails.isRelativeWorkingInNium,
      employeeIdOfRelative: dependantDetails && dependantDetails.employeeIdOfRelative,
      employee: {
        id: empDetails && empDetails.empData && empDetails.empData.id,
      },
      address: !checked
        ? {
            streetLineOne:
              dependentAddress && dependentAddress.streetAddressLine1,
            streetLineTwo:
              dependentAddress && dependentAddress.streetAddressLine2,
            city: dependentAddress && dependentAddress.city,
            state: dependentAddress && dependentAddress.state,
            postalCode: dependentAddress && dependentAddress.postalCode,
          }
        : empDetails && empDetails.empData && empDetails.empData.address,
    })
    .then((res) => {
      res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Added {dependantDetails && dependantDetails.relationship} successfully</div>);
      setAddLoading(false);
      history.push(`/reimbursement/${ReimbursementPlanData?.id}`)
    })
    .catch((err) =>{
      toast.error(
        <div className='flex flex-row'>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{err?.title}
        </div>
      );      
      setAddLoading(false);      
    }
    );
  };

  return (
    <div className="AddDependentPage h-full">
      <h4 className="page-heading-navbar">Add Dependent</h4>

      <Stack sx={{ mx: { xs: 0, xl: 10, md: 5 } }} height="100%">
        <Box sx={{ my: 2 }} height="100%">
          <Stack
            display={{ xs: "flex", sm: "flex" }}
            direction={{ xs: "column", sm: "column" }}
            justifyContent="space-between"
            alignItems={{ xs: "center", sm: "center" }}
            spacing={2}
            height="100%"
          >
            <Stack width={{ xs: "90%", sm: "75%" }} height="100%">
              <div className="h-full flex flex-col justify-between">
                <div>
                  <div className="mt-4">
                    <div className="flex flex-wrap">
                      <div className="w-full flex justify-between">
                        <div className="details">
                          <h3 className="title">Address</h3>
                          <p className="subTitle">
                            Add the address for your dependent.{" "}
                          </p>
                        </div>

                      </div>
                    </div>
                  </div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="success"
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Address same as me"
                    />
                  </FormGroup>
                  {!checked && (
                    <>
                      <TextField
                        size="small"
                        name="streetAddressLine1"
                        label="Street Address Line 1"
                        id="outlined-basic"
                        onChange={(event) =>
                          handleChangeDependentAddress(event, "textfield")
                        }
                        variant="outlined"
                        error={formError.streetAddressLine1.isError}
                        helperText={formError.streetAddressLine1.errorMessage}
                        value={
                          dependentAddress.streetAddressLine1
                            ? dependentAddress.streetAddressLine1
                            : ""
                        }
                        sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                            border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          // ...requiredStyled
                        }}
                      />
                      <TextField
                        size="small"
                        name="streetAddressLine2"
                        label="Street Address Line 2"
                        id="outlined-basic"
                        onChange={(event) =>
                          handleChangeDependentAddress(event, "textfield")
                        }
                        variant="outlined"
                        error={formError.streetAddressLine2.isError}
                        helperText={formError.streetAddressLine2.errorMessage}
                        value={
                          dependentAddress.streetAddressLine2
                            ? dependentAddress.streetAddressLine2
                            : ""
                        }
                        sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                            border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          // ...requiredStyled
                        }}
                      />
                      <TextField
                        size="small"
                        name="city"
                        label="City"
                        id="outlined-basic"
                        onChange={(event) =>
                          handleChangeDependentAddress(event, "textfield")
                        }
                        variant="outlined"
                        error={formError.city.isError}
                        helperText={formError.city.errorMessage}
                        value={
                          dependentAddress.city ? dependentAddress.city : ""
                        }
                        sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                            border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          // ...requiredStyled
                        }}
                      />

                      <TextField
                        size="small"
                        name="state"
                        label="State"
                        id="outlined-basic"
                        onChange={(event) =>
                          handleChangeDependentAddress(event, "textfield")
                        }
                        variant="outlined"
                        error={formError.state.isError}
                        helperText={formError.state.errorMessage}
                        value={
                          dependentAddress.state ? dependentAddress.state : ""
                        }
                        sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                            border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          // ...requiredStyled
                        }}
                      />

                      <TextField
                        size="small"
                        name="postalCode"
                        label="Postal Code"
                        id="outlined-basic"
                        onChange={(event) =>
                          handleChangeDependentAddress(event, "onlyNumber")
                        }
                        variant="outlined"
                        error={formError.postalCode.isError}
                        helperText={formError.postalCode.errorMessage}
                        value={
                          dependentAddress.postalCode
                            ? dependentAddress.postalCode
                            : ""
                        }
                        sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                            border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          // ...requiredStyled
                        }}
                      />
                    </>
                  )}
                </div>
                <div className="bottomButtonReq">
                  <ButtonM
                    text="SUBMIT"
                    disabled={addLoading}
                    loading={addLoading}
                    onpress={() => handleValidateDependent()}
                  />
                </div>
              </div>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </div>
  );
};

export default AddDependentAddressReim;
