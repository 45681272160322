import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { employeeActions } from "./reedux/action";
import { useDispatch } from "react-redux"
import { Skeleton, Stack, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import Box from "@mui/material/Box"
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddcaseTable from "./AddcaseTable";
import Paper from '@mui/material/Paper';
import moment from "moment";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { fontFamily } from "@mui/system";
import { getAge } from "components/utils/utils";
import { FormattedMessage } from "react-intl";


const Inbox = () => {
  // const dispatch = useDispatch();
  const history = useHistory();
  const empDataSelector = useSelector((state) => state.empData)
  const { loader, caseData } = useSelector((store) => store?.InboxReducer)
  const dispatch = useDispatch();
  const [caseCategories, setCaseCategories] = useState([])

  const [addCase, setAddcase] = useState(false);
  useEffect(() => {

    if (!loader) {
      
      let status = {
        "INACTIVE": 2,
        "ACTIVE": 1,
       };

      let sortArray = caseData?.sort((a, b) => getAge(a.createdAt) - getAge(b.createdAt));
      setCaseCategories(sortArray)
    
    }
  }, [loader])

  useEffect(() => {

    dispatch(employeeActions.getEmployeeCaseRequest({
      id: empDataSelector?.empData && empDataSelector?.empData?.id
    }));

  }, []);



  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--primary)",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));


  return (
    <>
      <div className="contactYourHR">
        <h4 className="page-heading-navbar">
          {/* Contact your HR */}
          <FormattedMessage 
              id="contactYourHr"
              defaultMessage="Contact your HR" 
          />
          </h4>

        <div className="leave-modals-block-coverage mx-4">
          <table>
            <thead>
              <tr>
                <th>
                  <p>
                    {/* Benefits */}
                    <FormattedMessage id="contactYourHr.benefits" defaultMessage="Benefits"/>
                  </p>
                </th>
                <th>
                  <p>
                    {/* Contact Email */}
                    <FormattedMessage id="contactYourHr.contactEmail" defaultMessage="Contact Email"/>
                  </p>
                  
                </th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <td>
                  <p>
                     <FormattedMessage id="contactYourHr.forLeave" defaultMessage="For Leave"/>
                   </p>
                </td>
                <td style={{color:"blue"}}>
                <a href="mailto:benefits@nium.com"> benefits@nium.com </a>
                </td>
              </tr> */}
              <tr>
                <td>
                  <p>
                    {/* Insurance Enrollment */}
                    <FormattedMessage id="contactYourHr.insurenceEnrollment" defaultMessage="Insurance Enrollment"/>
                  </p>
                </td>
                <td style={{color:"blue"}}>
                <a href="mailto:benefits@nium.com"> benefits@nium.com </a>
                  {/* <p>benefits@nium.com</p> */}
                </td>
              </tr>
              {/* <tr>
                <td>
                  <p>Insurance Coverage</p>
                </td>
                <td>
                  <p onClick={() => history.push("/contactus")} className="text-blue-500 underline decoration-1 cursor-pointer w-fit">Insurance Provider</p>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>

        <Stack direction="row" component={Box} sx={{ mx: 2 }}>
          <Typography flex={1} variant="h6">
            {" "}
            {/* Your Previous Tickets. */}
            <FormattedMessage id="contactYourHr.yourPreviousTickets" defaultMessage="Your Previous Tickets."/>
          </Typography>
          
            {
              !addCase &&
              <Button variant="contained" onClick={() => setAddcase(!addCase)}>
               {/* Contact your HR */}
               <FormattedMessage id="contactYourHr.writetohr" defaultMessage="Write To HR"/>
               </Button>
            }
            
          
        </Stack>
        {addCase && (
          <AddcaseTable
            caseFunction={{
              addCase,
              setAddcase,
            }}
          />
        )}

        {loader ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{
              height: { md: "300px", xs: "70px" },
              width: "96%",
              my: 2,
              mx: "auto",
            }}
          />
        ) : (
          <>
            {caseCategories?.length > 0 ? (
              <Box width="96%" sx={{ my: 2, mx: "auto" }}>
                <p>
                  {/* Active Tickets */}
                  {/* <FormattedMessage id="contactYourHr.activeTickets" defaultMessage="Active Tickets"/> */}
                </p>
                <TableContainer component={Paper} sx={{ mb: 2, mx: "auto" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>
                          {/* S.no */}
                          <FormattedMessage id="contactYourHr.sno" defaultMessage="Sr.No"/>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {/* Title */}
                          <FormattedMessage id="contactYourHr.title" defaultMessage="Title"/>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {/* Description */}
                          <FormattedMessage id="contactYourHr.Description" defaultMessage="Description"/>
                          </StyledTableCell>
                        <StyledTableCell align="left">
                          {/* Created on */}
                          <FormattedMessage id="contactYourHr.createdOn" defaultMessage="Created on"/>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {/* Status */}
                          <FormattedMessage id="contactYourHr.status" defaultMessage="Status"/>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {/* Action */}
                          <FormattedMessage id="contactYourHr.action" defaultMessage="Action"/>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {caseCategories?.map((row, index) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ textTransform: "capitalize", wordBreak:"break-all" }}
                          >
                            {row.title}
                          </TableCell>
                          <TableCell align="left"> {row.message.length > 25 ? `${row.message.slice(0,25)}...` : row.message }</TableCell>
                          <TableCell align="left">
                            {moment(row?.createdAt).format("DD-MM-YY hh:mm a")}
                          </TableCell>
                          <TableCell align="left">{row?.status}</TableCell>
                          <TableCell align="left">
                            <Button
                              Primary
                              sx={{ fontFamily: "poppins" }}
                              onClick={() =>
                                history.push({
                                  pathname: `/inbox/view-case/${row.id}`,
                                })
                              }
                            >
                              {/* View */}
                              <FormattedMessage id="common.button.view" defaultMessage="View"/>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              <Typography mx={2}>
                {/* You have 0 cases */}
                <FormattedMessage id="youHave0Cases" defaultMessage="You have 0 cases"/>
              </Typography>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Inbox;
