import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.EMPLOYEE_DATA_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.EMPLOYEE_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        empData: payload || {},
    }),
    [types.EMPLOYEE_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),

    [types.EMPLOYEE_ENROLLED_REQUEST]: (state) => ({
        ...state,
        employeeEnrolledLoader: true,
    }),
    [types.EMPLOYEE_ENROLLED_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        employeeEnrolledLoader: false,
        employeeEnrolled: payload.Plans || {},
        enrollmentDeadline: payload.enrollmentDeadline || null,
    }),
    [types.EMPLOYEE_ENROLLED_REQUEST_FAILED]: (state) => ({
        ...state,
        employeeEnrolledLoader: false,
    }),


    [types.GET_DE_ENROLL]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.GET_DE_ENROLL_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        deEnroll: payload || {},
    }),
    [types.GET_DE_ENROLL_FAILED]: (state , {payload}) => ({
        ...state,
        loader: false,
        error: {title:"no deEnroll data"}
    }),

    
    [types.ELIGIBLE_PLAN_DATA_REQUEST]: (state, { payload }) => ({
        ...state,
        elligiblePlanLoader: true,
    }),
    [types.ELIGIBLE_PLAN_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        elligiblePlanLoader: false,
        eligibleplans: payload || [],
    }),
    [types.ELIGIBLE_PLAN_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        elligiblePlanLoader: false,
    }),

    [types.GET_ID_TYPES]: (state, { payload }) => ({
        ...state,
        idLoader: true,
    }),
    [types.GET_ID_TYPES_SUCCESS]: (state, { payload }) => ({
        ...state,
        idLoader: false,
        idTypes: payload || [],
    }),
    [types.GET_ID_TYPES_FAILED]: (state) => ({
        ...state,
        idLoader: false,
    }),

    [types.SET_PREF_LANGUAGE]: (state, { payload }) => ({
        ...state,
        language: payload?.lang,
    }),

    [types.PROFILE_PIC_CHANGE]: (state, { payload }) => ({
        ...state,
        isPicChange: payload?.action,
    }),
};

export default handleActions(actionHandler, {
    loader: false,
    empData: {},
    employeeEnrolledLoader: false,
    employeeEnrolled: [],
    deEnroll: [],
    enrollmentDeadline:null,
    elligiblePlanLoader: false,
    eligibleplans: [],
    idTypes: [],
    idLoader: false,
    language:'en',
    isPicChange: false,
});
