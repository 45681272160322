import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import APIList from "api";
import ButtonM from "components/common/Button";
import PrimaryForLifePage from "components/pages/AddBeneficiaryAfterPlanENroll/PrimaryForLifePage";
import { useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

const BenAllocation = () => {

    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    
    const [addedBeneficiaries, setAddedBeneficiaries] = useState([]);
    const [isAllowedForContengent, setIsAllowedForContengent] = useState(false);
    const [totalPercentagePrimary, setTotalPercentagePrimary] = useState(0);
    const [dontWantToAddBeneficiary, setDontWantToAddBeneficiary] = useState(false);
    const [electionChecked, setElectionChecked] = useState(false);
    const [enrollMentLoader, setEnrollMentLoader] = useState(false);

    const addBenficiariesToPlan = () => {
      setEnrollMentLoader(true);
      let benWithZeroPercentIsPresent = addedBeneficiaries?.filter((ben) => ben?.percentage === 0)?.length > 0 ? true : false;
      if(benWithZeroPercentIsPresent){
        toast.info(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;Beneficiary with 0 % is not allowed.
          </div>
        );
        setEnrollMentLoader(false);
      } else {
        if(Math.round(addedBeneficiaries?.filter((x) => x?.primary)?.reduce((a, b) => a + Number(b.percentage), 0)) == 100){
          APIList.addBeneficiaryAllocation({addedBeneficiaries})
          .then((res) => {
            toast.success(
              <div className='flex flex-row'>
                <BsFillCheckCircleFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;Allocate Beneficiary Successfully.
              </div>
            )
              setEnrollMentLoader(false);
              history.push('/my-beneficiaries');              
            })
            .catch((err) => {
              toast.error(
                <div className="flex flex-row">
                  <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                  &nbsp;&nbsp;
                  {err?.message}            
                </div>
              );            
            setEnrollMentLoader(false);
            });
        } else {
          toast.info(
              <div className="flex flex-row">
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;Total of percentage is not 100%.
              </div>
            );
            setEnrollMentLoader(false);
          }
      }
     
            
      }
    
    return(
        <div className="AddBeneficiaryMainPage m-4">

        <h4 className="page-heading-navbar">{location?.state?.planName}</h4>            

              <PrimaryForLifePage 
                setIsAllowedForContengent={setIsAllowedForContengent} 
                setTotalPercentagePrimary={setTotalPercentagePrimary} 
                setDontWantToAddBeneficiary={setDontWantToAddBeneficiary}
                setAddedBeneficiaries={setAddedBeneficiaries}
                addedBeneficiaries={addedBeneficiaries}
                // isEdit={isEdit()}
                addBenPath={`/my-beneficiaries-allocation/${params?.id}`}
                planId={params?.id}
              />

              <div>
            <p
          className="mt-2 peer-invalid:visible text-gray-400 text-sm"
          style={{ marginBottom: "-1rem" }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  checked={electionChecked}
                  onChange={() => setElectionChecked(!electionChecked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              // label="Click here to confirm"
              label={<FormattedMessage id='myBeneficiaries.clickHereToConfirm' defaultValue='Click here to confirm'/>}
            />
          </FormGroup>
        </p>
              {/* <button className="next skip">I want to skip for now</button> */}
              <ButtonM
              // text="SUBMIT" 
              text={<FormattedMessage id='common.button.SUBMIT' defaultValue='SUBMIT'/>} 
              onpress={() => addBenficiariesToPlan()} 
              disabled={enrollMentLoader || !electionChecked} 
              loading={enrollMentLoader} 
              bgcolor={
                !electionChecked
                  ? "gray"
                  : ""
              }
              />
            </div>
        </div>
    )
}

export default BenAllocation;