import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../../../api/index';
import { actions, types } from './actions';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";

// const EmployeeGroupMedicalRequest = function* EmployeeGroupMedicalRequest({ payload }) {
//     try {
//         const { data } = yield call(api.getEmpHealthInsurance, payload);
//         if (data) {
//             yield put(actions.EmployeeGroupMedicalRequestSuccess(data));
//             // toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;feched succesfully</div>);
//         } else {
//             // toast.error(data.message);
//             yield put(actions.EmployeeGroupMedicalRequestFailed());
//         }
//     } catch (err) {
//         // toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
//         yield put(actions.EmployeeGroupMedicalRequestFailed());
//     }
// };
const EnrollWizardsRequest = function* EnrollWizardsRequest({ payload }) {
    try {
        const { data } = yield call(api.getEnrollWizards, payload);
        if (data) {
            yield put(actions.EnrollWizardsRequestSuccess(data));
            // toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.EnrollWizardsRequestFailed());
        }
    } catch (err) {
        // toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.EnrollWizardsRequestFailed());
    }
};

const EmployeeGroupMedicalRequest = function* EmployeeGroupMedicalRequest({ payload }) {
    try {
        const { data } = yield call(api.getPlanByEmployeeId, payload);
        if (data) {
            let plans = [];
            for (const [key, value] of Object.entries(data)) {
                plans = plans.concat(value);
            }
            // const abc = plans.sort((a, b) => a.planType.localeCompare(b.planType));

            const base = plans.filter((item) => {
                return item.planType === 'Base'
            });
            const voluntary = plans.filter((item) => {
                return item.planType === 'Voluntary'
            });
            const merge = [];
            voluntary.map((item) => (
                merge.push(item)
            ));
            base.map((item) => (
                merge.push(item)
            ));
            const sortBySequenceId = merge.sort((a, b) => (a.benefits.sequenceId > b.benefits.sequenceId) ? 1 : -1)

            yield put(actions.EmployeeGroupMedicalRequestSuccess(sortBySequenceId));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.EmployeeGroupMedicalRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.EmployeeGroupMedicalRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.EMPLOYEE_GROUP_MEDICAL_REQUEST, EmployeeGroupMedicalRequest);
    yield takeLatest(types.ENROLL_WIZARDS_REQUEST, EnrollWizardsRequest);
}
