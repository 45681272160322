import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.COMPANY_PLAN_DATA_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.COMPANY_PLAN_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        companyPlanData: payload || [],
    }),
    [types.COMPANY_PLAN_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),

    [types.EVENT_DETAIL_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.EVENT_DETAIL_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        eventDetails: payload || {},
        errors: {}
    }),
    [types.EVENT_DETAIL_REQUEST_FAILED]: (state, { payload }) => ({
        ...state,
        loader: false,
        eventDetails: {},
        errors: { title: "NO LIFE EVENT CONFIGURATION FOUND WITH GIVEN COMPANY ID " }
    }),

    [types.POST_EVENT_DETAIL_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),

  [types.POST_EVENT_DETAIL_REQUEST_SUCCESS]: (state, { payload }) => ({
    ...state,
    loader: false,
    postStatus: true,
  }),
  [types.POST_EVENT_DETAIL_REQUEST_FAILED]: (state, { payload }) => ({
    ...state,
    loader: false,
    postStatus: false,
    eventDetails: {},
    errors: { title: "EVENT POST FAILED" },
  }),
  [types.SELECT_EVENT_OPTION]: (state, { payload }) => ({
    ...state,
    selectedOption: payload,
  }),
  [types.CLEAR_SELECTED_EVENT_OPTION]: (state, { payload }) => ({
    ...state,
    selectedOption: null,
  }),
};

export default handleActions(actionHandler, {
  loader: false,
  companyPlanData: [],
  eventDetails: {},
  errors: {},
  postStatus: false,
  selectedOption: null,
});
