const { createAction } = require('redux-actions');
const PLAN_BENEFICIARIES_DATA_REQUEST = 'BENEVOLVE/PLAN_BENEFICIARIES_DATA_REQUEST';
const PLAN_BENEFICIARIES_DATA_REQUEST_SUCCESS = 'BENEVOLVE/PLAN_BENEFICIARIES_DATA_REQUEST_SUCCESS';
const PLAN_BENEFICIARIES_DATA_REQUEST_FAILED = 'BENEVOLVE/PLAN_BENEFICIARIES_DATA_REQUEST_FAILED';

const PlanBeneficiariesDataRequest = createAction(PLAN_BENEFICIARIES_DATA_REQUEST);
const PlanBeneficiariesDataRequestSuccess = createAction(PLAN_BENEFICIARIES_DATA_REQUEST_SUCCESS);
const PlanBeneficiariesDataRequestFailed = createAction(PLAN_BENEFICIARIES_DATA_REQUEST_FAILED);

const ALL_BENEFICIARIES_REQUEST = 'BENEVOLVE/ALL_BENEFICIARIES_REQUEST';
const ALL_BENEFICIARIES_REQUEST_SUCCESS = 'BENEVOLVE/ALL_BENEFICIARIES_REQUEST_SUCCESS';
const ALL_BENEFICIARIES_REQUEST_FAILED = 'BENEVOLVE/ALL_BENEFICIARIES_REQUEST_FAILED';

const GetAllBeneficiariesRequest = createAction(ALL_BENEFICIARIES_REQUEST);
const GetAllBeneficiariesRequestSuccess = createAction(ALL_BENEFICIARIES_REQUEST_SUCCESS);
const GetAllBeneficiariesRequestFailed = createAction(ALL_BENEFICIARIES_REQUEST_FAILED);


const TEMP_BEN_ALLOCATION_INFO = 'BENEVOLVE/TEMP_BEN_ALLOCATION_INFO';
const TempBenAllocationInfo = createAction(TEMP_BEN_ALLOCATION_INFO);



export const actions = {
    PlanBeneficiariesDataRequest,
    PlanBeneficiariesDataRequestSuccess,
    PlanBeneficiariesDataRequestFailed,    

    GetAllBeneficiariesRequest,
    GetAllBeneficiariesRequestSuccess,
    GetAllBeneficiariesRequestFailed,

    TempBenAllocationInfo,

};

export const types = {
    PLAN_BENEFICIARIES_DATA_REQUEST,
    PLAN_BENEFICIARIES_DATA_REQUEST_SUCCESS,
    PLAN_BENEFICIARIES_DATA_REQUEST_FAILED,

    ALL_BENEFICIARIES_REQUEST,
    ALL_BENEFICIARIES_REQUEST_SUCCESS,
    ALL_BENEFICIARIES_REQUEST_FAILED,

    TEMP_BEN_ALLOCATION_INFO,
};

