import { useEffect } from "react";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import ellipseIcon from "../../../../../assets/Ellipse.png"

import { useHistory, useLocation } from "react-router-dom";
import ButtonM from "../../../../common/Button";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import { genders, getRelationName, relations, requiredSelectStyled, requiredStyled } from "components/utils/utils";

const AddDependentFamily = () => {
  const history = useHistory();

  // const location = useLocation();

  // const [allowedDependents, setAllowedDependents] = React.useState(null);
  // console.log("allowedDependents",allowedDependents)

  const employeeDetails = useSelector((state) => state && state.empData);
  const empCompany = useSelector((state) => state?.empData?.empData?.company?.displayName);
  const idType = useSelector((state) => state?.empData?.idTypes);

  const selectedPlan = useSelector((state) => state?.planReducer?.planData);

  const {
    selectedPlanDependants
  } = useSelector((state) => state?.voluntryReducer);
  

  // useEffect(() => {
  //   getAllowedDepends();
  // }, []);

  // const getAllowedDepends = () => {
  //   let legitDependents = [];
  
  //   selectedPlan &&
  //     selectedPlan.planDependants &&
  //     selectedPlan.planDependants.forEach(
  //       (x) =>
  //         x &&
  //         x.employeeRoles.forEach(
  //           (y) =>
  //             (y && y.id) ===
  //               (employeeDetails &&
  //                 employeeDetails.empData &&
  //                 employeeDetails.empData.employeeRole &&
  //                 employeeDetails.empData.employeeRole.id) &&
  //                 legitDependents.push(x)
  //         )
  //     );

  //     setAllowedDependents(legitDependents);
  // }

  const initialEmptyDependent = {
    relationship: null,
    firstName: null,
    middleName: null,
    lastName: null,
    gender: null,
    idType: null,
    nationalId: null,
    dateOfBirth: null,
    nationality: null,
    isRelativeWorking:null,
    isRelativeWorkingInNium:null,
    employeeIdOfRelative:null,
  };

  const initialFormErrorList = {
    relationship: { isError: false, errorMessage: "" },
    firstName: { isError: false, errorMessage: "" },
    middleName: { isError: false, errorMessage: "" },
    lastName: { isError: false, errorMessage: "" },
    gender: { isError: false, errorMessage: "" },
    idType: { isError: false, errorMessage: "" },
    nationalId: { isError: false, errorMessage: "" },
    dateOfBirth: { isError: false, errorMessage: "" },
    nationality: { isError: false, errorMessage: "" },
    isRelativeWorking: { isError: false, errorMessage: "" },
    isRelativeWorkingInNium: { isError: false, errorMessage: "" },
    employeeIdOfRelative: { isError: false, errorMessage: "" },
  };

  const [dependent, setDependent] = React.useState(initialEmptyDependent);
  const [formError, setFormError] = React.useState(initialFormErrorList);

  const selectedRelation =
    selectedPlan &&
    selectedPlan.planDependants.filter(
      (x) =>
        x &&
        x.relation &&
        x.relation.includes(dependent.relationship && dependent.relationship)
    );
  const handleResetForm = () => {
    const tempInitDependent = JSON.parse(JSON.stringify(initialEmptyDependent));
    // console.log(tempInitDependent);
    // console.log(dependent);

    const tempInitError = JSON.parse(JSON.stringify(initialFormErrorList));
    setDependent(tempInitDependent);
    setFormError(tempInitError);
  };

  const handleChangeDependent = async (event, fieldType) => {
    
    const tempDependent = JSON.parse(JSON.stringify(dependent));
    const tempError = JSON.parse(JSON.stringify(formError));
    if (fieldType === "textfield" || fieldType === "dropdown") {
      tempDependent[event.target.name] = event.target.value;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    } else if (fieldType === "birthdate") {
      tempDependent.dateOfBirth = event;
      tempError.dateOfBirth.isError = false;
      tempError.dateOfBirth.errorMessage = "";
    }else if (fieldType === "checkbox") {
      tempDependent[event.target.name] = event.target.value === "true" ? true : false;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    }

    // if(!tempDependent["isRelativeWorking"]){
    //   tempDependent["isRelativeWorkingInNium"] = null;
    //   tempDependent["employeeIdOfRelative"] = null;
    // }
    if(!tempDependent["isRelativeWorkingInNium"]){
      tempDependent["employeeIdOfRelative"] = null;
    }

    setDependent(tempDependent);
    setFormError(tempError);
  };

  const handleValidateDependent = async () => {
    let isValid = true;
    const tempError = JSON.parse(JSON.stringify(formError));
    const tempDependent = JSON.parse(JSON.stringify(dependent));

    if (
      tempDependent.relationship === null ||
      tempDependent.relationship.trim() === ""
    ) {
      tempError.relationship.isError = true;
      tempError.relationship.errorMessage = "Please select Relationship";
      isValid = false;
    }
    if (
      tempDependent.firstName === null ||
      tempDependent.firstName.trim() === ""
    ) {
      tempError.firstName.isError = true;
      tempError.firstName.errorMessage = "Please enter First Name";
      isValid = false;
    }
    // if (
    //   tempDependent.middleName === null ||
    //   tempDependent.middleName.trim() === ""
    // ) {
    //   tempError.middleName.isError = true;
    //   tempError.middleName.errorMessage = "Please enter Middle Name";
    //   isValid = false;
    // }

    if (
      tempDependent.lastName === null ||
      tempDependent.lastName.trim() === ""
    ) {
      tempError.lastName.isError = true;
      tempError.lastName.errorMessage = "Please enter Last Name";
      isValid = false;
    }

    if (tempDependent.gender === null || tempDependent.gender.trim() === "") {
      tempError.gender.isError = true;
      tempError.gender.errorMessage = "Please select Gender";
      isValid = false;
    }
    // if (tempDependent.idType === null || tempDependent.idType.trim() === "") {
    //   tempError.idType.isError = true;
    //   tempError.idType.errorMessage = "Please select idType";
    //   isValid = false;
    // }
    
    // if (
    //   tempDependent.dateOfBirth == null ||
    //   tempDependent.dateOfBirth.trim() == ""
    // ) {
    //   tempError.dateOfBirth.isError = true;
    //   tempError.dateOfBirth.errorMessage = "Please enter Date of Birth";
    //   isValid = false;
    // }
    // if (
    //   (getAge(tempDependent.dateOfBirth) + 1) >
    //   ((selectedRelation &&
    //     selectedRelation[0] &&
    //     selectedRelation[0].maxAge !== null) ?
    //     selectedRelation[0].maxAge : 1000)
    // ) {
    //   tempError.dateOfBirth.isError = true;
    //   tempError.dateOfBirth.errorMessage = `This plan does not allow ${
    //     tempDependent.relationship
    //   } above ${
    //     (selectedRelation &&
    //       selectedRelation[0] &&
    //       selectedRelation[0].maxAge !== null) ?
    //       selectedRelation[0].maxAge : '1000'
    //   } years to be covered`; 
    //   isValid = false;
    // }
    // if (
    //   getAge(tempDependent.dateOfBirth) <
    //   ((selectedRelation &&
    //     selectedRelation[0] &&
    //     selectedRelation[0].minAge !== null) ?
    //     selectedRelation[0].minAge : 0)
    // ) {
    //   tempError.dateOfBirth.isError = true;
    //   tempError.dateOfBirth.errorMessage = `This plan does not allow ${
    //     tempDependent.relationship
    //   } below ${
    //     (selectedRelation &&
    //       selectedRelation[0] &&
    //       selectedRelation[0].minAge !== null) ?
    //       selectedRelation[0].minAge : 0
    //   } years to be covered`;
    //   isValid = false;
    // }
    // if (
    //   tempDependent.idType !== null && (tempDependent.nationalId == null ||
    //   tempDependent?.nationalId?.trim() == "")
    // ) {
    //   tempError.nationalId.isError = true;
    //   tempError.nationalId.errorMessage = `Please enter ID (${tempDependent?.idType})`;
    //   isValid = false;
    // }
    // if (
    //   tempDependent.nationality == null ||
    //   tempDependent?.nationality?.trim() == ""
    // ) {
    //   tempError.nationality.isError = true;
    //   tempError.nationality.errorMessage = "Please enter nationality";
    //   isValid = false;
    // } 
    if (
      getAge(tempDependent.dateOfBirth) < 0
    ) {
      tempError.dateOfBirth.isError = true;
      tempError.dateOfBirth.errorMessage = "Please enter correct Date of Birth";
      isValid = false;
    }
    // if (
    //   tempDependent.isRelativeWorking == null 
    // ) {
    //   tempError.isRelativeWorking.isError = true;
    //   tempError.isRelativeWorking.errorMessage = "Please select yes/no";
    //   isValid = false;
    // }
    if (
      tempDependent.isRelativeWorkingInNium == null
    ) {
      tempError.isRelativeWorkingInNium.isError = true;
      tempError.isRelativeWorkingInNium.errorMessage = "Please select yes/no";
      isValid = false;
    }
    if (
      tempDependent.isRelativeWorkingInNium && (tempDependent.employeeIdOfRelative == null || tempDependent?.employeeIdOfRelative?.trim() == "")
    ) {
      tempError.employeeIdOfRelative.isError = true;
      tempError.employeeIdOfRelative.errorMessage = "Please enter employe-id of dependant";
      isValid = false;
    }
    //do Submit
    if (isValid) {
      // if (isEdit) {
      //   updateDependent();
      // } else {
      submitDependent();
      // }
    } else {
      setFormError(tempError);
    }
  };

  const submitDependent = () => {
    handleResetForm();
    history.push({
      pathname: "/voluntry/family-add-dependant-address",
      state: { dependant: dependent },
    });
  };

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <div className="AddDependentPage">
      <h4 className="page-heading-navbar">Add Dependent</h4>

     

      <Stack sx={{ mx: { xs: 0, xl: 10, md: 5 } }}>
        <Box sx={{ my: 2 }}>
          <Stack
            display={{ xs: "flex", sm: "flex" }}
            direction={{ xs: "column", sm: "column" }}
            justifyContent="space-between"
            alignItems={{ xs: "center", sm: "center" }}
            spacing={2}
          >
            <Stack width={{ xs: "90%", sm: "75%" }}>
               {/* <Stack sx={{ mb: 5 }} width={{ xs: "90%", sm: "100%" }}> */}
      <div className="mt-4">
        <div className="flex flex-wrap">
          <div className="w-full flex justify-between">
            <div className="details">
              <h3 className="title">Personal Details</h3>
              <p className="subTitle">
                Add the details for your dependents.{" "}
              </p>
            </div>

            {/* <div className="eclipseOuter mx-2">
              <img src={ellipseIcon} alt="Ellipse" className="ellipse" />
              <p className="count">2 of 4</p>
            </div> */}
          </div>
        </div>
      </div>
      {/* </Stack> */}
              <FormControl sx={{ my: 2 }}>
                <InputLabel size="small" id="relationship">
                  Select relationship
                </InputLabel>
                <Select
                  value={dependent.relationship ? dependent.relationship : ""}
                  labelId="Select relationship"
                  name="relationship"
                  onChange={(event) => handleChangeDependent(event, "dropdown")}
                  variant="outlined"
                  error={formError.relationship.isError}
                  helperText={formError.relationship.errorMessage}
                  label="Select relationship"
                  size="small"
                  sx={{
                    width: "100%",
                    //backgroundColor: "#dedede",
                    "& fieldset": {
                      border: "1px solid #dedede",
                    },
                    "& .MuiInputBase-input:focus": {
                      border: "2px solid #fcd25e", // focus
                    },
                    "& .css-k4qjio-MuiFormHelperText-root": {
                      backgroundColor: "#ffffff",
                      margin: "0px",
                      paddingLeft: "0.5rem",
                    },
                    ...requiredSelectStyled
                  }}
                >
                   {
                  selectedPlanDependants &&
                  selectedPlanDependants.map((allowedDependent) => {
                      return (
                        allowedDependent &&
                        allowedDependent.relation &&
                        allowedDependent.relation.map((relation) => {
                         
                          return ( 
                            relation == "Parent" ?
                            ["Mother","Father"].map((rel) => {
                              return(
                            <MenuItem key={rel} value={rel} sx={{textTransform:"capitalize"}}>
                              {getRelationName(rel)}
                              {}
                            </MenuItem>
                              )
                            })
                            :  
                            relation == "PIL" ?
                            ["FIL","MIL"].map((rel) => {
                              return(
                            <MenuItem key={rel} value={rel} sx={{textTransform:"capitalize"}}>
                              {getRelationName(rel)}
                              {}
                            </MenuItem>
                              )
                            })
                            :                                                       
                            <MenuItem key={relation} value={relation} sx={{textTransform:"capitalize"}}>
                              {getRelationName(relation)}
                              {}
                            </MenuItem>
                          );
                        })
                      );
                    })}
                  {/* {
                  relations.map((relation) => {                          
                          return (
                            <MenuItem key={relation} value={relation} sx={{textTransform:"capitalize"}}>
                              {relation}
                              {}
                            </MenuItem>
                          );
                        })
                   } */}
                </Select>
                {formError.relationship.errorMessage && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {formError.relationship.errorMessage}
                  </FormHelperText>
                )}
              </FormControl>

              <TextField
                size="small"
                name="firstName"
                label="First Name"
                id="outlined-basic"
                onChange={(event) => handleChangeDependent(event, "textfield")}
                variant="outlined"
                error={formError.firstName.isError}
                helperText={formError.firstName.errorMessage}
                value={dependent.firstName ? dependent.firstName : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  //backgroundColor: "#dedede",
                  "& fieldset": {
                    border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                  ...requiredStyled
                }}
              />
              <TextField
                size="small"
                name="middleName"
                label="Middle Name"
                id="outlined-basic"
                onChange={(event) => handleChangeDependent(event, "textfield")}
                variant="outlined"
                error={formError.middleName.isError}
                helperText={formError.middleName.errorMessage}
                value={dependent.middleName ? dependent.middleName : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  //backgroundColor: "#dedede",
                  "& fieldset": {
                    border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                }}
              />
              <TextField
                size="small"
                name="lastName"
                label="Last Name"
                id="outlined-basic"
                onChange={(event) => handleChangeDependent(event, "textfield")}
                variant="outlined"
                error={formError.lastName.isError}
                helperText={formError.lastName.errorMessage}
                value={dependent.lastName ? dependent.lastName : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  //backgroundColor: "#dedede",
                  "& fieldset": {
                    border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                  ...requiredStyled
                }}
              />
              <FormControl sx={{ my: 2 }}>
                <InputLabel size="small" id="gender">
                  Gender
                </InputLabel>
                <Select
                  value={dependent.gender ? dependent.gender : ""}
                  labelId="gender"
                  name="gender"
                  onChange={(event) => handleChangeDependent(event, "dropdown")}
                  variant="outlined"
                  error={formError.gender.isError}
                  helperText={formError.gender.errorMessage}
                  label="Gender"
                  size="small"
                  sx={{
                    width: "100%",
                    //backgroundColor: "#dedede",
                    "& fieldset": {
                      border: "1px solid #dedede",
                    },
                    "& .MuiInputBase-input:focus": {
                      border: "2px solid #fcd25e", // focus
                    },
                    "& .css-k4qjio-MuiFormHelperText-root": {
                      backgroundColor: "#ffffff",
                      margin: "0px",
                      paddingLeft: "0.5rem",
                    },
                    ...requiredSelectStyled
                  }}
                >
                   {
                    genders.map((gender) => {
                      return(
                        <MenuItem key={gender.value} value={gender.value}>
                          {gender.name}
                        </MenuItem>
                      )
                    })
                  }  
                </Select>
                {formError.gender.errorMessage && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {formError.gender.errorMessage}
                  </FormHelperText>
                )}
              </FormControl>

              {/* <FormControl sx={{ my: 2 }}>
                <InputLabel size="small" id="idType">
                  Id Type
                </InputLabel>
                <Select
                  value={dependent.idType ? dependent.idType : ""}
                  labelId="idType"
                  name="idType"
                  onChange={(event) => handleChangeDependent(event, "dropdown")}
                  variant="outlined"
                  error={formError.idType.isError}
                  helperText={formError.idType.errorMessage}
                  label="idType"
                  size="small"
                  sx={{
                    width: "100%",
                    //backgroundColor: "#dedede",
                    "& fieldset": {
                      border: "1px solid #dedede",
                    },
                    "& .MuiInputBase-input:focus": {
                      border: "2px solid #fcd25e", // focus
                    },
                    "& .css-k4qjio-MuiFormHelperText-root": {
                      backgroundColor: "#ffffff",
                      margin: "0px",
                      paddingLeft: "0.5rem",
                    },
                  }}
                >
                  {
                    idType?.map((type) => {
                      return(
                        <MenuItem key={type.name} value={type.name}>
                          {type.name}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
                {formError.idType.errorMessage && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {formError.idType.errorMessage}
                  </FormHelperText>
                )}
              </FormControl>

              <TextField
                size="small"
                name="nationalId"
                label={dependent.idType || "Id"}
                disabled={dependent.idType == null}
                id="outlined-basic"
                onChange={(event) => handleChangeDependent(event, "textfield")}
                variant="outlined"
                error={formError.nationalId.isError}
                helperText={formError.nationalId.errorMessage}
                value={dependent.nationalId ? dependent.nationalId : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  //backgroundColor: "#dedede",
                  "& fieldset": {
                    border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                }}
              /> */}

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat={
                    employeeDetails &&
                    employeeDetails.empData &&
                    employeeDetails.empData.company &&
                    employeeDetails.empData.company.dateFormat &&
                    employeeDetails.empData.company.dateFormat ||
                    "dd/MM/yyyy"
                  }
                  value={dependent.dateOfBirth}
                  label="Date of Birth"
                  name="dateOfBirth"
                  maxDate={new Date()}
                  onChange={(event) =>
                    handleChangeDependent(event, "birthdate")
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      helperText={formError.dateOfBirth.errorMessage}
                      size="small"
                      sx={{
                        pointerEvents: 'none',
                        '& .MuiOutlinedInput-root': {
                          'button': {
                            pointerEvents: 'all',
                          }},
                        my: 2,
                        width: "100%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        "& .MuiInputBase-input:focus": {
                          border: "2px solid #fcd25e", // focus
                        },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        // ...requiredStyled
                      }}
                      {...params}
                      error={formError.dateOfBirth.isError}
                    />
                  )}
                />
              </LocalizationProvider>
               <TextField
                size="small"
                name="nationality"
                label="Nationality"
                id="outlined-basic"
                onChange={(event) =>
                  handleChangeDependent(event, "textfield")
                }
                variant="outlined"
                error={formError.nationality.isError}
                helperText={formError.nationality.errorMessage}
                value={dependent.nationality ? dependent.nationality : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  //backgroundColor: "#dedede",
                  "& fieldset": {
                      border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                  // ...requiredStyled
                }}
              />
              {/* <Stack
        direction='row'
        alignItems='center'
      >
        <Stack sx={{width: '50%'}} spacing={5}>
          <FormLabel id='demo-row-radio-buttons-group-label'>
            <Stack direction='row'>
              <Stack fontWeight={500}>Is dependant working ?</Stack>
            </Stack>
          </FormLabel>
        </Stack>
        <Stack sx={{width: '50%'}} direction='row' alignItems='center'>
          <RadioGroup
            value={dependent?.isRelativeWorking}
            onChange={(event) => handleChangeDependent(event, "checkbox")}
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='isRelativeWorking'
          >
            <FormControlLabel value={true} control={<Radio />} label='Yes' />
            <FormControlLabel value={false} control={<Radio />} label='No' />
          </RadioGroup>
          {formError?.isRelativeWorking?.isError && (
            <FormHelperText className='Mui-error'>
              {formError?.isRelativeWorking?.errorMessage}
            </FormHelperText>
          )}
        </Stack>
      </Stack> */}

      {/* {
        dependent?.isRelativeWorking &&  */}
        <Stack
        direction='row'
        alignItems='center'
      >
        <Stack sx={{width: '50%'}} spacing={5}>
          <FormLabel id='demo-row-radio-buttons-group-label'>
            <Stack direction='row'>
              <Stack fontWeight={500}>Is dependant working in {empCompany} ?</Stack>
            </Stack>
          </FormLabel>
        </Stack>
        <Stack sx={{width: '50%'}} direction='row' alignItems='center'>
          <RadioGroup
            value={dependent?.isRelativeWorkingInNium}
            onChange={(event) => handleChangeDependent(event, "checkbox")}
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='isRelativeWorkingInNium'
          >
            <FormControlLabel value={true} control={<Radio />} label='Yes' />
            <FormControlLabel value={false} control={<Radio />} label='No' />
          </RadioGroup>
          {formError?.isRelativeWorkingInNium?.isError && (
            <FormHelperText className='Mui-error'>
              {formError?.isRelativeWorkingInNium?.errorMessage}
            </FormHelperText>
          )}
        </Stack>
      </Stack>     
      {/* }     */}
      {
        dependent?.isRelativeWorkingInNium && 
        <TextField
                size="small"
                name="employeeIdOfRelative"
                label="Employee-ID of Dependant"
                id="outlined-basic"
                onChange={(event) =>
                  handleChangeDependent(event, "textfield")
                }
                variant="outlined"
                error={formError.employeeIdOfRelative.isError}
                helperText={formError.employeeIdOfRelative.errorMessage}
                value={dependent.employeeIdOfRelative ? dependent.employeeIdOfRelative : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  //backgroundColor: "#dedede",
                  "& fieldset": {
                      border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                }}
              />
      }
            <div className="bottomButtonReq">
              <ButtonM text="NEXT" onpress={() => handleValidateDependent()} />
              </div>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </div>
  );
};

export default AddDependentFamily;
