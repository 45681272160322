import loginBg from "../../../assets/loginBg.svg";
import { useDispatch, useSelector } from "react-redux";
import { actions as logInActions } from "./redux/actions";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams , useLocation} from "react-router-dom";
import ButtonM from "../../common/Button";
import ReactDOM from 'react-dom';
import Popout from 'react-popout-v2';
import { isEmptyNullUndefined } from "components/utils/utils";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const LogIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);
  const [isToken, setIsToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [usernameText, setUsernameText] = useState(true);
  const [loginPopup, setLoginPopup] = useState(false);

  const userToken = useSelector((state) => state?.user?.userToken);
  const loading = useSelector((state) => state?.user?.loader);

  useEffect(() => {
    if (localStorage.getItem("loggedOutFromFooter")) {
      localStorage.removeItem("loggedOutFromFooter");
      window.location.reload();
    }
  }, []);

  // Handle SSO token in URL
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const token = pathSegments[pathSegments.length - 1];
    if (location.pathname.startsWith('/login/') && token) {
      dispatch(logInActions.LogInRequest({
        username: "logInThroughSSORedirect",
        password: token
      }));
    }
  }, [location, dispatch]);

  // Logging in through admin portal 
  useEffect(() => {
    if (params?.xyz?.length > 0) {
      dispatch(logInActions.LogInRequest({
        username: "logInThroughPopupWindow",
        password: params?.xyz
      }));
    }
  }, [params, dispatch]);

 useEffect(() => {
    // if (userToken && Object.keys(userToken).length > 0) {      
    if (userToken?.id_token !== undefined) {      
      // dispatch(logInActions.UserRequest());
      history.push('/home');
    }
  }, [userToken]);

  // useEffect(() => {
  //   if ((user && Object.keys(user).length > 0) && (user && !user.loader)) {
  //     history.push('/home');
  //   }
  // }, [user]);

  useEffect(() => {
    // console.log("in use call back out");

      if(isEmptyNullUndefined(userToken)){
        // console.log("in use call back if");
        setIsToken("no");
      }
  }, [submit]);
  
  const checkValidation = () => {
    setUsernameText(false);
    if (username && password) {
      dispatch(logInActions.LogInRequest({
        username: username,
        password: password
      }));
    } 
      setSubmit(true);
    
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      checkValidation();
    }
  }

 window.addEventListener('message', function(e){
    // console.log("addEventListener", e.data);
    if(e?.data?.id_token){
      dispatch(logInActions.LogInRequest({
        username: "logInThroughPopupWindow",
        password: e?.data?.id_token
      }))
    }
  })  

  return (
    <div className="w-full absolute top-0 bg-white">
      <div className="headerLogin">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full">
              <div className="headerLoginInner flex flex-col">
                <img src={loginBg} alt="logBg" className="logoImg" />
                <div className="left pt-4 pb-4 flex flex-col items-start">
                  <p className="text">
                    WELCOME <br />
                    <span className='textInner'>Nium</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="loginBlock">
        <div className="container mx-auto px-5">
        <div className="flex flex-wrap pb-2">
            <div className="w-full loginBtnOuter">
              <ButtonM text='Login via company' 
              onpress={() => {
                // handleLoginClick();
                setLoginPopup(true)
              }} 
                bgcolor="var(--primary)" 
                color="#ffffff" 
                bghover="#287546" />
            </div>
        </div>
        <div className="orOuter my-4">
          <hr />
          <div className="orInner">
            OR
          </div>
        </div>
          <div className="flex flex-wrap pb-2">
            <div className="my-1 w-full">
              <input
                name="email"
                onChange={(event) => {setUsername(event.target.value); setIsToken(""); setSubmit(false);}}
                className={(submit && (username.trim() === "" || (!loading && isToken == "no"))) ? "input border border-red-500 focus:outline-none" : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"}
                type="text"
                placeholder="Username"                
              />
              {
                usernameText &&
                <p className="pt-4 peer-invalid:visible text-gray-400 text-sm" style={{ lineHeight: '0px'}}>
                  Your username is your company e-mail id.
                </p>
              }
              {submit && (username.trim() === "" || (!loading && isToken == "no")) && (
                <p className="mt-2 peer-invalid:visible text-sm" style={{color:"var(--warning)"}}>
                  Your username is your company e-mail id.<br/>
                  *Please provide a valid Username.
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap pt-2">
            <div className="my-1 w-full">
              <div className="flex relative">
              <input
                name="password"
                onChange={(event) => {setPassword(event.target.value); setIsToken(""); setSubmit(false);}}
                className={(submit && (password.trim() === "" || (!loading && isToken == "no"))) ? "input border border-red-500 focus:outline-none" : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"}
                type = {showPassword ? "text" : "password"}
                placeholder="Password"
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <span class="eyeIcon" onClick={() => setShowPassword(!showPassword)}>
                {
                  showPassword ?
                    <FaEye />
                    :
                    <FaEyeSlash />
                }               
              </span>
              </div>
              {submit && (password.trim() === "" || (!loading && isToken == "no")) && (
                <p className="mt-2 peer-invalid:visible text-sm" style={{color:"var(--warning)"}}>
                  *Please provide a valid Password.
                </p>
              )}
            </div>
          </div>
          <div className="flex justify-between pt-2 pb-2">
            <div className="w-full">
              {/* <Link className="link">Forgot Username</Link> */}
            </div>
            <div className='w-full text-right'>
              <Link to='/forgot-password' className='link'>
              Forgot Password ?
              </Link>
            </div>
          </div>
          <div className="flex flex-wrap pb-2">
          <div className="w-full loginBtnOuter" id="login_btn_for_employee">
              <ButtonM text='Login' onpress={() => checkValidation()} loading={loading} disabled={loading} bgcolor="var(--primary)" color="#ffffff" bghover="#287546" />       
          </div>
          </div>
         
          
        </div>
      </div>
      {
        loginPopup &&
        <Popout 
        url='/saml/login?disco=true' 
        reactDom={ReactDOM}
        title='SSO Login' 
        onClose={() => setLoginPopup(false)}
        >
        </Popout>
      }
      
    </div>
  );
};

export default LogIn;