import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import APIList from "api";
import ButtonM from "components/common/Button";
import { isEmptyNullUndefined } from "components/utils/utils";
import { useEffect, useRef, useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiCloseCircleFill, RiErrorWarningFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import removeImgBtn from "../../../../assets/reimbuersement/close_remove_image_btn.png";

const UpdateDetails = () => {

  const location = useLocation();
  const history = useHistory();

  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const ReimbursementPlanData = useSelector((state) => state?.reimReducer?.reimbursePlanData?.plan);

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  // const [imagePreviews, setImagePreviews] = useState([]);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [imageInfos, setImageInfos] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const progressInfosRef = useRef(null);
  const [coverage, setCoverage] = useState(null);
  const [dependantRule, setdependantRule] = useState(null);
  const [remainingAmount, setRemainingAmount] = useState("");

const getCatRules = () => {
  let arr = []

  location?.state?.categoryId?.forEach((id) => {
     arr.push({id : id})
  })

  return arr;
}

  const initialEmptyUploadDetails = {
    date: null,
    description: null,
    amount: null,

    reimbursementsId:{
      id: ReimbursementPlanData?.id || null
  },

    dependantsId:{
      id: location?.state?.addedDependent?.id || null
  },
       
    receipts: [],

    categoryRules : getCatRules(),  

    submittedBy: {id: employeeDetails?.id}
  };

  const initialFormErrorList = {
    date: { isError: false, errorMessage: "" },
    description: { isError: false, errorMessage: "" },
    amount: { isError: false, errorMessage: "" },
    file: { isError: false, errorMessage: "" },
  };

  const [uploadDetails, setUploadDetails] = useState(initialEmptyUploadDetails);
  const [formError, setFormError] = useState(initialFormErrorList);

  const [imgData, setimgData] = useState([]);

useEffect(() => {
  if(isEmptyNullUndefined(location?.state?.categoryId)){
    history.goBack();
  }
  if(!location?.state?.selectedSelf && isEmptyNullUndefined(location?.state?.addedDependent)){
    history.goBack();
  }

  ReimbursementPlanData?.reimbursementCoverageRules?.forEach(cov => {
    cov?.employeeRoles?.forEach(x => {
      if(x.id === employeeDetails?.employeeRole?.id){
         setCoverage(cov);
      }
    })
  });

  if(!location?.state?.selectedSelf){    
    ReimbursementPlanData?.dependantRules?.forEach(depRule => {
      depRule?.employeeRoles?.forEach(x => {
        if(x.id === employeeDetails?.employeeRole?.id){
           setdependantRule(depRule);
        }
      })
    });
  };
}, [])

useEffect(() => { 
  if(!isEmptyNullUndefined(ReimbursementPlanData?.id)){
    APIList.remainingAmount({
    empId: employeeDetails?.id,
    reimId: ReimbursementPlanData?.id
  }).then((res) => {
    setRemainingAmount(res.data)
  }).catch((err) => {
    setRemainingAmount("N/A")
    toast.error(
      <div className="flex flex-row">
        <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
        &nbsp;&nbsp;
        {err?.message}            
      </div>
    )
  })
  }  
}, [ReimbursementPlanData])


  useEffect(() => {
    const tempUploadDetails = JSON.parse(JSON.stringify(uploadDetails));
    tempUploadDetails.receipts = imgData;
    setUploadDetails(tempUploadDetails);
  }, [imgData])  
  
  const handleChangeUploadDetails = async (event, fieldType) => {
    const tempUploadDetails = { ...uploadDetails };
    const tempError = JSON.parse(JSON.stringify(formError));
    if (fieldType === "textfield" || fieldType === "dropdown") {
      tempUploadDetails[event.target.name] = event.target.value;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    } else if (fieldType === "date") {
      tempUploadDetails.date = event;
      tempError.date.isError = false;
      tempError.date.errorMessage = "";
    } else if (fieldType == "amount") {
      tempUploadDetails[event.target.name] = event.target.value.replace(/[^0-9]/g,""); 
      if(!isEmptyNullUndefined(location?.state?.addedDependent)){
        if(coverage?.limitType === "PerPerson"){
          if(Number(tempUploadDetails.amount) < Number(dependantRule?.lowerLimit) || Number(tempUploadDetails.amount) > Number(dependantRule?.upperLimit)){
            tempError[event.target.name].isError = true;
            tempError[event.target.name].errorMessage = `Amount should be between ${dependantRule?.lowerLimit} - ${dependantRule?.upperLimit}`;
          } else {
            tempError[event.target.name].isError = false;
          tempError[event.target.name].errorMessage = "";
          }
        } else if(coverage?.limitType === "Cummulative"){
          if(Number(tempUploadDetails.amount) > Number(coverage?.limit)){
            tempError[event.target.name].isError = true;
            tempError[event.target.name].errorMessage = `Amount should be less than ${coverage?.limit}`;
            
          } else {
            tempError[event.target.name].isError = false;
            tempError[event.target.name].errorMessage = "";
          }
        } 
      } else if(location?.state?.selectedSelf) {
        if(coverage?.limitType === "PerPerson"){
          if(Number(tempUploadDetails.amount) < Number(coverage?.lowerLimit) || Number(tempUploadDetails.amount) > Number(coverage?.upperLimit)){
            tempError[event.target.name].isError = true;
            tempError[event.target.name].errorMessage = `Amount should be between ${coverage?.lowerLimit} - ${coverage?.upperLimit}`;
            
          } else {
            tempError[event.target.name].isError = false;
            tempError[event.target.name].errorMessage = "";
          }
        } else if(coverage?.limitType === "Cummulative"){
          if(Number(tempUploadDetails.amount) > Number(coverage?.limit)){
            tempError[event.target.name].isError = true;
            tempError[event.target.name].errorMessage = `Amount should be less than ${coverage?.limit}`;
           
          } else {
            tempError[event.target.name].isError = false;
            tempError[event.target.name].errorMessage = "";
          }
        } 
      }  
    
    } else if (fieldType === "file") {
      let images = [...imgData];
      event.target.files?.length > 0 && 
      Object.keys(event.target.files).map((x) => {
        // console.log("event.target.files",event.target.files);
        const file =
      event.target.files?.length > 0 ? event.target.files[x] : undefined;
    if (!uploadingFile && file != undefined) {
      setUploadingFile(true);
      
        // UploadFile(file);
        const formData = new FormData();
        formData.append('file', file);
       
     APIList.fileDocUpload(formData, {
          headers: { ContentType: "multipart/form-data" },
        })
        
          .then((res) => {
            images.push({filePath : res.data})
            
          })
          .catch((e) => {
            toast.error(e?.response?.data?.message);
            setUploadingFile(false);
          });
    
        const inputElem = document.getElementById('inputFile_');
        if (inputElem != undefined) inputElem.value = null;
      
    }
      }) 
      setTimeout(() => {
        setimgData(images) 
        setUploadingFile(false);
      }, 2000);
      
    }
    setUploadDetails(tempUploadDetails);
    setFormError(tempError);
  }; 

  // console.log("uploadDetails", uploadDetails);

  const handleValidateUploadDetails = async () => {
    let isValid = true;
    const tempError = JSON.parse(JSON.stringify(formError));
    const tempUploadDetails = JSON.parse(JSON.stringify(uploadDetails));

    if (
      tempUploadDetails.date === null ||
      tempUploadDetails.date.trim() === ""
    ) {
      tempError.date.isError = true;
      tempError.date.errorMessage = "Please select date";
      isValid = false;
    }
    if (
      tempUploadDetails.description === null ||
      tempUploadDetails.description.trim() === ""
    ) {
      tempError.description.isError = true;
      tempError.description.errorMessage = "Please select description";
      isValid = false;
    }
    if (
      tempUploadDetails.amount === null ||
      tempUploadDetails.amount.trim() === ""
    ) {     
      tempError.amount.isError = true;
      tempError.amount.errorMessage = "Please select amount";
      isValid = false;
    } 
    if(!isEmptyNullUndefined(location?.state?.addedDependent)){
      if(coverage?.limitType === "PerPerson"){
        if(Number(tempUploadDetails.amount) < Number(dependantRule?.lowerLimit) || Number(tempUploadDetails.amount) > Number(dependantRule?.upperLimit)){
          tempError.amount.isError = true;
          tempError.amount.errorMessage = `Amount should be between ${dependantRule?.lowerLimit} - ${dependantRule?.upperLimit}`;
          isValid = false;
        }
      } else if(coverage?.limitType === "Cummulative"){
        if(Number(tempUploadDetails.amount) > Number(coverage?.limit)){
          tempError.amount.isError = true;
          tempError.amount.errorMessage = `Amount should be less than ${coverage?.limit}`;
          isValid = false;
        }
      } 
    } else if(location?.state?.selectedSelf) {
      if(coverage?.limitType === "PerPerson"){
        if(Number(tempUploadDetails.amount) < Number(coverage?.lowerLimit) || Number(tempUploadDetails.amount) > Number(coverage?.upperLimit)){
          tempError.amount.isError = true;
          tempError.amount.errorMessage = `Amount should be between ${coverage?.lowerLimit} - ${coverage?.upperLimit}`;
          isValid = false;
        }
      } else if(coverage?.limitType === "Cummulative"){
        if(Number(tempUploadDetails.amount) > Number(coverage?.limit)){
          tempError.amount.isError = true;
          tempError.amount.errorMessage = `Amount should be less than ${coverage?.limit}`;
          isValid = false;
        }
      } 
    }  
    // console.log("tempError",tempError)

    //do Submit
    if (isValid) {
      // if (isEdit) {
      //   updateDependent();
      // } else {
      submitUploadDetails();
      // }
    } else {
      setFormError(tempError);
    }
  };  

  const handleDeleteRecipt = (receipt) => {

    setimgData(imgData?.filter((x)=> x?.filePath?.id != receipt?.filePath?.id));

  }

  const submitUploadDetails = () => {
    APIList.reimburseClaims(uploadDetails)
    .then((res) =>{
      res &&
        toast.success(
       <div className="flex flex-row"><BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }}/>
         &nbsp;&nbsp;Claim raised successfully
       </div>          
       );
       history.push(`/home`)
   }).catch((err) => {
    toast.error(
      <div className="flex flex-row">
        <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
        &nbsp;&nbsp;
        {err?.message}            
      </div>
    )
  })
  };

  // console.log(Object.values(uploadDetails), "Upload Details Form");

  return (
    <div className="uploadDetailsReimbursement m-4">
                  <h4 className="page-heading-navbar">Upload Details</h4>
      <div className="remainingAmountBlock">Remaining Amount - {remainingAmount}</div>
      <div className="mainTitle">Please upload your document</div>
      <div className="formDiv">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            inputFormat={employeeDetails?.company?.dateFormat || "dd/MM/yyyy"}
            value={uploadDetails.date}
            label="Date"
            name="date"
            maxDate={new Date()}
            onChange={(event) => handleChangeUploadDetails(event, "date")}
            renderInput={(params) => (
              <TextField
                fullWidth
                helperText={formError.date.errorMessage}
                size="small"
                error={formError.date.isError}
                {...params}
              />
            )}
          />
        </LocalizationProvider>

        <TextField
          fullWidth
          multiline
          rows={3}
          id="fullWidth"
          size="small"
          name="description"
          label="Description"
          onChange={(event) => handleChangeUploadDetails(event, "textfield")}
          variant="outlined"
          error={formError.description.isError}
          helperText={formError.description.errorMessage}
          value={uploadDetails.description ? uploadDetails.description : ""}
        />

        <TextField
          fullWidth
          id="fullWidth"
          size="small"
          name="amount"
          label="Amount"
          onChange={(event) => handleChangeUploadDetails(event, "amount")}
          variant="outlined"
          error={formError.amount.isError}
          helperText={formError.amount.errorMessage}
          value={uploadDetails.amount ? uploadDetails.amount : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {employeeDetails?.company?.currency}
              </InputAdornment>
            ),
          }}
         
        />
        <div className="updoctitle">Upload document</div>
        <input
          //   accept="image/*"
          //   className={classes.input}
          style={{ display: "none" }}
          id="file"
          name="file"
          onChange={(event) => handleChangeUploadDetails(event, "file")}
          // onChange={selectFiles}
          multiple
          type="file"
        />
        <label className="raised-button-file" htmlFor="file">
          <Button variant="raised" component="span" className="uploadtitle" disabled={uploadingFile}>
            {uploadingFile ? (
              <CircularProgress />
            ) : (
              <div className="flex flex-col items-center">
              <AiOutlineCloudUpload /> Upload
              </div>
            )}
          </Button>
        </label>
       
        {
        (uploadDetails?.receipts?.length > 0) && (
          <div className="receiptImagesBlock">
            {uploadDetails?.receipts.map((reciept, i) => {
              return (
                <div className="receiptImages-outer" key={`imgPreviewCont${i}`}>
                  <RiCloseCircleFill
                    style={{
                      fontSize:"2rem",
                    cursor: "pointer",
                    position: "absolute",
                    top: "0",
                    right: "0",
                  }}
                  onClick={() => {
                    handleDeleteRecipt(reciept)
                  }}
                   />
                  {/* <img
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "-4px",
                      right: "-9px",
                    }}
                    onClick={() => {
                      handleDeleteRecipt(reciept)
                    }}
                    src={removeImgBtn}
                    alt="rmvImgBtn"
                  /> */}
                  <img style={{maxHeight:"9.9rem"}} className="preview" src={reciept?.filePath?.path} alt={"image-" + i} />
                
                </div>
              );
            })}
          </div>
        )}
         
          <ButtonM
            text="UPDATE DETAILS"
            onpress={handleValidateUploadDetails}
          />
        
      </div>
    </div>
  );
};

export default UpdateDetails;
