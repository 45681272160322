import { Link } from "react-router-dom"

const Disability = () => {
    return(
        <>
        <Link to={"/disability/long-term"}>
            <h1 className="m-4 p-4 bg-sky-500/50 md:w-1/3">
            Long Term
            </h1>
        </Link>
        <Link to={"/disability/short-term"}>
            <h1 className="m-4 p-4 bg-pink-500/50 md:w-1/3">
            Short Term
            </h1>
        </Link>
        </>
    )
};

export default Disability;