import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../api/index';
import { actions, types } from './actions';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";

const EnrollRequest = function* EnrollRequest({ payload }) {
    try {
        // console.log('payload', payload.payload);
        const { data } = yield call(api.healthInsuranceEnroll, payload.payload);
        if (data) {
            yield put(actions.EnrollRequestSuccess(data));
            data && toast.success(
                <div className='flex flex-row'>
                    <BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }} />
                    &nbsp;&nbsp;{payload.payload.isEmployeeEnrolled ? 'Enrolled successfully' :
                        'You have choosen to opt out of the plan successfully'}
                </div>);
        } else {
            yield put(actions.EnrollRequestFailed());
        }
    } catch (err) {
        // console.log('voluntry enroll error', err);
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.EnrollRequestFailed());
    }
};

const VolunteryDeleteDependantRequest = function* VolunteryDeleteDependantRequest({ payload }) {
    try {
        const { data } = yield call(api.deleteDependant, payload);
        if (data) {
            yield put(actions.VolunteryDeleteDependantRequestSuccess(payload.depId));
            data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;Dependant deleted</div>);

        } else {
            // toast.error(data.message);
            yield put(actions.VolunteryDeleteDependantRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.VolunteryDeleteDependantRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.VOLUNTRY_ENROLL_REQUEST, EnrollRequest);
    yield takeLatest(types.VOLUNTRY_DELETE_DEPENDANT_REQUEST, VolunteryDeleteDependantRequest);
}
