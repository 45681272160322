import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import Info from "../../../assets/LearningPage/vectorOrange.svg";
import Vector from "../../../assets/LearningPage/Vector.svg";

import "../../../styles/LearningPages/index.scss";
import Block2Image5 from "../../../assets/homepage/timeOff.png";
import { useHistory, useLocation } from "react-router-dom";
import PageHeader from "components/common/PageHeader";
import learnIcon from "../../../assets/homepage/learn.png";
import { actions as EmployeeDataActions } from "../../common/Header/redux/actions";
import { FormattedMessage } from "react-intl";
import { BsCheckAll } from "react-icons/bs";

const NewLearningPages = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [employeeEnrolledArray, setEmployeeEnrolled] = useState([]);
  const state = useSelector((state) => state);
  
  const eligibleplans = useSelector((state) => state && state?.empData?.eligibleplans);
  const elligiblePlanLoader = useSelector((state) => state && state?.empData?.elligiblePlanLoader);

  const employeeDetails = state && state.empData;

  useEffect(() => {
    if(employeeDetails?.empData?.id !== undefined){
    dispatch(EmployeeDataActions.EligiblePlanDataRequest(employeeDetails?.empData?.id));
  }
  }, [employeeDetails?.empData?.id])
  

  useEffect(() => {
    if (!elligiblePlanLoader) {
      setEmployeeEnrolled(eligibleplans);
    }
  }, [eligibleplans]);

  const companyDetails =
    state &&
    state.empData &&
    state.empData.empData &&
    state.empData.empData.company;

  const redirect = (plan) => {

      history.push(
        `/new-learning-page-tabs/${plan?.className}/${plan?.planId}`
      );
   
  };

  return (
    <div className="learningPage">
      <h4 className="page-heading-navbar left">{companyDetails?.displayName}</h4>

      <PageHeader
              heading={""}
              description={` As an eligible employee at${" "}
              ${companyDetails?.displayName}, you can choose from a
              variety of benefit options, some require you to actively
              enroll in. A detailed description of each benefit is found in the tiles listed
              below.`}
              planIcon={learnIcon}
              type="learnPage"
              // btnText={"Click for more details"}
              // pageNumber={"1 of 4"}
              // eIcon={ellipseIcon}
              // btnRedirect={() => history.push(`/learning-page`)}
            />

      {elligiblePlanLoader ? (
        <div className=" grid grid-cols-4 gap-4 px-4 mt-8">
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
        </div>
      ) : (
        <div class="grid grid-cols-3 block1 block2 gap-2">
          {employeeEnrolledArray.map((item) => {
            return (
              <button className="block-div" onClick={() => redirect(item)}>
                <div className="block2-image-div">
                  { 
                    <img src={item?.icon} alt="Image1" className="block-image" />
                  }
                  

                  <img
                    src={
                      item?.enrollmentStatus?.toLowerCase() !== "enrolled"
                        ? Info
                        : Vector
                    }
                    alt="Image1"
                    className="icon-image"
                  />
                </div>

                <p className="block2-text">{item?.displayName}</p>
                {/* <p className="block2-sub_text">No cost to you</p> */}
                <button className="block2-button">
                  {/* {item?.enrollmentStatus?.toLowerCase() !== "yes"
                    ? 
                    <FormattedMessage id="policyDetails.youMustEnroll" defaultMessage="You must enroll"/>
                    :
                    <FormattedMessage id="policyDetails.enrolled" defaultMessage="Enrolled"/>
                  } */}
                  <p className="mr-4">
                      {item?.enrollmentStatus?.toLocaleLowerCase() ===
                    "enrolled" ? ( 
                      <span className="flex justify-center items-center" style={{color:"var(--primary)"}}>
                        {" "}
                        {item?.enrollmentStatus}{" "}
                        <BsCheckAll
                          style={{ fontSize: "1.1rem" }}
                        />{" "}
                      </span>
                    ) : (
                      <span style={{color:"var(--secondary)"}}>{item?.enrollmentStatus}</span>
                    )}{" "} 
                  </p>
                </button>
              </button>
            );
          })}
          <button
            className="block-div"
            onClick={() =>
              history.push(
                `/new-learning-page-tabs/learnLeaves/12345`
              )
            }
          >
            <div className="block2-image-div">
              <img src={Block2Image5} alt="Image1" className="block-image" />

              {/* <img src={Info} alt="Image1" className="icon-image" /> */}
            </div>

            <p className="block2-text">
              {/* Time off & Leave */}
              <FormattedMessage id="policyDetails.timeoff&Leave" defaultMessage="Time off & Leave"/>
              </p>
            {/* <p className="block2-sub_text">No cost to you</p> */}
            <button className="block2-button" style={{color:"var(--primary)", textAlign:"center"}}>
              {/* Automatically Enrolled */}
              {/* <FormattedMessage id="policyDetails.automaticallyEnrolled" defaultMessage="Automatically Enrolled"/> */}
              Eligible
              </button>
          </button>
        </div>
      )}
    </div>
  );
};

export default NewLearningPages;
