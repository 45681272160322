const { createAction } = require('redux-actions');

const VOLUNTRY_ENROLL_REQUEST = 'BENEVOLVE/VOLUNTRY_ENROLL_REQUEST';
const VOLUNTRY_ENROLL_REQUEST_SUCCESS = 'BENEVOLVE/VOLUNTRY_ENROLL_REQUEST_SUCCESS';
const VOLUNTRY_ENROLL_REQUEST_FAILED = 'BENEVOLVE/VOLUNTRY_ENROLL_REQUEST_FAILED';
const SET_SELECTED_CATEGORIES = 'BENEVOLVE/SET_SELECTED_CATEGORIES';
const SET_PROGRESS_ARRAY = 'BENEVOLVE/SET_PROGRESS_ARRAY';
const SET_RATECARD_SELECTION = 'BENEVOLVE/SET_RATECARD_SELECTION';
const SET_CURRENTLY_ENROLLED = 'BENEVOLVE/SET_CURRENTLY_ENROLLED';
const SET_CURRENTLY_INPROCESS_ENROLLED = 'BENEVOLVE/SET_CURRENTLY_INPROCESS_ENROLLED';
const ALL_SELECTED_PLAN_DEPENDANTS = 'BENEVOLVE/ALL_SELECTED_PLAN_DEPENDANTS';
const ALL_SELECTED_COVERAGES = 'BENEVOLVE/ALL_SELECTED_COVERAGES';
const SET_FAMILY_SELECTED_COVERAGES = 'BENEVOLVE/SET_FAMILY_SELECTED_COVERAGES';
const SET_BUNDLE_ENROLL_LIST = 'BENEVOLVE/SET_BUNDLE_ENROLL_LIST';
const SET_INDIVIDUAL_SELECTED_DEPENDANTS_LIST = 'BENEVOLVE/SET_INDIVIDUAL_SELECTED_DEPENDANTS_LIST';

const SET_DEPENDENT_NAME = 'BENEVOLVE/SET_DEPENDENT_NAME'
const SET_PARENT_FLOW = 'BENEVOLVE/SET_PARENT_FLOW'
const SET_MULTIPLE_DEPENDENT = 'BENEVOLVE/SET_MULTIPLE_DEPENDENT'
const SET_ONLY_PARENT_FLOW = 'BENEVOLVE/SET_ONLY_PARENT_FLOW'
const SET_GROUP_SELECTION = 'BENEVOLVE/SET_GROUP_SELECTION'
const SET_INDIVIDUAL_SELECTION = 'BENEVOLVE/SET_INDIVIDUAL_SELECTION'
const VOLUNTRY_DELETE_DEPENDANT_REQUEST = 'BENEVOLVE/VOLUNTRY_DELETE_DEPENDANT_REQUEST';
const VOLUNTRY_DELETE_DEPENDANT_REQUEST_SUCCESS = 'BENEVOLVE/VOLUNTRY_DELETE_DEPENDANT_REQUEST_SUCCESS';
const VOLUNTRY_DELETE_DEPENDANT_REQUEST_FAILED = 'BENEVOLVE/VOLUNTRY_DELETE_DEPENDANT_REQUEST_FAILED';
const IS_EDIT_DEPENDENT = 'BENEVOLVE/IS_EDIT_DEPENDENT'
const EDIT_FROM = 'BENEVOLVE/EDIT_FROM'
const DEPENDENT_EDIT_DETAILS = 'BENEVOLVE/DEPENDENT_EDIT_DETAILS'
const VIEWED_PREMIUM_RATES = 'BENEVOLVE/VIEWED_PREMIUM_RATES'

const EnrollRequest = createAction(VOLUNTRY_ENROLL_REQUEST);
const EnrollRequestSuccess = createAction(VOLUNTRY_ENROLL_REQUEST_SUCCESS);
const EnrollRequestFailed = createAction(VOLUNTRY_ENROLL_REQUEST_FAILED);
const SetSelectedCategories = createAction(SET_SELECTED_CATEGORIES);
const SetProgressArray = createAction(SET_PROGRESS_ARRAY);
const SetRatecardSelection = createAction(SET_RATECARD_SELECTION);
const SetCurrentlyEnrolled = createAction(SET_CURRENTLY_ENROLLED);
const SetCurrentlyInprocessEnrolled = createAction(SET_CURRENTLY_INPROCESS_ENROLLED);
const setSelectedPlanDependants = createAction(ALL_SELECTED_PLAN_DEPENDANTS);
const setSelectedCoverages = createAction(ALL_SELECTED_COVERAGES);
const setFamilySelectedCoverages = createAction(SET_FAMILY_SELECTED_COVERAGES);
const setBundleEnrollList = createAction(SET_BUNDLE_ENROLL_LIST);
const setIndividualSelectedDependant = createAction(SET_INDIVIDUAL_SELECTED_DEPENDANTS_LIST);

const SetDependentName = createAction(SET_DEPENDENT_NAME)
const SetParentFlow = createAction(SET_PARENT_FLOW)
const SetMultipleDependent = createAction(SET_MULTIPLE_DEPENDENT)
const SetOnlyParentFlow = createAction(SET_ONLY_PARENT_FLOW)
const SetGroupSelection = createAction(SET_GROUP_SELECTION)
const SetIndividualSelection = createAction(SET_INDIVIDUAL_SELECTION)
const VolunteryDeleteDependantRequest = createAction(VOLUNTRY_DELETE_DEPENDANT_REQUEST);
const VolunteryDeleteDependantRequestSuccess = createAction(VOLUNTRY_DELETE_DEPENDANT_REQUEST_SUCCESS);
const VolunteryDeleteDependantRequestFailed = createAction(VOLUNTRY_DELETE_DEPENDANT_REQUEST_FAILED);
const IsEditDependent = createAction(IS_EDIT_DEPENDENT)
const EditFrom = createAction(EDIT_FROM)
const EditDependentDetails = createAction(DEPENDENT_EDIT_DETAILS)
const ViewedPremiumRates = createAction(VIEWED_PREMIUM_RATES)

export const actions = {
    EnrollRequest,
    EnrollRequestSuccess,
    EnrollRequestFailed,
    SetSelectedCategories,
    SetProgressArray,
    SetRatecardSelection,
    SetCurrentlyEnrolled,
    SetCurrentlyInprocessEnrolled,
    setSelectedPlanDependants,
    setSelectedCoverages,
    setFamilySelectedCoverages,
    setBundleEnrollList,
    setIndividualSelectedDependant,

   
    SetDependentName,
    SetParentFlow,
    SetMultipleDependent,
    SetOnlyParentFlow,
    SetGroupSelection,
    SetIndividualSelection,
    VolunteryDeleteDependantRequest,
    VolunteryDeleteDependantRequestSuccess,
    VolunteryDeleteDependantRequestFailed,
    IsEditDependent,
    EditFrom,
    EditDependentDetails,
    ViewedPremiumRates
};

export const types = {
    VOLUNTRY_ENROLL_REQUEST,
    VOLUNTRY_ENROLL_REQUEST_SUCCESS,
    VOLUNTRY_ENROLL_REQUEST_FAILED,
    SET_SELECTED_CATEGORIES,
    SET_PROGRESS_ARRAY,
    SET_RATECARD_SELECTION,
    SET_CURRENTLY_ENROLLED,
    SET_CURRENTLY_INPROCESS_ENROLLED,
    ALL_SELECTED_PLAN_DEPENDANTS,
    ALL_SELECTED_COVERAGES,
    SET_FAMILY_SELECTED_COVERAGES,
    SET_BUNDLE_ENROLL_LIST,
    SET_INDIVIDUAL_SELECTED_DEPENDANTS_LIST,

    SET_DEPENDENT_NAME,
    SET_PARENT_FLOW,
    SET_MULTIPLE_DEPENDENT,
    SET_ONLY_PARENT_FLOW,
    SET_GROUP_SELECTION,
    SET_INDIVIDUAL_SELECTION,
    VOLUNTRY_DELETE_DEPENDANT_REQUEST,
    VOLUNTRY_DELETE_DEPENDANT_REQUEST_SUCCESS,
    VOLUNTRY_DELETE_DEPENDANT_REQUEST_FAILED,
    IS_EDIT_DEPENDENT,
    EDIT_FROM,
    DEPENDENT_EDIT_DETAILS,
    VIEWED_PREMIUM_RATES
};