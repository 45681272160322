import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.ADD_DEPENDANT_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.ADD_DEPENDANT_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        addDependant: payload || {},
    }),
    [types.ADD_DEPENDANT_REQUEST_FAILED]: (state, { payload }) => ({
        ...state,
        loader: false,
        addDependant : {error:payload}
    }),

    [types.GET_DEPENDENTS_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.GET_DEPENDENTS_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        allDependents: payload || [],
    }),
    [types.GET_DEPENDENTS_REQUEST_FAILED]: (state, { payload }) => ({
        ...state,
        loader: false,
        allDependents : {error:payload}
    }),

    [types.TEMP_SAVE_DEPENDANT_INFO]: (state, { payload }) => ({
        ...state,
        loader: false,
        tempDependantInfo : payload || null,
    }),
    [types.TEMP_SAVE_DEPENDANT_ADDRESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        tempDependantAddress : payload || null,
    }),
};

export default handleActions(actionHandler, {
    loader: false,
    addDependant:{},
    tempDependantInfo:null,
    tempDependantAddress:null,
    allDependents: []
});
