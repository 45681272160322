import { toast } from 'react-toastify';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import api from "../../../../../api/index"
import { actions, types } from './action';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { actions as logInActions } from "../../../../pages/Login/redux/actions";

const getNotificationCountRequest = function* getNotificationCountRequest({ payload }) {    

    if (payload === "clearData") {
        yield put(actions.GetNotificationRequestCountSuccess({}));
    } else {
        let count = "0";
        while(payload.loggedIn){            
            try {                
                const { data } = yield call(api.getNotificationCount, payload.id);

                if(data.toString() != count){
                    yield put(actions.GetNotificationRequestCountSuccess(data.toString()));
                    count = data.toString();
                }
                
                yield delay(60000);

            } catch (err) {
                toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
                
                // yield put(logInActions.clearLogInToken());
                // window.location.reload();
                // yield put(actions.GetNotificationRequestCountSuccess({}));                
                // yield put(actions.GetNotificationRequestCountFailed(err));

            }
        }
    }

};

const GetNotificationRequest = function* GetNotificationRequest({ payload }) {
    try {
        
        const { data } = yield call(api.getNotification, payload);
        if (data) {
            yield put(actions.GetNotificatiionrequestSuccess(data));
            //   data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.GetNotificatiionrequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.GetNotificatiionrequestFailed());
    }
};




export default function* sagas() {
    yield takeLatest(types.GET_NOTIFICATION_COUNT, getNotificationCountRequest);
    yield takeLatest(types.GET_NOTIFICATION_REQUEST, GetNotificationRequest);

}
