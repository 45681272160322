import APIList from "api";
import React, { useState } from "react";
import { useEffect } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FcUpload, FcCheckmark } from "react-icons/fc";
import { AiOutlineRight } from "react-icons/ai";
import { FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TabsComponent from "./Tabs";
import { CircularProgress, IconButton } from "@mui/material";
import { RiErrorWarningFill } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";
import "../../../styles/Documents/documents.scss";
import { useRef } from "react";
import { isEmptyNullUndefined } from "components/utils/utils";
import { FormattedMessage } from "react-intl";

const MyDocuments = () => {
  const state = useSelector((state) => state);
  const employeeDetails = state && state.empData;
  const employeeId = employeeDetails.empData.id;
  const [loading, setLoading] = useState(false);
  const [planName, setPlanName] = useState([]);
  const [planActiveKey, setPlanActiveKey] = useState(null);
  const [providerDocumentPolicy, setProviderDocumentPolicy] = useState([]);
  const [activePlanIndex, setActivePlanIndex] = useState(null);
  const [isEnrolledInPlans, setIsEnrolledinPlans] = useState(false);
  const [isInitialValueSet, setIsInitialValueSet] = useState(false);
  const [active, setActive] = useState("");
  const [noDocActive, setNoDocActive] = useState("");
  const [height, setHeight] = useState("0px");
  const [noDocHeight, setNoDocHeight] = useState("0px");
  const [rotate, setRotate] = useState("accordion__icon");
  const doc_tbl_ref = useRef(null);
  const noDocsToUpload = useRef(null);
  const { loader: eligiblePlanLoading, eligibleplans } = useSelector(
    ({ planReducer }) => planReducer
  );

  useEffect(() => {
    if (!isEmptyNullUndefined(employeeDetails?.empData?.id)) {
      getEmployeeEnrollments();
    }
  }, [employeeDetails?.empData?.id]);

  const getClassName = (tab, index) => {
    if (tab.displayName === planActiveKey) {
      if (index !== 0 && index !== planName.length - 1) {
        return "sideBarActive w-full ";
      } else {
        return "sideBarActive w-full";
      }
    } else {
      if (index !== 0 && index !== planName.length - 1) {
        return "sideBarInActive w-full ";
      } else {
        return "sideBarInActive w-full";
      }
    }
  };
  const getEmployeeEnrollments = () => {
    APIList.getEligiblePlans(employeeDetails?.empData?.id)
      .then((res) => {
        setPlanName(res.data);
        if (!isInitialValueSet && res.data.length > 0) {
          setActivePlanIndex(0);
          setPlanActiveKey(res.data[0].displayName);
          getClassName(res.data[0], 0);
          handleTabClick(res.data[0]);
          setIsInitialValueSet(true);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      });
  };

  const getEnrolledPlansDetails = (eId) => {
    setLoading(true);
    APIList.getEnrolledPlanDetails({
      enrollmentId: eId,
    })
      .then((res) => {
        setProviderDocumentPolicy(res.data.enrollmentDocument);
        setLoading(false);
        opnAccrdnFunc(res.data.enrollmentDocument);
      })
      .catch((e) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {e ? e.message : "Something went wrong Please try again"}
          </div>
        );
        setLoading(false);
      });
  };

  const handleCaptureFile = (event, index) => {
    const file =
      event.target.files.length > 0 ? event.target.files[0] : undefined;
    if (file != undefined) {
      setTimeout(() => {
        UploadFile(file, index);
        const inputElem = document.getElementById("inputFile_" + index);
        if (inputElem != undefined) inputElem.value = null;
      }, 200);
    }
  };

  const UploadFile = async (file, index) => {
    const formData = new FormData();
    formData.append("file", file);
    APIList.fileDocUpload(formData, {
      headers: { ContentType: "multipart/form-data" },
    })
      .then((res) => {
        submitDocumentPolicyFile(
          res?.data?.id,
          providerDocumentPolicy[index]?.id
        );
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      });
  };

  const submitDocumentPolicyFile = async (fileId, enrollmentDocumentId) => {
    APIList.enrollmentDocumentUpload({
      fileId: fileId,
      enrollmentDocumentId: enrollmentDocumentId,
    })
      .then((res) => {
        res &&
          toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;Document
              {` Uploaded successfully`}
            </div>
          );
        getEnrolledPlansDetails(planName[activePlanIndex].enrollment);
      })
      .catch((err) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {err ? err.message : "Something went wrong Please try again"}
          </div>
        );
      });
  };

  const handleTabClick = (tab) => {
    if (tab.enrollmentStatus == "Enrolled") {
      if (tab.displayName !== planActiveKey) {
        setIsEnrolledinPlans(true);
        getEnrolledPlansDetails(tab.enrollment);
      } else {
        accrdnFunc(providerDocumentPolicy);
      }
    } else {
      setIsEnrolledinPlans(false);
    }
  };

  const opnAccrdnFunc = (pdp) => {
    if (pdp.length > 0) {
      setActive(active === "" ? "active" : "");
      setHeight(`${doc_tbl_ref.current.scrollHeight}px`);
      setRotate("accordion__icon rotate");
      setActive("");
    } else {
      setNoDocActive(noDocActive === "" ? "active" : "");
      setNoDocHeight(`${noDocsToUpload.current.scrollHeight}px`);
      setRotate("accordion__icon rotate");
      setNoDocActive("");
    }
  };

  const accrdnFunc = (pdp) => {
    if (pdp.length > 0) {
      setActive(active === "" ? "active" : "");
      setHeight(
        active === "active" ? `${doc_tbl_ref.current.scrollHeight}px` : "0px"
      );
      setRotate(active === "" ? "accordion__icon" : "accordion__icon rotate");
    } else {
      setNoDocActive(noDocActive === "" ? "active" : "");
      setNoDocHeight(
        noDocActive === "active"
          ? `${noDocsToUpload.current.scrollHeight}px`
          : "0px"
      );
      setRotate(
        noDocActive === "" ? "accordion__icon" : "accordion__icon rotate"
      );
    }
  };

  function getFilenameFromUrl(url) {
    const pathname = new URL(url).pathname;
    const index = pathname.lastIndexOf("/");
    return pathname.substring(index + 1); // if index === -1 then index+1 will be 0
  }
  const downloadFile = (url) => {
    const fileExtension = url.split(/[#?]/)[0].split(".").pop().trim();

    if (fileExtension === ("xlsx" || "xls" || "odt" || "docx" || "doc")) {
      createAnchorAndDownload(url);
    } else if (fileExtension === "png" || "jpg" || "jpeg" || "pdf") {
      getDocFileFromURI(url);
    } else {
      createAnchorAndDownload(url);
    }
  };
  const createAnchorAndDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(`download`, `${getFilenameFromUrl(url)}`);
    document.body.appendChild(link);
    link.target = "_blank";
    link.click();
    document.body.removeChild(link);
  };

  const getDocFileFromURI = (uri) => {
    setLoading(true);
    APIList.getFileFromURI({
      filepath: uri,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: `image/${uri.split(/[#?]/)[0].split(".").pop().trim()}`,
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${getFilenameFromUrl(uri)}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {e ? e.message : "Something went wrong Please try again"}
          </div>
        );
      });
  };

  return (
    <div className="learningPage beneficiaries">
      <div className="BMheader w-full px-2">
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className="pageheaderdata">
              <div className="pageheaderDataInneer w-4/5">
                <h3 className="title" style={{ color: "var(--primary)" }}>
                  {/* My Documents */}
                  <FormattedMessage id="myDocuments.pageTitle" defaultMessage="My Documents"/>
                </h3>
                <p className="desc">
                  {/* Manage your documents here. */}
                  <FormattedMessage id="myDocuments.manageYourDocuments" defaultMessage="Manage your documents here."/>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="myDocuments">
        <div>
          <div className="myDocuments">
            <div className="Side-bar flex flex-col place-items-center text-center w-full h-full text-blue-gray-900 font-normal leading-relaxed select-none cursor-pointer">
              {planName.length > 0 &&
                planName.map(
                  (tab, index) =>
                    tab.enrollmentStatus == "Enrolled" && (
                      <>
                        <div
                          key={`${index}_plans}`}
                          className="w-full"
                          style={{ marginBottom: "0.25rem" }}
                        >
                          <div
                            key={`tab_${index}`}
                            onClick={() => {
                              setProviderDocumentPolicy([]);
                              setPlanActiveKey(tab.displayName);
                              setActivePlanIndex(index);
                              handleTabClick(tab);
                            }}
                            className={getClassName(tab, index)}
                          >
                            <button
                              className="list-menu"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              {tab.displayName}
                              <AiOutlineRight
                                className={
                                  index === activePlanIndex ? `${rotate}` : ``
                                }
                              />
                            </button>
                          </div>
                        </div>
                        {index === activePlanIndex &&
                        providerDocumentPolicy.length > 0 ? (
                          <div
                            ref={doc_tbl_ref}
                            style={{
                              maxHeight: `${height}`,
                              marginTop: "-0.1rem",
                            }}
                            className="w-full accordion__content"
                          >
                            {providerDocumentPolicy.length > 0 ? (
                              <div
                                className={`my_doc_tabl w-full overflow-auto ${active}`}
                                style={{
                                  visibility:
                                    height === "0px" ? "hidden" : "visible",
                                }}
                              >
                                <table
                                  style={{
                                    tableLayout: "auto",
                                    width: "100%",
                                    textAlign: "left",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <thead>
                                    <tr
                                      style={{
                                        backgroundColor: "#b4b4b4",
                                        color: "#000",
                                        fontSize: "14.4px",
                                      }}
                                    >
                                      {/* <th>Name</th> */}
                                      <th><FormattedMessage id="myDocuments.name" defaultMessage="Name"/></th>
                                      {/* <th>Relation</th> */}
                                      <th><FormattedMessage id="myDocuments.relation" defaultMessage="Relation"/></th>
                                      {/* <th>Document</th> */}
                                      <th><FormattedMessage id="myDocuments.document" defaultMessage="Document"/></th>
                                      {/* <th>Format</th> */}
                                      <th><FormattedMessage id="myDocuments.format" defaultMessage="Format"/></th>
                                      {/* <th>Uploaded</th> */}
                                      <th><FormattedMessage id="myDocuments.uploaded" defaultMessage="Uploaded"/></th>
                                      {/* <th>Action</th> */}
                                      <th><FormattedMessage id="myDocuments.action" defaultMessage="Action"/></th>
                                      {/* <th>Status</th> */}
                                      <th><FormattedMessage id="myDocuments.status" defaultMessage="Status"/></th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    style={{
                                      fontSize: "13.5px",
                                    }}
                                  >
                                    {providerDocumentPolicy.length > 0 &&
                                      providerDocumentPolicy.map(
                                        (item, index) => {
                                          return (
                                            <>
                                              <tr key={`${index}row}`}>
                                                <td>
                                                  {
                                                    (item.middleName = ""
                                                      ? `${item.firstName} ${item.lastName}`
                                                      : `${item.firstName} ${item.lastName}`)
                                                  }
                                                </td>
                                                <td>{item.relation}</td>
                                                <td>
                                                  {item.providerDocumentPolicy}
                                                </td>
                                                <td>
                                                  {item?.format && (
                                                    <FaCloudDownloadAlt
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        downloadFile(
                                                          item.format
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                </td>
                                                <td>
                                                  <span>
                                                    {item.isUploaded ? (
                                                      <FcCheckmark
                                                        style={{
                                                          margin: "0 auto",
                                                        }}
                                                      />
                                                    ) : (
                                                      <FiX
                                                        style={{
                                                          margin: "0 auto",
                                                        }}
                                                      />
                                                    )}
                                                  </span>
                                                </td>
                                                <td>
                                                  {item.isUploaded ? (
                                                    <a
                                                      target="_blank"
                                                      href={`${item?.fileDetails?.path}`}
                                                      rel="noopener noreferrer"
                                                      style={{
                                                        color: "#009688",
                                                      }}
                                                    >
                                                      View
                                                    </a>
                                                  ) : (
                                                    <IconButton
                                                      color="primary"
                                                      aria-label="upload document"
                                                      component="label"
                                                      style={{
                                                        width: "100%",
                                                      }}
                                                    >
                                                      <input
                                                        id={
                                                          "inputFile_" + index
                                                        }
                                                        onChange={(event) =>
                                                          handleCaptureFile(
                                                            event,
                                                            index
                                                          )
                                                        }
                                                        hidden
                                                        type="file"
                                                      />
                                                      <FcUpload />
                                                    </IconButton>
                                                  )}
                                                </td>
                                                <td>
                                                  {item?.status}
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          index === activePlanIndex && (
                            <div
                              style={{
                                maxHeight: noDocHeight,
                              }}
                              ref={noDocsToUpload}
                              className={
                                noDocHeight === "0px"
                                  ? `w-full text-sm accordion__content ${noDocActive}`
                                  : `w-full py-20 px-15 text-sm accordion__content ${noDocActive}`
                              }
                            >
                              {loading ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                <h1
                                  className="noDoc_txt"
                                  style={{
                                    maxHeight: noDocHeight,
                                    visibility:
                                      noDocHeight === "0px"
                                        ? "hidden"
                                        : "visible",
                                  }}
                                >
                                  {/* You don't have any documents to Upload for{" "}
                                  {planActiveKey} Plan */}
                                  <FormattedMessage id="myDocuments.youDontHaveAnyDocument" defaultMessage="You don't have any documents to Upload for"/>{" "}
                                  {planActiveKey} <FormattedMessage id="myDocuments.plan" defaultMessage="Plan"/>
                                </h1>
                              )}
                            </div>
                          )
                        )}
                      </>
                    )
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDocuments;
