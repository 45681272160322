import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import APIList from 'api';
import { toast } from 'react-toastify';
import { RiErrorWarningFill } from 'react-icons/ri';
import CalenderDialog from './component/CalenderDialog';
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, ListItemIcon, ListItemText, MenuItem, Radio, RadioGroup, Select, Stack, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { getIdListFromObjects, isEmptyNullUndefined, week } from 'components/utils/utils';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BsFillCheckCircleFill } from 'react-icons/bs';

moment.locale('en-GB');
const localizer = momentLocalizer(moment);

const requiredStyled = {
    backgroundColor: 'white',
    mb: 2,
    width: '100%',
    // width: {xs: '100%', xl: '60%', md: '75%'},
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderLeftColor: 'red',
        borderLeftWidth: 3,
      },
    },
  };

const Calender = () => {

  const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const company = useSelector((state) => state?.empData?.empData?.company);  
      
  const [openDialog, setOpenDialog] = useState(false);
  const [empEvents, setEmpEvents] = useState([]);
  const [companyEvents, setCompanyEvents] = useState([]);
  const [deleteEvent, setdeleteEvent] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isNewEvent, setIsNewEvent] = useState(false);
  const [eventDeleteLoader, setEventDeleteLoader] = useState(false);
  const [addEventLoader, setAddEventLoader] = useState(false);
  const [eventsData, setEventsData] = useState([]);  

  let initialEmptyEvent = {
    title: null,
    allDay: true,
    startDate: null,
    endDate: null,
    url: null,
    isRepeat: false,
    color: null,
    description: null,
    dayOfWeek: [],
    company: {
      id: company && company.id,
    },
    employee : {
        id : employeeDetails?.id,
    }
  };
  let initialEmptyEventError = {
    title: {isError: false, errorMessage: ''},
    allDay: true,
    startDate: {isError: false, errorMessage: ''},
    endDate: {isError: false, errorMessage: ''},   
    url: {isError: false, errorMessage: ''},
    isRepeat: {isError: false, errorMessage: ''},
    color: {isError: false, errorMessage: ''},
    description: {isError: false, errorMessage: ''},
    dayOfWeek: {isError: false, errorMessage: ''},
  };
  const [selectedEvent, setSelectedEvent] = useState(initialEmptyEvent);
  const [eventError, setEventError] = useState(initialEmptyEventError);

  useEffect(() => {

    getCompanyEvents(company?.id);
    getEmployeeEvents(company?.id);

  }, []);

  useEffect(() => {

    setEventsData([...companyEvents, ...empEvents]);

  }, [companyEvents, empEvents]);
  
  const getCompanyEvents = async (companyId) => {

    APIList.getCompanyEvents({companyId:companyId})

    .then((res) => {

        setCompanyEvents(res.data);

    })
    .catch((err) => {

        toast.error(
            <div className="flex flex-row">
              <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
              &nbsp;&nbsp;
              {err?.message}            
            </div>
          );

    })

  };

  const getEmployeeEvents = async (companyId) => {
    APIList.getEmployeeEvents({empId:employeeDetails?.id})
    .then((res) => {
        setEmpEvents(res.data);
    })
    .catch((err) => {
        toast.error(
            <div className="flex flex-row">
              <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
              &nbsp;&nbsp;
              {err?.message}            
            </div>
          );
    })
  };

  const handleClickEvent = (event) => {

    console.log("event", event);

    setIsNewEvent(() => false);
    setSelectedEvent(event);
    setOpenDialog(true);

  };

  const handleValidateEvent = async () => {
    let isValid = true;

    // const tempError = JSON.parse(JSON.stringify(eventError));
    const tempError ={...eventError};
    const tempEvent = JSON.parse(JSON.stringify(selectedEvent));

    if (tempEvent.title == null || tempEvent.title.trim() == '') {
      tempError.title.isError = true;
      // tempError.title.errorMessage = 'Please enter Event title';
      tempError.title.errorMessage = <FormattedMessage id='error.calender.pleaseEnterEventTitle' defaultMessage='Please enter Event title'/>;
      isValid = false;
    }

    if (tempEvent.startDate == null || tempEvent.startDate.trim() == '') {
      tempError.startDate.isError = true;
      // tempError.startDate.errorMessage = 'Please enter Start Date';
      tempError.startDate.errorMessage = <FormattedMessage id='error.calender.pleaseEnterStartDate' defaultMessage='Please enter Start Date'/>;
      isValid = false;
    }

    if (tempEvent.endDate == null || tempEvent.endDate.trim() == '') {
      tempError.endDate.isError = true;
      // tempError.endDate.errorMessage = 'Please enter End Date';
      tempError.endDate.errorMessage = <FormattedMessage id='error.calender.pleaseEnterEndDate' defaultMessage='Please enter End Date'/>;
      isValid = false;
    }

    if (tempEvent.description == null || tempEvent.description.trim() == '') {      
      tempError.description.isError = true;
      // tempError.description.errorMessage = 'Please enter Event Description';
      tempError.description.errorMessage = <FormattedMessage id='error.calender.pleaseEnterEventDescription' defaultMessage='Please enter Event Description'/>;
      isValid = false;
    }

    if(tempEvent.description?.length > 250){
      tempError.description.isError = true;
      // tempError.description.errorMessage = 'Maxium 250 characters allowed in Description ';
      tempError.description.errorMessage = <FormattedMessage id='error.calender.maxiumCharactersAllowedDescription' defaultMessage='Maxium 250 characters allowed in Description'/>;
      isValid = false;
    }

    if (tempEvent.url == null || tempEvent.url.trim() == '') {
      tempError.url.isError = true;
      // tempError.url.errorMessage = 'Please enter URL';
      tempError.url.errorMessage = <FormattedMessage id='error.calender.pleaseEnterUrl' defaultMessage='Please enter URL'/>;
      isValid = false;
    }

    if((tempEvent.isRepeat == 'true') || (tempEvent.isRepeat == true)) {
      if(isEmptyNullUndefined(tempEvent.dayOfWeek)) {
        tempError.dayOfWeek.isError = true;
        // tempError.dayOfWeek.errorMessage = 'Please enter Repeat Date';
        tempError.dayOfWeek.errorMessage =  <FormattedMessage id='error.calender.pleaseEnterRepeatDate' defaultMessage='Please enter Repeat Date'/>;
        isValid = false;
      }
    }

    if (!isEmptyNullUndefined(tempEvent.url)) {
      if (
        !tempEvent.url.match(
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
        )
      ) {
        tempError.url.isError = true;
        // tempError.url.errorMessage = 'Please enter valid URL';
        tempError.url.errorMessage = <FormattedMessage id='error.calender.pleaseEnterValidUrl' defaultMessage='Please enter valid URL'/>;
        isValid = false;
      }
    }

    //do Submit
    if (isValid) {

      if (isEdit) {
        updateCompanyEvents();
      } else {
        addCompanyEvents();
      }

    } else {
      setEventError(tempError);
    }

  };

  const updateCompanyEvents = () => {

    setAddEventLoader(true);

    let payload = {...selectedEvent};
    Object.keys(payload.company).forEach(function (itm) {
      if (itm != 'id') delete payload.company[itm];
    });

    payload["employee"] = {id:employeeDetails?.id}

    APIList.updateCompanyEvents({id:selectedEvent?.id, payload:payload})
    .then((res) => {
        getEmployeeEvents()
        toast.success(
            <div className='flex flex-row'>
            <BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />
            &nbsp;&nbsp;Event updated successfully!
            </div>
        );
        handleClosePopup();
        setAddEventLoader(false);
    })
    .catch((err) => {
        toast.error(
            <div className='flex flex-row'>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
            </div>
          ); 
          setAddEventLoader(false);
    })

  };

  const handleClosePopup = (deleteAction) => {

    setIsNewEvent(false);
    setIsEdit(false);
    setOpenDialog(false);
    setEventError(initialEmptyEventError);

    if(!isEmptyNullUndefined(deleteAction)){
      !(deleteAction === "yes") && setSelectedEvent(initialEmptyEvent);
    } else {
      setSelectedEvent(initialEmptyEvent);
    }    

  };  

  const handleDeleteEvent = () => {

    setEventDeleteLoader(true);

    APIList.deleteCompanyEvents({id:selectedEvent?.id})
    .then((res) => {
      getEmployeeEvents()
        toast.success(
            <div className='flex flex-row'>
            <BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />
            &nbsp;&nbsp;Event deleted successfully!
            </div>
        );
        setEventDeleteLoader(false);
        setdeleteEvent(false);
        handleClosePopup();
        getEmployeeEvents();
    })
    .catch((err) => {
      toast.error(
        <div className='flex flex-row'>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{err?.title}
        </div>
      ); 
      setEventDeleteLoader(false);
    })

  }
  
  const addCompanyEvents = () => {

    setAddEventLoader(true);

    APIList.addCompanyEvents(selectedEvent)
    .then((res) => {
        getEmployeeEvents()
        toast.success(
            <div className='flex flex-row'>
            <BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />
            &nbsp;&nbsp;Event added successfully!
            </div>
        );
        handleClosePopup();
        setAddEventLoader(false);
    })
    .catch((err) => {
        toast.error(
            <div className='flex flex-row'>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
            </div>
          ); 
          setAddEventLoader(false);
    })

  };

  const handleChangeEventDetails = async (event, fieldType) => {

    let tempSelectedEvent = JSON.parse(JSON.stringify(selectedEvent));
    // const tempError = JSON.parse(JSON.stringify(eventError));
    const tempError ={...eventError};

    if (fieldType == 'textfield') {
      tempSelectedEvent[event.target.name] = event.target.value;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = '';
    }

    if (fieldType == 'ratio') {
      tempSelectedEvent[event.target.name] = event.target.value === 'true' ? true : false;
      // tempSelectedEvent[event.target.name] = event.target.value;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = '';
    }

    if(fieldType == 'dayOfWeek') {
      let value = event.target.value;
      let preventDuplicate = value.filter(
        (v, i, a) => a.findIndex((t) => t === v) === i,
      );
      if (value[value?.length - 1] === 'All') {
        console.log('is If Condition working')
        preventDuplicate =
          preventDuplicate?.length - 1 === week?.length
            ? []
            : getIdListFromObjects(week, 'value');
      }
      tempSelectedEvent.dayOfWeek = preventDuplicate;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = '';
    }

    if (fieldType == 'startDate' || fieldType == 'endDate') {
      tempSelectedEvent[fieldType] = event;
      tempError[fieldType].isError = false;
      tempError[fieldType].errorMessage = '';

      if (
        tempSelectedEvent.endDate != null &&
        tempSelectedEvent.startDate != null
      ) {
        //End Date should not be less than Start Date
        if (
          Date.parse(tempSelectedEvent.endDate) <
          Date.parse(tempSelectedEvent.startDate)
        ) {
          tempError.endDate.isError = true;
          // tempError.endDate.errorMessage =
          //   'End Date should be greater than Start Date';
          tempError.endDate.errorMessage =
            <FormattedMessage id='error.calender.endDateShouldGreaterThanStartDate' defaultValue='End Date should be greater than Start Date'/>;
        } else {
          tempError.endDate.isError = false;
          tempError.endDate.errorMessage = '';
        }
      } else {
        tempError.endDate.isError = false;
        tempError.endDate.errorMessage = '';
        tempError.startDate.isError = false;
        tempError.startDate.errorMessage = '';
      }
    }

    if(event?.target?.name == 'isRepeat') {
      if((event.target.value == false) || (event.target.value == 'false')) {
        tempSelectedEvent.dayOfWeek = [];
        tempError.dayOfWeek.isError = true;
        tempError.dayOfWeek.errorMessage = '';
      }
    }
    setSelectedEvent(tempSelectedEvent);
    setEventError(tempError);
    
  }; 

    return(
        <div className="calender-main-page">              
          <h4 className="page-heading-navbar"> 
          {/* Calendar  */}
          <FormattedMessage id='calender.CalendarHeading' defaultMessage="Calendar"/>
          </h4>   
            <Calendar
            views={['month', 'week', 'day', 'agenda']}
            // selectable
            localizer={localizer}
            defaultDate={new Date()}
            defaultView='month'
            events={eventsData}
            components={{
              eventWrapper: ({event, children}) => (
                <div
                  onContextMenu={(e) => {
                    alert(`${event.title} is clicked.`);
                    e.preventDefault();
                  }}
                >
                  {children}
                </div>
              ),
            }}
            startAccessor={(event) => moment(event.startDate).toDate()}
            endAccessor={(event) => moment(event.endDate).add(10, 'm').toDate()}
            popup={true}
            tooltipAccessor={(e) => e.description}
            // showAllEvents
            dayLayoutAlgorithm='no-overlap'
            eventPropGetter={(event) => {
              const backgroundColor = event.color ? event.color : '#0A8FDC';
              // const color = event.color ? event.color : '#FFF';
              return {style: {backgroundColor}};
            }}
            // style={{height: '50vh'}}
            style={{height: '100%'}}
            onSelectEvent={handleClickEvent}
            // onSelectSlot={handleSelect}
          />

          <div className='add-event-btn'>
             <Button 
             variant="outlined"
             onClick={() => {setOpenDialog(() => true); setIsNewEvent(() => true)}}
             >
                {/* Add Event */}
                <FormattedMessage id='calender.addEvent' defaultMessage="Add Event"/>
            </Button>
          </div>

          <div>
            {
                openDialog && 
                isNewEvent ?
                <CalenderDialog 
                openDialog={openDialog} 
                setOpenDialog={setOpenDialog} 
                initialEmptyEvent={initialEmptyEvent} 
                setSelectedEvent={setSelectedEvent} 
                setIsNewEvent={setIsNewEvent}
                setIsEdit={setIsEdit}
                setEventError={setEventError}
                initialEmptyEventError={initialEmptyEventError}
                >

                  <p style={{fontFamily:"poppins", fontSize:"0.9rem", fontWeight:"600"}}>
                    {/* Add New Event */}
                    <FormattedMessage id='calender.addNewEvent' defaultMessage="Add New Event"/>
                  </p>

                    <Stack
                  sx={{mt: 2}}
                  display='flex'
                  justifyContent='center'
                  spacing={2}
                >

                  <TextField
                    size='small'
                    id='eventTitleTextField'
                    name='title'
                    label={<FormattedMessage id='calender.eventTitle' defaultMessage="Event Title"/>}
                    variant='outlined'
                    InputProps={{inputProps: {min: 0}}}
                    placeholder='Event Title'
                    sx={{
                      ...requiredStyled,
                    }}
                    onChange={(e) => {
                      handleChangeEventDetails(e, 'textfield');
                    }}
                    value={
                      !isEmptyNullUndefined(selectedEvent.title)
                        ? selectedEvent.title
                        : ''
                    }
                    error={eventError.title.isError}
                    helperText={eventError.title.errorMessage}
                  />
                  <Stack
                    id='event-start-date-picker'
                    name='startDate'
                    sx={{
                      width: '100%',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat={dateFormat}
                        value={selectedEvent.startDate}
                        label={<FormattedMessage id='calender.startDateOfTheEvent' defaultMessage="Start Date Of Event"/>}
                        name='startDate'
                        onChange={(e) => {
                          handleChangeEventDetails(e, 'startDate');
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant='outlined'
                            helperText={eventError.startDate.errorMessage}
                            size='small'
                            sx={{...requiredStyled}}
                            {...params}
                            error={eventError.startDate.isError}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack
                    id='event-end-date-picker'
                    name='endDate'
                    sx={{
                      width: '100%',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat={dateFormat}
                        value={selectedEvent.endDate}
                        label={<FormattedMessage id='calender.endDateOfTheEvent' defaultMessage="End Date Of Event"/>}
                        name='endDate'
                        onChange={(e) => {
                          handleChangeEventDetails(e, 'endDate');
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant='outlined'
                            helperText={eventError.endDate.errorMessage}
                            size='small'
                            sx={{...requiredStyled}}
                            {...params}
                            error={eventError.endDate.isError}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack
                    direction='row'
                    sx={{mt: 2, ml: 3}}
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={2}
                  >
                    <Stack sx={{width: '50%'}}>
                      <FormLabel id='demo-row-radio-buttons-group-label'>
                        <Stack direction='row'>
                          <Stack fontWeight={500}>
                            {/* {'Is this repeat event ?'} */}
                            <FormattedMessage id='calender.isThisRepeatEvent' defaultMessage='Is this repeat event ?'/>
                          </Stack>
                          <Stack sx={{color: 'red', ml: 2, mb: 2, fontSize: 14}}>*</Stack>
                        </Stack>
                      </FormLabel>
                    </Stack>
                    <Stack
                      sx={{width: '50%'}}
                      direction='row'
                      alignItems='center'
                    >
                      <RadioGroup
                        id='radio-group-does-the-company-plan-require-depedent-working-declaration-from-employee'
                        value={selectedEvent.isRepeat}
                        onChange={e => handleChangeEventDetails(e, 'ratio')}
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='isRepeat'
                      >
                        <FormControlLabel
                          id='form-control-label-true-does-the-company-plan-require-depedent-working-declaration-from-employee'
                          value={true}
                          control={<Radio />}
                          // label='Yes'
                          label={<FormattedMessage id='common.button.yes' defaultMessage='Yes'/>}
                        />
                        <FormControlLabel
                          id='form-control-label-false-does-the-company-plan-require-depedent-working-declaration-from-employee'
                          value={false}
                          control={<Radio />}
                          // label='No'
                          label={<FormattedMessage id='common.button.no' defaultMessage='No'/>}
                        />
                      </RadioGroup>
                      {eventError.isRepeat.isError && (
                        <FormHelperText className='Mui-error'>
                          {eventError.isRepeat.errorMessage}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Stack>
                  <Stack sx={{width: '100%'}}>
                    <FormControl>
                      <InputLabel size='small' id='dayOfWeek'>
                      {/* Repeat Date */}
                        <FormattedMessage id='calender.repeatDate' defaultMessage='Repeat Date'/>
                      </InputLabel>
                      <Select
                        name='dayOfWeek'
                        disabled={(selectedEvent.isRepeat == false) || (selectedEvent.isRepeat == "false")}
                        sx={{
                          ...requiredStyled,
                          backgroundColor: 'white',
                          mb: 2,
                          width: {xs: '100%', xl: '100%', md: '100%'},
                          '& fieldset': {
                            borderLeftColor: 'red',
                            borderLeftWidth: 3,
                          },
                        }}
                        labelId='dayOfWeek'
                        label='dayOfWeek'
                        value={selectedEvent?.dayOfWeek}
                        // error={formError.dayOfWeek.isError}
                        onChange={(e) => {
                          handleChangeEventDetails(e, 'dayOfWeek');
                        }}
                        variant='outlined'
                        multiple
                        renderValue={(selected) => selected + ", "}
                        size='small'
                        // sx={{...textFieldStyled}}
                      >
                        {week?.length > 0 && (
                          <MenuItem value='All'>
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  selectedEvent?.dayOfWeek?.length ==
                                  week?.length
                                }
                                indeterminate={
                                  selectedEvent?.dayOfWeek?.length > 0 &&
                                  selectedEvent?.dayOfWeek?.length <
                                    week?.length
                                }
                              />
                            </ListItemIcon>
                            <ListItemText primary='Select All' />
                          </MenuItem>
                        )}
                        {week?.map((item) => (
                          <MenuItem key={'class_' + item.value} value={item.value}>
                            <Checkbox
                              checked={
                                selectedEvent?.dayOfWeek?.findIndex(
                                  (xType) => xType === item?.value,
                                ) > -1
                              }
                            />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                      {eventError.dayOfWeek.isError && (
                        <FormHelperText className='Mui-error'>
                          {eventError.dayOfWeek.errorMessage}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      border: '1px solid #d8d8d8',
                      borderLeftColor: 'red',
                      borderLeftWidth: 3,
                      borderRadius: '5px',
                      padding: '7px 14px',
                    }}
                  >
                    <strong
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: 'rgb(107, 114, 128)',
                      }}
                    >
                     <FormattedMessage id='calender.color' defaultMessage="Color"/>
                    </strong>
                    <input
                      // disabled={
                      //   !CompanyConfigurationData.isCustomizationrequired
                      // }
                      type='color'
                      // onFocus={(event) =>
                      //   handleClearColorErrorOnfocus(
                      //     event,
                      //     JSON.parse(JSON.stringify(eventError)),
                      //   )
                      // }
                      onChange={(e) => {
                        let tempSelectedEvent = JSON.parse(
                          JSON.stringify(selectedEvent),
                        );
                        tempSelectedEvent.color = e.target.value;
                        setSelectedEvent(tempSelectedEvent);
                      }}
                      id='eventColorPicker'
                      name='color'
                      value={selectedEvent.color || ''}
                    ></input>
                    {eventError.color.isError && (
                      <FormHelperText className='Mui-error'>
                        {eventError.color.errorMessage}
                      </FormHelperText>
                    )}
                  </Stack>
                  <TextField
                    size='small'
                    id='eventDescriptionTextField'
                    name='description'
                    label={<FormattedMessage id='calender.eventDescription' defaultMessage="Event Description"/>}
                    variant='outlined'
                    InputProps={{inputProps: {min: 0}}}
                    placeholder='Event Description'
                    sx={{
                      ...requiredStyled,
                    }}
                    onChange={(e) => {
                      handleChangeEventDetails(e, 'textfield');
                    }}
                    value={
                      !isEmptyNullUndefined(selectedEvent.description)
                        ? selectedEvent.description
                        : ''
                    }
                    error={eventError.description.isError}
                    helperText={eventError.description.errorMessage}
                  />
                  <TextField
                    size='small'
                    id='eventDescriptionTextField'
                    name='url'
                    label={<FormattedMessage id='calender.url'  defaultMessage="URL"/>}
                    variant='outlined'
                    InputProps={{inputProps: {min: 0}}}
                    placeholder='URL'
                    sx={{
                      ...requiredStyled,
                    }}
                    onChange={(e) => {
                      handleChangeEventDetails(e, 'textfield');
                    }}
                    value={
                      !isEmptyNullUndefined(selectedEvent.url)
                        ? selectedEvent.url
                        : ''
                    }
                    error={eventError.url.isError}
                    helperText={eventError.url.errorMessage}
                  />
                  {
                    (addEventLoader || eventDeleteLoader) ? 
                    <p className='w-full flex justify-center'>
                      <CircularProgress />
                    </p>
                    :
                    <p className='popup-btns'>
                      <Button variant="text" onClick={handleValidateEvent}>
                        {/* Submit */}
                        <FormattedMessage id='common.button.submit' defaultMessage='Submit'/>
                      </Button>
                      <Button variant="text" onClick={handleClosePopup}>
                        {/* Close */}
                        <FormattedMessage id='common.button.close' defaultMessage='Close'/>
                      </Button>
                    </p>
                  }                  
                </Stack>
                </CalenderDialog>
                :
                isEdit ?
                <CalenderDialog 
                openDialog={openDialog} 
                setOpenDialog={setOpenDialog} 
                setIsNewEvent={setIsNewEvent}
                setIsEdit={setIsEdit}           
                initialEmptyEvent={initialEmptyEvent} 
                setSelectedEvent={setSelectedEvent}      
                setEventError={setEventError}
                initialEmptyEventError={initialEmptyEventError}
                >
                    <Stack
                  sx={{mt: 2}}
                  display='flex'
                  justifyContent='center'
                  spacing={2}
                >
                  <TextField
                    size='small'
                    id='eventTitleTextField'
                    name='title'
                    label={<FormattedMessage id='calender.eventTitle' defaultMessage="Event Title"/>}
                    variant='outlined'
                    InputProps={{inputProps: {min: 0}}}
                    placeholder='Event Title'
                    sx={{
                      ...requiredStyled,
                    }}
                    onChange={(e) => {
                      handleChangeEventDetails(e, 'textfield');
                    }}
                    value={
                      !isEmptyNullUndefined(selectedEvent.title)
                        ? selectedEvent.title
                        : ''
                    }
                    error={eventError.title.isError}
                    helperText={eventError.title.errorMessage}
                  />
                  <Stack
                    id='event-start-date-picker'
                    name='startDate'
                    sx={{
                      width: '100%',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat={dateFormat}
                        value={selectedEvent.startDate}
                        label={<FormattedMessage id='calender.startDateOfTheEvent' defaultMessage="Start Date Of Event"/>}
                        name='startDate'
                        onChange={(e) => {
                          handleChangeEventDetails(e, 'startDate');
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant='outlined'
                            helperText={eventError.startDate.errorMessage}
                            size='small'
                            sx={{...requiredStyled}}
                            {...params}
                            error={eventError.startDate.isError}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack
                    id='event-end-date-picker'
                    name='endDate'
                    sx={{
                      width: '100%',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat={dateFormat}
                        value={selectedEvent.endDate}
                        label={<FormattedMessage id='calender.endDateOfTheEvent' defaultMessage="End Date Of Event"/>}
                        name='endDate'
                        onChange={(e) => {
                          handleChangeEventDetails(e, 'endDate');
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant='outlined'
                            helperText={eventError.endDate.errorMessage}
                            size='small'
                            sx={{...requiredStyled}}
                            {...params}
                            error={eventError.endDate.isError}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack
                    direction='row'
                    sx={{mt: 2, ml: 3}}
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={2}
                  >
                    <Stack sx={{width: '50%'}}>
                      <FormLabel id='demo-row-radio-buttons-group-label'>
                        <Stack direction='row'>
                          <Stack fontWeight={500}>
                            {'Is this repeat event ?'}
                            {/* <IntlMessages id='Plans.Radio.Does_company_allow_employee_to_opt_out_of_this_plan'/> */}
                          </Stack>
                          <Stack sx={{color: 'red', ml: 2, mb: 2, fontSize: 14}}>*</Stack>
                        </Stack>
                      </FormLabel>
                    </Stack>
                    <Stack
                      sx={{width: '50%'}}
                      direction='row'
                      alignItems='center'
                    >
                      <RadioGroup
                        id='radio-group-does-the-company-plan-require-depedent-working-declaration-from-employee'
                        value={selectedEvent.isRepeat}
                        onChange={e => handleChangeEventDetails(e, 'ratio')}
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='isRepeat'
                      >
                        <FormControlLabel
                          id='form-control-label-true-does-the-company-plan-require-depedent-working-declaration-from-employee'
                          value={true}
                          control={<Radio />}
                          // label='Yes'
                          label={<FormattedMessage id='common.button.yes' defaultMessage='Yes'/>}
                        />
                        <FormControlLabel
                          id='form-control-label-false-does-the-company-plan-require-depedent-working-declaration-from-employee'
                          value={false}
                          control={<Radio />}
                          // label='No'
                          label={<FormattedMessage id='common.button.no' defaultMessage='No'/>}
                        />
                      </RadioGroup>
                      {eventError.isRepeat.isError && (
                        <FormHelperText className='Mui-error'>
                          {eventError.isRepeat.errorMessage}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Stack>
                  <Stack sx={{width: '100%'}}>
                    <FormControl>
                      <InputLabel size='small' id='dayOfWeek'>
                      {/* Repeat Date */}
                        <FormattedMessage id='calender.repeatDate' defaultMessage='Repeat Date'/>
                      </InputLabel>
                      <Select
                        name='dayOfWeek'
                        disabled={(selectedEvent.isRepeat == false) || (selectedEvent.isRepeat == "false")}
                        sx={{
                          ...requiredStyled,
                          backgroundColor: 'white',
                          mb: 2,
                          width: {xs: '100%', xl: '100%', md: '100%'},
                          '& fieldset': {
                            borderLeftColor: 'red',
                            borderLeftWidth: 3,
                          },
                        }}
                        labelId='dayOfWeek'
                        label='dayOfWeek'
                        value={!isEmptyNullUndefined(selectedEvent?.dayOfWeek) ? selectedEvent?.dayOfWeek : []}
                        // error={formError.dayOfWeek.isError}
                        onChange={(e) => {
                          handleChangeEventDetails(e, 'dayOfWeek');
                        }}
                        variant='outlined'
                        multiple
                        renderValue={(selected) => selected + ", "}
                        size='small'
                        // sx={{...textFieldStyled}}
                      >
                        {week?.length > 0 && (
                          <MenuItem value='All'>
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  selectedEvent?.dayOfWeek?.length ==
                                  week?.length
                                }
                                indeterminate={
                                  selectedEvent?.dayOfWeek?.length > 0 &&
                                  selectedEvent?.dayOfWeek?.length <
                                    week?.length
                                }
                              />
                            </ListItemIcon>
                            <ListItemText primary='Select All' />
                          </MenuItem>
                        )}
                        {week?.map((item) => (
                          <MenuItem key={'class_' + item.value} value={item.value}>
                            <Checkbox
                              checked={
                                selectedEvent?.dayOfWeek?.findIndex(
                                  (xType) => xType === item?.value,
                                ) > -1
                              }
                            />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                      {eventError.dayOfWeek.isError && (
                        <FormHelperText className='Mui-error'>
                          {eventError.dayOfWeek.errorMessage}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      border: '1px solid #d8d8d8',
                      borderLeftColor: 'red',
                      borderLeftWidth: 3,
                      borderRadius: '5px',
                      padding: '7px 14px',
                    }}
                  >
                    <strong
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: 'rgb(107, 114, 128)',
                      }}
                    >
                     <FormattedMessage id='calender.color' defaultMessage="Color"/>
                    </strong>
                    <input
                      // disabled={
                      //   !CompanyConfigurationData.isCustomizationrequired
                      // }
                      type='color'
                      // onFocus={(event) =>
                      //   handleClearColorErrorOnfocus(
                      //     event,
                      //     JSON.parse(JSON.stringify(eventError)),
                      //   )
                      // }
                      onChange={(e) => {
                        let tempSelectedEvent = JSON.parse(
                          JSON.stringify(selectedEvent),
                        );
                        tempSelectedEvent.color = e.target.value;
                        setSelectedEvent(tempSelectedEvent);
                      }}
                      id='eventColorPicker'
                      name='color'
                      value={selectedEvent.color || ''}
                    ></input>
                    {eventError.color.isError && (
                      <FormHelperText className='Mui-error'>
                        {eventError.color.errorMessage}
                      </FormHelperText>
                    )}
                  </Stack>
                  <TextField
                    size='small'
                    id='eventDescriptionTextField'
                    name='description'
                    label={<FormattedMessage id='calender.eventDescription' defaultMessage="Event Description"/>}
                    variant='outlined'
                    InputProps={{inputProps: {min: 0}}}
                    placeholder='Event Description'
                    sx={{
                      ...requiredStyled,
                    }}
                    onChange={(e) => {
                      handleChangeEventDetails(e, 'textfield');
                    }}
                    value={
                      !isEmptyNullUndefined(selectedEvent.description)
                        ? selectedEvent.description
                        : ''
                    }
                    error={eventError.description.isError}
                    helperText={eventError.description.errorMessage}
                  />
                  <TextField
                    size='small'
                    id='eventDescriptionTextField'
                    name='url'
                    label={<FormattedMessage id='calender.url'  defaultMessage="URL"/>}
                    variant='outlined'
                    InputProps={{inputProps: {min: 0}}}
                    placeholder='URL'
                    sx={{
                      ...requiredStyled,
                    }}
                    onChange={(e) => {
                      handleChangeEventDetails(e, 'textfield');
                    }}
                    value={
                      !isEmptyNullUndefined(selectedEvent.url)
                        ? selectedEvent.url
                        : ''
                    }
                    error={eventError.url.isError}
                    helperText={eventError.url.errorMessage}
                  />
                 {
                    (addEventLoader || eventDeleteLoader) ? 
                    <p className='w-full flex justify-center'>
                      <CircularProgress />
                    </p>
                    :
                    <p className='popup-btns'>
                      <Button variant="text" onClick={handleValidateEvent}>
                        {/* Submit */}
                        <FormattedMessage id='common.button.submit' defaultMessage='Submit'/>
                      </Button>
                      <Button variant="text" onClick={handleClosePopup}>
                        {/* Close */}
                        <FormattedMessage id='common.button.close' defaultMessage='Close'/>
                      </Button>
                    </p>
                  }  
                </Stack>
                </CalenderDialog> 
                :
                <CalenderDialog 
                openDialog={openDialog} 
                setOpenDialog={setOpenDialog} 
                initialEmptyEvent={initialEmptyEvent} 
                setSelectedEvent={setSelectedEvent} 
                setIsNewEvent={setIsNewEvent}
                setIsEdit={setIsEdit}
                setEventError={setEventError}
                initialEmptyEventError={initialEmptyEventError}
                >
                  <p style={{fontFamily:"poppins", fontSize:"1.1rem", fontWeight:"600"}}>
                    {/* Event Details */}
                    <FormattedMessage id='calender.eventDetails' defaultValue='Event Details' />
                  </p>
                    <div className="calender-popup-block">
                  <p className='event-info-row'>
                    <strong><FormattedMessage id='calender.title' defaultMessage="Title"/> : </strong>
                    {
                    !isEmptyNullUndefined(selectedEvent.title)
                      ? selectedEvent.title
                      : ''
                      }
                  </p>
                  <p className='event-info-row'>
                    <strong><FormattedMessage id='calender.startDate' defaultMessage="Start Date"/> : </strong>
                    {
                    !isEmptyNullUndefined(selectedEvent.startDate)
                      ? moment(selectedEvent.startDate).format(
                        dateFormat?.toUpperCase(),
                        )
                      : '-'
                      }
                  </p>
                  <p className='event-info-row'>
                    <strong><FormattedMessage id='calender.endDate' defaultMessage="End Date"/> : </strong>
                    {
                    !isEmptyNullUndefined(selectedEvent.endDate)
                      ? moment(selectedEvent.endDate).format(
                        dateFormat?.toUpperCase(),
                        )
                      : '-'
                      }
                  </p>
                  <p className='event-info-row'>
                    <strong><FormattedMessage id='calender.color' defaultMessage="Color"/> : </strong>
                    {
                    !isEmptyNullUndefined(selectedEvent.color) ? (
                      <input
                        disabled={true}
                        type='color'
                        onFocus={(event) => console.log(event, 'AKM Event')}
                        onChange={(event) => console.log(event, 'AKM Event')}
                        id='currentSelectedEventColorInput'
                        name='currentSelectedEventColor'
                        value={selectedEvent.color || ''}
                      ></input>
                    ) : ('-')
                    }
                  </p>
                  <p className='event-info-row'>
                    <strong><FormattedMessage id='calender.description' defaultMessage="Description"/> :</strong>
                    {
                    !isEmptyNullUndefined(selectedEvent.description)
                      ? selectedEvent.description
                      : '-'
                      }
                  </p>
                  <p className='event-info-row'>
                    <strong><FormattedMessage id='calender.url' defaultMessage="URL"/> : </strong>
                    {
                    !isEmptyNullUndefined(selectedEvent.url) ? (
                      <span
                        onClick={() => {
                          let link = selectedEvent.url.startsWith('http')
                            ? `${selectedEvent.url}`
                            : `https://${selectedEvent.url}`;
                          window.open(
                            `${link}`,
                            'Window Title',
                            'width=500, height=450',
                            '_blank',
                          );
                        }}
                        style={{color: '#0A8FDC', cursor: 'pointer'}}
                      >
                        {selectedEvent.url}
                      </span>
                    ) : ('-')
                    }
                  </p>
                  {
                    (addEventLoader || eventDeleteLoader) ?

                    <p className='w-full flex justify-center'>
                      <CircularProgress />
                    </p>

                    :

                  <p className='popup-btns'>  

                    {
                      !isEmptyNullUndefined(selectedEvent?.employee) && 
                      <Button variant="text" onClick={() => setIsEdit(() => true)}>
                        {/* Edit */}
                        <FormattedMessage id='common.button.Edit' defaultMessage='Edit'/>
                      </Button>
                    }                    

                    {
                      !isEmptyNullUndefined(selectedEvent?.employee) && 
                      <Button variant="text" onClick={() => {handleClosePopup("yes"); setdeleteEvent(() => true);}}>
                        {/* Delete */}
                        <FormattedMessage id='common.button.delete' defaultMessage='Delete'/>
                      </Button>
                    }                  

                    <Button variant="text" onClick={handleClosePopup}>
                      {/* Close */}
                      <FormattedMessage id='common.button.close' defaultMessage='Close'/>
                    </Button>

                  </p>
                  }                  
                    </div>
                </CalenderDialog>    
            }
            

           
          </div>

          {
            deleteEvent && 
            <p className='delete-confirm-popup'>
              <p style={{fontFamily:"poppins", fontSize:"1.1rem", fontWeight:"600"}}>
                {/* Are you sure, you want to delete this event ? */}
                <FormattedMessage id='calender.areYouSureWantDeleteThisEvent' defaultMessage='Are you sure, you want to delete this event ?'/>
              </p>

              {
              (addEventLoader || eventDeleteLoader) ? 
                <p className='w-full flex justify-center'>
                  <CircularProgress />
                </p>

                :

                <p className='event-delete-popup-btns'>
                <button className='yes-btn' onClick={() => handleDeleteEvent()}>
                  {/* Yes */}
                  <FormattedMessage id='common.button.yes' defaultMessage='Yes'/>
                </button>
                <button className='no-btn' onClick={() => setdeleteEvent(() => false)}>
                  {/* No */}
                  <FormattedMessage id='common.button.no' defaultMessage='No'/>
                </button>
              </p>
              }
              
              </p>
          }

        </div>
    )
};

export default Calender;