import { useEffect, useState } from "react";
import Rectangle from "../../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../../assets/radioCheck.svg";
import ButtonM from "../../../common/Button";
import PageTitleAndDescription from "../../../common/PageTitleAndDescription";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import APIList from "api";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { BsArrowRight, BsFillCheckCircleFill } from "react-icons/bs";
import { Box } from "@mui/system";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/material";
import { CurrencyFormat } from "components/utils/numberFormatter";
import { getPensionCoverageRule } from "components/utils/utils";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";
var classNames = require("classnames");

const PensionPlan = () => {
  const history = useHistory();

  const [dontWantEnroll, setDontWantEnroll] = useState(false);
  const [enroll, setEnroll] = useState(false);
  const [coveragePlan, setCoveragePlan] = useState(null);
  const [minContribution, setMinContribution] = useState(null);
  const [maxContribution, setMaxContribution] = useState(null);
  const [percentageOfValue, setPercentageOfValue] = useState(null);
  const [enrollMentLoader, setEnrollMentLoader] = useState(false);
  const [electionChecked, setElectionChecked] = useState(false);
  const [value, setValue] = useState(0);
  const [idNumber, setIdNumber] = useState("");
  const [isValidAmount, setIsValidAmount] = useState(true);
  const [amountErr, setAmountErr] = useState("");
  // const [coverageType, setcoverageType] = useState(null);
  const [employerContri, setemployerContri] = useState(null);
  const [contriLoader, setContriLoader] = useState(false);
  const [isOn, setIsOn] = useState(false);

  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const enrolledPlanData = useSelector(
    (state) => state?.planReducer?.enrolledPlanData
  );
  const currency = employeeDetails?.company?.currency;

  useEffect(() => {
    if (Object.keys(selectedPlan)?.length == 0) {
      history.push("/plans");
    }
  }, []);

  useEffect(() => {
    setCoveragePlan(getPensionCoverageRule(selectedPlan, employeeDetails));
    if (selectedPlan?.autoRenewal) {
      setEnroll(true);
    }
    if (enrolledPlanData?.employeeCoverage) {
      setPercentageOfValue(
        (
          (enrolledPlanData?.employeeCoverage * 100) /
          employeeDetails?.currentSalary
        ).toFixed(2)
      );
    }
  }, []);

  useEffect(() => {
    // if (selectedPlan?.employeeCoverageSelectionOption === "Flat") {
    //   // setcoverageType("Flat");
    //   setIsOn(true);
    // }
    if (selectedPlan?.employeeCoverageSelectionOption === "Percent") {
      // setcoverageType("Percent");
      setIsOn(false);
    }else{
      setIsOn(true);
    }
  }, [selectedPlan?.employeeCoverageSelectionOption])
  

  useEffect(() => {
    if(!isOn){
      if (isEdit()) {
        setValue(enrolledPlanData?.employeeCoverage);
      } else {
        setValue(minContriPercent());
      }
    }else{
      if (isEdit()) {
        setValue(enrolledPlanData?.employeeCoverage);
      } else {
        setValue(minContribution);
      }
    }
    
  }, [minContribution]);

  
  useEffect(() => {
    if(!isOn){
      if (isEdit()) {
        setValue(enrolledPlanData?.employeeCoverage);
      } else {
        setValue(minContriPercent());
      }
    }else{
      if (isEdit()) {
        setValue(enrolledPlanData?.employeeCoverage);
      } else {
        setValue(minContribution);
      }
    }
    
  }, [isOn]);

  useEffect(() => {
    if (coveragePlan?.minimumCoverageType?.toLowerCase() == "percentage") {
      setMinContribution(
        (employeeDetails?.currentSalary * coveragePlan?.minimumContribution) /
          100
      );
    } else {
      setMinContribution(coveragePlan?.minimumContribution);
    }

    if (coveragePlan?.maximumCoverageType?.toLowerCase() == "percentage") {
      setMaxContribution(
        (employeeDetails?.currentSalary * coveragePlan?.maximumContribution) /
          100
      );
    } else {
      setMaxContribution(coveragePlan?.maximumContribution);
    }
  }, [coveragePlan]);

  const isEdit = () => {
    let edit = false;
    if (enrolledPlanData?.plan?.id == selectedPlan?.id) {
      edit = true;
    }
    return edit;
  };

  const getContri = () => {
    setContriLoader(true);
    APIList.pensionPlanEmployerContri({
      id:selectedPlan?.id,
      amount:value,
      empId:employeeDetails?.id,
      coverageType: isOn ? "Flat" : "Percent",
    }) .then((res) => {
      setemployerContri(res?.data);
      setContriLoader(false);
    })
    .catch((err) => {
      toast.error(
        <div className="flex flex-row">
          <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
          &nbsp;&nbsp;
          {err?.message}            
        </div>
      );
      setContriLoader(false);
    }
    )
  }

  const handleAddEnroll = () => {
    setEnrollMentLoader(true);
    if (isEdit()) {
      APIList.editHealthInsuranceEnroll({
        id: enrolledPlanData?.id,
        employee: {
          id: employeeDetails.id,
        },
        isDraft: true,
        status: "ISDRAFT",
        isEmployeeEnrolled: !dontWantEnroll,
        planOptOut: dontWantEnroll,
        selectedCoverage:
          // null,
          {
            id: dontWantEnroll ? null : coveragePlan?.id,
          },
        employeeCoverage: dontWantEnroll ? null : value,
        // selectedPlan?.coveragePlans[0]?.amount,
        idType: selectedPlan?.uniqueIDName,
        idNumber: idNumber,
        plan: {
          id: selectedPlan.id,
          className: selectedPlan.className,
        },
        enrollmentDate: new Date(),
        employeeCoverageType: isOn ? "Flat" : "Percent",
      })
        .then((res) => {
          res &&
            toast.success(
              <div className="flex flex-row">
                <BsFillCheckCircleFill
                  style={{ width: "2rem", height: "2rem" }}
                />
                &nbsp;&nbsp;Updated Successfully
              </div>
            );
          setEnrollMentLoader(false);
          history.push("/plans");
        })
        .catch((err) => {
          toast.error(
            <div className="flex flex-row">
              <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
              &nbsp;&nbsp;{err?.title}
            </div>
          );
          setEnrollMentLoader(false);
        });
    } else {
      APIList.healthInsuranceEnroll({
        employee: {
          id: employeeDetails.id,
        },
        isDraft: true,
        status: "ISDRAFT",
        isEmployeeEnrolled: !dontWantEnroll,
        planOptOut: dontWantEnroll,
        selectedCoverage:
          // null,
          {
            id: dontWantEnroll ? null : coveragePlan?.id,
          },
        employeeCoverage: dontWantEnroll ? null : value,
        // selectedPlan?.coveragePlans[0]?.amount,
        idType: selectedPlan?.uniqueIDName,
        idNumber: idNumber,
        plan: {
          id: selectedPlan.id,
          className: selectedPlan.className,
        },
        enrollmentDate: new Date(),
        employeeCoverageType: isOn ? "Flat" : "Percent",
      })
        .then((res) => {
          res &&
            toast.success(
              <div className="flex flex-row">
                <BsFillCheckCircleFill
                  style={{ width: "2rem", height: "2rem" }}
                />
                &nbsp;&nbsp;Enrolled Successfully
              </div>
            );
          setEnrollMentLoader(false);
          history.push("/plans");
        })
        .catch((err) => {
          toast.error(
            <div className="flex flex-row">
              <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
              &nbsp;&nbsp;{err?.title}
            </div>
          );
          setEnrollMentLoader(false);
        });
    }
  };

  const handleChange = (event, newValue) => {
    setemployerContri(null);
    setValue(newValue);
    setPercentageOfValue(
      ((newValue * 100) / employeeDetails?.currentSalary).toFixed(2)
    );
  };

  const handleInputChange = (event) => {
    setemployerContri(null);
    const { value: inputValue } = event.target;
    // only allow digits
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    setValue(numericValue);

    // check if value is within range
    if(!isOn){
      if(numericValue <= minContriPercent()){
        setIsValidAmount(false);
        setAmountErr(`The amount should be less than ${minContriPercent()}%`);
      }
      if(numericValue >= maxContriPercent()){
        setIsValidAmount(false);
        setAmountErr(`The amount should be greater than ${maxContriPercent()}%`);
      }
      if (
        numericValue >= minContriPercent() &&
        numericValue <= maxContriPercent()
      ) {
        setIsValidAmount(true);
        setAmountErr("");
      }

    }else{
      if (numericValue <= minContribution) {
        setIsValidAmount(false);
        setAmountErr(`The amount should be greater than ${minContribution}`);
      }
      if (numericValue >= (maxContribution || employeeDetails?.currentSalary)) {
        setIsValidAmount(false);
        setAmountErr(
          `The amount should be less than ${
            maxContribution || employeeDetails?.currentSalary
          }`
        );
      }
      if(numericValue % Number(selectedPlan?.slabSize) !== 0){
        setIsValidAmount(false);
        setAmountErr(`The amount must be a multiple of ${selectedPlan?.slabSize}`);
      }
      if (
        numericValue >= minContribution &&
        numericValue <= (maxContribution || employeeDetails?.currentSalary) && 
        numericValue % Number(selectedPlan?.slabSize) === 0
      ) {
        setIsValidAmount(true);
        setAmountErr("");
      }
      
    }
  };

  const minContriPercent = () => {
    return ((Number(minContribution)/Number(employeeDetails?.currentSalary)) * 100);
  }
  const maxContriPercent = () => {
    return ((Number(maxContribution)/Number(employeeDetails?.currentSalary)) * 100);
  }

  const stepArr = () => {
    let arr = []
    if(!isOn && selectedPlan?.slabSize !== null) {
      for(let i = minContriPercent() + Number(selectedPlan?.slabSize); i < maxContriPercent(); i += Number(selectedPlan?.slabSize)){
        arr.push(i);
    }  
    }
    return arr;
  };

  const marks = [
    {
      value: !isOn ? minContriPercent() : (minContribution || 0),
      label: !isOn ? `${minContriPercent()}%` : `${CurrencyFormat(minContribution, currency)}`,
    },
    ...stepArr()?.map((x) => {
      return {
        value: !isOn ? x : "",
        // label: !isOn ? `${x}%` : "",
      }
    },),
    {
      value: !isOn ? maxContriPercent() : (maxContribution || employeeDetails?.currentSalary),
      label: !isOn ? `${maxContriPercent()}%` : `${CurrencyFormat(
        maxContribution || employeeDetails?.currentSalary,
        currency
      )}`,
    },
  ];
  function valueLabelFormat(value) {
    let labelVal
    if(!isOn){
      labelVal = `${value}%` 
    }else{
      labelVal = CurrencyFormat(value, currency)
    }
    return labelVal;
  }
 
  // const handleCoverageType = (event) => {
  //   setcoverageType(event.target.value);
  // };


  const handleButtonClick = () => {
    if (selectedPlan?.employeeCoverageSelectionOption === "Both"){
    setIsOn(!isOn);
  }else{

  }
  };

  return (
    <div className="lifeInsurancePage">
      <h4 className="page-heading-navbar">{selectedPlan?.displayName}</h4>
      <PageHeaderForPlans />
      <div className="data pension-plan">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <PageTitleAndDescription
              title={"Choose your plan"}
              // description={`Benelove offers you the option to add your dependents to the medical plan.`}
            />
            <div className="w-full">
              

              <div className="radioButtonOuter">
                {selectedPlan?.planOptOut && (
                  <button
                    onClick={() => {
                      setDontWantEnroll(!dontWantEnroll);
                    }}
                    className={classNames("radioButton", {
                      active: dontWantEnroll,
                    })}
                    data-value="dont want to enroll"
                  >
                    <div className="radioOuter">
                      <img src={Rectangle} alt="" className="radio" />
                      <img src={radioCheckIcon} alt="" className="radioCheck" />
                    </div>
                    I don't want to enroll
                  </button>
                )}
                {!dontWantEnroll && (
                  <button
                    onClick={() =>
                      !selectedPlan?.autoRenewal && setEnroll(!enroll)
                    }
                    className={classNames("radioButton", { active: enroll })}
                    data-value="employee"
                  >
                    <div className="radioOuter">
                      <img src={Rectangle} alt="" className="radio" />
                      <img src={radioCheckIcon} alt="" className="radioCheck" />
                    </div>
                    {employeeDetails?.firstName} {employeeDetails?.lastName}
                  </button>
                )}
                {enroll && !dontWantEnroll && (
                  <>










              <div className="pension-card">
                <div className="first-section">
                  <p className="que-pension">How much you want to contribute</p>
                  <p className="contri-header"> {!isOn ? `${value}% of your salary ${ coveragePlan?.frequency !== null ? coveragePlan?.frequency : ""}` :  `${CurrencyFormat(value, currency)} ${ coveragePlan?.frequency !== null ? coveragePlan?.frequency : ""}`}</p>
                </div>
                <div className="pension-contri-slider">
                  <Slider
                    min={
                      !isOn ? minContriPercent() : minContribution || 0
                    }
                    max={
                      !isOn ? maxContriPercent() : maxContribution || employeeDetails?.currentSalary
                    }
                    marks={marks}
                    valueLabelDisplay="on"
                    getAriaValueText={valueLabelFormat}
                    valueLabelFormat={valueLabelFormat}
                    aria-label="Default"
                    value={value}
                    onChange={handleChange}
                    step={Number(selectedPlan?.slabSize) || 1}
                  />
                </div>
                <div className="slabTags">
                  <div className="slabBlocks">
                  {
                    !isOn &&
                    stepArr()?.map((x) => {
                      return(
                        <span onClick={() => {setValue(x); setemployerContri(null);}}>{x}%</span>
                      )
                    })
                  }
                  </div>
                  {
                    isOn &&
                    <TextField
                    label="Enter Amount"
                    value={value}
                    onChange={handleInputChange}
                    inputProps={{
                      min: minContribution || 0,
                      max:
                        maxContribution || employeeDetails?.currentSalary,
                      pattern: "[0-9]*", // add this attribute to show numeric keyboard on mobile devices
                      inputMode: "numeric", // add this attribute to show numeric keyboard on mobile devices
                    }}
                    InputProps={{
                      endAdornment:
                        // !isOn ? (
                        //   <InputAdornment position="end">%</InputAdornment>
                        // ) : (
                          <InputAdornment position="end">
                            {employeeDetails?.company?.currency || ""}
                          </InputAdornment>
                        // ),
                    }}
                    sx={{
                      // mb: 1,
                      width: "100%",
                      // margin: "auto",
                      // marginTop: "1rem",
                    }}
                    error={!isValidAmount}
                    helperText={amountErr}
                  />
                  }
                </div>
                <div className="togglaeMain">
                  <p className={`${isOn ? "lightText" : "darkText"}`}>
                    Percentage
                  </p>

                  <div
                    className={`switch-button ${isOn ? "on" : "off"}`}
                    onClick={() => selectedPlan?.employeeCoverageSelectionOption === "Both" && handleButtonClick()}
                    disabled={selectedPlan?.employeeCoverageSelectionOption !== "Both"}
                  >
                    <div className="switch-button-circle"></div>
                    <div className="switch-button-label"></div>
                  </div>

                  <p className={`${isOn ? "darkText" : "lightText"}`}>
                    Flat Amount
                  </p>
                </div>

                <div className="employerMatch">
                <div className="empmatch">
                <p className="upper">Employer match</p>
                &nbsp;<BsArrowRight />&nbsp; 
                <p className={`${employerContri !== null ? "full-middle" : "middle"}`}>
                <ButtonM
                        text={
                          employerContri !== null
                            ? `${employerContri} ${employeeDetails?.company?.currency} ${ coveragePlan?.frequency !== null ? coveragePlan?.frequency : ""}`
                            : "Click here"
                        }
                        onpress={() => getContri()}
                        disabled={
                          contriLoader ||
                          employerContri !== null ||
                          (isOn && !isValidAmount)
                        }
                        loading={contriLoader}
                        bgcolor={
                          employerContri !== null || (isOn && !isValidAmount)
                            ? "#ffffff"
                            : ""
                        }
                      />
                </p>
                </div>
                {
                  employerContri !== null && 
                  <div className="bottom">
                <p>Total 
                  {/* {`${ coveragePlan?.frequency !== null ? `<${coveragePlan?.frequency}>` : ""}`} */}
                  </p>
                &nbsp;<BsArrowRight />&nbsp; 
                {
                  isOn ? 
                  <p className="tAmount">{CurrencyFormat(Number(employerContri) + value, currency)} {coveragePlan?.frequency !== null ? coveragePlan?.frequency : ""}</p>
                  :
                  <p className="tAmount">{CurrencyFormat(Number(employerContri) + ((employeeDetails?.currentSalary * value)/100), currency)} {coveragePlan?.frequency !== null ? coveragePlan?.frequency : ""}</p>
                }
                </div>
                }
                
              </div>

              <div className="enterId">
              {!isEdit() && selectedPlan?.needUniqueID && (
                        <TextField
                          id="outlined-basic"
                          label={selectedPlan?.uniqueIDName}
                          variant="outlined"
                          onChange={(event) => setIdNumber(event.target.value)}
                          value={idNumber}
                          sx={{
                            // mb: 1,
                            width: "100%",
                            // margin: "auto",
                            // marginTop: "1rem",
                          }}
                          className="id-number"
                        />
                      )}
              </div>
              </div>
                  </>
                )}
              </div>
            </div>
            <div className="bottomButtonReq">
              <p
                className="mt-2 peer-invalid:visible text-gray-400 text-sm"
                style={{ marginBottom: "-1rem" }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="success"
                        checked={electionChecked}
                        onChange={() => setElectionChecked(!electionChecked)}
                        inputProps={{ "aria-label": "controlled" }}
                        data-value="confirm-submission"
                      />
                    }
                    label="Click here to confirm"
                  />
                </FormGroup>
              </p>
              <ButtonM
                text="Save as Draft"
                onpress={() => handleAddEnroll()}
                disabled={
                  enrollMentLoader ||
                  (!dontWantEnroll && enroll && value == 0) ||
                  !electionChecked ||
                  (!isEdit() &&
                    !dontWantEnroll &&
                    selectedPlan?.needUniqueID &&
                    idNumber.trim() === "") ||
                  !isValidAmount
                }
                loading={enrollMentLoader}
                bgcolor={
                  (!dontWantEnroll && enroll && value == 0) ||
                  !electionChecked ||
                  (!isEdit() &&
                    !dontWantEnroll &&
                    selectedPlan?.needUniqueID &&
                    idNumber.trim() === "") ||
                  !isValidAmount
                    ? "gray"
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PensionPlan;
