import { useEffect } from "react";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import ButtonM from "../../../common/Button";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import { genders, getAllowedEmp, getRelationName, isEmptyNullUndefined, requiredSelectStyled, requiredStyled } from "components/utils/utils";
import { actions as DependantActions } from "../AddDependentAddress/redux/actions";
import { FormattedMessage } from "react-intl";

const AddDependent = () => {
  
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [allowedDependents, setAllowedDependents] = React.useState(null);

  const employeeDetails = useSelector((state) => state && state.empData);
  const empCompany = useSelector((state) => state?.empData?.empData?.company?.displayName);
  const idType = useSelector((state) => state?.empData?.idTypes);

  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const empDependant = useSelector((state) => state?.dependents?.allDependents);
  const tempDependantInfo = useSelector((state) => state?.dependents?.tempDependantInfo);
  const lifeEventConfiguratorDTO = useSelector((state) => state?.planReducer?.lifeEventConfiguratorDTO);

  useEffect(() => {
    if(Object.keys(selectedPlan)?.length == 0){
        history.push("/plans");
      }
  }, [selectedPlan]);
  
  useEffect(() => {
    getAllowedDepends();
  }, []);

  const getAllowedDepends = () => {
    let legitDependents = [];
      selectedPlan?.coveragePlans?.forEach(
        (x) =>
          x?.employeeRoles?.forEach(
            (y) =>
               y?.id ===
                 employeeDetails?.empData?.employeeRole?.id &&
                  legitDependents.push(x)
          )
      );

      setAllowedDependents(legitDependents);

      if( lifeEventConfiguratorDTO != null && !lifeEventConfiguratorDTO?.relationships?.includes("NA")){
        let lgd = [];
        legitDependents?.length > 0 &&
          legitDependents?.forEach((dep) =>
            dep?.relation?.forEach((x) =>
              lifeEventConfiguratorDTO?.relationships?.includes(x) && 
              lgd.push(dep)
            )
          );
          setAllowedDependents(lgd);
      }  

  }

  const initialEmptyDependent = {
    relation: null,
    firstName: null,
    middleName: null,
    lastName: null,
    gender: null,
    idType: null,
    nationalId: null,
    dateOfBirth: null,
    nationality: null,
    isRelativeWorking:null,
    isRelativeWorkingInNium:null,
    employeeIdOfRelative:null,
  };

  const initialFormErrorList = {
    relation: { isError: false, errorMessage: "" },
    firstName: { isError: false, errorMessage: "" },
    middleName: { isError: false, errorMessage: "" },
    lastName: { isError: false, errorMessage: "" },
    gender: { isError: false, errorMessage: "" },
    idType: { isError: false, errorMessage: "" },
    nationalId: { isError: false, errorMessage: "" },
    dateOfBirth: { isError: false, errorMessage: "" },
    nationality: { isError: false, errorMessage: "" },
    isRelativeWorking: { isError: false, errorMessage: "" },
    isRelativeWorkingInNium: { isError: false, errorMessage: "" },
    employeeIdOfRelative: { isError: false, errorMessage: "" },
  };

  const [dependent, setDependent] = React.useState({});
  const [formError, setFormError] = React.useState(initialFormErrorList);

  useEffect(() => {
   if (location?.state?.isDepEdit){
    let dependantToEdit = empDependant?.filter((x) => x.id === location?.state?.id)[0];
    if(!isEmptyNullUndefined(tempDependantInfo)){
      setDependent(tempDependantInfo);
    } else {
      setDependent(dependantToEdit);
    }      
   } else {
    if(!isEmptyNullUndefined(tempDependantInfo)){
      setDependent(tempDependantInfo);
    } else {
      setDependent(initialEmptyDependent);
    }    
   }
  }, [])
  

  const selectedRelation =    
    selectedPlan?.coveragePlans?.filter(
      (x) =>
        x &&
        x.relation &&
        x.relation.includes(dependent.relation && dependent.relation)
    );
  const handleResetForm = () => {
    const tempInitDependent = JSON.parse(JSON.stringify(initialEmptyDependent));

    const tempInitError = JSON.parse(JSON.stringify(initialFormErrorList));
    setDependent(tempInitDependent);
    setFormError(tempInitError);
  };

  const handleChangeDependent = async (event, fieldType) => {
    
    const tempDependent = JSON.parse(JSON.stringify(dependent));
    const tempError = JSON.parse(JSON.stringify(formError));
    if (fieldType === "textfield" || fieldType === "dropdown") {
      tempDependent[event.target.name] = event.target.value;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    } else if (fieldType === "birthdate") {
      tempDependent.dateOfBirth = event;
      tempError.dateOfBirth.isError = false;
      tempError.dateOfBirth.errorMessage = "";
    } else if (fieldType === "checkbox") {
      tempDependent[event.target.name] = event.target.value === "true" ? true : false;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    }

    // if(!tempDependent["isRelativeWorking"]){
    //   tempDependent["isRelativeWorkingInNium"] = null;
    //   tempDependent["employeeIdOfRelative"] = null;
    // }
    if(!tempDependent["isRelativeWorkingInNium"]){
      tempDependent["employeeIdOfRelative"] = null;
    }

    setDependent(tempDependent);
    setFormError(tempError);
  };

  const handleValidateDependent = async () => {
    let isValid = true;
    const tempError = JSON.parse(JSON.stringify(formError));
    const tempDependent = JSON.parse(JSON.stringify(dependent));

    if (
      tempDependent.relation === null ||
      tempDependent.relation.trim() === ""
    ) {
      tempError.relation.isError = true;
      tempError.relation.errorMessage = "Please select Relationship";
      isValid = false;
    }
    if (
      tempDependent.firstName === null ||
      tempDependent.firstName.trim() === ""
    ) {
      tempError.firstName.isError = true;
      tempError.firstName.errorMessage = "Please enter First Name";
      isValid = false;
    }

    if (
      tempDependent.lastName === null ||
      tempDependent.lastName.trim() === ""
    ) {
      tempError.lastName.isError = true;
      tempError.lastName.errorMessage = "Please enter Last Name";
      isValid = false;
    }

    if (tempDependent.gender === null || tempDependent.gender.trim() === "") {
      tempError.gender.isError = true;
      tempError.gender.errorMessage = "Please select Gender";
      isValid = false;
    }
    // if (tempDependent.idType === null || tempDependent.idType.trim() === "") {
    //   tempError.idType.isError = true;
    //   tempError.idType.errorMessage = "Please select idType";
    //   isValid = false;
    // }
    if (
      tempDependent.dateOfBirth == null ||
      tempDependent.dateOfBirth.trim() == ""
    ) {
      tempError.dateOfBirth.isError = true;
      tempError.dateOfBirth.errorMessage = "Please enter Date of Birth";
      isValid = false;
    }
    // if (
    //   (getAge(tempDependent.dateOfBirth) + 1) >
    //   ((selectedRelation &&
    //     selectedRelation[0] &&
    //     selectedRelation[0].maxAge !== null) ?
    //     selectedRelation[0].maxAge : 1000)
    // ) {
    //   tempError.dateOfBirth.isError = true;
    //   tempError.dateOfBirth.errorMessage = `This plan does not allow ${
    //     tempDependent.relationship
    //   } above ${
    //     (selectedRelation &&
    //       selectedRelation[0] &&
    //       selectedRelation[0].maxAge !== null) ?
    //       selectedRelation[0].maxAge : '1000'
    //   } years to be covered`; 
    //   isValid = false;
    // }
    // if (
    //   getAge(tempDependent.dateOfBirth) <
    //   ((selectedRelation &&
    //     selectedRelation[0] &&
    //     selectedRelation[0].minAge !== null) ?
    //     selectedRelation[0].minAge : 0)
    // ) {
    //   tempError.dateOfBirth.isError = true;
    //   tempError.dateOfBirth.errorMessage = `This plan does not allow ${
    //     tempDependent.relationship
    //   } below ${
    //     (selectedRelation &&
    //       selectedRelation[0] &&
    //       selectedRelation[0].minAge !== null) ?
    //       selectedRelation[0].minAge : 0
    //   } years to be covered`;
    //   isValid = false;
    // }
    // if (
    //   tempDependent.idType !== null && (tempDependent.nationalId == null ||
    //   tempDependent?.nationalId?.trim() == "")
    // ) {
    //   tempError.nationalId.isError = true;
    //   tempError.nationalId.errorMessage = `Please enter ID (${tempDependent?.idType})`;
    //   isValid = false;
    // }
    // if (
    //   tempDependent.nationality == null ||
    //   tempDependent?.nationality?.trim() == ""
    // ) {
    //   tempError.nationality.isError = true;
    //   tempError.nationality.errorMessage = "Please enter nationality";
    //   isValid = false;
    // }
    if (
      getAge(tempDependent.dateOfBirth) < 0
    ) {
      tempError.dateOfBirth.isError = true;
      tempError.dateOfBirth.errorMessage = "Please enter correct Date of Birth";
      isValid = false;
    }
    // if (
    //   tempDependent.isRelativeWorking == null 
    // ) {
    //   tempError.isRelativeWorking.isError = true;
    //   tempError.isRelativeWorking.errorMessage = "Please select yes/no";
    //   isValid = false;
    // }
    if (
      tempDependent.isRelativeWorkingInNium == null
    ) {
      tempError.isRelativeWorkingInNium.isError = true;
      tempError.isRelativeWorkingInNium.errorMessage = "Please select yes/no";
      isValid = false;
    }
    if (
      tempDependent.isRelativeWorkingInNium && (tempDependent.employeeIdOfRelative == null || tempDependent?.employeeIdOfRelative?.trim() == "")
    ) {
      tempError.employeeIdOfRelative.isError = true;
      tempError.employeeIdOfRelative.errorMessage = "Please enter employe-id of dependant";
      isValid = false;
    }

    //do Submit
    if (isValid) {
      // if (isEdit) {
      //   updateDependent();
      // } else {
      submitDependent();
      // }
    } else {
      setFormError(tempError);
    }
  };

  const submitDependent = () => {
    // handleResetForm();
    dispatch(DependantActions.SaveDependantInfo(dependent));
    if (location?.state?.isDepEdit){
      history.push({
        pathname: "/adddependentaddress",
        state: { dependant: dependent, isDepEdit:true },
      });
     } else {      
      history.push({
        pathname: "/adddependentaddress",
        state: { dependant: dependent },
      });
     }
    
  };

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <div className="AddDependentPage">
      <h4 className="page-heading-navbar">
        {/* Add Dependent */}
        {location?.state?.isDepEdit ? <FormattedMessage id="basicMedical.editDependent" defaultMessage="Edit Dependent"/> : <FormattedMessage id="basicMedical.addDependent" defaultMessage="Add Dependent"/>}
      </h4>     

      <Stack sx={{ mx: { xs: 0, xl: 10, md: 5 } }}>
        <Box sx={{ my: 2 }}>
          <Stack
            display={{ xs: "flex", sm: "flex" }}
            direction={{ xs: "column", sm: "column" }}
            justifyContent="space-between"
            alignItems={{ xs: "center", sm: "center" }}
            spacing={2}
          >
            <Stack width={{ xs: "90%", sm: "75%" }}>
              <div className="mt-4">
                <div className="flex flex-wrap">
                  <div className="w-full flex justify-between">
                    <div className="details">
                      <h3 className="title">
                        {/* Personal Details */}
                        <FormattedMessage id="basicMedical.personalDetails" defaultMessage="Personal Details"/>
                      </h3>
                      <p className="subTitle">
                        {/* Add the details for your dependents.{" "} */}
                        <FormattedMessage id="basicMedical.addTheDetailsForYourDependents" defaultMessage="Add the details for your dependents."/>{" "}
                      </p>
                    </div>

                  </div>
                </div>
              </div>
              <FormControl sx={{ my: 2 }}>
                <InputLabel size="small" id="relationship">
                  {/* Select relationship */}
                  <FormattedMessage id="basicMedical.selectRelationship" defaultMessage="Select relationship"/>
                </InputLabel>
                <Select
                  value={dependent.relation ? dependent.relation : ""}
                  labelId="Select relationship"
                  name="relation"
                  onChange={(event) => handleChangeDependent(event, "dropdown")}
                  variant="outlined"
                  error={formError.relation.isError}
                  helperText={formError.relation.errorMessage}
                  // label="Select relationship"
                  label={ <FormattedMessage id="basicMedical.selectRelationship" defaultMessage="Select relationship"/>}
                  size="small"
                  sx={{
                    width: "100%",
                    //backgroundColor: "#dedede",
                    "& fieldset": {
                      border: "1px solid #dedede",
                    },
                    "& .MuiInputBase-input:focus": {
                      border: "2px solid #fcd25e", // focus
                    },
                    "& .css-k4qjio-MuiFormHelperText-root": {
                      backgroundColor: "#ffffff",
                      margin: "0px",
                      paddingLeft: "0.5rem",
                    },
                    ...requiredSelectStyled
                  }}
                >
                  {
                  allowedDependents &&
                    allowedDependents.map((allowedDependent) => {
                      return (
                        allowedDependent &&
                        allowedDependent.relation &&
                        allowedDependent.relation.map((relation) => {
                          return (
                            relation == "Parent" ?
                            ["Mother","Father"].map((rel) => {
                              return(
                            <MenuItem key={rel} value={rel} sx={{textTransform:"capitalize"}}>
                              {getRelationName(rel)}
                              {}
                            </MenuItem>
                              )
                            })
                            :  
                            relation == "PIL" ?
                            ["FIL","MIL"].map((rel) => {
                              return(
                            <MenuItem key={rel} value={rel} sx={{textTransform:"capitalize"}}>
                              {getRelationName(rel)}
                              {}
                            </MenuItem>
                              )
                            })
                            :     
                            relation != "NA" && (getRelationName(relation) !== "Self") &&                                                  
                            <MenuItem key={relation} value={relation} sx={{textTransform:"capitalize"}}>
                              { getRelationName(relation)}
                              {}
                            </MenuItem>
                          );
                        })
                      );
                    })}
                </Select>
                {formError.relation.errorMessage && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {formError.relation.errorMessage}
                  </FormHelperText>
                )}
              </FormControl>

              <TextField
                size="small"
                name="firstName"
                // label="First Name"
                label={<FormattedMessage id="basicMedical.firstName" defaultMessage="First Name"/>}
                id="outlined-basic"
                onChange={(event) => handleChangeDependent(event, "textfield")}
                variant="outlined"
                error={formError.firstName.isError}
                helperText={formError.firstName.errorMessage}
                value={dependent.firstName ? dependent.firstName : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  //backgroundColor: "#dedede",
                  "& fieldset": {
                    border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                  ...requiredStyled
                }}
              />
              <TextField
                size="small"
                name="middleName"
                // label="Middle Name"
                label={<FormattedMessage id="basicMedical.middleName" defaultMessage="Middle Name"/>}
                id="outlined-basic"
                onChange={(event) => handleChangeDependent(event, "textfield")}
                variant="outlined"
                error={formError.middleName.isError}
                helperText={formError.middleName.errorMessage}
                value={dependent.middleName ? dependent.middleName : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  //backgroundColor: "#dedede",
                  "& fieldset": {
                    border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                }}
              />
              <TextField
                size="small"
                name="lastName"
                // label="Last Name"
                label={<FormattedMessage id="basicMedical.lastName" defaultMessage="Last Name"/>}
                id="outlined-basic"
                onChange={(event) => handleChangeDependent(event, "textfield")}
                variant="outlined"
                error={formError.lastName.isError}
                helperText={formError.lastName.errorMessage}
                value={dependent.lastName ? dependent.lastName : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  //backgroundColor: "#dedede",
                  "& fieldset": {
                    border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                  ...requiredStyled
                }}
              />
              <FormControl sx={{ my: 2 }}>
                <InputLabel size="small" id="gender">
                  {/* Gender */}
                  <FormattedMessage id="basicMedical.gender" defaultMessage="Gender"/>
                </InputLabel>
                <Select
                  value={dependent.gender ? dependent.gender : ""}
                  labelId="gender"
                  name="gender"
                  onChange={(event) => handleChangeDependent(event, "dropdown")}
                  variant="outlined"
                  error={formError.gender.isError}
                  helperText={formError.gender.errorMessage}
                  // label="Gender"
                  label={<FormattedMessage id="basicMedical.gender" defaultMessage="Gender"/>}
                  size="small"
                  sx={{
                    width: "100%",
                    //backgroundColor: "#dedede",
                    "& fieldset": {
                      border: "1px solid #dedede",
                    },
                    "& .MuiInputBase-input:focus": {
                      border: "2px solid #fcd25e", // focus
                    },
                    "& .css-k4qjio-MuiFormHelperText-root": {
                      backgroundColor: "#ffffff",
                      margin: "0px",
                      paddingLeft: "0.5rem",
                    },
                    ...requiredSelectStyled
                  }}
                >
                  {
                    genders.map((gender) => {
                      return(
                        <MenuItem key={gender.value} value={gender.value}>
                          {gender.name}
                        </MenuItem>
                      )
                    })
                  }                  
                </Select>
                {formError.gender.errorMessage && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {formError.gender.errorMessage}
                  </FormHelperText>
                )}
              </FormControl>

              {/* <FormControl sx={{ my: 2 }}>
                <InputLabel size="small" id="idType">
                  Id Type
                </InputLabel>
                <Select
                  value={dependent.idType ? dependent.idType : ""}
                  labelId="idType"
                  name="idType"
                  onChange={(event) => handleChangeDependent(event, "dropdown")}
                  variant="outlined"
                  error={formError.idType.isError}
                  helperText={formError.idType.errorMessage}
                  label="idType"
                  size="small"
                  sx={{
                    width: "100%",
                    //backgroundColor: "#dedede",
                    "& fieldset": {
                      border: "1px solid #dedede",
                    },
                    "& .MuiInputBase-input:focus": {
                      border: "2px solid #fcd25e", // focus
                    },
                    "& .css-k4qjio-MuiFormHelperText-root": {
                      backgroundColor: "#ffffff",
                      margin: "0px",
                      paddingLeft: "0.5rem",
                    },
                  }}
                >
                  {
                    idType?.map((type) => {
                      return(
                        <MenuItem key={type.name} value={type.name}>
                          {type.name}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
                {formError.idType.errorMessage && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {formError.idType.errorMessage}
                  </FormHelperText>
                )}
              </FormControl>

              <TextField
                size="small"
                name="nationalId"
                label={dependent.idType || "Id"}
                disabled={dependent.idType == null}
                id="outlined-basic"
                onChange={(event) => handleChangeDependent(event, "textfield")}
                variant="outlined"
                error={formError.nationalId.isError}
                helperText={formError.nationalId.errorMessage}
                value={dependent.nationalId ? dependent.nationalId : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  //backgroundColor: "#dedede",
                  "& fieldset": {
                    border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                }}
              /> */}

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat={
                    employeeDetails &&
                    employeeDetails.empData &&
                    employeeDetails.empData.company &&
                    employeeDetails.empData.company.dateFormat &&
                    employeeDetails.empData.company.dateFormat ||
                    "dd/MM/yyyy"
                  }
                  value={dependent.dateOfBirth}
                  // label="Date of Birth"
                  label={<FormattedMessage id="basicMedical.dateOfBirth" defaultMessage="Date Of Birth"/>}
                  name="dateOfBirth"
                  maxDate={new Date()}
                  onChange={(event) =>
                    handleChangeDependent(event, "birthdate")
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      helperText={formError.dateOfBirth.errorMessage}
                      size="small"
                      sx={{
                        pointerEvents: 'none',
                        '& .MuiOutlinedInput-root': {
                          'button': {
                            pointerEvents: 'all',
                          }},
                        my: 2,
                        width: "100%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        "& .MuiInputBase-input:focus": {
                          border: "2px solid #fcd25e", // focus
                        },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        // ...requiredStyled
                      }}
                      {...params}
                      error={formError.dateOfBirth.isError}
                    />
                  )}
                />
              </LocalizationProvider>

              <TextField
                size="small"
                name="nationality"
                // label="Nationality"
                label={<FormattedMessage id="basicMedical.nationality" defaultMessage="Nationality"/>}
                id="outlined-basic"
                onChange={(event) =>
                  handleChangeDependent(event, "textfield")
                }
                variant="outlined"
                error={formError.nationality.isError}
                helperText={formError.nationality.errorMessage}
                value={dependent.nationality ? dependent.nationality : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  //backgroundColor: "#dedede",
                  "& fieldset": {
                      border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                  // ...requiredStyled
                }}
              />

      {/* <Stack
        direction='row'
        alignItems='center'
      >
        <Stack sx={{width: '50%'}} spacing={5}>
          <FormLabel id='demo-row-radio-buttons-group-label'>
            <Stack direction='row'>
              <Stack fontWeight={500}>Is dependant working ?</Stack>
            </Stack>
          </FormLabel>
        </Stack>
        <Stack sx={{width: '50%'}} direction='row' alignItems='center'>
          <RadioGroup
            value={dependent?.isRelativeWorking}
            onChange={(event) => handleChangeDependent(event, "checkbox")}
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='isRelativeWorking'
          >
            <FormControlLabel value={true} control={<Radio />} label='Yes' />
            <FormControlLabel value={false} control={<Radio />} label='No' />
          </RadioGroup>
          {formError?.isRelativeWorking?.isError && (
            <FormHelperText className='Mui-error'>
              {formError?.isRelativeWorking?.errorMessage}
            </FormHelperText>
          )}
        </Stack>
      </Stack> */}

      {/* {
        dependent?.isRelativeWorking &&  */}
        <Stack
        direction='row'
        alignItems='center'
      >
        <Stack sx={{width: '50%'}} spacing={5}>
          <FormLabel id='demo-row-radio-buttons-group-label'>
            <Stack direction='row'>
              <Stack fontWeight={500}>Is dependant working in {empCompany} ?</Stack>
            </Stack>
          </FormLabel>
        </Stack>
        <Stack sx={{width: '50%'}} direction='row' alignItems='center'>
          <RadioGroup
            value={dependent?.isRelativeWorkingInNium}
            onChange={(event) => handleChangeDependent(event, "checkbox")}
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='isRelativeWorkingInNium'
          >
            <FormControlLabel value={true} control={<Radio checked={dependent?.isRelativeWorkingInNium !== null && dependent?.isRelativeWorkingInNium} />} label='Yes' />
            <FormControlLabel value={false} control={<Radio checked={dependent?.isRelativeWorkingInNium !== null && !dependent?.isRelativeWorkingInNium} />} label='No' />
          </RadioGroup>
          {formError?.isRelativeWorkingInNium?.isError && (
            <FormHelperText className='Mui-error'>
              {formError?.isRelativeWorkingInNium?.errorMessage}
            </FormHelperText>
          )}
        </Stack>
      </Stack>     
      {/* }     */}
      {
        dependent?.isRelativeWorkingInNium && 
        <TextField
                size="small"
                name="employeeIdOfRelative"
                // label="Employee-ID of Dependant"
                label={<FormattedMessage id="basicMedical.employee-IDofDependant" defaultMessage="Employee-ID of Dependant"/>}
                id="outlined-basic"
                onChange={(event) =>
                  handleChangeDependent(event, "textfield")
                }
                variant="outlined"
                error={formError.employeeIdOfRelative.isError}
                helperText={formError.employeeIdOfRelative.errorMessage}
                value={dependent.employeeIdOfRelative ? dependent.employeeIdOfRelative : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  //backgroundColor: "#dedede",
                  "& fieldset": {
                      border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                }}
              />
      }

            <div className="bottomButtonReq">
              <ButtonM text={<FormattedMessage id="common.button.NEXT" defaultMessage="NEXT"/>} onpress={() => handleValidateDependent()} />
              </div>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </div>
  );
};

export default AddDependent;
