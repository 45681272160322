import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ButtonM from "components/common/Button";
import { useRef, useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useSelector } from "react-redux";
import removeImgBtn from "../../../../assets/reimbuersement/close_remove_image_btn.png";

const UpdateDetailsD = () => {
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [imageInfos, setImageInfos] = useState([]);
  const progressInfosRef = useRef(null);

  const initialEmptyUploadDetails = {
    date: null,
    description: null,
    amount: null,
    file: null,
  };

  const initialFormErrorList = {
    date: { isError: false, errorMessage: "" },
    description: { isError: false, errorMessage: "" },
    amount: { isError: false, errorMessage: "" },
    file: { isError: false, errorMessage: "" },
  };

  const [uploadDetails, setUploadDetails] = useState(initialEmptyUploadDetails);
  const [formError, setFormError] = useState(initialFormErrorList);

  const handleChangeUploadDetails = async (event, fieldType) => {
    const tempUploadDetails = { ...uploadDetails };
    const tempError = JSON.parse(JSON.stringify(formError));
    if (fieldType === "textfield" || fieldType === "dropdown") {
      tempUploadDetails[event.target.name] = event.target.value;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    } else if (fieldType === "date") {
      tempUploadDetails.date = event;
      tempError.date.isError = false;
      tempError.date.errorMessage = "";
    } else if (fieldType == "amount") {
      tempUploadDetails[event.target.name] = event.target.value.replace(
        /[^0-9]/g,
        ""
      );
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    } else if (fieldType === "file") {
      let images = [];

      for (let i = 0; i < event.target.files.length; i++) {
        images.push(URL.createObjectURL(event.target.files[i]));
      }
      setImagePreviews(images);
      tempUploadDetails.file =
        event.target.files.length > 0 ? event.target.files : undefined;
      tempError.file.isError = false;
      tempError.file.errorMessage = "";
    }

    setUploadDetails(tempUploadDetails);
    setFormError(tempError);
  };
  // console.log("uploadDetails", uploadDetails);

  const handleValidateUploadDetails = async () => {
    let isValid = true;
    const tempError = JSON.parse(JSON.stringify(formError));
    const tempUploadDetails = JSON.parse(JSON.stringify(uploadDetails));

    if (
      tempUploadDetails.date === null ||
      tempUploadDetails.date.trim() === ""
    ) {
      tempError.date.isError = true;
      tempError.date.errorMessage = "Please select date";
      isValid = false;
    }
    if (
      tempUploadDetails.description === null ||
      tempUploadDetails.description.trim() === ""
    ) {
      tempError.description.isError = true;
      tempError.description.errorMessage = "Please select description";
      isValid = false;
    }
    if (
      tempUploadDetails.amount === null ||
      tempUploadDetails.amount.trim() === ""
    ) {
      tempError.amount.isError = true;
      tempError.amount.errorMessage = "Please select amount";
      isValid = false;
    }
    if (
      tempUploadDetails.file === null ||
      tempUploadDetails.file.trim() === "" ||
      tempUploadDetails.file.trim() === undefined
    ) {
      tempError.file.isError = true;
      tempError.file.errorMessage = "Please select file";
      isValid = false;
    }

    //do Submit
    if (isValid) {
      // if (isEdit) {
      //   updateDependent();
      // } else {
      submitUploadDetails();
      // }
    } else {
      setFormError(tempError);
    }
  };

  const selectFiles = (event) => {
    let images = [];

    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]));
    }

    setSelectedFiles(event.target.files);
    setImagePreviews(images);
    setProgressInfos({ val: [] });
    setMessage([]);
  };

  function removeFileFromFileList(index) {
    const dt = new DataTransfer();
    const tempUploadDetails = { ...uploadDetails };
    const { file } = tempUploadDetails;
    for (let i = 0; i < file.length; i++) {
      const sFile = file[i];
      if (index !== i) dt.items.add(sFile); // here you exclude the file. thus removing it.
    }
    tempUploadDetails.file = dt.files; // Assign the updates list
    setUploadDetails(tempUploadDetails);
  }

  const dltImgPreview = (index) => {
    let tempImgPrvws = JSON.parse(JSON.stringify(imagePreviews));

    if (index > -1) {
      // only splice array when item is found
      tempImgPrvws.splice(index, 1);
      setImagePreviews(tempImgPrvws);
    }
  };

  const submitUploadDetails = () => {};

  // console.log(Object.values(uploadDetails), "Upload Details Form");

  return (
    <div className="uploadDetailsReimbursement m-4">
      <div className="mainTitle">Please upload your document</div>
      <div className="formDiv">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            inputFormat={employeeDetails?.company?.dateFormat || "dd/MM/yyyy"}
            value={uploadDetails.date}
            label="Date"
            name="date"
            onChange={(event) => handleChangeUploadDetails(event, "date")}
            renderInput={(params) => (
              <TextField
                fullWidth
                helperText={formError.date.errorMessage}
                size="small"
                error={formError.date.isError}
                {...params}
              />
            )}
          />
        </LocalizationProvider>

        <TextField
          fullWidth
          multiline
          rows={3}
          id="fullWidth"
          size="small"
          name="description"
          label="Description"
          onChange={(event) => handleChangeUploadDetails(event, "textfield")}
          variant="outlined"
          error={formError.description.isError}
          helperText={formError.description.errorMessage}
          value={uploadDetails.description ? uploadDetails.description : ""}
        />

        <TextField
          fullWidth
          id="fullWidth"
          size="small"
          name="amount"
          label="Amount"
          onChange={(event) => handleChangeUploadDetails(event, "amount")}
          variant="outlined"
          error={formError.amount.isError}
          helperText={formError.amount.errorMessage}
          value={uploadDetails.amount ? uploadDetails.amount : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {employeeDetails?.company?.currency}
              </InputAdornment>
            ),
          }}
        />
        <div className="updoctitle">Upload document</div>
        <input
          //   accept="image/*"
          //   className={classes.input}
          style={{ display: "none" }}
          id="file"
          name="file"
          onChange={(event) => handleChangeUploadDetails(event, "file")}
          // onChange={selectFiles}
          multiple
          type="file"
        />
        <label className="raised-button-file" htmlFor="file">
          <Button variant="raised" component="span" className="uploadtitle">
            {imagePreviews.length > 0 ? (
              <p
                style={{
                  color: "#1A4D2E",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                }}
              >
                Upload successfully
              </p>
            ) : (
              <AiOutlineCloudUpload />
            )}
          </Button>
        </label>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "19px",
          }}
        >
          <div style={{ width: "65px", height: "auto", position: "relative" }}>
            {uploadDetails.file && (
              <img
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "0",
                  right: "-6px",
                }}
                onClick={(event) => handleChangeUploadDetails(event, "file")}
                src={removeImgBtn}
                alt="rmvImgBtn"
              />
            )}
            <img
              src={
                uploadDetails.file != null &&
                URL.createObjectURL(uploadDetails.file)
              }
              alt="noimg"
            />
          </div>
          {uploadDetails.file && (
            <p
              style={{
                marginLeft: "16px",
                textDecorationLine: "underline",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "rgba(0, 0, 0, 0.37)",
              }}
            >
              Upload again
            </p>
          )}
        </div> */}
        {imagePreviews && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "19px",
            }}
          >
            {imagePreviews.map((img, i) => {
              return (
                <div
                  key={`imgPreviewCont${i}`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "1rem",
                    width: "65px",
                    height: "auto",
                    position: "relative",
                  }}
                >
                  <img
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "-4px",
                      right: "-9px",
                    }}
                    onClick={(event) => {
                      dltImgPreview(i);
                      removeFileFromFileList(i);
                    }}
                    src={removeImgBtn}
                    alt="rmvImgBtn"
                  />
                  <img className="preview" src={img} alt={"image-" + i} />
                  {imagePreviews.length > 0 && i === 0 && (
                    <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "155%",
                        whiteSpace: "nowrap",
                        transform: "translate(-50%,-50%)",
                        marginLeft: "16px",
                        cursor: "pointer",
                        textDecorationLine: "underline",
                        display: "inline-block",
                        width: "auto",
                        fontSize: "12px",
                        lineHeight: "18px",
                        color: "rgba(0, 0, 0, 0.37)",
                      }}
                      onClick={(event) => {
                        setImagePreviews([]);
                        uploadDetails.file = null;
                        document.getElementById("file").click();
                      }}
                    >
                      Upload again
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        )}
        {Object.values(uploadDetails).every((x) => x !== null) && (
          <ButtonM
            text="UPDATE DETAILS"
            onpress={handleValidateUploadDetails}
          />
        )}
      </div>
    </div>
  );
};

export default UpdateDetailsD;
