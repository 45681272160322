import { useEffect, useRef } from "react";
import ButtonM from "../../../common/Button";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";
const parse = require('html-react-parser');

const DisplayPlan = () => {
  const history = useHistory();

  const selectedPlan = useSelector((state) => state?.planReducer?.planData);

  useEffect(() => {
    if(Object.keys(selectedPlan)?.length == 0){
        history.push("/plans");
      }
  }, [selectedPlan]);

  return (
    <div className="displayPlanMain">
      <h4 className="page-heading-navbar">{selectedPlan?.displayName}</h4>
      <PageHeaderForPlans />
      <div className="data">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <div className="description w-full mt-4 mx-4">
            {parse(selectedPlan?.description)}
            </div>
            <div className="bottomButtonReq">              
              <ButtonM
                text="NEXT"
                onpress={() => history.push("/plans")}
                // disabled={ true }
                // loading={enrollMentLoader}
                // bgcolor={true ? "gray" : "" }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayPlan;
