import { FormControlLabel, Switch } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import notificationsMenu from "../../../../styles/notificationsMenu.scss";
import { actions as notificationActions } from "./reedux/action";
import { BsCircleFill, BsFillCheckCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import moment from "moment";
import api from "../../../../api/index";
import { Skeleton } from "@mui/material";
import EmptyNotificationImg from "../../../../assets/header/no-notifications.jpg";

const NotificationsMenu = ({ func }) => {
  const wrapperRef = useRef(null);
  const employeeDetails = useSelector((state) => state && state.empData);

  const { loader, Notifications, NotificationCount } = useSelector(
    ({ NotificationReducer }) => NotificationReducer
  );
  const dispatch = useDispatch();

  const [notiFicationState, setNotificationState] = useState([]);
  const [type, setType] = useState(
    parseInt(NotificationCount) > 0 ? "UNREAD" : "ALL"
  );
  const [readState, setReadState] = useState(null);
  const [isChecked, setIsChecked] = useState(parseInt(NotificationCount) > 0);
  useEffect(() => {
    let object = {
      type,
      employeeId: employeeDetails?.empData?.id,
    };

    // dispatch(notificationActions.GetNotificatiionrequest(object));
  }, [type, NotificationCount]);

  const handelChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setType("UNREAD");
    } else {
      setType("ALL");
    }
  };

  useEffect(() => {
    if (!loader) {
      const sortNotifications = Notifications.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdDate) - new Date(a.createdDate);
      });
      
      setNotificationState(sortNotifications);
    }
  }, [loader]);

  const markRead = () => {
    let id = [];

    notiFicationState.forEach((value) => {
      id.push(value.id);
    });

    updateNotification(id);
  };

  const updateNotification = (idArray) => {
    let mainArray = [...idArray];

    const body = {
      ids: mainArray,
    };
    api
      .UpdateNotification(body)
      .then((res) => {
        // res &&
        //   toast.success(
        //     <div className="flex flex-row">
        //       <BsFillCheckCircleFill
        //         style={{ width: "2rem", height: "2rem" }}
        //       />
        //       &nbsp;&nbsp;Added Successfully
        //     </div>
        //   );

        let object = {
          type,
          employeeId: employeeDetails?.empData?.id,
        };

        // dispatch(notificationActions.GetNotificatiionrequest(object));
      })
      .catch((error) => {
        toast.error(
          <div className="flex flex-row">
            <BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp; {JSON.stringify(error)}
          </div>
        );
      });

    
  };

  useEffect(() => {
    if (type == "ALL") {
      const value = notiFicationState.every((val) => {
        return val.read == true;
      });
      setReadState(value);
    } else {
      const value = notiFicationState.every((val) => {
        return val.read == false;
      });
      setReadState(value);
    }
  }, [notiFicationState]);

  

  function HandleOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        const element = document.getElementById("notification_btn_wrpr");
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.target !== element && !element.contains(event.target)) {
            func();
          }
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  HandleOutsideClick(wrapperRef);

  return (
    // <div id="notification_li">
    //   <span id="notification_count">3</span>

    <div id="notificationContainer" ref={wrapperRef}>
      <div className="notfCont">
        <div id="notificationTitle">
          <p className="titleText">Notifications</p>

          <div className="osuDiv">
            <p className="osuText">Only show unread</p>
            <Switch onChange={handelChange} checked={isChecked} />
          </div>
        </div>
        {loader ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "300px", xs: "70px" } }}
          />
        ) : notiFicationState?.length > 0 ? (
          <div id="notificationsBody" className="notifications">
            <div className="notfDCard">
              <div className="notfDayCont">
                <p>Today</p>

                {type == "UNREAD" && readState == true && (
                  <p style={{ cursor: "pointer" }} onClick={markRead}>
                    Mark all as read
                  </p>
                )}

                {readState == false && (
                  <p style={{ cursor: "pointer" }} onClick={markRead}>
                    Mark all as read
                  </p>
                )}
              </div>

              {notiFicationState.map((values, index) => {
                return (
                  <section key={index} className="notfCardCont">
                    <section
                      className="notfCard"
                      onClick={() => updateNotification([values.id])}
                    >
                      <div className="notfAvtrImg">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMU0H-PL0L_56dy_67K1Z3oeQ4xqenwnl0RQ&usqp=CAU"
                          alt="dummyImg"
                        />
                      </div>
                      <p
                        className="notfCardTxt"
                        style={!values?.read ? { fontWeight: "600" } : {}}
                      >
                        <p className="titleOfNtf"> {values?.title}</p>
                        <span className="msgOfNtf">
                          {values?.message}
                          <span>{moment(values?.createdDate?.slice(0,10)).calendar()}</span>
                        </span>
                      </p>
                      {!values?.read && (
                        <BsCircleFill
                          style={{ color: "#1976d2", fontSize: "10px" }}
                        />
                      )}
                    </section>
                    <div className="notfCardAddnlContnt">
                      <div className="blnk"></div>
                      {/* <div className="cntntArea">
                          <div className="addtnlCntnImg">
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMU0H-PL0L_56dy_67K1Z3oeQ4xqenwnl0RQ&usqp=CAU"
                              alt="dummyImg"
                            />
                          </div>
                          <p className="notfCardAddnlTxt">
                            +2 updates from Lorem ipsum dolor.
                          </p>
                        </div> */}
                    </div>
                  </section>
                );
              })}

              {/* <section className="notfCardCont">
                  <section className="notfCard">
                    <div className="notfAvtrImg">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSj85e4USXfd6QpMPoY-UtBPMUgktJrvFvVGg&usqp=CAU"
                        alt="dummyImg2"
                      />
                    </div>
                    <p className="notfCardTxt">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Tempore architecto sit delectus quod temporibus cumque.
                      <span>9 hours ago</span>
                    </p>
                  </section>
                  <div className="notfCardAddnlContnt">
                    <div className="blnk"></div>
                    <div className="cntntArea">
                      <div className="addtnlCntnImg">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2FVAFJ42YuREL6dnf7aHU0C32WPHi8U7nRQ&usqp=CAU"
                          alt="dummyImg"
                        />
                      </div>
                      <p className="notfCardAddnlTxt">
                        +3 updates from Lorem ipsum dolor.
                      </p>
                    </div>
                  </div>
                </section>
                <section className="notfCardCont">
                  <section className="notfCard">
                    <div className="notfAvtrImg">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCnUu_2lKHdbNCyoHd_U8XCpTrmGgymPdn7w&usqp=CAU"
                        alt="dummyImg3"
                      />
                    </div>
                    <p className="notfCardTxt">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Tempore architecto sit delectus quod temporibus cumque.
                      <span>16 hours ago</span>
                    </p>
                  </section>
                  <div className="notfCardAddnlContnt">
                    <div className="blnk"></div>
                    <div className="cntntArea">
                      <div className="addtnlCntnImg">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMU0H-PL0L_56dy_67K1Z3oeQ4xqenwnl0RQ&usqp=CAU"
                          alt="dummyImg"
                        />
                      </div>
                      <p className="notfCardAddnlTxt">
                        +1 update from Lorem ipsum dolor.
                      </p>
                    </div>
                  </div>
                </section>
                <section className="notfCardCont">
                  <section className="notfCard">
                    <div className="notfAvtrImg">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2FVAFJ42YuREL6dnf7aHU0C32WPHi8U7nRQ&usqp=CAU"
                        alt="dummyImg4"
                      />
                    </div>
                    <p className="notfCardTxt">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Tempore architecto sit delectus quod temporibus cumque.
                      <span>23 hours ago</span>
                    </p>
                  </section>
                  <div className="notfCardAddnlContnt">
                    <div className="blnk"></div>
                    <div className="cntntArea">
                      <div className="addtnlCntnImg">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2FVAFJ42YuREL6dnf7aHU0C32WPHi8U7nRQ&usqp=CAU"
                          alt="dummyImg"
                        />
                      </div>
                      <p className="notfCardAddnlTxt">
                        +7 updates from Lorem ipsum dolor.
                      </p>
                    </div>
                  </div>
                </section> */}
            </div>
          </div>
        ) : (
          <div>
            <img
              style={{ marginTop: "50px" }}
              src={EmptyNotificationImg}
              alt="no-message"
            />
          </div>
        )}

        {/* <div id="notificationFooter">
        <button>See All</button>
      </div> */}
      </div>
    </div>
    // </div>
  );
};

export default React.memo(NotificationsMenu);
