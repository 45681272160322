import React, { useEffect } from 'react'
import PolicyCover from 'components/common/PolicyCover'
import CoverIcon from "../../../assets/events/event.png";
import ButtonM from "../../common/Button";
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { actions as companyPlanActions } from "./redux/actions";
import { FormattedMessage } from 'react-intl';

const Event = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);

    const history = useHistory();

    const employeeDetails = state && state.empData;

    // const empGroup = state && state.empGroup && state.empGroup.empGroup;

    const companyDetails = state && state.empData && state.empData.empData && state.empData.empData.company;

    const { displayName } = companyDetails;

    // useEffect(() => {
    //     getEventList();
    // }, [])

    // const getEventList = () => {
    //     dispatch(companyPlanActions.CompanyPlanDataRequest({
    //         companyId: companyDetails?.id,
    //     }))
    // }


    const handelClickFunction = () => {
        history.push("/event/event-report");
    }
    return (
        <div className='learningPage'>
            <div className="page-heading-navbar left">
                <h4 >{displayName}</h4>
            </div>


            <PolicyCover
                policyIcon={CoverIcon}
                // title={`You can make changes after certain life events.`}
                title={<FormattedMessage id="reportEvent.title" defaultMessage="You can make changes after certain life events."/>}
                // description1={"Typically you can enroll when you first become eligible or during an annual renewal period.  If you need to enroll or make changes at other times, you may do so if you experience certain life events such as getting married or having a child."}
                description1={<FormattedMessage id="reportEvent.description" defaultMessage="Typically you can enroll when you first become eligible or during an annual renewal period.  If you need to enroll or make changes at other times, you may do so if you experience certain life events such as getting married or having a child."/>}

            // description3={`Click next to learn more about how this benefit works and to enroll.`}
            />

            <div className="bottomButtonReq">
                <ButtonM
                //  text="NEXT"
                 text={<FormattedMessage id="common.button.NEXT" defaultMessage="NEXT"/>}
                 onpress={handelClickFunction} />
            </div>
        </div>
    )
}

export default Event