import './loader.scss';

const Loader = ({ size }) => {
    return (
        <div className="loader-holder">
            <div className="preloader" style={{ width: `${size}px`, height: `${size}px` }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    );
};

export default Loader;