const { createAction } = require('redux-actions');
const PLAN_DATA_REQUEST = 'BENEVOLVE/PLAN_DATA_REQUEST';
const PLAN_DATA_REQUEST_SUCCESS = 'BENEVOLVE/PLAN_DATA_REQUEST_SUCCESS';
const PLAN_DATA_REQUEST_FAILED = 'BENEVOLVE/PLAN_DATA_REQUEST_FAILED'

const ENROLLED_PLAN_DATA_REQUEST = 'BENEVOLVE/ENROLLED_PLAN_DATA_REQUEST';
const ENROLLED_PLAN_DATA_REQUEST_SUCCESS = 'BENEVOLVE/ENROLLED_PLAN_DATA_REQUEST_SUCCESS';
const ENROLLED_PLAN_DATA_REQUEST_FAILED = 'BENEVOLVE/ENROLLED_PLAN_DATA_REQUEST_FAILED';

const FINAL_ENROLLED_PLANS = 'BENEVOLVE/FINAL_ENROLLED_PLANS';
const FINAL_ENROLLED_PLANS_SUCCESS = 'BENEVOLVE/FINAL_ENROLLED_PLANS_SUCCESS';
const FINAL_ENROLLED_PLANS_FAILED = 'BENEVOLVE/FINAL_ENROLLED_PLANS_FAILED';

const OPEN_EXIT_POPUP = 'BENEVOLVE/OPEN_EXIT_POPUP';
const CLOSE_EXIT_POPUP = 'BENEVOLVE/CLOSE_EXIT_POPUP';

const EXIT_PATH = 'BENEVOLVE/EXIT_PATH';

const LOOP_PLANS = 'BENEVOLVE/LOOP_PLANS';

const PlanDataRequest = createAction(PLAN_DATA_REQUEST);
const PlanDataRequestSuccess = createAction(PLAN_DATA_REQUEST_SUCCESS);
const PlanDataRequestFailed = createAction(PLAN_DATA_REQUEST_FAILED)

const EnrolledPlanDataRequest = createAction(ENROLLED_PLAN_DATA_REQUEST);
const EnrolledPlanDataRequestSuccess = createAction(ENROLLED_PLAN_DATA_REQUEST_SUCCESS);
const EnrolledPlanDataRequestFailed = createAction(ENROLLED_PLAN_DATA_REQUEST_FAILED);

const getFinalEnrollPlansRequest = createAction(FINAL_ENROLLED_PLANS);
const getFinalEnrollPlansRequestSuccess = createAction(FINAL_ENROLLED_PLANS_SUCCESS);
const getFinalEnrollPlansRequestFailed = createAction(FINAL_ENROLLED_PLANS_FAILED);

const openExitPopUp = createAction(OPEN_EXIT_POPUP);
const closeExitPopUp = createAction(CLOSE_EXIT_POPUP);

const setExitPath = createAction(EXIT_PATH);

const loopPlans = createAction(LOOP_PLANS);


export const actions = {
    PlanDataRequest,
    PlanDataRequestSuccess,
    PlanDataRequestFailed,

    EnrolledPlanDataRequest,
    EnrolledPlanDataRequestSuccess,
    EnrolledPlanDataRequestFailed,

    getFinalEnrollPlansRequest,
    getFinalEnrollPlansRequestSuccess,
    getFinalEnrollPlansRequestFailed,

    openExitPopUp,
    closeExitPopUp,

    setExitPath,

    loopPlans
};

export const types = {
    PLAN_DATA_REQUEST,
    PLAN_DATA_REQUEST_SUCCESS,
    PLAN_DATA_REQUEST_FAILED,

    ENROLLED_PLAN_DATA_REQUEST,
    ENROLLED_PLAN_DATA_REQUEST_SUCCESS,
    ENROLLED_PLAN_DATA_REQUEST_FAILED,
    
    FINAL_ENROLLED_PLANS,
    FINAL_ENROLLED_PLANS_SUCCESS,
    FINAL_ENROLLED_PLANS_FAILED,

    OPEN_EXIT_POPUP,
    CLOSE_EXIT_POPUP,

    EXIT_PATH,

    LOOP_PLANS
};

