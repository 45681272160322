import { useEffect, useState } from "react";
import Rectangle from "../../../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../../../assets/radioCheck.svg";
import ButtonM from "../../../../common/Button";
// import VoluntaryParentalModal from "../../ViewModal/index";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as VolutryActions } from "../../redux/actions";
import { actions as DependantActions } from "../../../BasicMedical/AddDependentAddress/redux/actions";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import { BiError } from "react-icons/bi";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";
import { getAllowedEmp, getcurrentDependant, getElligiblePlanDependant, getNoOfDependantEnrollFromThisPlan, getRelationName } from "components/utils/utils";

const classNames = require("classnames");

const FamilyTypeAddParents = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { selectedCategories, familySelectedCoverages, selectedPlanDependants } = useSelector((state) => state?.voluntryReducer);

  const selectedPlan = useSelector((state) => state?.planReducer?.planData);

  const [openModal, setOpenModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [elligiblePlanDependant, setElligiblePlanDependant] = useState([]);
  const [allowedEmp, setAllowedEmp] = useState(null);
  const [allowedCurrentEmpDependants, setAllowedCurrentEmpDependants] = useState(null);
  const [enrolledDependants, setEnrolledDependants] = useState(familySelectedCoverages || []);
  const [currentDependants, setCurrentDependants] = useState([]);
  const lifeEventConfiguratorDTO = useSelector((state) => state?.planReducer?.lifeEventConfiguratorDTO);

  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const empDependant = useSelector((state) => state?.dependents?.allDependents);
  const dependantLoader = useSelector((state) => state?.dependents?.loader);

  useEffect(() => {
    dispatch(
      DependantActions.GetDependentRequest({
        empId: employeeDetails && employeeDetails.id,
      })
    );
  }, []);

  useEffect(() => {
    if(selectedCategories?.length < 1){
    history.push("/voluntry");
  }
    
}, []);

useEffect(() => {  
  setCurrentDependants(getcurrentDependant(selectedCategories));
}, [selectedCategories]);

  Array.prototype.equals = function (array, strict) {
    if (!array) return false;

    if (arguments.length == 1) strict = true;

    if (this.length != array.length) return false;

    for (var i = 0; i < this.length; i++) {
      if (this[i] instanceof Array && array[i] instanceof Array) {
        if (!this[i].equals(array[i], strict)) return false;
      } else if (strict && this[i] != array[i]) {
        return false;
      } else if (!strict) {
        return this.sort().equals(array.sort(), true);
      }
    }
    return true;
  };

  useEffect(() => {
    if (currentDependants == "self") {
      !selectedPlan?.planOptOut && setEnrolledDependants(["Self"]);
    }
    setElligiblePlanDependant(getElligiblePlanDependant(selectedPlan, employeeDetails));
    
  }, [employeeDetails]);

  useEffect(() => {
    setAllowedEmp(getAllowedEmp(empDependant, elligiblePlanDependant, lifeEventConfiguratorDTO));
  }, [elligiblePlanDependant, empDependant]);

  useEffect(() => {
    getallowedCurrentEmpDependants();
  }, [allowedEmp]);

  const handleDependentSelection = (dep) => {
    if (dep == "Self") {
      enrolledDependants?.includes(dep)
        ? setEnrolledDependants(enrolledDependants?.filter((x) => x !== dep))
        : setEnrolledDependants([...enrolledDependants, dep]);
    } else {
      enrolledDependants?.filter((x) => x.id === dep.id).length > 0
        ? setEnrolledDependants(
            enrolledDependants?.filter((x) => x.id !== dep.id)
          )
        : setEnrolledDependants([...enrolledDependants, dep]);
    }
  };

  const getallowedCurrentEmpDependants = () => {
    let arr = [];
      arr = allowedEmp?.filter(
        (x) =>
          currentDependants?.includes(x.relation)
      );
    setAllowedCurrentEmpDependants(arr);
  };

  const handleAddDependent = (relation, type) => {
   history.push("/voluntry/family-add-dependant");
  };

  const handleNext = () => {
    dispatch(VolutryActions.setFamilySelectedCoverages(enrolledDependants));
    history.push("/voluntry/FamilyCoverageSelection");
  };

  const exceedPlanDeppendantLimit = () => {
    let exceed = false;
    selectedPlanDependants?.forEach((dep) => {
      if (getNoOfDependantEnrollFromThisPlan(dep, enrolledDependants) > dep.dependantLimit) {
        exceed = true;
      }
    });
    if(enrolledDependants?.length > selectedPlan?.totalLiveMaxLimit){
      exceed = true;
    }
    if(enrolledDependants?.length == 0) {
      exceed = true;
    }
    return exceed;
  };

  return (
    <div className="voluntryAddParentsPage">
      {
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={deleteLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }

      {selectedPlan && (
        <div>
          <h4 className="page-heading-navbar">{selectedPlan.displayName}</h4>
          <PageHeaderForPlans 
          // btnRedirect={() => setOpenModal(true)} 
          />
        </div>
      )}
      <div className="data">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            {/* <PageTitleAndDescription
              title={`Add ${currentNames === 'PIL' ? 'Parents in Law' : currentNames === 'self' ? `Your`:`${currentNames?.replace("-","s and ")}s`} Details`}
              description={`Details about your ${currentNames === 'PIL' ? 'Parents in Law' : currentNames === 'self' ? `${employeeDetails.firstName}`:`${currentNames?.replace("-"," and ")}`} are required before continuing.`}
            /> */}

            <span
              className="PageTitle-And-Description w-full mt-4 mx-4"
              style={{ fontSize: "1rem" }}
            >
              <span className="details">
                <span className="title mb-2">
                  Add{" "}
                  {!(currentDependants instanceof Array) ? (
                    currentDependants == "self" ? (
                      <span style={{ color: "#edaf00" }}>
                        {employeeDetails?.firstName}
                      </span>
                    ) : (
                      <span style={{ color: "#edaf00" }}>
                        {currentDependants}
                      </span>
                    )
                  ) : (
                    currentDependants?.map((item, index) => {
                      return (currentDependants.length - 1 === index) ? (
                        <span style={{ color: "#edaf00" }}> 
                        {" "} {index != 0 && "and "}{" "}
                        {getRelationName(item)}
                        </span>
                      ) : (
                        <span style={{ color: "#edaf00" }}>
                          {index !== 0 && ", "}
                          {getRelationName(item)}
                        </span>
                      );
                    })
                  )}
                </span>
              </span>
            </span>

            <div className="w-full">
              <div className="radioButtonOuter">
                {
                  dependantLoader ? 
                  <>
                  <Skeleton width={"48%"} height={69} />
                  <Skeleton width={"48%"} height={69} />
                  <Skeleton width={"48%"} height={69} />
                  <Skeleton width={"48%"} height={69} />
                  </>
                  :
                allowedCurrentEmpDependants?.map((dependent, i) => {
                  return (
                    <button
                      className={classNames("radioButton justify-between", {
                        active:
                          enrolledDependants?.filter(
                            (x) => x.id === dependent.id
                          ).length > 0,
                      })}
                      onClick={() => {
                        handleDependentSelection(dependent);
                      }}
                    >
                      <div className="flex flex-row justify-start capitalize">
                        <div className="radioOuter">
                          <img src={Rectangle} alt="" className="radio" />
                          <img
                            src={radioCheckIcon}
                            alt=""
                            className="radioCheck"
                          />
                        </div>
                        <div>
                          {dependent && dependent.firstName}{" "}
                          {dependent && dependent.lastName}
                        </div>
                      </div>
                    </button>
                  );
                })}

                {
                  //  dontWantEnroll === false &&
                  (enrolledDependants && enrolledDependants.length) >
                  (selectedPlan && selectedPlan.totalLiveMaxLimit) ? (
                    <p className="flex justify-center items-center mx-auto my-4" style={{color:"var(--warning)"}}>
                      {/* <BiError style={{ fontSize: "1.2rem" }} /> */}
                      This plan policy does not allow to add more than{" "}
                      {selectedPlan && selectedPlan.totalLiveMaxLimit}{" "}
                      dependents{" "}
                    </p>
                  ) : (
                    // dontWantEnroll === false &&
                    <p className="w-full">
                      {selectedPlanDependants &&
                        selectedPlanDependants.map((dep) => {
                          return (
                            // dep && dep.relation &&
                            // dep.relation.map((relation) => {
                            //   return (
                            <p className="w-full">
                              {
                                // getNoOfTimesRelationRepeated(relation)
                                getNoOfDependantEnrollFromThisPlan(dep, enrolledDependants) >
                                  dep.dependantLimit && (
                                  <p className="w-full justify-center flex items-center mx-auto my-4" style={{color:"var(--warning)"}}>
                                    {/* <BiError style={{ fontSize: "1.2rem" }} /> */}
                                    This plan policy does not allow to add more
                                    than {dep && dep.dependantLimit}{" "}
                                    {dep.relation.join(", ").replace("PIL", "Parents in law").replace("MIL", "Mother in law").replace("FIL", "Father in law")}
                                  </p>
                                )
                              }
                            </p>
                            //   );
                            // })
                          );
                        })}
                    </p>
                  )
                }

                {
                  !dependantLoader && 
                  <div className="w-full linksOuter mx-4 my-4">
                  <p className="addLink" onClick={() => handleAddDependent()}>
                    Add Dependent
                  </p>
                </div>
                }                

              </div>
            </div>
            <div className="bottomButtonReq">
              {/* <button className="next skip">I want to skip for now</button> */}
              <ButtonM
                text="NEXT"
                disabled={ exceedPlanDeppendantLimit() }
                bgcolor={ exceedPlanDeppendantLimit() ? "gray" : "" }
                onpress={() => handleNext()}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {openModal && <VoluntaryParentalModal setOpenModal={setOpenModal} />} */}
    </div>
  );
};

export default FamilyTypeAddParents;
