import ButtonM from "components/common/Button";
import { CurrencyFormat } from "components/utils/numberFormatter";
import { getRelationName } from "components/utils/utils";
import { useCallback, useEffect, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { actions as VolutryActions } from "../../../redux/actions";
var classNames = require("classnames");
// import { selectedPlan } from "../../../utils";

const BundleSingleDependant = ({ rateCards, currentNames, isEdit }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const selectedPlan = useSelector((state) => state?.planReducer?.planData);
    const [coveragePlan, setCoveragePlan] = useState(null);
    const [allowedRatecards, setallowedRatecards] = useState([]);
    const [selectedCoverageCard, setSelectedCoverageCard] = useState({});
    const [selectedDiv, setselectedDiv] = useState("");
    const [currentEnrollingId, setCurrentEnrollingId] = useState("");

    const { selectedCategories, progressArray, selectedRatecards, currentlyEnrolled, currentlyInprocessEnrolled, selectedCoverages, bundleEnrolls} = useSelector((state) => state?.voluntryReducer); 
    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const currency = employeeDetails?.company?.currency;
    const enrollingPlanDependantsArray = currentNames?.split("-");
    const deductionMethod = selectedPlan?.deductionMethod;
    const lifeEventConfiguratorDTO = useSelector((state) => state?.planReducer?.lifeEventConfiguratorDTO);
    const enrolledPlanData = useSelector((state) => state?.planReducer?.enrolledPlanData);

    // console.log("enrollingPlanDependantsArray",enrollingPlanDependantsArray,selectedCategories?.filter(x => !(progressArray?.includes(x)))[0]?.split("-"));
    
  useEffect(() => {       
    setSelectedCoverageCard({});
    getCoveragePlan();
    
  }, []);
  useEffect(() => {

    if(currentlyEnrolled?.length == currentlyInprocessEnrolled?.length){

        dispatch(VolutryActions.SetCurrentlyInprocessEnrolled([]));
        dispatch(VolutryActions.SetProgressArray([ ...progressArray, currentNames]));
        history.push('/voluntry/BundleIndividualPlanFinal');
    } 

    getAllowedRatecards();
    
  }, [coveragePlan, currentlyInprocessEnrolled]);

  Array.prototype.equals = function (array, strict) {
    if (!array)
        return false;
  
    if (arguments.length == 1)
        strict = true;
  
    if (this.length != array.length)
        return false;
  
    for (var i = 0; i < this.length; i++) {
        if (this[i] instanceof Array && array[i] instanceof Array) {
            if (!this[i].equals(array[i], strict))
                return false;
        }
        else if (strict && this[i] != array[i]) {
            return false;
        }
        else if (!strict) {
            return this.sort().equals(array.sort(), true);
        }
    }
    return true;
  }

  const getCoveragePlan = () => {
    
      selectedPlan?.coveragePlans?.forEach(
        (x) => x?.employeeRoles?.forEach(
            (y) =>
              y.id ===
              (employeeDetails?.employeeRole?.id) &&
              setCoveragePlan(x)
          )
      );
  };

  const getAllowedRatecards = () => {
    // let arr = [];
    // if ((currentlyEnrolled[0] === "Self")) {
    //     const arates = rateCards.filter(
    //         (x) =>
    //           (x?.sumInsured >= coveragePlan?.minCoverage) &&
    //           (x?.sumInsured <= coveragePlan?.maxCoverage) 
    //       );
          
    //   arr = arates.filter(
    //     (x) =>
    //       ((x.relationship?.includes("Self")) || (x.relationship?.includes("NA"))) &&
    //       ((x.gender == employeeDetails.gender) || (x.gender == "NA")) &&
    //       isSelfAge(x,employeeDetails)
    //   );
    // }else{
        // arr = rateCards.filter(
        //     (x) =>
        //       ((x.relationship?.includes(currentlyEnrolled[currentlyInprocessEnrolled?.length]?.relation)) || (x.relationship?.includes("NA"))) &&
        //       ((x.gender == currentlyEnrolled[currentlyInprocessEnrolled?.length]?.gender) || (x.gender == "NA")) &&
        //       isSelfAge(x, currentlyEnrolled[currentlyInprocessEnrolled?.length])
        //   );
    // }
    rateCards.sort((a, b) => a.sumInsured - b.sumInsured);
    setallowedRatecards(rateCards);
  }

  // function getAge(dateString) {
  //   var today = new Date();
  //   var birthDate = new Date(dateString);
  //   var age = today.getFullYear() - birthDate.getFullYear();
  //   var m = today.getMonth() - birthDate.getMonth();
  //   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
  //     age--;
  //   }
  //   return age;
  // }

  // const isSelfAge = (rateCard, person) => {
  //   let isAge = false;

  //   if (rateCard.ageCondition == "<") {
  //     if (getAge(person.dateOfBirth) < rateCard.age) {
  //       isAge = true;
  //     }
  //   }
  //   if (rateCard.ageCondition == "<=") {
  //     if (getAge(person.dateOfBirth) <= rateCard.age) {
  //       isAge = true;
  //     }
  //   }
  //   if (rateCard.ageCondition == ">") {
  //     if (getAge(person.dateOfBirth) > rateCard.age) {
  //       isAge = true;
  //     }
  //   }
  //   if (rateCard.ageCondition == ">=") {
  //     if (getAge(person.dateOfBirth) >= rateCard.age) {
  //       isAge = true;
  //     }
  //   }
  //   if (rateCard.ageCondition == "=") {
  //     if (getAge(person.dateOfBirth) == rateCard.age) {
  //       isAge = true;
  //     }
  //   }
  //   if (rateCard.ageCondition == "!=") {
  //     if (getAge(person.dateOfBirth) != rateCard.age) {
  //       isAge = true;
  //     }
  //   }
  //   if (rateCard.ageCondition == "NA") {
  //     isAge = true;
  //   }

  //   return isAge;
  // };

  const handleNext = () => {

  //   if(currentlyEnrolled[0] != "Self"){ 
    
    if(Object.keys(selectedCoverageCard)?.length === 0){
      toast.info(
        <div className='flex flex-row'>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;Please select coverage to continue.
        </div>
      );   
    }else{
    
    if(currentlyEnrolled?.length == currentlyInprocessEnrolled?.length){

        // dispatch(VolutryActions.setSelectedCoverages([ ...selectedCoverages, selectedCoverageCard]));
        if(selectedCoverageCard.dependantId != (null || "" || undefined)){
          // dispatch(VolutryActions.setSelectedCoverages([ ...selectedCoverages, selectedCoverageCard]));
          dispatch(VolutryActions.setBundleEnrollList([ ...bundleEnrolls, selectedCoverageCard]));
        }
    dispatch(VolutryActions.SetProgressArray([ ...progressArray, currentNames]));
    
    setSelectedCoverageCard({});
    history.push("/voluntry/BundleIndividualPlanFinal");
    }else{

      if(selectedCoverageCard.dependantId != (null || "" || undefined)){
        // dispatch(VolutryActions.setSelectedCoverages([ ...selectedCoverages, selectedCoverageCard]));
        dispatch(VolutryActions.setBundleEnrollList([ ...bundleEnrolls, selectedCoverageCard]));
        // setSelectedCoverageCard({});
      }
      dispatch(VolutryActions.SetCurrentlyInprocessEnrolled([ ...currentlyInprocessEnrolled, currentlyEnrolled[currentlyInprocessEnrolled?.length]]));
      setSelectedCoverageCard({});
    }}
  //  }else{
  //   let tempSelectedCoverageCard = selectedCoverageCard;
  //   console.log("tempSelectedCoverageCard",tempSelectedCoverageCard)
  //   tempSelectedCoverageCard.name = employeeDetails.firstName;        
  //   tempSelectedCoverageCard.dependantId = employeeDetails.id;     
  //   tempSelectedCoverageCard.relation = "Self";     
  //   console.log("tempSelectedCoverageCard",tempSelectedCoverageCard)
  //   dispatch(VolutryActions.setSelectedCoverages([ ...selectedCoverages, tempSelectedCoverageCard]));
  //   dispatch(VolutryActions.SetProgressArray([ ...progressArray, currentNames]));
  //   history.push('/voluntry/add-parents');
  //  }
 
  };

  const getDependantSplit = (rateCard) => {
    let bundle = null;
    let PremiumToBePaid = null;
    
    selectedPlan?.planDependants?.forEach((x) => {
         if(x.relation?.includes(selectedCategories[progressArray.length])){
          bundle = x.bundleFlatFees;
         }
    })
    
    const twoLifeBundle = bundle?.filter(x => x.life == 1)[0];
    const RC = rateCard?.bundlePremium?.filter(x => x.life == 1)[0] ;
    
    if(deductionMethod == "Percent"){
      
      PremiumToBePaid = ( RC?.value * twoLifeBundle?.value)/100 ;
    }
    return CurrencyFormat(PremiumToBePaid,currency);
  };

  const getDependantId = () => {
    let id = null;
    selectedPlan?.planDependants?.forEach((x) => {
         if(x.relation?.equals(isEdit ? selectedCategories?.filter(x => !(progressArray?.includes(x)))[0]?.split("-") : enrollingPlanDependantsArray,false)){
            id = x.id;
         }
    })
    return id;
  };
// console.log("currentlyEnrolled",currentlyEnrolled)

  // this will trigger only when the user is editing in already enrolled plan
  // to auto select the coverage(previously selected coverage)
  // so the user will know what was the coverage seleced for the same dependants
  useEffect(() => {

    if(lifeEventConfiguratorDTO != null && Object.keys(enrolledPlanData)?.length > 0){
       if(enrolledPlanData?.dependantEnrollments?.length > 0){
        let alreadtEnrolled = [];
        // currentlyEnrolled?.forEach(x => {
          enrolledPlanData?.dependantEnrollments?.forEach(y => {
            if((currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id === y?.dependants?.id) && (y?.life === 1)){
              alreadtEnrolled.push(y);
            }
          })
        // });
        if((alreadtEnrolled?.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i))?.length > 0){
          // console.log("alreadtEnrolled",alreadtEnrolled?.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i));
          const ratecard = allowedRatecards?.filter(x => x.sumInsured === alreadtEnrolled[0]?.dependentCoverage)[0];
          let selected = {
            name:currentlyEnrolled[currentlyInprocessEnrolled?.length]?.firstName,
            dependantId:currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id,
            planDependantId:getDependantId(),
            relation:currentlyEnrolled[currentlyInprocessEnrolled?.length]?.relation,
            coverageId:ratecard?.id,
            amount:ratecard?.sumInsured,
            category: isEdit ? selectedCategories?.filter(x => !(progressArray?.includes(x)))[0] : selectedCategories[progressArray?.length],
            typeSelected:"individual"
           };

          setSelectedCoverageCard(selected);
          setselectedDiv(ratecard?.id);   
          setCurrentEnrollingId(currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id);  

        }
       }
    }

  }, [lifeEventConfiguratorDTO, enrolledPlanData, allowedRatecards]);

  const handleSelectCoverage = (ratecard) => {

    if(lifeEventConfiguratorDTO != null){
      if(lifeEventConfiguratorDTO?.modificationAllowed) {
      let selected = {
        name:currentlyEnrolled[currentlyInprocessEnrolled?.length]?.firstName,
        dependantId:currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id,
        planDependantId:getDependantId(),
        relation:currentlyEnrolled[currentlyInprocessEnrolled?.length]?.relation,
        coverageId:ratecard.id,
        amount:ratecard.sumInsured,
        category: isEdit ? selectedCategories?.filter(x => !(progressArray?.includes(x)))[0] : selectedCategories[progressArray?.length],
        typeSelected:"individual"
       };

      setSelectedCoverageCard(selected);
      setselectedDiv(ratecard.id);   
      setCurrentEnrollingId(currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id);
      }
    }else{
      let selected = {
        name:currentlyEnrolled[currentlyInprocessEnrolled?.length]?.firstName,
        dependantId:currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id,
        planDependantId:getDependantId(),
        relation:currentlyEnrolled[currentlyInprocessEnrolled?.length]?.relation,
        coverageId:ratecard.id,
        amount:ratecard.sumInsured,
        category: isEdit ? selectedCategories?.filter(x => !(progressArray?.includes(x)))[0] : selectedCategories[progressArray?.length],
        typeSelected:"individual"
       };

      setSelectedCoverageCard(selected);
      setselectedDiv(ratecard.id);   
      setCurrentEnrollingId(currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id);

    }

    

  }

  return (
    <div className="singleDependant w-full">
        <div
            className="w-full flex justify-between group mt-4 mb-5 bg-[var(--secondary)] px-4"
            style={{ paddingTop: 15, paddingBottom: 15 }}
          >
            <p className="text-lime-800 font-semibold text-xs">
              Individual: Each dependent has their own coverage limits.
              <p style={{color:"rgba(0, 0, 0, 0.37)"}}>This plan covers your{(currentlyEnrolled[0] != "Self") ? `${" "}${getRelationName(currentlyEnrolled[currentlyInprocessEnrolled?.length]?.relation)}` : "self"}</p>
            </p>
          </div>
      {allowedRatecards?.length == 0 ? (
        <div className="my-16 text-center"> No plan available </div>
      ) : (
        <>         

          <div className="w-full flex flex-row mt-4 relative">
            <div className="w-full mx-4">Coverage Amount</div>
            <div className="w-full mx-4">Employee Cost</div>
          </div>

          {allowedRatecards?.map((ratecard) => {
            return (
              <div
                key={ratecard.id}
                // className="w-full flex flex-row card mt-4 relative"
                className={classNames("w-full flex flex-row card mt-4 relative", {
                    activeCard: ((ratecard.id == selectedDiv) && (currentEnrollingId == currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id)),
                  })}
              >
                <div className="w-full flex justify-between items-center sm:mx-4 mr-2 cardInner">
                  <div className="left">
                    <p className="price">{CurrencyFormat(ratecard.sumInsured,currency)}</p>
                  </div>
                  <div className="right">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.0677 11.9929L18.818 7.75739L17.4061 9.17398L19.2415 11.0032L0.932469 11.0012L0.932251 13.0012L19.2369 13.0032L17.4155 14.8308L18.8321 16.2426L23.0677 11.9929Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
                <div className="w-full flex justify-between items-center sm:mx-4 ml-2 cardInner2">
                  <div className="left">
                    <p className="price">{
                     getDependantSplit(ratecard)
                     }</p>
                  </div>
                  
                  {
                    ((ratecard.id == selectedDiv) && (currentEnrollingId == currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id)) ? 
                       <BsFillCheckCircleFill style={{width:"2rem",height:"2rem",color:"var(--primary)"}} />
                       :
                       <button className="btn active" onClick={() => handleSelectCoverage(ratecard)}>Select</button>
                  }
                  
                  
                </div>
              </div>
            );
          })}
        </>
      )}

      <div className="w-full">
              <ButtonM text="NEXT" onpress={() => handleNext()} />
            </div>
    </div>
  );
};
export default BundleSingleDependant;
