import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from "@mui/material";
import { isEmptyNullUndefined } from "components/utils/utils";
import React, { useEffect, useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { RiCloseCircleLine } from "react-icons/ri";

const QueDialogPopup = ({
  openDialog,
  setOpenDialog,
  queDescriptionParent,
  setQueDescriptionParent,
  relationParent,
  relationPIL,
  setElectionChecked,
  queDescriptionPIL,
  setQueDescriptionPIL
}) => {
  const [descError, setDescError] = useState(false);
  const [showForParent, setShowForParent] = useState(false);
  const [showForPIL, setShowForPIL] = useState(false);
  const [actionToPil, setActionToPil] = useState(false);

  useEffect(() => {
    if(!isEmptyNullUndefined(relationParent)){
      setShowForParent(true);
    } else if(isEmptyNullUndefined(relationParent) && !isEmptyNullUndefined(relationPIL)){
      setShowForPIL(true);
    }
  }, [])

  useEffect(() => {
    if(!showForParent && actionToPil && !isEmptyNullUndefined(relationPIL)){
      // setShowForParent(false);
      setShowForPIL(true);
     }
  }, [showForParent])  

  const handleClose = () => {
    if(showForParent && !isEmptyNullUndefined(relationPIL)){
     setShowForParent(false);
     setActionToPil(true);
     setShowForPIL(true);
    } else if(showForParent && isEmptyNullUndefined(relationPIL)){
      setOpenDialog(false);
    }  else if(showForPIL){
      setOpenDialog(false);
    }    
  };

  const handleSubmit = () => {
    if (showForParent && isEmptyNullUndefined(queDescriptionParent)) {
      setDescError(true);
    } else if (showForPIL && isEmptyNullUndefined(queDescriptionPIL)) {
      setDescError(true);
    }  else {
      handleClose();
    }
  };

  const handleSelectOption = (selection) => {
    if(showForParent){
      setQueDescriptionParent(selection);
    }
    if(showForPIL){
      setQueDescriptionPIL(selection);
    }
    
  };

  return (
    <div className="parent-que-dialog">
      <Dialog
        open={openDialog}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <div className="flex justify-end"><FaRegWindowClose onClick={() => {setElectionChecked(false); handleClose()}} style={{fontSize:"1.5rem", cursor:"pointer", marginRight:"0.5rem", marginTop:"0.4rem"}} /></div> */}

        <div
          className="alert-dialog-title"
          style={{
            fontFamily: "poppins",
            color: "var(--primary)",
            fontSize: "1rem",
            fontWeight: "600",
            margin: "1rem",
          }}
        >
          We have noticed that you have not selected &nbsp;
          {showForParent && relationParent?.length > 0 &&
            relationParent?.map((rel, index) => {
              return (
                <span>
                  {rel}
                </span>
              );
            })}
          {showForPIL && relationPIL?.length > 0 &&
            relationPIL?.map((rel, index) => {
              return (
                <span>
                  {rel}
                </span>
              );
            })}
          &nbsp;please check the appropriate reason given below :
        </div>
        {/* <DialogContent> */}
        {/* <TextField
        //   fullWidth
          multiline
          rows={3}
          id="fullWidth"
          size="small"
          name="description"
          label="Enter your reason"
          onChange={(event) => {setQueDescriptionParent(event.target.value); setDescError(false)}}
          variant="outlined"
          error={descError}
          helperText={descError && "please enter your answer"}
          value={queDescriptionParent}
          sx={{margin:"0px 1rem"}}
        /> */}
        <Stack className="que-options" sx={{ marginLeft: "2rem" }}>
          <FormGroup sx={{ display: "inline" }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  checked={(showForParent && queDescriptionParent === "Deceased") || (showForPIL && queDescriptionPIL === "Deceased")}
                  onChange={() => {
                    handleSelectOption("Deceased");
                    setDescError(false);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              sx={{ marginRight: "0" }}
              // label="Deceased"
            />
            <span style={{ fontFamily: "poppins", color: "var(--primary)" }}>
              Deceased
            </span>
          </FormGroup>
          <FormGroup sx={{ display: "inline" }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  checked={(showForParent && queDescriptionParent === "Over 80 years") || (showForPIL && queDescriptionPIL === "Over 80 years")}
                  onChange={() => {
                    handleSelectOption("Over 80 years");
                    setDescError(false);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              sx={{ marginRight: "0" }}
              // label="Over 80 years"
            />
            <span style={{ fontFamily: "poppins", color: "var(--primary)" }}>
              Over 80 years
            </span>
          </FormGroup>
          <FormGroup sx={{ display: "inline" }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  checked={
                    (showForParent && queDescriptionParent === "Parents / Parents-in-law are Divorced") || (showForPIL && queDescriptionPIL === "Parents / Parents-in-law are Divorced")
                  }
                  onChange={() => {
                    handleSelectOption("Parents / Parents-in-law are Divorced");
                    setDescError(false);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              sx={{ marginRight: "0" }}
              // label="Parents / Parents-in-law are Divorced"
            />
            <span style={{ fontFamily: "poppins", color: "var(--primary)" }}>
              Parents / Parents-in-law are Divorced
            </span>
          </FormGroup>
        </Stack>
        {/* </DialogContent> */}
        <div
          style={{
            fontFamily: "poppins",
            color: "var(--primary)",
            fontSize: "1rem",
            margin: "1rem",
          }}
        >
          Note : For all other reason, both parents / parents-in-law need to be
          enrolled. You may click on BACK to resubmit your selections.
        </div>
        {descError && (
          <div style={{ color: "var(--warning)", margin: "0px auto" }}>
            Please select reason
          </div>
        )}
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <div style={{ margin: "0px auto" }}>
            <Button
              onClick={() => handleSubmit()}
              sx={{
                backgroundColor: "var(--primary)",
                color: "var(--secondary)",
                "&:hover": { backgroundColor: "var(--primary)" },
                fontFamily: "poppins",
                marginRight: "1rem",
              }}
              //  autoFocus
            >
              Submit
            </Button>
            <Button
              onClick={() => {
                setElectionChecked(false);
                handleClose();
              }}
              sx={{
                backgroundColor: "var(--secondary)",
                color: "var(--primary)",
                "&:hover": { backgroundColor: "var(--secondary)" },
                fontFamily: "poppins",
              }}
              //  autoFocus
            >
              Back
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QueDialogPopup;