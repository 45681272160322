import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import APIList from 'api';
import { actions as eventActions } from "./reedux/action"
import { useSelector } from 'react-redux';
import { Skeleton, Button, Input, FilledInput, Stack, Typography, Chip, Box, List, ListItem, ListItemIcon, TextField, InputAdornment } from "@mui/material";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { BsShield, BsShieldCheck, BsCalendarDate, BsFillCheckCircleFill, BsFillCalendarEventFill } from "react-icons/bs";
import { RiErrorWarningFill, RiShieldUserFill } from "react-icons/ri";
import moment from "moment";
import { GiBrutalHelm, GiCheckMark, GiLogicGateNxor, GiRosaShield, GiShieldEchoes } from "react-icons/gi";
import { MdDelete, MdEvent } from 'react-icons/md';
import { toast } from 'react-toastify';
import { isEmptyNullUndefined } from 'components/utils/utils';
import { FormattedMessage } from 'react-intl';
const EventLogs = () => {
  const dispatch = useDispatch();


  const { empData } = useSelector((store) => store?.empData)
  const { loader, EventLogs } = useSelector((store) => store?.EventLogs)

  const [eventLogs, setLogsData] = useState([]);

  useEffect(() => {
    if(!isEmptyNullUndefined(empData?.id)){
      dispatch(eventActions.EventLogsDataRequest({ id: empData?.id }));
    }
    
  }, [empData?.id]);

  useEffect(() => {
    if (!loader) {

      const sortedLogs = EventLogs?.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.eventDate) - new Date(a.eventDate);
      });


      setLogsData(sortedLogs)
    }
  }, [loader]);

  const deleteEvent = (id) => {
    
      APIList.eventDelete({Id:id})
      .then((res) =>{
        res &&
          toast.success(
         <div className="flex flex-row"><BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }}/>
           &nbsp;&nbsp;Deleted Successfully
         </div>          
         );
         dispatch(eventActions.EventLogsDataRequest({ id: empData?.id }))
     }).catch((err) => {
      toast.error(
        <div className="flex flex-row">
          <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
          &nbsp;&nbsp;
          {err?.message}            
        </div>
      )
    })
    
  }

  return (
    <div className='learningPage'>
      <div className="page-heading-navbar left">
        <h4 >
          {/* Event Logs */}
          <FormattedMessage id="eventLogs.pageTitle" defaultMessage="Event Logs "/>
        </h4>
      </div>
      {
        loader ? <Skeleton variant="rectangular" animation="wave" sx={{ height: { md: "130px", xs: "70px" } }} /> : <Box>
          <Box my={3}>
            {
              eventLogs.length > 0 ? (<VerticalTimeline layout="2-columns" lineColor={"var(--primary)"}>

                {eventLogs?.map((plan) => {
                  return (
                    <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      contentStyle={{ background: plan?.flag ? "var(--secondary)" : "var(--primary)", color: plan?.flag ? "var(--primary)" : "var(--secondary)" }}
                      contentArrowStyle={{
                        borderRight: plan?.flag ? "7px solid var(--secondary)" : "7px solid var(--primary)",
                      }}
                      // date="2011 - present"
                      iconStyle={{ background: plan?.flag ? "var(--secondary)" : "var(--primary)", color: plan?.flag ? "var(--primary)" : "var(--secondary)" }}
                      icon={<MdEvent />}
                    >
                      <h3 className="vertical-timeline-element-title">
                        {plan?.event?.name.toUpperCase()}
                      </h3>
                      <h5 className="vertical-timeline-element-subtitle">

                        {plan?.event?.eventType}
                      </h5>
                      <p>


                        <div className="flex items-center w-full flex justify-between">
                          <div className='flex'>
                          <BsCalendarDate style={{ width: "1.35rem", height: "1.35rem", marginRight: "0.5rem" }} />
                          {moment(plan?.eventDate?.slice(0,10)).format(`${empData?.company?.dateFormat?.toUpperCase() || "DD/MM/YYYY"} , hh:mm a`)}
                          </div>
                          {/* <div className="cursor-pointer" onClick={() => deleteEvent(plan?.id)} >
                          <MdDelete />
                          </div> */}
                        </div>


                      </p>
                    </VerticalTimelineElement>
                  );
                })}

                {/* <VerticalTimelineElement
                  // contentStyle={{ background: "rgb(16, 204, 82)", color: "var(--primary)" }}
                  iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
                  icon={<GiBrutalHelm />}
                /> */}
                {/* <h3 className="vertical-timeline-element-title text-center font-semibold">
  END
</h3> */}
                {/* </VerticalTimelineElement> */}
              </VerticalTimeline>) : <Typography m={3}>
                {/* No event logs available */}
                <FormattedMessage id="eventLogs.noEventLogsAvailable" defaultMessage="No event logs available"/>
              </Typography>
            }

          </Box>

        </Box>
      }
    </div>



  )
}

export default EventLogs