const { createAction } = require('redux-actions');
const EMPLOYEE_DATA_REQUEST = 'BENEVOLVE/EMPLOYEE_DATA_REQUEST';
const EMPLOYEE_DATA_REQUEST_SUCCESS = 'BENEVOLVE/EMPLOYEE_DATA_REQUEST_SUCCESS';
const EMPLOYEE_DATA_REQUEST_FAILED = 'BENEVOLVE/EMPLOYEE_DATA_REQUEST_FAILED';

const EMPLOYEE_ENROLLED_REQUEST = 'BENEVOLVE/EMPLOYEE_ENROLLED_REQUEST';
const EMPLOYEE_ENROLLED_REQUEST_SUCCESS = 'BENEVOLVE/EMPLOYEE_ENROLLED_REQUEST_SUCCESS';
const EMPLOYEE_ENROLLED_REQUEST_FAILED = 'BENEVOLVE/EMPLOYEE_ENROLLED_REQUEST_FAILED';

const GET_DE_ENROLL = 'BENEVOLVE/GET_DE_ENROLL';
const GET_DE_ENROLL_SUCCESS = 'BENEVOLVE/GET_DE_ENROLL_SUCCESS';
const GET_DE_ENROLL_FAILED = 'BENEVOLVE/GET_DE_ENROLL_FAILED';

const ELIGIBLE_PLAN_DATA_REQUEST = 'BENEVOLVE/ELIGIBLE_PLAN_DATA_REQUEST';
const ELIGIBLE_PLAN_DATA_REQUEST_SUCCESS = 'BENEVOLVE/ELIGIBLE_PLAN_DATA_REQUEST_SUCCESS';
const ELIGIBLE_PLAN_DATA_REQUEST_FAILED = 'BENEVOLVE/ELIGIBLE_PLAN_DATA_REQUEST_FAILED';

const GET_ID_TYPES = 'BENEVOLVE/GET_ID_TYPES';
const GET_ID_TYPES_SUCCESS = 'BENEVOLVE/GET_ID_TYPES_SUCCESS';
const GET_ID_TYPES_FAILED = 'BENEVOLVE/GET_ID_TYPES_FAILED';

const SET_PREF_LANGUAGE = 'BENEVOLVE/SET_PREF_LANGUAGE';

const PROFILE_PIC_CHANGE = 'BENEVOLVE/PROFILE_PIC_CHANGE';

const EmployeeDataRequest = createAction(EMPLOYEE_DATA_REQUEST);
const EmployeeDataRequestSuccess = createAction(EMPLOYEE_DATA_REQUEST_SUCCESS);
const EmployeeDataRequestFailed = createAction(EMPLOYEE_DATA_REQUEST_FAILED);

const EmployeeEnrolledRequest = createAction(EMPLOYEE_ENROLLED_REQUEST);
const EmployeeEnrolledRequestSuccess = createAction(EMPLOYEE_ENROLLED_REQUEST_SUCCESS);
const EmployeeEnrolledRequestFailed = createAction(EMPLOYEE_ENROLLED_REQUEST_FAILED);

const getDeEnroll = createAction(GET_DE_ENROLL);
const getDeEnrollSuccess = createAction(GET_DE_ENROLL_SUCCESS);
const getDeEnrollFailed = createAction(GET_DE_ENROLL_FAILED);

const EligiblePlanDataRequest = createAction(ELIGIBLE_PLAN_DATA_REQUEST);
const EligiblePlanDataRequestSuccess = createAction(ELIGIBLE_PLAN_DATA_REQUEST_SUCCESS);
const EligiblePlanDataRequestFailed = createAction(ELIGIBLE_PLAN_DATA_REQUEST_FAILED);

const getIdTypesRequest = createAction(GET_ID_TYPES);
const getIdTypesRequestSuccess = createAction(GET_ID_TYPES_SUCCESS);
const getIdTypesRequestFailed = createAction(GET_ID_TYPES_FAILED);

const setPrefLanguage = createAction(SET_PREF_LANGUAGE);

const isProfilePicChange = createAction(PROFILE_PIC_CHANGE);


export const actions = {
    EmployeeDataRequest,
    EmployeeDataRequestSuccess,
    EmployeeDataRequestFailed,

    EmployeeEnrolledRequest,
    EmployeeEnrolledRequestSuccess,
    EmployeeEnrolledRequestFailed,

    getDeEnroll,
    getDeEnrollSuccess,
    getDeEnrollFailed,

    EligiblePlanDataRequest,
    EligiblePlanDataRequestSuccess,
    EligiblePlanDataRequestFailed,

    getIdTypesRequest,
    getIdTypesRequestSuccess,
    getIdTypesRequestFailed,

    setPrefLanguage,

    isProfilePicChange,
};

export const types = {
    EMPLOYEE_DATA_REQUEST,
    EMPLOYEE_DATA_REQUEST_SUCCESS,
    EMPLOYEE_DATA_REQUEST_FAILED,

    EMPLOYEE_ENROLLED_REQUEST,
    EMPLOYEE_ENROLLED_REQUEST_SUCCESS,
    EMPLOYEE_ENROLLED_REQUEST_FAILED,

    GET_DE_ENROLL,
    GET_DE_ENROLL_SUCCESS,
    GET_DE_ENROLL_FAILED,

    ELIGIBLE_PLAN_DATA_REQUEST,
    ELIGIBLE_PLAN_DATA_REQUEST_SUCCESS,
    ELIGIBLE_PLAN_DATA_REQUEST_FAILED,

    GET_ID_TYPES,
    GET_ID_TYPES_SUCCESS,
    GET_ID_TYPES_FAILED,

    SET_PREF_LANGUAGE,

    PROFILE_PIC_CHANGE,
};
