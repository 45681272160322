import { useEffect, useRef, useState } from "react";
import leftArrowIcon from "../../../assets/leftArrow.svg";
import hamburger from "assets/hamberger.png";
import alertIcon from "../../../assets/alert.svg";
import coinIcon from "../../../assets/header/coin.png";
import arrowTopIcon from "../../../assets/header/arrowTop.png";
import userIcon from "../../../assets/user.svg";
import { useDispatch, useSelector } from "react-redux";
import { actions as EmployeeDataActions } from "./redux/actions";
import { useHistory } from "react-router-dom";
import { BsFillBellFill, BsGlobe } from "react-icons/bs";
import Sidebar from "./component/Sidebar";
import NotificationsMenu from "./Notifications";
import { actions as notificationActions } from "./Notifications/reedux/action";
import { actions as planPageAction } from "../Plans/redux/actions";
import { isEmptyNullUndefined, isShowExitPopup } from "components/utils/utils";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@mui/material";
import { GiEarthAmerica } from "react-icons/gi";
import ExitPopup from "../Exitpopup";
import APIList from "api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";

const Header = ({ routeName, setNotfcnIntrvlId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const employeeDetails = useSelector((state) => state && state.empData);
  const company = useSelector((state) => state?.empData?.empData?.company);
  const isPicChange = useSelector((state) => state?.empData?.isPicChange);
  const user = useSelector((state) => state && state.user && state.user.user);
  const { loader, Notifications, NotificationCount } = useSelector(({ NotificationReducer }) => NotificationReducer);
  const userLanguage = useSelector((state) => state?.empData?.language);
  const showExitPopup = useSelector((state) => state?.planReducer?.showExitPopup);
  const exitPath = useSelector((state) => state?.planReducer?.exitPath);
  const { employeeEnrolled, employeeEnrolledLoader } = useSelector((state) => state && state.empData);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [showCoinPopup, setShowCoinPopup] = useState(false);

  // const [open, setOpen] = useState(false);
  // const [selectedLang, setSelectedLang] = useState(userLanguage);
  // const [prefLang, setPrefLang] = useState(userLanguage);
  const [isLangSelectOpen, setIsLangSelectOpen] = useState(false);
  
  const [exitPopup, setExitPopup] = useState(false);


  const userToken = useSelector(
    (state) => state && state.user && state.user.userToken
  );
  
  useEffect(() => {
    setExitPopup(showExitPopup);
  }, [showExitPopup])
  
  // useEffect(() => {
  //  dispatch(EmployeeDataActions.setPrefLanguage({lang:prefLang}));
  // }, [prefLang])
  

  // useEffect(() => {
  //   if (user != null && user.email != undefined) {
  //     const email = user.email;
  //     dispatch(
  //       EmployeeDataActions.EmployeeDataRequest({
  //         emailId: email,
  //       })
  //     );
  //   }
  // }, [user]);
  useEffect(() => {
    if (isEmptyNullUndefined(userToken)) {
      history.push("/login");
    }else{
      dispatch(
        EmployeeDataActions.EmployeeDataRequest()
      )
    }
  }, []);
  useEffect(() => {
      if(isPicChange){
        dispatch(
          EmployeeDataActions.EmployeeDataRequest()
        );
        dispatch(
          EmployeeDataActions.isProfilePicChange({
            action: false,
          })
        );
      }     
    
  }, [isPicChange]);

  useEffect(() => {
    if (isEmptyNullUndefined(userToken)) {
      history.push("/login");
    }else{
      employeeDetails?.empData?.id != undefined &&
      dispatch(EmployeeDataActions.EmployeeEnrolledRequest({ empId : employeeDetails?.empData?.id }));
      employeeDetails?.empData?.company?.id != undefined &&
      dispatch(EmployeeDataActions.getIdTypesRequest({ id : employeeDetails?.empData?.company?.id }));
    }

  }, [employeeDetails?.empData?.id]);

  const goBack = () => {
    let parameter = employeeEnrolled?.length > 0 ? true : false;
    if (window.location.pathname === "/plans" && parameter) {
       dispatch(planPageAction.openExitPopUp());
       dispatch(planPageAction.setExitPath({path:"/home"}));
    } else {
      return history.goBack();
    }
  };

  const handleRedirectProfile = () => {
    let parameter = employeeEnrolled?.length > 0 ? true : false;
    if (isShowExitPopup(parameter)) {
       dispatch(planPageAction.openExitPopUp());
       dispatch(planPageAction.setExitPath({path:"/profile"}));
    } else {
      return history.push("/profile");
    }
  };

  // useEffect(() => {
  //   if (employeeDetails?.empData?.id) {
  //     dispatch(
  //       notificationActions.GetNotificationRequestCount({
  //         id: employeeDetails?.empData?.id,
  //         loggedIn: true
  //       })
  //     );
  //   }
  // }, [employeeDetails?.empData?.id]);

  const toggelNotification = () => {
    setOpenNotifications(!openNotifications);
  };

  function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          
          setShowCoinPopup(false);
          setIsLangSelectOpen(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);

// ***** for dynamic themes start *****
  
    useEffect(() => {
      const primaryColor = employeeDetails?.empData?.company?.primaryColor;
    const secondaryColor = employeeDetails?.empData?.company?.secondaryColor;
    const tertiaryColor = employeeDetails?.empData?.company?.tertiaryColor;
      document.documentElement.style.setProperty("--primary", primaryColor);
      document.documentElement.style.setProperty("--secondary", secondaryColor);
      document.documentElement.style.setProperty("--tertiary", tertiaryColor);

      if(!isEmptyNullUndefined(employeeDetails?.defaultLanguage)){
        dispatch(EmployeeDataActions.setPrefLanguage({lang:employeeDetails?.defaultLanguage}));
      }
    }, [employeeDetails])

// ***** for dynamic themes end *****


// const handleClickOpen = () => {
//   setOpen(true);
// };

// const handleClose = () => {
//   setOpen(false);
// };

const changeLanguage = (lang) => {
  APIList.updateLanguage({code:lang})
  .then((res) => {
    dispatch(EmployeeDataActions.EmployeeDataRequest());
    setIsLangSelectOpen(false);
    setShowCoinPopup(false);
  })
  .catch((err) => {
    toast.error(
      <div className='flex flex-row'>
        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
        &nbsp;&nbsp;{err?.title}
      </div>
    );   
    setIsLangSelectOpen(false);
    setShowCoinPopup(false);
  })
  // setPrefLang(lang);  
}

  return (
    <>
      <div className="header w-full">
        <div>
          <div className="flex flex-wrap">
            <div className="w-full">
              <div className="headerInner">
                <div className="left" id="open-sidebar-icon">
                  {window.location.pathname !== "/home" ? (
                    <img
                      src={leftArrowIcon}
                      onClick={() => goBack()}
                      alt="Arrow"
                      className="arrowImg cursor-pointer"
                    />
                  ) : (
                    <img
                      src={hamburger}
                      alt="Hamburger"
                      className="arrowImg cursor-pointer"
                      onClick={() => setOpenSidebar(!openSidebar)}
                    />
                  )}
                  {/* { routeName ==="/events" &&  <h4 className="title">LOGO</h4> } */}
                </div>
                <div className="right">
                 
                  <div className="coin">
                  {
                  window.location.pathname == "/home" && (
                    employeeDetails?.empData?.company?.bencoinRequired && 
                    <div className="coinDivOuter cursor-pointer" onClick={() => {setShowCoinPopup((value) => !value); setIsLangSelectOpen(false)}}>
                      <img src={coinIcon} alt="Coin" title="Coin" />
                      2000 <br />coins
                    </div>
                  )}
                  {
                    showCoinPopup &&  
                    <div className="coinPopup" ref={wrapperRef}>
                      <div className="boxOuter">
                        <div className="box">
                          <h1 className="title">5500</h1>
                          <h6 className="subTitle">$687.5</h6>
                          <p className="text">Allocated Coins</p>
                        </div>
                        <div className="box">
                          <h1 className="title">3500</h1>
                          <h6 className="subTitle">$437.5</h6>
                          <p className="text">Coins spent</p>
                          <div className="highlightedText">
                            <img src={arrowTopIcon} alt="Top arrow" title="Top arrow" />
                            2.5%
                          </div>
                        </div>
                        <div className="box">
                          <h1 className="title">2000</h1>
                          <h6 className="subTitle">$250</h6>
                          <p className="text">Remaining Coins</p>
                        </div>
                      </div>
                      <div className="availability">
                        <div className="coinsData">
                          <p className="text"><span className="title">2000</span>$250</p>
                          <p className="desc">Available Coins</p>
                        </div>
                        <button className="addMoreBtn">Add more +</button>
                      </div>
                    </div>

                  }
                   
                  </div>

                  <div className="selectLanguageIcon" onClick={() => setIsLangSelectOpen(true)}>
                      <GiEarthAmerica />
                  </div>
                  {
                    isLangSelectOpen && 
                    <div className="langSelectPopup" ref={wrapperRef}>
                      {
                        company?.preferredLanguage?.includes("English") && 
                        <Button variant="text" onClick={() => changeLanguage("en")} >English</Button>
                      }
                      {
                        company?.preferredLanguage?.includes("Spanish") && 
                        <Button variant="text" onClick={() => changeLanguage("es")} >Spanish</Button>
                      }
                      {
                        company?.preferredLanguage?.includes("Hindi") && 
                        <Button variant="text" onClick={() => changeLanguage("hi")} >Hindi</Button>
                      }          
                    </div>
                  }

                  {/* <button
                    onClick={toggelNotification}
                    id="notification_btn_wrpr"
                  >
                    <div className="alertOuter" id="notification_btn ">
                      
                      <BsFillBellFill
                        name="openNotif"
                        style={{ fontSize: "28px" }}
                      />

                      {loader
                        ? null
                        : parseInt(NotificationCount) > 0 && (
                            <p className="count">{NotificationCount}</p>
                          )}

                    </div>
                  </button> */}
                  {openNotifications && (
                    <NotificationsMenu func={toggelNotification} />
                  )}
                  <button
                    className="userOuter"
                    onClick={() => handleRedirectProfile()}
                  >
                    {employeeDetails?.empData?.profilePhoto?.path ? (
                      <img
                        src={employeeDetails?.empData?.profilePhoto?.path}
                        alt="profilePik"
                        className="img"
                      />
                    ) : (
                      <>
                        <img src={userIcon} alt="user" className="userImg" />
                        <h6 className="userName">
                          {employeeDetails &&
                            employeeDetails.empData &&
                            employeeDetails.empData.firstName &&
                            employeeDetails.empData.firstName[0].toUpperCase()}
                        </h6>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Select your Language"}
            </DialogTitle>
            <DialogContent>
                <div><FormControlLabel onClick={() => setSelectedLang("en")} control={<Checkbox checked={selectedLang === "en"} />} label="English" /></div>
                <div><FormControlLabel onClick={() => setSelectedLang("es")} control={<Checkbox checked={selectedLang === "es"} />} label="Spanish" /></div>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => {setPrefLang(selectedLang); handleClose()}}>Submit</Button>
            </DialogActions>
        </Dialog> */}
      </div>
      {openSidebar && (
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
      )}
       {
          exitPopup && 
          <ExitPopup path={exitPath} />
        }
    </>
  );
};

export default Header;
