import { useEffect, useRef } from "react";

const ReimModal = ({setViewDetails }) => {

  function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // console.log("clicked :- ");
          setViewDetails(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);
  
  return (
    <div className="learningPageCoverageDetails reimbursementModal">
      <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mr-auto md:ml-auto max-w-3xl">
          <div className="CoverageDetailsPopup" ref={wrapperRef}>
            <div className="content">
              <div className="container">
                <div className="statusOuter">
                  <p className="mainTitle">Child Care</p>
                  <div className="statusInner">
                    <p className="circle pending">&nbsp;</p>
                    <p className="statusDetails pending">Pending</p>
                  </div>
                </div>
                <p className="title">Description</p>
                <p className="description">The plan covers part of the costs associated with an ambulance that transports you or your covered family members to or from the hospital where the amublance is medically needed.</p>
                <hr></hr>
                <div className="dataOuter">
                  <p className="title">Service Date</p>
                  <p className="description">23 November 2022</p>
                </div>
                <div className="dataOuter">
                  <p className="title">Submitted date</p>
                  <p className="description">23 November 2022</p>
                </div>
                <div className="dataOuter">
                  <p className="title">Provider</p>
                  <p className="description">Benelove private limited</p>
                </div>
                <div className="dataOuter">
                  <p className="title">Recepiet</p>
                  <p className="description">2300097798</p>
                </div>

                <div className="claimDetails dataOuter">
                  <div className="claimDetailsInner">
                    <p className="claimFor">Claim for</p>
                    <p className="claimPerson">Child one</p>
                  </div>
                  <div className="claimDetailsInner">
                    <p className="amount">Amount</p>
                    <p className="price">Rs 2000</p>
                  </div>
                </div>


              </div>
              <div className="flex flex-wrap justify-between w-full total">
                <button
                  className="backBtn"
                  onClick={() => setViewDetails(false)}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReimModal;
