import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.ENROLL_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.ENROLL_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        healthInsuranceEnroll: payload || {},
    }),
    [types.ENROLL_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),

    [types.DELETE_DEPENDANT_REQUEST]: (state) => ({
        ...state,
        deleteloader: true,
    }),
    [types.DELETE_DEPENDANT_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        deleteloader: false,
    }),
    [types.DELETE_DEPENDANT_REQUEST_FAILED]: (state) => ({
        ...state,
        deleteloader: false,
    }),
};

export default handleActions(actionHandler, {
    loader: false,
    healthInsuranceEnroll:{},
    deleteloader: false,
});
