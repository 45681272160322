import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.PLAN_DATA_REQUEST]: (state, { payload }) => ({
        ...state,
        loader: true,
    }),
    [types.PLAN_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        planData: payload.plan || {},
        planMsg: payload.plansMessages || [],
        benefitStartDate: payload.benefitStartDate || null,
        lifeEventConfiguratorDTO: payload.lifeEventConfiguratorDTO || null,
    }),
    [types.PLAN_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),

    [types.ENROLLED_PLAN_DATA_REQUEST]: (state, { payload }) => ({
        ...state,
        loader: true,
    }),
    [types.ENROLLED_PLAN_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        enrolledPlanData: payload || {},
    }),
    [types.ENROLLED_PLAN_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),

    [types.FINAL_ENROLLED_PLANS]: (state, { payload }) => ({
        ...state,
        loader: true,
    }),
    [types.FINAL_ENROLLED_PLANS_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        finalPlans: payload || [],
    }),
    [types.FINAL_ENROLLED_PLANS_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),

    [types.OPEN_EXIT_POPUP]: (state) => ({
        ...state,
        showExitPopup: true,
    }),
    [types.CLOSE_EXIT_POPUP]: (state) => ({
        ...state,
        showExitPopup: false,
    }),

    [types.EXIT_PATH]: (state, { payload }) => ({
        ...state,
        exitPath: payload.path,
    }),

    [types.LOOP_PLANS]: (state, { payload }) => ({
        ...state,
        loopPlans: payload,
    }),

};

export default handleActions(actionHandler, {
    loader: false,    
    planData: {},
    planMsg: [],
    lifeEventConfiguratorDTO: null,
    enrolledPlanData: {},
    benefitStartDate: null,    
    finalPlans: [],
    showExitPopup:false,
    exitPath:"",
    loopPlans:[]
});
