import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../api/index';
import { actions, types } from './actions';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { actions as logInActions } from "../../../pages/Login/redux/actions";

const EmployeeDataRequest = function* EmployeeDataRequest({ payload }) {
    try {
        const { data } = yield call(api.getEmployeeData, payload);
        if (data) {
            yield put(actions.EmployeeDataRequestSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.EmployeeDataRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.EmployeeDataRequestFailed());

        // logout user if error
        yield put(logInActions.clearLogInToken());
        // window.location.reload();        
    }
};

const EmployeeEnrolledRequest = function* EmployeeEnrolledRequest( {payload} ) {
    try {
        
        const  {data}  = yield call(api.getEmployeeEnrolled, payload);
        if (data) {
            yield put(actions.EmployeeEnrolledRequestSuccess(data));
          //   data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
           yield put(actions.EmployeeEnrolledRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.EmployeeEnrolledRequestFailed());
    }
};


const deEnrollRequest = function* deEnrollRequest( {payload} ) {
    try {
        const  {data}  = yield call(api.getDeEnroll, payload);
        if (data) {
            yield put(actions.getDeEnrollSuccess(data));
           data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;{data}</div>);
        } else {
            // toast.error(data.message);
           yield put(actions.getDeEnrollFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.getDeEnrollFailed());
    }
};

const EligiblePlanDataRequest = function* EligiblePlanDataRequest({ payload }) {
    
    if(payload === "clearData"){
        yield put(actions.EligiblePlanDataRequestSuccess({}));
    }else{
    try {
        const { data } = yield call(api.getEligiblePlans, payload);
        if (data) {
            yield put(actions.EligiblePlanDataRequestSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.EligiblePlanDataRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.EligiblePlanDataRequestFailed());
    }
}
};

const getIdTypesRequest = function* getIdTypesRequest({ payload }) {
    
    if(payload === "clearData"){
        yield put(actions.getIdTypesRequestSuccess({}));
    }else{
    try {
        const { data } = yield call(api.idTypes, payload);
        if (data) {
            yield put(actions.getIdTypesRequestSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.getIdTypesRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.getIdTypesRequestFailed());
    }
}
};

export default function* sagas() {
    yield takeLatest(types.EMPLOYEE_DATA_REQUEST, EmployeeDataRequest);
    yield takeLatest(types.EMPLOYEE_ENROLLED_REQUEST, EmployeeEnrolledRequest);
    yield takeLatest(types.GET_DE_ENROLL , deEnrollRequest);
    yield takeLatest(types.ELIGIBLE_PLAN_DATA_REQUEST, EligiblePlanDataRequest);
    yield takeLatest(types.GET_ID_TYPES, getIdTypesRequest);
}
