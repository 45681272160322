const { createAction } = require('redux-actions');

const LIFE_INSURANCE_REQUEST = 'BENEVOLVE/LIFE_INSURANCE_REQUEST';
const LIFE_INSURANCE_REQUEST_SUCCESS = 'BENEVOLVE/LIFE_INSURANCE_REQUEST_SUCCESS';
const LIFE_INSURANCE_REQUEST_FAILED = 'BENEVOLVE/LIFE_INSURANCE_REQUEST_FAILED';

const LifeInsuranceRequest = createAction(LIFE_INSURANCE_REQUEST);
const LifeInsuranceRequestSuccess = createAction(LIFE_INSURANCE_REQUEST_SUCCESS);
const LifeInsuranceRequestFailed = createAction(LIFE_INSURANCE_REQUEST_FAILED);

export const actions = {
    LifeInsuranceRequest,
    LifeInsuranceRequestSuccess,
    LifeInsuranceRequestFailed,
};

export const types = {
    LIFE_INSURANCE_REQUEST,
    LIFE_INSURANCE_REQUEST_SUCCESS,
    LIFE_INSURANCE_REQUEST_FAILED,
};

