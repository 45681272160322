import ButtonM from "../../common/Button";
import coinFlowImage from "../../../assets/coinFlow/coinFlowImage.svg";
import line from "../../../assets/coinFlow/line.png";
import IconActive from "../../../assets/coinFlow/IconActive.png";
import Icon from "../../../assets/coinFlow/Icon.png";
import EditArrow from "../../../assets/coinFlow/EditArrow.png";
import { useHistory, useLocation } from "react-router-dom";
import ReimModal from "../Reimbursement/Modal/ReimModal";
import { useState } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
var classNames = require("classnames");

const CoinWizards = () => {

  const history = useHistory();
  const location = useLocation();

  const [viewDetails, setViewDetails] = useState(false);

  return(
    <div className="coinWizard">

      <div className="BMheader w-full px-2">
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className="pageheaderdata">
              <div className="pageheaderDataInneer w-4/5">
                <h3 className="title">Available Coins</h3>
                <div className="flex-1 borderR">
                  <p className="name">2000<span className="bal">$1000</span></p>
                </div>
                <div className="flex">
                  <div className="annualCost active">
                    <ButtonM text="View Annual cost" onpress={() => setViewDetails(true)}></ButtonM>
                  </div>
                  <div className="addMore">
                    <ButtonM text="Add more +"></ButtonM>
                  </div>
                </div>
              </div>

              <img
                src={coinFlowImage}
                alt="Medical Insurance"
                className="medicalImage h-1/2 w-20"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="boxOuter">
        <div className="flex">
          <div className="left">
            <img src={line} alt="Img" title="Icon" className="lineImg" />
          </div>
          <div className="right">
            <div className="box active">
              <img src={IconActive} alt="Img" title="Icon" className="iconBox" />
              <p className="newHire">New Hire</p>
              <p className="title">BASIC MEDICAL</p>
              <hr className="line"></hr>
              <div className="flex justify-between items-center dateOuter">
                <p className="subTitle">Enrollment Deadline</p>
                <p className="date">26 June, 2022</p>
              </div>
              <hr className="line"></hr>
              <div className="flex justify-between items-center coinData">
                <p className="coinCount">200 coins</p>
                <div className="flex justify-end items-center editEnroll">
                  <div className="enroll">
                    <ButtonM text="Enroll"></ButtonM>
                  </div>
                  <div className="edit flex items-center ">
                    Edit
                    <img src={EditArrow} className="editArrow" />
                  </div>

                </div>
              </div>
            </div>

            <div className={classNames("box", {active: (location?.state?.fromEnroll),})}>
              <img src={Icon} alt="Img" title="Icon" className="iconBox" />
              <p className="newHire">New Hire</p>
              <p className="title">VOLUNTRY PARENTAL</p>
              <hr className="line"></hr>
              <div className="flex justify-between items-center dateOuter">
                <p className="subTitle">Enrollment Deadline</p>
                <p className="date">26 June, 2022</p>
              </div>
              <hr className="line"></hr>
              <div className="flex justify-between items-center coinData">
                <p className="coinCount">600 coins</p>
                <div className="flex justify-end items-center editEnroll">
                  {
                    location?.state?.fromEnroll ? 
                    <>
                    <div className="enroll">
                    <ButtonM text="Enroll"></ButtonM>
                  </div>
                  <div className="edit flex items-center ">
                    Edit
                    <img src={EditArrow} className="editArrow" />
                  </div>
                  </>
                  :
                  <div className="enroll">
                    <ButtonM text="Enroll Now" onpress={() => history.push("/PlanEnroll")}></ButtonM>
                  </div>
                  }
                  
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Icon} alt="Img" title="Icon" className="iconBox" />
              <p className="newHire">New Hire</p>
              <p className="title">LIFE INSURANCE</p>
              <hr className="line"></hr>
              <div className="flex justify-between items-center dateOuter">
                <p className="subTitle">Enrollment Deadline</p>
                <p className="date">26 June, 2022</p>
              </div>
              <hr className="line"></hr>
              <div className="flex justify-between items-center coinData">
                <p className="coinCount">100 coins</p>
                <div className="flex justify-end items-center editEnroll">
                  <div className="enroll">
                    <ButtonM text="Enroll Now"></ButtonM>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="box">
              <img src={Icon} alt="Img" title="Icon" className="iconBox" />
              <p className="newHire">New Hire</p>
              <p className="title">VOLUNTRY PARENTAL</p>
              <hr className="line"></hr>
              <div className="flex justify-between items-center dateOuter">
                <p className="subTitle">Enrollment Deadline</p>
                <p className="date">26 June, 2022</p>
              </div>
              <hr className="line"></hr>
              <div className="flex justify-between items-center coinData">
                <p className="coinCount">600 coins</p>
                <div className="flex justify-end items-center editEnroll">
                  <div className="enroll">
                    <ButtonM text="Enroll Now"></ButtonM>
                  </div>
                </div>
              </div>
              
            </div> */}



          </div>
        </div>
      </div>
      {
        viewDetails && 
        <ReimModal setViewDetails={setViewDetails}>
        <div className="coinPopTable"> 
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Plans</TableCell>
            <TableCell align="right" colSpan={2}>Your Anual Premium(SGD)</TableCell>
            {/* <TableCell align="right">Fat&nbsp;(g)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className="lightcolorfont" component="th" scope="row">BASE MEDICAL</TableCell>
              <TableCell align="right">200 coins</TableCell>
              <TableCell className="lightcolorfont" align="right">$100</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className="lightcolorfont" component="th" scope="row">VOLUNTRY PARENTIAL</TableCell>
              <TableCell align="right">600 coins</TableCell>
              <TableCell className="lightcolorfont" align="right">$300</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className="lightcolorfont" component="th" scope="row">LIFE INSURANCE</TableCell>
              <TableCell align="right">100 coins</TableCell>
              <TableCell className="lightcolorfont" align="right">$50</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </div>
      </ReimModal>
      }
      
      <div className="finalEnroll">
        <ButtonM text="FINALIZE ENROLLMENT" onpress={() => history.push("/SuccessPage")}></ButtonM>
      </div>

      
    </div>

  )
}

export default CoinWizards;