import { all, fork, select, takeEvery } from "redux-saga/effects";
import EmployeeDataSagas from "../components/common/Header/redux/sagas";
import empGroupMediSagas from "../components/pages/BasicMedical/Cover/GroupMedical/redux/sagas";
import logInSagas from "../components/pages/Login/redux/sagas";
import addDependantSagas from "../components/pages/BasicMedical/AddDependentAddress/redux/sagas";
import healthEnrollmentSagas from "../components/pages/BasicMedical/redux/sagas";
import voluntryEnrollmentSagas from "../components/pages/Voluntry/redux/sagas";
import lifeInsuranceSagas from "../components/pages/LifeInsurance/redux/sagas";
import planSagas from "../components/common/Plans/redux/sagas";
import companyPlanSagas from "../components/pages/Events/redux/sagas";
import homepageSaga from "../components/pages/Home/reedux/saga";
import InboxSaga from "../components/pages/Inbox/reedux/saga";
import dependantEnrollInfoSagas from "../components/pages/Dependents/redux/sagas";
import EventSaga from "../components/common/EventLogs/reedux/saga";
import beneficiarySagas from "../components/pages/AddBeneficiaryAfterPlanENroll/redux/sagas";
import notificationSaga from "../components/common/Header/Notifications/reedux/saga";
import reimSaga from "../components/pages/Reimbursement/redux/saga";


function* watchAndLog() {
  yield takeEvery("*", function* logger(action) {
    const state = yield select();
    console.debug("action", action);
    console.debug("state after", state);
  });
}

export default function* root() {
  yield all([
    fork(watchAndLog),
    fork(EmployeeDataSagas),
    fork(empGroupMediSagas),
    fork(logInSagas),
    fork(addDependantSagas),
    fork(healthEnrollmentSagas),
    fork(voluntryEnrollmentSagas),
    fork(lifeInsuranceSagas),
    fork(planSagas),
    fork(companyPlanSagas),
    fork(homepageSaga),
    fork(InboxSaga),
    fork(dependantEnrollInfoSagas),
    fork(beneficiarySagas),
    fork(EventSaga),
    fork(notificationSaga),
    fork(reimSaga),
  ]);
};
