import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import EmployeeDataReducer from "../components/common/Header/redux/reducers";
import empGroupMediReducer from "../components/pages/BasicMedical/Cover/GroupMedical/redux/reducers";
import logInReducer from "../components/pages/Login/redux/reducers";
import dependentsReducer from "../components/pages/BasicMedical/AddDependentAddress/redux/reducers";
import healthEnrollmentReducer from "../components/pages/BasicMedical/redux/reducers";
import voluntryReducer from "../components/pages/Voluntry/redux/reducers";
import lifeInsuranceReducer from "../components/pages/LifeInsurance/redux/reducers";
import planReducer from "../components/common/Plans/redux/reducers";
import companyPlanReducer from "../components/pages/Events/redux/reducers";
import homepageReducer from "../components/pages/Home/reedux/reducer";
import InboxReducer from "../components/pages/Inbox/reedux/reducer";
import dependantEnrollInfoReducer from "../components/pages/Dependents/redux/reducers";
import EventLogs from "../components/common/EventLogs/reedux/reducer";
import beneficiaryReducer from "../components/pages/AddBeneficiaryAfterPlanENroll/redux/reducers";
import NotificationReducer from "../components/common/Header/Notifications/reedux/reducer"; 
import reimReducer from "../components/pages/Reimbursement/redux/reducer"; 


const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    empData: EmployeeDataReducer,
    empGroup: empGroupMediReducer,
    user: logInReducer,
    dependents: dependentsReducer,
    healthEnrollment: healthEnrollmentReducer,
    voluntryReducer: voluntryReducer,
    lifeInsuranceReducer: lifeInsuranceReducer,
    planReducer: planReducer,
    companyPlanReducer: companyPlanReducer,
    homepageReducer: homepageReducer,
    dependantEnrollInfo: dependantEnrollInfoReducer,
    beneficiary:beneficiaryReducer,
    InboxReducer,
    EventLogs,
    NotificationReducer,
    reimReducer
  
  });

export default createRootReducer;
