import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AppRouters from "./routes";
import { history } from "./store";

const App = () => {
    const [isLoading, setLoading] = useState(true);

    function fakeRequest() {
        return new Promise((resolve) => setTimeout(() => resolve(), 2));
    }
    useEffect(() => {
        fakeRequest().then(() => {
            const el = document.querySelector('.siteLoadingTempholder');
            if (el) {
                el.remove();
                setLoading(!isLoading);
            }
        });
    }, [isLoading]);

    if (isLoading) {
        return null;
    }
  return (
    <div className="App">
      <BrowserRouter history={history}>
        <Switch>
          <Route component={AppRouters} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App;
