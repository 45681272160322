import { useEffect } from "react";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Box,
  FormHelperText,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import ButtonM from "../../../common/Button";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import {
  genders,
  getRelationName,
  isEmptyNullUndefined,
  relationsBen,
  requiredSelectStyled,
  requiredStyled,
} from "components/utils/utils";
import { actions as DependantActions } from "../../../pages/BasicMedical/AddDependentAddress/redux/actions";
import { FormattedMessage } from "react-intl";

const AddBeneficiary = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const employeeDetails = useSelector((state) => state && state.empData);
  const allBeneficiaies = useSelector(
    (state) => state?.beneficiary?.allBeneficiaries
  );
  const tempDependantInfo = useSelector((state) => state?.dependents?.tempDependantInfo);

  const initialEmptyBeneficiary = {
    relation: null,
    firstName: null,
    middleName: null,
    lastName: null,
    gender: null,
    idType: null,
    nationalId: null,
    dateOfBirth: null,
    nationality: null,
    isRelativeWorking: null,
    isRelativeWorkingInNium: null,
    employeeIdOfRelative: null,
    countryCode:null,
    phoneNumber:null,
    guardianName:null,
    guardianRelation:null,
    guardianContact:null,
  };

  const initialFormErrorList = {
    relation: { isError: false, errorMessage: "" },
    firstName: { isError: false, errorMessage: "" },
    middleName: { isError: false, errorMessage: "" },
    lastName: { isError: false, errorMessage: "" },
    gender: { isError: false, errorMessage: "" },
    idType: { isError: false, errorMessage: "" },
    nationalId: { isError: false, errorMessage: "" },
    dateOfBirth: { isError: false, errorMessage: "" },
    nationality: { isError: false, errorMessage: "" },
    isRelativeWorking: { isError: false, errorMessage: "" },
    isRelativeWorkingInNium: { isError: false, errorMessage: "" },
    employeeIdOfRelative: { isError: false, errorMessage: "" },
    countryCode: { isError: false, errorMessage: "" },
    phoneNumber: { isError: false, errorMessage: "" },
    guardianName: { isError: false, errorMessage: "" },
    guardianRelation: { isError: false, errorMessage: "" },
    guardianContact: { isError: false, errorMessage: "" },
  };

  const [dependent, setDependent] = React.useState(initialEmptyBeneficiary);
  const [formError, setFormError] = React.useState(initialFormErrorList);

  const [guardianRequired, setGuardianRequired] = React.useState(false);

  useEffect(() => {
    if (location?.state?.isDepEdit) {
      let dependantToEdit = allBeneficiaies?.filter(
        (x) => x.id === location?.state?.id
      )[0];
       if(!isEmptyNullUndefined(tempDependantInfo)){
         setDependent(tempDependantInfo);
       } else {
      setDependent(dependantToEdit);
       }
    } else {
       if(!isEmptyNullUndefined(tempDependantInfo)){
         setDependent(tempDependantInfo);
       } else {
      setDependent(initialEmptyBeneficiary);
       }
    }
  }, []);

  useEffect(() => {
    if(getAge(dependent.dateOfBirth) < 18){
      setGuardianRequired(() => true);
    } else {
      setGuardianRequired(() => false);
    }
  }, [dependent.dateOfBirth]);

  const handleChangeDependent = async (event, fieldType) => {
    const tempDependent = JSON.parse(JSON.stringify(dependent));
    // const tempError = JSON.parse(JSON.stringify(formError));
    const tempError ={...formError}
    if (fieldType === "textfield" || fieldType === "dropdown") {
      tempDependent[event.target.name] = event.target.value;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";      
    } else if (fieldType === "birthdate") {
      tempDependent.dateOfBirth = event;
      tempError.dateOfBirth.isError = false;
      tempError.dateOfBirth.errorMessage = "";
     
    } else if (fieldType === "checkbox") {
      tempDependent[event.target.name] =
        event.target.value === "true" ? true : false;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    } else if (fieldType == 'onlyNumber') {
      tempDependent[event.target.name] = event.target.value.replace(
        /[^0-9.]/g,
        '',
      );
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = '';
    }
    if (!tempDependent["isRelativeWorkingInNium"]) {
      tempDependent["employeeIdOfRelative"] = null;
    }

    setDependent(tempDependent);
    if(event.target.value === "Trust" || event.target.value === "Estate"){
      setFormError(initialFormErrorList);
    } else {
      setFormError(tempError);
    }    
  };

  const handleValidateDependent = async () => {
    let isValid = true;
    // const tempError = JSON.parse(JSON.stringify(formError));
    const tempError ={...formError}
    const tempDependent = JSON.parse(JSON.stringify(dependent));

    if (
      tempDependent.relation === null ||
      tempDependent.relation.trim() === ""
    ) {
      tempError.relation.isError = true;
      // tempError.relation.errorMessage = "Please select Relationship";
       tempError.relation.errorMessage = <FormattedMessage id='error.pleaseSelectRelationaship' defaultValue='Please select Relationship'/>;
      isValid = false;
    }

    if ((dependent?.relation === "Trust" || dependent?.relation === "Estate")) {
      if (
        tempDependent.firstName === null ||
        tempDependent.firstName.trim() === ""
      ) {
        tempError.firstName.isError = true;
        tempError.firstName.errorMessage = `Please enter ${dependent?.relation} Name`;
        isValid = false;
      }
    } else {      
      if (
        tempDependent.firstName === null ||
        tempDependent.firstName.trim() === ""
      ) {
        tempError.firstName.isError = true;
        // tempError.firstName.errorMessage = "Please enter First Name";
        tempError.firstName.errorMessage = <FormattedMessage id='error.pleaseEnterFirstName' defaultValue='Please enter First Name'/>;
        isValid = false;
      }
  
      if (
        tempDependent.lastName === null ||
        tempDependent.lastName.trim() === ""
      ) {
        tempError.lastName.isError = true;
        // tempError.lastName.errorMessage = "Please enter Last Name";
        tempError.lastName.errorMessage = <FormattedMessage id='error.pleaseEnterLastName' defaultValue='Please enter Last Name'/>;
        isValid = false;
      }
  
      if (tempDependent.gender === null || tempDependent.gender.trim() === "") {
        tempError.gender.isError = true;
        // tempError.gender.errorMessage = "Please select Gender";
        tempError.gender.errorMessage = <FormattedMessage id='error.pleaseSelectGender' defaultValue='Please select Gender'/>;
        isValid = false;
      }
      if (
        tempDependent.dateOfBirth == null ||
        tempDependent.dateOfBirth.trim() == ""
      ) {
        tempError.dateOfBirth.isError = true;
        // tempError.dateOfBirth.errorMessage = "Please enter Date of Birth";
        tempError.dateOfBirth.errorMessage = <FormattedMessage id='error.pleaseEnterDateofBirth' defaultValue='Please enter Date of Birth'/>;
        isValid = false;
      }
      if (
        tempDependent.countryCode == null ||
        tempDependent.countryCode.trim() == ""
      ) {
        tempError.countryCode.isError = true;
        // tempError.countryCode.errorMessage = "Please enter Country Code";
        tempError.countryCode.errorMessage =<FormattedMessage id='error.pleaseEnterCountryCode' defaultValue='Please enter Country Code'/>;

        isValid = false;
      }
      if (
        tempDependent.phoneNumber == null ||
        tempDependent.phoneNumber.trim() == ""
      ) {
        tempError.phoneNumber.isError = true;
        // tempError.phoneNumber.errorMessage = "Please enter Phone Number";
        tempError.phoneNumber.errorMessage = <FormattedMessage id='error.PleaseEnterPhoneNumber' defaultValue='Please enter Phone Number'/>;
        isValid = false;
      }
      if (getAge(tempDependent.dateOfBirth) < 0) {
        tempError.dateOfBirth.isError = true;
        // tempError.dateOfBirth.errorMessage = "Please enter correct Date of Birth";
        tempError.dateOfBirth.errorMessage = <FormattedMessage id='error.pleaseEnterCorrectDateofBirth' defaultValue='Please enter correct Date of Birth'/>;
        isValid = false;
      }
      if (guardianRequired && 
        (
          tempDependent.guardianName == null ||
          tempDependent.guardianName.trim() == ""
        )) {
          tempError.guardianName.isError = true;
          // tempError.guardianName.errorMessage = "Please enter name of the guardian";
          tempError.guardianName.errorMessage = <FormattedMessage id='error.PleaseEnterNameGuardian' defaultValue='Please enter name of the guardian'/>;
          isValid = false;
      }
      if (guardianRequired && 
        (
          tempDependent.guardianRelation == null ||
          tempDependent.guardianRelation.trim() == ""
        )) {
          tempError.guardianRelation.isError = true;
          // tempError.guardianRelation.errorMessage = "Please enter relation of the guardian";
          tempError.guardianRelation.errorMessage = <FormattedMessage id='error.PleaseEnterRelationGuardian' defaultValue='Please enter relation of the guardian'/>;
          isValid = false;
      }
      if (guardianRequired && 
        (
          tempDependent.guardianContact == null ||
          tempDependent.guardianContact.trim() == ""
        )) {
          tempError.guardianContact.isError = true;
          // tempError.guardianContact.errorMessage = "Please enter relation of the guardian";
          tempError.guardianContact.errorMessage = <FormattedMessage id='error.PleaseEnterRelationGuardian' defaultValue='Please enter relation of the guardian'/>;
          isValid = false;
      }
      // if (
      //   tempDependent.isRelativeWorkingInNium == null
      // ) {
      //   tempError.isRelativeWorkingInNium.isError = true;
      //   tempError.isRelativeWorkingInNium.errorMessage = "Please select yes/no";
      //   isValid = false;
      // }
      // if (
      //   tempDependent.isRelativeWorkingInNium && (tempDependent.employeeIdOfRelative == null || tempDependent?.employeeIdOfRelative?.trim() == "")
      // ) {
      //   tempError.employeeIdOfRelative.isError = true;
      //   tempError.employeeIdOfRelative.errorMessage = "Please enter employe-id of dependant";
      //   isValid = false;
      // }
    }

    

    //do Submit
    if (isValid) {
      // if (isEdit) {
      //   updateDependent();
      // } else {
      submitDependent();
      // }
    } else {
      setFormError(tempError);
    }
  };

  const submitDependent = () => {
    // handleResetForm();
    dispatch(DependantActions.SaveDependantInfo(dependent));
    if (location?.state?.isDepEdit) {
      history.push({
        pathname: "/add-beneficiary-address",
        state: {
          dependant: dependent,
          isDepEdit: true,
          addBenPath: location?.state?.addBenPath,
        },
      });
    } else {
      history.push({
        pathname: "/add-beneficiary-address",
        state: {
          dependant: dependent,
          addBenPath: location?.state?.addBenPath,
        },
      });
    }
  };

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <div className="AddDependentPage">
      <h4 className="page-heading-navbar">{location?.state?.isDepEdit ? 
      // "Edit" 
      <FormattedMessage id='common.button.EditBeneficiary' defaultValue='Edit Beneficiary'/>
      : 
      // "Add"
      <FormattedMessage id='myBeneficiaries.AddBeneficiary' defaultValue='Add Beneficiary'/>
      } 
  
      </h4>

      <Stack sx={{ mx: { xs: 0, xl: 10, md: 5 } }}>
        <Box sx={{ my: 2 }}>
          <Stack
            display={{ xs: "flex", sm: "flex" }}
            direction={{ xs: "column", sm: "column" }}
            justifyContent="space-between"
            alignItems={{ xs: "center", sm: "center" }}
            spacing={2}
          >
            <Stack width={{ xs: "90%", sm: "75%" }}>
              <div className="mt-4">
                <div className="flex flex-wrap">
                  <div className="w-full flex justify-between">
                    <div className="details">
                      <h3 className="title">
                        {/* Personal Details */}
                        <FormattedMessage id='myBeneficiaries.PersonalDetails' defaultValue='Personal Details'/>
                      </h3>
                      <p className="subTitle">
                        {/* Add the details for your beneficiary.{" "} */}
                        <FormattedMessage id='myBeneficiaries.Addthedetailsforyourbeneficiary' defaultValue=' Add the details for your beneficiary'/>
                      </p>
                      { 
                        guardianRequired && 
                        <p className="subTitle">
                        {/* Add Gaurdian details since beneficiary is under the age of 18. */}
                        <FormattedMessage id='myBeneficiaries.AddGaurdianDetails' defaultValue=' Add Gaurdian details since beneficiary is under the age of 18.'/>
                      </p>                        
                      }
                    </div>
                  </div>
                </div>
              </div>
              
                <FormControl sx={{ my: 2 }}>
                  <InputLabel size="small" id="relationship">
                    {/* Select relationship */}
                    <FormattedMessage id='myBeneficiaries.selectRelationship' defaultValue='Select relationship'/>
                  </InputLabel>
                  <Select
                    value={dependent.relation ? dependent.relation : ""}
                    labelId="Select relationship"
                    name="relation"
                    onChange={(event) =>
                      handleChangeDependent(event, "dropdown")
                    }
                    variant="outlined"
                    error={formError.relation.isError}
                    helperText={formError.relation.errorMessage}
                    // label="Select relationship"
                    label={ <FormattedMessage id='myBeneficiaries.selectRelationship' defaultValue='Select relationship'/>}
                    size="small"
                    sx={{
                      width: "100%",
                      //backgroundColor: "#dedede",
                      "& fieldset": {
                        border: "1px solid #dedede",
                      },
                      // "& .MuiInputBase-input:focus": {
                      //   border: "2px solid #fcd25e", // focus
                      // },
                      "& .css-k4qjio-MuiFormHelperText-root": {
                        backgroundColor: "#ffffff",
                        margin: "0px",
                        paddingLeft: "0.5rem",
                      },
                      ...requiredSelectStyled,
                    }}
                  >
                    {relationsBen.map((relation) => {
                      return (
                        <MenuItem
                          key={relation}
                          value={relation}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {getRelationName(relation)}
                          {}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formError.relation.errorMessage && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {formError.relation.errorMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                {
                  (dependent?.relation === "Trust" || dependent?.relation === "Estate") ? 
                  <>
                  <TextField
                      size="small"
                      name="firstName"
                      label={`${dependent?.relation} Name`}
                      id="outlined-basic"
                      onChange={(event) =>
                        handleChangeDependent(event, "textfield")
                      }
                      variant="outlined"
                      error={formError.firstName.isError}
                      helperText={formError.firstName.errorMessage}
                      value={dependent.firstName ? dependent.firstName : ""}
                      sx={{
                        my: 2,
                        width: "100%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        // "& .MuiInputBase-input:focus": {
                        //   border: "2px solid #fcd25e", // focus
                        // },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        ...requiredStyled,
                      }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat={
                          (employeeDetails &&
                            employeeDetails.empData &&
                            employeeDetails.empData.company &&
                            employeeDetails.empData.company.dateFormat &&
                            employeeDetails.empData.company.dateFormat) ||
                          "dd/MM/yyyy"
                        }
                        value={dependent.dateOfBirth}
                        // label="Registration Date"
                        label={ <FormattedMessage id='myBeneficiaries.registrationDate' defaultValue='Registration Date'/>}
                        name="dateOfBirth"
                        maxDate={new Date()}
                        onChange={(event) =>
                          handleChangeDependent(event, "birthdate")
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            helperText={formError.dateOfBirth.errorMessage}
                            size="small"
                            sx={{
                              pointerEvents: 'none',
                              '& .MuiOutlinedInput-root': {
                                'button': {
                                  pointerEvents: 'all',
                                }},
                              my: 2,
                              width: "100%",
                              //backgroundColor: "#dedede",
                              "& fieldset": {
                                border: "1px solid #dedede",
                              },
                              // "& .MuiInputBase-input:focus": {
                              //   border: "2px solid #fcd25e", // focus
                              // },
                              "& .css-k4qjio-MuiFormHelperText-root": {
                                backgroundColor: "#ffffff",
                                margin: "0px",
                                paddingLeft: "0.5rem",
                              },
                              // ...requiredStyled,
                            }}
                            {...params}
                            error={formError.dateOfBirth.isError}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </>
                  :
                  <>
                    <TextField
                      size="small"
                      name="firstName"
                      // label="First Name"
                      label={<FormattedMessage id='myBeneficiaries.firstName' defaultValue='First Name'/>}
                      id="outlined-basic"
                      onChange={(event) =>
                        handleChangeDependent(event, "textfield")
                      }
                      variant="outlined"
                      error={formError.firstName.isError}
                      helperText={formError.firstName.errorMessage}
                      value={dependent.firstName ? dependent.firstName : ""}
                      sx={{
                        my: 2,
                        width: "100%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        // "& .MuiInputBase-input:focus": {
                        //   border: "2px solid #fcd25e", // focus
                        // },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        ...requiredStyled,
                      }}
                    />
                    <TextField
                      size="small"
                      name="middleName"
                      // label="Middle Name"
                      label={<FormattedMessage id='myBeneficiaries.middleName' defaultValue='Middle Name'/>}
                      id="outlined-basic"
                      onChange={(event) =>
                        handleChangeDependent(event, "textfield")
                      }
                      variant="outlined"
                      error={formError.middleName.isError}
                      helperText={formError.middleName.errorMessage}
                      value={dependent.middleName ? dependent.middleName : ""}
                      sx={{
                        my: 2,
                        width: "100%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        // "& .MuiInputBase-input:focus": {
                        //   border: "2px solid #fcd25e", // focus
                        // },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                      }}
                    />
                    <TextField
                      size="small"
                      name="lastName"
                      // label="Last Name"
                      label={<FormattedMessage id='myBeneficiaries.lastName' defaultValue='Last Name'/>}
                      id="outlined-basic"
                      onChange={(event) =>
                        handleChangeDependent(event, "textfield")
                      }
                      variant="outlined"
                      error={formError.lastName.isError}
                      helperText={formError.lastName.errorMessage}
                      value={dependent.lastName ? dependent.lastName : ""}
                      sx={{
                        my: 2,
                        width: "100%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        // "& .MuiInputBase-input:focus": {
                        //   border: "2px solid #fcd25e", // focus
                        // },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        ...requiredStyled,
                      }}
                    />
                    <Stack sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <TextField
                      size="small"
                      name="countryCode"
                      // label="Country Code"
                      label={<FormattedMessage id='myBeneficiaries.countryCode' defaultValue='Country Code'/>}
                      id="outlined-basic"
                      onChange={(event) => handleChangeDependent(event, "onlyNumber")}
                      variant="outlined"
                      error={formError.countryCode.isError}
                      helperText={formError.countryCode.errorMessage}
                      value={dependent.countryCode ? dependent.countryCode : ""}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+</InputAdornment>
                        ),
                      }}
                      sx={{
                        my: 2,
                        width: "24%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        // "& .MuiInputBase-input:focus": {
                        //   border: "2px solid #fcd25e", // focus
                        // },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        ...requiredStyled,
                      }}
                    />
                    <TextField
                      size="small"
                      name="phoneNumber"
                      // label="Phone Number"
                      label={<FormattedMessage id='myBeneficiaries.phoneNumber' defaultValue='Phone Number'/>}
                      id="outlined-basic"
                      onChange={(event) => handleChangeDependent(event, "onlyNumber")}
                      variant="outlined"
                      error={formError.phoneNumber.isError}
                      helperText={formError.phoneNumber.errorMessage}
                      value={dependent.phoneNumber ? dependent.phoneNumber : ""}
                      sx={{
                        my: 2,
                        width: "74%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        // "& .MuiInputBase-input:focus": {
                        //   border: "2px solid #fcd25e", // focus
                        // },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        ...requiredStyled,
                      }}
                    />
                    </Stack>
                    <FormControl sx={{ my: 2 }}>
                      <InputLabel size="small" id="gender">
                        {/* Gender */}
                        <FormattedMessage id='myBeneficiaries.gender' defaultValue='Gender'/>
                      </InputLabel>
                      <Select
                        value={dependent.gender ? dependent.gender : ""}
                        labelId="gender"
                        name="gender"
                        onChange={(event) =>
                          handleChangeDependent(event, "dropdown")
                        }
                        variant="outlined"
                        error={formError.gender.isError}
                        helperText={formError.gender.errorMessage}
                        // label="Gender"
                        label={<FormattedMessage id='myBeneficiaries.gender' defaultValue='Gender'/>}
                        size="small"
                        sx={{
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                            border: "1px solid #dedede",
                          },
                          // "& .MuiInputBase-input:focus": {
                          //   border: "2px solid #fcd25e", // focus
                          // },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          ...requiredSelectStyled,
                        }}
                      >
                        {genders.map((gender) => {
                          return (
                            <MenuItem key={gender.value} value={gender.value}>
                              {gender.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {formError.gender.errorMessage && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {formError.gender.errorMessage}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat={
                          (employeeDetails &&
                            employeeDetails.empData &&
                            employeeDetails.empData.company &&
                            employeeDetails.empData.company.dateFormat &&
                            employeeDetails.empData.company.dateFormat) ||
                          "dd/MM/yyyy"
                        }
                        value={dependent.dateOfBirth}
                        // label="Date of Birth"
                        label={<FormattedMessage id='myBeneficiaries.dob' defaultValue='Date of Birth'/>}
                        name="dateOfBirth"
                        maxDate={new Date()}
                        onChange={(event) =>
                          handleChangeDependent(event, "birthdate")
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            helperText={formError.dateOfBirth.errorMessage}
                            size="small"
                            sx={{
                              pointerEvents: 'none',
                              '& .MuiOutlinedInput-root': {
                                'button': {
                                  pointerEvents: 'all',
                                }},
                              my: 2,
                              width: "100%",
                              //backgroundColor: "#dedede",
                              "& fieldset": {
                                border: "1px solid #dedede",
                              },
                              // "& .MuiInputBase-input:focus": {
                              //   border: "2px solid #fcd25e", // focus
                              // },
                              "& .css-k4qjio-MuiFormHelperText-root": {
                                backgroundColor: "#ffffff",
                                margin: "0px",
                                paddingLeft: "0.5rem",
                              },
                              // ...requiredStyled,
                            }}
                            {...params}
                            error={formError.dateOfBirth.isError}
                          />
                        )}
                      />
                    </LocalizationProvider>

                    {
                      guardianRequired && 
                      <TextField
                      size="small"
                      name="guardianName"
                      // label="Guardian Name"
                      label={<FormattedMessage id='myBeneficiaries.GuardianName' defaultValue='Guardian Name'/>}
                      id="outlined-basic"
                      onChange={(event) =>
                        handleChangeDependent(event, "textfield")
                      }
                      variant="outlined"
                      error={formError.guardianName.isError}
                      helperText={formError.guardianName.errorMessage}
                      value={dependent.guardianName ? dependent.guardianName : ""}
                      sx={{
                        my: 2,
                        width: "100%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        // "& .MuiInputBase-input:focus": {
                        //   border: "2px solid #fcd25e", // focus
                        // },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        ...requiredStyled,
                      }}
                    />
                    }
                    {
                      guardianRequired && 
                      <TextField
                      size="small"
                      name="guardianRelation"
                      // label="Guardian Relation"
                      label={<FormattedMessage id='myBeneficiaries.GuardianRelation' defaultValue='Guardian Relation'/>}
                      id="outlined-basic"
                      onChange={(event) =>
                        handleChangeDependent(event, "textfield")
                      }
                      variant="outlined"
                      error={formError.guardianRelation.isError}
                      helperText={formError.guardianRelation.errorMessage}
                      value={dependent.guardianRelation ? dependent.guardianRelation : ""}
                      sx={{
                        my: 2,
                        width: "100%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        // "& .MuiInputBase-input:focus": {
                        //   border: "2px solid #fcd25e", // focus
                        // },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        ...requiredStyled,
                      }}
                    />
                    }
                    {
                      guardianRequired && 
                      <Stack sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    {/* <TextField
                      size="small"
                      name="countryCode"
                      label="Country Code"
                      id="outlined-basic"
                      onChange={(event) => handleChangeDependent(event, "onlyNumber")}
                      variant="outlined"
                      error={formError.countryCode.isError}
                      helperText={formError.countryCode.errorMessage}
                      value={dependent.countryCode ? dependent.countryCode : ""}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+</InputAdornment>
                        ),
                      }}
                      sx={{
                        my: 2,
                        width: "24%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        // "& .MuiInputBase-input:focus": {
                        //   border: "2px solid #fcd25e", // focus
                        // },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        ...requiredStyled,
                      }}
                    /> */}
                    <TextField
                      size="small"
                      name="guardianContact"
                      // label="Guardian Contact Number"
                      label={<FormattedMessage id='myBeneficiaries.GuardianContactNumber' defaultValue='Guardian Contact Number'/>}
                      id="outlined-basic"
                      onChange={(event) => handleChangeDependent(event, "onlyNumber")}
                      variant="outlined"
                      error={formError.guardianContact.isError}
                      helperText={formError.guardianContact.errorMessage}
                      value={dependent.guardianContact ? dependent.guardianContact : ""}
                      sx={{
                        my: 2,
                        // width: "74%",
                        width: "100%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        // "& .MuiInputBase-input:focus": {
                        //   border: "2px solid #fcd25e", // focus
                        // },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        ...requiredStyled,
                      }}
                    />
                    </Stack>
                    }

                    <TextField
                      size="small"
                      name="nationality"
                      // label="Nationality"
                      label={<FormattedMessage id='myBeneficiaries.nationality' defaultValue='Nationality'/>}
                      id="outlined-basic"
                      onChange={(event) =>
                        handleChangeDependent(event, "textfield")
                      }
                      variant="outlined"
                      error={formError.nationality.isError}
                      helperText={formError.nationality.errorMessage}
                      value={dependent.nationality ? dependent.nationality : ""}
                      sx={{
                        my: 2,
                        width: "100%",
                        //backgroundColor: "#dedede",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        // "& .MuiInputBase-input:focus": {
                        //   border: "2px solid #fcd25e", // focus
                        // },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        // ...requiredStyled
                      }}
                    />

                    {/* <Stack
            direction='row'
            alignItems='center'
          >
            <Stack sx={{width: '50%'}} spacing={5}>
              <FormLabel id='demo-row-radio-buttons-group-label'>
                <Stack direction='row'>
                  <Stack fontWeight={500}>Is dependant working in {empCompany} ?</Stack>
                </Stack>
              </FormLabel>
            </Stack>
            <Stack sx={{width: '50%'}} direction='row' alignItems='center'>
              <RadioGroup
                value={dependent?.isRelativeWorkingInNium}
                onChange={(event) => handleChangeDependent(event, "checkbox")}
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='isRelativeWorkingInNium'
              >
                <FormControlLabel value={true} control={<Radio checked={dependent?.isRelativeWorkingInNium !== null && dependent?.isRelativeWorkingInNium} />} label='Yes' />
                <FormControlLabel value={false} control={<Radio checked={dependent?.isRelativeWorkingInNium !== null && !dependent?.isRelativeWorkingInNium} />} label='No' />
              </RadioGroup>
              {formError?.isRelativeWorkingInNium?.isError && (
                <FormHelperText className='Mui-error'>
                  {formError?.isRelativeWorkingInNium?.errorMessage}
                </FormHelperText>
              )}
            </Stack>
          </Stack>     
          {
            dependent?.isRelativeWorkingInNium && 
            <TextField
                    size="small"
                    name="employeeIdOfRelative"
                    label="Employee-ID of Dependant"
                    id="outlined-basic"
                    onChange={(event) =>
                      handleChangeDependent(event, "textfield")
                    }
                    variant="outlined"
                    error={formError.employeeIdOfRelative.isError}
                    helperText={formError.employeeIdOfRelative.errorMessage}
                    value={dependent.employeeIdOfRelative ? dependent.employeeIdOfRelative : ""}
                    sx={{
                      my: 2,
                      width: "100%",
                      //backgroundColor: "#dedede",
                      "& fieldset": {
                          border: "1px solid #dedede",
                      },
                      "& .MuiInputBase-input:focus": {
                        border: "2px solid #fcd25e", // focus
                      },
                      "& .css-k4qjio-MuiFormHelperText-root": {
                        backgroundColor: "#ffffff",
                        margin: "0px",
                        paddingLeft: "0.5rem",
                      },
                    }}
                  />
          } */}
                  </>
                }
              
              <div className="bottomButtonReq">
                <ButtonM
                  // text="NEXT"
                  text={<FormattedMessage id='common.button.NEXT' defaultValue='NEXT'/>}
                  onpress={() => handleValidateDependent()}
                />
              </div>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </div>
  );
};

export default AddBeneficiary;
