import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import learnIcon from "../../../assets/homepage/learn.png";
import enrollIcon from "../../../assets/homepage/enroll.png";
import reportIcon from "../../../assets/homepage/report.png";
import { CurrencyFormat } from "../../utils/numberFormatter";
import balanceIcon from "../../../assets/homepage/balance.png";
import carLease from "../../../assets/carLeaseProgram/carLeaseS.svg";
import reimbursementIcon from "../../../assets/LearningPage/ExclusionNewIcon.svg";
import checkRight from "../../../assets/HomeOld/checkOrange.svg";
import { BiMoney } from "react-icons/bi";
import lifeInsurance from "../../../assets/HomeOld/lifeInsurance.png";
import dot from "../../../assets/HomeOld/dot.png";
import ImgSlider from "./components/ImgSlider";
import { actions as employeePlanActions } from "./reedux/action";
import APIList from "api";
import { Skeleton } from "@mui/material";
import { BsCheckAll } from "react-icons/bs";
import moment from "moment";
import { isEmptyNullUndefined } from "components/utils/utils";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";

const Home = () => {
  
  const dispatch = useDispatch();
  const history = useHistory();

  const [planId, setPLanId] = useState({ id: "", show: false });
  const [activeElliPlanTab, setactiveElliPlanTab] = useState("current");
  const [eligibleplans, setEligibleplans] = useState([]);
  const [elligiblePlanLoader, setElligiblePlanLoader] = useState(false);
  const [wizardPlanLoader, setwizardPlanLoader] = useState(false);
  const [wizardPlans, setWizardPlans] = useState([]);

  const empLoader = useSelector((state) => state?.empData?.loader);
  const company = useSelector((state) => state?.empData?.empData?.company?.displayName);
  const employeeDetails = useSelector((state) => state && state?.empData);
  const { planLoader, bannerLoader, eventData } = useSelector((state) => state?.homepageReducer);
  const userToken = useSelector((state) => state && state.user && state.user.userToken);

  const currenyCode = employeeDetails?.empData?.company?.currency;
  
  const [selectedEnrollPlan, setEnrollPlan] = useState({
    displayName: "Group Health Insurance",
    enrollmentStatus: "yes",

    dependents: [],
  });  

  useEffect(() => {
    if (isEmptyNullUndefined(userToken?.id_token)) {      
      history.push("/login")
    }else{
          if (employeeDetails?.empData?.id != undefined) {
      dispatch(
        employeePlanActions.CompanyBannerRequest({
          companyId: employeeDetails?.empData?.company?.id,
        })
      );
      getCurrentEligiblePlans()
    }
    }

    setwizardPlanLoader(true);
    APIList.getEmployeeEnrolled({empId:employeeDetails?.empData?.id})
    .then((res) => {
      setWizardPlans(res?.data?.Plans);
      setwizardPlanLoader(false);
    })
    .catch((err) => {
      console.log(err);
      setwizardPlanLoader(false);
    });

  }, [employeeDetails.empData?.id]);

  const getCurrentEligiblePlans = () => {
    setElligiblePlanLoader(true);
      APIList.getEligiblePlans(employeeDetails?.empData?.id)
      .then((res) => {
        setEligibleplans(res?.data);
        setElligiblePlanLoader(false);
      })
      .catch((err) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {err?.message}
          </div>
        )
        setElligiblePlanLoader(false);
      })
  }
  const getFutureEligiblePlans = () => {
    setElligiblePlanLoader(true);
      APIList.getFutureEligiblePlans(employeeDetails?.empData?.id)
      .then((res) => {
        setEligibleplans(res?.data);
        setElligiblePlanLoader(false);
      })
      .catch((err) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {err?.message}
          </div>
        )
        setElligiblePlanLoader(false);
      })
  }

  const handelSelectEnrollement = (event) => {
    if(event?.className === "reimbursements"){
       history.push(`/reimbursement/${event?.planId}`)
    } else {
          setEnrollPlan({ ...selectedEnrollPlan, ...event });

    if (planId?.id === event.planId) {
      setPLanId({ ...planId, show: !planId.show });
    } else {
      setPLanId({ id: event.planId, show: true });
      const payloadBody = {
        employeeId: employeeDetails?.empData?.id,
        planClassname: event?.className,
        planId: event?.planId,
      };

      if (event?.enrollmentStatus?.toLowerCase() === "enrolled") {
        dispatch(
          employeePlanActions.EmployeePlanEnrollmentRequest(payloadBody)
        );
      }
    }
    }
  };

  const handleClickCurPlans = () => {
    if(activeElliPlanTab !== "current") {
      getCurrentEligiblePlans();
      setactiveElliPlanTab("current");
      setPLanId({ ...planId, show: false });
    }    
  }
  const handleClickFutPlans = () => {
    if(activeElliPlanTab !== "future") {
      getFutureEligiblePlans();
      setactiveElliPlanTab("future");
      setPLanId({ ...planId, show: false });
    }    
  }

  return (
    <div className="homePage homePageOld">
      <h4 className="page-heading-navbar">{company}</h4>
      {(empLoader || bannerLoader) ? (
        <div className=" grid px-4 mt-8">

          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "300px", xs: "150px" } }}
          />
        </div>
      ) : (
        <div className="sliderBlock mx-4 md:mx-6">
          <div className="forSlider">
            <ImgSlider />
          </div>
        </div>
      )}
      {(empLoader || wizardPlanLoader) ? (
        
        <div className=" grid grid-cols-4 gap-4 px-4 mt-8">

          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { md: "125px", xs: "70px" } }}
          />
        </div>
      ) : (
        <>
          <div className=" grid grid-cols-4 gap-4 px-4 mt-8">
            {
              wizardPlans?.length > 0 && 
              <Link to={"/enrollmentIntro"} className="blocks enroll" data-value="proceed-to-enroll">
                <img src={enrollIcon} alt="enroll" />
                
                  <FormattedMessage 
                    id="home.enroll"
                    defaultMessage="Enroll" 
                  />
                  {/* <span> Enroll </span> */}
              </Link>
            }
            
            <Link to={"/new-learning-page"} className="blocks" data-value="proceed-to-learn">
              <img src={learnIcon} alt="learn" />
              {/* <span>Learn</span> */}
              <FormattedMessage 
                  id="home.Learn"
                  defaultMessage="Learn" 
                />
            </Link>

            <Link to={"/events"} className="blocks" data-value="proceed-to-report-event">
              <img src={reportIcon} alt="report" />
              {/* <span>Report a change</span> */}
              <FormattedMessage 
                  id="home.Reportachange"
                  defaultMessage="Report a change" 
                />
            </Link>
            {
              employeeDetails?.empData?.company?.bencoinRequired && 
              <Link to={"/reimbursementD"} className="blocks">
              <img src={reimbursementIcon} alt="reimbursements" />
              <span>Reimbursements</span>
            </Link>
            }
            {
              employeeDetails?.empData?.company?.bencoinRequired && 
              <Link to={"/carLeaseProgram"} className="blocks">
              <img src={carLease} alt="carLeaseProgram" />
              <span>Car Lease</span>
            </Link>
            }            
            
            {/* <div className="blocks">
              <img src={timeOffIcon} alt="timeOff" />
              <span>Time off & Leave</span>
            </div> */}
            
            {/* <div className="blocks">
              <img src={horizontalThreeDotsIcon} alt="more" />
              <span>More</span>
            </div> */}
          </div>

          {
            employeeDetails?.empData?.company?.bencoinRequired && 
            <div className="bencoinBlock px-4 mt-8">
              <h1 className="title">
                {/* Bencoin plan options  */}
                <FormattedMessage 
                  id="home.Bencoinplanoptions"
                  defaultMessage="Bencoin plan options" 
                />
                </h1>
              <div className="grid grid-cols-4 gap-4 mt-8">

                <Link to={"/coinWizards"} className="blocks benEnroll" data-value="bencoin-enroll">
                    <img src={enrollIcon} alt="balance" />
                    {/* <span>Enroll with Bencoin plans</span> */}
                    <FormattedMessage 
                      id="home.EnrollwithBencoinplans"
                      defaultMessage="Enroll with Bencoin plans" 
                    />
                  </Link>

                <Link to={"/CoinTable"} className="blocks balances" data-value="bencoin-balances">
                    <img src={balanceIcon} alt="balance" />
                    {/* <span>Bencoin Balances</span> */}
                    <FormattedMessage 
                      id="home.BencoinBalances"
                      defaultMessage="Bencoin Balances" 
                    />
                  </Link>
              
              </div>
            </div>
          }

              <div
                // style={{ cursor: "pointer" }}
                className="elligible-plans-tabs mx-4 mt-8"
                // onClick={() =>
                //   history.push({
                //     pathname: "/all-benefits",
                //     state: {
                //       eligibleplans: eligibleplans,
                //     },
                //   })
                // }
              >
                {/* <h1 className="title">
                  <FormattedMessage 
                      id="home.AllBenefits"
                      defaultMessage="All Benefits" 
                    />
                  </h1> */}
                {/* <p className="flex viewAll">
                  <FormattedMessage 
                      id="home.Viewall"
                      defaultMessage="View all" 
                    />
                  <img className="arrow" src={arrowRight} alt="more" />
                </p> */}

                <div 
                className={`${activeElliPlanTab === "current" ? "active tab-plans" : "tab-plans"}`} 
                onClick={handleClickCurPlans}
                >
                  Current plans
                </div>
                {/* <div 
                className={`${activeElliPlanTab === "future" ? "active tab-plans" : "tab-plans"}`}
                onClick={handleClickFutPlans}
                >
                  Future plans
                </div> */}

              </div>

          
          {/* Old Home page content */}
          {(empLoader || elligiblePlanLoader) ? (
            
            <div className=" grid grid-cols-3 gap-4 px-4 mt-8">

              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ height: { md: "125px", xs: "70px" } }}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ height: { md: "125px", xs: "70px" } }}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ height: { md: "125px", xs: "70px" } }}
              />
            </div>
          ) : (
            <div className="healthPlans px-4 py-4">
             
              <ul class="tile_grid  block1 block2 gap-2">               

              {
                  eligibleplans?.map((val, index) => (
                    <>
                      <li>
                        <button
                          style={{ width: "100%" }}
                          onClick={() => handelSelectEnrollement(val)}
                          key={val?.planId}
                          className={
                            planId?.id === val?.planId && planId?.show
                              ? `boxShadow block-div color1
                                )}`
                              : `block-div color3`
                          }
                        >
                          <div className=" block2-image-div">
                            <img
                              src={val?.icon}
                              alt="Image1"
                              className="block-image"
                            />
                          </div>
                          <p className="block2-text">{val?.displayName}</p>
                          <p className="block2-sub_text">
                             {val?.enrollmentStatus?.toLowerCase() === "enrolled" ? ( 
                              <span className="flex justify-center items-center" style={{color:"var(--primary)"}}>
                                {" "}
                                {val?.enrollmentStatus}{" "}
                                <BsCheckAll
                                  style={{ fontSize: "1.1rem" }}
                                />{" "}
                              </span>
                             ) : (
                              <span style={{color:"var(--secondary"}}>{val?.enrollmentStatus}</span>
                            )} 
                          </p>
                        </button>
                      </li>

                      <li
                        className={`${
                          planId?.id === val?.planId && planId?.show
                            ? "fullwidth"
                            : "is-hidden"
                        }`}
                      >
                        {planLoader ? (
                          <div className=" grid grid-cols-1 gap-4 px-4 mt-8">

                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              sx={{ height: { md: "125px", xs: "70px" } }}
                            />
                          </div>
                        ) : (
                          <div className="box px-4 py-4 ">
                            <div className="flex justify-between">
                              <div className="flex sm:flex-row flex-col">
                                <img
                                  className="lifeInsurance invert"
                                  src={
                                    selectedEnrollPlan?.icon || lifeInsurance
                                  }
                                  alt="more"
                                />
                                <div className="px-4">
                                  <div>
                                    <h1 className="boxTitle">
                                      {selectedEnrollPlan?.displayName}
                                    </h1>
                                  </div>
                                  <div className="flex pt-2">
                                    
                                      <p className="mr-4">
                                         {selectedEnrollPlan?.enrollmentStatus?.toLocaleLowerCase() ===
                                        "enrolled" ? ( 
                                          <span className="flex justify-center items-center">
                                            {" "}
                                            {selectedEnrollPlan?.enrollmentStatus}{" "}
                                            <BsCheckAll
                                              style={{ fontSize: "1.1rem" }}
                                            />{" "}
                                          </span>
                                        ) : (
                                          <span style={{color:"var(--secondary)"}}>{selectedEnrollPlan?.enrollmentStatus}</span>
                                        )}{" "} 
                                      </p>
                                      <button
                                        onClick={() =>
                                          history.push(
                                            `/new-learning-page-tabs/${selectedEnrollPlan?.className?.toLowerCase()}/${selectedEnrollPlan?.planId}`
                                          )
                                        }
                                        className="more"
                                      >
                                        More Details
                                      </button>
                                   
                                 
                                  </div>
                                </div>
                              </div>                             

                              {selectedEnrollPlan?.enrollmentStatus?.toLocaleLowerCase() ===
                              "enrolled" ? (
                                <> <div>
                                Benefit Start Date :{" "}
                                {moment(
                                  eventData?.benefitStartDate?.slice(0,10)
                                ).format(
                                  `${
                                    employeeDetails?.empData?.company
                                      ?.dateFormat
                                      ? employeeDetails?.empData?.company?.dateFormat?.toUpperCase()
                                      : "DD/MM/YYYY"
                                  }`
                                )}
                              </div> 
                              <img
                                  className="checkRight"
                                  src={checkRight}
                                  alt="more"
                                />
                                </>
                               
                              ) : null}
                            </div>
                            <div className="grid grid-cols-2 gap-2 pt-8">
                              <div className="box1">
                                <div className="flex-1 borderR">
                                  {/* <p className="box1Title">Coverage Limit</p> */}
                                  <p className="box1Title">What is covered</p>
                                  <div
                                    className="flex pt-2"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <BiMoney />
                                    <span className="date ml-2">
                                      {selectedEnrollPlan?.enrollmentStatus?.toLocaleLowerCase() ===
                                        "enrolled" &&
                                         !isEmptyNullUndefined(eventData?.coverageString) ? 
                                         <>{eventData?.coverageString}</>
                                         :
                                          eventData?.coverage
                                        ? CurrencyFormat(
                                            eventData?.coverage,
                                            currenyCode
                                          )
                                        : //  CurrencyFormat(500000,currenyCode)
                                          "No Coverage Limit"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="box1">
                                {/* <p class="box1Title">Dependent</p> */}
                                <p class="box1Title">Who is covered</p>
                                <div class="grid grid-cols-2 gap-1 pt-2">
                                  {selectedEnrollPlan?.enrollmentStatus?.toLocaleLowerCase() ===
                                    "enrolled" &&
                                  eventData?.dependants &&
                                  eventData?.dependants?.length > 0 ? (
                                    eventData?.dependants?.map((val, ind) => (
                                      <p key={ind} class="name flex">
                                        <img src={dot} alt="" className="dot" />
                                        {val.name}
                                      </p>
                                    ))
                                  ) : (
                                    <p>No dependent</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    </>
                  ))}

                {/* <button className="block-div color2">
                    <div className="block2-image-div">
                      <img src={dental} alt="Image1" className="block-image" />
                    </div>
                    <p className="block2-text">Dental</p>
                    <p className="block2-sub_text">Not Enrolled</p>
                  </button>
                  <button className="block-div color3">
                    <div className="block2-image-div">
                      <img src={vision} alt="Image1" className="block-image" />
                    </div>
                    <p className="block2-text">Vision</p>
                    <p className="block2-sub_text">Not Enrolled</p>
                  </button> */}
              </ul>
            </div>
          )}
          {/* <div className="healthPlans px-4 mt-8 py-4">



              <h1 className="moreBenefits">More Benefits</h1>
              <div class="moreBenefitsBox grid grid-cols-3 block1 block2 gap-2 py-4">
                <button className="block-div color4">
                  <div className="block2-image-div">
                    <img src={additionalInsurance} alt="Image1" className="block-image" />
                  </div>
                  <p className="block2-text">Additional Insurance</p>
                  <p className="block2-sub_text">Not Enrolled</p>
                </button>
                <button className="block-div color4">
                  <div className="block2-image-div">
                    <img src={shortTermDisabiity} alt="Image1" className="block-image" />
                  </div>
                  <p className="block2-text">Short Term Disabiity</p>
                  <p className="block2-sub_text">Not Enrolled</p>
                </button>
                <button className="block-div color4">
                  <div className="block2-image-div">
                    <img src={longTermDisabiity} alt="Image1" className="block-image" />
                  </div>
                  <p className="block2-text">Long Term Disabiity</p>
                  <p className="block2-sub_text">Not Enrolled</p>
                </button>
                <button className="block-div color4">
                  <div className="block2-image-div">
                    <img src={gymMembership} alt="Image1" className="block-image" />
                  </div>
                  <p className="block2-text">Gym Membership</p>
                  <p className="block2-sub_text">Not Enrolled</p>
                </button>
              </div>
            </div> */}
        </>
      )}
    </div>
  );
};

export default Home;
