const { createAction } = require('redux-actions');

const EVENT_LOGS_DATA_REQUEST = 'BENEVOLVE/EVENT_LOGS_DATA_REQUEST';
const EVENT_LOGS_DATA_REQUEST_SUCCESS = 'BENEVOLVE/EVENT_LOGS_DATA_REQUEST_SUCCESS';
const EVENT_LOGS_DATA_REQUEST_FAILED = 'BENEVOLVE/EVENT_LOGS_DATA_REQUEST_FAILED';

 
const EventLogsDataRequest = createAction(EVENT_LOGS_DATA_REQUEST);
const EventLogsDataRequestSuccess = createAction(EVENT_LOGS_DATA_REQUEST_SUCCESS);
const EventLogsDataRequestFailed = createAction(EVENT_LOGS_DATA_REQUEST_FAILED);

 

export const actions = {
    EventLogsDataRequest,
    EventLogsDataRequestSuccess,
    EventLogsDataRequestFailed,    

     
};

export const types = {
    EVENT_LOGS_DATA_REQUEST,
    EVENT_LOGS_DATA_REQUEST_SUCCESS,
    EVENT_LOGS_DATA_REQUEST_FAILED,
 
};

