const { createAction } = require('redux-actions');

const EMPLOYEE_GROUP_MEDICAL_REQUEST = 'BENEVOLVE/EMPLOYEE_GROUP_MEDICAL_REQUEST';
const EMPLOYEE_GROUP_MEDICAL_REQUEST_SUCCESS = 'BENEVOLVE/EMPLOYEE_GROUP_MEDICAL_REQUEST_SUCCESS';
const EMPLOYEE_GROUP_MEDICAL_REQUEST_FAILED = 'BENEVOLVE/EMPLOYEE_GROUP_MEDICAL_REQUEST_FAILED';

const EmployeeGroupMedicalRequest = createAction(EMPLOYEE_GROUP_MEDICAL_REQUEST);
const EmployeeGroupMedicalRequestSuccess = createAction(EMPLOYEE_GROUP_MEDICAL_REQUEST_SUCCESS);
const EmployeeGroupMedicalRequestFailed = createAction(EMPLOYEE_GROUP_MEDICAL_REQUEST_FAILED);

const ENROLL_WIZARDS_REQUEST = 'BENEVOLVE/ENROLL_WIZARDS_REQUEST';
const ENROLL_WIZARDS_REQUEST_SUCCESS = 'BENEVOLVE/ENROLL_WIZARDS_REQUEST_SUCCESS';
const ENROLL_WIZARDS_REQUEST_FAILED = 'BENEVOLVE/ENROLL_WIZARDS_REQUEST_FAILED';

const EnrollWizardsRequest = createAction(ENROLL_WIZARDS_REQUEST);
const EnrollWizardsRequestSuccess = createAction(ENROLL_WIZARDS_REQUEST_SUCCESS);
const EnrollWizardsRequestFailed = createAction(ENROLL_WIZARDS_REQUEST_FAILED);

export const actions = {
    EmployeeGroupMedicalRequest,
    EmployeeGroupMedicalRequestSuccess,
    EmployeeGroupMedicalRequestFailed,

    EnrollWizardsRequest,
    EnrollWizardsRequestSuccess,
    EnrollWizardsRequestFailed,
};

export const types = {
    EMPLOYEE_GROUP_MEDICAL_REQUEST,
    EMPLOYEE_GROUP_MEDICAL_REQUEST_SUCCESS,
    EMPLOYEE_GROUP_MEDICAL_REQUEST_FAILED,

    ENROLL_WIZARDS_REQUEST,
    ENROLL_WIZARDS_REQUEST_SUCCESS,
    ENROLL_WIZARDS_REQUEST_FAILED,
};

