import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.EMPLOYEE_GROUP_MEDICAL_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.EMPLOYEE_GROUP_MEDICAL_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        empGroup: payload || {},
    }),
    [types.EMPLOYEE_GROUP_MEDICAL_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),

    [types.ENROLL_WIZARDS_REQUEST]: (state) => ({
        ...state,
        wizLoader: true,
    }),
    [types.ENROLL_WIZARDS_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        wizLoader: false,
        empWizards: payload || {},
    }),
    [types.ENROLL_WIZARDS_REQUEST_FAILED]: (state) => ({
        ...state,
        wizLoader: false,
    }),
};

export default handleActions(actionHandler, {
    loader: false,
    empGroup:{},
    wizLoader: false,
    empWizards: {},
});
