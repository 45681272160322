import { useEffect, useState } from "react";
import ellipseIcon from "../../../../assets/Ellipse3.png";
import icon from "../../../../assets/term/medicalcross.png";
import Rectangle from "../../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../../assets/radioCheck.svg";
import ButtonM from "../../../common/Button";
import PageHeader from "../../../common/PageHeader";
import PageTitleAndDescription from "../../../common/PageTitleAndDescription";
// import { useHistory } from "react-router-dom";
var classNames = require('classnames');

const LongTermDisability = () => {

  // const history = useHistory();

 const [dontEnroll, setDontEnroll] = useState(false);
 const [enroll, setEnroll] = useState(false);
  
  useEffect(
    () => {
      // dispatch(companyListsActions.CompanyListRequest());
    },
    [
      // dispatch
      
    ]
  );

  const handleAddEnroll = () => {
    // if(enroll){
    //   history.push('/voluntry/add-enroll')
    // }
  }

  return (
    <div className="longTermDisabilityPage">
      <h4 className="page-heading-navbar">Basic Medical</h4>
      <PageHeader
        heading={"Long-Term Disability"}
        description={`Long-term disability provides payments if you can’t work for [26] consecutive [weeks] or more due to sickness or a non-work injury.  You will receive [40%] of your annual Pay on a [weekly] basis for [the duration of your disability].`}
        btnText={"Click here for more details"}
        pageNumber={ "1 of 1" }
        eIcon={ellipseIcon}
        planIcon={icon}
      />
      <div className="data">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <PageTitleAndDescription
              title={"Choose your plan"}
              // description={`Benelove offers you the option to add your dependents to the medical plan.`}
            />
            <div className="w-full">
              <div className="radioButtonOuter">
                <button onClick={() => setDontEnroll(!dontEnroll)} className={classNames("radioButton",{'active': dontEnroll})}>
                  <div className="radioOuter">
                    <img src={Rectangle} alt="" className="radio" />
                    <img src={radioCheckIcon} alt="" className="radioCheck" />
                  </div>
                  I don’t want to enroll
                </button>                
                <button onClick={() => setEnroll(!enroll)} className={classNames("radioButton",{'active': enroll})}>
                  <div className="radioOuter">
                    <img src={Rectangle} alt="" className="radio" />
                    <img src={radioCheckIcon} alt="" className="radioCheck" />
                  </div>
                  Enroll in Long-Term Disabilty
                </button>                          
              </div>
            </div>
            <div className="bottomButtonReq">
              {/* <button className="next skip">I want to skip for now</button> */}
              <ButtonM text="NEXT" onpress={() => handleAddEnroll()} />
            </div>
          </div>
        </div>
      </div>      
    </div>
  );
};

export default LongTermDisability;
