import { createAction } from "redux-actions";

const EMPLOYEE_PLAN_ENROLLMENT_REQUEST = 'BENEVOLVE/EMPLOYEE_PLAN_ENROLLMENT_REQUEST';
const EMPLOYEE_PLAN_ENROLLMENT_REQUEST_SUCCESS = 'BENEVOLVE/EMPLOYEE_PLAN_ENROLLMENT_REQUEST_SUCCESS';
const EMPLOYEE_PLAN_ENROLLMENT_REQUEST_FAILED = 'BENEVOLVE/EMPLOYEE_PLAN_ENROLLMENT_REQUEST_FAILED';

const COMPANY_BANNER_REQUEST = 'BENEVOLVE/ECOMPANY_BANNER_REQUEST_REQUEST';
const COMPANY_BANNER_REQUEST_SUCCESS = 'BENEVOLVE/COMPANY_BANNER_REQUEST_SUCCESS';
const COMPANY_BANNER_REQUEST_FAILED = 'BENEVOLVE/COMPANY_BANNER_REQUEST_FAILED';


const EmployeePlanEnrollmentRequest = createAction(EMPLOYEE_PLAN_ENROLLMENT_REQUEST);
const EmployeePlanEnrollmentSuccess = createAction(EMPLOYEE_PLAN_ENROLLMENT_REQUEST_SUCCESS);
const EmployeePlanEnrollmentFailed = createAction(EMPLOYEE_PLAN_ENROLLMENT_REQUEST_FAILED);

//Banners request
const CompanyBannerRequest = createAction(COMPANY_BANNER_REQUEST);
const CompanyBannerRequestSuccess = createAction(COMPANY_BANNER_REQUEST_SUCCESS);
const CompanyBannerRequestFailed = createAction(COMPANY_BANNER_REQUEST_FAILED);


export const actions = {
    EmployeePlanEnrollmentRequest,
    EmployeePlanEnrollmentSuccess,
    EmployeePlanEnrollmentFailed,

    CompanyBannerRequest,
    CompanyBannerRequestFailed,
    CompanyBannerRequestSuccess
}

export const types = {
    EMPLOYEE_PLAN_ENROLLMENT_REQUEST,
    EMPLOYEE_PLAN_ENROLLMENT_REQUEST_SUCCESS,
    EMPLOYEE_PLAN_ENROLLMENT_REQUEST_FAILED,
    COMPANY_BANNER_REQUEST,
    COMPANY_BANNER_REQUEST_FAILED,
    COMPANY_BANNER_REQUEST_SUCCESS
}