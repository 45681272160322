// import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../api/index';
import { actions, types } from './actions';

const LifeInsuranceRequest = function* LifeInsuranceRequest({ payload }) {
    try {
        const { data } = yield call(api.lifeInsurance, payload);
        if (data) {
            yield put(actions.LifeInsuranceRequestSuccess(data));
        } else {
            // toast.error(data.message);
            yield put(actions.LifeInsuranceRequestFailed());
        }
    } catch (err) {
        yield put(actions.LifeInsuranceRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.LIFE_INSURANCE_REQUEST, LifeInsuranceRequest);
}
