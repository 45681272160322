import { useCallback, useEffect, useMemo, useState } from "react";
import Rectangle from "../../../../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../../../../assets/radioCheck.svg";
import ButtonM from "../../../../../common/Button";
// import VoluntaryParentalModal from "../../../ViewModal/index";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as VolutryActions } from "../../../redux/actions";
import { actions as DependantActions } from "../../../../BasicMedical/AddDependentAddress/redux/actions";
import { Backdrop, Button, CircularProgress, Dialog, Skeleton } from "@mui/material";
import {
  getAge,
  getAllowedEmp,
  getElligiblePlanDependant,
  getEventName,
  getNoOfDependantEnrollFromThisPlan,
  getRelationName,
  getRelationNames,
} from "components/utils/utils";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";
import APIList from "api";
import { toast } from "react-toastify";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { actions as getPlanActions } from "../../../../../common/Plans/redux/actions";
import BenModal from "components/common/Modal/BenModal";
import RateCard from "../RateCard";
import { FiEdit } from "react-icons/fi";

const classNames = require("classnames");

const NiumIndiaBundleTypeAddParents = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    selectedCategories,
    progressArray,
    bundleEnrolls,
    currentlyEnrolled,
  } = useSelector((state) => state?.voluntryReducer);

  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const lifeEventConfiguratorDTO = useSelector((state) => state?.planReducer?.lifeEventConfiguratorDTO);
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const currentDependants = selectedCategories[progressArray?.length]?.includes("-")
    ? selectedCategories[progressArray?.length].split("-")
    : selectedCategories[progressArray?.length];
  const empDependant = useSelector((state) => state && state.dependents && state.dependents.allDependents);
  const dependantLoader = useSelector((state) => state?.dependents?.loader);
  const isShowRates = useSelector((state) => state?.voluntryReducer?.isShowRates);

  const [dontWannaEnroll, setDontWannaEnroll] = useState(false);
  const [enrollLoader, setEnrollLoader] = useState(false);
  const [viewRateCard, setViewRateCard] = useState(false);
  const [elligibleToEnroll, setElligibleToEnroll] = useState(false);
  const [openRateAlertDialog, setOpenRateAlertDialog] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [elligiblePlanDependant, setElligiblePlanDependant] = useState([]);
  const [allowedEmp, setAllowedEmp] = useState(null);
  const [allowedCurrentEmpDependants, setAllowedCurrentEmpDependants] = useState(null);
  const [enrolledDependants, setEnrolledDependants] = useState([]);
  const [currentPlanDependant, setCurrentPlanDependant] = useState({});

  
  // console.log("elligiblePlanDependant",elligiblePlanDependant);
  // console.log("allowedCurrentEmpDependants", allowedCurrentEmpDependants);
  // console.log("allowedEmp", allowedEmp);

  useEffect(() => {
    if(Object.keys(selectedPlan)?.length == 0){
        dispatch(VolutryActions.SetCurrentlyEnrolled([]));
        history.push("/plans");
      }
  }, []);

  useEffect(() => {
    dispatch(
      DependantActions.GetDependentRequest({
        empId: employeeDetails && employeeDetails.id,
      })
    );
  }, []);

  useEffect(() => {
    if (location?.state?.fromFinalPage) {
      setEnrolledDependants(currentlyEnrolled);
    } else {
      dispatch(VolutryActions.SetCurrentlyEnrolled([]));
    }
  }, []);

  Array.prototype.equals = function (array, strict) {
    if (!array) return false;

    if (arguments.length == 1) strict = true;

    if (this.length != array.length) return false;

    for (var i = 0; i < this.length; i++) {
      if (this[i] instanceof Array && array[i] instanceof Array) {
        if (!this[i].equals(array[i], strict)) return false;
      } else if (strict && this[i] != array[i]) {
        return false;
      } else if (!strict) {
        return this.sort().equals(array.sort(), true);
      }
    }
    return true;
  };

  useEffect(() => {
    if (currentDependants == "self") {
      !selectedPlan?.planOptOut && setEnrolledDependants(["Self"]);
    }
    setElligiblePlanDependant(
      getElligiblePlanDependant(selectedPlan, employeeDetails)
    );
  }, [employeeDetails, progressArray]);

  useEffect(() => {
    setAllowedEmp(
      getAllowedEmp(
        empDependant,
        elligiblePlanDependant,
        lifeEventConfiguratorDTO
      )
    );
  }, [elligiblePlanDependant, empDependant]);

  useEffect(() => {
    getallowedCurrentEmpDependants();
  }, [allowedEmp]);

  const handleDependentSelection = (dep) => {
    enrolledDependants.filter((x) => x.id === dep.id).length > 0
      ? setEnrolledDependants(enrolledDependants.filter((x) => x.id !== dep.id))
      : setEnrolledDependants([...enrolledDependants, dep]);
  };

  const getallowedCurrentEmpDependants = () => {
    let arr = [];
    if (lifeEventConfiguratorDTO != null) {
      let relArr = [];
      if (lifeEventConfiguratorDTO?.relationships?.includes("NA")){
        relArr = ["Parent", "PIL"];
      } else if (
        lifeEventConfiguratorDTO?.relationships?.includes("Parent") &&
        lifeEventConfiguratorDTO?.relationships?.includes("PIL")
      ) {
        relArr = ["Parent", "PIL"];
      } else if (lifeEventConfiguratorDTO?.relationships?.includes("PIL")) {
        relArr = ["PIL"];
      } else if (lifeEventConfiguratorDTO?.relationships?.includes("Parent")) {
        relArr = ["Parent"];
      } 
      arr = allowedEmp?.filter((x) => relArr?.includes(x.relation));
    } else {
      arr = allowedEmp?.filter((x) =>
        ["Parent", "PIL"]?.includes(x.relation)
      );
    }
    setAllowedCurrentEmpDependants(arr);
  };

  const handleAddDependent = () => {    
    dispatch(DependantActions.SaveDependantInfo(null));                        
    dispatch(DependantActions.SaveDependantAddress(null));
      history.push("/voluntry/bundle-addDep-new");
  };
console.log("isShowRates",isShowRates)
const proceedNext = () => {
  if(!isShowRates && !dontWannaEnroll){
    setOpenRateAlertDialog(true);
  } else {
    handleNext();
  }
}
  const handleNext = () => {
    if(!isShowRates && !dontWannaEnroll){
      setViewRateCard(true);
      dispatch(VolutryActions.ViewedPremiumRates(true));
    } else {      
      if (dontWannaEnroll) {
        setEnrollLoader(true);
        APIList.healthInsuranceEnroll({
          employee: {
            id: employeeDetails.id,
          },
          isDraft: true,
          status: "ISDRAFT",
          isEmployeeEnrolled: false,
          planOptOut: dontWannaEnroll,
          selectedCoverage: null,
          // {
          //   id: selectedPlan?.coveragePlans[0]?.id
          // },
          employeeCoverage: null,
          // selectedPlan?.coveragePlans[0]?.amount,
          plan: {
            id: selectedPlan.id,
            className: selectedPlan.className,
          },
          enrollmentDate: new Date(),
          dependantEnrollments: null,
        })
          .then((res) => {
            res &&
              toast.success(
                <div className="flex flex-row">
                  <BsFillCheckCircleFill
                    style={{ width: "2rem", height: "2rem" }}
                  />
                  &nbsp;&nbsp;You have opted out of this plan successfully
                </div>
              );
            setEnrollLoader(false);
            // history.push({ pathname: "/plans", state: { fromAddress: true } });
            dispatch(getPlanActions.PlanDataRequest("clearData"));
            history.push({pathname:'/loading', state:{planName:selectedPlan?.displayName}});
          })
          .catch((err) => {
            toast.error(
              <div className="flex flex-row">
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;{err?.title}
              </div>
            );
            setEnrollLoader(false);
          });
      } else {
        dispatch(VolutryActions.SetCurrentlyEnrolled(enrolledDependants));
        history.push("/voluntry/bundle-coverage-selection-new");
      }
    }
   
  };

  //   window.onpopstate = () => {
  //     dispatch(VolutryActions.SetSelectedCategories([]));
  //     dispatch(VolutryActions.SetCurrentlyEnrolled([]));
  //  };

  useEffect(() => {
    getIsValidToEnroll();
  }, [enrolledDependants]);

  const getIsValidToEnroll = () => {
    if (!dontWannaEnroll && enrolledDependants?.length === 0) {
      setElligibleToEnroll(false);
    } else {
      if (enrolledDependants?.length > selectedPlan?.totalLiveMaxLimit) {
        setElligibleToEnroll(false);
      } else {
        elligiblePlanDependant?.forEach((dep) => {
          getNoOfDependantEnrollFromThisPlan(dep, enrolledDependants) >
          dep.dependantLimit
            ? setElligibleToEnroll(false)
            : setElligibleToEnroll(true);
        });
      }
    }
  };

  const parentSelectionError = () => {
    let parents = [];
    let PILs = [];
    enrolledDependants?.forEach((x) => {
      if(x.relation === "Mother" || x.relation === "Father"){
        parents.push(x.relation);
      }
      if(x.relation === "FIL" || x.relation === "MIL"){
        PILs.push(x.relation);
      }
    });

    if(parents.length > 2 || PILs.length > 2){
      return true;
    }else{
      return false;
    }
  };

  const isAgeMismatch = useMemo(() => {
    let mismatch = "no err";
    enrolledDependants?.forEach((dep) => {
      elligiblePlanDependant.forEach((pd) => {
        if((dep?.relation === "Father") || (dep?.relation === "Mother")){
          if(pd?.relation?.includes("Parent") || pd?.relation?.includes(dep.relation)){
            if (
                (getAge(dep.dateOfBirth) >
                (pd.maxAge !== null ? pd.maxAge : 1000)) ||
                (getAge(dep.dateOfBirth) < (pd.minAge !== null ? pd.minAge : -1))
              ) {
                mismatch = `this plan allows ${dep?.relation} between ${pd.minAge}-${pd.maxAge} years of age only`;
              }}
        } else if((dep?.relation === "FIL") || (dep?.relation === "MIL")){
          if(pd?.relation?.includes("PIL") || pd?.relation?.includes(dep.relation)){
            if (
                (getAge(dep.dateOfBirth) >
                (pd.maxAge !== null ? pd.maxAge : 1000)) ||
                (getAge(dep.dateOfBirth) < (pd.minAge !== null ? pd.minAge : -1))
              ) {
                mismatch = `this plan allows ${dep?.relation} between ${pd.minAge}-${pd.maxAge} years of age only`;
              }}
        } else if(pd?.relation?.includes(dep.relation)){
          if (
              (getAge(dep.dateOfBirth) >
              (pd.maxAge !== null ? pd.maxAge : 1000)) ||
              (getAge(dep.dateOfBirth) < (pd.minAge !== null ? pd.minAge : -1))
            ) {
              mismatch = `this plan allows ${dep?.relation} between ${pd.minAge}-${pd.maxAge} years of age only`;
            }}
      })
    })
    return mismatch;
  },[enrolledDependants]);

  const showWorkingDeclarationErr = useMemo(() => {
    let response = "no err";
    if(!selectedPlan?.allowDependentInSameCompany){
      enrolledDependants?.forEach((dep) => {
        if(dep?.isRelativeWorkingInNium){
          // response = `This plan does not allow to add dependents working in ${employeeDetails?.company?.displayName}`
          response = `Our records show your ${dep?.firstName} ${dep?.lastName} is working in ${employeeDetails?.company?.displayName} and hence cannot be enrolled as Dual cover is not provided`
        }
      })
    }
    return response;
  },[enrolledDependants]);

  const handleEditDependant = (id) => {    
    dispatch(DependantActions.SaveDependantInfo(null));                        
    dispatch(DependantActions.SaveDependantAddress(null));
    history.push({
      pathname: "/voluntry/bundle-addDep-new",
      state: { isDepEdit:true, id:id },
    })
  }

  return (
    <div className="voluntryAddParentsPage">
      {
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={deleteLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }

      {selectedPlan && (
        <div>
          <h4 className="page-heading-navbar">{selectedPlan.displayName}</h4>
          <PageHeaderForPlans 
          // btnRedirect={() => setOpenModal(true)} 
          />
        </div>
      )}
      {/* <p
        className="addLink-outside-text"
        onClick={() => setViewRateCard(true)}
      >
        VIEW PREMIUM RATES
      </p> */}
      <div className="data">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <div className="w-full">
              {selectedPlan?.planOptOut && (
                <div className="radioButtonOuter">
                  <button
                    onClick={() => {setDontWannaEnroll(!dontWannaEnroll) ; setEnrolledDependants([])}}
                    className={classNames("radioButton", {
                      active: dontWannaEnroll,
                    })}
                  >
                    <div className="radioOuter">
                      <img src={Rectangle} alt="" className="radio" />
                      <img src={radioCheckIcon} alt="" className="radioCheck" />
                    </div>
                    I don't want to Enroll
                  </button>
                </div>
              )}
              <div className="radioButtonOuter">
                {dependantLoader ? (
                  <>
                    <Skeleton width={"48%"} height={69} />
                    <Skeleton width={"48%"} height={69} />
                    <Skeleton width={"48%"} height={69} />
                    <Skeleton width={"48%"} height={69} />
                  </>
                ) : (
                  !dontWannaEnroll && allowedCurrentEmpDependants?.map((dependent, i) => {
                    return (
                      <button
                        className={classNames("radioButton justify-between", {
                          active:
                            enrolledDependants.filter(
                              (x) => x.id === dependent.id
                            ).length > 0,
                        })}
                        onClick={() => {
                          handleDependentSelection(dependent);
                        }}
                      >
                        <div className="flex flex-row justify-start capitalize">
                          <div className="radioOuter">
                            <img src={Rectangle} alt="" className="radio" />
                            <img
                              src={radioCheckIcon}
                              alt=""
                              className="radioCheck"
                            />
                          </div>
                          <div>
                            {dependent && dependent.firstName}{" "}
                            {dependent && dependent.lastName}
                          </div>
                        </div>
                        <span onClick={() => handleEditDependant(dependent.id)}>
                                <FiEdit />
                              </span>
                      </button>
                    );
                  })
                )}
                </div>
                {parentSelectionError() && (
                  <p className="enrollValidations">
                    can add only 2 parents and 2 parents in law
                  </p>
                )}
                {isAgeMismatch !== "no err" && (
                  <p className="enrollValidations">
                    {isAgeMismatch}
                  </p>
                )}
                {showWorkingDeclarationErr !== "no err" && (
                  <p className="enrollValidations">
                    {showWorkingDeclarationErr}
                  </p>
                )}
                {enrolledDependants?.length >
                  currentPlanDependant?.dependantLimit && (
                  <p
                    className="flex items-center mx-auto my-4"
                    style={{ color: "var(--warning)" }}
                  >
                    {/* <BiError style={{ fontSize: "1.2rem" }} /> */}
                    &nbsp; Can not select more than{" "}
                    {currentPlanDependant?.dependantLimit} dependants in this
                    category
                  </p>
                )}
                <>
                  {!dontWannaEnroll &&
                  (enrolledDependants && enrolledDependants.length) >
                    (selectedPlan && selectedPlan.totalLiveMaxLimit) ? (
                    <p className="enrollValidations">
                      {/* <BsExclamationTriangle />   */}
                      This plan policy does not allow to add more than{" "}
                      {selectedPlan && selectedPlan.totalLiveMaxLimit}{" "}
                      dependents{" "}
                    </p>
                  ) : (
                    !dontWannaEnroll && (
                      <p className="enrollValidations">
                        {elligiblePlanDependant &&
                          elligiblePlanDependant.map((dep) => {
                            return (
                              // dep && dep.relation &&
                              // dep.relation.map((relation) => {
                              //   return (
                              <p>
                                {
                                  // getNoOfTimesRelationRepeated(relation)
                                  getNoOfDependantEnrollFromThisPlan(
                                    dep,
                                    enrolledDependants
                                  ) >
                                  (dep.dependantLimit == null
                                    ? 1000
                                    : dep.dependantLimit)
                                    ? `This plan policy does not allow to add more than ${
                                        dep && dep.dependantLimit
                                      }${" "}${getRelationNames(dep.relation)}`
                                    : ""
                                }
                              </p>
                              //   );
                              // })
                            );
                          })}
                      </p>
                    )
                  )}
                  {/* {coveragePlan === null && (
                <p className="enrollValidations">
                  You don't have any coverage plan
                </p>
              )} */}

                  <div className="w-full linksOuter my-4">
                    <p
                      className="addLink"
                      onClick={() =>
                        handleAddDependent(
                          [`FIL`, `MIL`, "Father", "Mother"],
                          "PIL"
                        )
                      }
                    >
                      Add Dependent
                    </p>
                    <p
                      className="addLink"
                      onClick={() => {setViewRateCard(true); dispatch(VolutryActions.ViewedPremiumRates(true));}}
                    >
                      VIEW PREMIUM RATES
                    </p>
                  </div>
                </>
              </div>
            {/* </div> */}
            <div className="bottomButtonReq">
              <ButtonM
                text="NEXT"
                disabled={(!elligibleToEnroll && !dontWannaEnroll) || 
                  (!dontWannaEnroll && isAgeMismatch !== "no err") || 
                  (!dontWannaEnroll && showWorkingDeclarationErr !== "no err") || 
                  parentSelectionError()}
                bgcolor={((!elligibleToEnroll && !dontWannaEnroll) || 
                  (!dontWannaEnroll && isAgeMismatch !== "no err") || 
                  (!dontWannaEnroll && showWorkingDeclarationErr !== "no err") || 
                  parentSelectionError()) ? 
                  "gray" : ""}
                onpress={() => proceedNext()}
              />
            </div>
          </div>
        </div>
      </div>
      {viewRateCard && (
        <BenModal setViewDetails={setViewRateCard}>
          <RateCard plan={selectedPlan} />
        </BenModal>
      )}
      {/* {openModal && <VoluntaryParentalModal setOpenModal={setOpenModal} />} */}
      <Dialog
        open={!isShowRates && openRateAlertDialog}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{color:"var(--primary)", fontFamily:"poppins", margin:"1rem", fontWeight:"600"}}>
          Please view Premium Rates before proceeding
        </div>
        <Button
           onClick={() => handleNext()} 
           sx={{backgroundColor:"var(--secondary)", color:"var(--primary)", "&:hover":{backgroundColor:"var(--secondary)"}, fontFamily:"poppins", margin:"1rem auto"}}
           autoFocus
           >
            Continue
          </Button>
      </Dialog>
    </div>
  );
};

export default NiumIndiaBundleTypeAddParents;
