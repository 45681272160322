import { Box } from "@mui/material";
import PolicyCoverForPlans from "../../../../common/PolicyCoverForPlans";
import ButtonM from "../../../../common/Button";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const GroupCover = () => {

    const history = useHistory();

    const handleNext = () => history.push('/basicMedical');

    return (
        <Box>
            <PolicyCoverForPlans />
            <div className="bottomButtonReq">
                <ButtonM text={<FormattedMessage id="common.button.NEXT" defaultMessage="NEXT"/>} onpress={() => handleNext()} />
            </div>
        </Box>
    )
};

export default GroupCover;
