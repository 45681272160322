import APIList from "api";
import { useEffect, useState } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { FaFilePdf } from "react-icons/fa";
import { Button, CircularProgress } from "@mui/material";
// import { CurrencyFormat } from "components/utils/numberFormatter";
import BenSumTable from "./BenSumTable/BenSumTable";
import { isEmptyNullUndefined } from "components/utils/utils";
import { FormattedMessage } from "react-intl";
import moment from 'moment';

const BenefitSummary = () => {

    const employeeDetails = useSelector((state) => state?.empData?.empData)
    const currency = employeeDetails?.company?.currency;
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);  

    const [summaryData, setsummaryData] = useState(null);
    const [loadingSummary, setLoadingSummary] = useState(false);

    useEffect(() => {
      setLoadingSummary(true);
      APIList.benefitSummary({empId:employeeDetails?.id})
      .then((res) => {
        setsummaryData(res.data);
        setLoadingSummary(false);
      })
      .catch((err) => {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        setLoadingSummary(false);
    })
    }, [employeeDetails]);

    const currencyFormat = (amount) => {
        if(amount == "--"){
            return amount
        }
        if (currency === "INR") {
    
          // return (parseFloat(amount?.toFixed(1)).toLocaleString('en-IN'));
    
          return (Math.round(amount).toLocaleString('en-IN'));
    
        } else {
    
          // return (parseFloat(amount?.toFixed(1)).toLocaleString('en-US'));
    
          return (Math.round(amount).toLocaleString('en-US'));
          
        }
      }

const handleDownloadPDF = () => {
    const doc = new jsPDF();
    // Set font style
doc.setFont("helvetica", "bold"); // Set font family and weight
doc.setFontSize(25); // Set font size

     doc.text(`${employeeDetails?.company?.companyName}`,10,10);

    // Set font style
doc.setFont("helvetica"); // Set font family and weight
doc.setFontSize(10); // Set font size

     doc.text(`${employeeDetails?.firstName ? employeeDetails?.firstName : " "} ${employeeDetails?.lastName ? employeeDetails?.lastName : " "}`,10,30);
     doc.text(`${employeeDetails?.address?.streetLineOne ? employeeDetails?.address?.streetLineOne + "," : " "} ${employeeDetails?.address?.streetLineTwo ? employeeDetails?.address?.streetLineTwo + "," : " "}`,10,35);
     doc.text(`${employeeDetails?.address?.city ? employeeDetails?.address?.city + "," : " "} ${employeeDetails?.address?.state ? employeeDetails?.address?.state + "," : " "}`,10,40);
     doc.text(`${employeeDetails?.address?.postalCode ? employeeDetails?.address?.postalCode : " "}`,10,45);

    const getDepData = (plan) => {
        // if(plan?.className === "lifeinsurance"){
        //     return getBen(plan?.beneficiaryAllocation);
        // }else{
            return getDep(plan);
        // }
    };
 
    const getDep = (plan) => {
        let interestsHtml = '';
        if( plan?.employeeEnrolled){
            interestsHtml += `${employeeDetails?.firstName ? employeeDetails?.firstName : " "} ${employeeDetails?.lastName ? employeeDetails?.lastName : ""},`
            interestsHtml += `\n`;            
        }
        if(plan?.className === "lifeinsurance"){
            // plan?.beneficiaryAllocation?.forEach((dep, index) => {
            //     interestsHtml += `${dep?.beneficiary?.firstName} ${isEmptyNullUndefined(dep?.beneficiary?.lastName) ? "" : dep?.beneficiary?.lastName}${plan?.beneficiaryAllocation?.length !== (index + 1) ? ", ":""}`;
            // });
        } else {
            plan?.dependants?.forEach((dep, index) => {
                interestsHtml += `${dep?.firstName} ${dep?.lastName} (DOB - ${moment(dep?.dateOfBirth).format(dateFormat?.toUpperCase())})${plan?.dependants?.length !== (index + 1) ? ", ":""}`
                interestsHtml += `\n`;
             });
        }
        
        return interestsHtml;
    };

    const getBen = (benificiaries) => {
        let interestsHtml = '';
        benificiaries?.forEach((dep, index) => {
            interestsHtml += `${dep?.dependants?.firstName} ${dep?.dependants?.lastName}${benificiaries?.length !== (index + 1) ? ", ":""}`;
        });
        return interestsHtml;
    };

    const getCoverageToShow = (plan) => {
        if (!isEmptyNullUndefined(plan?.employeeCoverageString)) {
            return plan?.employeeCoverageString
        } else {
            return currencyFormat(plan?.employeeCoverage, currency)
        }             
    };    

     doc.autoTable({ 
        head: [['Benefit', 'Coverage', 'Covered', {content: 'Annual cost', styles: {halign: 'center'}}]],
        body: summaryData?.map(plan => [plan?.planName, getCoverageToShow(plan), getDepData(plan), currencyFormat(plan?.employeeCost, currency)
        ]), columnStyles: { 2: { cellWidth: 40  },3: { halign: 'center'} }, startY: 50 
      });

    doc.save(`benefit_summary_${employeeDetails?.firstName}-${employeeDetails?.lastName}.pdf`);
    // });
    
  };

 return(
        <div className="benefit-summary-main mx-4">
            <h4 className="page-heading-navbar">
                {/* Benefit Summary */}
                <FormattedMessage id='sidebar.BenefitSummary' defaultMessage='Benefit Summary'/>
            </h4>
            <div className="w-full flex flex-row-reverse p-4">
            <Button onClick={handleDownloadPDF} variant="outlined" startIcon={<FaFilePdf />}>
                {/* Download PDF */}
                <FormattedMessage id='sidebar.BenefitSummary.DownloadPDF' defaultMessage='Download PDF'/>
            </Button>
            </div>

            <BenSumTable loadingSummary={loadingSummary} summaryData={summaryData} location="notPlans"/>
            
        </div>
    );
}

export default BenefitSummary;