import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../api/index';
import { actions, types } from './action';
// import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";

const EventLogsRequest = function* EventLogsRequest({ payload }) {
    try {
        const { data } = yield call(api.getEventLogs, payload);
        if (data) {
            yield put(actions.EventLogsDataRequestSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.EventLogsDataRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.EventLogsDataRequestFailed());
    }
};

 


export default function* sagas() {
    yield takeLatest(types.EVENT_LOGS_DATA_REQUEST, EventLogsRequest);
  
}
