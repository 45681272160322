import { createAction } from "redux-actions";

const GET_REIMBURSE_PLAN_REQUEST = "GET_REIMBURSE_PLAN_REQUEST";
const GET_REIMBURSE_PLAN_REQUEST_SUCCESS = "GET_REIMBURSE_PLAN_REQUEST_SUCCESS";
const GET_REIMBURSE_PLAN_REQUEST_FAIL = "GET_REIMBURSE_PLAN_REQUEST_FAIL";

const GET_ALL_CLAIMS_REQUEST = 'BENEVOLVE/GET_ALL_CLAIMS_REQUEST';
const GET_ALL_CLAIMS_REQUEST_SUCCESS = 'BENEVOLVE/GET_ALL_CLAIMS_REQUEST_SUCCESS';
const GET_ALL_CLAIMS_REQUEST_FAILED = 'BENEVOLVE/GET_ALL_CLAIMS_REQUEST_FAILED';

const getReimbursePlanRequest = createAction(GET_REIMBURSE_PLAN_REQUEST);
const getReimbursePlanRequest_success = createAction(GET_REIMBURSE_PLAN_REQUEST_SUCCESS);
const getReimbursePlanRequest_failed = createAction(GET_REIMBURSE_PLAN_REQUEST_FAIL);

const getAllClaimsRequest = createAction(GET_ALL_CLAIMS_REQUEST);
const getAllClaimsRequestSuccess = createAction(GET_ALL_CLAIMS_REQUEST_SUCCESS);
const getAllClaimsRequestFailed = createAction(GET_ALL_CLAIMS_REQUEST_FAILED);


export const reimburseActions = {
    getReimbursePlanRequest,
    getReimbursePlanRequest_success,
    getReimbursePlanRequest_failed,

    getAllClaimsRequest,
    getAllClaimsRequestSuccess,
    getAllClaimsRequestFailed,

}


export const reimburseTypes = {
    GET_REIMBURSE_PLAN_REQUEST,
    GET_REIMBURSE_PLAN_REQUEST_SUCCESS,
    GET_REIMBURSE_PLAN_REQUEST_FAIL,

    GET_ALL_CLAIMS_REQUEST,
    GET_ALL_CLAIMS_REQUEST_SUCCESS,
    GET_ALL_CLAIMS_REQUEST_FAILED,
}
