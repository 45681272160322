import ButtonM from "components/common/Button";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { actions as VolutryActions } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { CurrencyFormat } from "components/utils/numberFormatter";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";
import { AiOutlinePlus } from "react-icons/ai";
// import VoluntaryParentalModal from "components/pages/Voluntry/ViewModal";
var classNames = require("classnames");

const NiumIndBundleCoverageSelection = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const { currentlyEnrolled } = useSelector((state) => state?.voluntryReducer);
  
  const employeeDetails = useSelector((state) => state?.empData?.empData);

  const currency = employeeDetails?.company?.currency;
  const deductionMethod = selectedPlan?.deductionMethod;
  const lifeEventConfiguratorDTO = useSelector(
    (state) => state?.planReducer?.lifeEventConfiguratorDTO
  );

  const [allowedRatecards, setallowedRatecards] = useState([]);
  const [selectedCoverageCard, setSelectedCoverageCard] = useState([]);
  const [selectedDiv, setselectedDiv] = useState("");
  const [openModal, setOpenModal] = useState(false);
  // console.log("allowedRatecards", allowedRatecards);

  useEffect(() => {
    if(Object.keys(selectedPlan)?.length == 0){
        dispatch(VolutryActions.SetCurrentlyEnrolled([]));
        history.push("/plans");
      }
  }, []);
  
  useEffect(() => {
    const rateCards = selectedPlan?.rateCard?.rateCardItems?.sort(
      (a, b) => a?.sumInsured - b?.sumInsured
    );
    setallowedRatecards(rateCards);
  }, []);

  const getElligiblePlanDependant = (planDependants) => {
    let legitDependents = [];

    planDependants?.forEach((x) =>
      x?.employeeRoles?.forEach((y) => {
        if (y?.id === employeeDetails?.employeeRole?.id) {
          legitDependents.push(x);
        }
      })
    );
    return legitDependents.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
  };

  const getDependantSplit = (rateCard, life) => {
    const noOfLife = life === 4 ? 2 : life;
    const planDep = selectedPlan?.coveragePlans?.filter(
      (item) => typeof item === "object"
    );
    const elligibleDep = getElligiblePlanDependant(planDep);

    let bundle = elligibleDep[0]?.bundleFlatFees;
    let PremiumToBePaid = null;

    const oneLifeBundle = bundle?.filter((x) => x.life == noOfLife)[0];
    const RC = rateCard?.bundlePremium?.filter((x) => x.life == noOfLife)[0];

    if (deductionMethod == "Percent") {
      PremiumToBePaid = (RC?.value * oneLifeBundle?.value) / 100;
    } else if (deductionMethod == "Flat") {
      PremiumToBePaid = oneLifeBundle?.value;
    } else if (deductionMethod == "NA") {
      PremiumToBePaid = 0;
    }

    return CurrencyFormat(PremiumToBePaid, currency);
  };

  const getDependantRelCat = (dep) => {
    if(dep?.relation === "Father" || "Mother"){
        return "Parent";
    } else if(dep?.relation === "FIL" || "MIL"){
        return "PIL";
    }
  };

  const getDependantId = (dep) => {
    const planDep = selectedPlan?.coveragePlans?.filter(
        (item) => typeof item === "object"
      );
      const elligibleDep = getElligiblePlanDependant(planDep);
  
      let id = elligibleDep?.filter((z) => z?.relation?.includes(getDependantRelCat(dep)))[0]?.id;
    return id;
  };

  const handleSelectCoverage = (ratecard) => {
    // if (lifeEventConfiguratorDTO != null) {
    //   if (lifeEventConfiguratorDTO?.modificationAllowed) {
    //     let selected = currentlyEnrolled?.map((x) => {
    //       return {
    //         name: x.firstName,
    //         dependantId: x.id,
    //         planDependantId: getDependantId(x),
    //         relation: x.relation,
    //         coverageId: ratecard.id,
    //         amount: ratecard.sumInsured,
    //         category: getDependantRelCat(x),
    //         typeSelected: "group",
    //       };
    //     });
    //     setSelectedCoverageCard(selected);
    //     setselectedDiv(ratecard.id);
    //   }
    // } else {
      let selected = currentlyEnrolled?.map((x) => {
        return {
          name: x.firstName,
          dependantId: x.id,
          planDependantId: getDependantId(x),
          relation: x.relation,
          coverageId: ratecard.id,
          amount: ratecard.sumInsured,
          category: getDependantRelCat(x),
          typeSelected: "group",
        };
      });
      setSelectedCoverageCard(selected);
      setselectedDiv(ratecard.id);
    // }
  };

  const handleNext = () => {
    if (selectedCoverageCard?.length < 1) {
      toast.info(
        <div className="flex flex-row">
          <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
          &nbsp;&nbsp;Please select coverage to continue.
        </div>
      );
    } else {
      
        if (selectedCoverageCard?.length > 0) {
          dispatch(
            VolutryActions.setBundleEnrollList([
              ...selectedCoverageCard
            ])
          );
        }
        history.push("/voluntry/bundle-final-new");
      }
    
  };

  return (
    <div className="singleDependant">
      {selectedPlan && (
        <div>
          <h4 className="page-heading-navbar">{selectedPlan.displayName}</h4>
          <PageHeaderForPlans 
          // btnRedirect={() => setOpenModal(true)} 
          />
        </div>
      )}
      {allowedRatecards?.length == 0 ? (
        <div className="my-16 text-center"> No plan available </div>
      ) : (
        <div className="mx-4">
        {
            currentlyEnrolled?.length === 4 ? 
            <div className="w-full flex flex-row mt-4 relative">
                <div className="w-full mx-4 cCount">Sum Insured</div>
                <div className="w-1/5 mx-4 parentBundle">2 Parents bundle Cost / annum</div>
                <div className="w-1/5 mx-4 pilBundle">2 Parents in law bundle Cost / annum</div>
                <div className="blank"></div>
            </div>
            :
            <div className="w-full flex flex-row mt-4 relative">
                <div className="w-full mx-4">Sum Insured</div>
                <div className="w-full mx-4">*Employee Cost / annum</div>
            </div>
          
        }
          

          {allowedRatecards?.map((ratecard) => {
            return (
              <div
                key={ratecard?.id}
                // className="w-full flex flex-row card mt-4 relative"
                className={classNames(
                  "w-full flex flex-row card mt-4 relative",
                  {
                    activeCard:
                      ratecard.id == selectedDiv,
                      // && (currentEnrollingId == currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id)
                  }
                )}
              >
                <div className={currentlyEnrolled?.length === 4 ? "cCount" : "w-full flex justify-between items-center sm:mx-4 mr-2 cardInner"}>
                  <div className="left">
                    <p className="price">
                      {CurrencyFormat(ratecard?.sumInsured, currency)}
                    </p>
                  </div>
                  {
                  currentlyEnrolled?.length !== 4 && 
                  <div className="right">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.0677 11.9929L18.818 7.75739L17.4061 9.17398L19.2415 11.0032L0.932469 11.0012L0.932251 13.0012L19.2369 13.0032L17.4155 14.8308L18.8321 16.2426L23.0677 11.9929Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
          }
                </div>
                
                <div className="w-full flex justify-between items-center sm:mx-4 ml-2 cardInner2">
                  
                  {
                    currentlyEnrolled?.length !== 4 && 
                  <div className="left">
                    <p className="price">{getDependantSplit(ratecard, currentlyEnrolled?.length)}</p>
                  </div>
                }
                  {
                    currentlyEnrolled?.length === 4 && 
                  <div className="left parentBundle">
                    <p className="price">{getDependantSplit(ratecard, currentlyEnrolled?.length)}</p>
                  </div>
                }
                {
                    currentlyEnrolled?.length === 4 && 
                  <div className="left parentBundle">
                    <AiOutlinePlus style={{color:"var(--primary)",fontSize:"1.25rem"}} />
                  </div>
                }
                  {
                    currentlyEnrolled?.length === 4 && 
                  <div className="left pilBundle">
                    <p className="price">{getDependantSplit(ratecard, currentlyEnrolled?.length)}</p>
                  </div>
                }

                  {ratecard.id == selectedDiv ? (
                    // && (currentEnrollingId == currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id)
                    <BsFillCheckCircleFill
                      style={{
                        width: "2rem",
                        height: "2rem",
                        color: "var(--primary)",
                      }}
                    />
                  ) : (
                    <button
                      className="btn active"
                      onClick={() => handleSelectCoverage(ratecard)}
                    >
                      Select
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}

      <p 
      className="coverageSelectionPageContent"
      > 
      
      * This is the Annual Premium 
      to be paid over the course of 
      the policy, your actual 
      cost will be prorated based
       on number days in the policy
        period

        </p>

      <div className="mx-4">
        <ButtonM text="NEXT" onpress={() => handleNext()} />
      </div>
      {/* {openModal && <VoluntaryParentalModal setOpenModal={setOpenModal} />} */}
    </div>
  );
};

export default NiumIndBundleCoverageSelection;
