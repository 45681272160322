import { useSelector } from 'react-redux';
import PolicyCoverImg from '../../../assets/policyCover.png';
const parse = require('html-react-parser');

const PolicyCover = ({
  policyIcon,
  title,
  description,
  description1,
  description2,
  description3,
}) => {

  
// const messages = useSelector((state) => state?.planReducer?.planMsg);

  return (
    <div className="policyCoverPage flex flex-col mt-10 mb-10 mx-4">
      <div className="mainImg w-full flex flex-col items-center">
        <div className="relative">
          <img
            src={PolicyCoverImg}
            className="coverBgImage"
            alt="coverBgImage"
          />
          <img
            src={policyIcon}
            className="policyIcon absolute top-1/4 right-1/4"
            alt="policyIcon"
          />
        </div>
      </div>
      <div className="coverTitle mx-auto my-6 mt-10">{title}</div>

      {/* {messages?.map((item) => {
        return (
          <>
            {item.pageNo == "PAGE_1" && (
              <div className="coverDescription">{parse(item?.details)} </div>
            )}
          </>
        );
      })} */}

      <div className='coverDescription'>{description}</div><br/>
      <div className='coverDescription'>{description1}</div><br/>
      <div className='coverDescription'>{description2}</div><br/>
      <div className='coverDescription'>{description3}</div><br/>
    </div>
  );
};

export default PolicyCover;