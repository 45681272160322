import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../api/index';
import { actions, types } from './actions';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";

const EnrollRequest = function* EnrollRequest({payload}) {  
    try {
        const { data } = yield call(api.healthInsuranceEnroll, payload);
        if (data) {
            yield put(actions.EnrollRequestSuccess(data));
            data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;{payload.isEmployeeEnrolled ? "Enrolled successfully" : "Choose opt out successfuly"}</div>);
           
        } else {
            // toast.error(data.message);
            yield put(actions.EnrollRequestFailed());            
        }
    } catch (err) {        
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);       
        yield put(actions.EnrollRequestFailed());
    }
};

const DeleteDependantRequest = function* DeleteDependantRequest({payload}) {  
    try {
        const { data } = yield call(api.deleteDependant, payload);
        if (data) {
            yield put(actions.DeleteDependantRequestSuccess(data));
            data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Dependant deleted</div>);
           
        } else {
            // toast.error(data.message);
            yield put(actions.DeleteDependantRequestFailed());            
        }
    } catch (err) {        
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);       
        yield put(actions.DeleteDependantRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.ENROLL_REQUEST, EnrollRequest);
    yield takeLatest(types.DELETE_DEPENDANT_REQUEST, DeleteDependantRequest);
}
