import { useEffect, useRef } from "react";
import moment from "moment";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import ButtonM from "components/common/Button";
import { getRelationName, relationsNew, requiredSelectStyled, requiredStyled } from "components/utils/utils";
import { genders } from "components/utils/utils";
import { FormattedMessage } from "react-intl";

const ModalBeneficiary = ({
  setViewModal,
  isFormOneFilled,
  setIsFormOneFilled,
  beneficiaryId = null,
  setBeneficiaryId,
  handleChangeDependent,
  dependent,
  getCompanyDateFormat,
  formError,
  setFormError,
  dependentAddress,
  setDependentAddress,
  formAddressError,
  setFormAddressError,
  initialFormErrorList,
  setDependent,
  initialEmptyDependent,
  handleValidateDependent,
  handleChangeDependentAddress,
  handleValidateDependentSubmit,
  employeeDetails,
  checked,
  setChecked,
  addLoading,
  setAddLoading,
}) => {
  
  const idType = useSelector((state) => state?.empData?.idTypes);
  const empCompany = useSelector((state) => state?.empData?.empData?.company?.displayName);

  function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !event.target.classList.value.includes("Mui") &&
          !event.target.classList.value.includes("PrivatePickers") &&
          !event.target.classList.value.includes("1v994a0") &&
          !event.target.classList.value.includes("1dozdou") &&
          !event.target.classList.value.includes("1n2mv2k") &&
          !event.target.classList.value.includes("l0iinn") &&
          !event.target.classList.value.includes("i6bazn") &&
          !event.target.classList.value.includes("mvmu")
        ) {
          if (!event.target.matches("path")) {
            setIsFormOneFilled(false);
            setDependent(initialEmptyDependent);
            setBeneficiaryId(null);
            
            setViewModal(false);
            setFormError(initialFormErrorList);
            setChecked(false);
          }
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);

  return (
    <div className="learningPageCoverageDetails beneficiariesModal">
      <div className="backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        {/* <div className="relative w-auto my-6 mr-auto md:ml-auto max-w-3xl"> */}
        <div
          className="CoverageDetailsPopup"
          style={{ padding: "0" }}
          ref={wrapperRef}
        >
          {!isFormOneFilled ? (
            <Stack sx={{ mx: { xs: 0, xl: 10, md: 5 } }} height="100%">
              <Box sx={{ my: 2 }} height="100%">
                <Stack
                  display={{ xs: "flex", sm: "flex" }}
                  direction={{ xs: "column", sm: "column" }}
                  justifyContent="space-between"
                  alignItems={{ xs: "center", sm: "center" }}
                  spacing={2}
                >
                  <Stack width={{ xs: "90%", sm: "75%" }} height="100%">
                    {/* <Stack sx={{ mb: 5 }} width={{ xs: "90%", sm: "100%" }}> */}
                    <div className="mt-4">
                      <div className="flex flex-wrap">
                        <div className="w-full flex justify-between">
                          <div className="details">
                            {/* <h3 className="title">Personal Details</h3> */}
                            <h3 className="title"><FormattedMessage id="myDependent.addDependent.personalDetails" defaultMessage="Personal Details"/></h3>
                            <p className="subTitle">
                              {/* Add the details for your dependents. */}
                              <FormattedMessage id="myDependent.addDependent.addDetailsForDependent" defaultMessage="Add the details for your dependents."/>
                            </p>
                          </div>

                          {/* <div className="eclipseOuter mx-2">
              <img src={ellipseIcon} alt="Ellipse" className="ellipse" />
              <p className="count">2 of 4</p>
            </div> */}
                        </div>
                      </div>
                    </div>
                    {/* </Stack> */}
                    <FormControl sx={{ my: 2 }}>
                      <InputLabel size="small" id="relation">
                        {/* Select relationship */}
                        <FormattedMessage id="myDependent.addDependent.selectRelationship" defaultMessage="Select relationship"/>
                      </InputLabel>
                      <Select
                        value={dependent.relation ? dependent.relation : ""}
                        labelId="Select relationship"
                        name="relation"
                        onChange={(event) =>
                          handleChangeDependent(event, "dropdown")
                        }
                        variant="outlined"
                        error={formError.relation.isError}
                        helperText={formError.relation.errorMessage}
                        // label="Select relationship"
                        label={<FormattedMessage id="myDependent.addDependent.selectRelationship" defaultMessage="Select relationship"/>}
                        size="small"
                        sx={{
                          width: "100%",
                          //   backgroundColor: "#dedede",
                          "& fieldset": {
                            // border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          ...requiredSelectStyled
                        }}
                      >
                        {relationsNew.map((item) => {
                          return (
                            <MenuItem
                              key={item}
                              value={item}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {getRelationName(item)}
                            </MenuItem>
                          );
                        })}
                        {/* {relations &&
                            relations.map((allowedDependent) => {
                              return (
                                allowedDependent &&
                                allowedDependent.relation &&
                                allowedDependent.relation.map((relation) => {
                                  return relation == "Parent"
                                    ? ["Mother", "Father"].map((rel) => {
                                        return (
                                          <MenuItem
                                            key={rel}
                                            value={rel}
                                            sx={{ textTransform: "capitalize" }}
                                          >
                                            {getRelationName(rel)}
                                          </MenuItem>
                                        );
                                      })
                                    : relation == "PIL"
                                    ? ["FIL", "MIL"].map((rel) => {
                                        return (
                                          <MenuItem
                                            key={rel}
                                            value={rel}
                                            sx={{ textTransform: "capitalize" }}
                                          >
                                            {getRelationName(rel)}
                                            {}
                                          </MenuItem>
                                        );
                                      })
                                    : relation != "NA" && (
                                        <MenuItem
                                          key={relation}
                                          value={relation}
                                          sx={{ textTransform: "capitalize" }}
                                        >
                                          {getRelationName(relation)}
                                        </MenuItem>
                                      );
                                })
                              );
                            })} */}
                      </Select>
                      {formError.relation.errorMessage && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {formError.relation.errorMessage}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <TextField
                      size="small"
                      name="firstName"
                      // label="First Name"
                      label={<FormattedMessage id="myDependent.addDependent.firstName" defaultMessage="First Name"/>}
                      id="outlined-basic"
                      onChange={(event) =>
                        handleChangeDependent(event, "textfield")
                      }
                      variant="outlined"
                      error={formError.firstName.isError}
                      helperText={formError.firstName.errorMessage}
                      value={dependent.firstName ? dependent.firstName : ""}
                      sx={{
                        my: 2,
                        width: "100%",
                        // backgroundColor: "#dedede",
                        "& fieldset": {
                          //   border: "1px solid #dedede",
                        },
                        "& .MuiInputBase-input:focus": {
                          border: "2px solid #fcd25e", // focus
                        },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        ...requiredStyled
                      }}
                    />
                    <TextField
                      size="small"
                      name="middleName"
                      // label="Middle Name"
                      label={<FormattedMessage id="myDependent.addDependent.middleName" defaultMessage="Middle Name"/>}
                      id="outlined-basic"
                      onChange={(event) =>
                        handleChangeDependent(event, "textfield")
                      }
                      variant="outlined"
                      error={formError.middleName.isError}
                      helperText={formError.middleName.errorMessage}
                      value={dependent.middleName ? dependent.middleName : ""}
                      sx={{
                        my: 2,
                        width: "100%",
                        // backgroundColor: "#dedede",
                        "& fieldset": {
                          //   border: "1px solid #dedede",
                        },
                        "& .MuiInputBase-input:focus": {
                          border: "2px solid #fcd25e", // focus
                        },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                      }}
                    />
                    <TextField
                      size="small"
                      name="lastName"
                      // label="Last Name"
                      label={<FormattedMessage id="myDependent.addDependent.lastName" defaultMessage="Last Name"/>}
                      id="outlined-basic"
                      onChange={(event) =>
                        handleChangeDependent(event, "textfield")
                      }
                      variant="outlined"
                      error={formError.lastName.isError}
                      helperText={formError.lastName.errorMessage}
                      value={dependent.lastName ? dependent.lastName : ""}
                      sx={{
                        my: 2,
                        width: "100%",
                        // backgroundColor: "#dedede",
                        "& fieldset": {
                          //   border: "1px solid #dedede",
                        },
                        "& .MuiInputBase-input:focus": {
                          border: "2px solid #fcd25e", // focus
                        },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        ...requiredStyled
                      }}
                    />
                    <FormControl sx={{ my: 2 }}>
                      <InputLabel size="small" id="gender">
                        {/* Gender */}
                        <FormattedMessage id="myDependent.addDependent.gender" defaultMessage="Gender"/>
                      </InputLabel>
                      <Select
                        value={dependent.gender ? dependent.gender : ""}
                        labelId="gender"
                        name="gender"
                        onChange={(event) =>
                          handleChangeDependent(event, "dropdown")
                        }
                        variant="outlined"
                        error={formError.gender.isError}
                        helperText={formError.gender.errorMessage}
                        // label="Gender"
                        label={<FormattedMessage id="myDependent.addDependent.gender" defaultMessage="Gender"/>}
                        size="small"
                        sx={{
                          width: "100%",
                          //   backgroundColor: "#dedede",
                          "& fieldset": {
                            // border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                          ...requiredSelectStyled
                        }}
                      >
                        {genders.map((gender) => {
                          return (
                            <MenuItem key={gender.value} value={gender.value}>
                              {gender.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {formError.gender.errorMessage && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {formError.gender.errorMessage}
                        </FormHelperText>
                      )}
                    </FormControl>

                    {/* <FormControl sx={{ my: 2 }}>
                      <InputLabel size="small" id="idType">
                        Id Type
                      </InputLabel>
                      <Select
                        value={dependent.idType ? dependent.idType : ""}
                        labelId="idType"
                        name="idType"
                        onChange={(event) =>
                          handleChangeDependent(event, "dropdown")
                        }
                        variant="outlined"
                        error={formError.idType.isError}
                        helperText={formError.idType.errorMessage}
                        label="idType"
                        size="small"
                        sx={{
                          width: "100%",
                          //   backgroundColor: "#dedede",
                          "& fieldset": {
                            // border: "1px solid #dedede",
                          },
                          "& .MuiInputBase-input:focus": {
                            border: "2px solid #fcd25e", // focus
                          },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                            backgroundColor: "#ffffff",
                            margin: "0px",
                            paddingLeft: "0.5rem",
                          },
                        }}
                      >
                        {idType?.map((type) => {
                          return (
                            <MenuItem key={type.name} value={type.name}>
                              {type.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {formError.idType.errorMessage && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {formError.idType.errorMessage}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <TextField
                      size="small"
                      name="nationalId"
                      label={
                        dependent.idType || "Id"
                      }
                      disabled={dependent.idType == null}
                      id="outlined-basic"
                      onChange={(event) =>
                        handleChangeDependent(event, "textfield")
                      }
                      variant="outlined"
                      error={formError.nationalId.isError}
                      helperText={formError.nationalId.errorMessage}
                      value={dependent.nationalId ? dependent.nationalId : ""}
                      sx={{
                        my: 2,
                        width: "100%",
                        // backgroundColor: "#dedede",
                        "& fieldset": {
                          //   border: "1px solid #dedede",
                        },
                        "& .MuiInputBase-input:focus": {
                          border: "2px solid #fcd25e", // focus
                        },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                      }}
                    /> */}

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat={
                          (employeeDetails &&
                            employeeDetails.empData &&
                            employeeDetails.empData.company &&
                            employeeDetails.empData.company.dateFormat &&
                            employeeDetails.empData.company.dateFormat) ||
                            "dd/MM/yyyy"
                        }
                        value={dependent.dateOfBirth}
                        // label="Date of Birth"
                        label={<FormattedMessage id="myDependent.addDependent.dob" defaultMessage="Date of Birth"/>}
                        name="dateOfBirth"
                        maxDate={new Date()}
                        onChange={(event) =>
                          handleChangeDependent(event, "birthdate")
                        }
                        renderInput={(params) => (
                          <TextField
                            name="dateOfBirthForTest"
                            variant="outlined"
                            helperText={formError.dateOfBirth.errorMessage}
                            inputProps={{ readOnly: true }} // Disable user input
                            size="small"
                            sx={{
                              pointerEvents: 'none',
                              '& .MuiOutlinedInput-root': {
                                'button': {
                                  pointerEvents: 'all',
                                }},
                              my: 2,
                              width: "100%",
                              //   backgroundColor: "#dedede",
                              "& fieldset": {
                                // border: "1px solid #dedede",
                              },
                              "& .MuiInputBase-input:focus": {
                                border: "2px solid #fcd25e", // focus
                              },
                              "& .css-k4qjio-MuiFormHelperText-root": {
                                backgroundColor: "#ffffff",
                                margin: "0px",
                                paddingLeft: "0.5rem",
                              },
                              // ...requiredStyled
                            }}
                            {...params}
                            error={formError.dateOfBirth.isError}
                            // disabled
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <TextField
                              size="small"
                              name="nationality"
                              // label="Nationality"
                              label={<FormattedMessage id="myDependent.addDependent.nationality" defaultMessage="Nationality"/>}
                              id="outlined-basic"
                              onChange={(event) =>
                                handleChangeDependent(event, "textfield")
                              }
                              variant="outlined"
                              error={formError.nationality.isError}
                              helperText={formError.nationality.errorMessage}
                              value={dependent.nationality ? dependent.nationality : ""}
                              sx={{
                                my: 2,
                                width: "100%",
                                // backgroundColor: "#dedede",
                                "& fieldset": {
                                  //   border: "1px solid #dedede",
                                },
                                "& .MuiInputBase-input:focus": {
                                  border: "2px solid #fcd25e", // focus
                                },
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                  backgroundColor: "#ffffff",
                                  margin: "0px",
                                  paddingLeft: "0.5rem",
                                },
                                // ...requiredStyled
                              }}
                            />
                            {/* <Stack
        direction='row'
        alignItems='center'
      >
        <Stack sx={{width: '50%'}} spacing={5}>
          <FormLabel id='demo-row-radio-buttons-group-label'>
            <Stack direction='row'>
              <Stack fontWeight={500}>Is dependant working ?</Stack>
            </Stack>
          </FormLabel>
        </Stack>
        <Stack sx={{width: '50%'}} direction='row' alignItems='center'>
          <RadioGroup
            value={dependent?.isRelativeWorking}
            onChange={(event) => handleChangeDependent(event, "checkbox")}
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='isRelativeWorking'
          >
            <FormControlLabel value={true} control={<Radio />} label='Yes' />
            <FormControlLabel value={false} control={<Radio />} label='No' />
          </RadioGroup>
          {formError?.isRelativeWorking?.isError && (
            <FormHelperText className='Mui-error'>
              {formError?.isRelativeWorking?.errorMessage}
            </FormHelperText>
          )}
        </Stack>
      </Stack> */}

      {/* {
        dependent?.isRelativeWorking &&  */}
        <Stack
        direction='row'
        alignItems='center'
      >
        <Stack sx={{width: '50%'}} spacing={5}>
          <FormLabel id='demo-row-radio-buttons-group-label'>
            <Stack direction='row'>
              <Stack fontWeight={500}>
                {/* Is dependant working in */}
                <FormattedMessage id="myDependent.addDependent.isDependantWorking" defaultMessage="Is dependant working in"/>
                 {empCompany} ?</Stack>
            </Stack>
          </FormLabel>
        </Stack>
        <Stack sx={{width: '50%'}} direction='row' alignItems='center'>
          <RadioGroup
            value={dependent?.isRelativeWorkingInNium}
            onChange={(event) => handleChangeDependent(event, "checkbox")}
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='isRelativeWorkingInNium'
          >
            {/* <FormControlLabel value={true} control={<Radio />} label='Yes' /> */}
            <FormControlLabel value={true} control={<Radio />} label={<FormattedMessage id="common.button.yes" defaultMessage="Yes"/>} />
            {/* <FormControlLabel value={false} control={<Radio />} label='No' /> */}
            <FormControlLabel value={false} control={<Radio />} label={<FormattedMessage id="common.button.no" defaultMessage="No"/>} />
          </RadioGroup>
          {formError?.isRelativeWorkingInNium?.isError && (
            <FormHelperText className='Mui-error'>
              {formError?.isRelativeWorkingInNium?.errorMessage}
            </FormHelperText>
          )}
        </Stack>
      </Stack>     
      {/* }     */}
      {
        dependent?.isRelativeWorkingInNium && 
        <TextField
                size="small"
                name="employeeIdOfRelative"
                // label="Employee-ID of Dependant"
                label={<FormattedMessage id="basicMedical.employee-IDofDependant" defaultMessage="Employee-ID of Dependant"/>}
                id="outlined-basic"
                onChange={(event) =>
                  handleChangeDependent(event, "textfield")
                }
                variant="outlined"
                error={formError.employeeIdOfRelative.isError}
                helperText={formError.employeeIdOfRelative.errorMessage}
                value={dependent.employeeIdOfRelative ? dependent.employeeIdOfRelative : ""}
                sx={{
                  my: 2,
                  width: "100%",
                  backgroundColor: "#dedede",
                  "& fieldset": {
                      border: "1px solid #dedede",
                  },
                  "& .MuiInputBase-input:focus": {
                    border: "2px solid #fcd25e", // focus
                  },
                  "& .css-k4qjio-MuiFormHelperText-root": {
                    backgroundColor: "#ffffff",
                    margin: "0px",
                    paddingLeft: "0.5rem",
                  },
                }}
              />
      }
                    <div
                      className="bottomButtonReq"
                      style={{ paddingInline: "0", paddingBottom: "1rem" }}
                      id="next-btn"
                    >
                      <ButtonM
                        // text="NEXT"
                        text={<FormattedMessage id="common.button.NEXT" defaultMessage="NEXT"/>}
                        onpress={() => handleValidateDependent()}
                      />
                    </div>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          ) : (
            <Stack sx={{ mx: { xs: 0, xl: 10, md: 5 } }} height="100%">
              <Box sx={{ my: 2 }} height="100%">
                <Stack
                  display={{ xs: "flex", sm: "flex" }}
                  direction={{ xs: "column", sm: "column" }}
                  justifyContent="space-between"
                  alignItems={{ xs: "center", sm: "center" }}
                  spacing={2}
                  //   height="100%"
                >
                  <Stack width={{ xs: "90%", sm: "75%" }} height="100%">
                    <div className="h-full flex flex-col justify-between">
                      <div>
                        {/* <Stack sx={{ mb: 5 }} width={{ xs: "90%", sm: "100%" }}> */}
                        <div className="mt-4">
                          <div className="flex flex-wrap">
                            <div className="w-full flex justify-between">
                              <div className="details">
                                {/* <h3 className="title">Address</h3> */}
                                <h3 className="title"><FormattedMessage id="basicMedical.address" defaultMessage="Address"/></h3>
                                <p className="subTitle">
                                  {/* Add the address for your dependent. */}
                                  <FormattedMessage id="basicMedical.addressForYourDependent" defaultMessage="Add the address for your dependent."/>
                                </p>
                              </div>

                              {/* <div className="eclipseOuter mx-2">
                          <img
                            src={ellipseIcon}
                            alt="Ellipse"
                            className="ellipse"
                          />
                          <p className="count">3 of 4</p>
                        </div> */}
                            </div>
                          </div>
                        </div>
                        {/* </Stack> */}
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="same-as-me-check"
                                color="success"
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            // label="Address same as me"
                            label={<FormattedMessage id="basicMedical.addressSameAsMe" defaultMessage="Address same as me"/>}
                          />
                        </FormGroup>
                        {!checked && (
                          <>
                            <TextField
                              size="small"
                              name="streetLineOne"
                              // label="Street Address Line 1"
                              label={<FormattedMessage id="basicMedical.streetAddressLine1" defaultMessage="Street Address Line 1"/>}
                              id="outlined-basic"
                              onChange={(event) =>
                                handleChangeDependentAddress(event, "textfield")
                              }
                              variant="outlined"
                              error={formAddressError.streetLineOne.isError}
                              helperText={
                                formAddressError.streetLineOne.errorMessage
                              }
                              value={
                                dependentAddress.streetLineOne
                                  ? dependentAddress.streetLineOne
                                  : ""
                              }
                              sx={{
                                my: 2,
                                width: "100%",
                                // backgroundColor: "#dedede",
                                "& fieldset": {
                                  //   border: "1px solid #dedede",
                                },
                                "& .MuiInputBase-input:focus": {
                                  border: "2px solid #fcd25e", // focus
                                },
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                  backgroundColor: "#ffffff",
                                  margin: "0px",
                                  paddingLeft: "0.5rem",
                                },
                                // ...requiredStyled
                              }}
                            />
                            <TextField
                              size="small"
                              name="streetLineTwo"
                              // label="Street Address Line 2"
                              label={<FormattedMessage id="basicMedical.streetAddressLine2" defaultMessage="Street Address Line 2"/>}
                              id="outlined-basic"
                              onChange={(event) =>
                                handleChangeDependentAddress(event, "textfield")
                              }
                              variant="outlined"
                              error={formAddressError.streetLineTwo.isError}
                              helperText={
                                formAddressError.streetLineTwo.errorMessage
                              }
                              value={
                                dependentAddress.streetLineTwo
                                  ? dependentAddress.streetLineTwo
                                  : ""
                              }
                              sx={{
                                my: 2,
                                width: "100%",
                                // backgroundColor: "#dedede",
                                "& fieldset": {
                                  //   border: "1px solid #dedede",
                                },
                                "& .MuiInputBase-input:focus": {
                                  border: "2px solid #fcd25e", // focus
                                },
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                  backgroundColor: "#ffffff",
                                  margin: "0px",
                                  paddingLeft: "0.5rem",
                                },
                                // ...requiredStyled
                              }}
                            />
                            <TextField
                              size="small"
                              name="city"
                              // label="City"
                              label={<FormattedMessage id="basicMedical.city" defaultMessage="City"/>}
                              id="outlined-basic"
                              onChange={(event) =>
                                handleChangeDependentAddress(event, "textfield")
                              }
                              variant="outlined"
                              error={formAddressError.city.isError}
                              helperText={formAddressError.city.errorMessage}
                              value={
                                dependentAddress.city
                                  ? dependentAddress.city
                                  : ""
                              }
                              sx={{
                                my: 2,
                                width: "100%",
                                // backgroundColor: "#dedede",
                                "& fieldset": {
                                  //   border: "1px solid #dedede",
                                },
                                "& .MuiInputBase-input:focus": {
                                  border: "2px solid #fcd25e", // focus
                                },
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                  backgroundColor: "#ffffff",
                                  margin: "0px",
                                  paddingLeft: "0.5rem",
                                },
                                // ...requiredStyled
                              }}
                            />

                            <TextField
                              size="small"
                              name="state"
                              // label="State"
                              label={<FormattedMessage id="basicMedical.state" defaultMessage="State"/>}
                              id="outlined-basic"
                              onChange={(event) =>
                                handleChangeDependentAddress(event, "textfield")
                              }
                              variant="outlined"
                              error={formAddressError.state.isError}
                              helperText={formAddressError.state.errorMessage}
                              value={
                                dependentAddress.state
                                  ? dependentAddress.state
                                  : ""
                              }
                              sx={{
                                my: 2,
                                width: "100%",
                                // backgroundColor: "#dedede",
                                "& fieldset": {
                                  //   border: "1px solid #dedede",
                                },
                                "& .MuiInputBase-input:focus": {
                                  border: "2px solid #fcd25e", // focus
                                },
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                  backgroundColor: "#ffffff",
                                  margin: "0px",
                                  paddingLeft: "0.5rem",
                                },
                                // ...requiredStyled
                              }}
                            />

                            <TextField
                              size="small"
                              name="postalCode"
                              // label="Postal Code"
                              label={<FormattedMessage id="basicMedical.postalCode" defaultMessage="Postal Code"/>}
                              id="outlined-basic"
                              onChange={(event) =>
                                handleChangeDependentAddress(
                                  event,
                                  "onlyNumber"
                                )
                              }
                              variant="outlined"
                              error={formAddressError.postalCode.isError}
                              helperText={
                                formAddressError.postalCode.errorMessage
                              }
                              value={
                                dependentAddress.postalCode
                                  ? dependentAddress.postalCode
                                  : ""
                              }
                              sx={{
                                my: 2,
                                width: "100%",
                                // backgroundColor: "#dedede",
                                "& fieldset": {
                                  //   border: "1px solid #dedede",
                                },
                                "& .MuiInputBase-input:focus": {
                                  border: "2px solid #fcd25e", // focus
                                },
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                  backgroundColor: "#ffffff",
                                  margin: "0px",
                                  paddingLeft: "0.5rem",
                                },
                                // ...requiredStyled
                              }}
                            />

                           
                          </>
                        )}
                      </div>
                      <div
                        className="bottomButtonReq"
                        style={{ paddingInline: "0", paddingBottom: "1rem" }}
                        id="submit-btn"
                      >
                        <ButtonM
                          // text="SUBMIT"
                          text={<FormattedMessage id="common.button.SUBMIT" defaultMessage="SUBMIT"/>}
                          disabled={addLoading}
                          loading={addLoading}
                          onpress={() => handleValidateDependentSubmit()}
                        />
                      </div>
                    </div>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          )}
          {/* <div className="flex flex-wrap justify-between w-full total">
                <button
                  className="backBtn"
                  onClick={() => {
                    setDependent(initialEmptyDependent);
                    setBeneficiaryId(null);
                    setViewModal(false);
                    setFormError(initialFormErrorList);
                    setChecked(false);
                  }}
                >
                  No Change
                </button>
                <button
                  className="backBtn"
                  onClick={() => handleValidateDependent()}
                >
                  Submit
                </button>
              </div> */}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ModalBeneficiary;
