import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";

const DialogPopup = ({openDialog, setOpenDialog, content}) => {

  const [open, setOpen] = React.useState(openDialog);

  const handleClickOpen = () => {
    setOpen(openDialog);
  };

  const handleClose = () => {
    // setOpen(false);
    setOpenDialog(false);
  };

    return(
      <div className="common-dialog">
        <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <div id="alert-dialog-description">
            {content}
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button
           onClick={handleClose} 
           sx={{backgroundColor:"var(--secondary)", color:"var(--primary)", "&:hover":{backgroundColor:"var(--secondary)"}, fontFamily:"poppins"}}
           autoFocus
           >
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    )
}

export default DialogPopup;