import success from "../../../../assets/enrollmentSuccess/success.png";
import loaderImg from "../../../../assets/enrollmentSuccess/loading.png";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const SuccessPage = () => {

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push("/home")
    }, 2000);
    
  }, [])
  
return(
    <div className="h-full mx-auto flex flex-col justify-between">
        
    <div className="enrollmentSuccess">
      <div className="enrollmentSuccessInner">
        <img src={success} alt="Success" title="Success" class="successImg" />
        <p className="title">Enrollment successful</p>
        <p className="subTitle">You have enrolled to</p>
        <div className="boxOuter">
          <p className="text flex justify-between"> <span> BASIC MEDICAL </span>  <span>200 coins</span></p>
          <p className="text flex justify-between"><span>LIFE INSURANCE </span>  <span> 600 coins</span></p>
        </div>
        <img src={loaderImg} alt="Loader" title="Loader" class="loaderImg" />
        <p className="loadingText">Landing to home page...please wait</p>
      </div>
    </div>
    </div>
)
}

export default SuccessPage;