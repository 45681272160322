import childCare from "../../../assets/reimbuersement/childCare.png";
import childCareActive from "../../../assets/reimbuersement/childCareActive.png";
import gym from "../../../assets/reimbuersement/gym.png";
import gymActive from "../../../assets/reimbuersement/gymActive.png";
import internet from "../../../assets/reimbuersement/internet.png";
import internetActive from "../../../assets/reimbuersement/internetActive.png";
import childImg from "../../../assets/reimbuersement/child.png";
import check from "../../../assets/carLeaseProgram/active.png";
import Rectangle from "../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../assets/radioCheck.svg";
import { useEffect, useState } from "react";
import ButtonM from "components/common/Button";
import { useHistory, useParams } from "react-router-dom";
// import { ReimbursementPlanData } from "./dummyData/data";
import { getAllowedEmp, getAllowedEmpReim, getElligiblePlanDependant, isEmptyNullUndefined } from "components/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { actions as DependantActions } from "../BasicMedical/AddDependentAddress/redux/actions";
import { reimburseActions as reimActions } from "./redux/action";
import { Skeleton } from "@mui/material";

var classNames = require('classnames');

// const parse = require("html-react-parser");
const Reimbursement = () =>{

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const empDependant = useSelector((state) => state?.dependents?.allDependents);
  const dependantLoader = useSelector((state) => state?.dependents?.loader);
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const user = useSelector((state) => state?.user?.user);
  const ReimbursementPlanData = useSelector((state) => state?.reimReducer?.reimbursePlanData?.plan);
  const ReimLoader = useSelector((state) => state?.reimReducer?.loader);
  // const selectedPlan = useSelector((state) => state?.planReducer?.planData);

  // const [activeTab, setActiveTab] = useState("");
  const [activeItem, setActiveItem] = useState([]);
  const [activeChild, setActiveChild] = useState("");

  const [allowedEmp, setAllowedEmp] = useState(null);
  const [elligiblePlanDependant, setElligiblePlanDependant] = useState([]);
  const [enrolledDependants, setEnrolledDependants] = useState(null);
  const [selectedSelf, setselectedSelf] = useState(false);

  const getElligiblePlanDependantReim = (selectedPlan, employeeDetails) => {
    let legitDependents = [];
  
      selectedPlan?.dependantRules?.forEach(
        (x) =>
          x?.employeeRoles?.forEach(
            (y) =>{
              if(y?.id === employeeDetails?.employeeRole?.id ){
              legitDependents.push(x)
          }
        }
          )
      );
    return legitDependents.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
  };

  useEffect(() => {

    dispatch(reimActions.getReimbursePlanRequest({     
      className: "reimbursements",
      Id: params?.id,
      empId: employeeDetails?.id,
      eventId: null,
      lifeEventId: null
    }));

    dispatch(
      DependantActions.GetDependentRequest({
        empId: employeeDetails && employeeDetails.id ,
      })
    )    
    
  }, [employeeDetails]);

  useEffect(() => {
    setElligiblePlanDependant(getElligiblePlanDependantReim(ReimbursementPlanData, employeeDetails));
  }, [ReimbursementPlanData]);

  useEffect(() => {
    setAllowedEmp(getAllowedEmpReim(empDependant, elligiblePlanDependant));
  }, [elligiblePlanDependant, empDependant]);

  const handleDependentSelection = (dep) => {
    // enrolledDependants.filter((x) => x.id === dep.id).length > 0
    //   ? setEnrolledDependants(enrolledDependants.filter((x) => x.id !== dep.id))
    //   : setEnrolledDependants([...enrolledDependants, dep]);
    setEnrolledDependants(dep);
    setselectedSelf(false);
  };

  const addCategory = (cat) => {
    if (activeItem.includes(cat?.id)){
      setActiveItem(activeItem.filter(x => x !== cat?.id))
    }else{
       const arr = [...activeItem, cat?.id]
    setActiveItem(arr)
    }   
  }

  return (
    <div>
            <h4 className="page-heading-navbar">{ReimbursementPlanData?.displayName}</h4>
      <div className="reimbursement p-4">
        <div>
          <div className="claimBlock">
            <span className="claimTitle" onClick={() => history.push("/claimHistory")}>Claim History</span>          
          </div>
          <div>
            <p className="mainTitle">Select the reimbursement types</p>            

              <div className="description">
                You are eligible to claim child care expense upto a limit of Rs 4500 per month for maximum of two kids
              </div>  

              <div className="questions mt-6">
                What type of expense is this claim for?
              </div> 

              {                
                ReimLoader ? 
                <div className="flex flex-wrap justify-between">
                {[ ...Array(4).keys() ].map((item) => {
                  return(
                      <Skeleton className="radioButton" width={"48%"} height={75} />
                  )
                })
                }
                </div>
                :
                <div className="grid grid-cols-3 gap-1 radioButtonOuter">
                {
                  ReimbursementPlanData?.categoryRules?.map((cat) => {
                    return(
                      <button onClick={() => addCategory(cat)} className={classNames("radioButton",{'active': activeItem.includes(cat?.id)})}>
                  <div className="radioOuter">
                    <img src={Rectangle} alt="" className="radio" />
                    <img src={radioCheckIcon} alt="" className="radioCheck" />
                  </div>
                  {cat?.categoryName}
                </button>
                    )
                  })
                }
              </div>  
              }
            
              
            
            {
              activeItem != "" && 
              <>
              <div className="questions mt-4">
                Who was the expense spent on?             
              </div>  
              {
                 dependantLoader ?
                 <div className="flex flex-wrap justify-between">
               {[ ...Array(4).keys() ].map((item) => {
                 return(
                    <Skeleton className="radioButton" width={"48%"} height={75} />
                 )
               })
               }
               </div>
               :
             <div className="expenseSpendDiv radioButtonOuter">
                {                 
                
                  allowedEmp?.length === 0 ?
                  <span className="w-full text-center" style={{color:"var(--warning)"}}>No dependants to add</span>
                  :
                  <>
                  {
                    employeeDetails &&
                   <button
                      className={classNames("radioButton justify-between", {
                        active:
                          selectedSelf
                      })}
                      onClick={() => {
                        setselectedSelf(true);
                        setEnrolledDependants(null);
                      }}
                    >
                      <div className="flex flex-row justify-start capitalize">
                        <div className="radioOuter">
                          <img src={Rectangle} alt="" className="radio" />
                          <img
                            src={radioCheckIcon}
                            alt=""
                            className="radioCheck"
                          />
                        </div>
                        <div>
                          {employeeDetails && employeeDetails.firstName}{" "}
                          {employeeDetails && employeeDetails.lastName}
                        </div>
                      </div>
                    </button>
                  }
                  
                {
                allowedEmp?.map((dependent, i) => {
                  return (
                    <button
                      className={classNames("radioButton justify-between", {
                        active:
                          // enrolledDependants.filter(
                          //   (x) => x.id === dependent.id
                          // ).length > 0,
                          enrolledDependants?.id === dependent.id
                      })}
                      onClick={() => {
                        handleDependentSelection(dependent);
                      }}
                    >
                      <div className="flex flex-row justify-start capitalize">
                        <div className="radioOuter">
                          <img src={Rectangle} alt="" className="radio" />
                          <img
                            src={radioCheckIcon}
                            alt=""
                            className="radioCheck"
                          />
                        </div>
                        <div>
                          {dependent && dependent.firstName}{" "}
                          {dependent && dependent.lastName}
                        </div>
                      </div>
                    </button>
                    
                  );
                })
              }
                </>
                }   
              </div>
              }

                <div className="w-full linksOuter mx-4 mb-4">
                  
                    <p
                      className="addLink mr-4"
                      onClick={() =>
                        history.push({
                          pathname: "/reimburseAddDependant",
                          state: { addedDependent: enrolledDependants },
                        })
                      }
                    >
                      Add Dependent
                    </p>
                  
                    {/* <p className="viewLink" onClick={() => handleViewCost()}>
                      {elligibleToEnroll && "View Cost"}
                    </p> */}
                  
                </div>
           
              </>
            }
            {
              // activeChild != "" &&
              <ButtonM 
              text="UPDATE DETAILS" 
              onpress={() => history.push("/updateDetails",{ addedDependent: enrolledDependants, categoryId: activeItem, selectedSelf:selectedSelf })} 
              disabled = {isEmptyNullUndefined(activeItem) || (!selectedSelf && isEmptyNullUndefined(enrolledDependants))}
              bgcolor={
                (isEmptyNullUndefined(activeItem) || (!selectedSelf && isEmptyNullUndefined(enrolledDependants)))
                  ? "gray"
                  : ""
              }
              />

            }


          </div>
        </div>

      </div>

    </div>
  )
} 
export default Reimbursement;