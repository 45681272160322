const { createAction } = require('redux-actions');
const ADD_DEPENDANT_REQUEST = 'BENEVOLVE/ADD_DEPENDANT_REQUEST';
const ADD_DEPENDANT_REQUEST_SUCCESS = 'BENEVOLVE/ADD_DEPENDANT_REQUEST_SUCCESS';
const ADD_DEPENDANT_REQUEST_FAILED = 'BENEVOLVE/ADD_DEPENDANT_REQUEST_FAILED';

const GET_DEPENDENTS_REQUEST = 'BENEVOLVE/GET_DEPENDENTS_REQUEST';
const GET_DEPENDENTS_REQUEST_SUCCESS = 'BENEVOLVE/GET_DEPENDENTS_REQUEST_SUCCESS';
const GET_DEPENDENTS_REQUEST_FAILED = 'BENEVOLVE/GET_DEPENDENTS_REQUEST_FAILED';

const TEMP_SAVE_DEPENDANT_INFO = 'BENEVOLVE/TEMP_SAVE_DEPENDANT_INFO';
const TEMP_SAVE_DEPENDANT_ADDRESS = 'BENEVOLVE/TEMP_SAVE_DEPENDANT_ADDRESS';

const AddDependantRequest = createAction(ADD_DEPENDANT_REQUEST);
const AddDependantRequestSuccess = createAction(ADD_DEPENDANT_REQUEST_SUCCESS);
const AddDependantRequestFailed = createAction(ADD_DEPENDANT_REQUEST_FAILED);

const GetDependentRequest = createAction(GET_DEPENDENTS_REQUEST);
const GetDependentRequestSuccess = createAction(GET_DEPENDENTS_REQUEST_SUCCESS);
const GetDependentRequestFailed = createAction(GET_DEPENDENTS_REQUEST_FAILED);

const SaveDependantInfo = createAction(TEMP_SAVE_DEPENDANT_INFO);
const SaveDependantAddress = createAction(TEMP_SAVE_DEPENDANT_ADDRESS);

export const actions = {
    AddDependantRequest,
    AddDependantRequestSuccess,
    AddDependantRequestFailed,

    GetDependentRequest,
    GetDependentRequestSuccess,
    GetDependentRequestFailed,

    SaveDependantInfo,
    SaveDependantAddress
};

export const types = {
    ADD_DEPENDANT_REQUEST,
    ADD_DEPENDANT_REQUEST_SUCCESS,
    ADD_DEPENDANT_REQUEST_FAILED,

    GET_DEPENDENTS_REQUEST,
    GET_DEPENDENTS_REQUEST_SUCCESS,
    GET_DEPENDENTS_REQUEST_FAILED,

    TEMP_SAVE_DEPENDANT_INFO,
    TEMP_SAVE_DEPENDANT_ADDRESS,
};
