import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";

// import { Data } from "./utils";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import APIList from "api";
// import PageHeader from "../../../common/PageHeader";
// import LearningPageHeading from "../../../../assets/LearningPage/LearningPageHeading.svg";

// import "../../../../styles/LearningPages/tabs.scss";

const TabsComponent = ({ activeKey }) => {
  const history = useHistory();

  const state = useSelector((state) => state);
  //   const dispatch = useDispatch();

  const employeeDetails = state && state.empData;
  //   const employeeId = employeeDetails.empData.id;
  const employeeId = "ce85e295-15ec-4a91-98e2-546716aa84f2";
  const [planName, setPlanName] = useState([]);
  const [planActiveKey, setPlanActiveKey] = useState(planName[0]);

  useEffect(() => {
    getEmployeeEnrollments();
    getEnums();
  }, [employeeId]);

  const getEmployeeEnrollments = () => {
    APIList.getEmployeeEnrolled({ empId: employeeId })
      .then((res) => {
        setPlanName(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getEnums = () => {
    APIList.getEnums()
      .then((res) => {
        console.log(res.data, "AMITCTM");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const companyDetails =
    state &&
    state.empData &&
    state.empData.empData &&
    state.empData.empData.company;
  const selectedPlan = useSelector((state) => state?.planReducer?.planData);

  const planDisplayName = selectedPlan && selectedPlan.displayName;

  const [planDiaplayDiscription, setPlanDiaplayDiscription] = useState("");

  const TabList = planName.length > 0 && planName;

  const gotoPage = (link) => {
    return history.push(link);
  };

  const getClassName = (tab, index) => {
    if (tab.displayName === activeKey) {
      if (index !== 0 && index !== TabList.length - 1) {
        return "sideBarActive w-full mx-2";
      } else {
        return "sideBarActive w-full";
      }
    } else {
      if (index !== 0 && index !== TabList.length - 1) {
        return "sideBarInActive w-full mx-2";
      } else {
        return "sideBarInActive w-full";
      }
    }
  };

  return (
    <div>
      <div className="myBeneficiary">
        <div className="Side-bar flex place-items-center text-center w-full h-full text-blue-gray-900 font-normal leading-relaxed select-none cursor-pointer">
          {TabList &&
            TabList.map((tab, index) => (
              <div>
                <div
                  key={`tab_${index}`}
                  onClick={() => {
                    gotoPage(tab.link);
                    setPlanActiveKey(tab);
                  }}
                  className={getClassName(tab, index)}
                >
                  <button className="list-menu">{tab.displayName}</button>
                </div>
              </div>
              // <div
              //   key={`tab_${index}`}
              //   onClick={() => gotoPage(tab.link)}
              //   className={getClassName(tab, index)}
              // >
              //   <button className="list-menu">{tab.name}</button>
              // </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TabsComponent;
