import { useState } from "react";
import arrowRight from "../../../../assets/reimbuersement/arrowRight.png";
import ReimModal from "../Modal/ReimModal";

const ClaimHistoryD = () => {

    const [viewDetails, setViewDetails] = useState(false);


    const blockClick = () => {
        setViewDetails(true);
      };

    return(
        <div className="claimHistoryD m-4">
             <p className="mainTitle">Claims History (2)</p>
            <div className="cards">
              <div className="cardsOuter" onClick={() => blockClick()}>
                <div className="left">
                  <p className="name">Child care</p>
                  <p className="date">23 Novemeber 2022</p>
                </div>
                <div className="right">
                  <div className="statusOuter">
                    <p className="status">Status</p>
                    <div className="statusInner">
                      <p className="circle pending">&nbsp;</p>
                      <p className="statusDetails pending">Pending</p>
                    </div>
                  </div>
                  <img src={arrowRight} alt="Line" className="arrowRight" />
                </div>
              </div>
              <div className="cardsOuter" onClick={() => blockClick()}>
                <div className="left">
                  <p className="name">Child care</p>
                  <p className="date">23 Novemeber 2022</p>
                </div>
                <div className="right">
                  <div className="statusOuter">
                    <p className="status">Status</p>
                    <div className="statusInner">
                      <p className="circle paid">&nbsp;</p>
                      <p className="statusDetails paid">Paid</p>
                    </div>
                  </div>
                  <img src={arrowRight} alt="Line" className="arrowRight" />
                </div>
              </div>
            </div>
            {viewDetails && <ReimModal setViewDetails={setViewDetails} />}
        </div>
    )
}

export default ClaimHistoryD;