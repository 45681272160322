import { Button, Skeleton } from "@mui/material";
import APIList from "api";
import { useEffect, useState } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import { actions as BeneficiaryActions } from "../AddBeneficiaryAfterPlanENroll/redux/actions";
import { FormattedMessage } from "react-intl";

const Beneficiaries = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const employeeDetails = useSelector((state) => state?.empData?.empData);

  const [data, setData] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);

  useEffect(() => {    
  dispatch(BeneficiaryActions.TempBenAllocationInfo(null));
  setDataLoader(true);
   APIList.getEnums({empId: employeeDetails?.id})
   .then((res) => {
    setData(res?.data);
    setDataLoader(false);
   })
   .catch((err) => {
    toast.error(
      <div className='flex flex-row'>
        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
        &nbsp;&nbsp;{err?.title}
      </div>
    );      
    setDataLoader(false);
   })
  }, [])
  
  return(
    <div className="my-beneficiaries-main">
    <h4 className="page-heading-navbar">
      {/* My Beneficiaries */}
      <FormattedMessage id='sidebar.MyBeneficiaries' defaultValue='My Beneficiaries'/>
    </h4>
       {
        dataLoader ? 
        <div>
            <Skeleton
              variant="rectangular"
              height={125}
              sx={{ margin: "1rem 0rem" }}
            />
            <Skeleton
              variant="rectangular"
              height={125}
              sx={{ margin: "1rem 0rem" }}
            />
          </div>
          :
          <div className="my-beneficiaries-plan">
            {
              data?.map((plan) => {
                return(
                  <div className="plan-block">
                    <div className="info-block">
                      <div className="plan-name">{plan?.displayName}</div>
                      {/* <div className="class-name">{plan?.className}</div> */}
                    </div>
                    <div className="action-block">
                      <button 
                      className="add-allocate-btn"
                      onClick={() => history.push({pathname:`/my-beneficiaries-allocation/${plan?.id}`, state:{planName:plan?.displayName}})} 
                      >
                        {/* View / Allocate Beneficiary */}
                        <FormattedMessage id='sidebar.MyBeneficiaries.View/Allocate Beneficiary' defaultValue='View / Allocate Beneficiary'/>
                      </button>
                    </div>
                  </div>
                )
              })
            }
          </div>
       }
    </div>
  )
}

export default Beneficiaries;
