import homeIcon from "../../../assets/home.svg";
import inboxIcon from "../../../assets/inbox.svg";
import contactUsIcon from "../../../assets/contactUs.svg";
import profileIcon from "../../../assets/profile.svg";
import logoutIcon from "../../../assets/logout.svg";
import menuActiveIcon from "../../../assets/menuActive.svg";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { actions as logInActions } from "../../pages/Login/redux/actions";
import { actions as notificationActions } from "../Header/Notifications/reedux/action";
import { actions as planPageAction } from "../Plans/redux/actions";
import { isShowExitPopup } from "components/utils/utils";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { FormattedMessage } from "react-intl";

var classNames = require('classnames');

const Footer = ({ notfcnIntrvlId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useLocation();
  const [activeItem, setActiveItem] = useState("");
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);

  // const companies = useSelector((state) => console.log('state',state));
    const { employeeEnrolled, employeeEnrolledLoader } = useSelector((state) => state && state.empData);

  useEffect(() => {
    setActiveItem(params.pathname.split('/')[1]);
  }, [params.pathname]);

  const handleLogOutPopup = () => {
    setShowLogoutPopup(true);
  }

const handleLogOut = () => {
    dispatch(logInActions.clearLogInToken())
// if (notfcnIntrvlId) {
//       clearInterval(notfcnIntrvlId);
//     }
dispatch(
  notificationActions.GetNotificationRequestCount({
    // id: employeeDetails?.empData?.id,
    loggedIn: false
  })
);
localStorage.setItem("loggedOutFromFooter",true);
    setTimeout(() => {
      history.push('/login')
    }, 100);
    // window.location.reload();
  }

  const handleRedirect = (path) => {
    let parameter = employeeEnrolled?.length > 0 ? true : false;
    if (isShowExitPopup(parameter)) {
      dispatch(planPageAction.openExitPopUp());
      dispatch(planPageAction.setExitPath({path:path}));
   } else {
     return history.push(path);
   }
  };

  const handleClose = () => {
    setShowLogoutPopup(false);
  }

  return (
    <>
      <div className="footer">
        <div>
          <div className="flex flex-wrap">
            <div className="w-full">
              <div className="menu">
              <p onClick={() => handleRedirect('/home')} data-value="home">
                <div 
                 className={classNames("menuLink",{'active': (activeItem==='home' && (window.location.pathname === "/home"))})}
                 onClick={() => {
                  setActiveItem('home');
                }}
                 >
                  <div className="menuImg">
                    <img src={homeIcon} alt="Home" className="img" />
                    {
                      activeItem==='home' &&
                      <img
                      src={menuActiveIcon}
                      alt="Active Menu"
                      className="menuActive"
                    />
                    }
                    </div>
                  
                  {/* Home */}
                  <FormattedMessage id='footer.home' defaultMessage='Home'/>
                </div>
                </p>
                <p onClick={() => handleRedirect('/inbox')} data-value="inbox">
                <div                 
                  className={classNames("menuLink",{'active': (activeItem==='inbox' && (window.location.pathname === "/inbox"))})}
                  onClick={() => {
                    setActiveItem('inbox');
                    }} 
                  >
                 <div className="menuImg">
                    <img src={inboxIcon} alt="Inbox" className="img" />
                    {
                      activeItem==='inbox' &&
                      <img
                      src={menuActiveIcon}
                      alt="Active Menu"
                      className="menuActive"
                    />
                    }
                    </div>
                    
                  
                  {/* Contact your HR */}
                  <FormattedMessage id='footer.contactYourHR' defaultMessage='Contact your HR'/>
                 
                </div>
                </p>
                <p onClick={() => handleRedirect('/contactus')} data-value="contactUs">
                <div
                className={classNames("menuLink",{'active': (activeItem==='contactus' && (window.location.pathname === "/contactus"))})}
                onClick={() => {
                  setActiveItem('contactus');
                }} 
                >
                  <div className="menuImg">
                    <img src={contactUsIcon} alt="contactUs" className="img" />
                    {
                      activeItem==='contactus' &&
                      <img
                      src={menuActiveIcon}
                      alt="Active Menu"
                      className="menuActive"
                    />
                    }
                    </div>
                  
                  {/* Contact Provider */}
                  <FormattedMessage id='footer.contactProvider' defaultMessage='Contact Provider'/>
                
                </div>
                </p>
                  <p onClick={() => handleRedirect('/profile')} data-value="profile">
                <div 
                 className={classNames("menuLink",{'active': (activeItem==='profile' && (window.location.pathname === "/profile"))})}
                 onClick={() => {
                  setActiveItem('profile');
                }}
                 >
                  <div className="menuImg">
                    <img src={profileIcon} alt="Profile" className="img" />
                    {
                      activeItem==='profile' &&
                      <img
                      src={menuActiveIcon}
                      alt="Active Menu"
                      className="menuActive"
                    />
                    }
                    </div>
                  {/* Profile */}
                  <FormattedMessage id='footer.profile' defaultMessage='Profile'/>
                </div>
                  </p>
                <div className="menuLink" onClick={() => handleLogOutPopup()}  data-value="logout">
                  <div className="menuImg">
                    <img src={logoutIcon} alt="Home" className="img" />
                    {/* <img
                      src={menuActiveIcon}
                      alt="Active Menu"
                      className="menuActive"
                    /> */}
                  </div>
                  {/* Logout */}
                  <FormattedMessage id='footer.logout' defaultMessage='Logout'/>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
        open={showLogoutPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <div id="alert-dialog-description">
            {/* Are you sure you want to logout ? */}
            <FormattedMessage id='logout.areYouSureLogout' defaultMessage="Are you sure you want to logout ?"/>
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button
           onClick={() => {handleClose(); handleLogOut();}} 
           sx={{backgroundColor:"var(--primary)", color:"var(--secondary)", "&:hover":{backgroundColor:"var(--primary)"}, fontFamily:"poppins"}}
          //  autoFocus
           >
            {/* YES */}
            <FormattedMessage id='common.button.YES' defaultMessage="YES"/>
          </Button>
          <Button
           onClick={handleClose} 
           sx={{backgroundColor:"var(--secondary)", color:"var(--primary)", "&:hover":{backgroundColor:"var(--secondary)"}, fontFamily:"poppins"}}
          //  autoFocus
           >
            {/* NO */}
            <FormattedMessage id='common.button.NO' defaultMessage="NO"/>
          </Button>
        </DialogActions>
      </Dialog>
        
      </div>
    </>
  );
};

export default Footer;
