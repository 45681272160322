import { useEffect, useState } from "react";
import ellipseIcon from "../../../../../../assets/Ellipse2.png";
import PageHeader from "../../../../../common/PageHeader";
// import VoluntaryParentalModal from "../../../ViewModal/index";
import { useSelector } from "react-redux";
import { actions as VolutryActions } from "../../../redux/actions";
import coverImg from "../../../../../../assets/groupMedical/cover.png";
import BundleSingleDependant from "../SingleDependant";
import BundleMultiDependant from "../MultipleDependant";
import { getRelationName } from "components/utils/utils";

const classNames = require("classnames");

const EditBundleCoverageType = () => {

  const [isGroup, setIsGroup] = useState(true);
  const [isIndividual, setIsIndividual] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  
  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const planDependants = selectedPlan?.planDependants;
  const rateDiffer = selectedPlan?.rateCard?.rateDiffer;
  const { selectedCategories, progressArray, selectedRatecards, currentlyEnrolled,currentlyInprocessEnrolled} = useSelector((state) => state?.voluntryReducer); 
  // const currentNames = selectedCategories[progressArray?.length];
  const currentDependants = selectedCategories[progressArray?.length]?.includes("-") ? selectedCategories[progressArray?.length].split("-") : selectedCategories[progressArray?.length]

  const [currentNames, setCurrentNames] = useState('');

  useEffect(() => {
      const current = selectedCategories?.filter((x) => !progressArray.includes(x))[0];
      setCurrentNames(current);
    }, [progressArray]);

  Array.prototype.equals = function (array, strict) {
    if (!array)
        return false;

    if (arguments.length == 1)
        strict = true;

    if (this.length != array.length)
        return false;

    for (var i = 0; i < this.length; i++) {
        if (this[i] instanceof Array && array[i] instanceof Array) {
            if (!this[i].equals(array[i], strict))
                return false;
        }
        else if (strict && this[i] != array[i]) {
            return false;
        }
        else if (!strict) {
            return this.sort().equals(array.sort(), true);
        }
    }
    return true;
}
  
const getMinCoverageLimits = () => {
  let minCoverage = 0;

  if(currentDependants instanceof Array){
  planDependants?.map((item) => {
    if(item.relation?.equals(currentDependants,false)){
      if(item.minCoverage != "NA" || item.minCoverage != null){ 
      minCoverage = item.minCoverage;
      }
    }
  })}else{
    planDependants?.map((item) => {
    if(item.relation?.includes(currentDependants)){
      if(item.minCoverage != "NA" || item.minCoverage != null){ 
      minCoverage = item.minCoverage;
      }
    }
  })
  }
  return minCoverage;
}

const getMaxCoverageLimits = () => {
  let maxCoverage = 999999999;

  if(currentDependants instanceof Array){
    planDependants?.map((item) => {
      if(item.relation?.equals(currentDependants,false)){
        if(item.maxCoverage != "NA" || item.maxCoverage != null){ 
        maxCoverage = item.maxCoverage;
      }
      }
    })}else{
      planDependants?.map((item) => {
      if(item.relation?.includes(currentDependants)){
        if(item.maxCoverage != "NA" || item.maxCoverage != null){ 
        maxCoverage = item.maxCoverage;
        }
      }
    })
    }
  return maxCoverage;

} 

const rateCards = selectedPlan?.rateCard?.rateCardItems?.filter((x) => ((x.sumInsured >= getMinCoverageLimits()) && (x.sumInsured <= getMaxCoverageLimits())))
  // {  console.log("currentDependants",selectedPlan)}

  return (
    <div className="coverageTypePage">
      {selectedPlan && (
        <div>
          <h4 className="page-heading-navbar">{selectedPlan.displayName}</h4>
          <PageHeader
            heading={""}
            description={`${selectedPlan.company.displayName} provides you an option to purchase coverage for`}
            btnText={"Click for more details"}
            pageNumber={isGroup ? "3 of 4" : "4 of 4"}
            eIcon={ellipseIcon}
            planIcon={coverImg}
            // date={new Date()}
            // btnRedirect={() => setOpenModal(true)}
          />
        </div>
      )}
      <div className="data">
        <div className="container mx-auto">
          <div className="flex flex-wrap ">
            {isGroup ? (
              <div className="PageTitle-And-Description w-full mt-4 mx-4">
                <div className="details flex flex-row">
                  <h3 className="title">
                    Choose your coverage type for{" "}
                    <span
                      className="ml-1 font-bold text-[var(--secondary)]"
                      style={{ fontSize: "1.2rem" }}
                    >
                      {selectedCategories?.filter((x) => !progressArray.includes(x))[0] == "Parent" ? `Mother and Father` : "Parents in law"}
                    </span>
                  </h3>
                </div>
              </div>
            ) : (
              <div className="PageTitle-And-Description w-full mt-4 mx-4">
                <div className="details flex flex-row">
                  <h3 className="title">
                    Choose your coverage type for{" "}
                    <span
                      className="ml-1 font-bold text-[var(--secondary)]"
                      style={{ fontSize: "1.2rem" }}
                    >
                       {`${" "}${getRelationName(currentlyEnrolled[currentlyInprocessEnrolled?.length]?.relation)}`}

                    </span>
                  </h3>
                </div>
              </div>
            )}
            <div className="w-full flex justify-between btnOuter">
              {rateDiffer == "bundle" && (
                <button
                  onClick={() => {
                    setIsGroup(true);
                    setIsIndividual(false);
                  }}
                  className={classNames("btn", {
                    active: isGroup,
                  })}
                >
                  Group
                </button>
              )}
              {rateDiffer == "bundle" && (
                <button
                  onClick={() => {
                    setIsGroup(false);
                    setIsIndividual(true);
                  }}
                  className={classNames("btn", {
                    active: isIndividual,
                  })}
                >
                  Individual
                </button>
              )}
            </div>
            
            {rateDiffer == "bundle" && isGroup && (              
                    
                    <BundleMultiDependant
                    rateCards={rateCards}
                    currentNames={currentNames}
                    isEdit={true}
                    />                
             
            )}
            {rateDiffer == "bundle" && isIndividual && (              
                    
                    <BundleSingleDependant
                    rateCards={rateCards}
                    currentNames={currentNames}
                    isEdit={true}
                    />                
             
            )}
         
          </div>
        </div>
      </div>
      {/* {openModal && <VoluntaryParentalModal setOpenModal={setOpenModal} />} */}
    </div>
  );
};

export default EditBundleCoverageType;
