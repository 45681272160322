import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import LineGreenIcon from "../../../../../assets/LineGreen.svg";
import { FormattedMessage } from "react-intl";

const GetCostVersion2 = ({ onBack, loader, costData, payCheckFrequency }) => {

  const currency = useSelector((state) => state?.empData?.empData?.company?.currency);

  const currencyFormat = (amount) => {
    if(amount == "--"){
        return amount
    }
    if (currency === "INR") {

      // return (parseFloat(amount?.toFixed(1)).toLocaleString('en-IN'));

      return (Math.round(amount).toLocaleString('en-IN'));

    } else {

      // return (parseFloat(amount?.toFixed(1)).toLocaleString('en-US'));

      return (Math.round(amount).toLocaleString('en-US'));
      
    }
  }

//   const costData1 = [
//     {
//         "Relation": "Self",
//         "EmployeeCost": 5151,
//         "employerCost": 7194,
//         "PaycheckFrequency": 99.0576923076923
//     },
//     {
//         "Relation": "Siblings",
//         "EmployeeCost": 4949,
//         "employerCost": 3939,
//         "PaycheckFrequency": 95.17307692307692
//     },
//     {
//         "Relation": "Spouse",
//         "EmployeeCost": 5050,
//         "employerCost": 3838,
//         "PaycheckFrequency": 97.11538461538461
//     },
//     {
//         "Relation": "Siblings",
//         "EmployeeCost": 4949,
//         "employerCost": 7396,
//         "PaycheckFrequency": 95.17307692307692
//     },
//     {
//         "Relation": "Spouse",
//         "EmployeeCost": 5050,
//         "employerCost": 7295,
//         "PaycheckFrequency": 97.11538461538461
//     }
// ]



const getTotalEmployerPrice = () => {

    let total = 0;
    
    costData?.forEach((x) => {
        total = total + x.employerCost ;
    })

    return total;
}

const getTotalEmployeePrice = () => {

    let total = 0;

    costData?.forEach((x) => {
        total = total + x.EmployeeCost ;
    })

    return total;
}

const getTotalfrequency = () => {

    let total = 0;

    costData?.forEach((x) => {
        total = total + x.PaycheckFrequency ;
    })

    return total;
}

  return (
    <div className="popupTotalCost">
      {loader ? (
      // this is skeleton to show while the data is loading
        <div className="fex flex-wrap overflow-auto">
          <p className="title">
            {/* Total Cost */}
            <FormattedMessage id="basicMedical.totalCost" defaultMessage="Total Cost"/>
          </p>
          
          <table className="w-full">
            <thead>
              <tr>
                <th className="md:tracking-wider md:w-40 text-left">
                  {/* Relation */}
                  <FormattedMessage id="basicMedical.relation" defaultMessage="Relation"/>
                </th>
                <th className="md:tracking-wider md:w-40 text-left">
                  {/* Employer Annual Contribution({currency}) */}
                  <FormattedMessage id="basicMedical.employerAnnualContribution" defaultMessage="Employer Annual Contribution"/>({currency})
                </th>
                <th className="md:tracking-wider md:w-40 text-left">
                  {/* Your Annual Contribution({currency}) */}
                  <FormattedMessage id="basicMedical.yourAnnualContribution" defaultMessage="Your Annual Contribution"/>({currency})
                </th>
                <th className="md:tracking-wider md:w-40 text-left">
                  {/* Your Cost Per Pay Period({currency}){period} */}
                  <FormattedMessage id="basicMedical.yourCostPerPayPeriod" defaultMessage="Your Cost Per Pay Period"/>({currency})
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    fontSize: "2rem",
                    lineHeight: "2.5rem",
                    padding: "0px 0.5rem",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "2rem" }}
                  />
                </td>
                <td
                  style={{
                    fontSize: "2rem",
                    lineHeight: "2.5rem",
                    padding: "0px 0.5rem",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "2rem" }}
                  />
                </td>
                <td
                  style={{
                    fontSize: "2rem",
                    lineHeight: "2.5rem",
                    padding: "0px 0.5rem",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "2rem" }}
                  />
                </td>
                <td
                  style={{
                    fontSize: "2rem",
                    lineHeight: "2.5rem",
                    padding: "0px 0.5rem",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "2rem" }}
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "2rem",
                    lineHeight: "2.5rem",
                    padding: "0px 0.5rem",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "2rem" }}
                  />
                </td>
                <td
                  style={{
                    fontSize: "2rem",
                    lineHeight: "2.5rem",
                    padding: "0px 0.5rem",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "2rem" }}
                  />
                </td>
                <td
                  style={{
                    fontSize: "2rem",
                    lineHeight: "2.5rem",
                    padding: "0px 0.5rem",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "2rem" }}
                  />
                </td>
                <td
                  style={{
                    fontSize: "2rem",
                    lineHeight: "2.5rem",
                    padding: "0px 0.5rem",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "2rem" }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {/* Total */}
                  <FormattedMessage id="basicMedical.total" defaultMessage="Total"/>
                </td>
                <td
                  style={{
                    fontSize: "2rem",
                    lineHeight: "2.5rem",
                    padding: "0px 0.5rem",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "2rem" }}
                  />
                </td>
                <td
                  style={{
                    fontSize: "2rem",
                    lineHeight: "2.5rem",
                    padding: "0px 0.5rem",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "2rem" }}
                  />
                </td>
                <td
                  style={{
                    fontSize: "2rem",
                    lineHeight: "2.5rem",
                    padding: "0px 0.5rem",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "2rem" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="flex flex-wrap justify-between w-full total">
            <div className="totalOuter">
              {/* <p className="totalText">Total Cost</p> */}
              <p className="include">
                <span className="total">{/* {totalCost.toFixed(2)} $ */}</span>
                {/* *Excluding tax */}
                *<FormattedMessage id="basicMedical.excludingTax" defaultMessage="Excluding tax"/>
              </p>
            </div>
            <button className="backBtn" onClick={onBack}>
               {/* Back */}
               <FormattedMessage id="common.button.back" defaultMessage="Back"/>
            </button>
          </div>
        </div>
      // loading skeleton ends here
      ) : (
        <>
          <div className="fex flex-wrap">
            <img src={LineGreenIcon} alt="Line" className="lineGreen" />
          </div>
          <div className="fex flex-wrap overflow-auto">
            <p className="title">
              {/* Total Cost* */}
              <FormattedMessage id="basicMedical.totalCost" defaultMessage="Total Cost"/>*
              </p>

              <p className="viewCostContentMsg">

                This is the current year 
                Prorated Premium adjusted 
                for the time covered during 
                the policy year.

                </p>
            
            <table className="w-full">
              <thead>
                <tr>
                  <th className="md:tracking-wider md:w-40 text-left">
                    {/* Relation */}
                    <FormattedMessage id="basicMedical.totalCost" defaultMessage="Relation"/>
                  </th>
                  <th className="md:tracking-wider md:w-40 text-left">
                    {/* Employer Annual Contribution({currency}) */}
                    <FormattedMessage id="basicMedical.employerAnnualContribution" defaultMessage="Employer Annual Contribution"/>({currency})
                  </th>
                  <th className="md:tracking-wider md:w-40 text-left">
                    {/* Your Annual Contribution({currency}) */}
                    <FormattedMessage id="basicMedical.yourAnnualContribution" defaultMessage="Your Annual Contribution"/>({currency})
                  </th>
                  <th className="md:tracking-wider md:w-40 text-left">
                    {/* Your Cost Per Pay Period({currency}) */}
                    <FormattedMessage id="basicMedical.yourCostPerPayPeriod" defaultMessage="Your Cost Per Pay Period"/>({currency})
                    {payCheckFrequency != "NA" && `(${payCheckFrequency})`}
                  </th>
                </tr>
              </thead>
              <tbody>
                {costData?.map((item, idx) => (
                  <tr key={idx}>
                    <td>
                      {item.Name} {`(${item.Relation})`}{" "}
                    </td>
                    <td>{currencyFormat(item.employerCost)}</td>
                    <td>{currencyFormat(item.EmployeeCost)}</td>
                    <td>{currencyFormat(item.PaycheckFrequency)}</td>
                  </tr>
                ))}
                <tr>
                  <td>
                  {/* Total */}
                  <FormattedMessage id="basicMedical.total" defaultMessage="Total"/>
                  </td>
                  <td>{currencyFormat(getTotalEmployerPrice())}</td>
                  <td>{currencyFormat(getTotalEmployeePrice())}</td>
                  <td>{currencyFormat(getTotalfrequency())}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex flex-wrap justify-between w-full total">
            <div className="totalOuter">
              {/* <p className="totalText">Total Cost</p> */}
              <p className="include">
                <span className="total">{/* {totalCost.toFixed(2)} $ */}</span>
                {/* *Excluding tax */}
                *<FormattedMessage id="basicMedical.excludingTax" defaultMessage="Excluding tax"/>
              </p>
            </div>
            <button className="backBtn" onClick={onBack}>
              {/* Back */}
              <FormattedMessage id="common.button.back" defaultMessage="Back"/>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default GetCostVersion2;

