const { createAction } = require('redux-actions');
const GET_NOTIFICATION_COUNT = 'BENEVOLVE/GET_NOTIFICATION_COUNT';
const GET_NOTIFICATION_COUNT_SUCCESS = 'BENEVOLVE/GET_NOTIFICATION_COUNT_SUCCESS';
const GET_NOTIFICATION_COUNT_FAILED = 'BENEVOLVE/GET_NOTIFICATION_COUNT_FAILED';

 
const GET_NOTIFICATION_REQUEST = 'BENEVOLVE/GET_NOTIFICATION_REQUEST';
const GET_NOTIFICATION_REQUEST_SUCCESS = 'BENEVOLVE/GET_NOTIFICATION_REQUEST_SUCCESS';
const GET_NOTIFICATION_REQUEST_FAILED = 'BENEVOLVE/GET_NOTIFICATION_REQUEST_FAILED';


 
const GetNotificationRequestCount = createAction(GET_NOTIFICATION_COUNT);
const GetNotificationRequestCountSuccess = createAction(GET_NOTIFICATION_COUNT_SUCCESS);
const GetNotificationRequestCountFailed = createAction(GET_NOTIFICATION_COUNT_FAILED);

const GetNotificatiionrequest = createAction(GET_NOTIFICATION_REQUEST);
const GetNotificatiionrequestSuccess = createAction(GET_NOTIFICATION_REQUEST_SUCCESS);
const GetNotificatiionrequestFailed = createAction(GET_NOTIFICATION_REQUEST_FAILED);

 

export const actions = {
    GetNotificationRequestCount,
    GetNotificationRequestCountSuccess,
    GetNotificationRequestCountFailed,

    GetNotificatiionrequest,
    GetNotificatiionrequestSuccess,
    GetNotificatiionrequestFailed,

    
};

export const types = {
    GET_NOTIFICATION_COUNT,
    GET_NOTIFICATION_COUNT_SUCCESS,
    GET_NOTIFICATION_COUNT_FAILED,

    GET_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_REQUEST_SUCCESS,
    GET_NOTIFICATION_REQUEST_FAILED,

    
};
