import carLease from "../../../assets/carLeaseProgram/carLease.png";
import carLeaseActive from "../../../assets/carLeaseProgram/carLeaseActive.png";
import gym from "../../../assets/reimbuersement/gym.png";
import gymActive from "../../../assets/reimbuersement/gymActive.png";
import internet from "../../../assets/reimbuersement/internet.png";
import internetActive from "../../../assets/reimbuersement/internetActive.png";
import childImg from "../../../assets/reimbuersement/child.png";
import successUpload from "../../../assets/carLeaseProgram/uploadSuccess.png";
import files from "../../../assets/carLeaseProgram/files.png";
import browse from "../../../assets/carLeaseProgram/browse.png";
import check from "../../../assets/carLeaseProgram/active.png";
import Rectangle from "../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../assets/radioCheck.svg";
import { useState } from "react";
import ButtonM from "components/common/Button";
import { useHistory } from "react-router-dom";

var classNames = require("classnames");

// const parse = require("html-react-parser");
const CarLeaseProgram = () => {
  const history = useHistory();

  const [activeTab, setActiveTab] = useState("");
  const [activeItem, setActiveItem] = useState("");

  return (
    <div>
      <div className="carLeaseProgram p-4">
        <div className="p-4">
          <div>
            <p className="mainTitle">
              {activeTab == ""
                ? "Select the Car lease program"
                : "Select the reimbursement types"}
            </p>
            <div className="grid grid-cols-3 gap-2 py-4 cardOuter">
              <div
                onClick={() => setActiveTab(activeTab == "" ? "carLease" : "")}
                className={classNames("card", {
                  active: activeTab === "carLease",
                })}
              >
                <div className="cardInner">
                  <div className="imageOuter">
                    <img src={carLease} alt="Line" className="cardImg" />
                    <img
                      src={carLeaseActive}
                      alt="Line"
                      className="cardImg active"
                    />
                  </div>
                  <p className="title">Car Lease</p>
                  <img src={check} alt="Line" className="activeImg" />
                </div>
              </div>
            </div>

            {activeTab != "" && (
              <div className="bannerTextOuter p-4">
                <p className="bannerText">
                  You are eligible to get a car lease of car value upto Rs 25
                  lakh
                </p>
              </div>
            )}

            {activeTab != "" && (
              <p className="mainTitle py-4">Do you want to avail Car Lease?</p>
            )}

            {activeTab != "" && (
              <div className="grid grid-cols-3 gap-1 py-4 radioButtonOuter">
                <button
                  onClick={() =>
                    setActiveItem(
                      activeItem == "" ? "yes" : activeItem == "no" ? "yes" : ""
                    )
                  }
                  className={classNames("radioButton", {
                    active: activeItem === "yes",
                  })}
                >
                  <div className="radioOuter">
                    <img src={Rectangle} alt="" className="radio" />
                    <img src={radioCheckIcon} alt="" className="radioCheck" />
                  </div>
                  Yes, I want
                </button>
                <button
                  onClick={() =>
                    setActiveItem(
                      activeItem == "" ? "no" : activeItem == "yes" ? "no" : ""
                    )
                  }
                  className={classNames("radioButton", {
                    active: activeItem === "no",
                  })}
                >
                  <div className="radioOuter">
                    <img src={Rectangle} alt="" className="radio" />
                    <img src={radioCheckIcon} alt="" className="radioCheck" />
                  </div>
                  No, I don’t want
                </button>
              </div>
            )}

            {activeTab != "" && activeItem == "yes" && (
              <p className="info py-4">
                Please use this document to present to the dealer on your
                eligibility and options
              </p>
            )}
            {activeTab != "" && activeItem == "yes" && (
              <p className="download">
                <img src={files} alt="" className="files" />
                <a className="link">Download</a>
              </p>
            )}
            {activeTab != "" && activeItem == "yes" && (
              <p className="info py-4">
                upload documents to initiate your lease
              </p>
            )}
            {activeTab != "" && activeItem == "yes" && (
              <ButtonM
                text="upload"
                onpress={() =>
                  history.push("/carLeaseProgram/upload-car-lease-docs")
                }
              />
            )}
          </div>

          {/* <div>
            <p className="mainTitle py-4">
              Checklist of document to be uploaded
            </p>
            <div className="grid grid-cols-3 gap-1 py-4 radioButtonOuter">
              <button className="radioButton active">
                <div className="radioOuter">
                  <img src={Rectangle} alt="" className="radio" />
                  <img src={radioCheckIcon} alt="" className="radioCheck" />
                </div>
                Lease document
              </button>
              <button className="radioButton">
                <div className="radioOuter">
                  <img src={Rectangle} alt="" className="radio" />
                  <img src={radioCheckIcon} alt="" className="radioCheck" />
                </div>
                Payment schedule
              </button>
            </div>
            <div className="grid grid-cols-2 gap-2 py-4">
              <div class="outer">
                <p className="info pb-4">Driving Licence</p>
                <div className="browseOuter">
                  <div className="browseInner">
                    <img src={browse} alt="Browse" className="browse" />
                    <p className="subTitle">Browse</p>
                  </div>
                </div>
              </div>
              <div class="outer">
                <p className="info pb-4">Car RC</p>
                <div className="browseOuter">
                  <div className="browseInner">
                    <img src={browse} alt="Browse" className="browse" />
                    <p className="subTitle">Browse</p>
                  </div>
                </div>
              </div>
            </div>
            <ButtonM text="submit" />
          </div> */}
          {/* <div>
            <div className="successs">
              <img src={successUpload} alt="" className="successUpload" />
              <p className="text1">
                Congratulations your document has been successfully upload
              </p>
              <p className="text2">
                Please click on view cost to see cost sechudule
              </p>
              <a href="#" className="viewCost">
                VIEW COST
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default CarLeaseProgram;
