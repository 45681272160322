import { reimburseTypes } from "./action"
import { handleActions } from 'redux-actions';

const reducerHandller = {
    [reimburseTypes.GET_REIMBURSE_PLAN_REQUEST]: (state) => {
        return {
            ...state,
            loader: true
        }
    },
    [reimburseTypes.GET_REIMBURSE_PLAN_REQUEST_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            loader: false,
            reimbursePlanData: payload,
             }
    },
    [reimburseTypes.GET_REIMBURSE_PLAN_REQUEST_FAIL]: (state, { payload }) => {

        return {
            ...state,
            loader: false,
            reimbursePlanData:{},
            }
    },   

    [reimburseTypes.GET_ALL_CLAIMS_REQUEST]: (state) => {
        return {
            ...state,
            allClaimLoader: true
        }
    },
    [reimburseTypes.GET_ALL_CLAIMS_REQUEST_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            allClaimLoader: false,
            allClaims: payload,
             }
    },
    [reimburseTypes.GET_ALL_CLAIMS_REQUEST_FAILED]: (state, { payload }) => {

        return {
            ...state,
            allClaimLoader: false,
            allClaims:[],
            }
    },   

}

const initialState = {
    loader: false,
    reimbursePlanData:{},
    allClaimLoader: false,
    allClaims:[],

}

export default handleActions(reducerHandller, initialState)