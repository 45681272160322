import loginBg from "../../../../assets/loginBg.svg";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ButtonM from "../../../common/Button";
import { Link, useLocation, useHistory } from "react-router-dom";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import APIList from "../../../../api";
import { isEmptyNullUndefined } from "components/utils/utils";

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const initialEmptyChangePasswordForm = {
    newPassword: null,
    confirmNewPassword: null,
  };
  const initialEmptyChangePasswordFormErrorList = {
    newPassword: { isError: false, errorMessage: "" },
    confirmNewPassword: { isError: false, errorMessage: "" },
    unmatch: { isError: false, errorMessage: "" },
  };
  const [password, setPassword] = useState(initialEmptyChangePasswordForm);
  const [passwordError, setPasswordError] = useState(
    initialEmptyChangePasswordFormErrorList
  );

  const key = location.search.split("=")[1];

  //   const loading = useSelector(
  //     (state) => state && state.user && state.forgotPassword.loader
  //   );

  useEffect(() => {
    window.localStorage.clear();
  }, []);

  const handleChangePasswordForm = async (event, fieldType) => {
    const tempPassword = JSON.parse(JSON.stringify(password));
    const tempError = JSON.parse(JSON.stringify(passwordError));

    if (fieldType === "newPassword") {
      tempPassword[event.target.name] = event.target.value;
      tempError.newPassword.isError = false;
      tempError.newPassword.errorMessage = "";
    }
    if (fieldType === "confirmNewPassword") {
      tempPassword[event.target.name] = event.target.value;
      tempError.confirmNewPassword.isError = false;
      tempError.confirmNewPassword.errorMessage = "";
    }

    setPassword(tempPassword);
    setPasswordError(tempError);
  };

  const handleValidatePassword = async () => {
    let isValid = true;
    const tempPassword = JSON.parse(JSON.stringify(password));
    const tempError = JSON.parse(JSON.stringify(passwordError));

    if (isEmptyNullUndefined(tempPassword.newPassword)) {
      tempError.newPassword.isError = true;
      tempError.newPassword.errorMessage = "*Please enter your new Password";
      isValid = false;
    }

    if (!isEmptyNullUndefined(tempPassword.newPassword)) {
      if (
        !tempPassword.newPassword.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        )
      ) {
        tempError.newPassword.isError = true;
        tempError.newPassword.errorMessage =
          "must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
        isValid = false;
      }
    }
    if (isEmptyNullUndefined(tempPassword.confirmNewPassword)) {
      tempError.confirmNewPassword.isError = true;
      tempError.confirmNewPassword.errorMessage =
        "*Please confirm your new Password";
      isValid = false;
    }
    if (!isEmptyNullUndefined(tempPassword.confirmNewPassword)) {
      if (
        !tempPassword.confirmNewPassword.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        )
      ) {
        tempError.confirmNewPassword.isError = true;
        tempError.confirmNewPassword.errorMessage =
          "must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
        isValid = false;
      }
    }

    if (
      !isEmptyNullUndefined(tempPassword.newPassword) &&
      !isEmptyNullUndefined(tempPassword.confirmNewPassword) &&
      JSON.stringify(tempPassword.newPassword) !==
        JSON.stringify(tempPassword.confirmNewPassword)
    ) {
      tempError.unmatch.isError = true;
      tempError.unmatch.errorMessage = "Passwords do not match";
      isValid = false;
    }

    //do Submit
    if (isValid) {
      submitButtonClick();
    } else {
      setPasswordError(tempError);
    }
  };

  const submitButtonClick = () => {
    APIList.resetPassword({ key, newPassword: password.newPassword })
      .then((res) => {
        toast.success(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {"Password reset done successfully! Please login"}
          </div>
        );
        setPassword(initialEmptyChangePasswordForm);
        setPasswordError(initialEmptyChangePasswordFormErrorList);
        history.push("/login");
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.detail}
          </div>
        );
      });
  };

  return (
    <div className="w-full absolute top-0">
      <div className="headerLogin">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full">
              <div className="headerLoginInner flex flex-col">
                <img src={loginBg} alt="logBg" className="logoImg" />
                <div className="left pt-4 pb-4 flex flex-col items-start">
                  <p className="text">
                    Reset Your Password <br />
                    {/* <span className="textInner">Benevolve</span> */}
                  </p>
                  <p className="desc">Enter your new password.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="forgotPasswordBlock">
        <div className="container my-4 mx-auto px-5">
          <div className="flex flex-wrap pt-2 pb-2">
            <div className="my-1 w-full md:w-1/2 lg:my-4 lg:px-2 lg:w-1/2">
              <input
                onChange={(event) =>
                  handleChangePasswordForm(event, "newPassword")
                }
                name="newPassword"
                value={password.newPassword}
                className={
                  passwordError.newPassword.error
                    ? "input border border-red-500 focus:outline-none"
                    : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                }
                type="password"
                placeholder="Password"
              />
              {passwordError.newPassword.isError && (
                <p className="mt-2 text-sm" style={{color:"var(--warning)"}}>
                  {passwordError.newPassword.errorMessage}
                </p>
              )}
            </div>
          </div>

          <div className="flex flex-wrap pt-2 pb-2">
            <div className="my-1 w-full md:w-1/2 lg:my-4 lg:px-2 lg:w-1/2">
              <input
                onChange={(event) =>
                  handleChangePasswordForm(event, "confirmNewPassword")
                }
                name="confirmNewPassword"
                value={password.confirmNewPassword}
                className={
                  passwordError.confirmNewPassword?.isError
                    ? "input border border-red-500 focus:outline-none"
                    : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                }
                type="password"
                placeholder="Confirm Password"
              />
              {passwordError.confirmNewPassword.isError && (
                <p className="mt-2 text-sm" style={{color:"var(--warning)"}}>
                  {passwordError.confirmNewPassword.errorMessage}
                </p>
              )}
              {passwordError.unmatch.errorMessage && (
                <p className="mt-2 text-sm" style={{color:"var(--warning)"}}>
                  {passwordError.unmatch.errorMessage}
                </p>
              )}
            </div>
          </div>

          <div className="flex flex-wrap pt-8 pb-2">
            <div className="w-full">
              {/* <button onClick={() => checkValidation()} className="login">
              Login
            </button> */}
              <ButtonM
                text="Reset Password"
                onpress={() => handleValidatePassword()}
                bgcolor="var(--primary)"
                color="#ffffff"
                bghover="#287546"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
