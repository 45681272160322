import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../api/index';
import { actions, types } from './actions';
// import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";

const PlanDataRequest = function* PlanDataRequest({ payload }) {
    if(payload === "clearData"){
        yield put(actions.PlanDataRequestSuccess({}));
    }else{
    try {
        const { data } = yield call(api.getPlanDetails, payload);
        if (data) {
            yield put(actions.PlanDataRequestSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.PlanDataRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.PlanDataRequestFailed());
    }
}
};

const EnrolledPlanDataRequest = function* EnrolledPlanDataRequest({ payload }) {
    if(payload === "clearData"){
        yield put(actions.EnrolledPlanDataRequestSuccess({}));
    }else{
    try {
        const { data } = yield call(api.getEnrolledPlanDetails, payload);
        if (data) {
            yield put(actions.EnrolledPlanDataRequestSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.EnrolledPlanDataRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.PlanDataRequestFailed());
    }
}
};

const getFinalEnrollPlansRequest = function* getFinalEnrollPlansRequest({ payload }) {
    
    if(payload === "clearData"){
        yield put(actions.getFinalEnrollPlansRequestSuccess([]));
    }else{
    try {
        const { data } = yield call(api.getFinalEnrolledPlans, payload);
        if (data) {
            yield put(actions.getFinalEnrollPlansRequestSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.getFinalEnrollPlansRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.getFinalEnrollPlansRequestFailed());
    }
}
};


export default function* sagas() {
    yield takeLatest(types.PLAN_DATA_REQUEST, PlanDataRequest);
    yield takeLatest(types.ENROLLED_PLAN_DATA_REQUEST, EnrolledPlanDataRequest);    
    yield takeLatest(types.FINAL_ENROLLED_PLANS, getFinalEnrollPlansRequest);
}
