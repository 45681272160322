import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonM from "../../../../../../common/Button";
import PageTitleAndDescription from "../../../../../../common/PageTitleAndDescription";
import { actions as VolutryActions } from "../../../../redux/actions";
// import VoluntaryParentalModal from "../../../../ViewModal/index";
import { CurrencyFormat } from "components/utils/numberFormatter";
import APIList from "api";
import { RiErrorWarningFill } from "react-icons/ri";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import GetCostVersion2 from "components/pages/BasicMedical/ViewCost/Version2";
import { getPayCheckFrequency, getRelationName } from "components/utils/utils";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";
import { actions as getPlanActions } from "../../../../../../common/Plans/redux/actions";
var classNames = require("classnames");

const BundleIndividualPlanFinal = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const currency = employeeDetails?.company?.currency;

  const { selectedCoverages, bundleEnrolls, selectedCategories, progressArray } = useSelector((state) => state?.voluntryReducer);
  const enrolledPlanData = useSelector((state) => state?.planReducer?.enrolledPlanData);
  const benefitStartDate = useSelector((state) => state?.planReducer?.benefitStartDate);

  const [openModal, setOpenModal] = useState(false);
  const [electionChecked, setElectionChecked] = useState(false);
  const [totalDependents, setTotalDependents] = useState([]);
  const [groupPILCoverages, setGroupPILCoverages] = useState([]);
  const [groupParentCoverages, setGroupParentCoverages] = useState([]);
  const [individualCoverages, setIndividualCoverages] = useState([]);
  const [enrollLoader, setEnrollLoader] = useState(false);
  const [getCostLoader, setGetCostLoader] = useState(false);
  const [editId, setEditId] = useState("");  
  const [viewCost, setViewCost] = useState(false);
  const [costData, setCostData] = useState([]);

  useEffect(() => {
    if(selectedCategories?.length < 1){
      history.push("/voluntry");
    }
    getTotalDependants();
  }, []);

  useEffect(() => {
    const groupPILData = bundleEnrolls.filter(x => (x.typeSelected == "group" && (x.relation == "FIL" || x.relation == "MIL")));
    setGroupPILCoverages(groupPILData);
    const groupParentData = bundleEnrolls.filter(x => (x.typeSelected == "group" && (x.relation == "Mother" || x.relation == "Father")));
    setGroupParentCoverages(groupParentData);
    const individualData = bundleEnrolls.filter(x => x.typeSelected == "individual");
    setIndividualCoverages(individualData);
  }, [bundleEnrolls]);

  function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setViewCost(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);

  const getTotalDependants = () => {
    let allEnrolled = selectedCoverages?.map((x) => x.name);
    setTotalDependents(allEnrolled);
  };

  const isEdit = () => {
    let edit = false;
    if(enrolledPlanData?.plan?.id == selectedPlan?.id){
       edit = true;
    }
    return edit;
  };  

  const handleEnrollment = () => {
    if(selectedCategories?.length != progressArray?.length){
      history.push("/voluntry/bundle-add-parents");
    }else{
    setEnrollLoader(true);

    if(isEdit()){ 
      APIList.editHealthInsuranceEnroll({
        id:enrolledPlanData?.id,
        benefitStartDate:benefitStartDate,
        employee:{
          id: employeeDetails.id
        },
        isDraft: true,
        status: "ISDRAFT",
        isEmployeeEnrolled: false,
        selectedCoverage:
        // null,
        {
          id: selectedPlan?.coveragePlans[0]?.id
        },
        employeeCoverage: 
        // null,
        selectedPlan?.coveragePlans[0]?.amount,
        plan:{
          id: selectedPlan.id,
          className: selectedPlan.className
        },
        enrollmentDate: new Date(),
        dependantEnrollments: bundleEnrolls.map((x) => {return({
                                  life: x.typeSelected == "individual" ? 1 : 2,
                                  benefitStartDate:benefitStartDate,
                                  dependants:{
                                    id: x.dependantId
                                  },
                                  dependantEnrollmentDate: new Date(),
                                  selectedPlan:{
                                    id: x.planDependantId
                                  },
                                  dependentCoverage: x.amount
                                })})                   
     }).then((res) => {
       res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Enrolled in {selectedPlan?.displayName} successfully</div>);
       setEnrollLoader(false);
       history.push({ pathname: "/plans", state: { fromAddress: true } });
     })
     .catch((err) =>{
       toast.error(
         <div className='flex flex-row'>
           <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
           &nbsp;&nbsp;{err?.title}
         </div>
       );      
       setEnrollLoader(false);      
     }
     )
     } else {

    APIList.healthInsuranceEnroll({
      employee:{
        id: employeeDetails.id
      },
      benefitStartDate:benefitStartDate,
      isDraft: true,
      status: "ISDRAFT",
      isEmployeeEnrolled: false,
      selectedCoverage:
      // null,
      {
        id: selectedPlan?.coveragePlans[0]?.id
      },
      employeeCoverage: 
      // null,
      selectedPlan?.coveragePlans[0]?.amount,
      plan:{
        id: selectedPlan.id,
        className: selectedPlan.className
      },
      enrollmentDate: new Date(),
      dependantEnrollments: bundleEnrolls.map((x) => {return({
                                life: x.typeSelected == "individual" ? 1 : 2,
                                benefitStartDate:benefitStartDate,
                                dependants:{
                                  id: x.dependantId
                                },
                                dependantEnrollmentDate: new Date(),
                                selectedPlan:{
                                  id: x.planDependantId
                                },
                                dependentCoverage: x.amount
                              })})
    }).then((res) => {
      res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Enrolled in {selectedPlan?.displayName} successfully</div>);
      setEnrollLoader(false);
      // history.push({ pathname: "/plans", state: { fromAddress: true } });
      dispatch(getPlanActions.PlanDataRequest("clearData")); 
      history.push({pathname:'/loading', state:{planName:selectedPlan?.displayName}});
    })
    .catch((err) =>{
      toast.error(
        <div className='flex flex-row'>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{err?.title}
        </div>
      );      
      setEnrollLoader(false);      
    }
    );
  }
}
  };

  const handleViewCost = () => {

    setViewCost(true);
    // const employee = allCoverageCards.filter(x => x.dependantId == employeeDetails.id);
    const dependants = bundleEnrolls;
    // allCoverageCards.filter(x => x.dependantId != employeeDetails.id);

    setGetCostLoader(true);

    APIList.getCost({
      employee:{
        id: employeeDetails.id
      },
      isEmployeeEnrolled: false,
      selectedCoverage:
      // null,
      {
        id: selectedPlan?.coveragePlans[0]?.id
      },
      employeeCoverage: 
      // null,
      selectedPlan?.coveragePlans[0]?.amount,
      plan:{
        id: selectedPlan.id,
        className: selectedPlan.className
      },
      enrollmentDate: new Date(),
      dependantEnrollments: dependants.map((x) => {return({
                                life: x.typeSelected == "individual" ? 1 : 2,
                                dependants:{
                                  id: x.dependantId
                                },
                                dependantEnrollmentDate: new Date(),
                                selectedPlan:{
                                  id: x.planDependantId
                                },
                                dependentCoverage: x.amount
                              })})
    }).then((res) => {
      // res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;{}</div>);
      setCostData(res.data);
      setGetCostLoader(false);
    })
    .catch((err) =>{
      toast.error(
        <div className='flex flex-row'>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{err?.title}
        </div>
      );      
      setGetCostLoader(false);      
    }
    );

  };

  const handleEdit = (coverage) => {
    
    setEditId(coverage.dependantId);
    setTimeout(() => {      
      history.push(`/voluntry/bundle-edit-parents/${coverage?.category}`);
    }, 1000);
    
  }

  window.onpopstate = () => {
    dispatch(VolutryActions.SetSelectedCategories([]));
    dispatch(VolutryActions.SetCurrentlyEnrolled([]));
    dispatch(VolutryActions.SetProgressArray([]));
 };

  return (
    <div className="individualPlanFinal">
      <div>
        <h4 className="page-heading-navbar">{selectedPlan.displayName}</h4>
        <PageHeaderForPlans 
        // btnRedirect={() => setOpenModal(true)} 
        />
      </div>

      <div className="data">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <PageTitleAndDescription
              title={"Summary Election"}
              description={`You have selected the following coverage:`}
            />

            
            <div className="cardsBlock-group">
              {groupParentCoverages?.length > 0 &&
                
                  // return(
                    <div className={classNames("coverageCard-group", {
                      // deletingCard: (coverage.dependantId == deleteId),
                      editingCard: (groupParentCoverages[0].dependantId == editId),
                    })}
                    >
                      <div className="upperSection">
                        {
                          groupParentCoverages?.map((coverage) => {
                            return(
                              <div className="left">
                                <p className="title">Relation</p>
                                <p className="description">{getRelationName(coverage.relation)}</p>
                              </div>
                            )
                          })
                        }
                        
                        <div className="text-sm" style={{color:"var(--secondary)"}}>
                          <p>Group</p>
                        </div>
                      </div>
                      <hr />
                      <div className="lowerSection">
                        <div className="left">
                          <p className="title">Coverage Amount</p>
                          <p className="description">{CurrencyFormat(groupParentCoverages[0].amount,currency)}</p>
                        </div>
                        <div className="right">                          
                            <button onClick={() => handleEdit(groupParentCoverages[0])}>Edit</button>                        
                        </div>
                      </div>
                    </div>
                  // )
                // })
              }
            </div>

            <div className="cardsBlock-group">
              {groupPILCoverages?.length > 0 &&
                
                  // return(
                    <div className={classNames("coverageCard-group", {
                      // deletingCard: (coverage.dependantId == deleteId),
                      editingCard: (groupPILCoverages[0].dependantId == editId),
                    })}
                    >
                      <div className="upperSection">
                        {
                          groupPILCoverages?.map((coverage) => {
                            return(
                              <div className="left">
                                <p className="title">Relation</p>
                                <p className="description">{getRelationName(coverage.relation)}</p>
                              </div>
                            )
                          })
                        }
                        
                        <div className="text-sm" style={{color:"var(--secondary)"}}>
                          <p>Group</p>
                        </div>
                      </div>
                      <hr />
                      <div className="lowerSection">
                        <div className="left">
                          <p className="title">Coverage Amount</p>
                          <p className="description">{CurrencyFormat(groupPILCoverages[0].amount,currency)}</p>
                        </div>
                        <div className="right">                          
                            <button onClick={() => handleEdit(groupPILCoverages[0])}>Edit</button>                        
                        </div>
                      </div>
                    </div>
                  // )
                // })
              }
            </div>


            <div className="cardsBlock">
              { individualCoverages?.length > 0 &&
                individualCoverages?.map((coverage) => {
                  return(
                    <div className={classNames("coverageCard", {
                      // deletingCard: (coverage.dependantId == deleteId),
                      editingCard: (coverage.dependantId == editId),
                    })}
                    >
                      <div className="upperSection">
                      
                              <div className="left">
                                <p className="title">Relation</p>
                                <p className="description">{getRelationName(coverage.relation)}</p>
                              </div>
                                                  
                        <div className="text-sm" style={{color:"var(--secondary)"}}>
                          <p>Individual</p>
                        </div>
                      </div>
                      <hr />
                      <div className="lowerSection">
                        <div className="left">
                          <p className="title">Coverage Amount</p>
                          <p className="description">{CurrencyFormat(coverage.amount,currency)}</p>
                        </div>
                        <div className="right">                          
                            <button onClick={() => handleEdit(coverage)}>Edit</button>                        
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            {/* {
              (getNumberOfDependantEnroll() > selectedPlan?.totalLiveMaxLimit) ?
              <p className="flex items-center mx-auto my-4 text-red-600">
                <BiError style={{fontSize:"1.2rem"}} />&nbsp;Can not select more than {selectedPlan?.totalLiveMaxLimit} lives in this plan
              </p>
              : */}
              <p className="w-full flex justify-end mx-auto my-4 linksOuter">
                <span className="cursor-pointer viewLink" onClick={() => handleViewCost()}>
                View Cost
                </span>
                </p>
            {/* } */}

            <div className="bottomButtonReq px-4">
              {/* <button className="next skip">I want to skip for now</button> */}
             {
             (selectedCategories?.length == progressArray?.length) &&
              <p
                className="mt-2 peer-invalid:visible text-gray-400 text-sm"
                style={{ marginBottom: "-1rem" }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="success"
                        checked={electionChecked}
                        onChange={() => setElectionChecked(!electionChecked)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Click here to confirm"
                  />
                </FormGroup>
              </p>}
              <ButtonM
                text= { selectedCategories?.length == progressArray?.length ? "SUBMIT" : "NEXT" }
                disabled={
                  (
                    // (getNumberOfDependantEnroll() > selectedPlan?.totalLiveMaxLimit) || 
                    ((selectedCategories?.length == progressArray?.length) && !electionChecked))
                }
                loading={enrollLoader}
                bgcolor={
                  (
                    // (getNumberOfDependantEnroll() > selectedPlan?.totalLiveMaxLimit) || 
                    ((selectedCategories?.length == progressArray?.length) && !electionChecked) )
                    ? 
                      "gray"
                    : ""
                }
                onpress={() => handleEnrollment()}
              />
            </div>
          </div>
        </div>
      </div>


{viewCost && (
        <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-full h-full popupWrapper">
            <div className="totalCostPopup" ref={wrapperRef}>
            <GetCostVersion2 
              onBack={() => setViewCost(false)}
              loader={
                getCostLoader
                // true
              }
              costData = {costData}
              
              payCheckFrequency={getPayCheckFrequency(selectedPlan, employeeDetails)}
              />
              {/* <GetCost
                onBack={() => setViewCost(false)}
                loader={getCostLoader}
                // coveragePlan={coveragePlan}
                // rateCard={selectedPlan && selectedPlan.rateCard}
                // enrolledDependants={enrolledDependants}
                // deductionMethod={selectedPlan && selectedPlan.deductionMethod}
                // applicableRatecards={applicableRatecards}
                // elligiblePlanDependant={elligiblePlanDependant}
                // isEmployeeEnroll={isEmployeeEnroll}
                // payCheckFrequency={PayCheckFreq}
              /> */}
            </div>
          </div>
        </div>
      )}

      {/* {openModal && <VoluntaryParentalModal setOpenModal={setOpenModal} />} */}
    </div>
  );
};

export default BundleIndividualPlanFinal;
