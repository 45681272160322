import React from 'react';
import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import userIcon from "../../../../assets/user.svg";
import hamburger from 'assets/hamberger.png';
import Home from '../../../../assets/home-white.svg';
import MyBeneficiery from '../../../../assets/MyBeneficiery.svg';
import MyDependents from '../../../../assets/MyDependents.svg';
import MyDocuments from '../../../../assets/MyDocuments.svg';
import calender from '../../../../assets/calendar.svg';
import { actions as SidebarActions } from "../redux/actions"
import APIList from '../../../../api';
import { actions as EmployeeDataActions } from "../../Header/redux/actions";
import { BsFillCheckCircleFill, BsGlobe } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { RiErrorWarningFill } from 'react-icons/ri';
import { GiReceiveMoney } from 'react-icons/gi';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { isEmptyNullUndefined } from 'components/utils/utils';
import { FormattedMessage } from 'react-intl';

const Sidebar = ({ openSidebar, setOpenSidebar }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const employeeDetails = useSelector((state) => state && state.empData);
    const wrapperRef = useRef(null)
   
    const redirection = (url) => {
        setOpenSidebar(!openSidebar);
        return history.push(`/${url}`);
    };

    function HandleOutsideClick(ref) {
        useEffect(() => {

            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpenSidebar(!openSidebar);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    HandleOutsideClick(wrapperRef);

    const callApi = (id) => {

        // dispatch(SidebarActions.getDeEnroll({id}))
        APIList.getDeEnroll({ id }).then((res) => {
            const payload = {
                empId: employeeDetails && employeeDetails.empData && employeeDetails.empData.id
            };
           
            res && dispatch(EmployeeDataActions.EmployeeEnrolledRequest(payload));
            res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;De-Enrolled Successfully</div>);

        }).catch((err) => {
            toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        })
    }
    return (
        <div className="sidebar backdrop-blur-sm outline-none focus:outline-none">
            <aside className='sidebar-width' aria-label="Sidebar" ref={wrapperRef}>
                <div class="overflow-y-auto py-4 px-3 bg-white rounded dark:bg-gray-800">
                    <div className="headerInner"   >
                        <div className="right">
                            <button
                                className='userOuter'
                                onClick={() => redirection('profile')}
                            >
                                {
                                    employeeDetails?.empData?.profilePhoto?.path ? (<img
                                        src={employeeDetails?.empData?.profilePhoto?.path}
                                        alt="profilePik"
                                        className='img'
                                    />) : (<>
                                        <img src={userIcon} alt='user' className='userImg' />
                                        <h6 className='userName'>
                                            {employeeDetails &&
                                                employeeDetails.empData &&
                                                employeeDetails.empData.firstName &&
                                                employeeDetails.empData.firstName[0].toUpperCase()}
                                        </h6>
                                    </>)
                                }



                            </button>
                            <div className='profile'>
                            {employeeDetails && employeeDetails.empData &&
                                employeeDetails.empData.firstName &&
                                employeeDetails.empData.lastName &&
                                <p className='name'>{`${employeeDetails.empData.firstName} ${employeeDetails.empData.lastName}`}</p>
                            }
                            {/* {
                                employeeDetails && employeeDetails.empData &&
                                employeeDetails.empData.employeeType &&
                                <p className='role'>{employeeDetails.empData.employeeType?.name}</p>
                            } */}
                        </div>


                        </div>
                       
                        <div className="left">
                            <img
                                src={hamburger}
                                alt='Hamburger'
                                className='arrowImg cursor-pointer'
                                onClick={() => setOpenSidebar(!openSidebar)}
                            />
                        </div>
                    </div>

                    <div className='block'>
                        <div>

                            <button className={window.location.pathname === '/home' ?
                                'flex content back-color' : 'flex content'} style={{ width: '90%' }}
                                onClick={() => redirection('home')}>
                                <img
                                    src={Home}
                                    alt='home'
                                    className='arrowImg cursor-pointer li-image'
                                />
                                <p className={window.location.pathname === '/home' ?
                                    'li-text text-color' : 'li-text'}>
                                        {/* Home */}
                                        <FormattedMessage id='sidebar.Home' defaultMessage='Home'/>
                                    </p>
                            </button>

                            <button className={window.location.pathname === '/my-beneficiaries' ?
                                'flex content back-color' : 'flex content'} style={{ width: '90%' }}
                                onClick={() => redirection('my-beneficiaries')}>
                                <img
                                    src={MyBeneficiery}
                                    alt='home'
                                    className='arrowImg cursor-pointer li-image'
                                />
                                <p className={window.location.pathname === '/my-beneficiaries' ?
                                    'li-text text-color' : 'li-text'}>
                                        {/* My Beneficiaries */}
                                        <FormattedMessage id='sidebar.MyBeneficiaries' defaultMessage='My Beneficiaries'/>
                                    </p>
                            </button>

                            <button 
                               className={window.location.pathname === '/my-dependents' ?
                              'flex content back-color' : 'flex content'} style={{ width: '90%' }}
                               onClick={() => redirection('my-dependents')}
                               id="my-dependants-in-sidebar"
                            >
                                <img
                                    src={MyDependents}
                                    alt='home'
                                    className='arrowImg cursor-pointer li-image'
                                />
                                <p className={window.location.pathname === '/my-dependents' ?
                                    'li-text text-color' : 'li-text'}>
                                        {/* My Dependents */}
                                        <FormattedMessage id='sidebar.MyDependents' defaultMessage='My Dependents'/>
                                    </p>
                            </button>

                            <button className={window.location.pathname === '/my-documents' ?
                                'flex content back-color' : 'flex content'} style={{ width: '90%' }}
                                onClick={() => redirection('my-documents')}
                                id="my-documents-in-sidebar"
                                >
                                <img
                                    src={MyDocuments}
                                    alt='home'
                                    className='arrowImg cursor-pointer li-image'
                                />
                                <p className={window.location.pathname === '/my-documents' ?
                                    'li-text text-color' : 'li-text'}>
                                        {/* My Documents */}
                                        <FormattedMessage id='sidebar.MyDocuments' defaultMessage='My Documents'/>
                                    </p>
                            </button>

                            <button className={window.location.pathname === '/calender' ?
                                'flex content back-color' : 'flex content'} style={{ width: '90%' }}
                                onClick={() => redirection('calender')}
                                id="my-documents-in-sidebar"
                                >
                                <img
                                    src={calender}
                                    alt='home'
                                    className='arrowImg cursor-pointer li-image'
                                />
                                <p className={window.location.pathname === '/my-documents' ?
                                    'li-text text-color' : 'li-text'}>
                                        {/* Calendar */}
                                        <FormattedMessage id='sidebar.Calendar' defaultMessage='Calendar'/>
                                    </p>
                            </button>

                            {/* <button className={window.location.pathname === '/my-documents' ?
                                'flex content back-color' : 'flex content'} style={{ width: '90%' }}
                                onClick={() => callApi(employeeDetails?.empData?.id)}

                            >
                                <img
                                    src={MyDocuments}
                                    alt='home'
                                    className='arrowImg cursor-pointer li-image'
                                />
                                <p className={window.location.pathname === 'deEnroll' ?
                                    'li-text text-color' : 'li-text'}>De Enroll</p>
                            </button> */}

                            <button className={window.location.pathname === '/event-log' ?
                                'flex content back-color' : 'flex content'} style={{ width: '90%' }}
                                onClick={() => redirection('event-log')}
                                id="event-logs-in-sidebar"
                            >
                                <img
                                    src={MyDocuments}
                                    alt='home'
                                    className='arrowImg cursor-pointer li-image'
                                />
                                <p className={window.location.pathname === 'event-log' ?
                                    'li-text text-color' : 'li-text'}>
                                        {/* Event Logs */}
                                        <FormattedMessage id='sidebar.EventLogs' defaultMessage='Event Logs'/>
                                    </p>
                            </button>

                            <button className={window.location.pathname === '/benefit-summary' ?
                                'flex content back-color' : 'flex content'} style={{ width: '90%' }}
                                onClick={() => redirection('benefit-summary')}
                                id="benefit-summary-in-sidebar"
                            >
                               <GiReceiveMoney />
                                <p className={window.location.pathname === '/benefit-summary' ?
                                    'li-text text-color' : 'li-text'}>
                                        {/* Benefit Summary */}
                                        <FormattedMessage id='sidebar.BenefitSummary' defaultMessage='Benefit Summary'/>
                                    </p>
                            </button>

                        </div>

                        <div className='content'>
                            {/* <p className='logo'>LOGO</p>
                            <p className='version'>App version 2.99</p> */}
                            {
                                !isEmptyNullUndefined(employeeDetails?.empData?.company?.icon?.path) && 
                                <img className='company-logo-in-sidebar' src={employeeDetails?.empData?.company?.icon?.path} alt="Company Name" />
                            }                            
                        </div>
                    </div>
                </div>
                
            </aside>
            
        </div>
    );
};

export default Sidebar;
