import React, { useState } from "react";
import ButtonM from "components/common/Button";
import Rectangle from "../../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../../assets/radioCheck.svg";
import browse from "../../../../assets/carLeaseProgram/browse.png";
import removeImgBtn from "../../../../assets/reimbuersement/close_remove_image_btn.png";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { BsFillCheckCircleFill } from "react-icons/bs";
var classNames = require("classnames");

const UploadDocuments = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("");
  const [imagePreviewsDl, setImagePreviewsDl] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const initialEmptyUploadDetails = {
    // date: null,
    // description: null,
    // amount: null,
    dl: null,
    rc: null,
  };

  const initialFormErrorList = {
    // date: { isError: false, errorMessage: "" },
    // description: { isError: false, errorMessage: "" },
    // amount: { isError: false, errorMessage: "" },
    rc: { isError: false, errorMessage: "" },
    dl: { isError: false, errorMessage: "" },
  };
  const handleChangeUploadDetails = async (event, fieldType) => {
    const tempUploadDetails = { ...uploadDetails };
    const tempError = JSON.parse(JSON.stringify(formError));
    if (fieldType === "dl") {
      let images = [];
      for (let i = 0; i < event.target.files.length; i++) {
        images.push(URL.createObjectURL(event.target.files[i]));
      }
      setImagePreviewsDl(images);
      tempUploadDetails.dl =
        event.target.files.length > 0 ? event.target.files : undefined;
      tempError.dl.isError = false;
      tempError.dl.errorMessage = "";
    } else if (fieldType === "rc") {
      let images = [];
      for (let i = 0; i < event.target.files.length; i++) {
        images.push(URL.createObjectURL(event.target.files[i]));
      }
      setImagePreviews(images);
      tempUploadDetails.rc =
        event.target.files.length > 0 ? event.target.files : undefined;
      tempError.rc.isError = false;
      tempError.rc.errorMessage = "";
    }

    setUploadDetails(tempUploadDetails);
    setFormError(tempError);
  };

  const [uploadDetails, setUploadDetails] = useState(initialEmptyUploadDetails);
  const [formError, setFormError] = useState(initialFormErrorList);

  function removeFileFromFileList(index, docType) {
    const dt = new DataTransfer();
    const tempUploadDetails = { ...uploadDetails };
    const { rc, dl } = tempUploadDetails;
    if (docType === "rc") {
      for (let i = 0; i < rc.length; i++) {
        const sFile = rc[i];
        if (index !== i) dt.items.add(sFile);
      }
      tempUploadDetails.rc = dt.files;
      setUploadDetails(tempUploadDetails);
    }
    if (docType === "dl") {
      for (let i = 0; i < dl.length; i++) {
        const sFile = dl[i];
        if (index !== i) dt.items.add(sFile);
      }
      tempUploadDetails.dl = dt.files;
      setUploadDetails(tempUploadDetails);
    }
  }

  const dltImgPreview = (index, docType) => {
    if (docType === "dl") {
      let tempImgPrvws = JSON.parse(JSON.stringify(imagePreviewsDl));
      if (index > -1) {
        // only splice array when item is found
        tempImgPrvws.splice(index, 1);
        setImagePreviewsDl(tempImgPrvws);
      }
    }
    if (docType === "rc") {
      let tempImgPrvws = JSON.parse(JSON.stringify(imagePreviews));
      if (index > -1) {
        // only splice array when item is found
        tempImgPrvws.splice(index, 1);
        setImagePreviews(tempImgPrvws);
      }
    }
  };

  return (
    <div>
      <div className="carLeaseProgram p-4">
        <div className="p-4">
          <p className="mainTitle py-4">Checklist of document to be uploaded</p>
          <div className="grid grid-cols-3 gap-1 py-4 radioButtonOuter">
            <button
              onClick={() =>
                setActiveTab(
                  activeTab == "" ? "ld" : activeTab == "ps" ? "ld" : ""
                )
              }
              className={classNames("radioButton", {
                active: activeTab === "ld",
              })}
            >
              <div className="radioOuter">
                <img src={Rectangle} alt="" className="radio" />
                <img src={radioCheckIcon} alt="" className="radioCheck" />
              </div>
              Lease document
            </button>
            <button
              onClick={() =>
                setActiveTab(
                  activeTab == "" ? "ps" : activeTab == "ld" ? "ps" : ""
                )
              }
              className={classNames("radioButton", {
                active: activeTab === "ps",
              })}
            >
              <div className="radioOuter">
                <img src={Rectangle} alt="" className="radio" />
                <img src={radioCheckIcon} alt="" className="radioCheck" />
              </div>
              Payment schedule
            </button>
          </div>
          <div className="grid grid-cols-2 gap-2 py-4">
            <div class="outer">
              <p className="info pb-4">Driving license</p>
              <div className="browseOuter">
                <div className="browseInner">
                  {imagePreviewsDl.length > 0 ? (
                    <>
                      <p
                        style={{
                          color: "var(--primary)",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "18px",
                        }}
                      >
                        Upload successfully
                      </p>
                      <input
                        //   accept="image/*"
                        //   className={classes.input}
                        style={{ display: "none" }}
                        id="dl"
                        name="dl"
                        onChange={(event) =>
                          handleChangeUploadDetails(event, "dl")
                        }
                        // onChange={selectFiles}
                        multiple
                        type="file"
                      />
                    </>
                  ) : (
                    <>
                      <input
                        //   accept="image/*"
                        //   className={classes.input}
                        style={{ display: "none" }}
                        id="dl"
                        name="dl"
                        onChange={(event) =>
                          handleChangeUploadDetails(event, "dl")
                        }
                        // onChange={selectFiles}
                        multiple
                        type="file"
                      />
                      <img
                        src={browse}
                        onClick={(event) => {
                          document.getElementById("dl").click();
                        }}
                        alt="Browse"
                        className="browse"
                      />
                      <p
                        onClick={(event) => {
                          document.getElementById("dl").click();
                        }}
                        className="subTitle"
                      >
                        Browse
                      </p>
                    </>
                  )}
                </div>
              </div>

              {imagePreviewsDl && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "19px",
                  }}
                >
                  {imagePreviewsDl.map((img, i) => {
                    return (
                      <div
                        key={`imgPreviewCont${i}`}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginTop: "1rem",
                          width: "65px",
                          height: "auto",
                          position: "relative",
                        }}
                      >
                        <img
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "-4px",
                            right: "-9px",
                          }}
                          onClick={(event) => {
                            dltImgPreview(i, "dl");
                            removeFileFromFileList(i, "dl");
                          }}
                          src={removeImgBtn}
                          alt="rmvImgBtn"
                        />
                        <img className="preview" src={img} alt={"image-" + i} />
                        {imagePreviewsDl.length > 0 && i === 0 && (
                          <span
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "155%",
                              whiteSpace: "nowrap",
                              transform: "translate(-50%,-50%)",
                              marginLeft: "16px",
                              cursor: "pointer",
                              textDecorationLine: "underline",
                              display: "inline-block",
                              width: "auto",
                              fontSize: "12px",
                              lineHeight: "18px",
                              color: "rgba(0, 0, 0, 0.37)",
                            }}
                            onClick={(event) => {
                              setImagePreviewsDl([]);
                              uploadDetails.dl = null;
                              document.getElementById("dl").click();
                            }}
                          >
                            Upload again
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div class="outer">
              <p className="info pb-4">Car RC</p>
              <div className="browseOuter">
                <div className="browseInner">
                  {imagePreviews.length > 0 ? (
                    <>
                      <p
                        style={{
                          color: "var(--primary)",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "18px",
                        }}
                      >
                        Upload successfully
                      </p>
                      <input
                        //   accept="image/*"
                        //   className={classes.input}
                        style={{ display: "none" }}
                        id="rc"
                        name="rc"
                        onChange={(event) =>
                          handleChangeUploadDetails(event, "rc")
                        }
                        // onChange={selectFiles}
                        multiple
                        type="file"
                      />
                    </>
                  ) : (
                    <>
                      <input
                        //   accept="image/*"
                        //   className={classes.input}
                        style={{ display: "none" }}
                        id="rc"
                        name="rc"
                        onChange={(event) =>
                          handleChangeUploadDetails(event, "rc")
                        }
                        // onChange={selectFiles}
                        multiple
                        type="file"
                      />
                      <img
                        src={browse}
                        onClick={(event) => {
                          document.getElementById("rc").click();
                        }}
                        alt="Browse"
                        className="browse"
                      />
                      <p
                        onClick={(event) => {
                          document.getElementById("rc").click();
                        }}
                        className="subTitle"
                      >
                        Browse
                      </p>
                    </>
                  )}
                </div>
              </div>

              {imagePreviews && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "19px",
                  }}
                >
                  {imagePreviews.map((img, i) => {
                    return (
                      <div
                        key={`imgPreviewCont${i}`}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginTop: "1rem",
                          width: "65px",
                          height: "auto",
                          position: "relative",
                        }}
                      >
                        <img
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "-4px",
                            right: "-9px",
                          }}
                          onClick={(event) => {
                            dltImgPreview(i, "rc");
                            removeFileFromFileList(i, "rc");
                          }}
                          src={removeImgBtn}
                          alt="rmvImgBtn"
                        />
                        <img className="preview" src={img} alt={"image-" + i} />
                        {imagePreviews.length > 0 && i === 0 && (
                          <span
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "155%",
                              whiteSpace: "nowrap",
                              transform: "translate(-50%,-50%)",
                              marginLeft: "16px",
                              cursor: "pointer",
                              textDecorationLine: "underline",
                              display: "inline-block",
                              width: "auto",
                              fontSize: "12px",
                              lineHeight: "18px",
                              color: "rgba(0, 0, 0, 0.37)",
                            }}
                            onClick={(event) => {
                              setImagePreviews([]);
                              uploadDetails.rc = null;
                              document.getElementById("rc").click();
                            }}
                          >
                            Upload again
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <ButtonM
            text="submit"
            onpress={() => {
              if (Object.values(uploadDetails).every((x) => x !== null)) {
                history.push("/carLeaseProgram/upload-car-lease-docs-success");
              } else {
                toast.warning(
                  <div className="flex flex-row">
                    <BsFillCheckCircleFill
                      style={{ width: "2rem", height: "2rem" }}
                    />
                    &nbsp;&nbsp;Finish Uploading documents first
                  </div>
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadDocuments;
