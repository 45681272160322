import { Box } from '@mui/material';
import ButtonM from 'components/common/Button';
import { useHistory } from 'react-router-dom';
import PolicyCoverForPlans from 'components/common/PolicyCoverForPlans';

const VoluntryCover = () => {

    const history = useHistory();

    const handleNext = () => history.push('/voluntry');

    return (
        <Box>
            <PolicyCoverForPlans />
            <div className="bottomButtonReq">
                <ButtonM text="NEXT" onpress={() => handleNext()} />
            </div>
        </Box>
    )
}

export default VoluntryCover;