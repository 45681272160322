import { useEffect, useState } from "react";
import Rectangle from "../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../assets/radioCheck.svg";
import ButtonM from "../../common/Button";
import PageTitleAndDescription from "../../common/PageTitleAndDescription";
// import VoluntaryParentalModal from "../Voluntry/ViewModal/index";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as VolutryActions } from "./redux/actions"
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import APIList from "api";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { CurrencyFormat } from "components/utils/numberFormatter";
import { getCoveragePlan, getInValidRelations, yellowText } from "components/utils/utils";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";
import { actions as getPlanActions } from "../../common/Plans/redux/actions";
// import { selectedPlan } from "./utils";

const classNames = require('classnames');

const Voluntry = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [dependentArr, setDependentArr] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [dontWannaEnroll, setDontWannaEnroll] = useState(false);
  const [enrollLoader, setEnrollLoader] = useState(false);
  const [selectedRelations, setSelectedRelations] = useState([]);
  const [coveragePlan, setCoveragePlan] = useState(null);

  const selectedPlan = useSelector((state) => state?.planReducer?.planData);
  const enrolledPlanData = useSelector((state) => state?.planReducer?.enrolledPlanData);
  const empHasCoveragePlan = selectedPlan?.hasCoveragePlan;
  const rateDiffer = selectedPlan?.rateCard?.rateDiffer;
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const currency = employeeDetails?.company?.currency;  

  const { selectedPlanDependants } = useSelector((state) => state?.voluntryReducer);

  useEffect(() => {
    if(Object.keys(selectedPlan)?.length == 0){
        history.push("/plans");
      }
  }, []);

  const isEdit = () => {
    let edit = false;
    if(enrolledPlanData?.plan?.id == selectedPlan?.id){
       edit = true;
    }
    return edit;
  };

  useEffect(() => {   
    dispatch(VolutryActions.ViewedPremiumRates(false));
    if(rateDiffer == "bundle"){
      if(isEdit()) {
        // history.push({ pathname: "/bundle-final-new-edit", state: { forEdit: true } });
        history.push("/voluntry/bundle-final-new-edit");
      } else {
        history.push("/voluntry/bundle-add-parents-new");
      }      
    }

  }, [rateDiffer]);
  

  useEffect(() => {
    
    dispatch(VolutryActions.SetProgressArray([]));
    dispatch(VolutryActions.SetSelectedCategories([]));
    dispatch(VolutryActions.SetRatecardSelection([]));
    dispatch(VolutryActions.SetCurrentlyEnrolled([]));
    dispatch(VolutryActions.SetCurrentlyInprocessEnrolled([]));
    dispatch(VolutryActions.setSelectedCoverages([]));
    dispatch(VolutryActions.setFamilySelectedCoverages([]));
    dispatch(VolutryActions.setBundleEnrollList([]));
    dispatch(VolutryActions.setSelectedPlanDependants([]));
    dispatch(VolutryActions.setIndividualSelectedDependant([]));
 
  }, []);  

  useEffect(() => {
   if(empHasCoveragePlan){
    if(rateDiffer == "family"){
      let relArr = ["self"];
        selectedPlan?.planDependants?.forEach((item) => {
            relArr = [...relArr, ...item?.relation]
        })
        setDependentArr(relArr);
        dispatch(VolutryActions.SetSelectedCategories(relArr));
        dispatch(VolutryActions.setSelectedPlanDependants(selectedPlan?.planDependants));

    }else{
    setDependentArr(["self"]);
    }   
    setCoveragePlan(getCoveragePlan(selectedPlan, employeeDetails));
 }
  }, [empHasCoveragePlan]);

  useEffect(() => {
    let rel = [];
    dependentArr?.forEach((x) => {
      rel = [...rel , ...x.split("-")];
    })
    setSelectedRelations(rel);
  }, [dependentArr]);

const handleSelectedPlanDependants = (planDependant) => {
  let selected = selectedPlanDependants;

  selected.filter((x) => x.id === planDependant.id).length > 0
       ? dispatch(VolutryActions.setSelectedPlanDependants(selected.filter((x) => x.id !== planDependant.id))) 
       : dispatch(VolutryActions.setSelectedPlanDependants([...selected, planDependant]));

}  

  const handleSelectDependant = (item) => {
    let dependants = dependentArr;
 
    dependants.filter((x) => x === item).length > 0
       ? setDependentArr(dependants.filter((x) => x !== item))
       : setDependentArr([...dependants, item]);
   };

  Array.prototype.equals = function (array, strict) {
    if (!array) return false;

    if (arguments.length == 1) strict = true;

    if (this.length != array.length) return false;

    for (var i = 0; i < this.length; i++) {
      if (this[i] instanceof Array && array[i] instanceof Array) {
        if (!this[i].equals(array[i], strict)) return false;
      } else if (strict && this[i] != array[i]) {
        return false;
      } else if (!strict) {
        return this.sort().equals(array.sort(), true);
      }
    }
    return true;
  };

  const handleNext = () => {    

    if(dontWannaEnroll){
      setEnrollLoader(true);
      APIList.healthInsuranceEnroll({
        employee:{
          id: employeeDetails.id
        }, 
        isDraft: true,
        status: "ISDRAFT",
        isEmployeeEnrolled: false,
        planOptOut:dontWannaEnroll,
        selectedCoverage:
        null,
        // {
        //   id: selectedPlan?.coveragePlans[0]?.id
        // },
        employeeCoverage: 
        null,
        // selectedPlan?.coveragePlans[0]?.amount,
        plan:{
          id: selectedPlan.id,
          className: selectedPlan.className
        },
        enrollmentDate: new Date(),
        dependantEnrollments: null
      }).then((res) => {
        res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;You have opted out of this plan successfully</div>);
        setEnrollLoader(false);
        // history.push({ pathname: "/plans", state: { fromAddress: true } });
        dispatch(getPlanActions.PlanDataRequest("clearData")); 
        history.push({pathname:'/loading', state:{planName:selectedPlan?.displayName}});
      })
      .catch((err) =>{
        toast.error(
          <div className='flex flex-row'>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );      
        setEnrollLoader(false);      
      }
      );

    }else{

    if(dependentArr?.length > 0){

      dispatch(VolutryActions.SetSelectedCategories(dependentArr));

      if(rateDiffer == "family"){
        
       if(dependentArr.equals(["self"])){        
          history.push("/voluntry/FamilyCoverageSelection");
        }else{
        history.push('/voluntry/familyAddParents');
        }
      }else if(rateDiffer == "individual"){
        
      history.push('/voluntry/add-parents');

     }else if(rateDiffer == "bundle"){
        
      history.push('/voluntry/bundle-add-parents');

     }
    }else{
      history.push('/plans');
    }        
}
  };

  return (
    <div className="voluntryPage">
      {selectedPlan && (
        <div>
          <h4 className="page-heading-navbar">{selectedPlan.displayName}</h4>
          <PageHeaderForPlans 
          // btnRedirect={() => setOpenModal(true)} 
          />
        </div>
      )}
      <div className="data">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <PageTitleAndDescription title={"Whom do you wish to enroll?"} />
            <div className="w-full">
              {selectedPlan?.planOptOut && (
                <div className="radioButtonOuter">
                  <button
                    onClick={() => setDontWannaEnroll(!dontWannaEnroll)}
                    className={classNames("radioButton", {
                      active: dontWannaEnroll,
                    })}
                  >
                    <div className="radioOuter">
                      <img src={Rectangle} alt="" className="radio" />
                      <img src={radioCheckIcon} alt="" className="radioCheck" />
                    </div>
                    I don't want to Enroll
                  </button>
                </div>
              )}

              {/* ----- for individual type plan only ----- */}
              {rateDiffer == "individual" && !dontWannaEnroll && (
                <div className="radioButtonOuterFamily">
                  {empHasCoveragePlan && (
                    <button
                      // onClick={() => {
                      //   if(empOptOut){
                      //   handleSelectDependant("self")
                      // }
                      // }}
                      className={classNames("radioButton", {
                        active: dependentArr.includes("self"),
                      })}
                    >
                          
                      <div className="radioOuter">
                        <img src={Rectangle} alt="" className="radio" />
                        <img
                          src={radioCheckIcon}
                          alt=""
                          className="radioCheck"
                        />
                      </div>
                      <div className="bucketRight">
                      <div className="relations">
                      {/* <span style={{color: "var(--secondary)"}}> */}
                        {yellowText(employeeDetails?.firstName)}{" "}{yellowText(employeeDetails?.lastName)}
                        {/* </span> */}
                      </div>
                      <div className="coverages">
                        Coverages ranging from 
                        {/* <span style={{color: "var(--secondary)"}}> */}
                          {" "}{yellowText(CurrencyFormat(coveragePlan?.minCoverage, currency))}{" "}
                        to {yellowText(CurrencyFormat(coveragePlan?.maxCoverage, currency))}.
                        {/* </span> */}
                      </div>

                     
                      </div>
                      
                    </button>
                  )}
                  {/* 
                  {dependentRelations?.map((item, idx) => (
                    <button
                      key={idx}
                      onClick={() => handleSelectDependant(item)}
                      className={classNames("radioButton", {
                        active: dependentArr.includes(item),
                      })}
                    >
                      <div className="radioOuter">
                        <img src={Rectangle} alt="" className="radio" />
                        <img
                          src={radioCheckIcon}
                          alt=""
                          className="radioCheck"
                        />
                      </div>
                      {item == "PIL" ? "Parents in law" : item}
                    </button>
                  ))} */}

{selectedPlan?.planDependants?.map((item, idx) => (
                    <button
                      key={idx}
                      onClick={() =>{
                        handleSelectedPlanDependants(item);
                        handleSelectDependant(item?.relation?.join("-"));
                      }}
                      className={classNames("radioButton", {
                        active: dependentArr.includes(
                          item?.relation?.join("-")
                        ),
                      })}
                    >
                      <div className="radioOuter">
                        <img src={Rectangle} alt="" className="radio" />
                        <img
                          src={radioCheckIcon}
                          alt=""
                          className="radioCheck"
                        />
                      </div>
                      <div className="bucketRight">
                      <div className="coverages">
                        Coverages ranging from 
                        {/* <span style={{color: "var(--secondary)"}}>  */}
                        &nbsp;{yellowText(CurrencyFormat(item.minCoverage, currency))}{" "}
                        to {yellowText(CurrencyFormat(item.maxCoverage, currency))}{" "}
                        for {yellowText(item?.dependantLimit)} {item?.dependantLimit > 1 ? `Lives` : `Life`}  
                        {/* </span> */}
                      </div>

                      <div className="relations">
                        {/* {item?.relation?.join("-") == "PIL" ? "Parents in law" : item?.relation?.join("-")} */}
                       Relationships applicable under this option{" "}
                        {item?.relation?.map((rel, index) => {
                          return (
                          <span >
                            {yellowText(rel)}{ yellowText((item?.relation?.length == (index + 1)) ? "." : ", ") }
                            </span>)

                        })}
                      </div>
                      </div>
                    </button>
                  ))}
                  {
                  (getInValidRelations(selectedRelations).length > 0) &&
                    <p className="w-full">
                      <p className="w-full text-center mx-auto my-4" style={{color:"var(--warning)"}}>
                        {/* <BiError style={{ fontSize: "1.2rem" }} /> */}
                        You can select only one Category with relation
                        <span className="font-semibold">
                        &nbsp;{getInValidRelations(selectedRelations).join(", ")}
                        </span>
                      </p>
                    </p>
                  }
                </div>
              )}

              {/* ----- for family type plan only ----- */}
              {rateDiffer == "family" && !dontWannaEnroll && (
                <div className="radioButtonOuterFamily">
                  {empHasCoveragePlan && (
                    <button
                      // onClick={() => {
                      //   if(empOptOut){
                      //   handleSelectDependant("self")
                      // }
                      // }}
                      className={classNames("radioButton", {
                        active: dependentArr.includes("self"),
                      })}
                    >
                      <div className="radioOuter">
                        <img src={Rectangle} alt="" className="radio" />
                        <img
                          src={radioCheckIcon}
                          alt=""
                          className="radioCheck"
                        />
                      </div>
                      <div className="bucketRight">
                      <div className="relations">
                      {employeeDetails?.firstName}{" "}{employeeDetails?.lastName}
                      </div>
                      <div className="coverages">
                        Coverages ranging from 
                        {" "}
                        {yellowText(CurrencyFormat(coveragePlan?.minCoverage, currency))}{" "}
                        to {yellowText(CurrencyFormat(coveragePlan?.maxCoverage, currency))}.
                      </div>

                     
                      </div>
                    </button>
                  )}

                  {/* {selectedPlan?.planDependants?.map((item, idx) => (
                    <button
                      key={idx}
                      onClick={() =>{
                        handleSelectedPlanDependants(item);
                        handleSelectDependant(item?.relation?.join("-"))
                      }}
                      className={classNames("radioButton", {
                        active: dependentArr.includes(
                          item?.relation?.join("-")
                        ),
                      })}
                    >
                      <div className="radioOuter">
                        <img src={Rectangle} alt="" className="radio" />
                        <img
                          src={radioCheckIcon}
                          alt=""
                          className="radioCheck"
                        />
                      </div>
                      <div className="bucketRight">
                      <div className="coverages">
                        Coverages ranging from 
                        &nbsp;{yellowText(CurrencyFormat(item.minCoverage, currency))}{" "}
                        to {yellowText(CurrencyFormat(item.maxCoverage, currency))}{" "}
                        for {yellowText(item?.dependantLimit)} {item?.dependantLimit > 1 ? `Lives` : `Life`}   
                      </div>

                      <div className="relations">
                       Relationships applicable under this option{" "}
                       {item?.relation?.map((rel, index) => {
                          return (
                          <span >
                            {yellowText(rel)}{ yellowText((item?.relation?.length == (index + 1)) ? "." : ", ") }
                            </span>)

                        })}
                      </div>
                      </div>
                    </button>
                  ))} */}
                  {
                  (getInValidRelations(selectedRelations).length > 0) &&
                    <p className="w-full">
                      <p className="w-full text-center mx-auto my-4" style={{color:"var(--warning)"}}>
                        {/* <BiError style={{ fontSize: "1.2rem" }} /> */}
                        {/* You can select only one Category with relation */}
                        Sorry, You can not Enroll in this plan due to 
                        <span className="font-semibold">
                        {" "}Incorrect Plan configuration.
                        {/* &nbsp;{getInValidRelations(selectedRelations).join(", ")} */}
                        </span>
                      </p>
                    </p>
                  }
                </div>
              )}

              {/* ----- for bundle type plan only ----- */}
              {rateDiffer == "bundle" && !dontWannaEnroll && (
                <div className="radioButtonOuter">
                  {empHasCoveragePlan && (
                    <button
                      // onClick={() => {
                      //   if(empOptOut){
                      //   handleSelectDependant("self")
                      // }
                      // }}
                      className={classNames("radioButton", {
                        active: dependentArr.includes("self"),
                      })}
                    >
                      <div className="radioOuter">
                        <img src={Rectangle} alt="" className="radio" />
                        <img
                          src={radioCheckIcon}
                          alt=""
                          className="radioCheck"
                        />
                      </div>
                      Self
                    </button>
                  )}

                  {["Parent", "PIL"]?.map((item, idx) => (
                    <button
                      key={idx}
                      onClick={() => handleSelectDependant(item)}
                      className={classNames("radioButton", {
                        active: dependentArr.includes(item),
                      })}
                    >
                      <div className="radioOuter">
                        <img src={Rectangle} alt="" className="radio" />
                        <img
                          src={radioCheckIcon}
                          alt=""
                          className="radioCheck"
                        />
                      </div>
                      {item == "PIL" ? "Parents in law" : item}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div className="bottomButtonReq">
              <ButtonM
                text="NEXT"
                onpress={() => handleNext()}
                loading={enrollLoader}
                disabled={enrollLoader || (getInValidRelations(selectedRelations).length > 0)}
                bgcolor={(getInValidRelations(selectedRelations).length > 0) ? "gray" : ""}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {openModal && <VoluntaryParentalModal setOpenModal={setOpenModal} />} */}
    </div>
  );
};

export default Voluntry;
