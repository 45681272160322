import React from 'react';

const BannerImage = props => {
  return (
    <div className="bannerImage">
      <img className="banner-image-hight" src={props.path} alt={props.fname} />
    </div>
  );
};

export default BannerImage;