import loginBg from "../../../assets/loginBg.svg";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ButtonM from "../../common/Button";
import APIList from "../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { isEmptyNullUndefined } from "components/utils/utils";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {

  const history = useHistory();

  const [email, setEmail] = useState("");
  const emptyEmailError = {
    isError: false,
    errorMessage: "",
  };
  const [emailError, setEmailError] = useState(emptyEmailError);

  const loading = useSelector(
    (state) => state && state.user && state.user.loader
  );

 
  

  const handleValidateEmailAddress = async () => {
    let isValid = true;
    const tempEmail = JSON.stringify(email);
    const tempError = JSON.parse(JSON.stringify(emailError));

    if (isEmptyNullUndefined(email)) {
      tempError.isError = true;
      tempError.errorMessage = "*Please enter your company E-Mail ID";
      isValid = false;
    }

    if (!isEmptyNullUndefined(email)) {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        tempError.isError = true;
        tempError.errorMessage = "Please enter a valid E-Mail ID";
        isValid = false;
      }
    }

    //do Submit
    if (isValid) {
      submitButtonClick();
    } else {
      setEmailError(tempError);
    }
  };

  const submitButtonClick = () => {
    APIList.forgotPassword(email)
      .then((res) => {
        toast.success(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {"Reset password instuction sent to your email address."}
          </div>
        );
        setEmail("");
        setEmailError(emptyEmailError);
      })
      .catch((err) =>
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        )
      );
  };

  return (
    <div className="w-full absolute top-0">
      <div className="headerLogin">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full">
              <div className="headerLoginInner flex flex-col">
                <img src={loginBg} alt="logBg" className="logoImg" />
                <div className="left pt-4 pb-4 flex flex-col items-start">
                  <p className="text">
                    Reset Your Password? <br />
                    {/* <span className="textInner">Benevolve</span> */}
                  </p>
                  <p className="desc">Please provide your company E-Mail ID.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="forgotPasswordBlock">
        <div className="container my-4 mx-auto px-5">
          <div className="flex flex-wrap pt-2 pb-2">
            <div className="my-1 w-full md:w-1/2 lg:my-4 lg:px-2 lg:w-1/2">
              <input
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                className={
                  emailError.isError
                    ? "input border border-red-500 focus:outline-none"
                    : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                }
                type="text"
                placeholder="Email"
              />
              {emailError.isError && (
                <p className="mt-2 text-sm" style={{color:"var(--warning)"}}>
                  {emailError.errorMessage}
                </p>
              )}
            </div>
          </div>

          <div className="flex flex-wrap pt-8 pb-2">
            <div className="w-full">
              <ButtonM
                text="Send Email"
                onpress={() => handleValidateEmailAddress()}
                bgcolor="var(--primary)"
                color="#ffffff"
                bghover="#287546"
              />
              <ButtonM
                text="Go to Login page"
                onpress={() => history.push("/login")}
                bgcolor="var(--primary)"
                color="#ffffff"
                bghover="#287546"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
