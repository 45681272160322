import doctor from "../../../../assets/coinFlow/doctor.png";
import arrowRight from "../../../../assets/coinFlow/arrowRight.png";
import arrowWhiteRight from "../../../../assets/coinFlow/arrowWhiteRight.png";
import IconYellowActive from "../../../../assets/coinFlow/IconYellowActive.png";
import ButtonM from "components/common/Button";
import { useHistory } from "react-router-dom";
import { useState } from "react";
var classNames = require("classnames");


const PlanEnroll = () => {

    const history = useHistory();

    const [selectTab, setselectTab] = useState("");

return(
    <div className="coinWizard2">
        <div class="BMheader w-full px-2">
          <div class="flex flex-wrap">
            <div class="w-full">
              <div class="pageheaderdata">
                <div class="pageheaderDataInneer w-4/5">
                  <p class="desc">[Client Name] provides you an option to purchase coverage for [your parents/your parents and your parents-in-law]. </p>
                  <div className="moreDetails">
                    <ButtonM text="Click for more details"></ButtonM>
                  </div>
                </div>
                <img src={doctor} alt="Medical Insurance" class="medicalImage h-1/2 w-20" />
              </div>
            </div>
          </div>
        </div>



        <div className="boxOuter m-4">
          <h1 className="heading mt-4">Choose the coverage type for [FNAME] and [FNAME]</h1>

          <div className="AddBeneficiaryMainPage">
        
            <div className="flex justify-between mb-4 btnOuter">
              <button className="btn active">Individual</button>
              <button className="btn">Group</button>
            </div>
            <div className="tabDetails mx-4">
                
            </div>
          </div>


          <div className="box">
            <p className="title">Individual: Each [parent/parent-in-law] has their own individual coverage limits.    </p>
            <p className="subTitle">This plan covers your father</p>
          </div>
          <div className="flex justify-between">
            <div className="head first">
              <p className="label">Coverage amount</p>
            </div>
            <div className="head">
              <p className="label">Employee Cost</p>
            </div>
          </div>

          <div className={classNames("box1 flex items-center", {active: (selectTab == "1"),})}>
            <div className="data1 flex items-center">
                {
                    (selectTab == "1") && 
                    <img src={IconYellowActive} alt="Arrow Right" title="Arrow Right" className="iconActive" />
                }              
              <p className="coverageAmount">INR 100000</p>
              <div className="imgOuter">
                <img src={arrowRight} alt="Arrow Right" title="Arrow Right" className="arrowImg" />
                <img src={arrowWhiteRight} alt="Arrow Right" title="Arrow Right" className="arrowImg active" />
              </div>
            </div>
            <div className="data2 flex justify-between items-center">
              <p className="EmployeeCost">Coins 200</p>
              <div className="selectBtn">
                <ButtonM text="Select" onpress={() => setselectTab("1")}></ButtonM>
              </div>
            </div>
          </div>
          <div className={classNames("box1 flex items-center", {active: (selectTab == "2"),})}>
            <div className="data1 flex items-center">
            {
                    (selectTab == "2") && 
                    <img src={IconYellowActive} alt="Arrow Right" title="Arrow Right" className="iconActive" />
                }
              <p className="coverageAmount">INR 200000</p>
              <div className="imgOuter">
                <img src={arrowRight} alt="Arrow Right" title="Arrow Right" className="arrowImg" />
              </div>
            </div>
            <div className="data2 flex justify-between items-center">
              <p className="EmployeeCost">Coins 300</p>
              <div className="selectBtn">
                <ButtonM text="Select" onpress={() => setselectTab("2")}></ButtonM>
              </div>
            </div>
          </div>
          <div className={classNames("box1 flex items-center", {active: (selectTab == "3"),})}>
            <div className="data1 flex items-center">
            {
                    (selectTab == "3") && 
                    <img src={IconYellowActive} alt="Arrow Right" title="Arrow Right" className="iconActive" />
                }
              <p className="coverageAmount">INR 300000</p>
              <div className="imgOuter">
                <img src={arrowRight} alt="Arrow Right" title="Arrow Right" className="arrowImg" />
              </div>
            </div>
            <div className="data2 flex justify-between items-center">
              <p className="EmployeeCost">Coins 400</p>
              <div className="selectBtn">
                <ButtonM text="Select" onpress={() => setselectTab("3")}></ButtonM>
              </div>
            </div>
          </div>

        </div>
        <div className="submit mt-6">
          <ButtonM text="SUBMIT" onpress={() =>  history.push({ pathname: "/coinWizards", state: { fromEnroll: true } })} disabled={(selectTab == "")} bgcolor={(selectTab == "") ? "gray" : ""} />
        </div>
      </div>
)
}

export default PlanEnroll;