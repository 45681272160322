import APIList from "api";
import ButtonM from "components/common/Button";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";
import PageTitleAndDescription from "components/common/PageTitleAndDescription";
// import VoluntaryParentalModal from "components/pages/Voluntry/ViewModal";
import { CurrencyFormat } from "components/utils/numberFormatter";
import { useEffect, useRef, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { actions as getPlanActions } from "../../../../../common/Plans/redux/actions";
import { actions as VolutryActions } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import QueDialogPopup from "../QuePopup";
import GetCostVersion2 from "components/pages/BasicMedical/ViewCost/Version2";
import { getPayCheckFrequency } from "components/utils/utils";
import { FormattedMessage } from "react-intl";

const NiumIndBundleFinal = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const selectedPlan = useSelector((state) => state?.planReducer?.planData);    
    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const currency = employeeDetails?.company?.currency;
    const { bundleEnrolls } = useSelector((state) => state?.voluntryReducer);
    const benefitStartDate = useSelector((state) => state?.planReducer?.benefitStartDate);

    const [openModal, setOpenModal] = useState(false);
    const [enrollLoader, setEnrollLoader] = useState(false);
    const [electionChecked, setElectionChecked] = useState(false);
    const [showQuePopup, setShowQuePopup] = useState(false);
    const [queDescriptionParent, setQueDescriptionParent] = useState(null);
    const [queDescriptionPIL, setQueDescriptionPIL] = useState(null);
    const [queRelationParent, setQueRelationParent] = useState([]);
    const [queRelationPIL, setQueRelationPIL] = useState([]);
    const [getCostLoader, setGetCostLoader] = useState(false);
    const [viewCost, setViewCost] = useState(false);
    const [costData, setCostData] = useState([]);
    
    function HandleOutsideClick(ref) {
      useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setViewCost(false);
          }
        }
  
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);
    }
  
    const wrapperRef = useRef(null);
    HandleOutsideClick(wrapperRef);
  

    useEffect(() => {
      if(Object.keys(selectedPlan)?.length == 0){
          dispatch(VolutryActions.SetCurrentlyEnrolled([]));
          dispatch(VolutryActions.setBundleEnrollList([]));
          history.push("/plans");
        }
    }, []);

    useEffect(() => {
      let depCat = [];
      let excludedParents = [];
      let excludedPIL = [];

      bundleEnrolls?.forEach((x) => {
        depCat.push(x.relation);
      });

     if(electionChecked){   
      if(depCat?.includes("PIL") && depCat?.filter((x) => x === "PIL")?.length < 2){
        excludedPIL.push("Parent-in-law");
      }
    if(depCat?.includes("Parent") && depCat?.filter((x) => x === "Parent")?.length < 2){
        excludedParents.push("Parent");
      }

     }
     if(excludedParents?.length > 0){
      setShowQuePopup(true);
      setQueRelationParent(excludedParents);
     }
     if(excludedPIL?.length > 0){
      setShowQuePopup(true);
      setQueRelationPIL(excludedPIL);
     }
    }, [electionChecked])

    console.log("bundleEnrolls",bundleEnrolls)
    const bothCategory = () => {
        let both = false;
        let rel = [];
        bundleEnrolls?.forEach((dep) => {
            rel.push(dep?.relation)
        })
        if((rel.includes("Father") || rel.includes("Mother")) && (rel.includes("FIL") || rel.includes("MIL"))){
            both = true;
        }
        return both;
    };

    const handleEnrollment = () => {

        setEnrollLoader(true);
    
        APIList.healthInsuranceEnroll({
          employee:{
            id: employeeDetails.id
          },
          benefitStartDate:benefitStartDate,
          isDraft: true,
          status: "ISDRAFT",
          isEmployeeEnrolled: false,          
          planOptOut: false,
          selectedCoverage:
          // null,
          {
            id: bundleEnrolls[0]?.coverageId
          },
          employeeCoverage: 
          null,
        //   selectedPlan?.coveragePlans[0]?.amount,
          plan:{
            id: selectedPlan.id,
            className: selectedPlan.className
          },
          parentExcludeReason: queDescriptionParent,
          pilExcludeReason: queDescriptionPIL,
          enrollmentDate: new Date(),
          dependantEnrollments: bundleEnrolls.map((x) => {return({
                                    life: bundleEnrolls?.length > 3 ? 2 : bundleEnrolls?.length,
                                    benefitStartDate:benefitStartDate,
                                    dependants:{
                                      id: x.dependantId
                                    },
                                    dependantEnrollmentDate: new Date(),
                                    selectedCoverage:{
                                      id: x.planDependantId
                                    },
                                    dependentCoverage: x.amount
                                  })})
        }).then((res) => {
          res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Submitted {selectedPlan?.displayName} successfully</div>);
          setEnrollLoader(false);
          // history.push({ pathname: "/plans", state: { fromAddress: true } });
          dispatch(getPlanActions.PlanDataRequest("clearData")); 
          dispatch(VolutryActions.setBundleEnrollList([]));
          dispatch(VolutryActions.SetCurrentlyEnrolled([]));
          history.push({pathname:'/loading', state:{planName:selectedPlan?.displayName}});
        })
        .catch((err) =>{
          toast.error(
            <div className='flex flex-row'>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
            </div>
          );      
          setEnrollLoader(false);      
        }
        );      
    
      };

    const handleEdit = () => {
        history.push({
            pathname: "/voluntry/bundle-add-parents-new",
            state: { fromFinalPage:true },
          });
    };

    const handleViewCost = () => {

      setViewCost(true);
      // const employee = allCoverageCards.filter(x => x.dependantId == employeeDetails.id);
      const dependants = bundleEnrolls;
      // allCoverageCards.filter(x => x.dependantId != employeeDetails.id);
  
      setGetCostLoader(true);
  
      APIList.getCost({
        employee:{
          id: employeeDetails.id
        },
        isEmployeeEnrolled: false,
        selectedCoverage:
        // null,
        {
          id: selectedPlan?.coveragePlans[0]?.id
        },
        employeeCoverage: 
        // null,
        selectedPlan?.coveragePlans[0]?.amount,
        plan:{
          id: selectedPlan.id,
          className: selectedPlan.className
        },
        enrollmentDate: new Date(),
        benefitStartDate: benefitStartDate,
        dependantEnrollments: dependants.map((x) => {return({
                                  life: dependants?.length > 3 ? 2 : dependants?.length,
                                  dependants:{
                                    id: x.dependantId
                                  },
                                  dependantEnrollmentDate: new Date(),
                                  selectedCoverage:{
                                    id: x.planDependantId
                                  },
                                  dependentCoverage: x.amount
                                })})
      }).then((res) => {
        // res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;{}</div>);
        setCostData(res.data);
        setGetCostLoader(false);
      })
      .catch((err) =>{
        toast.error(
          <div className='flex flex-row'>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );      
        setGetCostLoader(false);      
      }
      );
  
    };
    
    return(
        <div className="bundleFinalEnroll-new">

            <div>
                <h4 className="page-heading-navbar">{selectedPlan.displayName}</h4>
                <PageHeaderForPlans 
                // btnRedirect={() => setOpenModal(true)} 
                />
            </div>

            <PageTitleAndDescription
              title={"Summary Election"}
              description={`You have selected the following coverage:`}
            />

            <div className="main">
                {
                     !bothCategory() && (bundleEnrolls?.length > 0) &&
                     <div className="single-card">
                
                          <div className="dep-info">
                          {
                   bundleEnrolls?.map((dep) => {
                        return(
                            <div className="row1">
                                <div className="dep-rel">{dep?.relation}</div>
                                <div className="dep-name">{dep?.name}</div>
                            </div>
                            )
                        })
                        
                    }
                    </div>
                            <div className="row2">
                                <div className="coverage">Coverage Amount &nbsp;&nbsp; <span>{CurrencyFormat(bundleEnrolls[0]?.amount, currency)}</span></div>
                                <div className="btn" onClick={() => handleEdit()}>edit</div>
                            </div>
                          
                        
              </div>
                }
                {
                     bothCategory() && (bundleEnrolls?.length < 4) && 
                     <div className="single-card">
                
                     <div className="dep-info">
                     {
              bundleEnrolls?.map((dep) => {
                   return(
                       <div className="row1">
                           <div className="dep-rel">{dep?.relation}</div>
                           <div className="dep-name">{dep?.name}</div>
                       </div>
                       )
                   })
                   
               }
               </div>
                       <div className="row2">
                           <div className="coverage">Coverage Amount &nbsp;&nbsp; <span>{CurrencyFormat(bundleEnrolls[0]?.amount, currency)}</span></div>
                           <div className="btn" onClick={() => handleEdit()}>edit</div>
                       </div>
                     
                   
         </div>
                }
                {
                     bothCategory() && (bundleEnrolls?.length === 4) && 
                     <div className="both-parent-cat">
                        <div className="single-card">                    
                            <div className="dep-info">
                              {
                                bundleEnrolls?.map((dep) => {                                    
                                return(
                                    ((dep?.relation === "Mother") || (dep?.relation === "Father")) &&
                                    <div className="row1">
                                        <div className="dep-rel">{dep?.relation}</div>
                                        <div className="dep-name">{dep?.name}</div>
                                    </div>
                                    )
                                })
                            
                              }
                            </div>
                                <div className="row2">
                                    <div className="coverage">Coverage Amount &nbsp;&nbsp; <span>{CurrencyFormat(bundleEnrolls[0]?.amount, currency)}</span></div>
                                    <div className="btn" onClick={() => handleEdit()}>edit</div>
                                </div>                           
                        </div>
                        <div className="single-card">                    
                            <div className="dep-info">
                              {
                                bundleEnrolls?.map((dep) => {                                    
                                return(
                                    ((dep?.relation === "FIL") || (dep?.relation === "MIL")) &&
                                    <div className="row1">
                                        <div className="dep-rel">{dep?.relation}</div>
                                        <div className="dep-name">{dep?.name}</div>
                                    </div>
                                    )
                                })
                            
                              }
                            </div>
                                <div className="row2">
                                    <div className="coverage">Coverage Amount &nbsp;&nbsp; <span>{CurrencyFormat(bundleEnrolls[0]?.amount, currency)}</span></div>
                                    <div className="btn" onClick={() => handleEdit()}>edit</div>
                                </div>                           
                        </div>
                    </div>
                }
              

            </div>

            {
            selectedPlan?.showCost && 
                <p className="flex justify-end mr-4 my-4 linksOuter">
                  <span className="cursor-pointer viewLink" onClick={() => handleViewCost()}>
                  View Cost
                  </span>
                </p>
            }

            <div className="mx-4">
            <p className="mt-2 peer-invalid:visible text-gray-400 text-sm" style={{ marginBottom: "-1rem" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="success"
                      checked={electionChecked}
                      onChange={() => setElectionChecked(!electionChecked)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Click here to confirm"
                />
              </FormGroup>
            </p>
              <ButtonM 
              text={
                <FormattedMessage id="common.button.PLANSUBMIT" defaultMessage="Save as Draft"/>
              } 
              loading={enrollLoader} 
              disabled={enrollLoader || !electionChecked} 
              bgcolor={
                (enrollLoader || !electionChecked)
                ? "gray"
                  : ""
              }
              onpress={() => handleEnrollment()} 
              />
            </div>

            {/* {openModal && <VoluntaryParentalModal setOpenModal={setOpenModal} />} */}
            {
            showQuePopup && 
            <QueDialogPopup 
            openDialog={showQuePopup} 
            setOpenDialog={setShowQuePopup} 
            queDescriptionParent={queDescriptionParent}
            setQueDescriptionParent={setQueDescriptionParent}
            queDescriptionPIL={queDescriptionPIL}
            setQueDescriptionPIL={setQueDescriptionPIL}
            relationParent={queRelationParent}
            relationPIL={queRelationPIL}
            setElectionChecked={setElectionChecked}
            />
            }
      {viewCost && (
        <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-full h-full popupWrapper">
            <div className="totalCostPopup" ref={wrapperRef}>
            <GetCostVersion2 
              onBack={() => setViewCost(false)}
              loader={
                getCostLoader
                // true
              }
              costData = {costData}
              
              payCheckFrequency={getPayCheckFrequency(selectedPlan, employeeDetails)}
              />
            </div>
          </div>
        </div>
      )}

        </div>
    )
}

export default NiumIndBundleFinal ;