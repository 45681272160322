export function CurrencyFormat(amount,currency) {
  if(amount == null){
    return "NA";
  }else{
    if (currency === "INR") {
      return `${currency} ${parseFloat(amount).toLocaleString("en-IN")}`;
    } else {
      return `${currency} ${parseFloat(amount).toLocaleString("en-US")}`;
    }
  }
  };