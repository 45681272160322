import { useEffect } from "react";
import * as React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import APIList from "api";
import { toast } from "react-toastify";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { isEmptyNullUndefined, requiredStyled } from "components/utils/utils";
import ButtonM from "components/common/Button";
import { actions as DependantActions } from "../../../pages/BasicMedical/AddDependentAddress/redux/actions";
import { FormattedMessage } from "react-intl";

const AddBeneficiaryAddress = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const empDetails = useSelector((state) => state && state.empData);
  const dependantDetails = location && location.state && location.state.dependant;
  // const user = useSelector((state) => state && state.user && state.user.user);
  const adddependant = useSelector((state) => state && state.adddependant);
  const tempDependantAddress = useSelector((state) => state?.dependents?.tempDependantAddress);

  const [checked, setChecked] = React.useState(false);
  const [addLoading, setAddLoading] = React.useState(false);

  useEffect(
    () => {
      location && (!location.state || (location.state && !location.state.dependant)) && history.push('/add-beneficiary');
    },
    [
      // dispatch
    ]
  );

  const initialEmptyDependentAddress = {
    streetLineOne: null,
    streetLineTwo: null,
    city: null,
    state: null,
    postalCode: null,
  };

  const initialFormErrorList = {
    streetLineOne: { isError: false, errorMessage: "" },
    streetLineTwo: { isError: false, errorMessage: "" },
    city: { isError: false, errorMessage: "" },
    state: { isError: false, errorMessage: "" },
    postalCode: { isError: false, errorMessage: "" },
  };

  const [dependentAddress, setDependentAddress] = React.useState({});
  const [formError, setFormError] = React.useState(initialFormErrorList);

  useEffect(() => {
    if(location?.state?.isDepEdit){
      if(!isEmptyNullUndefined(tempDependantAddress)){
        setDependentAddress(tempDependantAddress);
      } else {
        setDependentAddress(location?.state?.dependant?.address);
      }         
    } else {
      if(!isEmptyNullUndefined(tempDependantAddress)){
        setDependentAddress(tempDependantAddress);
      } else {
        setDependentAddress(initialEmptyDependentAddress);
      }      
    }
  }, [])

  const handleResetForm = () => {
    const tempInitDependent = JSON.parse(JSON.stringify(initialEmptyDependentAddress));
    
    const tempInitError = JSON.parse(JSON.stringify(initialFormErrorList));
    setDependentAddress(tempInitDependent);
    setFormError(tempInitError);
  };

  const handleChangeDependentAddress = async (event, fieldType) => {
    const tempDependentAddress = JSON.parse(JSON.stringify(dependentAddress));
    const tempError = JSON.parse(JSON.stringify(formError));
    if (fieldType === "textfield" || fieldType === "dropdown") {
      tempDependentAddress[event.target.name] = event.target.value;
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = "";
    } else if (fieldType == 'onlyNumber') {
      tempDependentAddress[event.target.name] = event.target.value.replace(
        /[^0-9]/g,
        '',
      );
      tempError[event.target.name].isError = false;
      tempError[event.target.name].errorMessage = '';
    }
    setDependentAddress(tempDependentAddress);
    setFormError(tempError);
    dispatch(DependantActions.SaveDependantAddress(tempDependentAddress));
  };

  const handleValidateDependent = async () => {
    let isValid = true;
    // const tempError = JSON.parse(JSON.stringify(formError));
    // const tempDependentAddress = JSON.parse(JSON.stringify(dependentAddress));

    // if (
    //   tempDependentAddress.streetLineOne === null ||
    //   tempDependentAddress.streetLineOne.trim() === ""
    // ) {
    //   tempError.streetLineOne.isError = true;
    //   tempError.streetLineOne.errorMessage = "Please enter stree address line 1";
    //   isValid = false;
    // }
    // if (
    //   tempDependentAddress.streetLineTwo === null ||
    //   tempDependentAddress.streetLineTwo.trim() === ""
    // ) {
    //   tempError.streetLineTwo.isError = true;
    //   tempError.streetLineTwo.errorMessage = "Please enter stree address line 2";
    //   isValid = false;
    // }

    // if (tempDependentAddress.city === null || tempDependentAddress.city.trim() === "") {
    //   tempError.city.isError = true;
    //   tempError.city.errorMessage = "Please enter City";
    //   isValid = false;
    // }
    // if (
    //   tempDependentAddress.state === null ||
    //   tempDependentAddress.state.trim() === ""
    // ) {
    //   tempError.state.isError = true;
    //   tempError.state.errorMessage = "Please enter State";
    //   isValid = false;
    // }
    // if (
    //   tempDependentAddress.postalCode === null ||
    //   tempDependentAddress.postalCode.toString().trim() === ""
    // ) {
    //   tempError.postalCode.isError = true;
    //   tempError.postalCode.errorMessage = "Please enter Postal Code";
    //   isValid = false;
    // }

    //do Submit
    if (isValid || checked) {
      // if (isEdit) {
      //   updateDependent();
      // } else {
      submitDependent();
      // }
    } 
    // else {
    //   setFormError(tempError);
    // }
  };

  const submitDependent = () => {

    setAddLoading(true);
    if(location?.state?.isDepEdit){
      APIList.updateBeneficiary({
        id: dependantDetails?.id,
        firstName: dependantDetails && dependantDetails.firstName,
        middleName: (dependantDetails && dependantDetails.middleName) || "",
        lastName: dependantDetails && dependantDetails.lastName,
        relation: dependantDetails && dependantDetails.relation,
        dateOfBirth: dependantDetails && dependantDetails.dateOfBirth,
        nationalId: dependantDetails && dependantDetails.nationalId,
        idType: dependantDetails && dependantDetails.idType,
        gender: dependantDetails && dependantDetails.gender,
        nationality: dependantDetails && dependantDetails.nationality,
        isRelativeWorking: dependantDetails && dependantDetails.isRelativeWorking,
        isRelativeWorkingInNium: dependantDetails && dependantDetails.isRelativeWorkingInNium,
        employeeIdOfRelative: dependantDetails && dependantDetails.employeeIdOfRelative,
        countryCode: dependantDetails && dependantDetails.countryCode,
        phoneNumber: dependantDetails && dependantDetails.phoneNumber,
        guardianName: dependantDetails && dependantDetails.guardianName,
        guardianRelation: dependantDetails && dependantDetails.guardianRelation,
        guardianContact: dependantDetails && dependantDetails.guardianContact,
        employee: {
          id: empDetails && empDetails.empData && empDetails.empData.id,
        },
        address: !checked
          ? {
              streetLineOne:
                dependentAddress && dependentAddress.streetLineOne,
              streetLineTwo:
                dependentAddress && dependentAddress.streetLineTwo,
              city: dependentAddress && dependentAddress.city,
              state: dependentAddress && dependentAddress.state,
              postalCode: dependentAddress && dependentAddress.postalCode,
            }
          : empDetails && empDetails.empData && empDetails.empData.address,
      })
      .then((res) => {
        res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Updated {dependantDetails && dependantDetails.relation} successfully</div>);
        setAddLoading(false);
        dispatch(DependantActions.SaveDependantInfo(null));
        dispatch(DependantActions.SaveDependantAddress(null));
        history.push({ pathname: location?.state?.addBenPath, state: { fromBenAddress: true } });
      })
      .catch((err) =>{
        toast.error(
          <div className='flex flex-row'>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );      
        setAddLoading(false);
        
      }
      );
    } else {
      APIList.addNewBeneficiary({
        firstName: dependantDetails && dependantDetails.firstName,
        middleName: (dependantDetails && dependantDetails.middleName) || "",
        lastName: dependantDetails && dependantDetails.lastName,
        relation: dependantDetails && dependantDetails.relation,
        dateOfBirth: dependantDetails && dependantDetails.dateOfBirth,
        nationalId: dependantDetails && dependantDetails.nationalId,
        idType: dependantDetails && dependantDetails.idType,
        gender: dependantDetails && dependantDetails.gender,
        nationality: dependantDetails && dependantDetails.nationality,
        isRelativeWorking: dependantDetails && dependantDetails.isRelativeWorking,
        isRelativeWorkingInNium: dependantDetails && dependantDetails.isRelativeWorkingInNium,
        employeeIdOfRelative: dependantDetails && dependantDetails.employeeIdOfRelative,
        countryCode: dependantDetails && dependantDetails.countryCode,
        phoneNumber: dependantDetails && dependantDetails.phoneNumber,
        guardianName: dependantDetails && dependantDetails.guardianName,
        guardianRelation: dependantDetails && dependantDetails.guardianRelation,
        guardianContact: dependantDetails && dependantDetails.guardianContact,
        employee: {
          id: empDetails && empDetails.empData && empDetails.empData.id,
        },
        address: !checked
          ? {
              streetLineOne:
                dependentAddress && dependentAddress.streetLineOne,
              streetLineTwo:
                dependentAddress && dependentAddress.streetLineTwo,
              city: dependentAddress && dependentAddress.city,
              state: dependentAddress && dependentAddress.state,
              postalCode: dependentAddress && dependentAddress.postalCode,
            }
          : empDetails && empDetails.empData && empDetails.empData.address,
      })
      .then((res) => {
        res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Added {dependantDetails && dependantDetails.relation} successfully</div>);
        setAddLoading(false);
        dispatch(DependantActions.SaveDependantInfo(null));
        dispatch(DependantActions.SaveDependantAddress(null));
        history.push({ pathname: location?.state?.addBenPath, state: { fromBenAddress: true } });
      })
      .catch((err) =>{
        toast.error(
          <div className='flex flex-row'>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );      
        setAddLoading(false);
        
      }
      );
    }
    
  };

  return (
    <div className="AddDependentPage">
      <h4 className="page-heading-navbar">{location?.state?.isDepEdit ?
      //  "Edit" 
      <FormattedMessage id='myBeneficiaries.EditBeneficiaryAddress' defaultValue='Edit Beneficiary Address'/>
       : 
      //  "Add"
      <FormattedMessage id='myBeneficiaries.AddBeneficiaryAddress' defaultValue='Add Beneficiary Address'/>
       } 
      </h4>

      {/* <Stack sx={{ mb: 5 }} width={{ xs: "90%", sm: "100%" }}> */}
      <div className="container mx-auto mt-4">
        <div className="flex flex-wrap">
          <div className="w-full flex justify-between">
            <div className="details">
              <h3 className="title">
                {/* Address */}
                <FormattedMessage id='myBeneficiaries.Address' defaultValue='Address'/>
              </h3>
              <p className="subTitle">
                {/* Add the address for your beneficiary */}
                <FormattedMessage id='myBeneficiaries.AddressForBeneficiary' defaultValue='Add the address for your beneficiary'/>
              </p>
            </div>

            {/* <div className="eclipseOuter mx-2">
              <img src={ellipseIcon} alt="Ellipse" className="ellipse" />
              <p className="count">3 of 4</p>
            </div> */}
          </div>
        </div>
      </div>
      {/* </Stack> */}

      <Stack sx={{ mx: { xs: 0, xl: 10, md: 5 } }}>
        <Box sx={{ my: 2 }}>
          <Stack
            display={{ xs: "flex", sm: "flex" }}
            direction={{ xs: "column", sm: "column" }}
            justifyContent="space-between"
            alignItems={{ xs: "center", sm: "center" }}
            spacing={2}
          >
            <Stack width={{ xs: "90%", sm: "50%" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="success"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  // label="Address same as me"
                  label={<FormattedMessage id='myBeneficiaries.AddressSameAsMe' defaultValue='Address same as me'/>}
                />
              </FormGroup>
              {!checked && (
                <>

                  <TextField
                    size="small"
                    name="streetLineOne"
                    // label="Street Address Line 1"
                    label={<FormattedMessage id='myBeneficiaries.StreetAddressLine1' defaultValue='Street Address Line 1'/>}
                    id="outlined-basic"
                    onChange={(event) =>
                      handleChangeDependentAddress(event, "textfield")
                    }
                    variant="outlined"
                    error={formError.streetLineOne.isError}
                    helperText={formError.streetLineOne.errorMessage}
                    value={dependentAddress.streetLineOne ? dependentAddress.streetLineOne : ""}
                    sx={{
                      my: 2,
                      width: "100%",
                      //backgroundColor: "#dedede",
                      "& fieldset": {
                        border: "1px solid #dedede",
                      },
                      "& .MuiInputBase-input:focus": {
                        border: "2px solid #fcd25e", // focus
                      },
                      "& .css-k4qjio-MuiFormHelperText-root": {
                        backgroundColor: "#ffffff",
                        margin: "0px",
                        paddingLeft: "0.5rem",
                      },
                      // ...requiredStyled
                    }}
                  />
                  <TextField
                    size="small"
                    name="streetLineTwo"
                    // label="Street Address Line 2"
                    label={<FormattedMessage id='myBeneficiaries.StreetAddressLine2' defaultValue='Street Address Line 2'/>}
                    id="outlined-basic"
                    onChange={(event) =>
                      handleChangeDependentAddress(event, "textfield")
                    }
                    variant="outlined"
                    error={formError.streetLineTwo.isError}
                    helperText={formError.streetLineTwo.errorMessage}
                    value={dependentAddress.streetLineTwo ? dependentAddress.streetLineTwo : ""}
                    sx={{
                      my: 2,
                      width: "100%",
                      //backgroundColor: "#dedede",
                      "& fieldset": {
                        border: "1px solid #dedede",
                      },
                      "& .MuiInputBase-input:focus": {
                        border: "2px solid #fcd25e", // focus
                      },
                      "& .css-k4qjio-MuiFormHelperText-root": {
                        backgroundColor: "#ffffff",
                        margin: "0px",
                        paddingLeft: "0.5rem",
                      },
                      // ...requiredStyled
                    }}
                  />
                  <TextField
                    size="small"
                    name="city"
                    // label="City"
                    label={<FormattedMessage id='myBeneficiaries.City' defaultValue='City'/>}
                    id="outlined-basic"
                    onChange={(event) =>
                      handleChangeDependentAddress(event, "textfield")
                    }
                    variant="outlined"
                    error={formError.city.isError}
                    helperText={formError.city.errorMessage}
                    value={dependentAddress.city ? dependentAddress.city : ""}
                    sx={{
                      my: 2,
                      width: "100%",
                      //backgroundColor: "#dedede",
                      "& fieldset": {
                        border: "1px solid #dedede",
                      },
                      "& .MuiInputBase-input:focus": {
                        border: "2px solid #fcd25e", // focus
                      },
                      "& .css-k4qjio-MuiFormHelperText-root": {
                        backgroundColor: "#ffffff",
                        margin: "0px",
                        paddingLeft: "0.5rem",
                      },
                      // ...requiredStyled
                    }}
                  />

                  <TextField
                    size="small"
                    name="state"
                    // label="State"
                    label={<FormattedMessage id='myBeneficiaries.State' defaultValue='State'/>}
                    id="outlined-basic"
                    onChange={(event) =>
                      handleChangeDependentAddress(event, "textfield")
                    }
                    variant="outlined"
                    error={formError.state.isError}
                    helperText={formError.state.errorMessage}
                    value={dependentAddress.state ? dependentAddress.state : ""}
                    sx={{
                      my: 2,
                      width: "100%",
                      //backgroundColor: "#dedede",
                      "& fieldset": {
                        border: "1px solid #dedede",
                      },
                      "& .MuiInputBase-input:focus": {
                        border: "2px solid #fcd25e", // focus
                      },
                      "& .css-k4qjio-MuiFormHelperText-root": {
                        backgroundColor: "#ffffff",
                        margin: "0px",
                        paddingLeft: "0.5rem",
                      },
                      // ...requiredStyled
                    }}
                  />

                  <TextField
                    size="small"
                    name="postalCode"
                    // label="Postal Code"
                    label={<FormattedMessage id='myBeneficiaries.PostalCode' defaultValue='Postal Code'/>}
                    id="outlined-basic"
                    onChange={(event) =>
                      handleChangeDependentAddress(event, "onlyNumber")
                    }
                    variant="outlined"
                    error={formError.postalCode.isError}
                    helperText={formError.postalCode.errorMessage}
                    value={dependentAddress.postalCode ? dependentAddress.postalCode : ""}
                    sx={{
                      my: 2,
                      width: "100%",
                      //backgroundColor: "#dedede",
                      "& fieldset": {
                        border: "1px solid #dedede",
                      },
                      "& .MuiInputBase-input:focus": {
                        border: "2px solid #fcd25e", // focus
                      },
                      "& .css-k4qjio-MuiFormHelperText-root": {
                        backgroundColor: "#ffffff",
                        margin: "0px",
                        paddingLeft: "0.5rem",
                      },
                      // ...requiredStyled
                    }}
                  />
                </>
              )}
              <div className="bottomButtonReq">
              <ButtonM 
                // text="SUBMIT"
                text={<FormattedMessage id='common.button.SUBMIT' defaultValue='SUBMIT'/>}
                disabled={addLoading}
                loading={addLoading}
                onpress={() => handleValidateDependent()} />
             </div>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </div>
  );
};

export default AddBeneficiaryAddress;
