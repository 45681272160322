import { Skeleton } from "@mui/material";

const LearnPageSkeleton = () => {
    return(
        <div>
            <div className="flex justify-between my-4 mx-4">
            <Skeleton variant="rounded" sx={{width:"30%"}} height={50} />
            <Skeleton variant="rounded" sx={{width:"30%"}} height={50} />
            <Skeleton variant="rounded" sx={{width:"30%"}} height={50} />
          </div>
          <div className="flex justify-between my-4 mx-4">
            <Skeleton variant="rounded" sx={{width:"90%"}} height={100} />
          </div>
          <div className="flex flex-wrap justify-between my-4 mx-4">
            <Skeleton variant="rounded" sx={{width:"30%"}} height={115} />
            <Skeleton variant="rounded" sx={{width:"30%"}} height={115} />
            <Skeleton variant="rounded" sx={{width:"30%"}} height={115} />
          </div>
        </div>
    )
}

export default LearnPageSkeleton;