import PolicyCover from "../../../../common/PolicyCover";
import coverIcon from "../../../../../assets/term/shortTermcover.png";
import ButtonM from "../../../../common/Button";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

const ShortTermCover = () => {

    // const dispatch = useDispatch();
    const history = useHistory();

    const [coveragePlan, setCoveragePlan] = useState(null);
    
    const employeeDetails = useSelector((state) => state && state.empData);
    const clientName = useSelector((state) => state && state.empData && state.empData.empData && state.empData.empData.company && state.empData.empData.company.displayName); 
    const autoEnroll = useSelector((state) => state && state.empData && state.empData.empData && state.empData.empData.company && state.empData.empData.company.displayName); 
    const selectedPlan = useSelector((state) => state?.planReducer?.planData);
    const planDisplayName = selectedPlan && selectedPlan.displayName;

    useEffect(() => {
      getCoveragePlan();
      getIsDependantSplit()
    }, [])
    

    const handleGroup = () => {          
        history.push('/disability/long-term');
    };

    const getCoveragePlan = () => {
      selectedPlan && 
      selectedPlan.coveragePlans && 
      selectedPlan.coveragePlans.forEach(
        (x) => x && 
      x.employeeRoles && 
      x.employeeRoles.forEach(
        (y) => 
        (y.id === (employeeDetails &&
      employeeDetails.empData &&
      employeeDetails.empData.employeeRole &&
      employeeDetails.empData.employeeRole.id)) &&
      setCoveragePlan(x)
      )
      );
    };
    
    const getIsDependantSplit = () => {
      let isSplit = false;
          selectedPlan && selectedPlan.planDependants && selectedPlan.planDependants.forEach((dep) => {
             if(dep && dep.employeeSplitinPerc !== "NA"){
              isSplit = true;
             }
          })

          return isSplit;
    };

  return (
    <div className="groupMedicalCover">
      <PolicyCover
        policyIcon={coverIcon}
        title={`Short Term Disability`}
        description={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`}
        // description1={`${clientName} offers you Long Term Disability insurance to cover your costs when you visit a doctor or need medical treatment. ${autoEnroll ? "You do not need to enroll for this coverage for yourself but you need to take action to enroll your eligible family members" : "You need to enroll for this coverage"}.`}
        // description2={`${clientName} ${(coveragePlan && coveragePlan.employeeSplitPerc !== "NA") ? "pays the portion of cost of the insurance coverage for you" : "pays the cost of the insurance coverage for you"} ${getIsDependantSplit() ? "and a portion of the cost for your family." : "and for your family."}`}
        // description3={`Click next to learn more about how this benefit works and to enroll.`}
      />
      <div className="bottomButtonReq">
      <ButtonM text="NEXT" onpress={() => handleGroup()} />
      </div>
    </div>
    // <div>fafdagfdga</div>
  );
};

export default ShortTermCover;
