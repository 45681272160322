import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.PLAN_BENEFICIARIES_DATA_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.PLAN_BENEFICIARIES_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        beneficiaries: payload || [],
    }),
    [types.PLAN_BENEFICIARIES_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),

    [types.ALL_BENEFICIARIES_REQUEST]: (state) => ({
        ...state,
        allBenLoader: true,
    }),
    [types.ALL_BENEFICIARIES_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        allBenLoader: false,
        allBeneficiaries: payload || [],
    }),
    [types.ALL_BENEFICIARIES_REQUEST_FAILED]: (state) => ({
        ...state,
        allBenLoader: false,
    }),

    [types.TEMP_BEN_ALLOCATION_INFO]: (state, { payload }) => ({
        ...state,
        tempAllocatedBeneficiaries: payload || null,
    }),

};

export default handleActions(actionHandler, {
    loader: false,
    beneficiaries: [],      
    allBenLoader: false,
    allBeneficiaries: [],
    tempAllocatedBeneficiaries:null,
});
