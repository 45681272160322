import { CurrencyFormat } from "components/utils/numberFormatter";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RateCard = ({ plan }) => {
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const currency = employeeDetails?.company?.currency;
  const deductionMethod = plan?.deductionMethod;

  const [allowedRatecards, setallowedRatecards] = useState([]);

  useEffect(() => {
    const rateCards = plan?.rateCard?.rateCardItems?.sort(
      (a, b) => a?.sumInsured - b?.sumInsured
    );
    setallowedRatecards(rateCards);
  }, [plan]);

  const getElligiblePlanDependant = (planDependants) => {
    let legitDependents = [];
  
      planDependants?.forEach(
        (x) =>
          x?.employeeRoles?.forEach(
            (y) =>{
              if(y?.id === employeeDetails?.employeeRole?.id ){
              legitDependents.push(x)
          }
        }
          )
      );
    return legitDependents.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
  };

  const getDependantSplit = (rateCard, life) => {
    const planDep = plan?.planDependants?.filter(item => (typeof item === "object"));
    const elligibleDep = getElligiblePlanDependant(planDep);

    let bundle = elligibleDep[0]?.bundleFlatFees;
    let PremiumToBePaid = null;
    // plan?.planDependants?.forEach((x) => {
    //      if(x.relation?.includes(selectedCategories[progressArray.length])){
    //       bundle = x.bundleFlatFees;
    //      }
    // })

    const oneLifeBundle = bundle?.filter((x) => x.life == life)[0];
    const RC = rateCard?.bundlePremium?.filter((x) => x.life == life)[0];

    if (deductionMethod == "Percent") {
      // PremiumToBePaid = (RC?.value * oneLifeBundle?.value) / 100;
      PremiumToBePaid = RC?.value;
    } else if (deductionMethod == "Flat") {
        PremiumToBePaid = oneLifeBundle?.value;
    }else if (deductionMethod == "NA") {
        PremiumToBePaid = 0;
    }

    return CurrencyFormat(PremiumToBePaid, currency);
  };

  return (
    <div className="bundle-rate-card-popup">
      <h5 className="upper-heading">Sum Insured \ Premium</h5>
      {/* <p>Premium cost is shared and below is the total cost per annum. Nium sponsors 50% and Employee to bear 50% of premium cost.</p> */}
      <p>Premium cost is shared and below is the total Premium per annum (*PA). Nium sponsors 50% and Employee to bear 50% of premium cost </p>

      {allowedRatecards?.length == 0 ? (
        <div className="my-16 text-center"> No plan available </div>
      ) : (
        <>
        <div className="rate-cards-main one">
          <div className="one-parent">
            <h2>1 Parent</h2>
            <p>Individual cover - Sum Insured is not shared</p>
            <table className="rates-table">
              <thead className="header-row">
                <tr>
                <th>Sum Insured</th>
                <th className="rate-col">Premium PA (*)</th>
                </tr>
              </thead>
              {allowedRatecards?.map((ratecard) => {
                return (
                  <tbody className="row">
                    <tr>
                    <td>{CurrencyFormat(ratecard?.sumInsured, currency)}</td>
                    <td>{getDependantSplit(ratecard, 1)}</td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
        <div className="rate-cards-main two">
          <div className="one-parent">
            <h2>2 Parent</h2>
            <p>Floater cover-Sum insured is shared among 2 members</p>
            <table className="rates-table">
              <thead className="header-row">
                <tr>
                <th>Sum Insured</th>
                <th className="rate-col">Premium PA (*)</th>
                </tr>
              </thead>
              {allowedRatecards?.map((ratecard) => {
                return (
                  <tbody className="row">
                    <tr>
                    <td>{CurrencyFormat(ratecard?.sumInsured, currency)}</td>
                    <td>{getDependantSplit(ratecard, 2)}</td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
        <div className="rate-cards-main three">
          <div className="one-parent">
            <h2>3 Parent</h2>
            <p>Floater cover - Sum Insured is shared among 3 members</p>
            <table className="rates-table">
              <thead className="header-row">
                <tr>
                <th>Sum Insured</th>
                <th className="rate-col">Premium PA (*)</th>
                </tr>
              </thead>
              {allowedRatecards?.map((ratecard) => {
                return (
                  <tbody className="row">
                    <tr>
                    <td>{CurrencyFormat(ratecard?.sumInsured, currency)}</td>
                    <td>{getDependantSplit(ratecard, 3)}</td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
        <div className="rate-cards-main four">
          <div className="one-parent">
            <h2>4 Parent</h2>
            <p>Floater cover-Sum insured is shared among 2 members</p>
            <table className="rates-table">
              <thead className="header-row">
                <tr>
                <th>Sum Insured</th>
                <th className="rate-pre">2 Parents bundle Premium PA (*)</th>
                <th className="rate-pre">2 Parents in law bundle Premium PA (*)</th>
                </tr>
              </thead>
              {allowedRatecards?.map((ratecard) => {
                return (
                  <tbody className="row">
                    <tr>
                    <td>{CurrencyFormat(ratecard?.sumInsured, currency)}</td>
                    <td>{getDependantSplit(ratecard, 2)}</td>
                    <td>{getDependantSplit(ratecard, 2)}</td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
        </>
      )}
    </div>
  );
};

export default RateCard;
