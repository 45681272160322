import { CircularProgress } from "@mui/material";
// import { CurrencyFormat } from "components/utils/numberFormatter";
import { isEmptyNullUndefined } from "components/utils/utils";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import moment from 'moment';

const BenSumTable = ({loadingSummary, summaryData, location}) => {

    const employeeDetails = useSelector((state) => state?.empData?.empData)
    const currency = employeeDetails?.company?.currency;  
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

    const currencyFormat = (amount) => {
      if(amount == "--"){
          return amount
      }
      if (currency === "INR") {
  
        // return (parseFloat(amount?.toFixed(1)).toLocaleString('en-IN'));
  
        return (Math.round(amount).toLocaleString('en-IN'));
  
      } else {
  
        // return (parseFloat(amount?.toFixed(1)).toLocaleString('en-US'));
  
        return (Math.round(amount).toLocaleString('en-US'));
        
      }
    }

  return (
    <div id="benefit-summary-content-page" style={{ width: "100%", height:"100%" }}>
      {loadingSummary ? (
        <div className="w-full h-full flex justify-center items-center">
          <CircularProgress color="success" size={54} />
        </div>
      ) : (
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            // margin: "5%",
            textAlign: "left",
          }}
        >
          <thead>
            <tr>
              <th style={{width:"25%"}}>
                {/* Benefit */}
                <FormattedMessage id='sidebar.BenefitSummary.Benefit' defaultMessage='Benefit'/>
              </th>
              <th style={{width:"25%"}}>
                {/* Coverage */}
                <FormattedMessage id='sidebar.BenefitSummary.Coverage' defaultMessage='Coverage'/>
              </th>
              <th style={{width:"25%"}}>
                {/* Covered */}
                <FormattedMessage id='sidebar.BenefitSummary.Covered' defaultMessage='Covered'/>
              </th>
              {
                    // location !== "plans" && 
                    <th style={{ textAlign: "center", width:"25%" }}>
                      {/* Annual Cost */}
                      <FormattedMessage id='sidebar.BenefitSummary.AnnualCost' defaultMessage=' Annual Cost'/>
                    </th>
                  }   
              
            </tr>
          </thead>
          <tbody>
            {summaryData?.map((plan) => {
              return (
                <tr style={{ borderBottom: "1px solid black" }}>
                  <td style={{ padding: "0.5rem 0px" }}>{plan?.planName}</td>
                  <td style={{ padding: "0.5rem 0.5rem" }}>
                  {
                   !isEmptyNullUndefined(plan?.employeeCoverageString) ? 
                    <>{plan?.employeeCoverageString}</>
                    :
                    currencyFormat(plan?.employeeCoverage, currency)
                  }
                  </td>
                  <td style={{ padding: "0.5rem 0.5rem" }}>
                    <ul>
                      {
                        plan?.employeeEnrolled && 
                        <li>
                          {employeeDetails?.firstName} {employeeDetails?.lastName}
                        </li>
                      }
                        
                      {                        
                        plan?.className === "lifeinsurance" ? 
                        // plan?.beneficiaryAllocation?.map((dep) => {
                        //   return (
                        //     <li>
                        //       {dep?.beneficiary?.firstName} {isEmptyNullUndefined(dep?.beneficiary?.lastName) ? "" : dep?.beneficiary?.lastName}
                        //     </li>
                        //   );
                        // })
                        <></>
                        :                        
                        plan?.dependants?.map((dep) => {
                          return (
                            <li>
                              {dep?.firstName} {dep?.lastName} (DOB - {moment(dep?.dateOfBirth).format(
                        dateFormat?.toUpperCase()
                        )})
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </td>
                  {
                    // location !== "plans" && 
                    <td style={{ padding: "0.5rem 0px", textAlign: "center" }}>
                    {currencyFormat(plan?.employeeCost, currency)}
                  </td>
                  }                  
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default BenSumTable;
