import { useEffect, useRef } from "react";
import LineGreenIcon from "../../../assets/LineGreen.svg";
import { useHistory } from "react-router-dom";
import { actions as planPageAction } from "../Plans/redux/actions";
import { useDispatch } from "react-redux";

const ExitPopup = ({ path }) => {

  const history = useHistory();
  const dispatch = useDispatch();

  function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          
          dispatch(planPageAction.closeExitPopUp());
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);

  return (
    <div className="learningPageCoverageDetails">
      <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mr-auto md:ml-auto max-w-3xl">
          <div className="CoverageDetailsPopup exitpopup" ref={wrapperRef}>
            <div className="content">
              <div className="container benSumInFinalEnroll">
                <div className="fex flex-wrap">
                  <img src={LineGreenIcon} alt="Line" className="lineGreen" />
                </div>
                Are you sure you want to exit? If 'yes', your enrollment will be saved as draft and you have to revisit later to conclude enrollment by clicking 'Finalize' button.

                {/* <BenSumTable
                  loadingSummary={loadingSummary}
                  summaryData={summaryData}
                /> */}
              </div>
              <div className="flex flex-wrap justify-between w-full total">
                <div className="planPopUpBtns mt-4">
                  <button className="yesbtn" onClick={() => {dispatch(planPageAction.closeExitPopUp()); history.push(path);}}>
                    Yes
                  </button>
                  <button className="nobtn" onClick={() => dispatch(planPageAction.closeExitPopUp())}>
                    NO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExitPopup;
