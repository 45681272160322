import { useEffect } from "react";

const CoinTable = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

return(
    <div className="h-full mx-auto flex flex-col justify-between">
        
        <div className="coinTable">          

<div class="flex flex-col overflow-x-auto">
  <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
    <div class="overflow-x-auto table-bor">
      <table class="min-w-full text-left text-sm font-light">
        <thead class="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
          <tr>
            <th scope="col" class="px-4 py-4">Plans</th>
            <th scope="col" class="px-4 py-4">Coin</th>
            <th scope="col" class="px-4 py-4">Frequency</th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b dark:border-neutral-500">
            <td class="whitespace-nowrap px-4 py-4">2023 Benecoins allocated</td>
            <td class="whitespace-nowrap px-4 py-4 dark">5000 coin</td>
            <td class="whitespace-nowrap px-4 py-4 dark">-</td>
          </tr>
          <tr class="border-b dark:border-neutral-500">
            <td class="whitespace-nowrap px-4 py-4">2022 carry over</td>
            <td class="whitespace-nowrap px-4 py-4 dark">500 coins</td>
            <td class="whitespace-nowrap px-4 py-4 dark">-</td>
          </tr>
          <tr class="border-b dark:border-neutral-500">
            <td class="whitespace-nowrap px-4 py-4 dark">Total Coins (opening balance)</td>
            <td class="whitespace-nowrap px-4 py-4 dark total">5500 coins</td>
            <td class="whitespace-nowrap px-4 py-4 dark">-</td>
          </tr>
          <tr class="">
            <td class="whitespace-nowrap px-4 py-4">Spend Summary (YTD)</td>
            <td class="whitespace-nowrap px-4 py-4 dark"></td>
            <td class="whitespace-nowrap px-4 py-4 dark">-</td>
          </tr>
          <tr class="">
            <td class="whitespace-nowrap px-4 py-4">Spend</td>
            <td class="whitespace-nowrap px-4 py-4 dark">Your spent</td>
            <td class="whitespace-nowrap px-4 py-4 dark">Frequency</td>
          </tr>
          <tr class="">
            <td class="whitespace-nowrap px-4 py-4">Medical Plan</td>
            <td class="whitespace-nowrap px-4 py-4 dark">0</td>
            <td class="whitespace-nowrap px-4 py-4 dark">One time</td>
          </tr>
          <tr class="">
            <td class="whitespace-nowrap px-4 py-4">Medical Top up</td>
            <td class="whitespace-nowrap px-4 py-4 dark">200 coins</td>
            <td class="whitespace-nowrap px-4 py-4 dark">One time</td>
          </tr>
          <tr class="">
            <td class="whitespace-nowrap px-4 py-4">Parental plan</td>
            <td class="whitespace-nowrap px-4 py-4 dark">600 coins</td>
            <td class="whitespace-nowrap px-4 py-4 dark">One time</td>
          </tr>
          <tr class="">
            <td class="whitespace-nowrap px-4 py-4">Gym Membership</td>
            <td class="whitespace-nowrap px-4 py-4 dark">1200 coins</td>
            <td class="whitespace-nowrap px-4 py-4 dark">YTD (Aug 2023)</td>
          </tr>
          <tr class="border-b dark:border-neutral-500">
            <td class="whitespace-nowrap px-4 py-4">Child care</td>
            <td class="whitespace-nowrap px-4 py-4 dark">1500 coins</td>
            <td class="whitespace-nowrap px-4 py-4 dark">YTD (July 2023)</td>
          </tr>
          <tr class="">
            <td class="whitespace-nowrap px-4 py-4">Total Spent (YTD)</td>
            <td class="whitespace-nowrap px-4 py-4 dark">3500</td>
            <td class="whitespace-nowrap px-4 py-4 dark"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="pointsBtn bg-blue-500 hover:bg-blue-700 text-white font-bold my-5 py-2 px-4 border border-blue-700 rounded">
      <p className="subTitle">Remaining points</p>
      2000 coins
    </button>
  </div>
</div>
</div>
    </div>
)
}

export default CoinTable;