import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";

const CalenderDialog = ({
  openDialog, 
  setOpenDialog, 
  initialEmptyEvent, 
  setSelectedEvent, 
  setIsNewEvent, 
  setIsEdit, 
  children, 
  setEventError,
  initialEmptyEventError}) => {

//   const [open, setOpen] = React.useState(openDialog);

//   const handleClickOpen = () => {
//     setOpen(openDialog);
//   };

  const handleClose = () => {
    // setOpen(false);    
    setOpenDialog(false);
    setIsEdit(false);
    setIsNewEvent(false);
    setSelectedEvent(initialEmptyEvent);
    setEventError(initialEmptyEventError);
  };

    return(
      <div className="common-dialog">
        <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        
        <DialogContent>
          <div id="alert-dialog-description">
            {children}
          </div>
        </DialogContent>
        
      </Dialog>
      </div>
    )
}

export default CalenderDialog;