import { useEffect, useState} from "react";
import ButtonM from "../../common/Button";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { actions as getPlanActions } from "./redux/actions";
import { actions as EmployeeDataActions } from "../Header/redux/actions";
import { Backdrop, CircularProgress, LinearProgress, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import { GiCheckMark } from "react-icons/gi";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { BsFillCheckCircleFill, BsShieldCheck } from "react-icons/bs";
import { RiErrorWarningFill, RiShieldUserFill } from "react-icons/ri";
import APIList from "api";
import { toast } from "react-toastify";
import moment from "moment";
import PlanSkeleton from "./PlanSkeleton";
import { BiError } from "react-icons/bi";
import BenSumModal from "./BenSumModal";
import { isEmptyNullUndefined } from "components/utils/utils";
import { CurrencyFormat } from "components/utils/numberFormatter";
import { actions as BeneficiaryActions } from "../../pages/AddBeneficiaryAfterPlanENroll/redux/actions";
import { actions as VolutryActions } from "../../pages/Voluntry/redux/actions";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const costStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: "100%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const Plans = () => {
  
    const [viewCost, setViewCost] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false);setViewCost(false);};  

    const history = useHistory();
    const dispatch = useDispatch();

    const [create, setCreate] = useState(false);
    const [editPlan, setEditPlan] = useState(false);
    const [enrollPlanId, setEnrollPlanId] = useState("");
    const [enrollPlanType, setEnrollPlanType] = useState("");
    const [editPlansData, setEditPlansData] = useState({});
    const [enrollMentLoader, setEnrollMentLoader] = useState(false);
    const [costLoader, setCostLoader] = useState(false);
    const [costData, setCostData] = useState({});

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const { employeeEnrolled, employeeEnrolledLoader } = useSelector((state) => state && state.empData);
    const selectedPlan = useSelector((state) => state?.planReducer?.planData);
    const enrolledPlanData = useSelector((state) => state?.planReducer?.enrolledPlanData);
    const loopPlans = useSelector((state) => state?.planReducer?.loopPlans);
    const planLoader = useSelector((state) => state?.planReducer?.loader);
    const selectedPlanLoader = useSelector((state) => state?.planReducer?.loader);
    const currency = useSelector((state) => state?.empData?.empData?.company?.currency);

    useEffect(() => {      
      dispatch(BeneficiaryActions.TempBenAllocationInfo(null));
      if(employeeDetails?.id != undefined){
        const payload = {
            empId: employeeDetails?.id
        };
        dispatch(EmployeeDataActions.EmployeeEnrolledRequest(payload)); 
        dispatch(getPlanActions.PlanDataRequest("clearData")); 
        dispatch(getPlanActions.EnrolledPlanDataRequest("clearData")); 
      }
    }, [employeeDetails?.id]);
    

    useEffect(() => {
      dispatch(getPlanActions.loopPlans((Object.keys(employeeEnrolled)?.length > 0) && employeeEnrolled?.filter((item) => (item.enrollmentStatus == 'No'))));
    }, [employeeEnrolled]);

    useEffect(() => {
      if (create) {
        setCreate(false);
        if ( selectedPlan?.planType === "Base" && selectedPlan?.benefits?.subBenefitType === "Medical Insurance Plan" ) {
          // history.push("/base-cover");
          history.push("/basicMedical");
        } else if ( selectedPlan?.planType === "Base" &&
          (selectedPlan?.benefits?.subBenefitType === "Life Insurance" ||
            selectedPlan?.benefits?.subBenefitType === "Disability Insurance" ||
            selectedPlan?.benefits?.subBenefitType === "Accident and Death")
        ) {
          // history.push("/life-insurance-cover");
          history.push("/life-insurance");
        } else if ( selectedPlan?.planType === "Voluntary" && selectedPlan?.benefits?.subBenefitType === "Medical Insurance Plan" ) {
          // history.push("/voluntry-cover");
          dispatch(VolutryActions.ViewedPremiumRates(false));
          if(selectedPlan?.rateCard?.rateDiffer == "bundle"){            
              history.push("/voluntry/bundle-add-parents-new");
          } else {
          history.push("/voluntry");
        }
        } else if ( selectedPlan?.planType === "Base" && selectedPlan?.benefits?.subBenefitType === "Healthcare Reimbursement Account" && selectedPlan?.className === "healthinsurance") {
          // history.push("/base-cover");
          history.push("/basicMedical");
        }else if ( selectedPlan?.planType === "Base" && selectedPlan?.className === "pension" ) {
          // history.push("/pension-cover");
          history.push("/pension-plan");
        }else if ( selectedPlan?.planType === "Base" && selectedPlan?.className === "displayplan" ) {
          // history.push("/display-plan-cover");
          history.push("/display-plan");
        } else {
          history.push("/home");
        }
      }
      if (editPlan) {
        setCreate(false);
        if ( selectedPlan?.planType === "Base" && selectedPlan?.benefits?.subBenefitType === "Medical Insurance Plan" ) {
            history.push(`/basicMedical/${enrollPlanId}`);
        } else if ( selectedPlan?.planType === "Base" &&
          (selectedPlan?.benefits?.subBenefitType === "Life Insurance" ||
            selectedPlan?.benefits?.subBenefitType === "Disability Insurance" ||
            selectedPlan?.benefits?.subBenefitType === "Accident and Death")
        ) {
          history.push("/life-insurance");
        } else if ( selectedPlan?.planType === "Voluntary" && selectedPlan?.benefits?.subBenefitType === "Medical Insurance Plan" ) {
          // history.push("/voluntry-cover");
         
          dispatch(VolutryActions.ViewedPremiumRates(false));
          if(selectedPlan?.rateCard?.rateDiffer == "bundle"){
            if(enrolledPlanData?.dependantEnrollments?.length === 0){
              history.push("/voluntry/bundle-add-parents-new-edit");
            } else {
              history.push("/voluntry/bundle-final-new-edit");
            }              
          } else {
          history.push("/voluntry");
        }
        } else if ( selectedPlan?.planType === "Base" && selectedPlan?.benefits?.subBenefitType === "Healthcare Reimbursement Account" && selectedPlan?.className === "healthinsurance" ) {
          // history.push("/base-cover");
          history.push("/basicMedical");
        } else if ( selectedPlan?.planType === "Base" && selectedPlan?.className === "pension" ) {
          // history.push("/pension-cover");
          history.push("/pension-plan");
        } else {
          history.push("/home");
        }
      }
    }, [selectedPlan])

    useEffect(() => {
      if (editPlan) {
      dispatch(getPlanActions.PlanDataRequest({     
        className: editPlansData?.className,
        Id: editPlansData?.planId,
        empId: employeeDetails?.id,
        eventId: editPlansData?.eventId || null,
        lifeEventId: editPlansData?.lifeEventId || null
      }));
      } 
    }, [enrolledPlanData]);   

    const redirectToPlan = (plan) => {
      if(plan?.className === "reimbursements"){
        history.push(`/reimbursement/enroll/${plan?.planId}`)
     } else {
        dispatch(
          getPlanActions.PlanDataRequest({
            className: plan?.className,
            Id: plan?.planId,
            empId: employeeDetails?.id,
            eventId: plan?.eventId || null,
            lifeEventId: plan?.lifeEventId || null
          })
        );
        setCreate(true);   
     }  
    };

    const handleEdit = (plan) => {
      if(plan?.className === "reimbursements"){
        history.push(`/reimbursement/edit/${plan?.enrollment}`)
     } else {
      setEditPlansData(plan);
      setEnrollPlanId(plan?.planId);
      setEnrollPlanType(plan?.className);
        dispatch(getPlanActions.EnrolledPlanDataRequest({enrollmentId:plan?.enrollment}));
        setEditPlan(true); 
     }
    };

    const handleEnrollment = () => {
      
      const enrolled = employeeEnrolled?.filter((x) => x.enrollmentStatus == "Yes");
      const enrollmentIds = enrolled.map(x => x.enrollment);
     
      setEnrollMentLoader(true);
      APIList.plansEnroll({enrollmentIds:enrollmentIds,empId:employeeDetails?.id})
      .then((res) => {
        res &&
          toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;Enrolled Successfully
            </div>
          );
          // dispatch(EmployeeDataActions.EmployeeEnrolledRequest({empId: employeeDetails?.id})); 
          setEnrollMentLoader(false);
          handleClose();
          history.push('/home');          
      })
      .catch((err) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {err?.message}            
          </div>
        );
        setEnrollMentLoader(false);
      }
      );

    };

    const handleViewCost = () => {
      setViewCost(true);
      setOpen(true);

      const enrolled = employeeEnrolled?.filter((x) => x.enrollmentStatus == "Yes");
      const enrollmentIds = enrolled.map(x => x.enrollment);

      setCostLoader(true);
      APIList.getAllPlanCost({enrollmentIds})
      .then((res) => {        
        res && setCostLoader(false);       
        res && setCostData(res?.data);       
      })
      .catch((err) => {
        toast.error(
          <div className="flex flex-row">
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;
            {err?.message}            
          </div>
        );
        setCostLoader(false);
      }
      );
    }

    const anyPlanWithNoDependant = () => {
      let reply = false;
      employeeEnrolled
                  ?.filter((x) => x.enrollmentStatus == "Yes")
                  ?.forEach((plan) =>{
                    if(plan?.dependantEnrollmentCount == 0){
                      reply = true;
                    }
                  });
      return reply;            
    }

    const [summaryData, setsummaryData] = useState(null);
    const [loadingSummary, setLoadingSummary] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleBenSummary = () => {
      setOpenModal(true);
      setLoadingSummary(true);
      APIList.benefitSummary({empId:employeeDetails?.id})
      .then((res) => {
        setsummaryData(res.data);
        setLoadingSummary(false);
      })
      .catch((err) => {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        setLoadingSummary(false);
    })
    }

    const getEnrollBtnText = () => {
      if(employeeEnrolled?.filter((x) => x.enrollmentStatus == "Yes")?.length < 1){
        return "Start Enrollment";
      }else if(employeeEnrolled?.filter((x) => x.enrollmentStatus == "Yes")?.length < employeeEnrolled?.length){
        return "Continue Enroll";
      }else if(employeeEnrolled?.filter((x) => x.enrollmentStatus == "Yes")?.length === employeeEnrolled?.length){
        return "Finalize Enrollment";
      }
    }

    const handleEnrollBtn = () => {
      if(employeeEnrolled?.filter((x) => x.enrollmentStatus == "No")?.length > 0){
        redirectToPlan(employeeEnrolled?.filter((x) => x.enrollmentStatus == "No")[0]);
      }else{
        handleBenSummary();
      }
    }

    return (
      <div className="planWizardsMainPage flex flex-col mt-10 mb-10 mx-4 relative">
        <h4 className="page-heading-navbar">
          {employeeDetails?.company?.displayName}
        </h4>

        {
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={selectedPlanLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
        {employeeEnrolledLoader ? (
          <PlanSkeleton />
        ) : employeeEnrolled?.length > 0 ? (  
          <>
                     
            <VerticalTimeline layout="1-column" lineColor={"var(--primary)"}>
              {Object.keys(employeeEnrolled)?.length > 0 &&
                employeeEnrolled?.map((plan) => {
                  return (
                    <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      contentStyle={{
                        background:
                          plan?.enrollmentStatus == "Yes"
                            ? "var(--primary)"
                            : "var(--secondary)",
                        color:
                          plan?.enrollmentStatus == "Yes"
                            ? "var(--secondary)"
                            : "var(--primary)",
                      }}
                      contentArrowStyle={{
                        borderRight:
                          plan?.enrollmentStatus == "Yes"
                            ? "7px solid  var(--primary)"
                            : "7px solid var(--secondary)",
                      }}
                      // date="2011 - present"
                      iconStyle={{
                        background:
                          plan?.enrollmentStatus == "Yes"
                            ? "var(--primary)"
                            : "var(--secondary)",
                        color:
                          plan?.enrollmentStatus == "Yes"
                            ? "var(--secondary)"
                            : "var(--primary)",
                      }}
                      icon={
                        plan?.enrollmentStatus == "Yes" ? (
                          <GiCheckMark />
                        ) : (
                          <RiShieldUserFill />
                        )
                      }
                    >
                      <h3 className="vertical-timeline-element-title">
                        {plan.displayName.toUpperCase()}
                      </h3>
                      <hr />
                      {
                        <h6 className="vertical-timeline-element-subtitle wizardsSubTitlePlans">
                          Enrollment Deadline :{" "}
                          {moment(plan?.enrollmentDeadline?.slice(0,10)).format(
                            employeeDetails?.company?.dateFormat?.toUpperCase() ||
                              "DD/MM/YYYY"
                          )}
                        </h6>
                      }                      
                      <hr />

                      <p>
                         {plan?.enrollmentStatus == "Yes" &&
                          
                       
                          <div className="flex items-center planWizardsEmpEnrolled">
                            <BsShieldCheck
                              style={{
                                width: "1.35rem",
                                height: "1.35rem",
                                marginRight: "0.5rem",
                              }}
                            />
                            Self : {plan?.employeeEnrollment}
                          </div>
                       
                        }
                      </p>

                      {
                        plan?.enrollmentStatus === "Yes" && plan?.className === "healthinsurance" &&
                        <h6 className="vertical-timeline-element-subtitle mt-2">
                          {
                          plan?.dependantEnrollmentCount > 0 ? 
                          <span className="flex items-center planWizardsEmpEnrolled">
                            <BsShieldCheck
                          style={{
                            width: "1.35rem",
                            height: "1.35rem",
                            marginRight: "0.5rem",
                          }}
                        />
                        Dependants : {plan?.dependantEnrollmentCount} Enrolled </span>
                          :
                          <span className="inline-flex items-center" style={{color:"var(--warning)"}}><BiError style={{fontSize:"1.5rem"}} /> &nbsp; Dependants : NOT ADDED</span> 
                          }
                        </h6>
                      }
                      {
                        plan?.enrollmentStatus === "Yes" && plan?.className === "lifeinsurance" &&
                        <h6 className="vertical-timeline-element-subtitle mt-2">
                          {
                          Number(plan?.beneficiaryCount) > 0 ? 
                          <span className="flex items-center planWizardsEmpEnrolled">
                            <BsShieldCheck
                          style={{
                            width: "1.35rem",
                            height: "1.35rem",
                            marginRight: "0.5rem",
                          }}
                        />
                        {Number(plan?.beneficiaryCount)} {Number(plan?.beneficiaryCount) > 1 ? "beneficiaries" : "beneficiary" } added </span>
                          :
                          <span className="inline-flex items-center" style={{color:"var(--warning)"}}><BiError style={{fontSize:"1.5rem"}} /> &nbsp; No beneficiary added</span> 
                          }
                        </h6>
                      }

                      {
                       !isEmptyNullUndefined(plan?.coverageAmount) &&
                        <h6 className="vertical-timeline-element-subtitle mt-2">
                          <span className="flex items-center planWizardsEmpCoverage">
                            <BsShieldCheck
                          style={{
                            width: "1.35rem",
                            height: "1.35rem",
                            marginRight: "0.5rem",
                          }}
                        />
                        Coverage - {plan?.coverageAmount} </span>
                          
                        </h6>
                      }
                      {
                       !isEmptyNullUndefined(plan?.relation) &&
                        <h6 className="vertical-timeline-element-subtitle mt-2">
                          <span className="flex items-center planWizardsEmpCoverage">
                            <BsShieldCheck
                          style={{
                            width: "1.35rem",
                            height: "1.35rem",
                            marginRight: "0.5rem",
                          }}
                        />
                        Relations - {plan?.relation} </span>
                          
                        </h6>
                      }

                      {
                        plan?.eventName ? (
                          <p className="diagonalBadgeInPlansPage">
                            {plan?.eventName}
                          </p>
                        ) : (
                          ""
                        )
                        // <p className="diagonalBadgeInPlansPage">new joiner</p>
                      }
                      {plan?.enrollmentStatus == "Yes" && 
                          <div className="editBlock"  data-value={plan?.className}>                                          
                            <span
                              data-value="edit-btn"
                              className="editWizardButton"
                              onClick={() => handleEdit(plan)}
                            >
                              View/Edit
                            </span>
                          </div>
                }
                    </VerticalTimelineElement>
                  );
                })}
                
            </VerticalTimeline>

            {
            (employeeEnrolled?.filter((x) => x.enrollmentStatus == "Yes")?.length === employeeEnrolled?.length) &&
            <h4 className="planPleaseComplete mx-auto">
              {/* Please complete the Enrollment for each plan */}
              Click 'Finalize' at the end of the page to CONCLUDE Enrollment
            </h4>
          }

            {/* {employeeEnrolled?.filter((x) => x.enrollmentStatus == "Yes")
              ?.length > 0 && (
                <p className="planPageviewLink">
                  <span onClick={() => handleBenSummary()}>
                    Benefit Summary
                  </span>
                  <span onClick={() => handleViewCost()}>
                    View Annual Cost
                  </span>
                </p>
              
            )}           */}

            <div className="bottomButtonReq mt-4" id="final-enrollment-button-outer">

            {
              // (employeeEnrolled?.filter((x) => x.enrollmentStatus == "Yes")?.length > 0) &&
              <p className="userDisclaimer">
              {/* I agree the information provided above is true and correct. */}
               {/* I acknowledge on final submission that any inaccurate data or misrepresentation will be cause for refusal of Enrollment. */}
              </p>
            }

              <ButtonM
                text={getEnrollBtnText()}
                // disabled={
                //   enrollMentLoader ||
                //   employeeEnrolled?.filter((x) => x.enrollmentStatus == "Yes")
                //     ?.length < 1
                // }
                loading={enrollMentLoader}
                // bgcolor={
                //   enrollMentLoader ||
                //   employeeEnrolled?.filter((x) => x.enrollmentStatus == "Yes")
                //     ?.length < 1
                //     ? "gray"
                //     : ""
                // }
                // onpress={() => handleOpen()}
                onpress={() => handleEnrollBtn()}
              />
              {/* <ButtonM
                text="FINALIZE ENROLLMENT"
                disabled={
                  enrollMentLoader ||
                  employeeEnrolled?.filter((x) => x.enrollmentStatus == "Yes")
                    ?.length < 1
                }
                loading={enrollMentLoader}
                bgcolor={
                  enrollMentLoader ||
                  employeeEnrolled?.filter((x) => x.enrollmentStatus == "Yes")
                    ?.length < 1
                    ? "gray"
                    : ""
                }
                // onpress={() => handleOpen()}
                onpress={() => handleBenSummary()}
              /> */}
            </div>
            
          </>
        ) : (
          <div className="text-center my-16" id="go-to-home-button-outer">
            <p>You have no plan to Enroll</p>
            <ButtonM
              text="GO TO HOMEPAGE"
              onpress={() => history.push("/home")}
            />
          </div>
        )}

        {/* view cost modal starts here */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {viewCost ? (
            <Box sx={costStyle}>
              {Object.keys(costData)?.length > 0 &&
              costData?.totalCost === 0 ? (
                <div>
                  Nium Singapore pays the full cost of insurance for yourself and your eligible dependent(s).
                </div>
              ) : (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 375 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 700 }}>Plan</TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>
                          Your Annual Premium ({currency})
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {costLoader ? (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {/* <Skeleton
                              variant="text"
                              animation="wave"
                              sx={{ fontSize: "2rem" }}
                            /> */}
                            <CircularProgress color="success" size={29} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {/* <Skeleton
                              variant="text"
                              animation="wave"
                              sx={{ fontSize: "2rem" }}
                            /> */}
                            <CircularProgress color="success" size={29} />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {Object.keys(costData)?.length > 0 &&
                            costData?.planWise?.length > 0 &&
                            costData?.planWise.map((row) => (
                              <TableRow
                                key={row.planName}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.planName}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.totalCost}
                                </TableCell>
                              </TableRow>
                            ))}
                        </>
                      )}

                      {Object.keys(costData)?.length > 0 && (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ fontWeight: 700 }}>Total</TableCell>
                          <TableCell sx={{ fontWeight: 700 }}>
                            {costData?.totalCost}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <ButtonM
                text="BACK"
                onpress={() => {
                  handleClose();
                  setCostData({});
                }}
              />
            </Box>
          ) : (
            <Box sx={style}>
              <div className="planPopUpHeading">
                {/* Are you sure you want to enroll in plans? */}
                Please review your selection
              </div>
              <div className="planPopUpdescription">
                {employeeEnrolled
                  ?.filter((x) => x.enrollmentStatus == "Yes")
                  ?.map((plan) => {
                    return <li><span className="planNameInPopUp"> {plan?.displayName} </span> {(plan?.dependantEnrollmentCount == 0) ? " - Enrolled **" : " - Enrolled" }</li>;
                  })}
                  {
                anyPlanWithNoDependant() &&
                <p className="noDependantWarning">** You do not have dependant added in some plans</p>                
              }
              <p className="planNameInPopUp mt-4">Are you sure you want to continue?</p>
              </div>
              
              {enrollMentLoader ? (
                <div className="mb-4">
                  <LinearProgress color="success" />
                </div>
              ) : (
                <div className="planPopUpBtns">
                  <button className="yesbtn" onClick={() => handleEnrollment()}>
                    Yes
                  </button>
                  <button className="nobtn" onClick={() => handleClose()}>
                    NO
                  </button>
                </div>
              )}
              
            </Box>
          )}
        </Modal>
        {/* view cost modal ends here */}
        {openModal && 
        <BenSumModal 
        setOpenModal={setOpenModal} 
        loadingSummary={loadingSummary} 
        summaryData={summaryData} 
        enrollMentLoader={enrollMentLoader}
        handleEnrollment={handleEnrollment}
        location="plans"
        // handleClose={handleClose}
        />}
       
      </div>
    );
};

export default Plans;
