import { createAction } from "redux-actions";

const GET_EMPLOYEE_CASE_REQUEST = "GET_EMPLOYEE_CASE_REQUEST"
const GET_EMPLOYEE_CASE_REQUEST_SUCCESS = "GET_EMPLOYEE_CASE_REQUEST_SUCCESS"
const GET_EMPLOYEE_CASE_REQUEST_FAIL = "GET_EMPLOYEE_CASE_REQUEST_FAIL"

const GET_CASE_REQUEST = "GET_CASE_REQUEST"
const GET_CASE_REQUEST_SUCCESS = "GET_CASE_REQUEST_SUCCESS"
const GET_CASE_REQUEST_FAIL = "GET_CASE_REQUEST_FAIL"

const getEmployeeCaseRequest = createAction(GET_EMPLOYEE_CASE_REQUEST);
const getEmployeeCaseRequest_success = createAction(GET_EMPLOYEE_CASE_REQUEST_SUCCESS);
const getEmployeeCaseRequest_failed = createAction(GET_EMPLOYEE_CASE_REQUEST_FAIL);

const getCaseRequest = createAction(GET_CASE_REQUEST);
const getCaseRequest_success = createAction(GET_CASE_REQUEST_SUCCESS);
const getCaseRequest_failed = createAction(GET_CASE_REQUEST_FAIL);


export const employeeActions = {
    getEmployeeCaseRequest,
    getEmployeeCaseRequest_success,
    getEmployeeCaseRequest_failed,
    getCaseRequest,
    getCaseRequest_success,
    getCaseRequest_failed
}


export const employeeTypes = {
    GET_EMPLOYEE_CASE_REQUEST,
    GET_EMPLOYEE_CASE_REQUEST_SUCCESS,
    GET_EMPLOYEE_CASE_REQUEST_FAIL,

    GET_CASE_REQUEST,
    GET_CASE_REQUEST_SUCCESS,
    GET_CASE_REQUEST_FAIL
}
