import ButtonM from "components/common/Button";
import { CurrencyFormat } from "components/utils/numberFormatter";
import { useCallback, useEffect, useRef, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as VolutryActions } from "../../redux/actions";
import ellipseIcon from "../../../../../assets/Ellipse2.png";
import coverImg from "../../../../../assets/groupMedical/cover.png";
// import VoluntaryParentalModal from "../../ViewModal";
import PageHeader from "components/common/PageHeader";
import { getAge, getElligiblePlanDependant, getPayCheckFrequency } from "components/utils/utils";
import { RiErrorWarningFill } from "react-icons/ri";
import APIList from "api";
import { toast } from "react-toastify";
import GetCostVersion2 from "components/pages/BasicMedical/ViewCost/Version2";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import PageHeaderForPlans from "components/common/PageHeaderForPlans";
import { actions as getPlanActions } from "../../../../common/Plans/redux/actions";
import { FormattedMessage } from "react-intl";

var classNames = require("classnames");
// import { selectedPlan } from "../../utils";

const FamilyCoverageSelection = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const selectedPlan = useSelector((state) => state?.planReducer?.planData);
    const [coveragePlan, setCoveragePlan] = useState(null);
    const [allowedRatecards, setallowedRatecards] = useState([]);
    const [selectedCoverageCard, setSelectedCoverageCard] = useState({});
    const [selectedDiv, setselectedDiv] = useState("");
    const [currentEnrollingId, setCurrentEnrollingId] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [getCostLoader, setGetCostLoader] = useState(false);
    const [viewCost, setViewCost] = useState(false);
    const [costData, setCostData] = useState([]);
    const [electionChecked, setElectionChecked] = useState(false);
    const [enrollLoader, setEnrollLoader] = useState(false);

    const { selectedCategories, progressArray, selectedRatecards, currentlyEnrolled, currentlyInprocessEnrolled, selectedCoverages, familySelectedCoverages} = useSelector((state) => state?.voluntryReducer); 
    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const currency = employeeDetails?.company?.currency;
    const enrolledPlanData = useSelector((state) => state?.planReducer?.enrolledPlanData);
    const benefitStartDate = useSelector((state) => state?.planReducer?.benefitStartDate);
    
    const rateCards = selectedPlan?.rateCard?.rateCardItems?.filter((x) => ((x.sumInsured >= coveragePlan?.minCoverage) && (x.sumInsured <= coveragePlan.maxCoverage)))
    // console.log("rateCards",rateCards,coveragePlan);

  useEffect(() => {       
    dispatch(VolutryActions.setSelectedCoverages([]));
    setSelectedCoverageCard({});
    getCoveragePlan();
    
  }, []);
  useEffect(() => {

    getAllowedRatecards();
    
  }, [coveragePlan, currentlyInprocessEnrolled]);


  function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setViewCost(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);

  const getCoveragePlan = () => {
    
      selectedPlan?.coveragePlans?.forEach(
        (x) => x?.employeeRoles?.forEach(
            (y) =>
              y.id ===
              (employeeDetails?.employeeRole?.id) &&
              setCoveragePlan(x)
          )
      );
  };

  const getAllowedRatecards = () => {
    let arr = [];          
      arr = rateCards?.filter(
        (x) =>
          ((x.gender == employeeDetails.gender) || (x.gender == "NA")) &&
          isSelfAge(x,employeeDetails)
      );
    arr.sort((a, b) => a.sumInsured - b.sumInsured);
    setallowedRatecards(arr);
  }

  const isSelfAge = (rateCard, person) => {
    let isAge = false;
    if (rateCard.ageCondition == "NA") {
      isAge = true;
    }else{
    if (rateCard.ageCondition == "<") {
      if (getAge(person?.dateOfBirth) < rateCard.age) {
        isAge = true;
      }
    }
    if (rateCard.ageCondition == "<=") {
      if (getAge(person?.dateOfBirth) <= rateCard.age) {
        isAge = true;
      }
    }
    if (rateCard.ageCondition == ">") {
      if (getAge(person?.dateOfBirth) > rateCard.age) {
        isAge = true;
      }
    }
    if (rateCard.ageCondition == ">=") {
      if (getAge(person?.dateOfBirth) >= rateCard.age) {
        isAge = true;
      }
    }
    if (rateCard.ageCondition == "=") {
      if (getAge(person?.dateOfBirth) == rateCard.age) {
        isAge = true;
      }
    }
    if (rateCard.ageCondition == "!=") {
      if (getAge(person?.dateOfBirth) != rateCard.age) {
        isAge = true;
      }
    }
   }

    return isAge;
  };

  const handleSelectCoverage = (ratecard) => {

    let selected = {
                     relation:"Self",
                     coverageId:coveragePlan.id,
                     amount:ratecard.sumInsured,
                     };
    setSelectedCoverageCard(selected);
    setselectedDiv(ratecard.id);   
    setCurrentEnrollingId(currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id);

  };

  const getPremium = (ratecard) => {
    if(selectedPlan?.deductionMethod == "Percent"){
      let premium = (ratecard.premium * coveragePlan?.employeeSplitPerc)/100 ;
      return CurrencyFormat(premium, currency)
    }else{
    return CurrencyFormat(coveragePlan?.employeeSplitPerc,currency)
  }
};

const getPlanDependant = (rel) => {
  let dep = [];

  getElligiblePlanDependant(selectedPlan, employeeDetails)?.forEach((x) => {
    if(x.relation?.includes("PIL") && ["FIL", "MIL"].includes(rel)){
      dep = x;
    }
    if(x.relation?.includes("Parent") && ["Mother", "Father"].includes(rel)){
      dep = x;
    }
    if(x.relation?.includes(rel)){
      dep = x;
    }
})

  return dep;
}

const handleViewCost = () => {

  setViewCost(true);

  setGetCostLoader(true);

  APIList.getCost({
    employee:{
      id: employeeDetails.id
    },
    isEmployeeEnrolled: 
    // employee?.length > 0,
    true,
    selectedCoverage:{
      id: selectedCoverageCard?.coverageId
    },
    employeeCoverage: selectedCoverageCard?.amount,
    plan:{
      id: selectedPlan.id,
      className: selectedPlan.className
    },
    enrollmentDate: new Date(),
    dependantEnrollments: familySelectedCoverages.map((x) => {return({
                              dependants:{
                                id: x.id
                              },
                              dependantEnrollmentDate: new Date(),
                              selectedPlan:{
                                id: getPlanDependant(x.relation)?.id
                              },
                              dependentCoverage: selectedCoverageCard?.amount
                            })})
  }).then((res) => {
    // res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;{}</div>);
    setCostData(res.data);
    setGetCostLoader(false);
  })
  .catch((err) =>{
    toast.error(
      <div className='flex flex-row'>
        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
        &nbsp;&nbsp;{err?.title}
      </div>
    );      
    setGetCostLoader(false);      
  }
  );

};

const isEdit = () => {
  let edit = false;
  if(enrolledPlanData?.plan?.id == selectedPlan?.id){
     edit = true;
  }
  return edit;
};

const handleEnrollment = () => {
  setEnrollLoader(true);

  if(isEdit()){ 
    APIList.editHealthInsuranceEnroll({
      id:enrolledPlanData?.id,
      benefitStartDate:benefitStartDate,
      employee:{
        id: employeeDetails.id
      },
      isDraft: true,
      status: "ISDRAFT",
      isEmployeeEnrolled: 
      // employee?.length > 0,
      true,
      selectedCoverage:{
        id: selectedCoverageCard?.coverageId
      },
      employeeCoverage: selectedCoverageCard?.amount,
      plan:{
        id: selectedPlan.id,
        className: selectedPlan.className
      },
      enrollmentDate: new Date(),
      dependantEnrollments: familySelectedCoverages.map((x) => {return({
                                benefitStartDate:benefitStartDate,
                                dependants:{
                                  id: x.id
                                },
                                dependantEnrollmentDate: new Date(),
                                selectedPlan:{
                                  id: getPlanDependant(x.relation)?.id
                                },
                                dependentCoverage: selectedCoverageCard?.amount
                              })})                       
   }).then((res) => {
     res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Enrolled in {selectedPlan?.displayName} successfully</div>);
     setEnrollLoader(false);
     history.push({ pathname: "/plans", state: { fromAddress: true } });
   })
   .catch((err) =>{
     toast.error(
       <div className='flex flex-row'>
         <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
         &nbsp;&nbsp;{err?.title}
       </div>
     );      
     setEnrollLoader(false);      
   }
   )
   }else{

  APIList.healthInsuranceEnroll({
    employee:{
      id: employeeDetails.id
    },
    benefitStartDate:benefitStartDate,
    isDraft: true,
    status: "ISDRAFT",
    isEmployeeEnrolled: 
    // employee?.length > 0,
    true,
    selectedCoverage:{
      id: selectedCoverageCard?.coverageId
    },
    employeeCoverage: selectedCoverageCard?.amount,
    plan:{
      id: selectedPlan.id,
      className: selectedPlan.className
    },
    enrollmentDate: new Date(),
    dependantEnrollments: familySelectedCoverages.map((x) => {return({
                              benefitStartDate:benefitStartDate,
                              dependants:{
                                id: x.id
                              },
                              dependantEnrollmentDate: new Date(),
                              selectedPlan:{
                                id: getPlanDependant(x.relation)?.id
                              },
                              dependentCoverage: selectedCoverageCard?.amount
                            })})

  }).then((res) => {
    res && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;Enrolled in {selectedPlan?.displayName} successfully</div>);
    setEnrollLoader(false);
    // history.push({ pathname: "/plans", state: { fromAddress: true } });
        dispatch(getPlanActions.PlanDataRequest("clearData")); 
        history.push({pathname:'/loading', state:{planName:selectedPlan?.displayName}});
  })
  .catch((err) =>{
    toast.error(
      <div className='flex flex-row'>
        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
        &nbsp;&nbsp;{err?.title}
      </div>
    );      
    setEnrollLoader(false);      
  }
  );
   }
};

  return (
    <>
    {selectedPlan && (
        <div>
          <h4 className="page-heading-navbar">{selectedPlan.displayName}</h4>
          <PageHeaderForPlans 
          // btnRedirect={() => setOpenModal(true)} 
          />
        </div>
      )}
    
    <div className="singleDependant mx-4">
      
         <div className="w-full mt-4 font-semibold coverageSelectHead">Select coverage for your Family - </div>
      {allowedRatecards?.length == 0 ? (
        <div className="my-16 text-center"> No plan available </div>
      ) : (
        <>
          <div className="w-full flex flex-row mt-4 relative">
            <div className="w-full mx-4 costsHead">Coverage Amount</div>
            <div className="w-full mx-4 costsHead">Employee Cost</div>
          </div>

          {allowedRatecards?.map((ratecard) => {
            return (
              <div
                key={ratecard.id}
                // className="w-full flex flex-row card mt-4 relative"
                className={classNames(
                  "w-full flex flex-row card mt-4 relative",
                  {
                    activeCard:
                      ratecard.id == selectedDiv,
                      //  && (currentEnrollingId == currentlyEnrolled[currentlyInprocessEnrolled?.length]?.id)
                  }
                )}
              >
                <div className="w-full flex justify-between items-center sm:mx-4 mr-2 cardInner">
                  <div className="left">
                    <p className="price">
                      {CurrencyFormat(ratecard.sumInsured, currency)}
                    </p>
                  </div>
                  <div className="right">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.0677 11.9929L18.818 7.75739L17.4061 9.17398L19.2415 11.0032L0.932469 11.0012L0.932251 13.0012L19.2369 13.0032L17.4155 14.8308L18.8321 16.2426L23.0677 11.9929Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
                <div className="w-full flex justify-between items-center sm:mx-4 ml-2 cardInner2">
                  <div className="left">
                    <p className="price">                      
                      {getPremium(ratecard)}
                    </p>
                  </div>

                  {ratecard.id == selectedDiv &&
                  currentEnrollingId ==
                    currentlyEnrolled[currentlyInprocessEnrolled?.length]
                      ?.id ? (
                    // <BsFillCheckCircleFill
                    //   style={{
                    //     width: "2rem",
                    //     height: "2rem",
                    //     color: "var(--primary)",
                    //   }}
                    // />
                    <button
                    className="coverageSelectButtonActive"
                    // onClick={() => handleSelectCoverageForSelf(ratecard)}
                  >
                    Select
                  </button>
                  ) : (
                    <button
                      className="coverageSelectButton"
                      onClick={() => handleSelectCoverage(ratecard)}
                    >
                      Select
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}

      { 
      (Object.keys(selectedCoverageCard).length != 0) &&
        <p className="linksOuter w-full flex justify-end mx-auto my-4">
                <span className="viewLink cursor-pointer" onClick={() => handleViewCost()}>
                View Cost
                </span>
              </p>
      }             

      <div className="w-full">
        
       <p
          className="mt-2 peer-invalid:visible text-gray-400 text-sm"
          style={{ marginBottom: "-1rem" }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  checked={electionChecked}
                  onChange={() => setElectionChecked(!electionChecked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Click here to confirm"
            />
          </FormGroup>

        </p> 
        <ButtonM 
        text={
          <FormattedMessage id="common.button.PLANSUBMIT" defaultMessage="Save as Draft"/>
        } 
        onpress={() => handleEnrollment()}
        disabled={
          (!electionChecked || enrollLoader || (Object.keys(selectedCoverageCard).length == 0))
        }
        loading={enrollLoader}
        bgcolor={
          (!electionChecked || (Object.keys(selectedCoverageCard).length == 0))
            ? 
              "gray"
            : ""
        }
        />
      </div>
      
    </div>

    {viewCost && (
        <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-full h-full popupWrapper">
            <div className="totalCostPopup" ref={wrapperRef}>
            <GetCostVersion2 
              onBack={() => setViewCost(false)}
              loader={
                getCostLoader
                // true
              }
              costData = {costData}
              
              payCheckFrequency={getPayCheckFrequency(selectedPlan, employeeDetails)}
              />
             
            </div>
          </div>
        </div>
      )}

    {/* {openModal && <VoluntaryParentalModal setOpenModal={setOpenModal} />}  */}

    </>
  );
};
export default FamilyCoverageSelection;
