import Rectangle from "../../../../assets/Rectangle95.svg";
import radioCheckIcon from "../../../../assets/radioCheck.svg";
import { Checkbox, CircularProgress, FormControlLabel, FormGroup } from "@mui/material";
import ButtonM from "components/common/Button";
import { getReimCoverageRule, isEmptyNullUndefined } from "components/utils/utils";
import { useEffect, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { actions as getPlanActions } from "../../../common/Plans/redux/actions";
import { reimburseActions as reimActions } from "../redux/action";
import APIList from "api";
import { FormattedMessage } from "react-intl";

var classNames = require('classnames');

const ReimbursementEnroll = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();

  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const ReimbursementPlanData = useSelector((state) => state?.reimReducer?.reimbursePlanData?.plan);
  const enrolledPlanData = useSelector((state) => state?.planReducer?.enrolledPlanData);
  const ReimLoader = useSelector((state) => state?.reimReducer?.loader);
  const EnrolledPlanLoader = useSelector((state) => state?.planReducer?.loader);
  
  const [dontWantEnroll, setDontWantEnroll] = useState(false);
  const [enroll, setEnroll] = useState(false);
  const [electionChecked, setElectionChecked] = useState(false);
  const [coveragePlan, setCoveragePlan] = useState(null);
  const [enrollMentLoader, setEnrollMentLoader] = useState(false);

  useEffect(() => {
    if(params?.type === "edit"){
        dispatch(getPlanActions.EnrolledPlanDataRequest({enrollmentId:params?.id}));       
    }else{
        dispatch(reimActions.getReimbursePlanRequest({     
      className: "reimbursements",
      Id: params?.id,
      empId: employeeDetails?.id,
      eventId: null,
      lifeEventId: null
    }));  
    }
    
  }, [employeeDetails]);

  useEffect(() => {
    if(!isEmptyNullUndefined(enrolledPlanData?.plan?.id)){
    dispatch(reimActions.getReimbursePlanRequest({     
        className: "reimbursements",
        Id: enrolledPlanData?.plan?.id,
        empId: employeeDetails?.id,
        eventId: null,
        lifeEventId: null
      }));  
    }
  }, [enrolledPlanData])  

  useEffect(() => {
    setCoveragePlan(getReimCoverageRule(ReimbursementPlanData, employeeDetails));
    if(ReimbursementPlanData?.autoRenewal) {
        setEnroll(true)
      }
  }, [ReimbursementPlanData])
  

  const handleAddEnroll = () => {
    setEnrollMentLoader(true);
    if(params?.type === "edit"){ 
      APIList.editHealthInsuranceEnroll({
        id:enrolledPlanData?.id,
        employee:{
          id: employeeDetails?.id
        },
        isDraft: true,
        status: "ISDRAFT",
        isEmployeeEnrolled: !dontWantEnroll,
        planOptOut:dontWantEnroll,
        selectedCoverage:
        // null,
        {
          id: dontWantEnroll ? null : coveragePlan?.id     
      },
        // employeeCoverage: dontWantEnroll ? null : value,
        // selectedPlan?.coveragePlans[0]?.amount,
    //   idType:selectedPlan?.uniqueIDName,
    //   idNumber:idNumber,
        plan:{
          id: ReimbursementPlanData?.id,
          className: ReimbursementPlanData?.className
        },
        enrollmentDate: new Date(),
      }).then((res) => {
        res &&
            toast.success(
              <div className="flex flex-row">
                <BsFillCheckCircleFill
                  style={{ width: "2rem", height: "2rem" }}
                />
                &nbsp;&nbsp;Updated Successfully
              </div>
            );
            setEnrollMentLoader(false);
            history.push('/plans');
      })
      .catch((err) =>{
        toast.error(
          <div className='flex flex-row'>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );      
        setEnrollMentLoader(false);      
      }
      );
    }else{
    APIList.healthInsuranceEnroll({
      employee:{
        id: employeeDetails.id
      },
      isDraft: true,
      status: "ISDRAFT",
      isEmployeeEnrolled: !dontWantEnroll,
      planOptOut:dontWantEnroll,
      selectedCoverage:
      // null,
      {
        id: dontWantEnroll ? null : coveragePlan?.id     
    },
    //   employeeCoverage: dontWantEnroll ? null : value,
      // selectedPlan?.coveragePlans[0]?.amount,
    //   idType:selectedPlan?.uniqueIDName,
    //   idNumber:idNumber,
      plan:{
        id: ReimbursementPlanData?.id,
        className: ReimbursementPlanData?.className
      },
      enrollmentDate: new Date(),
    }).then((res) => {
      res &&
          toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;Enrolled Successfully
            </div>
          );
          setEnrollMentLoader(false);
          
          dispatch(getPlanActions.PlanDataRequest("clearData")); 
          history.push('/loading');
    })
    .catch((err) =>{
      toast.error(
        <div className='flex flex-row'>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{err?.title}
        </div>
      );      
      setEnrollMentLoader(false);      
    }
    );
  }
  };

  return (
    <div className="reim-enroll-main-page">
        <h4 className="page-heading-navbar">{ReimbursementPlanData?.displayName}</h4>
        <div className="main-reim-en">
      <div className="w-full">
        {
           ( ReimLoader || EnrolledPlanLoader ) ? 
           <CircularProgress />

           :
        
           <div className="radioButtonOuter">
          {ReimbursementPlanData?.planOptOut && (
            <button
              onClick={() => {
                setDontWantEnroll(!dontWantEnroll);
              }}
              className={classNames("radioButton", {
                active: dontWantEnroll,
              })}
            >
              <div className="radioOuter">
                <img src={Rectangle} alt="" className="radio" />
                <img src={radioCheckIcon} alt="" className="radioCheck" />
              </div>
              I don't want to enroll
            </button>
          )}

          <button
            onClick={() => !ReimbursementPlanData?.autoRenewal && setEnroll(!enroll)}
            className={classNames("radioButton", { active: enroll })}
          >
            <div className="radioOuter">
              <img src={Rectangle} alt="" className="radio" />
              <img src={radioCheckIcon} alt="" className="radioCheck" />
            </div>
            {employeeDetails?.firstName} {employeeDetails?.lastName}
          </button>
          </div>
          
        }
        
        
      </div>
      </div>
      <div className="bottomButtonReq">
            <p
          className="mt-2 peer-invalid:visible text-gray-400 text-sm"
          style={{ marginBottom: "-1rem" }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  checked={electionChecked}
                  onChange={() => setElectionChecked(!electionChecked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Click here to confirm"
            />
          </FormGroup>
        </p>
              <ButtonM
                text={
                  <FormattedMessage id="common.button.PLANSUBMIT" defaultMessage="Save as Draft"/>
                }
                onpress={() => handleAddEnroll()}
                disabled={enrollMentLoader || (!dontWantEnroll && !enroll) || !electionChecked}
                loading={enrollMentLoader}
                bgcolor={
                  ((!dontWantEnroll && !enroll) || !electionChecked) ? "gray" : ""
                }
              />
            </div>
    </div>
  );
};

export default ReimbursementEnroll;
