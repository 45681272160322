import { TextField, Grid, MenuItem, Card, CardContent } from '@mui/material';
import { useFormik } from 'formik';
import ButtonM from 'components/common/Button';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import { validationSchema } from '../validationSchema';
import APIList from 'api';
import { actions as EmployeeDataActions } from 'components/common/Header/redux/actions';
import moment from 'moment';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import { RiErrorWarningFill } from 'react-icons/ri';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

const textFieldStyle = {
  my: 2,
  backgroundColor: '#fff',
  '& fieldset': {
    border: '1px solid #dedede',
  },
  '& .MuiInputBase-input': {
    backgroundColor: '#dedede',
  },
  '& .MuiInputBase-input:focus': {
    border: '2px solid #fcd25e', // focus
  },
  '& .css-k4qjio-MuiFormHelperText-root': {
    backgroundColor: '#ffffff',
    margin: '0px',
    paddingLeft: '0.5rem',
  },
};

const genderList = [
  {
    value: 'MALE',
    label: 'MALE',
  },
  {
    value: 'FEMALE',
    label: 'FEMALE',
  },
  {
    value: 'NA',
    label: 'NA',
  },
];

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const { empData } = useSelector((state) => state.empData);
  // const { user } = useSelector((state) => state.user);

  const initialValues = {
    firstName: empData?.firstName,
    lastName: empData?.lastName,
    email: empData?.email,
    employeeDesignation: empData?.employeeDesignation,
    dateOfJoining: empData?.dateOfJoining,
    gender: empData?.gender,
    dateOfBirth: empData?.dateOfBirth,
    streetLineOne: empData?.address?.streetLineOne,
    streetLineTwo: empData?.address?.streetLineTwo,
    mobileNumber: empData?.mobileNumber,
    city: empData?.address?.city,
    maritalStatus: empData?.maritalStatus,
    nationalId: empData?.nationalId,
    terminationReason: empData?.terminationReason,
    state: empData?.address?.state,
    postalCode: empData?.address?.postalCode,
    bankName: empData?.bankName,
    bankBranchCode: empData?.bankBranchCode,
    accountNo: empData?.accountNo,
  };



 
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (
      {
        firstName,
        lastName,
        email,
        employeeDesignation,
        dateOfJoining,
        gender,
        dateOfBirth,
        streetLineOne,
        streetLineTwo,
        city,
        state,
        postalCode,
      },
      { setSubmitting }
    ) => {
      setSubmitting(true);
      APIList.updateEmployeeData(empData.id, {
        ...empData,
        firstName,
        lastName,
        email,
        dateOfJoining: moment(dateOfJoining).format('YYYY-MM-DD'),
        gender,
        dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
        address: {
          id: empData?.address?.id,
          postalCode,
          state,
          streetLineOne,
          streetLineTwo,
          city,
        },
      })
        .then((res) => {
          dispatch(
            EmployeeDataActions.EmployeeDataRequest()
          );
          setSubmitting(false);
          toast.success(
            <div className='flex flex-row'>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;
              {'Profile updated successfully!'}
            </div>
          );
        })
        .catch((err) => {
          setSubmitting(false);
          toast.error(
            <div className='flex flex-row'>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.response?.data?.title}
            </div>
          );
        });
    },
  });

  return (
    <>
      <div className='homePage'>
        <h4 className='page-heading-navbar updateProfileTitle'>Update Profile</h4>
        <div className='p-4 m-4'>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Card>
                  <h3 className='py-2 pb-4 font-semibold text-gray-600'>
                    Personal Info
                  </h3>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        > First Name  </Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.firstName}</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        >Last Name</Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.lastName}</Typography>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        >Mobile Number </Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.mobileNumber}</Typography>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        >Designation </Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.employeeDesignation}</Typography>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        >Marital Status </Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.maritalStatus}</Typography>
                      </Grid>

                      {/* <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        >Bank Name </Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.bankName}</Typography>
                      </Grid> */}

                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        >Date Of Birth </Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.dateOfBirth}</Typography>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        >Email  </Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.email}</Typography>

                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        >Date Of Joining </Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.dateOfJoining}</Typography>

                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        >Gender </Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.gender}</Typography>

                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <h3 className='py-2 pb-4 font-semibold text-gray-600'>
                    Bank Details
                  </h3>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        > Bank Name  </Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.bankName}</Typography>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        > Bank Bank BranchCode  </Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.bankBranchCode}</Typography>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption" display="block"
                          fullWidth

                        > Account No.  </Typography>
                        <Typography
                          sx={{ p: 1, border: '1px solid gainsboro', borderRadius: "5px" }}
                          component={Box}
                          variant="subtitle2" gutterBottom

                        >  {formik.values.accountNo}</Typography>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <h3 className='py-2 pb-4 font-semibold text-gray-600'>
                    Address
                  </h3>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          size='small'
                          fullWidth
                          id='streetLineOne'
                          name='streetLineOne'
                          label='Address Line 1'
                          value={formik.values.streetLineOne}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.streetLineOne &&
                            Boolean(formik.errors.streetLineOne)
                          }
                          helperText={
                            formik.touched.streetLineOne &&
                            formik.errors.streetLineOne
                          }
                          sx={textFieldStyle}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          size='small'
                          fullWidth
                          id='streetLineTwo'
                          name='streetLineTwo'
                          label='Address Line 2'
                          value={formik.values.streetLineTwo}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.streetLineTwo &&
                            Boolean(formik.errors.streetLineTwo)
                          }
                          helperText={
                            formik.touched.streetLineTwo &&
                            formik.errors.streetLineTwo
                          }
                          sx={textFieldStyle}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          size='small'
                          fullWidth
                          id='city'
                          name='city'
                          label='City'
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.city && Boolean(formik.errors.city)
                          }
                          helperText={formik.touched.city && formik.errors.city}
                          sx={textFieldStyle}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          size='small'
                          fullWidth
                          id='state'
                          name='state'
                          label='State'
                          value={formik.values.state}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.state && Boolean(formik.errors.state)
                          }
                          helperText={
                            formik.touched.state && formik.errors.state
                          }
                          sx={textFieldStyle}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          size='small'
                          id='postalCode'
                          name='postalCode'
                          label='Postal Code'
                          value={formik.values.postalCode}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.postalCode &&
                            Boolean(formik.errors.postalCode)
                          }
                          helperText={
                            formik.touched.postalCode &&
                            formik.errors.postalCode
                          }
                          sx={textFieldStyle}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <ButtonM
              type='submit'
              text='Update'
              onpress={() => { }}
              loading={formik.isSubmitting}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateProfile;
