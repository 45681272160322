import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ButtonM from 'components/common/Button';
import { FormattedMessage } from 'react-intl';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  minWidth: 340,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  fontStyle: "normal",
    fontWeight: "400",
    fontSize: "0.75rem !important",
    lineHeight: "22px",
    color: "rgba(0, 0, 0, 0.65)",
};

export default function ModalHeader({openAnc, showModal}) {
  const [open, setOpen] = React.useState(openAnc || false);
//   const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false); showModal(false)};

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={openAnc}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
          <div id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{fontSize:"0.91rem", paddingBottom:"1rem"}}>
            <strong>
              {/* Rights to vary program */}
              <FormattedMessage 
                    id="policyDetails.disclaimer.rightsToVaryProgram"
                    defaultMessage="Rights to vary program" 
                />

            </strong>
            </span>
            <p className='mb-2'>
            {/* The information on this webpage summarizes the current terms of
            certain employee benefit plans and programs available to Company
            employees. Company's formal employee plan documents govern the terms
            and conditions of the plans.  */}
            <FormattedMessage 
                    id="policyDetails.disclaimer.text1"
                    defaultMessage="The information on this webpage summarizes the current terms of
                    certain employee benefit plans and programs available to Company
                    employees. Company's formal employee plan documents govern the terms
                    and conditions of the plans." 
                />
            </p>
            <p className='mb-2'>
            {/* Company has reserved the right to modify, change, and revise the
            terms and conditions of the Company's employee benefits plans, as
            well as the right to terminate the plans, to the extent permitted by
            applicable law. */}
            <FormattedMessage 
                    id="policyDetails.disclaimer.text2"
                    defaultMessage="Company has reserved the right to modify, change, and revise the
                    terms and conditions of the Company's employee benefits plans, as
                    well as the right to terminate the plans, to the extent permitted by
                    applicable law." 
                />
            </p>
            {/* <p className='mb-2'>
            The information provided on this webpage is not a guarantee of the
            availability or design of the Company's employee benefits.
            </p> */}
          </div>
          <ButtonM text={<FormattedMessage id="common.button.back" defaultMessage="BACK"/>} onpress={() => handleClose()} />
        </Box>
      </Modal>
    </div>
  );
}