import { getPlanIcon } from 'components/utils/utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PolicyCoverImg from '../../../assets/policyCover.png';
const parse = require('html-react-parser');

const PolicyCoverForPlans = () => {  

  const history = useHistory();

const messages = useSelector((state) => state?.planReducer?.planMsg);
const selectedPlan = useSelector((state) => state?.planReducer?.planData);
const eligibleplans = useSelector((state) => state && state?.empData?.eligibleplans);
const elligiblePlanLoader = useSelector((state) => state && state?.empData?.elligiblePlanLoader);

useEffect(() => {
  if(Object.keys(selectedPlan)?.length == 0){
      history.push("/plans");
    }
}, [selectedPlan]);

  return (
    <div className="policyCoverPage flex flex-col mt-10 mb-10 mx-4">
      <div className="mainImg w-full flex flex-col items-center">
        <div className="relative">
          <img
            src={PolicyCoverImg}
            className="coverBgImage"
            alt="coverBgImage"
          />
          <img
            src={getPlanIcon(eligibleplans, selectedPlan?.id)}
            className="policyIcon absolute top-1/4 right-1/4"
            style={{width:"50%"}}
            alt="policyIcon"
          />
        </div>
      </div>
      <div className="coverTitle mx-auto my-6 mt-10">{selectedPlan?.displayName }</div>

      {messages?.map((item) => {
        return (
          <>
            {item.pageNo == "PAGE_1" && (
              <div className="coverDescription">{parse(item?.details)} </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default PolicyCoverForPlans;