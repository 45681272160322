import React, { useEffect, useRef, useState, } from 'react'
import { useSelector } from 'react-redux';
import "../../../styles/LearningPages/basics.scss";

const parse = require('html-react-parser');

const LeaveModel = ({ data }) => {
    const { setViewDetails, } = data;


    var Icon = "";
    const [detailsState, setDetail] = useState({})

    const state = useSelector((state) => state);

    const companyPlandata = state?.companyPlanReducer;



    const { loader, eventDetails, errors } = companyPlandata;

    function HandleOutsideClick(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setViewDetails(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    HandleOutsideClick(wrapperRef);

    const tableRows = [
        {
            LeaveType: " Company Declared Holiday",
            Entitlement: "Refer Holiday list",
            PaidUnpaid: "Paid",
            Eligibility: "All Employees",
        },
        {
            LeaveType: "  Vacation",
            Entitlement: "20 working days",
            PaidUnpaid: "Paid",
            Eligibility: "All Regular Full-time",
        },
        {
            LeaveType: "Paternity Leave",
            Entitlement: "10 working days",
            PaidUnpaid: "Paid",
            Eligibility: "Refer to <a className='text-blue-500' href='https://www.mom.gov.sg/employment- practices/leave/paternity-leave' target='blank'> MOM </a> website",
        },

        {
            LeaveType: "Maternity Leave",
            Entitlement: "Either 16 weeks OR 12 weeks ",
            PaidUnpaid: "Paid",
            Eligibility: "Refer to <a className='text-blue-500' href='https://www.mom.gov.sg/employment-practices/leave/maternity-leave/eligibility-and-entitlement' target='blank'> MOM </a> website",
        },

        {
            LeaveType: "Adoption Leave",
            Entitlement: "12 weeks",
            PaidUnpaid: "Paid",
            Eligibility: "Refer to <a className='text-blue-500' href='https://www.mom.gov.sg/employment-practices/leave/adoption-leave' target='blank'> MOM </a> website",
        },
        {
            LeaveType: "Shared Parental Leave",
            Entitlement: "Upto 4 weeks",
            PaidUnpaid: "Paid",
            Eligibility: "Refer to <a className='text-blue-500' href='https://www.mom.gov.sg/employment-practices/leave/shared-parental-leave' target='blank'> MOM </a> website",
        },
        {
            LeaveType: "Childcare Leave",
            Entitlement: "6 days",
            PaidUnpaid: "Paid",
            Eligibility: "Refer to <a className='text-blue-500' href='https://www.mom.gov.sg/employment-practices/leave/childcare-leave' target='blank'> MOM </a> website",
        }, 

        {
            LeaveType: "Bereavement Leave",
            Entitlement: "5 working days",
            PaidUnpaid: "Paid",
            Eligibility: "All Regular Full-time",
        },

        {
            LeaveType: "Refresh Leaves",
            Entitlement: "10 working days",
            PaidUnpaid: "Paid",
            Eligibility: "All Regular Full-time",
        },
        {
            LeaveType: "Sick Leave",
            Entitlement: "60 days if Hospitalized, 14 days if not",
            PaidUnpaid: "Paid",
            Eligibility: "All Regular Full-time",
        },

        {
            LeaveType: "Family Care Leave",
            Entitlement: "5 working days",
            PaidUnpaid: "UnPaid",
            Eligibility: "All Regular Full-time",
        }, 

        {
            LeaveType: "Annual Year-end closure Leave",
            Entitlement: "25th Dec to 1st Jan",
            PaidUnpaid: "Paid",
            Eligibility: "All Regular Full-time",
        },   

        {
            LeaveType: "Compensatory off",
            Entitlement: "In lieu of having worked on public holiday",
            PaidUnpaid: "Paid",
            Eligibility: "All Regular Full-time",
        },

    ]



    return (
        <div className="learningPageCoverageDetails reimbursementModal ben-modal">
        <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mr-auto md:ml-auto max-w-3xl">
            <div className="CoverageDetailsPopup" ref={wrapperRef}>
        {/* <div className="justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            <div className="relative w-auto my-6 mr-auto md:ml-auto max-w-3xl">

                <div className="CoverageDetailsPopup" ref={wrapperRef}> */}
                    {
                        <div className="content">
                            {/* <p style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                color: "var(--primary)",
                            }}>Summary of leave benefit</p> */}
                            <div className="fex flex-wrap">
                                <table className='dependent-table my-4'>
                                    <thead>
                                        <tr>
                                            <th className="md:tracking-wider text-center" style={{ width: '33.33%' }}><p className='table-header'>Leave Type</p></th>
                                            <th   className="md:tracking-wider text-center" style={{ width: '22.33%' }}><p className='table-header'>Entitlement</p></th>
                                            <th className="md:tracking-wider text-center" style={{ width: '18.33%' }}><p className='table-header'>Paid / Unpaid</p></th>
                                            <th className="md:tracking-wider text-center" style={{ width: '35.33%' }}><p className='table-header'>Eligibility</p></th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-left">
                                        {
                                            tableRows?.map((row) => {
                                                return (
                                                    <tr 
                                                    >
                                                        <td class="table-rows-outer"
                                                         style={Array.isArray(row?.Entitlement) ? {background: "var(--tertiary)"}:{}}
                                                        ><p className='table-rows col-1'>{row?.LeaveType}</p></td>
                                                        <td  >

                                                            {
                                                                Array.isArray(row?.Entitlement) ? row.Entitlement.map((x) => {
                                                                    return (
                                                                        <p className='table-rows'>{x}</p>
                                                                    )
                                                                }) : <p className='table-rows'>{row.Entitlement}</p>
                                                            }
                                                        </td>
                                                        <td   style={Array.isArray(row?.Entitlement) ? {background: "var(--tertiary)"}:{}}><p className='table-rows'>
                                                            {/* <a href="ds"> */}
                                                                {row?.PaidUnpaid}
                                                                {/* </a> */}
                                                                 </p></td>
                                                        <td    style={Array.isArray(row?.Entitlement) ? {background: "var(--tertiary)"}:{}}><p className='table-rows'>
                                                            {/* <a href="ds"> */}
                                                                {parse(row?.Eligibility)}
                                                                {/* </a> */}
                                                                 </p></td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                                {/* <p style={{ fontSize: "13px", color: "grey" }}>
                                    * Except Operations Team <br />
                                    ** Employees in critical functions may avail leaves betw 15th Dec to 15th Jan upon discussion with your Manager.

                                </p> */}
                            </div>



                            <div className="flex flex-wrap justify-between w-full total">
                                <button className="backBtn" onClick={() => setViewDetails(false)}>
                                    Back
                                </button>
                            </div>
                        </div>
                    }

                </div>
                </div>
            </div>
        </div>
    )
}

export default LeaveModel