import React, { useEffect, useState, useRef } from 'react'
import { Button, Stack, Typography, Box, List, ListItem } from "@mui/material";
import { employeeActions } from "./reedux/action";
import { IoMdSend } from "react-icons/io"
import { FaUserCircle } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux';
import { BsEyeFill } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import APIList from 'api';
import { toast } from 'react-toastify';
const ViewChat = ({ match: { params } }) => {

    // const messageRef = useRef();
    const dispatch = useDispatch();
    const employeeDetails = useSelector((state) => state && state.empData);
    const { loader, individualCase } = useSelector(state => state?.InboxReducer);
    const { loader: empLoader, empData } = useSelector(state => state?.empData);
    const divRef = useRef(null);

    const [caseData, setCaseData] = useState({});
    const [chatState, setchatState] = useState({
        message: ""
    });

    useEffect(() => {
        dispatch(employeeActions.getCaseRequest({
            id: params?.id
        }))
    }, []);

// to scroll to bottom after sending message
    useEffect(() => {
        if(caseData?.comments?.length > 0){
        const divElement = divRef.current;
        divElement.scrollTop = divElement.scrollHeight - divElement.clientHeight;
    }}, [chatState, caseData])
    
    //data processing
    useEffect(() => {
        if (!loader) {

            //setCaseData(individualCase);


            const sortedComments = individualCase?.comments.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.createdAt) - new Date(b.createdAt);
            });
            
            setCaseData({ ...individualCase, comments: sortedComments })

        }
    }, [loader]);

    //destructing data from state 
    const openFile = (filePath) => {
        window.open(`${filePath}`);
    }

    const HandelChat = async (event) => {
        event.preventDefault();
        const API_BODY = {

            "message": chatState?.message,
            "employee": {
                "id": caseData?.raisedBy?.id
            },

            "caseId": {
                "id": caseData?.id
            }
        }

        if (chatState?.message !== "") {

            // setCaseData({...caseData,comments:{...caseData?.comments , ...API_BODY}})


            try {
                let { status, data } = await APIList.commentApi(API_BODY);

                if (status == 201 || status == 200) {

                    setTimeout(() => {
                        dispatch(employeeActions.getCaseRequest({
                            id: params?.id
                        }))
                    }, 500)

                    setchatState({
                        message: ""
                    })
                    // setCaseData(caseData:{comments:...caseData?.comments,{...API_BODY}})
                    //setCaseData({...caseData,comments:{...caseData?.comments , ...API_BODY}})
                }
            } catch (error) {
                toast.error(error)
            }
        }
    }

    const handelChatField = (event) => {
        let message = event.target.value;
        setchatState({ ...chatState, message });
        
    }
    
    //EMP DATA

    return (
        <div className="inbox-chat">
            <h4 className='page-heading-navbar ProfileTitle'>Inbox</h4>
            <div className='inbox-msg-main'>

            <div className="BMheader w-full px-2">
                            <div className="flex flex-wrap">
                                <div className="w-full"
                                // style={{ border: "1px solid" }}
                                >
                                    <div className="pageheaderdata"
                                    // style={{ border: "1px solid" }}
                                    >
                                        <div className="pageheaderDataInneer w-4/5"
                                        //style={{ border: "1px solid" }}
                                        >
                                            <h3 className="title" style={{ color: "var(--primary)", wordBreak:"break-all" }}>
                                                {caseData?.title}
                                            </h3>
                                            <p className=" " style={{ wordBreak:"break-all" }}>
                                                {caseData?.message}
                                            </p>
                                            {/* <p className=" ">
                                                  {`${caseData?.raisedBy?.firstName} ${caseData?.raisedBy?.lastName}`}
                                            </p> */}
                                        </div>
                                        <div>
                                            {
                                                caseData?.fileUrl?.path && 
                                                <a href={caseData?.fileUrl?.path} target="_blank">
                                                <img
                                                src={caseData?.fileUrl?.path}
                                                alt="preview"
                                                width="100px"
                                                />
                                                </a>
                                            }                                            
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='chat-section' ref={divRef}>

                        <Box>
<Stack
    sx={{
        height: "90%",

        flexDirection: "column",
        justifyContent: "flex-end"
    }}
    component={Box} m={2} direction="column" spacing={1}>

    <List component={Box}
        // ref={messageRef}
        // sx={{ overflowY: "scroll" }}
    >
        {
            Array.isArray(caseData?.comments) && caseData?.comments?.map((val, index) => {
                return (
                    <ListItem

                        key={val.id}
                        sx={{

                            my: 1,
                            mx: 0,
                            p: 0
                        }}
                        component={Stack}
                        direction="column"


                    >
                        <Stack
                            component={Box}

                            direction={empData?.id == val?.employee?.id ? "row-reverse " : "row"}
                            width="100%"
                            alignItems="center"

                        >
                            {
                                employeeDetails?.empData?.profilePhoto?.path && empData?.id == val?.employee?.id ? 
                                (
                                
                                <img
                                    src={employeeDetails?.empData?.profilePhoto?.path}
                                    alt="profilePik"
                                    className='img'
                                    style={{
                                        height: "2rem",
                                        width: "2rem",
                                        borderRadius: "26px"
                                    }}

                                />
                                
                                ) : (<FaUserCircle
                                style={{ fontSize: "22px", color: "grey" }}
                            />)
                            }



                            <p className='message mb-4' style={{ wordBreak:"break-all" }}>
                                {val.message}
                            </p>
                            {
                                val?.fileUrl?.path && <Tooltip title="View file" placement="top">
                                    <Button
                                        onClick={() => openFile(val?.fileUrl?.path)}
                                    >
                                        <BsEyeFill style={{ fontSize: "22px", color: "grey" }} />
                                    </Button>
                                </Tooltip>
                            }

                        </Stack>
                        <Stack component={Box} direction="row" justifyContent="flex-end" width="100%">
                            {empData?.id == val?.employee?.id && <small> {moment(val?.createdAt?.slice(0,10)).format("hh:mm a")}</small>}
                        </Stack>
                        {
                            empData?.id !== val?.employee?.id &&
                            <Stack
                                direction="column"
                                alignItems="flex-start"
                                component={Box}

                                width="100%"
                            >
                                <Typography variant="caption" display="block" gutterBottom>
                                    {`${val?.employee?.firstName} ${val?.employee?.lastName} `}

                                    <small> {moment(val?.createdAt).format("hh:mm a")}</small>
                                </Typography>
                            </Stack>
                        }
                    </ListItem>
                )
            })
        }
    </List>
    

</Stack>
</Box>
                            
                        </div>
                        
                        <div className='input-chat-section'>
                        {
                                    caseData?.status?.toLowerCase() == "active" &&
                                    <Box component="form"
                                        onSubmit={HandelChat}
                                        // sx={{position:"absolute", bottom:"4.5rem", width:"95%"}}
                                        sx={{margin:"0px auto", bottom:"4.5rem", width:"95%"}}
                                    >
                                        <div
                                            className='messageInput'
                                        >
                                            <input
                                                placeholder='write message here'
                                                value={chatState?.message}
                                                onChange={handelChatField}
                                            />
                                            <Button
                                                type="submit"
                                            >
                                                <IoMdSend />
                                            </Button>

                                        </div>
                                    </Box>
                                }
                        </div>

            </div>
        </div>
    )
}

export default ViewChat