import { toast } from "react-toastify";
import APIList from "api";
import { employeeTypes, employeeActions } from "./action";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { call, put, takeLatest } from 'redux-saga/effects';

const companyEmployeeCases = function* companyEmployeeCases({ payload }) {
    try {
        const { data } = yield call(APIList.getEmployeeCase, payload);
        if (data) {
            yield put(employeeActions.getEmployeeCaseRequest_success(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(employeeActions.getEmployeeCaseRequest_failed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(employeeActions.getEmployeeCaseRequest_failed());
    }
};

const companyCases = function* companyCases({ payload }) {
    try {
        const { data } = yield call(APIList.getCaseDetail, payload);
        if (data) {
            yield put(employeeActions.getCaseRequest_success(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(employeeActions.getEmployeeCaseRequest_failed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(employeeActions.getEmployeeCaseRequest_failed());
    }
};


export default function* sagas() {
    yield takeLatest(employeeTypes.GET_EMPLOYEE_CASE_REQUEST, companyEmployeeCases);
    yield takeLatest(employeeTypes.GET_CASE_REQUEST,companyCases)
}