import { handleActions } from 'redux-actions';
import { types } from './action';

const actionHandler = {
    [types.EVENT_LOGS_DATA_REQUEST]: (state, { payload }) => ({
        ...state,
        loader: true,
    }),
    [types.EVENT_LOGS_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        EventLogs: payload || [],

    }),
    [types.EVENT_LOGS_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
        EnrollError: "request failed",
    }),


};

export default handleActions(actionHandler, {
    loader: null,
    EventLogs: [],
    EnrollError: {},
});
